import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'Components/Form-Elements/Button';
import { Input } from 'Components/Form-Elements/Input';
import { getCookie, setCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { submitSelfExclusion } from 'Actions/responsible-gaming-action';
import { logout } from 'Actions/logout-action';
import { ARRAY_CONST } from 'Constants/array-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { ModalBox } from 'Components/ModalBox';
import { Loader } from 'Components/Loader';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const SelfExclusion = ({ history, onBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [exclusionTerm, setExclusionTerm] = useState('');
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const INITIAL_DATA = ARRAY_CONST.SELF_EXCLUSION.important_points_initial;
  const DEFAULT_DATA = ARRAY_CONST.SELF_EXCLUSION.important_points_default;
  const { term } = ARRAY_CONST.SELF_EXCLUSION;

  const cleanSession = () => {
    // clear token and other user data if set.
    setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, '');
    setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, '');
    history.push('/');
  };

  const onValChange = value => {
    setExclusionTerm(value);
    if (value !== '') {
      setIsMonthSelected(true);
    } else {
      setIsMonthSelected(false);
    }
  };

  const submitExclusionRequest = () => {
    setIsLoading(true);
    submitSelfExclusion([Number(exclusionTerm)])
      .then(({ data }) => {
        setIsLoading(false);
        if (data === null) {
          logout()
            .then(cleanSession)
            .catch(cleanSession);
        } else {
          setToggleModalBox(true);
          setModalBoxName(APP_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR);
        }
      })
      .catch(err => {
        setIsLoading(false);
        // api does not throw error, only return 200 status
      });
  };

  const selfExclusionForm = () => {
    return (
      <div className="wrapper formWrap">
        <div className="form-group">
          <Input
            inputType="text"
            textLabel="Full Name"
            className="form-control"
            id="full-name"
            value={`${userdata.firstName || ''} ${userdata.lastName || ''}`}
            onblur={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING, {
                action: 'Full Name',
                formData: {
                  firstName: userdata.firstName,
                  lastName: userdata.lastName,
                },
              });
            }}
          />
        </div>
        <div className="form-group">
          <Input
            inputType="text"
            textLabel="Email Address"
            className="form-control"
            id="full-name"
            value={userdata.emailAddress}
            onblur={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING, {
                action: 'Full Name',
                formData: {
                  firstName: userdata.firstName,
                  lastName: userdata.lastName,
                  emailAddreess: userdata.emailAddress,
                },
              });
            }}
          />
        </div>
        <div className="form-group">
          Self Exclusion Term
          <select
            onChange={e => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING, {
                action: 'Full Name',
                formData: {
                  firstName: userdata.firstName,
                  lastName: userdata.lastName,
                  emailAddreess: userdata.emailAddress,
                  NoOfMonth: e.target.value,
                },
              });
              onValChange(e.target.value);
            }}
            className="form-control">
            <option key="select-option" value="">
              -- No. of months --
            </option>
            {term.map(item => (
              <option key={item.key} value={item.key}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
        <Button
          className="btn btn-primary scrollBtn selfExclusiveBtn"
          type="submit"
          disabled={!isMonthSelected}
          onClick={() => {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELF_EXCLUSION, {
              action: 'Submit',
              formData: {
                firstName: userdata.firstName,
                lastName: userdata.lastName,
                emailAddreess: userdata.emailAddress,
              },
            });
            submitExclusionRequest();
          }}
          label={BUTTON_LABELS.COMMON.SUBMIT}
        />
      </div>
    );
  };

  const selfExclusionRequsetView = () => {
    return (
      <div className="wrapper">
        <p>
          <div>Submit Self-exclusion Request</div>
          <div>
            Verify your full name and email address. Then select the time frame
            for your requested self-exclusion period.
          </div>
        </p>
        {selfExclusionForm()}
      </div>
    );
  };

  const customerSupportView = () => {
    return (
      <div className="wrapper">
        <p>
          If for any reason, you want to extend the time period of your
          self-exclusion or permanently close your account,{' '}
          <a
            href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_MAILID}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING, {
                action: 'Contact Support',
              });
            }}
            className="">
            contact support
          </a>{' '}
          and we can assist you in making your final withdrawal and shutting
          down your account. If you decide you would like to come back and play
          at the end of your self-exclusion period, please{' '}
          <a
            href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_MAILID}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING, {
                action: 'Contact Support',
              });
            }}
            className="">
            contact support
          </a>
          {'. '}
          Each request for reactivation will be evaluated individually.
          Additionally, we recommend that you contact any other daily fantasy
          sports sites you may play with and request an exclusion from there as
          well.
        </p>
      </div>
    );
  };

  const renderList = item => {
    return <li key={Math.random()}>{item}</li>;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR;
    }
    return modalbox;
  };

  return (
    <>
      {isLoading && <Loader />}
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="Ok"
          showBothButtons={false}
        />
      )}
      <div className="responsibleGamingWrapper">
        <div className="pageHeaderWrap">
          <div
            className="d-flex pageHeader 
         resetTopBar flexStartWrap">
            <div className="leftSide">
              <Link
                to="#"
                className="arrowBack"
                onClick={() => {
                  if (typeof onBack === 'function') {
                    onBack();
                  } else {
                    history.push('/responsible-gaming');
                  }
                }}
              />
            </div>
            <h2>Self Exclusion</h2>
            <div className="rightSide" />
          </div>
        </div>
        <div className="outerWrapper selfMargin">
          <div className="wrapper">
            <p>
              {APP_CONSTANTS.PARAGRAPH_CONSTANTS.SELF_EXCLUSION}
              <ul>
                {INITIAL_DATA.map(item => renderList(item))}
                <li>
                  You can reach out to{' '}
                  <a
                    // eslint-disable-next-line max-len
                    href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_MAILID}`}
                    target="_blank"
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING,
                        {
                          action: 'Customer Support',
                        },
                      );
                    }}
                    rel="noopener noreferrer"
                    className="">
                    customer support
                  </a>{' '}
                  for assistance if you wish to withdraw any remaining funds
                  from your account
                </li>
                {DEFAULT_DATA.map(item => renderList(item))}
              </ul>
            </p>
          </div>
          {selfExclusionRequsetView()}
          {customerSupportView()}
        </div>
      </div>
    </>
  );
};
