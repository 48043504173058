export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  emails: [],
  // ssn: '',
  dob: '',
  zip: '',
  phone: '',
  address: '',
  address1: '',
  city: '',
  state: '',
  disableBtn: false,
  // ssnErr: false,
  fnErr: false,
  lnErr: false,
  emailErr: false,
  dobErr: false,
  zipErr: false,
  phoneErr: false,
  addressErr: false,
  address1Err: false,
  cityErr: false,
  stateErr: false,
  isLoading: true,
  modalBoxName: '',
  toggleModalBox: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setFirstName':
      return { ...state, firstName: action.payload };
    case 'setLastName':
      return { ...state, lastName: action.payload };
    case 'setEmail':
      return { ...state, email: action.payload };
    case 'setEmails':
      return { ...state, emails: action.payload };
    // case 'setSsn':
    //   return { ...state, ssn: action.payload };
    case 'setZip':
      return { ...state, zip: action.payload };
    case 'setPhone':
      return { ...state, phone: action.payload };
    case 'setAddress':
      return { ...state, address: action.payload };
    case 'setAddress1':
      return { ...state, address1: action.payload };
    case 'setCity':
      return { ...state, city: action.payload };
    case 'setState':
      return { ...state, state: action.payload };
    case 'setDob':
      return { ...state, dob: action.payload };
    case 'setFnErr':
      return { ...state, fnErr: action.payload };
    case 'setLnErr':
      return { ...state, lnErr: action.payload };
    case 'setEmailErr':
      return { ...state, emailErr: action.payload };
    case 'setDobErr':
      return { ...state, dobErr: action.payload };
    // case 'setSsnErr':
    //   return { ...state, ssnErr: action.payload };
    case 'setZipErr':
      return { ...state, zipErr: action.payload };
    case 'setPhoneErr':
      return { ...state, phoneErr: action.payload };
    case 'setAddressErr':
      return { ...state, addressErr: action.payload };
    case 'setAddress1Err':
      return { ...state, address1Err: action.payload };
    case 'setCityErr':
      return { ...state, cityErr: action.payload };
    case 'setStateErr':
      return { ...state, stateErr: action.payload };
    case 'setDisableBtn':
      return { ...state, disableBtn: action.payload };
    case 'setIsLoading':
      return { ...state, isLoading: action.payload };
    case 'setModalBoxName':
      return { ...state, modalBoxName: action.payload };
    case 'setToggleModalBox':
      return { ...state, toggleModalBox: action.payload };
    default:
      throw new Error(`Unknown action type, ${action.type}`);
  }
};
