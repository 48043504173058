import { getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';

const userData = () => {
  return getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
};

export const getAge = date => {
  // age must be in "2001-09-22" format
  const ageDate = date.split('-');
  const today = new Date();
  let age = today.getFullYear() - ageDate[0];
  const m = today.getMonth() + 1 - ageDate[1];
  if (m < 0 || (m === 0 && today.getDate() < ageDate[2])) {
    age -= 1;
  }
  return age;
};

export const getUserBirthDate = () => {
  const userDetails = userData();
  return userDetails.birthdate.slice(0, 10);
};
