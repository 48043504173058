/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
  checkIfUserExistsSocial,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import icon from '../../assets/icons/icons.svg';

export const NewLogin = ({ history }) => {
  const isMobile = detectDevice();
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');

  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const customerId = query.get('customerid');
  const externalToken = query.get('token');

  const isInternationalUserExists = response => {
    checkIfInternationalUserExists(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
      })
      .catch(error => {
        const { status, code, message } = error.response.data;
        console.log('error');
        console.log(error);
      });
  };

  const isUserExists = response => {
    setForbiddenErr('');
    setError('');
    checkIfUserExistsSocial(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setTimeout(function() {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
              action: 'Username Login',
              formData: {
                user: userName,
              },
            });
          }, 2000);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          code === APP_CONSTANTS.API_STATUS_CODE.PHONE_NOT_VERIFIED
        ) {
          const phoneWithoutCode = data.phoneNo.slice(-10);
          const path = `/m-login-phone`;
          history.push(path, {
            phone: +phoneWithoutCode,
            email: data.email,
            successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.PHONE_NOT_VERIFIED,
          });
        }
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          code === APP_CONSTANTS.API_STATUS_CODE.DRAFT_USER_ALREADY_EXISTS
        ) {
          const path = `/m-signup-dob`;
          history.push(path, {
            email: data.email,
            successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.DOB_AFTER_EMAIL,
          });
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.SELF_EXCLUDED
        ) {
          setForbiddenErr(MESSAGE_CONSTANTS.ERROR_MSG.SELF_EXCLUSION);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_INSERT_FAILED
        ) {
          setError(MESSAGE_CONSTANTS.ERROR_MSG.EMAIL_PASSWORD_MATCH);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.INVALID_EMAIL
        ) {
          setError(MESSAGE_CONSTANTS.ERROR_CODE.INVALID_EMAIL);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_NOT_EXISTS
        ) {
          history.push('/m-signup-phone', {
            successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.EMAIL_VERIFIED_SIGNUP,
          });
        }
      });
  };

  const responseFacebook = response => {
    setInSessionStorage(SOCIAL_SIGNUP_KEY.EMAIL, response.email);
    setInSessionStorage(
      SOCIAL_SIGNUP_KEY.FIRST_NAME,
      response.name && firstName(response.name),
    );
    setInSessionStorage(
      SOCIAL_SIGNUP_KEY.LAST_NAME,
      response.name && lastName(response.name),
    );
    setInSessionStorage(SOCIAL_SIGNUP_KEY.SIGNUP_TYPE, 'facebook');
    setInSessionStorage(SOCIAL_SIGNUP_KEY.USERNAME, response.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.FACEBOOK_TOKEN, response.accessToken);
    const data = {
      firstName: response.name && firstName(response.name),
      lastName: response.name && lastName(response.name),
      email: response.email,
      facebookToken: response.accessToken,
      username: response.email,
      loginstrategy: 'facebook',
    };
    if (response && response.status !== APP_CONSTANTS.RESPONSE_STATUS.UNKNOWN) {
      isUserExists(data);
    }
  };

  const responseGoogle = response => {
    const { profileObj } = response;
    setInSessionStorage(SOCIAL_SIGNUP_KEY.EMAIL, profileObj.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.FIRST_NAME, profileObj.givenName);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.LAST_NAME, profileObj.familyName);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.SIGNUP_TYPE, 'gmail');
    setInSessionStorage(SOCIAL_SIGNUP_KEY.USERNAME, profileObj.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.GMAIL_ID, profileObj.googleId);
    const data = {
      firstName: profileObj.givenName,
      lastName: profileObj.familyName,
      email: profileObj.email,
      username: profileObj.email,
      loginstrategy: 'gmail',
      gmailId: profileObj.googleId,
    };
    isUserExists(data);
  };

  const errGoogle = response => {
    // Handle Error
    console.log(response);
  };

  const handleSubmit = evt => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        user_Name: userName,
      },
    });
    evt.preventDefault();
    if (!userName || !password) {
      return;
    }
    const data = {
      username: userName,
      password,
      loginstrategy: 'username',
    };
    isUserExists(data);
  };

  const smsLogin = () => {
    const path = `/m-login-phone`;
    // const path = `/m-login-phone-verify`;
    history.push(path);
  };
  const back = () => {
    const path = `/`;
    history.push(path);
  };
  const emailLogin = () => {
    const path = `/m-login-email`;
    history.push(path);
  };
  const emailSignup = () => {
    const path = `/m-signup-email`;
    history.push(path);
  };
  const facebookLogin = () => {
    console.log('facebook loggedin');
  };

  if (customerId && externalToken) {
    isInternationalUserExists({
      customerId,
      externalToken,
      loginstrategy: 'international',
    });
    return (
      <>
        <div className="logo" />
        <div className="landingWrapper">
          <h1>Set Your Lineup, and Game On!</h1>
          <span className="subLine">A Daily Fantasy Sports game changer.</span>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button
                  className="button button-back"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ffScreen__body justify-start">
          <h1 className="title">Welcome back, player</h1>
          <p className="copy">You’ve got some options. Choose wisely.</p>
        </div>
        <div className="ffScreen__footer">
          <button
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.MOBILE_LOGIN, {
                action: 'Log in SMS',
              });
              smsLogin();
            }}
            className="button button-full button-blue">
            Log in with SMS
          </button>
          <button
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.MOBILE_LOGIN, {
                action: 'Log in SMS',
              });
              emailLogin();
            }}
            className="button button-full button-white">
            Log in with Email
          </button>
          <div className="loginOptions ">
            <p className="copy or">Or, continue with</p>
            <div className="buttonGroup">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name, email, picture"
                /* callback={responseFacebook} */
                callback={res => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Facebook Login Response',
                    facebookResponse: responseFacebook,
                  });
                  /* return responseFacebook; */
                  responseFacebook(res);
                }}
                cssClass="loginOptions__item button button-white"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-facebook`} />
                  </svg>
                }
                textButton=""
                scope="email,public_profile"
                disableMobileRedirect
                isMobile={detectDevice()}
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Facebook Login',
                  });
                }}
              />
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={renderProps => (
                  <button
                    className="loginOptions__item button button-white"
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN,
                        {
                          action: 'Google Login',
                        },
                      );
                      renderProps.onClick();
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24">
                      <use xlinkHref={`${icon}#i-google`} />
                    </svg>
                  </button>
                )}
                onSuccess={res => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Google Login Success',
                    successResponse: responseGoogle,
                  });
                  responseGoogle(res);
                }}
                onFailure={err => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Google Login Failure',
                    failureResponse: errGoogle,
                  });
                  errGoogle(err);
                }}
                /* onSuccess={responseGoogle}
                onFailure={errGoogle} */
              />
            </div>
            <p className="copy terms">
              By accessing Daily Number you are agreeing to the&nbsp;
              <a href="/terms-of-service">Terms of Service</a>&nbsp;&&nbsp;
              <a href="/privacy-statement">Privacy Policy.</a>
            </p>
            <p className="copy need">Need an account?</p>
            <button
              className="button button-auto button-sm"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.MOBILE_LOGIN, {
                  action: 'Signup',
                });
                emailSignup();
              }}>
              Sign up
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
