import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const getSport = async () => {
  const url = `${API.SPORT_SLUG_LIST}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};

export const getContest = async () => {
  const url = `${API.CONTEST_LIST}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};

export const getCondensedSport = async () => {
  const url = `${API.SPORT_LIST}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};

export const getContestById = async data => {
  const url = `${API.CONTEST_BY_ID}/${data.id}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};

export const getContestByIds = async data => {
  const url = `${API.CONTEST_BY_IDS}`;
  const body = [data];
  const response = await NetworkManager.requestPOST(url, body);
  return response;
};
