import React, { useState, useEffect, useRef } from 'react';
import 'StyleSheets/css/favorite-team.css';
import { searchTeams } from 'Actions/survey-search-action';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { savePreference } from 'Actions/save-user-preference';
import { ListView } from 'Components/ListView';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';

export const SelectTeam = ({ history }) => {
  const [searchText, setSearchText] = useState('');
  const [TeamList, setTeamList] = useState([]);
  const [selectedTeamList, setSelectedTeamList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const pageno = useRef(1);
  const searchtext = useRef('');
  const teamList = useRef([]);

  const formatTeamData = list => {
    return list.map(listItem => {
      /* eslint no-underscore-dangle: 0 */
      return {
        id: listItem._id,
        title: listItem.fullName,
        subTitle: listItem.sportInfo.name,
        isSelected: selectedTeamList.some(item => item.id === listItem._id),
      };
    });
  };

  const teamSearch = (count = pageno.current) => {
    const text = searchtext.current;
    searchTeams(searchtext.current, count)
      .then(({ data }) => {
        if (
          data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          count === 1 &&
          text === searchtext.current
        ) {
          setTeamList(formatTeamData(data.data));
          setPageNo(count + 1);
        } else if (
          data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          count > 1 &&
          text === searchtext.current
        ) {
          setTeamList([...teamList.current, ...formatTeamData(data.data)]);
          setPageNo(count + 1);
        }
      })
      .catch(resp => {
        if (
          resp.status === APP_CONSTANTS.HTTP_STATUS_CODES.NOT_FOUND &&
          count === 1
        ) {
          setTeamList([]);
          teamList.current = [];
        }
      });
  };

  const loadMoreTeams = () => {
    teamSearch();
  };

  useEffect(() => {
    if (pageno.current !== pageNo) {
      pageno.current = pageNo;
    }
    if (searchtext.current !== searchText) {
      searchtext.current = searchText;
      if (searchText.length > 2) {
        teamSearch(1);
      } else {
        setTeamList([]);
        teamList.current = [];
      }
    }
    if (teamList.current !== TeamList) {
      teamList.current = TeamList;
    }
  }, [searchText, pageNo, teamList]);

  const toggleTeams = item => {
    const temp = TeamList;
    temp.forEach(listItem => {
      if (listItem.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        listItem.isSelected = !listItem.isSelected;
      }
    });
    setTeamList([...temp]);

    let temps = selectedTeamList;
    if (item.isSelected) {
      temps.push(item);
    } else {
      temps = temps.filter(listItem => listItem.id !== item.id);
    }
    setSelectedTeamList([...temps]);
  };

  const getTeamData = () => {
    const data = [];
    selectedTeamList.forEach(team => {
      if (team.isSelected) {
        data.push(team.id);
      }
    });
    return {
      favouriteTeams: data,
    };
  };

  const handleSubmit = () => {
    savePreference(getTeamData())
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          history.push('/select-players');
        }
      })
      .catch(resp => {
        // handle error here.
      });
  };

  const handleTextChange = event => {
    const text = event.target.value;
    setSearchText(text);
  };

  const handleTeamDelete = deleteTeam => {
    setSelectedTeamList(
      selectedTeamList.filter(team => team.id !== deleteTeam.id),
    );
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="40" />
          <div className="d-flex topBar">
            <Link to="/for-you" className="arrowBack" />
            <h2 className="heading">Sign Up</h2>
            <Link
              to="/select-players"
              // eslint-disable-next-line max-len
              className={`topSkip bottomFixed btnTransparent ${searchText.length &&
                'd-none'}`}>
              {BUTTON_LABELS.COMMON.SKIP}
            </Link>
          </div>
        </div>
        <div
          className={`wrapper ${searchText.length && 'favoriteTeamsWrapper'}`}>
          <div className="question info">
            <div className="title">
              {MESSAGE_CONSTANTS.SURVEY.FAVORITE_TEAM}
            </div>
          </div>
          <div className="searchWrapper">
            <input
              type="text"
              name="search"
              value={searchText}
              className="inputSearchBox"
              placeholder={MESSAGE_CONSTANTS.SURVEY.FIND_TEAM}
              onChange={handleTextChange}
              autoComplete="off"
            />
            <div className="searchIcon" />
            <Button className="btnClose" onClick={() => setSearchText('')} />
          </div>
        </div>
        {!searchText.length && selectedTeamList.length ? (
          <div className="yourFavoriteTeams">
            <div className="standings">
              {MESSAGE_CONSTANTS.SURVEY.YOUR_FAVORITES}
            </div>
            <ListView
              arrList={selectedTeamList}
              designType={APP_CONSTANTS.LIST_VIEW_DESIGN.ONE_ICON}
              handleClick={handleTeamDelete}
            />
          </div>
        ) : (
          <div className="teamListingWrapper">
            <ListView
              id="teamList"
              arrList={TeamList}
              designType={APP_CONSTANTS.LIST_VIEW_DESIGN.TWO_ICON}
              handleClick={toggleTeams}
              loadMore={loadMoreTeams}
            />
          </div>
        )}
        <div className="btnTeamWrapper">
          <Button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!selectedTeamList.some(({ isSelected }) => isSelected)}
            label={BUTTON_LABELS.COMMON.NEXT}
          />
        </div>
      </div>
    </>
  );
};
