import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { getCookie } from 'Utils/storage-utilities';
import { TransactionDetails } from './TransactionDetails';

export const TransactionHistory = ({
  transactions,
  pendingWithdrawal,
  cancelWithdrawalAction,
}) => {
  const [expandTransaction, setExpandTransaction] = useState(null);

  const noTransaction = () => {
    return (
      <div className="noTransactionWrap">
        <p>No transactions</p>
      </div>
    );
  };

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const { emailAddress } = userdata;

  const toggleTransactionDetails = transactionId => {
    if (transactionId === expandTransaction) {
      setExpandTransaction(null);
    } else {
      setExpandTransaction(transactionId);
    }
  };

  const renderTransactionItem = (item, key) => {
    const {
      amount: { currencyIsoCode },
      createDate,
    } = item;
    let {
      amount: { amount },
    } = item;
    let spanClass = '';

    if (item.description === APP_CONSTANTS.PRIZE_AWARDED) {
      spanClass = 'green';
      if (currencyIsoCode === MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD) {
        amount = `+ $${Math.abs(amount)}`;
      } else {
        amount = `+ ${Math.abs(amount)}xp`;
      }
    } else {
      amount = `$${Math.abs(amount)}`;
    }

    const description =
      APP_CONSTANTS.TRANSACTION_TITLE_MAPPINGS[item.description] ||
      item.description;
    return (
      <ListGroupItem onClick={() => toggleTransactionDetails(key)} key={key}>
        <div className="transactionEntry">
          <span>{description}</span>
          <span className={spanClass}>
            {currencyIsoCode === MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD ||
            item.description === APP_CONSTANTS.PRIZE_AWARDED
              ? `${amount}`
              : `FREE`}
          </span>
        </div>
        {expandTransaction === key && (
          <TransactionDetails
            amount={item.amount.amount}
            currencyIsoCode={currencyIsoCode}
            createDate={createDate}
            type={description}
            cancelWithdrawalAction={cancelWithdrawalAction}
          />
        )}
      </ListGroupItem>
    );
  };

  const withDrawRequestItem = () => {
    const {
      amount: { amount, currencyIsoCode },
      withdrawalId,
      createDate,
    } = pendingWithdrawal;

    return (
      <ListGroupItem
        onClick={() => toggleTransactionDetails(withdrawalId)}
        key={withdrawalId}>
        <div className="transactionRow">
          <div>
            Cash Out<p>{emailAddress}</p>
          </div>
          <span className="cashoutAmount">
            {currencyIsoCode === MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD
              ? `$${Math.abs(amount)}`
              : `${amount}xp`}
          </span>
        </div>
        {expandTransaction === withdrawalId && (
          <TransactionDetails
            amount={amount}
            currencyIsoCode={currencyIsoCode}
            withdrawalId={withdrawalId}
            createDate={createDate}
            cancelWithdrawalAction={cancelWithdrawalAction}
          />
        )}
      </ListGroupItem>
    );
  };

  return (
    <div>
      <div className="gridSection">
        <div className="gridSection__fullWidth">
          <div className="transactionHistoryTitle">Transaction History</div>
        </div>
      </div>
      <div className="gridSection transactionHistoryListWrap">
        <div className="gridSection__fullWidth">
          <div className="transactionHistoryList">
            <ListGroup>
              {pendingWithdrawal && withDrawRequestItem()}
              {transactions.length !== 0
                ? transactions.map((item, key) =>
                    renderTransactionItem(item, key),
                  )
                : noTransaction()}
            </ListGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

TransactionHistory.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  pendingWithdrawal: PropTypes.objectOf({
    amount: PropTypes.objectOf({
      amount: PropTypes.number.isRequired,
      currencyIsoCode: PropTypes.string.isRequired,
    }).isRequired,
    withdrawalId: PropTypes.string.isRequired,
    createDate: PropTypes.string.isRequired,
  }).isRequired,
  cancelWithdrawalAction: PropTypes.func.isRequired,
};
