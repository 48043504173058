import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'Components/Form-Elements/Button';
import { Input } from 'Components/Form-Elements/Input';
import {
  setCookie,
  getCookie,
  clearSessionStorage,
} from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { logout } from 'Actions/logout-action';
import Branch from 'branch-sdk';
import { CheckUserLogin } from 'Components/Settings/Account/CheckUserLogin';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import defaultImg from '../../../assets/images/defaultImg.png';

export const Account = ({
  history,
  showBack = true,
  onPasswordChange,
  onEmailChange,
}) => {
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const [showUserLogin, setshowUserLogin] = useState(false);
  const { profileContextDispatch } = useContext(ProfileContext);

  useEffect(() => {
    mixpanelTrackPageView('Logout Screen');
  }, []);

  const cleanSession = () => {
    // clear token and other user data if set.
    setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, '');
    setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, '');
    profileContextDispatch({
      type: ACTION_TYPE.PROFILE.USER_IMAGE,
      payload: defaultImg,
    });
    clearSessionStorage();
    Branch.logout();
    history.push('/');
  };

  const logoutMe = () => {
    logout()
      .then(cleanSession)
      .catch(cleanSession);
  };

  const checkAndProceed = changeType => {
    const { type } = userdata;
    if (changeType === 'changeEmail') {
      if (type === 'username') {
        if (typeof onEmailChange === 'function') {
          onEmailChange();
        } else {
          history.push({ pathname: '/change-email' });
        }
      } else {
        setshowUserLogin(true);
      }
    }

    if (changeType === 'changePassword') {
      if (type === 'username') {
        if (typeof onPasswordChange === 'function') {
          onPasswordChange();
        } else {
          history.push({ pathname: '/change-password' });
        }
      } else {
        setshowUserLogin(true);
      }
    }
  };

  return (
    <>
      {showUserLogin && (
        <CheckUserLogin close={() => setshowUserLogin(false)} />
      )}
      <div className="accountSettingsWrapper">
        <div className="pageHeaderWrap">
          <div className="d-flex pageHeader">
            {showBack && (
              /* eslint-disable-next-line */
              <div className="leftSide">
                <Link to="/settings" className="arrowBack" />
              </div>
            )}
            <h2>Account</h2>
            <div className="rightSide" />
          </div>
        </div>
        <div className="accountSettingFormWrap">
          <form className="accountSettingForm" autoComplete="off">
            <div className="formControls d-flex">
              <Input
                inputType="text"
                className="formField flex-grow-1"
                id="emailId"
                value={userdata.emailAddress}
              />
              <Button
                className="btnAccountSettings btnChangeMail"
                label="Change Email"
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.PAGE_VIEWED, {
                    action: 'Change Email',
                    formData: {
                      userMail: userdata.emailAddress,
                    },
                  });
                  checkAndProceed('changeEmail');
                }}
              />
            </div>
            <div className="formControls d-flex">
              <input
                type="password"
                className="formField flex-grow-1"
                id="password"
                defaultValue="abcd12345"
                onKeyDown={e => e.preventDefault()}
              />
              <Button
                className="btnAccountSettings btnChangePassword"
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.PAGE_VIEWED, {
                    action: 'Change Password',
                    formData: {
                      userMail: userdata.emailAddress,
                    },
                  });
                  checkAndProceed('changePassword');
                }}
                label="Change password"
              />
            </div>
          </form>
        </div>
        {detectDevice() && (
          <div className="additionalAccountSettings">
            <div className="buttonsWrap">
              <Button
                className="btnAccountSettings"
                onClick={() => history.push({ pathname: '/terms-of-service' })}
                label="Terms of service"
              />
            </div>
            <div className="buttonsWrap">
              <Button
                className="btnAccountSettings"
                onClick={() => history.push({ pathname: '/privacy-statement' })}
                label="Privacy Statement"
              />
            </div>
          </div>
        )}
        <div className="signOutWrap">
          <Button
            className="btnSignout"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                action: 'Sign Out',
              });
              logoutMe();
            }}
            label="Sign out"
          />
        </div>
      </div>
    </>
  );
};
