import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const requestResetPassword = async data => {
  const url = API.RESET_PASSWORD_LINK;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};

export const resetPassword = async (email, token, password) => {
  const url = API.RESET_PASSWORD;
  const data = {
    email,
    token,
    password,
  };
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};

export const validateResetLink = async body => {
  const url = API.VALIDATE_RESET_LINK;
  const response = await NetworkManager.requestPOST(url, body);
  return response;
};
