export const setInLocalStorage = (key, value) => {
  let data = value;
  try {
    data = typeof value === 'string' ? value : JSON.stringify(value);
  } catch (err) {
    // nothing to do
  }

  localStorage.setItem(key, data);
};

export const getFromLocalStorage = key => {
  let value = localStorage.getItem(key);
  try {
    value = JSON.parse(value);
  } catch (err) {
    // nothing to do
  }

  return value;
};

export const removeFromLocalStorage = key => {
  localStorage.removeItem(key);
};

export const getCookie = key => {
  if (!key || typeof key !== 'string') {
    return '';
  }
  const allCookies = document.cookie.split(';');
  const arr = [];
  allCookies.forEach(cookie => {
    const split = cookie.split('=').map(data => data.trim());
    if (split[0] === key) {
      arr.push(split[1] ? JSON.parse(split[1]) : '');
    }
  });
  return arr[0] || '';
};

export const setCookie = (key, value) => {
  const cookie = `${key.toString()}=${JSON.stringify(value)}`;
  document.cookie = cookie;
};

export const removeCookie = key => {
  if (!key) {
    return;
  }
  // setting previous time to expires will delete cookie.
  document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const setInSessionStorage = (key, value) => {
  let data = value;
  try {
    data = typeof value === 'string' ? value : JSON.stringify(value);
  } catch (err) {
    // nothing to do
  }

  sessionStorage.setItem(key, data);
};

export const getFromSessionStorage = key => {
  let value = sessionStorage.getItem(key);
  try {
    value = JSON.parse(value);
  } catch (err) {
    // nothing to do
  }

  return value;
};

export const removeFromSessionStorage = key => {
  sessionStorage.removeItem(key);
};

export const clearSessionStorage = () => sessionStorage.clear();
