import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'Components/Form-Elements/Input';
import { Button } from 'Components/Form-Elements/Button';
import { format } from 'react-phone-input-auto-format';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const Phone = ({ onChange, value, externalError }) => {
  const formatNumber = newValue => {
    if (newValue.length <= APP_CONSTANTS.TEXT_LENGTH.FOURTEEN) {
      onChange(format(newValue));
    }
  };

  return (
    <div className="phoneGroup">
      <div className="countryWrap">
        <select className="country">
          <option value="">United States</option>
        </select>
      </div>
      <div className="input-group">
        <div className="input-group-prepend">
          <div className="input-group-text">+1</div>
        </div>
        <Input
          inputType="tel"
          className="form-control"
          id="phone-number"
          placeholder="Username"
          handleChange={formatNumber}
          value={value}
          externalError={externalError}
        />
        <Button
          type="button"
          className="inputClear"
          onClick={() => {
            onChange('');
          }}
        />
      </div>
    </div>
  );
};

Phone.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  externalError: PropTypes.string.isRequired,
};
