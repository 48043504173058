/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Route, Redirect } from 'react-router';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
  updateDob,
  getDraftUser,
  getExternalUser,
  checkExternalUserWithSystem,
} from 'Actions/new-auth-action';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import Branch from 'branch-sdk';

export const VerifyEmailFD = ({ history }) => {
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const { profileContextDispatch } = useContext(ProfileContext);
  const [userName, setUserName] = useState('');
  const [externalUser, setExternalUser] = useState({});

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const method = url.searchParams.get('method');
  const token = url.searchParams.get('token');
  const email = url.searchParams.get('email');

  const openSignupPhone = () => {
    const path = `/m-signup-phone`;
    history.push(path, { email });
  };

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  useEffect(() => {
    const data = {
      token,
    };
    getExternalUser(data)
      .then(res => {
        const { status, data, statusText } = res;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          setExternalUser(data);
          checkExternalUserWithSystem({
            email: data.email,
          })
            .then(res => {
              const { code, message, data1, status } = res.data;
              if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
                const path = `/m-login-email-sent`;
                history.push(path, {
                  email: data.email,
                });
              } else if (status === APP_CONSTANTS.HTTP_STATUS_CODES.CREATED) {
                const path = `/m-signup-phone`;
                history.push(path, {
                  email: data.email,
                  phoneNumber: data.phoneNumber,
                  dob: data.birthdate,
                  successMsg:
                    MESSAGE_CONSTANTS.AUTHENTICATION.EMAIL_VERIFIED_SIGNUP,
                });
              }
            })
            .catch(error => {
              const { status, code } = error.response.data;
            });
        }
      })
      .catch(error => {
        const { status, statusText } = error.response;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NOT_FOUND) {
          const path = `/m-signup-email`;
          history.push(path, {
            apiError:
              'User Not Found with the External Token. Please Try Again',
          });
        }
      });
  }, []);

  return (
    <>
      <div />
    </>
  );
};
