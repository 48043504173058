import React, { useState } from 'react';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { maskCardNumber, getPaymentMethodIconClass } from 'Utils/data-utils';
import { processPayment } from 'Utils/kyc-utils';
import { getFromSessionStorage, getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import '../Loader/Loader.css';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ConfirmPaymentScreen = ({
  history,
  fetchAndSetModalPage,
  isModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const item = getFromSessionStorage('active-card');
  const userCoords = getFromSessionStorage('userCoords');
  const cardPublicId = getFromSessionStorage('card-public-id');
  const dollar = getFromSessionStorage('selected-amount');
  const bal = getFromSessionStorage('WalletBal');
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  const addFunds = () => {
    setIsLoading(true);
    const locationObj = {
      latitude: userCoords.lat,
      longitude: userCoords.long,
      horizontalAccuracy: userCoords.accuracy,
      timestamp: new Date().getTime(),
    };

    const createDepositObj = [
      cardPublicId,
      dollar,
      MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD,
      locationObj,
      userdata.appInstallId,
    ];
    return processPayment(
      { fetchAndSetModalPage, isModal, history },
      locationObj,
      createDepositObj,
    );
  };

  return (
    <>
      <div className="d-flex popUpHeader">
        <div className="leftSide">
          {!isLoading && (
            <Button
              className="arrowBack btn btnLink"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                  action: 'Back Arrow',
                });
                fetchAndSetModalPage('/add-funds');
              }}
            />
          )}
        </div>
        <h2>{MESSAGE_CONSTANTS.WALLET.ADD_FUNDS.HEADING}</h2>
        <div className="walletAmountBtn rightSide" />
      </div>
      {!isLoading ? (
        <div className="modalBody wrapper">
          <div className="walletHeadingWrap">
            <div className="centerText">
              <strong>Add ${dollar} your wallet?</strong>
            </div>
            <div className="availableBalance centerText">
              <span>{`$${bal} available in Wallet`}</span>
              <div className="questionMarkInfo">
                <span className="questionIcon" />
                <div className="aboutQuestionInfo">
                  <p>
                    {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                    <a href="/terms-of-service"> terms and conditions</a>. *Some
                    exclusions apply.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="formToWrap">
            <div className="listWithIcons">
              <div className="textFromTo">FROM</div>
              <ul className="border">
                <li className="d-flex" key={item.payment_method_identifier}>
                  <button
                    type="button"
                    tag="button"
                    className="d-flex align-items-center">
                    <div
                      className={`listIcon ${getPaymentMethodIconClass(item)}`}
                    />
                    <div className="infoWrap flex-grow-1">
                      <div className="title">
                        {item.card_brand ? item.card_brand : item.description}
                      </div>
                      <div className="info font-sm">
                        <span>{item.account_type}</span>
                        <span>{maskCardNumber(item.number)}</span>
                        <span>
                          {item.expiration_date
                            ? `Expires ${item.expiration_date}`
                            : ''}
                        </span>
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
            </div>

            <div className="listWithIcons">
              <div className="textFromTo">TO</div>
              <ul className="border">
                <li className="d-flex" key="add-card">
                  <button className="d-flex align-items-center" type="button">
                    <div className="iconDollor">$</div>
                    <div className="title"> Your Daily Number Wallet</div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <Button
            className="btn btnGrren scrollBtn"
            type="button"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                action: 'Confirm Transfer',
              });
              addFunds();
            }}
            label="Confirm Transfer"
          />
        </div>
      ) : (
        <div id="cover-spin" />
      )}
    </>
  );
};
