import moment from 'moment';

export const isDate = date => {
  const d = new Date(date);
  if (Object.prototype.toString.call(d) === '[object Date]') {
    if (Number.isNaN(d.getTime())) {
      return false;
    }
    return true;
  }
  return false;
};

export const scheduledDate = date => {
  return date ? moment(date).format('M/D') : '';
};

export const scheduledTime = date => {
  return date ? moment(date).format('h:mmA') : '';
};

export const isValidDate = (year, month, day) => {
  const d = new Date(year, month, day);
  return (
    isDate(d) &&
    d.getFullYear() === Number(year) &&
    d.getMonth() === Number(month) &&
    d.getDate() === Number(day)
  );
};

export const dob = value => {
  const parts = value.split('/');
  return `${parts[1]}/${parts[0]}/${parts[2]}`;
};

export const getTime = date => {
  return date
    ? moment(date)
        .format('ddd h:mmA')
        .toUpperCase()
    : '';
};

export const formatDateTime = date => {
  const systemTimeZone = 'PST';
  return date
    ? `${moment(date).format('MMM D, h:mmA z')} ${systemTimeZone}`
    : '';
};

export const formatDateMonthDate = date => {
  return date ? moment(date).format('MMM DD') : '';
};

export const formatDateMMDD = date => {
  return date ? moment(date).format('M/D') : '';
};

export const formatDateHHmm = date => {
  return date ? moment(date).format('h:mmA') : '';
};

export const formatDateHHmmDay = date => {
  return date ? moment(date).format('h:mmA ddd') : '';
};

export const formatDatellll = date => {
  return date ? moment(date).format('llll') : '';
};
export const timeUntil = date => {
  if (!date) {
    return '';
  }
  const now = moment();
  const diff = moment(date).diff(now);
  const dur = moment.duration(diff);
  /* eslint no-underscore-dangle: 0 */
  const { days, hours, minutes } = dur._data;
  const dayString = days > 0 ? `${days}d` : '';
  return `${dayString} ${hours}h ${minutes}m`;
};

const addZeros = (h, m, s) => {
  return `${`0${h}`.substr(-2)}:${`0${m}`.substr(-2)}:${`0${s}`.substr(-2)}`;
};
export const timeUntilInHMS = date => {
  if (!date) {
    return '';
  }
  const now = moment();
  const diff = moment(date).diff(now);
  const dur = moment.duration(diff);
  /* eslint no-underscore-dangle: 0 */
  const { days, hours, minutes, seconds } = dur._data;
  const totalHours = days > 0 ? 24 * days : 0;
  return addZeros(totalHours + hours, minutes, seconds);
};

export const formatAndDateAdd = (date, noOfDays) => {
  return date
    ? moment(date)
        .add(noOfDays, 'days')
        .format('ll')
    : '';
};

export const formatDatell = date => {
  return date ? moment(date).format('ll') : '';
};

export const getTimeDiff = date => {
  if (!date) {
    return '';
  }
  const now = moment();
  const diff = moment(date).diff(now);
  const dur = moment.duration(diff);
  /* eslint no-underscore-dangle: 0 */
  const { days, hours, minutes } = dur._data;
  return { days, hours, minutes };
};

export const gameTime = date => {
  return date
    ? moment(date)
        .format('ddd h:mmA')
        .toUpperCase()
    : '';
};

export const now = () => {
  return moment();
};

export const compareTime = (from, to) => {
  const fromTime = moment(from);
  const toTime = moment(to);
  return fromTime.isBefore(toTime);
};
