export const ACTION_TYPE = {
  LINEUP: {
    SET_ALLOW_PAID: 'SET_ALLOW_PAID',
    SET_MODAL_BOX_NAME: 'SET_MODAL_BOX_NAME',
    SET_TOGGLE_MODAL_BOX: 'SET_TOGGLE_MODAL_BOX',
    SET_SELECTED_ENTRY_FEE: 'SET_SELECTED_ENTRY_FEE',
    SET_ENTRY_FEE: 'SET_ENTRY_FEE',
    SET_AGE_ERROR: 'SET_AGE_ERROR',
    SET_WIN_AMOUNT_STR: 'SET_WIN_AMOUNT_STR',
    SET_CONTEST_DATA: 'SET_CONTEST_DATA',
    SET_SPORT_DATA: 'SET_SPORT_DATA',
    SET_LINE_UP: 'SET_LINE_UP',
    SET_EDIT_LINE_UP_RAW_DATA: 'SET_EDIT_LINE_UP_RAW_DATA',
    SET_ACTIVE_LINEUP_EDIT: 'SET_ACTIVE_LINEUP_EDIT',
    SET_ACTIVE_CONTEST_ID: 'SET_ACTIVE_CONTEST_ID',
    SET_ACTIVE_CONTEST_DATA: 'SET_ACTIVE_CONTEST_DATA',
    TOGGLE_LOADER: 'TOGGLE_LOADER',
    SET_LINEUP_STRENGTH: 'SET_LINEUP_STRENGTH',
    SET_BUTTON_ENABLE: 'SET_BUTTON_ENABLE',
    SET_RANGE_VALUE: 'SET_RANGE_VALUE',
    ENABLE_WIN_SCREEN: 'ENABLE_WIN_SCREEN',
    SET_ACTIVE_POSITION: 'SET_ACTIVE_POSITION',
    SET_RANGE_DATA: 'SET_RANGE_DATA',
    SET_FINAL_WIN: 'SET_FINAL_WIN',
    SET_LOCATION_VERIFIED: 'SET_LOCATION_VERIFIED',
    SET_BALANCE: 'SET_BALANCE',
    SET_TO_INITIAL_STATE: 'SET_TO_INITIAL_STATE',
    SET_TO_EDIT_LINEUP_ID: 'SET_TO_EDIT_LINEUP',
    SET_TO_EDIT_LINEUP_ENTRY_DATA: 'SET_TO_EDIT_LINEUP_ENTRY_DATA',
    SET_TO_DATA_PLAYERS: 'SET_TO_DATA_PLAYERS',
    SET_RAW_PLAYERS: 'SET_RAW_PLAYERS',
    SET_DRAFT_CREATED: 'SET_DRAFT_CREATED',
    TOGGLE_INITAL_DATA: 'TOGGLE_INITAL_DATA',
    RESET_RANGE_SLIDER_USED_CHECK: 'RESET_RANGE_SLIDER_USED_CHECK',
  },
  PROFILE: {
    USER_IMAGE: 'USER_IMAGE',
    SET_USER_LOGGED_IN: 'SET_USER_LOGGED_IN',
    SET_USER_MAIL: 'SET_USER_MAIL',
    SET_SUB_TAB: 'SET_SUB_TAB',
    SET_ACTIVE_LINEUP_EDIT: 'SET_ACTIVE_LINEUP_EDIT',
  },
};
