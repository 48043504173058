const getArray = (data, avgPts, avgDailyNumber) => {
  const series1 = {
    data,
    lineWidth: 3,
    color: 'rgb(251,214,3)',
    states: {
      inactive: {
        opacity: 1,
      },
    },
  };
  if (!avgPts) {
    return [series1];
  }
  return [
    series1,
    {
      data: data.map(() => ({
        y: avgPts,
      })),
      dashStyle: 'shortdot',
      color: 'rgb(151, 151, 151)',
      enableMouseTracking: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
    {
      data: data.map(() => ({
        y: avgDailyNumber,
      })),
      dashStyle: 'solid',
      color: 'rgb(0, 100, 251)',
      enableMouseTracking: false,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  ];
};

export const getLineChartDetails = (
  data,
  avgPts,
  avgDailyNumber,
  changeStat,
) => {
  const object = {
    xAxis: {
      categories: [],
      labels: {
        enabled: false,
      },
      title: {
        style: {
          color: 'rgb(148,148,148)',
          fontSize: '11px',
        },
        margin: 19,
        text: 'GAMES',
      },
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineWidth: 0,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.firstText}<br/>{point.secondText}',
    },
    series: getArray(data, avgPts, avgDailyNumber),
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
        cursor: 'pointer',
        point: {
          events: {
            click: ({ point: { options } }) => {
              if (options) {
                changeStat(options);
              }
            },
          },
        },
      },
    },
  };
  return { graphObj: object, avgPts, avgDailyNumber };
};
