/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { getMultiPlayerDetails } from 'Actions/player-details-action';
import { Contest } from 'Components/Profile/ProfileScreen';
import { GameCardDetails } from 'Components/LineUpScreens/PlayerDetails/GameCard';
import { detectDevice } from 'Utils/device-utils';
import { Button } from 'Components/Form-Elements/Button';
import icon from '../../../assets/icons/icons.svg';

const POLL_INTERVAL = 15000;

export const ContestDetailView = ({
  history,
  key,
  status,
  data,
  getStatDisplay,
  isActive,
  playerGames,
  games,
  teams,
  showStats,
  activeTab,
  contestUp,
  playerDetails,
  setShowEditLineupModal,
  setLineupModalLocation,
  setLineupModalHistory,
  setLineupModalSetter,
  setShowContestDetails,
}) => {
  const [multiGameDetails, setMultiGameDetails] = useState([]);
  const [startPolling, setStartPolling] = useState(false);
  const sportSlug = data.sportSlug.toString().toUpperCase();
  const DataPlayerGamesLive = [];
  const DataPlayerGames = [];
  const DataPlayers = [];
  const isMobile = detectDevice();

  data.roster.forEach(playerDetails => {
    playerGames.forEach(playerData => {
      if (playerData._id === playerDetails.playerGameId._str) {
        if (playerData.gameStatus === 'InProgress' && !playerData.finished) {
          DataPlayerGamesLive.push(playerDetails.playerGameId._str);
        } else if (!DataPlayerGames[playerData.gameId._str]) {
          DataPlayerGames[playerData.gameId._str] = playerData;
        }

        // prepare player details for each games
        // for player name and avatar usage purpose if required
        if (!DataPlayers[playerData.gameId._str]) {
          DataPlayers[playerData.gameId._str] = [];
        }
        DataPlayers[playerData.gameId._str].push(playerDetails.player);
      }
    });
  });
  DataPlayerGames.sort(playerData =>
    playerData.events && playerData.events.length > 0 ? 1 : 0,
  );

  const fetchMultiGameDetails = () => {
    if (DataPlayerGamesLive.length > 0) {
      getMultiPlayerDetails(DataPlayerGamesLive).then(({ data }) => {
        if (data.games && data.games.length) {
          setMultiGameDetails(data);
          // live Tab Data
          if (
            data.playerGames &&
            data.playerGames.length &&
            data.playerGames.some(
              item => item.gameId._str === data.games[0]._id,
            )
          ) {
            const liveGame = data.playerGames.filter(
              playGame => playGame.gameId._str === data.games[0]._id,
            );
            if (
              liveGame.length &&
              data.games[0].status === 'InProgress' &&
              !data.games[0].finished
            ) {
              if (!startPolling) {
                setStartPolling(true);
              }
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    let timer;
    if (startPolling) {
      timer = setInterval(() => {
        fetchMultiGameDetails();
      }, POLL_INTERVAL);
    }

    return () => {
      clearInterval(timer);
    };
  }, [startPolling]);

  useEffect(() => {
    fetchMultiGameDetails();
  }, []);

  if (!isMobile) {
    return (
      <div className="nodal open" id="target_id">
        <div className="nodal__dialog">
          <div className="nodal__overlay" data-dismiss />
          <div className="nodal__content isLineupDetailsPageDesktop">
            <div className="isLineupDetailsPage">
              <header className="topbar">
                <h1 className="topbar__title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-${data.sportSlug}`} />
                  </svg>
                  <span>
                    {sportSlug} • {data.title.substr(4)}
                  </span>
                </h1>
                <div className="topbar__leftAct">
                  <Button
                    className="button"
                    label="Close"
                    onClick={() => setShowContestDetails()}
                  />
                </div>
                <div className="topbar__rightAct">
                  <button type="button" className="button" data-dismiss="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24">
                      <use xlinkHref={`${icon}#i-upload`} />
                    </svg>
                  </button>
                </div>
              </header>
              <Contest
                history={history}
                key={key}
                status={status}
                data={data}
                playerGames={playerGames}
                games={games}
                getStatDisplay={getStatDisplay}
                isActive={isActive}
                showStats={showStats}
                activeTab={activeTab}
                contestUp={contestUp}
                playerDetails={playerDetails}
                setShowEditLineupModal={setShowEditLineupModal}
                setLineupModalLocation={setLineupModalLocation}
                setLineupModalHistory={setLineupModalHistory}
                setLineupModalSetter={setLineupModalSetter}
              />
              {multiGameDetails
                ? multiGameDetails.games &&
                  multiGameDetails.games.map(game => {
                    const awayTeam = multiGameDetails.teams.filter(
                      team => game.awayTeamId._str === team._id,
                    );
                    const homeTeam = multiGameDetails.teams.filter(
                      team => game.homeTeamId._str === team._id,
                    );
                    const player = multiGameDetails.playerGames.filter(
                      player => game._id === player.gameId._str,
                    );
                    return (
                      <GameCardDetails
                        key={game._id}
                        player={player[0]}
                        awayTeam={awayTeam[0]}
                        games={game}
                        homeTeam={homeTeam[0]}
                        DataPlayers={DataPlayers}
                        detailView
                      />
                    );
                  })
                : ''}
              {DataPlayerGames &&
                Object.entries(DataPlayerGames).map(([gameId, playerGame]) => {
                  const gameFltr = games.filter(
                    game => playerGame.gameId._str === game._id,
                  );
                  const game = gameFltr[0];

                  const awayTeam = teams.filter(
                    team => game.awayTeamId._str === team._id,
                  );
                  const homeTeam = teams.filter(
                    team => game.homeTeamId._str === team._id,
                  );
                  return (
                    <GameCardDetails
                      key={game._id}
                      player={playerGame}
                      awayTeam={awayTeam[0]}
                      games={game}
                      homeTeam={homeTeam[0]}
                      DataPlayers={DataPlayers}
                      detailView
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <main className="App__main main2">
      <div className="isLineupDetailsPage">
        <header className="topbar">
          <h1 className="topbar__title">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <use xlinkHref={`${icon}#i-${data.sportSlug}`} />
            </svg>
            <span>
              {sportSlug} • ${data.title.substr(4)}
            </span>
          </h1>
          <div className="topbar__leftAct">
            <Button
              className="button"
              label="Close"
              onClick={() => setShowContestDetails()}
            />
          </div>
          <div className="topbar__rightAct">
            <button type="button" className="button" data-dismiss="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <use xlinkHref={`${icon}#i-upload`} />
              </svg>
            </button>
          </div>
        </header>
        <Contest
          history={history}
          key={key}
          status={status}
          data={data}
          playerGames={playerGames}
          games={games}
          getStatDisplay={getStatDisplay}
          isActive={isActive}
          showStats={showStats}
          activeTab={activeTab}
          contestUp={contestUp}
          playerDetails={playerDetails}
          setShowEditLineupModal={setShowEditLineupModal}
          setLineupModalLocation={setLineupModalLocation}
          setLineupModalHistory={setLineupModalHistory}
          setLineupModalSetter={setLineupModalSetter}
        />
        {multiGameDetails
          ? multiGameDetails.games &&
            multiGameDetails.games.map(game => {
              const awayTeam = multiGameDetails.teams.filter(
                team => game.awayTeamId._str === team._id,
              );
              const homeTeam = multiGameDetails.teams.filter(
                team => game.homeTeamId._str === team._id,
              );
              const player = multiGameDetails.playerGames.filter(
                player => game._id === player.gameId._str,
              );
              return (
                <GameCardDetails
                  key={game._id}
                  player={player[0]}
                  awayTeam={awayTeam[0]}
                  games={game}
                  homeTeam={homeTeam[0]}
                  DataPlayers={DataPlayers}
                  detailView
                />
              );
            })
          : ''}
        {DataPlayerGames &&
          Object.entries(DataPlayerGames).map(([gameId, playerGame]) => {
            const gameFltr = games.filter(
              game => playerGame.gameId._str === game._id,
            );
            const game = gameFltr[0];

            const awayTeam = teams.filter(
              team => game.awayTeamId._str === team._id,
            );
            const homeTeam = teams.filter(
              team => game.homeTeamId._str === team._id,
            );
            return (
              <GameCardDetails
                key={game._id}
                player={playerGame}
                awayTeam={awayTeam[0]}
                games={game}
                homeTeam={homeTeam[0]}
                DataPlayers={DataPlayers}
                detailView
              />
            );
          })}
      </div>
    </main>
  );
};
