/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { detectDevice } from 'Utils/device-utils';
import { getMyContests } from 'Actions/contest-action';
import { getContestByIds } from 'Actions/sport-action';
import { Image } from 'Components/Form-Elements/Image';
import { PlayerDetails } from 'Components/LineUpScreens/PlayerDetails';
import { ContestDetailView } from 'Components/Profile/ProfileScreen/ContestDetailView';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { getNotifications } from 'Actions/user-notification-action';
import { LineUpModal } from 'Components/LineUpModalFlow';
import { NewGame } from 'Components/NewGame';
import { Button } from 'Components/Form-Elements/Button';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { Contest } from 'Components/Profile/ProfileScreen';
import { getContestStart } from 'Components/Profile/ProfileScreen/lineup-helper';
import { removeFromSessionStorage } from 'Utils/storage-utilities';
import icon from '../../assets/icons/icons.svg';
import gameEntryImage from '../../assets/images/freeEntryImage.png';
import howToPlay from '../../assets/images/howtoplay.jpg';
import { MESSAGE_CONSTANTS } from '../../constants/message-constants';
import { APP_CONSTANTS } from '../../constants/app-constants';
import 'StyleSheets/components/grid-view.css';

export const ForYou = ({ history, location }) => {
  const [contests, setContests] = useState([]);
  const { ACTIVE, DRAFT, UPCOMING, COMPLETED } = APP_CONSTANTS.LINEUPS_TABS;
  const [activeSubTab, setActiveSubTab] = useState('');
  const [playerGames, setPlayerGames] = useState([]);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const POLL_INTERVAL = 15000;
  const [showPlayerDetails, setshowPlayerDetails] = useState();
  const [showContestDetails, setShowContestDetails] = useState();
  const [contestUp, setUpContests] = useState([]);
  const [unReadNotifsCount, setUnreadNotifsCount] = useState(0);
  const [showLineupModal, setShowLineupModal] = useState(false);
  const [toggleDesign, setToggleDesign] = useState(false);
  const [showEditLineupModal, setShowEditLineupModal] = useState(false);
  const [showDraftLineupModal, setShowDraftLineupModal] = useState(false);
  const [lineupModalLocation, setLineupModalLocation] = useState({});
  const [lineupModalHistory, setLineupModalHistory] = useState({});
  const [lineupModalSetter, setLineupModalSetter] = useState(() => {});
  const [activeContests, setActiveContast] = useState([]);
  const isMobile = detectDevice();
  const isActive = activeSubTab === ACTIVE;
  const isUpcoming = activeSubTab === UPCOMING;
  const status = {
    [ACTIVE]: () => 'XXX MIN REMAINING',
    [DRAFT]: () => 'DRAFT',
    [UPCOMING]: getContestStart,
    [COMPLETED]: () => 'FINAL',
  };
  useEffect(() => {
    getNotifications(APP_CONSTANTS.GET_NOTIFICATION_COUNT)
      .then(({ data }) => {
        setUnreadNotifsCount(data.unreadCount);
      })
      .catch(err => {});
    removeFromSessionStorage('lineupTab');
  }, []);

  useEffect(() => {
    if (showPlayerDetails) {
      document.body.classList.add('playerDetailsView');
    } else {
      document.body.classList.remove('playerDetailsView');
    }
  }, [showPlayerDetails]);

  useEffect(() => {
    setActiveSubTab(ACTIVE);
    if (showContestDetails) {
      document.body.classList.add('contestDetailView');
    } else {
      document.body.classList.remove('contestDetailView');
    }
  }, [showContestDetails]);
  useEffect(() => {
    getMyContests(APP_CONSTANTS.LINEUPS_TABS.ACTIVE)
      .then(({ data: { entrants, playerGames, games, teams } }) => {
        setContests(entrants || []);
        setPlayerGames(playerGames || []);
        setGames(games || []);
        setTeams(teams || []);
        if (entrants.length) {
          const contestIDs = [];
          entrants.forEach(element => {
            contestIDs.push(element.contestId._str);
          });
          getContestByIds(contestIDs).then(({ data }) => {
            setActiveContast(data || []);
          });
        }
      })
      .catch(e => {
        setContests([]);
        setPlayerGames([]);
        setGames([]);
        setTeams([]);
      });
    mixpanelTrackPageView('Home Screen');

    const live = setInterval(() => {
      getMyContests(APP_CONSTANTS.LINEUPS_TABS.ACTIVE)
        .then(({ data: { entrants, playerGames, games, teams } }) => {
          setContests(entrants || []);
          setPlayerGames(playerGames || []);
          setGames(games || []);
          setTeams(teams || []);
          if (entrants.length) {
            const contestIDs = [];
            entrants.forEach(element => {
              contestIDs.push(element.contestId._str);
            });
            getContestByIds(contestIDs).then(({ data }) => {
              setActiveContast(data || []);
            });
          }
          if (!entrants) {
            clearInterval(live);
          }
        })
        .catch(e => {
          setContests([]);
          setPlayerGames([]);
          setGames([]);
          setTeams([]);
        });
    }, POLL_INTERVAL);

    return () => {
      clearInterval(live);
    };
  }, []);

  const toggleRoutes = () => {
    return isMobile ? setToggleDesign(!toggleDesign) : setShowLineupModal(true);
  };

  /* eslint no-underscore-dangle: 0 */
  function getStatDisplay(playerGameId) {
    const pg = playerGames.find(pg => pg._id === playerGameId._str);
    return pg && pg.totalFP;
  }

  if (showPlayerDetails) {
    return (
      <PlayerDetails
        player={showPlayerDetails}
        getToList={setshowPlayerDetails}
        className="profileFullPage"
      />
    );
  }
  if (showContestDetails) {
    return (
      <ContestDetailView
        history={history}
        key={showContestDetails._id}
        status={status[activeSubTab](showContestDetails)}
        data={showContestDetails}
        playerGames={playerGames}
        games={games}
        teams={teams}
        getStatDisplay={getStatDisplay}
        isActive={isActive}
        showStats={!isUpcoming}
        activeTab={activeSubTab}
        contestUp={contestUp}
        playerDetails={({ playerGameId, player, position }, details) => {
          setshowPlayerDetails({
            playerGame: {
              _id: playerGameId._str,
              name: player.name,
              position,
            },
            name: player.name,
            strength: showContestDetails.lineupStrength,
            picture: player.picture,
            liveGameDetails: details,
          });
        }}
        setShowContestDetails={setShowContestDetails}
        setShowEditLineupModal={setShowEditLineupModal}
        setLineupModalLocation={setLineupModalLocation}
        setLineupModalSetter={setLineupModalSetter}
        setLineupModalHistory={setLineupModalHistory}
      />
    );
  }
  return (
    <>
      <main className="App__main">
        {toggleDesign && (
          <div className="selectGuard selectGame">
            <div className="drawerClose">
              <span />
            </div>
            <div className="listOuter ">
              <div className="guardTitle">
                <Button
                  type="button"
                  className="closeGuard"
                  onClick={() => setToggleDesign(false)}
                />
                New DN Lineup
              </div>
              <NewGame />
            </div>
          </div>
        )}
        <div className="headSection isForyouPage">
          <div className="headSection__contain">
            {!isMobile ? (
              <h2 className="headSection__title">
                {process.env.REACT_APP_INTERNATIONAL_ENABLED === 'true'
                  ? MESSAGE_CONSTANTS.FOR_YOU.HEADING_INTL
                  : MESSAGE_CONSTANTS.FOR_YOU.HEADING}
              </h2>
            ) : (
              ''
            )}
            {/* <div className="headSection__rightAct">icon</div> */}
          </div>
        </div>
        <div className="foryouSection">
          <div className="foryouSection__contain">
            <h1 className="foryouSection__title">
              {process.env.REACT_APP_INTERNATIONAL_ENABLED === 'true'
                ? MESSAGE_CONSTANTS.FOR_YOU.HEADING_INTL
                : MESSAGE_CONSTANTS.FOR_YOU.HEADING}
            </h1>
          </div>
        </div>
        {contests.length ? (
          <div className="subHeadSection">
            <div className="subHeadSection__contain">
              <h2 className="subHeadSection__title">
                {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
                  <span>Active Lineups</span>
                ) : (
                  ''
                )}
              </h2>
              <div
                className="subHeadSection__rightAct"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.TAP_FOR_YOU__ALL_EVENTS,
                    {
                      action: 'See All',
                    },
                  );
                }}>
                <Link className="button" to="/profile">
                  See all
                </Link>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        {!contests.length ? (
          <div className="gridSection">
            <div className="gridSection__fullWidth">
              <div className="emptyGame">
                <div className="emptyGame__content">
                  <p>You have no active games</p>
                  <Button
                    className="button"
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.PROFILE_PAGE,
                        {
                          action: 'Start a new game',
                        },
                      );
                      toggleRoutes();
                    }}
                    label="Start a new game"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="horizontalScroll">
          <div className="gridSection">
            <div className="gridSection__contain">
              <div className="gridSection__list">
                {contests.map(contest => (
                  <Contest
                    history={history}
                    key={contest._id}
                    status={() => 'XXX MIN REMAINING'}
                    data={contest}
                    playerGames={playerGames}
                    games={games}
                    activeContests={activeContests}
                    getStatDisplay={getStatDisplay}
                    isActive
                    showStats
                    activeTab={APP_CONSTANTS.LINEUPS_TABS.ACTIVE}
                    playerDetails={(
                      { playerGameId, player, position },
                      details,
                    ) => {
                      setshowPlayerDetails({
                        playerGame: {
                          _id: playerGameId._str,
                          name: player.name,
                          position,
                        },
                        name: player.name,
                        strength: contest.lineupStrength,
                        picture: player.picture,
                        liveGameDetails: details,
                      });
                    }}
                    setShowEditLineupModal={setShowEditLineupModal}
                    setLineupModalLocation={setLineupModalLocation}
                    setLineupModalHistory={setLineupModalHistory}
                    setLineupModalSetter={setLineupModalSetter}
                    setShowContestDetails={setShowContestDetails}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="gridSection">
          <div className="gridSection__fullWidth">
            <Link
              className="button"
              to="/how-to-play"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.HOW_TO_PLAY, {
                  action: 'How To Play',
                });
              }}>
              <img src={howToPlay} alt="How to Play" />
            </Link>
          </div>
        </div>
        <button
          type="button"
          className="button button-addNewGame"
          onClick={() => {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ADD_NEW_GAME, {
              action: 'Add New Game',
            });
            toggleRoutes();
          }}
          data-modal="lineup_draft_selection">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <use xlinkHref={`${icon}#i-plus`} />
          </svg>
        </button>
      </main>
      {showLineupModal && (
        <LineUpModal
          showLineupModal={showLineupModal}
          setShowLineupModal={setShowLineupModal}
          history={history}
          location={location}
        />
      )}
      {showEditLineupModal && lineupModalLocation && (
        <LineUpModal
          showLineupModal={showEditLineupModal}
          setShowLineupModal={setShowEditLineupModal}
          history={lineupModalHistory}
          location={lineupModalLocation}
          status="EDIT_LINEUP"
        />
      )}
    </>
  );
};
