import React from 'react';
import './Loader.css';

export const Loader = () => {
  return (
    <div className="backdrop">
      <div id="cover-spin" />
    </div>
  );
};
