import React, { useState, useEffect } from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Button } from 'Components/Form-Elements/Button';
import { LinkShareModal } from 'Components/ReferFriend/LinkShareModal';
import { getCookie } from 'Utils/storage-utilities';
import referFriends from '../../assets/images/referFriend.png';

export const ReferFriend = ({ history, location }) => {
  const [showModal, setShowModal] = useState(false);
  const userData = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  const checkForGuestUser = setModal => {
    if (!userData) {
      history.push('/location');
      return;
    }
    setShowModal(setModal);
  };

  return (
    <>
      {showModal && <LinkShareModal close={() => setShowModal(false)} />}
      <div className="referWrapper d-flex">
        <div className="topBar d-flex referHeader">
          <div className="title flex-grow-1">Refer a Friend</div>
        </div>
        <div className="referFriendContent">
          <div className="imageWrap">
            <img src={referFriends} alt="refer-friends" />
          </div>
          <p>
            Refer a friend and both of you receive $10 when they play their
            first game!
          </p>
          <Button
            type="button"
            className={`referUrl ${!userData && 'hide'}`}
            label={userData.referralLink}
            onClick={() => checkForGuestUser(true)}
          />
        </div>
        <div className="inviteFriendWrapper">
          <p>
            Add your contacts and invite your friends (if you have any) to Daily
            Number
          </p>
          <Button
            label="Invite Friends"
            className="btn btn-primary scrollBtn"
            onClick={() => checkForGuestUser(true)}
          />
        </div>
      </div>
    </>
  );
};
