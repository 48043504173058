/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Button } from 'Components/Form-Elements/Button';
import { mixpanelTrackPageView } from 'Utils/mix-panel';
import {
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { detectDevice } from 'Utils/device-utils';

export const MessageScreen = ({
  history,
  fetchAndSetModalPage,
  closeModalBox,
  updateUserBal,
  isModal,
}) => {
  const message = getFromSessionStorage('message');
  const screen = getFromSessionStorage('screen');
  const isMobile = detectDevice();
  const description = getFromSessionStorage('description');

  // TODO: removing for now will be added soon
  // useEffect(() => {
  //   history.push(null, null, location.href);
  //   window.onpopstate = function() {
  //     history.go(1);
  //   };
  // });

  const close = () => {
    updateUserBal();
    closeModalBox();
    clearSessionStorage();
  };

  useEffect(() => {
    mixpanelTrackPageView('Message Screen', { message });
    if (!(screen && screen === MESSAGE_CONSTANTS.WIRECARD_RESP.ERROR)) {
      setTimeout(() => {
        const redirectScreen = getFromSessionStorage(
          APP_CONSTANTS.LINEUP_KEY.NAVIGATE_TO_KEY,
        );
        const redirectTo = redirectScreen || '/wallet';
        // eslint-disable-next-line no-unused-expressions
        isMobile
          ? isModal
            ? close()
            : history.push({ pathname: redirectTo })
          : close();
      }, 1000);
    }
    return () => {
      clearSessionStorage();
    };
  }, []);

  return (
    <main className="App__main noHamburger">
      <div className="d-flex align-items-center modalMsgWrap">
        <Button
          className="linkBtn topClose btnBackArrow"
          onClick={() => close()}
        />
        <div className="msgWrap">
          {(screen && screen === MESSAGE_CONSTANTS.WIRECARD_RESP.ERROR && (
            <>
              <div className="caution" />
              <div className="title">
                {MESSAGE_CONSTANTS.ADD_FUNDS_VALIDATION.SOMETHING_WENT_WRONG}
              </div>
              <div className="info">{decodeURIComponent(message)}</div>
            </>
          )) || (
            <>
              <div className="checked" />
              <div className="title">{decodeURIComponent(message)}</div>
              {description && (
                <div className="info">{decodeURIComponent(description)}</div>
              )}
            </>
          )}
        </div>
      </div>
    </main>
  );
};

MessageScreen.defaultProps = {
  updateUserBal: () => {},
};
