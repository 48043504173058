import React, { useState, useEffect } from 'react';
import { Button } from 'Components/Form-Elements/Button';
import { FileInput } from 'Components/Form-Elements/FileInput';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { WebcamCapture } from 'Components/WebcamCapture';
import { CustomModalBox } from 'Components/CustomModalBox';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import ReactCrop from 'react-image-crop';
import {
  uploadImageToAws,
  getSignedUrl,
  updateUserProfileImage,
} from 'Actions/user-profile-action';
import 'react-image-crop/dist/ReactCrop.css';
import { getCookie, setCookie } from 'Utils/storage-utilities';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { ACTION_TYPE } from 'Constants/action-type';
import img from '../../../assets/images/defaultImg.png';

export const OptionModal = ({
  close,
  setIsLoading,
  webcamAvailable,
  profileContextDispatch,
}) => {
  const [clickPicture, showClickPicture] = useState(false);
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const [tempImage, setTempImage] = useState();
  const [imageRef, setImageRef] = useState();
  const [imageBlob, setImageBlob] = useState();
  const [mainCrop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  const askCameraPermission = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        window.localStream = stream;
        showClickPicture(true);
      })
      .catch(error => {
        if (error.name === 'NotAllowedError') {
          // user denied access to camera
        }
      });
  };

  const removeUserImage = () => {
    setIsLoading(true);
    updateUserProfileImage('')
      .then(({ data }) => {
        const updatedProfile = data.data;
        delete updatedProfile.services;
        setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, updatedProfile);
        profileContextDispatch({
          type: ACTION_TYPE.PROFILE.USER_IMAGE,
          payload: img,
        });
        setIsLoading(false);
        close();
      })
      .catch(({ response }) => {
        console.log('== remove image failed == ', response);
      });
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    if (image.width < image.height) {
      ctx.transform(0, 1, -1, 0, crop.height, 0);
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    return new Promise(() => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName;
        setImageBlob(blob);
      });
    });
  };

  const makeClientCrop = async crop => {
    if (imageRef && crop.width && crop.height) {
      await getCroppedImg(imageRef, crop, `newFile${Math.random(5)}.jpeg`);
    }
  };

  const onCropComplete = crop => {
    makeClientCrop(crop);
  };

  const onCropChange = crop => {
    setCrop(crop);
  };

  const onImageLoaded = image => {
    setImageRef(image);
    onCropComplete(mainCrop);
  };

  const blobToFile = theBlob => {
    // eslint-disable-next-line no-param-reassign
    theBlob.lastModifiedDate = new Date();
    return theBlob;
  };

  const setAvatar = fileObj => {
    let file;
    if (fileObj) {
      file = fileObj;
    } else {
      file = blobToFile(imageBlob);
    }

    setIsLoading(true);
    setToggleModalBox(false);
    close();
    getSignedUrl(file.name, userdata.publicId)
      .then(({ data }) => {
        uploadImageToAws(data.signed_url, file)
          .then(({ data }) => {
            // eslint-disable-next-line max-len
            const url = `${process.env.REACT_APP_AWS_BASE_URL}users/${userdata.publicId}/icon/${file.name}`;
            updateUserProfileImage(url)
              .then(({ data }) => {
                const updatedProfile = data.data;
                delete updatedProfile.services;
                setCookie(
                  APP_CONSTANTS.USER_DATA_KEY.USER_DATA,
                  updatedProfile,
                );
                profileContextDispatch({
                  type: ACTION_TYPE.PROFILE.USER_IMAGE,
                  payload: url,
                });
                setTempImage();
                setIsLoading(false);
              })
              .catch(({ response }) => {
                setModalBoxName(APP_CONSTANTS.MODALBOX.SOMETHING_WRONG);
                setToggleModalBox(true);
              });
          })
          .catch(({ response }) => {
            setModalBoxName(APP_CONSTANTS.MODALBOX.SOMETHING_WRONG);
            setToggleModalBox(true);
          });
      })
      .catch(() => {
        setModalBoxName(APP_CONSTANTS.MODALBOX.SOMETHING_WRONG);
        setToggleModalBox(true);
      });
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    const key = Object.keys(APP_CONSTANTS.MODALBOX).find(
      key => APP_CONSTANTS.MODALBOX[key] === modalBoxName,
    );
    if (key) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX[key];
    }
    return modalbox;
  };

  const processHandler = () => {
    if (imageBlob) {
      setAvatar();
    }
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const handleSelectedFile = e => {
    if (window.FileReader) {
      const files = e.target.files[0];
      if (files && files.size > APP_CONSTANTS.IMAGE_MAX_SIZE) {
        setModalBoxName(APP_CONSTANTS.MODALBOX.FILE_TOO_LARGE);
        setToggleModalBox(true);
      } else if (files && !files.type.match('image.*')) {
        setModalBoxName(APP_CONSTANTS.MODALBOX.FILE_TYPE_ERROR);
        setToggleModalBox(true);
      } else if (files && files.type.match('image.*')) {
        setModalBoxName(APP_CONSTANTS.MODALBOX.CROP_IMAGE);
        setToggleModalBox(true);
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setTempImage(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  return (
    <>
      {toggleModalBox ? (
        <CustomModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="Ok"
          cancelButtonHandler={() => setToggleModalBox(false)}
          cancelButtonText="Cancel"
          showBothButtons
          showFooterButtons>
          {tempImage && (
            <ReactCrop
              src={tempImage}
              crop={mainCrop}
              ruleOfThirds
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              keepSelection
            />
          )}
        </CustomModalBox>
      ) : (
        <div className="backfade">
          <div id="popModal" className="card-body">
            {clickPicture ? (
              <WebcamCapture
                close={() => showClickPicture(false)}
                setTempImage={setTempImage}
                toggleModalBox={() => setToggleModalBox(true)}
                setModalBoxName={setModalBoxName}
              />
            ) : (
              <div className="title cancelBtnWrap">
                <FileInput
                  id="file_input"
                  accept="image/*"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVITY_ALL_EVENTS,
                      {
                        action: 'Choose Photo',
                      },
                    );
                  }}
                  handleChange={handleSelectedFile}
                  label="Choose Photo"
                  labelClass="btn btn-link d-block"
                />
                {webcamAvailable && (
                  <Button
                    className="btn btn-link d-block"
                    type="button"
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVITY_ALL_EVENTS,
                        {
                          action: 'Take Picture',
                        },
                      );
                      askCameraPermission();
                    }}
                    label="Take Picture"
                  />
                )}
                <Button
                  className="btn btn-link d-block"
                  type="button"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVITY_ALL_EVENTS,
                      {
                        action: 'Remove Photo',
                      },
                    );
                    removeUserImage();
                  }}
                  label="Remove Photo"
                />
                <Button
                  className="btn btn-link d-block txtCancel"
                  type="button"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVITY_ALL_EVENTS,
                      {
                        action: 'Cancel',
                      },
                    );
                    close();
                  }}
                  label="Cancel"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
