import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const changePassword = async (oldPassword, newPassword) => {
  const url = API.UPDATE_PASSWORD;
  const data = {
    oldPassword,
    newPassword,
  };
  const response = await NetworkManager.requestAuthPUT(url, data);
  return response;
};
