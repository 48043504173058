/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { ProgressBar } from 'Components/ProgressBar';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import { Button } from 'Components/Form-Elements/Button';
import {
  setInSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { getUserState } from 'Actions/location-action';
import { ModalBox } from 'Components/ModalBox';
import Branch from 'branch-sdk';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { detectDevice } from 'Utils/device-utils';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
  updateDob,
  getDraftUser,
  updateLocation,
} from 'Actions/new-auth-action';
import { Alert } from '../Alert';
import icon from '../../assets/icons/icons.svg';

export const LocationAllow = ({
  history,
  isModal,
  fetchAndSetModalPage,
  closeModalBox,
}) => {
  const [locationStatus, setLocationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locationObject, setLocationObject] = useState({});
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [alertTemplate, setAlertTemplate] = useState('red');

  const isMobile = detectDevice();
  const signupType = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
  );
  const { email, login } = history.location.state ? history.location.state : '';

  const back = () => {
    const path = `/m-signup-dob`;
    history.push(path, { email, login });
  };
  useEffect(() => {
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  /*   const updateLocation = response => {
    updateLocation(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          const path = `/m-signup-complete`;
          history.push(path);
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
      });
  }; */

  const onAllowLocation = position => {
    setIsLoading(true);
    getUserState(position.coords.latitude, position.coords.longitude)
      .then(({ data }) => {
        setIsLoading(false);
        mixPanelTrackEvent(
          MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
          {
            action: 'Allow Location',
            state: {
              name: data.state.name,
              stusps: data.state.stusps,
            },
            country: data.country,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          },
        );
        setLocationObject({
          state: {
            name: data.state.name,
            stusps: data.state.stusps,
          },
          country: data.country,
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
        setLocationStatus(true);
        /* const path = `/m-signup-complete`;
        history.push(path); */
        const apiParam = {
          location: {
            state: {
              name: data.state.name,
              stusps: data.state.stusps,
            },
            country: data.country,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          },
          email,
        };
        updateLocation(apiParam)
          .then(res => {
            const { code, message, data, status } = res.data;
            if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
              const path = `/m-signup-complete`;
              history.push(path, { email, login });
            }
          })
          .catch(error => {
            const { status, message, code } = error.response.data;
            if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
              setResponseError(message);
              setAlertTemplate('red');
              setToggleAlertBox(true);
            }
          });
      })
      .catch(({ response }) => {
        if (response) {
          setIsLoading(false);
          setLocationObject({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
          mixPanelTrackEvent(
            MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
            {
              action: 'Allow Location',
              lat: position.coords.latitude,
              long: position.coords.longitude,
            },
          );
          if (
            response.data.status ===
              APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
            response.data.code ===
              APP_CONSTANTS.ERROR_CODE.CODE_LOCATION_NOT_FOUND
          ) {
            setLocationStatus(true);
          }
        }
      });
  };

  const onDenyLocation = error => {
    mixPanelTrackEvent(
      MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
      {
        action: 'Deny Location',
      },
    );
    const path = `/m-signup-complete`;
    history.push(path, { email, login });
  };

  const getLocation = () => {
    mixPanelTrackEvent(
      MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
      {
        action: 'Get Location',
      },
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onAllowLocation, onDenyLocation);
    }
  };

  const storeData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.LOCATION,
      locationObject,
    );
    if (signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY) {
      if (isModal) {
        fetchAndSetModalPage('/verify-phone-number');
        return;
      }
      history.push('/verify-phone-number');
      return;
    }
    if (isModal) {
      fetchAndSetModalPage('/birth-date');
      return;
    }
    history.push('/birth-date');
  };

  const renderProgressDialog = () => {
    return isLoading && <Spinner />;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.PERMISSION_DENIED) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.PERMISSION_DENIED;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.TIMEOUT) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.TIMEOUT;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.UNKNOWN_ERR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.UNKNOWN_ERR;
    }
    return modalbox;
  };

  return (
    <>
      <div className="ffScreen Signup Login">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              {/* <div className="headSection-new__leftAct">
                <button
                  className="button button-back"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
            <use xlinkHref={`${icon}#i-location`} />
          </svg>
          <h1 className="title">Enable Location Services</h1>
          <p className="copy">
            Paid contests can only be played in some locations. We maybe need at
            little bit more copy explaining why we need to ask for this
            information, so now I’m just typing words.
          </p>
        </div>
        <div className="ffScreen__footer">
          <button
            className="button button-full button-blue"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION, {
                action: 'Enable',
              });
              getLocation();
            }}>
            Enable Location Services
          </button>
        </div>
      </div>
    </>
  );
};
