/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const GameRules = ({ history, showBack = true, onGameScoreRule }) => {
  const supportEmail = 'support_aacc@dnby5d.com';
  const liveChatUrl =
    'https://secure.livechatinc.com/licence/7584861/v2/open_chat.cgi';
  const paragraph1 =
    'Daily Number is the newest, simplest, and most exciting way to play daily fantasy sports. Rather than competing against other users, your fantasy sports lineup competes against a fixed target score known as the Daily Number. No need to outsmart the pros and other stat crunching nerds. Simply outscore the target and win!';
  const paragraph2 =
    'Daily Number gives you full control over your lineup and potential payouts. Simply choose your sport.  Pick any 5 players that you want.  No salary cap (or advanced college calculus) required.';
  const paragraph3 =
    'Your lineup will receive a rating from 0.5 to 5 stars based on the star power of the players you select.  This star rating will determine your potential payout.  Pick all superstars for the best shot at victory, but expect a modest prize. Or fill your lineup with role players for the opportunity to win BIG.';
  const paragraph4 = 'Let’s play!';
  const paragraph5 =
    'Before entering any paid contest, you will be required to verify your age, identity, and current location. You must be 18 or older (19 in NE and 21 in MA) and located in AK, AR, CA, CO, CT, DC, FL, KS, KY, MD, MN, NE, NM, NC, ND, OK, OR, RI, SC, UT, WV, WI, or WY. If you are determined to be eligible, you may enter a contest for real money by selecting your desired entry fee. Entry fees are deducted from your available balance at the time you submit your lineup.';
  return (
    <div className="gameRulesWrapper">
      <div className="pageHeaderWrap">
        <div className="d-flex pageHeader flexStartWrap fixedHeader">
          {showBack && (
            <div className="leftSide">
              <Link to="/settings" className="arrowBack" />
            </div>
          )}
          <h2>WELCOME TO DAILY NUMBER</h2>
          <div className="rightSide" />
        </div>
      </div>
      <div className="rulesDetailsWrap">
        <div className="gameRulesContent">
          <p>{paragraph1}</p>
          <p>{paragraph2}</p>
          <p>{paragraph3}</p>
          <p>{paragraph4}</p>
        </div>
        <div className="aboutRules">
          {/* <div className="rulesMainTitle">Rules</div> */}
          <div className="rulesSubTitle">ENTERING A CONTEST</div>
          <p>
            Eligible 5Dimes customers may access paid Daily Number contests. To
            enter a paid contest, you may choose from one of the preset entry
            fee amounts. Your selected entry fee is deducted from the available
            balance in your 5Dimes account at the time you submit your lineup.
          </p>
          <div className="rulesSubTitle">CONTEST START TIME</div>
          <p>
            Contests start at the scheduled start time of the first game that is
            included in the contest. Start times are listed for each contest.
          </p>
          <div className="rulesSubTitle">EDITING & CANCELLING LINEUPS</div>
          <p>
            You may edit or cancel your lineup at any time prior to the contest
            start time. Note that contest details, including the target score,
            player ratings, and potential prizes may change from the time your
            lineup was originally submitted to when you decide to edit your
            lineup. In this event, editing your lineup will require you to
            accept all of the latest contest details.
          </p>
          <p>
            When a lineup for a paid contest is cancelled, the entry fee is
            refunded to your 5Dimes balance.
          </p>
          <div className="rulesSubTitle">LINEUP RATINGS & PRIZES</div>
          <p>
            Every lineup is rated based on the strength of the athletes selected
            into the lineup. The lineup strength rating is primarily determined
            based on the projected fantasy sports scoring totals of the athletes
            selected. A contest may have between 5 and 10 possible lineup
            ratings with 5 stars being assigned to the strongest lineups and 0.5
            stars to the weakest lineups.
          </p>
          <p>
            Potential prizes vary based on lineup strength rating. Prior to
            entering a contest, you will be able to view the potential prize
            associated with each lineup strength rating level. You will be able
            to view your lineup’s rating and potential prize before submitting
            your lineup.
          </p>
          <p>
            Free contests do not offer cash prizes. For paid contests, the prize
            values displayed represent the total prize available for a winning
            lineup. For example, consider a contest with a $10 entry fee and a
            $20 prize. When you enter the contest, $10 is deducted from your
            available balance. When the contest is over, if your lineup has won,
            $20 will be added to your available balance resulting in a profit of
            $10.
          </p>
          <div className="rulesSubTitle">LINEUP CONFIGURATION & RULES</div>
          <p>
            Before submitting a lineup, you must select one player for each
            position listed. No partial lineups are accepted. Your lineup must
            be composed of athletes from at least two different games. Below are
            the required positions for each sport.
          </p>
          <div className="rulesSubTitle">NFL</div>
          <p>
            <ul>
              <li>Quarterback</li>
              <li>Running Back #1</li>
              <li>Wide Receiver #1</li>
              <li>Wide Receiver #2</li>
              <li>Flex: Running Back, Wide Receiver, or Tight End</li>
            </ul>
          </p>
          <div className="rulesSubTitle">NBA</div>
          <p>
            <ul>
              <li>Guard #1</li>
              <li>Guard #2</li>
              <li>Forward / Center #1</li>
              <li>Forward / Center #2</li>
              <li>Utility: Any available player</li>
            </ul>
          </p>
          <div className="rulesSubTitle">MLB</div>
          <p>
            <ul>
              <li>Starting Pitcher #1</li>
              <li>Hitter #1</li>
              <li>Hitter #2</li>
              <li>Hitter #3</li>
              <li>Hitter #4</li>
            </ul>
          </p>
          <div className="rulesSubTitle">NHL</div>
          <p>
            <ul>
              <li>Goalie #1</li>
              <li>Center #1</li>
              <li>Wing(Left/Right) #1</li>
              <li>Defenseman #1</li>
              <li>Utility: Can be Center, Wing, Defenseman</li>
            </ul>
          </p>
          <div className="rulesSubTitle">ATHLETE & GAME STATUSES</div>   
          <p>
            Daily Number displays information provided by third party sources
            regarding each athlete’s status as well as relevant weather
            information for all games. Prior to selecting a player to your
            lineup, you should carefully consider all of this information as
            well as your own independent research.
          </p>
          <p>
            If your lineup includes an athlete that does not participate in the
            game for any reason or was scheduled to participate in a game that
            is postponed or cancelled, your lineup will receive 0 points for the
            impacted player.
          </p>
          <p>
            In consideration of special instances, Daily Number at its sole
            discretion reserves the right to cancel your entry and refund your
            available balance in the amount of the entry fee.
          </p>
          <div className="rulesSubTitle">SCORING RULES</div>
          <p>
            Lineups earn fantasy points based on the scoring charts listed
            below.
          </p>
          <p>
            <ul className="gameRulesList">
              {APP_CONSTANTS.GAME_RULES.map(gameRule => (
                <li
                  key={gameRule.title}
                  className="d-flex rulesList"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                      action: 'Scoring Rules',
                      gameTitle: gameRule.title,
                    });
                    if (typeof onGameScoreRule === 'function') {
                      onGameScoreRule(gameRule.slug);
                    } else {
                      history.push(gameRule.link);
                    }
                  }}>
                  <div className="flex-grow-1 ruleTitle">{gameRule.title}</div>
                  <Link to="/" className="disclosureArrow" />
                </li>
              ))}
          </ul>
          </p>
          <div className="rulesSubTitle">CONTEST RESULTS</div>
          <p>
            Within approximately 30 minutes following the end of the last game
            included in the contest, scoring results are finalized and prizes
            are credited to winners’ accounts. When required due to errors,
            Daily Number reserves the right at its sole discretion to apply
            scoring revisions and correct contest results after a contest has
            been posted as final.
          </p>
          <div className="rulesSubTitle">TIES</div>
          <p>
            In the event that your lineup scores the exact equivalent amount of
            points as the target score, this will be determined to be a winning
            result and you will receive the full prize amount.
          </p>
          <div className="rulesSubTitle">
            MULTIPLE ACCOUNTS AND SUSPENDED ACCOUNTS
          </div>
          <p>
            You are permitted to open one (1) Account only. Only one account is
            allowed per shared computer. Opening multiple accounts is not
            permitted. If 5Dimes or the Daily Number determines that you have
            used or controlled more than one account, any or all of your
            accounts may be terminated or suspended and any prizes credited may
            be revoked or withheld and all funds, including original deposits,
            will be transferred to a charity nominated by the Gambling
            Supervision Commission. Customers shall also be liable to us for
            damages and costs incurred as a result of any fraud.
          </p>
          <p>
            There are a variety of behaviors that are detrimental to the Daily
            Number and other users on the Service. Engaging in those behaviors
            may result in suspension of some or all functions associated with
            your account. We reserve the right to suspend, disable or terminate
            your Account at any time at our sole discretion. Unless otherwise
            required or directed by the Governing Supervision Commission and in
            accordance with the relevant provisions of the Regulations, all
            decisions regarding the opening, maintenance and closing of Accounts
            rests with us and any decisions we take regarding any aspect of the
            Software, or any aspect of an Account is final. Suspended users are
            expected to respect the disciplinary actions imposed on their
            accounts and all communication regarding restoration of your account
            should take place via{' '}
            <a href={`mailto:${supportEmail}`}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                action: 'support',
              });
            }}
            >{supportEmail}</a> email account
            or contact us directly by <a 
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                action: 'Live Chat',
              });
            }}
            href={liveChatUrl}
            
            >Live Chat</a>.
          </p>
          <p>
            Management reserves the right to allow or deny access to the Daily
            Number when verification irregularities/difficulties arise or issues
            with another gaming establishments are reported to be related to
            your account (regardless if these reports are valid or invalid) or
            other evidence suggests it
          </p>
          <div className="rulesSubTitle">SCRIPTS</div>
          <p>Use of unauthorized scripts is prohibited on Daily Number.</p>
          <div className="rulesSubTitle">SERVICE ACCESS</div>
          <p>
            While we try to ensure that Daily Number is functioning smoothly at
            all times, like any online service we may periodically experience
            periods of outage or slow performance. These can sometimes result in
            an inability to access the Service, problems selecting athletes, or
            problems entering new contests. If you’re unable to access the
            Service, please report the problems by emailing us at{' '}
            <a href={`mailto:${supportEmail}`}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                action: 'support',
              });
            }}
            >{supportEmail}</a>.
          </p>
          <div className="rulesSubTitle">DRAFT CANCELLATION</div>
          <p>
            Daily Number reserves the right to cancel contests at its own
            discretion, without any restrictions.
          </p>
          <p>
            Any questions? Need help? Have Feedback? Contact us{' '}<a href={`mailto:${supportEmail}`}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                action: 'support',
              });
            }}
            >{supportEmail}</a>.
          </p>
          <p>
            
          </p>
        </div>
      </div>
    </div>
  );
};
