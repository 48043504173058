/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext } from 'react';
import { getContest } from 'Actions/sport-action';
import { LineUpContext } from 'Components/LineUpScreens/Context';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { draftLineupAction } from 'Actions/draft-lineup-action';
import {
  contestImages,
  gameName,
} from 'Components/LineUpScreens/SportData/sportsObject';
import { ACTION_TYPE } from 'Constants/action-type';
import { setInSessionStorage, getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { Button as FormButton } from 'Components/Form-Elements/Button';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const NewGameDesktop = ({ setNextScreen, closeModalBox }) => {
  const { contextDispatch } = useContext(LineUpContext);
  const [contests, setContests] = useState([]);
  const [selectedGameIndex, setSelectedGameIndex] = useState(0);
  const [disableBtn, toggleDisableBtn] = useState(true);
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [showText, setShowText] = useState(false);
  const [modal, setModal] = useState(false);
  const [content, setContent] = useState('');
  const [enableScroll, setEnableScroll] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setContent('');
    setEnableScroll(true);
  };
  const toggleScroll = enableScroll => {
    if (enableScroll) {
      document.documentElement.classList.remove('u-overflowHidden');
    } else {
      document.documentElement.classList.add('u-overflowHidden');
    }
  };

  useEffect(() => {
    getContest()
      .then(({ data }) => {
        setContests(data.contests || []);
        if (data.contests) {
          toggleDisableBtn(false);
          setShowContinueButton(true);
        } else {
          setShowText(true);
        }
        contextDispatch({
          type: ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE,
          payload: null,
        });
      })
      .catch(() => {
        // API error handling
      });
  }, []);

  useEffect(() => {
    toggleScroll(enableScroll);
  }, [enableScroll]);
  const handleClick = () => {
    toggleScroll(true);
    const draftLineupData = {
      entryFee: 0,
      currencyIsoCode: 'USD',
      // eslint-disable-next-line no-underscore-dangle
      contestId: contests[selectedGameIndex]._id,
      selectedNumber: 0,
      isDrafted: true,
    };
    const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
    if (token !== '') {
      mixPanelTrackEvent('contest selected', contests[selectedGameIndex]);
      draftLineupAction(draftLineupData)
        .then(({ data }) => {
          mixpanelTrackPageView('Contest Selection');
          setInSessionStorage(
            APP_CONSTANTS.SPORTS_KEY.CONTEST,
            contests[selectedGameIndex],
          );
          setInSessionStorage(APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST, data);
          setNextScreen('line-up');
        })
        .catch(err => {
          if (
            err.response.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.PERMANENTLY_REMOVED
          ) {
            setModal(!modal);
            setContent('PERMANENTLY_REMOVED');
          } else if (
            err.response.status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN
          ) {
            setModal(!modal);
            setContent('FORBIDDEN');
          }
        });
    } else {
      mixpanelTrackPageView('Contest Selection');
      setInSessionStorage(
        APP_CONSTANTS.SPORTS_KEY.CONTEST,
        contests[selectedGameIndex],
      );
      setNextScreen('line-up');
    }
  };

  const renderSportData = () => {
    if (!contests.length) {
      return (
        <div className={!showText && 'hide'}>
          There are no contests currently available. Check back soon.
        </div>
      );
    }
    return contests.map((sport, index) => {
      /* if (sport.sportSlug && sport.sportSlug.toUpperCase() === 'NHL') {
        return (
          <button
            type="button"
            className={`cols cols${index + 1} ${
              index === selectedGameIndex ? 'active' : ''
            }`}
            key={sport._id}
            onClick={() => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.TAP_FOR_YOU__ALL_EVENTS,
                {
                  action: 'Game Selection',
                  formData: {
                    gameIndex: index,
                    sportsType: sport,
                  },
                },
              );
              setSelectedGameIndex(index);
            }}>
            <div className="box">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="auto"
                height="70"
                opacity={index === selectedGameIndex ? '1' : '0.4'}
                style={{ margin: '0 auto 10px' }}>
                <use xlinkHref={`${contestImages[sport.sportSlug]}#i-nhl`} />
              </svg>
              <span>
                {(sport.sportSlug && sport.sportSlug.toUpperCase()) || ''}
              </span>
              <h6>{gameName[sport.sportSlug] || ''}</h6>
            </div>
          </button>
        );
      } */
      return (
        <button
          type="button"
          className={`cols cols${index + 1} ${
            index === selectedGameIndex ? 'active' : ''
          }`}
          key={sport._id}
          onClick={() => {
            mixPanelTrackEvent(
              MIXPANEL_EVENTS_CONSTANTS.TAP_FOR_YOU__ALL_EVENTS,
              {
                action: 'Game Selection',
                formData: {
                  gameIndex: index,
                  sportsType: sport,
                },
              },
            );
            setSelectedGameIndex(index);
          }}>
          <div className="box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="auto"
              height="70"
              opacity={index === selectedGameIndex ? '1' : '0.4'}
              style={{ margin: '0 auto 10px' }}>
              <use xlinkHref={`${contestImages.svg}#i-${sport.sportSlug}`} />
            </svg>
            {/* <img src={contestImages[sport.sportSlug]} alt="" /> */}
            <span>
              {(sport.sportSlug && sport.sportSlug.toUpperCase()) || ''}
            </span>
            <h6>{gameName[sport.sportSlug] || ''}</h6>
          </div>
        </button>
      );
    });
  };

  return (
    <div className="basketBallWrapper">
      <Modal isOpen={modal} toggle={modal}>
        <ModalHeader toggle={modal}>Draft Lineup Error</ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className="newGameBlock">
        <div className="">
          <div className="d-flex popUpHeader">
            <div className="leftSide">
              <button
                type="button"
                onClick={() => {
                  toggleScroll(true);
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.TAP_FOR_YOU__ALL_EVENTS,
                    {
                      action: 'Cancel',
                    },
                  );
                  closeModalBox();
                }}
                className="btnCancel">
                Cancel
              </button>
            </div>
            <h2>New Daily Number Lineup</h2>
            <div className="rightSide" />
          </div>
        </div>
        <div className="basketBallBlock d-flex justify-content-center align-items-center">
          {renderSportData()}
        </div>
        {showContinueButton ? (
          <div className="btnWrapper">
            <FormButton
              className="btn btn-primary"
              label="Continue"
              disabled={disableBtn}
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.TAP_FOR_YOU__ALL_EVENTS,
                  {
                    action: 'Continue',
                  },
                );
                handleClick();
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
