/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import icon from '../../assets/icons/icons.svg';

export const NotificationPayment = ({ history }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const isMobile = detectDevice();
  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;

  const { email, login } = history.location.state ? history.location.state : '';

  const back = () => {
    const path = `/m-signup-allow-notification`;
    history.push(path, { email, login });
  };

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  const skip = () => {
    if (login) {
      console.log('logindata', login);
      setTimeout(function() {
        mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
          action: 'Username Login',
          formData: {
            user: login.userName,
          },
        });
      }, 2000);
      profileContextDispatch({
        type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
        payload: true,
      });
      setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, login.token);
      if (login.groups) {
        delete login.groups;
      }
      setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, login);
      profileContextDispatch({
        type: ACTION_TYPE.PROFILE.USER_IMAGE,
        payload: login.imageUrl,
      });
      Branch.setIdentity(login.publicId);
      Branch.data();
      history.push('/for-you');
    } else {
      const path = `/login`;
      history.push(path);
    }
  };

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const customerId = query.get('customerid');
  const externalToken = query.get('token');

  return (
    <>
      <div className="ffScreen Signup Login">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button className="button button-back" onClick={back}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ffScreen__body">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
            <use xlinkHref={`${icon}#i-money`} />
          </svg>
          <h1 className="title">Set up your Wallet</h1>
          <p className="copy">
            Get a head start on playing paid contests. Use a card, bank or
            mobile pay as a funding source.
          </p>
        </div>
        <div className="ffScreen__footer">
          <button className="button button-full button-blue" disabled>
            Add Payment Method
          </button>
          <button className="button button-full" onClick={skip}>
            Skip
          </button>
        </div>
      </div>
    </>
  );
};
