/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { AddFunds } from 'Components/Wallet/AddFundsScreen';
import { Checkout } from 'Components/Wallet/Checkout';
import { Kyc } from 'Components/Kyc';
import { KycErrorScreen } from 'Components/Kyc/KycErrorScreen';
import { MessageScreen } from 'Components/MessageScreen';
import { PaymentMethodList } from 'Components/Wallet/PaymentMethodList';
import { ConfirmPaymentScreen } from 'Components/Wallet/ConfirmPaymentScreen';

export const AddFundsModal = ({
  showAddFundsModal,
  setShowAddFundsModal,
  updateUserBal,
}) => {
  const fetchAndSetModalPage = (pageLocation, data) => {
    setPage(routesHash[pageLocation]);
  };

  const closeModalBox = () => setShowAddFundsModal(false);

  const [page, setPage] = useState(
    <AddFunds
      isModal
      fetchAndSetModalPage={fetchAndSetModalPage}
      closeModalBox={closeModalBox}
    />,
  );

  const commonProps = {
    fetchAndSetModalPage,
    isModal: true,
    closeModalBox,
    updateUserBal,
  };

  const routesHash = {
    '/add-funds': <AddFunds {...commonProps} />,
    '/payment': <Checkout {...commonProps} key="payment" />,
    '/kyc': <Kyc {...commonProps} />,
    '/customer-support': <KycErrorScreen {...commonProps} />,
    '/message': <MessageScreen {...commonProps} />,
    '/payment-methods': <PaymentMethodList {...commonProps} />,
    '/confirm-payment': <ConfirmPaymentScreen {...commonProps} />,
  };

  if (page && page.key && showAddFundsModal) {
    return page;
  }

  return (
    <div>
      {showAddFundsModal && (
        <Modal isOpen centered>
          <ModalBody>{page}</ModalBody>
        </Modal>
      )}
    </div>
  );
};

AddFundsModal.defaultProps = {
  updateUserBal: () => {},
};
