export const BUTTON_LABELS = {
  LOCATION: {
    ENABLE_LOCATION: 'Enable Location Services',
  },
  COMMON: {
    NEXT: 'Next',
    SKIP: 'Skip',
    CANCEL: 'Cancel',
    ADD: 'Add',
    CASHOUT: 'Transfer',
    OK: 'Ok',
    SUBMIT: 'Submit',
    DONE: 'Done',
    CONFIRM: 'Confirm Transfer',
    OKAY: 'Okay',
  },
  HOME: {
    LOG_IN: 'Log In',
  },
  FORGOT_PASS: 'Forgot Password?',
  SIGN_UP: 'Sign Up',
  WALLET: {
    ADD_FUNDS: 'Add Funds',
    CASHOUT: 'Cash Out',
    ADD_CARD: 'Add Payment Method',
  },
  LINE_UP: {
    ADD_FUND: 'Add Funds',
    CANCEL_WITHOUT_SAVING: 'Cancel without saving',
    SAVE_AS_DRAFT: 'Save As Draft',
    DELETE_LINEUP: 'Delete Lineup',
    RESET_LINEUP: 'Reset Lineup',
  },
  XP: { REDEEM: 'Redeem' },
};
