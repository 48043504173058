import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';

export const CancelWithDrawalModal = ({ close, confirm }) => {
  return (
    <>
      <div className="backfade">
        <div id="popModal" className="card-body">
          <div className="info">
            <div className="title centerText">
              <strong>Are you sure want to cancel the Withdrawal?</strong>
            </div>
          </div>
          <div className="title cancelBtnWrap">
            <Button
              type="button"
              label="Cancel"
              className="btn btn-link d-block txtCancel"
              onClick={close}
            />
            <Button
              className="btn btn-link d-block"
              type="button"
              onClick={confirm}
              label="Confirm"
            />
          </div>
        </div>
      </div>
    </>
  );
};

CancelWithDrawalModal.propTypes = {
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};
