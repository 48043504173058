import React from 'react';
import { StarRating } from 'Components/StarRating';
import { formatDateMMDD, formatDateHHmm } from 'Utils/date-utils';
import { getPositionShortCode } from 'Utils/data-utils';
import { HighChart } from 'Components/HighChart';
import { mixPanelTrackRegistration, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { renderNews, renderStatDataForTabs } from './helper-player-details';
import { GameCardDetails } from './GameCard';
import { PlayerOverviewStats } from './PlayerOverviewStats';

export const DesktopDesignPlayerDetails = ({
  player,
  seasonStats,
  players,
  recentStats,
  awayTeam,
  games,
  addPlayer,
  playerTeam,
  homeTeam,
  removePlayer,
  statsData,
  playerNews,
  alreadySelectedPlayer,
  alreadySelectedPlayerGameData,
  showAlertPlayer,
  renderTabs,
  activeTab,
  seasonStatValue,
  renderTabContents,
}) => {
  return (
    <div className="cols2">
      <div className="cols2Inner starBoarder">
        <div className="cols2Inner__wrapper">
          <div className="playerDetails">
            <div className="playerAvatarWrapper">
              <div className="playerAvatar">
                <span
                  className="playerAvatar__image"
                  style={{
                    backgroundImage: `url(${player.picture})`,
                  }}
                />
              </div>
            </div>
            <div className="playerName text-center">{player.name}</div>
            <div className="aboutPlayer text-center">
              {player.playerGame.position} - {playerTeam.fullName}
            </div>
            <div className="ratingContain">
              <StarRating
                rating={player.strength}
                starRatedColor="rgb(251, 214, 3)"
                starEmptyColor="rgba(0, 0, 0, 0)"
                starDimension="14px"
                starSpacing="0.5px"
              />
            </div>
          </div>
          <PlayerOverviewStats
            player={player}
            liveStats={statsData}
            seasonStats={seasonStats}
            players={players}
            recentStats={recentStats}
            games={games}
          />
          <GameCardDetails
            player={player}
            seasonStats={seasonStats}
            playerTeam={playerTeam}
            players={players}
            recentStats={recentStats}
            awayTeam={awayTeam}
            games={games}
            addPlayer={addPlayer}
            homeTeam={homeTeam}
          />
          <div className="playerRecords playerRecordsTabs">
            <ul className="d-flex justify-content-start">{renderTabs()}</ul>
            <ul className="d-flex justify-content-start">
              {renderStatDataForTabs(activeTab, statsData, seasonStatValue)}
            </ul>
          </div>
          <div className="playerGraph text-center">{renderTabContents()}</div>
          {games.sportSlug !== 'nfl' ? (
            <div className="playerVideoHighlights">
              <div className="container">
                <div className="playerVideoTitle">NEWS & ANALYSIS</div>
              </div>
              <div className="container">
                {playerNews.length ? (
                  renderNews(playerNews)
                ) : (
                  <div>No News</div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className={`guardTitle guardBottomFixed ${
          alreadySelectedPlayerGameData &&
          !alreadySelectedPlayerGameData.lateScratchEnabled &&
          alreadySelectedPlayerGameData.activeTab
            ? 'u-notClickable'
            : ''
        }`}>
        <div className="guardBottomFixed__wrapper">
          {alreadySelectedPlayer === player.apiId && removePlayer && (
            <button
              className="btn btnOrange btnRemovePlayer"
              type="button"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                  action: 'Remove Player',
                  formData: { removedPlayer: player },
                });
                showAlertPlayer(player, 'remove');
              }}>
              <span className="removeIconWhite" />
              Remove Player
            </button>
          )}
          {alreadySelectedPlayer === '' && addPlayer && (
            <button
              className="btnBlue btn btn-primary"
              type="button"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                  action: 'Add Player',
                  formData: { addedPlayer: player },
                });
                addPlayer(player);
              }}>
              <span className="plusIconWhite" />
              Add Player
            </button>
          )}
          {alreadySelectedPlayer &&
            alreadySelectedPlayer !== player.apiId &&
            addPlayer && (
              <button
                className="btn btn-success"
                type="button"
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                    action: 'Add Player',
                    formData: { addedPlayer: player },
                  });
                  addPlayer(player);
                }}>
                <span className="swapIconWhite" />
                Swap Player
              </button>
            )}
        </div>
      </div>
    </div>
  );
};
