import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const verifyUserLocation = async (lat, long, accuracy) => {
  const url = API.VERIFY_LOCATION;
  const data = [lat, long, accuracy];
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};

export const getUserState = async (lat, long) => {
  const url = API.GET_LOCATION_STATE;
  const data = {
    latitude: lat,
    longitude: long,
  };
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
