import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Input } from 'Components/Form-Elements/Input';
import { Button } from 'Components/Form-Elements/Button';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { chekUserExists } from 'Actions/user-exists-action';
import { setCookie, getCookie } from 'Utils/storage-utilities';
import { ModalBox } from 'Components/ModalBox';
import { getUserDetail } from 'Actions/user-profile-action';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { changeEmailAction } from '../../actions/change-email-action';

export const ChangeEmail = ({ history, onBack }) => {
  const [asyncValidation, setValidity] = useState('');
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const [emailAddress, setEmail] = useState('');
  const [error, setError] = useState(true);
  const [isEmailUpdated, setEmailUpdated] = useState(false);
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const emailId = userdata.emailAddress;

  useEffect(() => {
    setEmail(emailId);
  }, [emailId]);

  useEffect(() => {
    mixpanelTrackPageView('Change Email Screen');
  }, []);

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.BAD_REQUEST) {
      modalbox = MESSAGE_CONSTANTS.KYC.BAD_REQUEST;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.OLD_PASSWORD_ERR) {
      modalbox = MESSAGE_CONSTANTS.KYC.OLD_PASSWORD_ERR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.SAME_AS_OLD_PASSWORD) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.SAME_AS_OLD_PASSWORD;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.CHECK_LOGIN_TYPE) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.CHECK_LOGIN_TYPE;
    }
    return modalbox;
  };

  useEffect(() => {
    setValidity('');
  }, [emailAddress]);

  const isEmailUnique = value => {
    chekUserExists(`email=${value}`)
      .then(response => {
        if (
          response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          !response.data.userEmailExists
        ) {
          setValidity('');
          setError(false);
        }
      })
      .catch(({ response }) => {
        if (response === undefined) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_EMAIL);
          setError(false);
        } else if (
          response.data.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.ALREADY_EXISTS &&
          userdata.emailAddress !== emailAddress
        ) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.EMAIL_EXISTS);
          setError(false);
        } else if (
          response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
        ) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_EMAIL);
          setError(false);
        }
      });
  };

  useEffect(() => {
    let timer;
    if (isEmailUpdated) {
      timer = setTimeout(() => {
        if (typeof onBack === 'function') {
          onBack();
        } else {
          history.push('/settings/account');
        }
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isEmailUpdated]);

  const validateUserExists = email => {
    isEmailUnique(email);
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const isEmailSubmitted = value => {
    changeEmailAction(value)
      .then(({ data }) => {
        if (data.status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          getUserDetail()
            .then(({ data }) => {
              const userProfileData = data.data;
              delete userProfileData.services;
              setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, userProfileData);
              setEmailUpdated(true);
            })
            .catch(({ response }) => {
              // Handle Errors
            });
        }
      })
      .catch(({ response }) => {
        if (
          response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
        ) {
          setToggleModalBox(true);
          setModalBoxName(APP_CONSTANTS.MODALBOX.BAD_REQUEST);
        }
      });
  };

  const submitEmail = () => {
    isEmailSubmitted(emailAddress);
  };

  return (
    <>
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="Ok"
          showBothButtons={false}
        />
      )}
      <div className="changeEmailWrapper">
        <div className="d-flex topBar resetTopBar topHeaderBar">
          <Link
            to="#"
            className="link"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES, {
                action: 'Cancel Change Usermail',
                formData: {
                  userMail: userdata.emailAddress,
                },
              });
              if (typeof onBack === 'function') {
                onBack();
              } else {
                history.push('/settings/account');
              }
            }}>
            Cancel
          </Link>
          <div className="title">Change Email</div>
          <Button
            className="link linkBtn"
            label="Save"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES, {
                action: 'Save User Mail',
                formData: {
                  userMail: userdata.emailAddress,
                },
              });
              submitEmail();
            }}
            disabled={!error}
          />
        </div>
        <div className="wrapper">
          <div className="form-group">
            <Input
              inputType="text"
              textLabel="Enter email"
              className="form-control"
              id="verify-email"
              placeholder={MESSAGE_CONSTANTS.VERIFY_EMAIL.ENTER_EMAIL}
              value={emailAddress}
              handleChange={setEmail}
              getValidity={err => setError(err)}
              onBlur={validateUserExists}
              validationRules={['validateEmail']}
              externalError={asyncValidation}
            />
            <div className="valid-feedback displayInlineBlock">
              {isEmailUpdated && MESSAGE_CONSTANTS.EMAIL_UPDATE_SUCCESS}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
