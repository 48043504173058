/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { LineUpInModal } from 'Components/LineUpScreens/LineUpInModal';
import { NewGameDesktop } from 'Components/LineUpScreens/NewGameDesktop';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { setInSessionStorage } from 'Utils/storage-utilities';

export const LineUpModal = ({
  showLineupModal,
  setShowLineupModal,
  history,
  location,
  status,
}) => {
  const onSuccess = (isLineupCreated = '') => {
    if (isLineupCreated === APP_CONSTANTS.LINEUPS_TABS.UPCOMING) {
      setInSessionStorage('lineupTab', APP_CONSTANTS.LINEUPS_TABS.UPCOMING);
    }
    if (isLineupCreated === APP_CONSTANTS.LINEUPS_TABS.DRAFT) {
      setInSessionStorage('lineupTab', APP_CONSTANTS.LINEUPS_TABS.DRAFT);
    }
    history.push({
      pathname: '/profile',
      state: {
        selectTab: APP_CONSTANTS.LINEUPS_TABS.ACTIVE,
      },
      random: Math.random(),
    });
    setShowLineupModal(false);
  };
  const closeModalBox = () => {
    setShowLineupModal(false);
  };
  const [isFull, setIsFull] = useState(status === 'EDIT_LINEUP');

  const setNextScreen = screen => {
    setIsFull(screen === 'line-up');
    setPage(component[screen]);
  };

  const component = {
    'new-game': (
      <NewGameDesktop
        setNextScreen={setNextScreen}
        closeModalBox={closeModalBox}
        setIsFull={setIsFull}
      />
    ),
    'line-up': (
      <LineUpInModal
        closeModalBox={closeModalBox}
        setNextScreen={setNextScreen}
        location={location}
        onSuccess={onSuccess}
      />
    ),
  };

  let firstComponent = component['new-game'];
  if (status === 'EDIT_LINEUP') {
    firstComponent = component['line-up'];
  }
  const [page, setPage] = useState(firstComponent);

  return (
    <div
      className={`nodal ${showLineupModal ? 'open' : ''} ${
        status === 'EDIT_LINEUP' || isFull
          ? 'isFull lineup_draft_selection'
          : ''
      }`}
      id="target_id">
      <div className="nodal__dialog">
        <div className="nodal__overlay" data-dismiss />
        <div className="nodal__content">{page}</div>
      </div>
    </div>
  );
};

LineUpModal.defaultProps = {
  location: { state: undefined },
};
