import { getFromSessionStorage } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';

const {
  SOCIAL_SIGNUP_KEY: { EMAIL, FIRST_NAME, LAST_NAME, SIGNUP_TYPE },
} = APP_CONSTANTS;

export const initialState = () => ({
  email: getFromSessionStorage(EMAIL),
  firstName: getFromSessionStorage(FIRST_NAME),
  lastName: getFromSessionStorage(LAST_NAME),
  userName: '',
  dob: '',
  err: '',
  error: false,
  fnErr: true,
  lnErr: true,
  usrErr: false,
  dobError: '',
  disableNext: true,
  loginStrategy: getFromSessionStorage(SIGNUP_TYPE),
});

export const reducer = (state, action) => {
  switch (action.type) {
    case 'setEmail':
      return { ...state, email: action.payload };
    case 'setFirstName':
      return { ...state, firstName: action.payload };
    case 'setLastName':
      return { ...state, lastName: action.payload };
    case 'setUserName':
      return { ...state, userName: action.payload };
    case 'setPhone':
      return { ...state, phone: action.payload };
    case 'setDob':
      return { ...state, dob: action.payload };
    case 'setErr':
      return { ...state, err: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setfnErr':
      return { ...state, fnErr: action.payload };
    case 'setlnErr':
      return { ...state, lnErr: action.payload };
    case 'setusrErr':
      return { ...state, usrErr: action.payload };
    case 'setdobError':
      return { ...state, dobError: action.payload };
    case 'setphnErr':
      return { ...state, phnErr: action.payload };
    case 'setdisableNext':
      return { ...state, disableNext: action.payload };
    case 'setloginstrategy':
      return { ...state, loginStrategy: action.payload };
    default:
      throw new Error(`Unknown action type, ${action.type}`);
  }
};
