import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { getCookie } from 'Utils/storage-utilities';

export const getSelectedPlayersForSubmission = (
  selectedEntryFees,
  targetNumber,
  selectedContest,
  positions,
) => {
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const { amount } = selectedEntryFees;
  const currency = selectedEntryFees.currencyIsoCode;
  const contestId = {
    $type: 'oid',
    /* eslint no-underscore-dangle: 0 */
    $value: selectedContest._id,
  };
  const location = {};
  const { appInstallId } = userdata;
  let playerDetails = {};

  const players = positions.map((position, index) => {
    playerDetails = {};
    playerDetails.position = position.position;
    playerDetails.index = index;
    playerDetails.player = {
      apiId: position.player.apiId,
      name: position.player.name,
      abbrName: position.player.abbrName,
      status: position.player.status,
      picture: position.player.picture,
      /* eslint no-underscore-dangle: 0 */
      _id: {
        $type: 'oid',
        /* eslint no-underscore-dangle: 0 */
        $value: position.player._id,
      },
    };
    playerDetails.playerGameId = {
      $type: 'oid',
      /* eslint no-underscore-dangle: 0 */
      $value: position.player.playerGame._id,
    };
    return playerDetails;
  });

  return [
    amount,
    currency,
    players,
    contestId,
    location,
    appInstallId,
    targetNumber,
  ];
};
export const getSelectedPlayersForSubmissionForActiveLineUp = (
  positions,
  editLineupId,
  editLineUpRawData,
) => {
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const entryId = editLineupId;
  // eslint-disable-next-line prefer-const
  let PlayerGameIds = [];

  for (let index = 0; index < positions.length; index += 1) {
    // eslint-disable-next-line prefer-const
    let playerDetails = {};
    const element = positions[index];
    if (
      editLineUpRawData[index].playerGames._id !== element.player.playerGame._id
    ) {
      playerDetails.prevPlayerGameId = editLineUpRawData[index].playerGames._id;
      playerDetails.newPlayerGameId = element.player.playerGame._id;
      PlayerGameIds.push(playerDetails);
    }
  }

  return [entryId, PlayerGameIds];
};

export const submitLineupconfirm = async data => {
  const url = API.LINE_UP_SUBMIT;
  const resp = await NetworkManager.requestAuthPOST(url, data);
  return resp;
};

export const swapPlayerForActiveLineUp = async data => {
  const url = API.SWAP_LINEUP;
  const resp = await NetworkManager.requestAuthPOST(url, data);
  return resp;
};
