/* eslint-disable */
import React from 'react';
import { Link } from 'react-router-dom';

export const TermsOfServiceMobile = () => {

  const contactSupport =(param)=>{
    typeof window.postMessage === 'function' &&  window.postMessage(param);
  }

  return (
    <div className="termsofServiceWrapper mainWrapper">
      <div className="privacyPolicyWrap contentWrapper">
        <div className="lastUpdated">Last updated: September 18, 2018</div>
        <div className="serviceTermsWrap">
          <p>
            Welcome to Daily Number! Daily Number is the newest, simplest, and
            most exciting way to play daily fantasy sports. Rather than
            competing against other users, your fantasy sports lineup competes
            against a fixed target score known as the Daily Number. No need to
            outthink the pros and other stat crunching nerds. Simply outscore
            the target and win. Before using Daily Number, please take some time
            to carefully read our Terms of Service below (“Terms,” or
            “Agreement”). The Terms below constitutes a binding contract between
            you and Daily Number.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>1. Acceptance of Terms</h3>
          <p>
            By using the website dailynumber.app (including all the areas
            available through such website, collectively, the “Site”),
            downloading the Daily Number mobile application (“App”), and/or
            using the various fantasy sports services (collectively, such
            services, including any new features and applications, together with
            the Site, the “Services”) offered by Daily Number, Inc., a Delaware
            corporation, (together with its parents, subsidiaries, affiliates,
            agents, representatives, consultants, employees, officers, and
            directors – collectively “DN,” “we,” “us,” and/or “our”), in any
            manner, including, but not limited to, visiting or browsing the
            Services or contributing content, information, or other materials or
            services to the Services, you, the user (collectively “Users,” or
            “you”), acknowledge and agree to these legally binding Terms. You
            also agree to the DN Privacy Policy and all other operating rules,
            policies, and procedures that may be published on the Services by
            DN, which are incorporated by reference. The DN Privacy Policy,
            which describes how we collect and use information from our Users,
            is available at Privacy Policy (“Privacy Policy”).
          </p>
          <p>
            You agree to use the Services only for lawful purposes, and that you
            are responsible for your use of the Services and communications with
            us, all of which must comply with these Terms.
          </p>
          <p>
            You hereby represent and warrant that you are fully able and
            competent to enter into the terms, conditions, obligations,
            affirmations, representations and warranties set forth in these
            terms and to abide by and comply with these terms.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>2. Modification to Terms of Service</h3>
          <p>
            DN may, at its sole discretion, change these Terms, including the DN
            Privacy Policy, from time to time. If changes to the Terms occur, we
            will notify you by posting the updated terms on the Site or by email
            to the email affiliated with your account. Updated versions of the
            Terms will never apply retroactively and the updated Terms will give
            the exact date they go into effect. Significant changes will go into
            effect no less than 30 days after we notify you. For the avoidance
            of doubt, ongoing Contests (as further defined below) shall be
            subject to the version of the Terms that is in effect at the start
            of the Contest. It is your responsibility to check the Terms
            periodically for changes. Your continued use of the Services
            following the posting of any changes to the Terms means you accept
            those new terms. In addition, some services offered through the
            Service may be subject to additional terms and conditions adopted by
            DN. Your use of those services means you agree to be subject to
            those additional terms and conditions, which are incorporated into
            these Terms by this reference.
          </p>
          <p>
            DN reserves the right to modify, suspend, or discontinue the
            Services (including, but not limited to, the availability of any
            feature, database, or Content), whether temporarily or permanently
            at any time for any reason. You agree that DN shall not be liable to
            your or to any third party for any modification, suspension, or
            discontinuation of the Services. DN may also impose limits on
            certain features and services or restrict your access to parts or
            all of the Services without notice or liability.
          </p>
          <p>
            You hereby represent and warrant that you are fully able and
            competent to enter into the terms, conditions, obligations,
            affirmations, representations and warranties set forth in these
            terms and to abide by and comply with these terms.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>3. Daily Number Account</h3>
          <span>Creating Your Daily Number Account </span>
          <p>
            You may view Content (as further defined below) on the Site or App
            without registering for an account, but as a condition of using
            certain aspects of the Services, including entering Contests through
            the Site or App, you are required to create a DN account (“Account,”
            as further defined below). You must be at least eighteen (18) years
            of age to open a DN account for any Paid Entry contests. You must be
            at least thirteen (13) years of age to open a DN account for any
            Free contests.
          </p>
          <p>
            To sign up for an Account, you must submit your full name, a
            username and password, as well as your mailing address, phone
            number, and date of birth. You must provide accurate, complete, and
            updated contact information, identification details, and any other
            form of authentication that DN may request in its sole discretion.
            To enter Contests that require you to make deposits, you may be
            required to submit your social security number. You must provide us
            with accurate and complete information when if, at any time, your
            account information changes.
          </p>
          <p>
            You may not use a username that promotes a commercial venture or a
            username that DN in its sole discretion deems offensive. DN may
            require you to change your username or may unilaterally change your
            username.
          </p>
          <p>
            You are solely responsible for maintaining the confidentiality of
            your account and password and for restricting access to your
            computer, and you agree to accept responsibility for all activities,
            charges, and damages that occur under your account. It shall be a
            violation of these Terms of Service to allow any other person to use
            your account to participate in any Contest. If you discover any
            unauthorized use of your account, or other known account-related
            security breach, you must report it to support@dailynumber.app
            immediately. You agree that you are responsible for anything that
            happens through your account until you close your account or prove
            that your account security was compromised due to no fault of your
            own. DN cannot and will not be liable for any loss or damage arising
            from your failure to comply with this section.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>One Account </span>
          <p>
            Each individual user may establish only one (1) Account. For
            avoidance of doubt, users may not “co-own” accounts on the Service.
            In the event DN discovers that you have opened more than one
            account, in addition to any other rights that DN may have, DN
            reserves the right to suspend or terminate any or all of your
            accounts and terminate, withhold, or revoke the awarding of any
            prizes.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Purpose of Account </span>
          <p>
            You agree that the sole and specific purpose of creating an account
            on DN is to participate in fantasy sports Contests. DN shall be
            entitled to suspend, limit, or terminate your account if we
            determine, in our sole discretion, that you are depositing funds
            without the intention of using them in Contests on the Service. In
            such circumstances, we may also report such activity to relevant
            authorities.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>4. Eligibility</h3>
          <p>
            Generally, you may have access to Free Contests (as further defined
            below) in any jurisdiction. There are restrictions, however, on who
            may have access to Paid Entry Contests (as further defined below),
            and where those persons may access the Paid Entry Contests. This
            Eligibility section applies to Paid Entry Contests.
          </p>
          <p>
            You must also be at least eighteen (18) years of age to participate
            in Contests or win prizes offered through DN Paid Entry Contests.
          </p>
          <p>
            In jurisdictions, territories, and locations where the minimum age
            for permissible use of Paid Entry Contests is greater than eighteen
            (18) years old, you must meet the age requirement in your local
            jurisdiction or territory. To enter a Paid Entry Contest, you must
            be at least nineteen (19) years of age if you are a legal resident
            of Alabama or Nebraska or twenty-one (21) years of age if you are a
            legal resident of Massachusetts. Legal residents physically located
            in any of the fifty (50) states and Washington, DC, excluding
            Alabama, Arizona, Hawaii, Idaho, Iowa, Louisiana, Montana, Nevada,
            and Washington (the “Prohibited States”) and Delaware, Indiana,
            Maine, Mississippi, Missouri, New Hampshire, New Jersey, New York,
            Ohio, Pennsylvania, Tennessee, Vermont, and Virginia (the
            “Legislated Omitted States”) (collectively with the Prohibited
            States, the “Excluded States”) are eligible to open an account and
            participate in Paid Entry Contests offered by DN.
          </p>
          <p>
            Legal residents of the Excluded States are eligible to open and
            maintain accounts on the Site for use only in games that do not
            offer prizes. However, legal residents of Alabama, Delaware, or
            Idaho who are physically located outside of the Excluded States are
            eligible to deposit funds, enter Contests and earn prizes offered by
            DN. Moreover, DN will begin offering Paid Entry Contests in the
            Legislated Omitted States upon obtaining proper licensing in each
            state. Please check these Terms for updates on included states.
          </p>
          <p>
            By depositing money or entering a Paid Entry Contest, you are
            representing and warranting that:
          </p>
          <ul>
            <li>
              you are of 18 years of age or older (or the minimum age for
              permissible use in your local jurisdiction).
            </li>
            <li>
              you are a citizen or resident of the United States of America and
              that you have an address in the United States of America.
            </li>
            <li>
              at the time of deposit or game entry you are physically located in
              a jurisdiction in which participation in the Contest is not
              prohibited by applicable law or these Terms.
            </li>
            <li>
              you are not listed on any U.S. Government list of prohibited or
              restricted parties.
            </li>
            <li>
              you will abide at all times by these Terms of Service and any
              other agreements between you and DN regarding your use of the
              Service or participation in games.
            </li>
            <li>
              when depositing funds or entering a paid Contest, you are not
              physically located in of any of the Excluded States.
            </li>
            <li>
              you are not subject to backup withholding tax because: (a) you are
              exempt from backup withholding, or (b) you have not been notified
              by the Internal Revenue Service (IRS) that you are subject to
              backup withholding as a result of a failure to report all interest
              or dividends, or (c) the IRS has notified you that you are no
              longer subject to backup withholding.
            </li>
            <li>
              When entering any Contest that awards prizes, you are not an
              Employee, Immediate Family Member, or operator of DN or any other
              daily fantasy site that charges entrance fees or offers cash
              prizes.
            </li>
            <li>
              You do not, by virtue of affiliation with another daily fantasy
              site, have access to the site’s pre-release non-public
              confidential data about Contest-related information.
            </li>
          </ul>
          <p />
          <p>
            DN employees may use the Service for the purpose of testing the user
            experience but may not withdraw money or prizes. Relatives of DN
            employees with whom they share a household are not eligible to
            participate in Contests unless they are Free Contests. DN
            consultants or promoters of the Service may play in Contests without
            such limitation, but only if (i) their arrangement with DN does not
            permit them to have any access to non-public Service data or any
            other data not made available to all players on the Service and (ii)
            they do not receive any other advantages in their play on the
            Service.
          </p>
          <p>
            If DN determines that you do not meet the eligibility requirements
            of this section, then you are not authorized to use the Service. DN
            may require you to provide proof that you are eligible to
            participate according to this section prior to receiving a prize.
            This includes by requesting that you fill out an affidavit of
            eligibility or other verification information. If DN otherwise
            determines that you do not meet the eligibility requirements of this
            section, in addition to any rights that DN may have in law or
            equity, DN reserves the right to terminate your account, withhold or
            revoke the awarding of any prizes associated with your account or
            limit your ability to withdraw. In such a situation, DN may pay out
            any withheld or revoked prizes to the other entrants in the relevant
            Contest in a manner consistent with the prize structure of the
            Contest, to be precisely determined by DN in its sole discretion. DN
            also reserves the right to withhold revoked prizes to use in
            furtherance of its fraud prevention or anti-money laundering
            efforts.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>5. Availability of the Services</h3>
          <p>
            You acknowledge that there may be interruptions in service or events
            that are beyond our control. While we use reasonable efforts to keep
            the Services accessible, the Services may be unavailable from time
            to time for any reason including, without limitation, system down
            time for routine maintenance. You further understand that there may
            be interruptions in service or events on third-party sites that may
            affect your use of the Services and that are beyond our control to
            prevent or correct. Interruptions in the Services that are beyond
            our control shall not serve as a basis to demand a full or partial
            refund of any prepaid fees.
          </p>
          <p>
            DN may limit access, via technological means, to the Site and the
            services in the Excluded States. If you attempt to deposit money
            into an Account or play in any Contest in which a prize is awarded
            while located in an Excluded State, you will be in violation of the
            law of such Excluded State and these Terms, and subject to having
            your Account suspended or terminated. You hereby agree that we
            cannot be held liable if laws applicable to you restrict or prohibit
            your participation. We make no representations or warranties,
            implicit or explicit, as to your legal right to participate in any
            service offered nor shall any person affiliated, or claiming
            affiliation, with us have authority to make any such representations
            or warranties. We do not intend that the Site or App and the
            services offered thereon to be used by persons present in
            jurisdictions in which the playing of fantasy sports Contests may be
            prohibited or restricted.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>6. Contests</h3>
          <p>
            After you create an account, you will be able to visit the Site or
            App and view the games available for entry (“Contests”). While some
            Contests may be free to enter, certain Contests on the Site or App
            will require an entry fee. It is your responsibility to know and
            understand the rules and required entry fees for any given Contest.
          </p>
          <p>
            DN offers two categories of Contests – Free Contests and Paid Entry
            Contests.
          </p>
          <ul>
            <li>
              Free Contests – Free contests are totally free. Users in free
              contests will pay nothing. Cash prizes are not issued by our
              system.
            </li>
            <li>
              Paid Entry Contests – Paid entry contests are our most popular
              format. DN collects an entry fee from each user as they join the
              Contest. Cash prizes are issued to contest winners by our system.
            </li>
          </ul>
          <p />
          <p>
            Please note that you must meet all eligibility requirements and
            otherwise comply with any other applicable rules to be eligible to
            win a prize. In particular, due to restrictions in state law,
            residents of the Excluded States are not eligible to participate in
            our Paid Entry Contests.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Entry Fee</span>
          <p>
            Paid Entry Contests each have an entry fee listed in US dollars. The
            entry fee may vary from Contest to Contest. When you choose to enter
            a Paid Entry Contest and complete the entry process, the requisite
            entry fee will be debited from your DN account. You may cancel your
            entry before a Contests starts, in which case the Entry Fee will be
            refunded to your account.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Conditions for Entry</span>
          <p>
            To be eligible to enter any Contest or receive any prize, you may be
            required to provide DN with additional documentation and/or
            information to verify your identity as the account holder, and to
            provide proof that all eligibility requirements are met. In the
            event of a dispute as to the identity or eligibility of an account
            holder, DN will, in its sole and absolute discretion, utilize
            certain information collected by DN to assist in verifying the
            identity and/or eligibility of such account holder.
          </p>
          <p>
            In the event of a dispute regarding the identity of the person
            submitting an entry, the entry will be deemed submitted by the
            person in whose username the entry was submitted, or if possession
            of the username itself is Contested and in DN’s opinion sufficiently
            uncertain, the name in which the email address on file was
            registered with the email service provider. DN reserves the right
            not to award a prize to an individual it believes in its sole
            discretion did not submit the winning entry.
          </p>
          <p>
            DN employees (“Employees”) and Immediate Family Members (an
            “Immediate Family Member” means any domestic partner and relative of
            the employee who resident at an employee’s residence, including but
            not limited to parents, grandparents, in-laws, children, siblings,
            and spouses) are not permitted to play in any Paid Entry Contest
            hosted on the Site or App; provided, however, Employees and
            Immediate Family Members are permitted to play in Free Contests.
          </p>
          <p>
            By entering a Contest, entrants agree to be bound by these Rules and
            the decisions of DN, which shall be final and binding in all
            respects. DN, at its sole discretion, may disqualify any entrant
            from a Contest, refuse to award benefits or prizes and require the
            return of any prizes, if the entrant engages in conduct or otherwise
            utilizes any information DN deems to be improper, unfair or
            otherwise adverse to the operation of the Contest or is in any way
            detrimental to other entrants. These Terms prohibit entering a
            Contest if the entrant is:
          </p>
          <ul>
            <li>
              Except as otherwise stated in the Eligibility section above, a DN
              Employee or an immediate family member of such employee;
            </li>
            <li>
              An employee or operator of any daily fantasy site including any
              that charges entrance fees or offers prizes, and any Immediate
              Family Member of any such person;
            </li>
            <li>
              Accessing or has had access to any pre-release, confidential
              information or other information that is not available to all
              other entrants of a Contest and that provides the entrant an
              advantage in such a Contest, including any information from any
              daily fantasy sport site or information from a sports governing
              body (e.g., pre-release injury information) (“Pre-Release Data”);
            </li>
            <li>
              An employee of a sponsor, consultant, or supplier of DN or any
              other daily fantasy sports Contest provider that has access to
              Pre-Release Data or otherwise receives an advantage in the
              entrant’s participation in a Contest;
            </li>
            <li>
              An employee of a sponsor, consultant, or supplier of DN or any
              other daily fantasy sports Contest provider that has access to
              Pre-Release Data or otherwise receives an advantage in the
              entrant’s participation in a Contest;
            </li>
            <li>
              A professional or amateur athlete, sports agent, coach, team
              owner, team employee, referee or league official or employee, or
              an immediate family member a professional or amateur athlete,
              sports agent, coach, team owner, team employee, referee or league
              official or employee if the Contest is in the league in which they
              are associated; or
            </li>
            <li>
              A person prohibited from participating pursuant to court order.
            </li>
          </ul>
          <p />
          <p>
            In addition, conduct that would be deemed improper also includes,
            but is not limited to:
          </p>
          <ul>
            <li>
              Falsifying any personal information required to create an account
              at the Site, or to enter a Contest or claim a prize;
            </li>
            <li>Unauthorized use of any third party’s Account;</li>
            <li>Creating multiple Accounts;</li>
            <li>
              Dealing, in any way, with any third party who may be able to
              influence the outcome of any Contest;
            </li>
            <li>
              Any attempt to tamper with, manipulate or influence the Services,
              software, or any security measure;
            </li>
            <li>
              Engaging in any type of financial fraud including unauthorized use
              of credit instruments to enter a Contest or claim a prize;
            </li>
            <li>
              Colluding with any other individual(s) or engaging in any type of
              syndicate play;
            </li>
            <li>Any violation of Contest rules or the Terms of Service;</li>
            <li>
              Using a single Account to participate in a Contest on behalf of
              multiple entrants or otherwise collaborating with others to
              participate in any Contest;
            </li>
            <li>
              Using automated means (including but not limited to scripts and
              third-party tools) to interact with the Site or App in any way
              (this includes, but is not limited to: creating a Contest,
              entering a Contest, withdrawing from a Contest, creating a lineup,
              and editing a lineup);
            </li>
            <li>
              Using automated means (including but not limited to harvesting
              bots, robots, parser, spiders or screen scrapers) to obtain,
              collect or access any information on the Site or App or of any
              User for any purpose; or
            </li>
            <li>Abusing the Site in any way.</li>
          </ul>
          <p />
          <p>
            Users further acknowledge that the forfeiture and/or return of any
            prize shall in no way prevent DN from pursuing criminal or civil
            proceedings in connection with such conduct.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Indemnification for Contest Entry</span>
          <p>
            By entering into a Contest or accepting any prize, entrants,
            including but not limited to the winner(s), agree to indemnify,
            release and to hold harmless DN, its parents, subsidiaries,
            affiliates and agents, as well as the officers, directors,
            employees, shareholders and representatives of any of the foregoing
            entities, from any and all liability, claims or actions of any kind
            whatsoever, including but not limited to injuries, damages, or
            losses to persons and property which may be sustained in connection
            with participation in the Contest, the receipt, ownership, use or
            misuse of any prize or while preparing for, participating in and/or
            travelling to or from any prize related activity, as well as any
            claims based on publicity rights, defamation, or invasion of
            privacy. DN may, in its sole and absolute discretion, require the
            account holder to execute a separate release of claims similar to
            the one listed above in this Paragraph as a condition of being
            awarded any prize or receiving any payout.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Contest of Skill – Paid Entry Contests</span>
          <p>
            Paid Entry Contests offered on the Site or App are Contests of
            skill. DN offers fantasy sports Contests where you must create a
            lineup that scores enough points to beat the target number provided
            by DN. Draft requirements, player rankings, entry fees and scoring
            may differ from Contest to Contest. Each of our Contests are
            governed by specific rules, as may be modified from time to time,
            which are set forth in the Contest Rules and are incorporated into
            these Terms by this reference. Winners are determined by the
            objective criteria described in the Contest deadline, roster, Rules,
            scoring, and any other applicable documentation associated with the
            Contest. From all entries received for each Contest, winners are
            determined by the individuals who use their skill and knowledge of
            relevant sports information and fantasy sports rules to accumulate
            the most points according to the corresponding scoring rules and
            accumulate more points than the Daily Number for that contest. The
            Site, App, and Contests may not be used for any form of illicit
            gambling.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Contest Results</span>
          <p>
            Prizes will only be awarded if a Contest is run. We reserve the
            right to cancel Contests at any time. In the event of a
            cancellation, all entry fees will be refunded to the customer except
            as specifically provided in these Terms of Service.
          </p>
          <p>
            The players in each Contest who accumulate enough fantasy points to
            beat the target number and comply with eligibility requirements and
            applicable rules will win prizes as set out in the posted Contest
            details. In the event a player lineup scores the exact equivalent
            amount of points as the target score, this “tie” will be determined
            in favor of the player, and the player will receive the full prize
            amount.
          </p>
          <p>
            After each Contest ends, the tentative winners are announced
            (generally by the following day) but remain subject to final
            verification. Winners may be requested to return via email or
            regular mail an affidavit of eligibility, a publicity agreement and
            appropriate tax forms by a specified deadline. Failure to comply
            with this requirement can result in disqualification. Any prize
            notification returned as undeliverable may result in
            disqualification and selection of an alternate winner. In addition,
            a list of winners and winner’s names for each public Contest may be
            obtained by writing to: Daily Number, Inc. 421 W 3rd Street, Unit
            513, Austin, Texas 78701.
          </p>
          <p>
            Once winners are determined and prizes are awarded, the scoring
            results will not be changed regardless of any official adjustments
            made by the professional leagues, though we reserve the right to
            make adjustments based on errors or irregularities in the
            transmission of information to us from our stats provider or in our
            calculation of results.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Prizes</span>
          <p>
            Contest prize payouts will be published with the creation of each
            new Contest (“Contest Prize Table”).
          </p>
          <p>
            Prizes will be awarded as soon as reasonably practicable following
            the conclusion of each Contest. The prizes available for each
            Contest are published in the respective Contest Prize Table.
          </p>
          <p>
            No substitution or transfer of prize is permitted except at our sole
            discretion. All prizes are awarded AS IS and WITHOUT WARRANTY OF ANY
            KIND, express or implied, (including, without limitation, any
            implied warranty of merchantability or fitness for a particular
            purpose) by us. If any legal authority challenges the award and/or
            your receipt of a prize for winning a Contest, DN reserves the right
            in its sole discretion to revoke, cancel, suspend, substitute, or
            modify the award of such prize. In all disputes arising out of the
            determination of the winner of DN Contests, DN is the sole judge and
            its actions are final and binding.
          </p>
          <p>
            You acknowledge and agree that should we, in our sole and absolute
            discretion, determine that you did not comply with and or adhere to
            the Terms of Service, in any way, we may disqualify you from any
            Contest entered, in which case any prizes awarded to you as a result
            of said Contest will be immediately forfeited. You agree to
            cooperate with our efforts to reverse payments.
          </p>
          <p>
            All monetary winnings will be deposited directly into your Account.
            Any amounts that are mistakenly credited as winnings to your Account
            remain our property and will automatically be transferred from your
            Account upon confirmation of the error. Any winnings mistakenly
            credited to your Account that have been withdrawn by you before
            confirmation of the error will constitute a debt owed by you.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Contest Statistics and Live Scoring</span>
          <p>
            To the extent that we offer ‘live’ statistics during gameplay, all
            ‘live’ statistics and other information provided through the Site or
            App and related information sources are unofficial. Live sports
            statistics and their respective components are offered for
            informational and/or entertainment purposes only and are not used to
            determine the results of our Contests. While DN and the third
            parties used to provide the Services use reasonable efforts to
            include accurate and up-to-date information, neither DN nor its
            third-party providers warrant or make any representations of any
            kind with respect to the information provided through the Site or
            App and related information sources. DN and its third-party
            providers shall not be responsible or liable for the accuracy,
            usefulness, or availability of any information transmitted or made
            available via the Site or App and related information sources, and
            shall not be responsible or liable for any error or omissions in
            that information.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>7. Contest Disqualification and Cancellation</h3>
          <p>
            You may withdraw from any Contest entered, at any time, prior to
            either the acceptance by us of the final Contestant’s entry, or the
            commencement of the Contest, whichever is sooner. If you timely
            cancel an entry, your entry fee will be refunded back into your
            Account. DN has no obligation to honor cancellation requests
            received after the start of a Contest.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>NO REFUND</span>
          <p>
            Deposits made on our site will appear on your statement as DAILY
            NUMBER LTD. All payments are final. No refunds will be issued. In
            the event of a dispute regarding the identity of the person
            submitting an entry, the entry will be deemed submitted by the
            person in whose name the account was registered.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Disqualification and Cancellation by Daily Number</span>
          <p>
            Participation in each Contest must be made only as specified in the
            Terms of Service. Failure to comply with these Terms of Service will
            result in disqualification and, if applicable, prize forfeiture.
          </p>
          <p>
            DN, in its sole discretion, may disqualify you from a Contest or the
            entire Service, refuse to award fantasy points or prizes and require
            the return of any prizes, or suspend, limit, or terminate your
            Account if you engage in conduct DN deems, in its sole discretion,
            to be improper, unfair, fraudulent or otherwise adverse to the
            operation of the Service or in any way detrimental to other users.
            Improper conduct includes, but is not limited to: falsifying
            personal information, including payment information, required to use
            the Service or claim a prize; violating eligible payment method
            terms, including the terms of any cash rewards payment card,
            violating any of these rules, accumulating points or prizes through
            unauthorized methods such as unauthorized scripts or other automated
            means; tampering with the administration of the Service or trying to
            in any way tamper with the computer programs associated with the
            Service; obtaining other entrants’ information and spamming other
            entrants; and abusing the Service in any way; or otherwise violating
            these Terms of Service. You acknowledge that the forfeiture and/or
            return of any prize shall in no way prevent DN from informing the
            relevant authorities, and/or pursuing criminal or civil proceedings
            in connection with such conduct.
          </p>
          <p>
            We reserve the right, in our sole discretion, to cancel or suspend
            any Contest (or any portion thereof) for any reason whatsoever,
            including but not limited to, the safeguarding of the
            administration, security, fairness, integrity or proper operation of
            the Contest (or any portion thereof). This section specifically
            includes when a Contest Prize Table has been tampered with. We may
            provide you with notification of such cancellations or suspensions,
            but will not be obliged to do so. In the event that a Contest is
            cancelled altogether, no Contest entry fee will be payable by you
            and any amounts that were to be used for the purpose of entering the
            Contest will once again be made available to you via your account.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>8. Deposits and Withdrawals</h3>
        </div>
        <div className="serviceTermsWrap">
          <span>Payment</span>
          <p>
            Creating a DN account is free. However, there are associated fees
            for certain Contests and Account types. When you use a service that
            has a fee you will have an opportunity to review and accept the fees
            that you will be charged.
          </p>
          <p>
            To pay for such services, you must provide DN with the information
            necessary to process such order including, but not limited to, your
            billing address and payment method. DN may also request your Social
            Security Number and a copy of your driver’s license. You agree to
            pay DN the charges incurred in accordance with these Terms and
            applicable pay services. If you dispute any charges, you must inform
            DN in writing within thirty (30) of being billed by DN. If our fees
            ever change, the changes to fees are effective after we provide you
            with notice by posting the changes on the Services or by email.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Deposits</span>
          <p>
            To participate in Paid Entry Contests, you will be required to
            deposit funds into your Account by any of the methods permitted on
            the Site. Such funds will be deposited into your Account upon actual
            receipt of the funds by DN. Minimum and maximum limits may be
            applied to deposits into your Account, depending upon your history
            with the Site, the method of deposit, state-imposed deposit limits,
            and other factors as determined solely by DN. We are not a bank and
            funds are not insured by any governmental agency. No interest is
            payable on amounts on deposit in your Account. All payments into
            your Account must be from a payment source on which you are the
            named account holder. It shall be a violation of these Terms for you
            to submit payment using any payment method that you are not the
            named account holder. In addition, to be eligible to deposit money
            into your Account and play in Contests that award prizes, you must
            not be subject to backup withholding tax from the IRS.
          </p>
          <p>
            If any deposit is charged back any winnings generated from DN
            Contests shall be invalidated, forfeited and deducted from your
            Account balance. In addition, the amount of the initial deposit will
            be invalidated, forfeited and deducted from the Account balance. DN
            reserves the right to close your account – without notice – should a
            deposit be charged back.
          </p>
          <p>
            By depositing funds or entering paid Contests, you agree to provide
            us with a valid mailing address, date of birth and social security
            number and any other information we may require in order to run
            appropriate identity checks and comply with applicable rules and
            regulations. If necessary, you may be required to provide
            appropriate documentation that allows us to verify you. While your
            account is pending verification, you may be able to deposit funds
            into your account and participate in Contests, but you will not be
            able to withdraw any funds from your account until verification is
            complete. If we are unable to verify you, we reserve the right to
            suspend your account and withhold any funds until such time as we
            have been able to successfully verify you.
          </p>
          <p>
            We also may conduct checks for Terms compliance, including
            anti-fraud checks on playing patterns and deposits prior to
            processing a withdrawal, and we may request additional information
            before permitting a withdrawal. If DN requests that an entrant
            completes and executes such an affidavit and the entrant fails to do
            so within ten (10) days, or DN otherwise determines that the entrant
            does not meet the eligibility requirements or is not in compliance
            with these Terms, DN reserves the right to terminate the entrant’s
            account and withhold or revoke the awarding of any prizes associated
            with such account. In such a situation, DN may pay out any withheld
            or revoked prizes to the other entrants in the relevant Contest in a
            manner consistent with the Rules of the Contest.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Account for Deposited Funds</span>
          <p>
            When you make a deposit, or earn winnings, your deposits and
            winnings after Contests are completed are held in a separate,
            segregated bank account (the “Segregated Account”) held by Daily
            Number LTD, a legally separate and independent subsidiary of Daily
            Number, Inc. Withdrawals which will be made from this Segregated
            Account, and checks issued from the Segregated Account, may bear the
            names of Daily Number LTD. Vendors, players, and creditors shall be
            aware that the funds in the Segregated Account are the property of
            Daily Number LTD and do not belong to Daily Number, Inc., and are
            not available to creditors of Daily Number, Inc., except for players
            whose funds are held in such account. Daily Number, Inc. and Daily
            Number LTD are prohibited from comingling funds held by Daily
            Number, Inc. and funds belonging to Daily Number LTD held in the
            Segregated Account. These funds belong to you, subject to review for
            evidence of fraud, verification or other prohibited conduct as
            described above, and DN may not use them to cover its operating
            expenses or for other purposes.
          </p>
          <p>
            Players who believe that funds held by or their accounts with Daily
            Number, Inc. have been misallocated, compromised or otherwise
            mishandled, may register a complaint with Daily Number, Inc. online
            by e-mailing support@dailynumber.app or in writing to 421 W 3rd
            Street, Unit 513, Austin, Texas 78701. Daily Number, Inc. shall use
            its best efforts to respond to such complaints within ten (10) days.
            If Daily Number, Inc. determines that the relief requested in the
            complaint will not be granted, its response will set forth the
            specific reasons therefore. If more information is required for
            Daily Number, Inc. to process the complaint, the response will note
            the form and nature of the necessary additional information needed.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Bonuses and Promotions</span>
          <p>
            From time to time, DN may provide you with promotional discounts or
            bonuses – for example, as an incentive to use DN Services, to
            establish a DN account or to refer others to sign up with DN. Such
            promotional programs will be governed by their own terms and
            conditions which will be presented at the time of such promotions.
            Unless otherwise provided, promotional discounts are
            non-transferrable.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Withdrawals</span>
          <p>
            You may request a withdrawal of funds from your available cash
            balance in your Account at any time. The minimum withdrawal is $5.00
            U.S. dollars. Account holders with pending deposits must wait until
            those funds clear before requesting a withdrawal. Withdrawals shall
            only be provided first via refund of the deposit amount to the
            original form of payment and the balance thereafter via a check
            payable to you or an ACH bank transfer to your account. Outside of
            refunding the original deposit amount to the original form of
            payment, DN does not permit withdrawals to a bank card or credit
            card.
          </p>
          <p>
            Entrants may withdraw their cash prize awards as well as cash
            deposits by using the “Withdrawal” option on the Site or App.
            Entrants may be requested to complete an affidavit of eligibility
            and a liability/publicity release (unless prohibited by law) and/or
            appropriate tax forms and forms of identification as reasonably
            requested by DN in order to complete the withdrawal of prizes. We
            also may conduct checks for Terms compliance, including anti-fraud
            checks on playing patterns and deposits prior to processing a
            withdrawal, and we may request additional information before
            permitting a withdrawal. Failure to comply with this requirement may
            result in disqualification and forfeiture of any prizes.
            Disqualification or forfeiture of any prizes may also occur if it is
            determined any such entrant did not comply with these Terms of
            Service in any manner.
          </p>
          <p>
            Once per month, Daily Number, Inc. shall reconcile all liabilities
            owed to users and the sum of funds or credits in the Segregated
            Account and credits due to Daily Number, Inc. from credit card
            processors as of the end date of the immediately preceding month.
          </p>
          <p>
            Processing of requested funds back may take up to five (5) business
            days; provided, however, DN reserves the right to freeze your
            Account and/or delay a request for withdrawal of funds pending
            completion of any investigation of reported or suspected abuse by a
            user requesting withdrawal of funds. If you do not receive your
            withdrawal within five (5) business days, please contact customer
            support at support@dailynumber.app. Checks for withdrawal requests
            are processed within 14 business days, and are sent via U.S. Mail.
          </p>
          <p>
            If your Account is closed by us for a violation of the Terms, we
            reserve the right to determine whether to declare as void any
            transaction placed by you. If a prize has been awarded on a closed
            account due to fraud DN may withhold the prize. In such an event, we
            use your Account funds to defray the costs of administration and
            enforcement of the Terms.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>U.S. Dollars</span>
          <p>
            All deposits and withdrawals will be denominated in U.S. dollars
            unless otherwise expressly stated. If you make a deposit that is
            denominated in a currency other than U.S. dollars, please be aware
            that the funds will be converted into U.S. dollars by our Payment
            Processors (defined below) or your financial institution and you may
            be charged a service fee for such conversion. Please also be aware
            that our Payment Processors and/or your financial institution may
            use different conversion rates for deposit and refund transactions.
            The conversion rate used is not under our control and we recommend
            that you contact your financial institution directly for more
            information.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Taxation</span>
          <p>
            All taxes associated with the receipt of any prize are the sole
            responsibility of the winner. In the event that the awarding of any
            prizes to winners of Contests is challenged by any legal authority,
            DN reserves the right in its sole discretion to determine whether or
            not to award such prizes.
          </p>
          <p>
            Each year all winners who have won $600 or more over the previous
            year must provide updated address and social security details to DN.
            These details will be used to allow DN to comply with tax
            regulations and may be shared with appropriate tax authorities. It
            is the policy of the Site, and in compliance with United States
            Internal Revenue Service regulations, DN may be required to submit a
            Form 1099-MISC, Miscellaneous Income (Form 1099) with the Internal
            Revenue Service (IRS) or other appropriate form to any person who
            wins in excess of $600 (USD) on the Site (winnings less entry fees)
            in any given year. Depending on the jurisdiction in which you
            reside, DN may require you to complete a Form W-9, Request for
            Taxpayer Identification Number (TIN) and Certification and/or
            additional tax forms. This information will be used to file the Form
            1099 with the IRS. DN reserves the right to withhold (from your
            existing account balance and/or from future net winnings) any amount
            required to be withheld by law. You remain solely responsible for
            filing and paying all federal and other taxes in accordance with the
            laws that apply in your local, state, province, and/or country of
            residence. DN does not provide tax advice, nor should any statements
            in this agreement or on the Service be construed as tax advice.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Third-Party Payment Processor</span>
          <p>
            DN uses third-party electronic payment processors and financial
            institutions (“Payment Processors”) to process deposits and/or
            payments for services or products offered via the Site or App. The
            information that we provide to and receive from these Payment
            Processors and the manner in which such information is used and
            disclosed is described in further detail in the DN Privacy Policy.
            You irrevocably authorize us, as necessary, to instruct such Payment
            Processors to handle payments and you irrevocably agree that DN may
            give such instructions on your behalf in accordance with your
            requests as submitted through the Site or App. You agree to be bound
            by the terms and conditions of each applicable Payment Processor,
            and in the event of a conflict between these Terms and the Payment
            Processors’ terms and conditions, then these Terms shall prevail.
            You further agree that DN is not liable for any loss caused by any
            unauthorized use of your credit card or other method of payment by a
            third party in connection with your use of the Site or App, except
            as a result of the gross negligence of DN or its employees. More
            information about the data collection by our payment processor can
            be found at{' '}
            <a
              className="anchorColor" onClick={()=> contactSupport('openWireCardBankPdf')}>
              https://www.wirecardbank.com/GDPR
            </a>
            .
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>9. Publicity</h3>
          <p>
            By entering a Contest, you consent to DN’s and its service
            providers’ and business partners’ use of your name, voice, likeness,
            location and photograph in connection with the development,
            production, distribution and/or exploitation (including marketing
            and promotion) of the selected Contest and/or other DN Contests and
            DN generally, unless otherwise prohibited by law. Daily Number, Inc.
            and its business partners reserve the right to make public
            statements about the entrants and winner(s), on-air, on the
            Internet, or otherwise, prior to, during, or following the Contest.
            Entrants agree that DN may announce any winner’s name on-air or on
            any of its websites or any other location at any time in connection
            with the marketing and promotion of DN or other Contests or games
            operated by DN. You agree that participation in and (where
            applicable) the winning of a prize in connection with a Contest
            constitute complete compensation for your obligations under this
            paragraph, and you agree not to seek to charge a fee or impose other
            conditions on the fulfillment of these obligations. The rules
            specific to certain Contests may contain additional publicity
            obligations or may require a written signature on a separate
            publicity waiver.
          </p>
          <p>
            YOU FURTHER ACKNOWLEDGE THAT IF YOU ARE A WINNER IN A PUBLIC
            CONTEST, YOUR IDENTIFYING INFORMATION MAY BE DISCLOSED TO THIRD
            PARTIES INCLUDING, WITHOUT LIMITATION, HAVING YOUR NAME OR USERNAME
            PLACED A WINNERS’ LIST.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>10. Rules and Conduct</h3>
          <p>
            As a condition of use, you promise not to use the Services for any
            purpose that is prohibited by the Terms or law. The Services are
            provided only for your own personal, non-commercial use. You are
            responsible for all of your activity in connection with the
            Services.
          </p>
          <p>
            You understand and agree that you will not use the Services to
            engage in the prohibited conduct below:
          </p>
          <ol>
            <li>
              You shall not use the Services for any illegal purpose, or in
              violation of any local, state, national, or international law,
              including, without limitation, laws governing intellectual
              property and other proprietary rights, data protection and
              privacy, and import or export control;
            </li>
            <li>
              You shall not post, store, send, transmit, or disseminate any
              information or material which infringes any patents, trademarks,
              trade secrets, copyrights, or any other proprietary or
              intellectual property rights;
            </li>
            <li>
              You shall not attempt to use any method to gain unauthorized
              access to any features of the Services;
            </li>
            <li>
              You shall not directly or indirectly decipher, decompile, remove,
              disassemble, reverse engineer, or otherwise attempt to derive any
              source code or underlying ideas or algorithms of any part of the
              Services, security-related features of the Services, features that
              prevent or restrict use or copying of any content accessible
              through the Services, or features that enforce limitations on use
              of the Services, except to the extent applicable laws specifically
              prohibit such restriction;
            </li>
            <li>
              You shall not directly or indirectly modify, translate, or
              otherwise create derivative works of any part of the Services;
            </li>
            <li>
              You shall not directly or indirectly copy, rent, lease,
              distribute, or otherwise transfer any of the rights that you
              receive hereunder;
            </li>
            <li>
              You shall not directly or indirectly take any action that
              constitutes unsolicited or unauthorized advertising or promotional
              material or any junk mail, spam, or chain letters; contains
              software viruses or any other computer codes, files, or programs
              that are designed or intended to disrupt, damage, limit, or
              interfere with the proper function of any software, hardware, or
              telecommunications equipment or to damage or obtain unauthorized
              access to any system, data, password, or other information of DN
              or any third party; or that impersonates any person or entity,
              including any employee or representative of DN;
            </li>
            <li>
              You shall not directly or indirectly take any action that imposes
              or may impose (as determined by DN in its sole discretion) an
              unreasonable or disproportionately large load on DN’s or its
              third-party providers’ infrastructure; interfere or attempt to
              interfere with the proper working of the Service or any activities
              conducted on the Service; run Maillist, Listserv, or any form of
              auto-responder or “spam” on the Service; or use manual or
              automated software, devices, or other processes to “crawl” or
              “spider” any page of the Site; and
            </li>
            <li>You shall not sell or otherwise transfer your profile.</li>
            <li>
              You are prohibited from posting content that: is direct or
              specific threat of violence to others; is in furtherance of
              illegal activities; is harassing, hateful, libelous, defamatory,
              abusive, or constitutes spam; or is pornographic, predatory,
              sexually graphic, racist, offensive, harmful to a minor, or would
              otherwise violate the rights of any third party or give rise to
              civil or criminal liability.
            </li>
          </ol>
          <p />
          <p>
            If for any reason, DN determines that you have failed to follow
            these rules, we reserve the right to prohibit any and all current or
            future use of the Services (or any portion thereof) by you. If we
            have reason to suspect, or learn that anyone is violating these
            Terms, we may investigate and/or take legal action as necessary
            including bringing a lawsuit for damages caused by the violation. We
            reserve the right to investigate and take appropriate legal action,
            including without limitation, cooperating with and assisting law
            enforcement or government agencies in any resulting investigations
            of illegal conduct.
          </p>
          <p>
            ANY ATTEMPT BY AN ENTRANT OR ANY OTHER INDIVIDUAL TO DELIBERATELY
            DAMAGE THE SITE OR APP OR UNDERMINE THE LEGITIMATE OPERATION OF ANY
            CONTEST IS A VIOLATION OF CRIMINAL AND/OR CIVIL LAWS AND SHOULD SUCH
            AN ATTEMPT BE MADE, DN RESERVES THE RIGHT TO SEEK DAMAGES AND OTHER
            REMEDIES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY
            LAW.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>11. Termination of Usage</h3>
          <p>
            You may cancel your account at any time through your account
            settings or by sending us an email at support@dailynumber.app.
            Unless DN is in breach of this Agreement and does not cure said
            breach within thirty (30) days of receiving written notice from you
            of an actual breach, identifying specifically the nature of the
            breach, you are not entitled to any refunds. We may suspend or
            cancel your account without notice or refund to you if you violate
            this Agreement. If your account is cancelled, DN reserves the right
            to remove your account information along with any account settings
            from our servers with NO liability or notice to you. Once your
            account information and account settings are removed, you will not
            be able to recover this data and you will lose access to all of your
            content (except that content stored/published to third-party
            websites, that data will remain on said third-party websites
            pursuant to those website’s terms and conditions).
          </p>
          <p>
            Following termination of your account, however, your license to use
            DN’s Content automatically terminates, and DN has no obligation to
            provide you with use of the Services. All provisions of these Terms
            that by their nature should survive termination shall survive
            termination, including, without limitation, ownership provisions,
            warranty disclaimers, indemnity, and limitations of liability. You
            acknowledge and understand that our rights regarding any content you
            submitted to the website before your account was terminated shall
            survive termination.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>12. Advertisements and Third-Party Sites</h3>
          <p>
            The Services may contain third-party advertisements and/or
            sponsorships. The advertisers and sponsors that provide these
            advertisements or sponsorships are solely responsible for insuring
            that the materials submitted for inclusion on the Services are
            accurate and that they comply with all applicable laws. We are not
            responsible for the acts or omissions of any sponsor or advertiser.
          </p>
          <p>
            Additionally, the Services may permit you to link to other websites
            or resources on the internet. Links on the Services to third party
            websites, if any, are provided only as a convenience to you. If you
            use these links, you will leave the Services. The inclusion or
            integration of third-party services or links does not imply control
            of, endorsement by, or affiliation with DN. Your dealings with third
            parties are solely between you and such third parties. You agree
            that DN will not be responsible or liable for any content, goods or
            services provided on or through these outside websites or for your
            use or inability to use such websites. You will use these links at
            your own risk.
          </p>
          <p>
            Without limiting the foregoing, your correspondence or business
            dealings with, participation in promotions of or purchases from,
            third parties found on or through the use of the Services, including
            payment for and delivery of related goods or services, and any other
            terms, conditions, warranties or representations associated with
            such dealings, are solely between you and such third party. You
            agree that DN shall not be responsible or liable for any loss or
            damage of any sort incurred as the result of any such dealings or as
            the result of the presence of such advertisers on the Services.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>13. Terms Applicable for Apple iOS</h3>
          <p>
            If you are accessing or using the Services through a device
            manufactured and/or sold by Apple, Inc. (“Apple”, with such device
            herein referenced as an “Apple Device”):
          </p>
          <ul>
            <li>
              to the extent that you are accessing the App through an Apple
              Device, you acknowledge that these Terms are entered into between
              you and DN and, that Apple is not a party to these Terms other
              than as third-party beneficiary as contemplated below;
            </li>
            <li>
              the license granted to you in Section 14 of these Terms is subject
              to the permitted Usage Rules set forth in the Apple App Store
              Terms and Conditions and any third-party terms of agreement
              applicable to the App;
            </li>
            <li>
              you acknowledge that DN, and not Apple, is responsible for
              providing the App and Content thereof;
            </li>
            <li>
              you acknowledge that Apple has no obligation whatsoever to furnish
              any maintenance or any support services to you with respect to the
              App;
            </li>
            <li>
              to the maximum extent permitted by applicable law, Apple will have
              no other warranty obligation whatsoever with respect to the App;
            </li>
            <li>
              notwithstanding anything to the contrary herein, and subject to
              the terms in these Terms, you acknowledge that, solely as between
              Apple and DN, DN and not Apple is responsible for addressing any
              claims you may have relating to the App, or your possession and/or
              use thereof, including, but not limited, to: (i) product liability
              claims, (ii) any claim that the App fails to conform to any
              applicable legal or regulatory requirement; and (iii) claims
              arising under consumer protection or similar legislation;
            </li>
            <li>
              further, you agree that if the App, or your possession and use of
              the App, infringes on a third party’s intellectual property
              rights, you will not hold Apple responsible for the investigation,
              defense, settlement and discharge of any such intellectual
              property infringement claims;
            </li>
            <li>
              you acknowledge and agree that Apple, and Apple’s subsidiaries,
              are third-party beneficiaries of these Terms, and that, upon your
              acceptance of the terms and conditions of these Terms, Apple will
              have the right (and will be deemed to have accepted the right) to
              enforce these Terms against you as a third-party beneficiary
              thereof;
            </li>
            <li>
              when using the App, you agree to comply with any and all
              third-party terms that are applicable to any platform, website,
              technology or service that interacts with the App; and
            </li>
            <li>
              you represent and warrant that: (i) you are not located in a
              country that is subject to a U.S. Government embargo or that has
              been designated by the U.S. Government as a “terrorist supporting”
              country; and (ii) you are not listed on any U.S. Government list
              of prohibited or restricted parties.
            </li>
          </ul>
          <p />
          <p>
            Apple Inc. is not a sponsor of, or involved in any way with Daily
            Number contests.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>14. Daily Number Intellectual Property and User Content</h3>
        </div>
        <div className="serviceTermsWrap">
          <span>Intellectual Property</span>
          <p>
            Through the Site, email, and other media, the Services make
            accessible various content, including, but not limited to, videos,
            photographs, images, artwork, graphics, audio clips, comments, data,
            text, software, scripts, campaigns, other material and information,
            and associated trademarks and copyrightable works (collectively,
            “Content”). Users of the Services may have the ability to
            contribute, add, create, submit, distribute, facilitate the
            distribution of, collect, post, or otherwise make accessible
            Content.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Intellectual Property</span>
          <p>
            Through the Site, email, and other media, the Services make
            accessible various content, including, but not limited to, videos,
            photographs, images, artwork, graphics, audio clips, comments, data,
            text, software, scripts, campaigns, other material and information,
            and associated trademarks and copyrightable works (collectively,
            “Content”). Users of the Services may have the ability to
            contribute, add, create, submit, distribute, facilitate the
            distribution of, collect, post, or otherwise make accessible
            Content.
          </p>
          <p>
            DN owns or in the process of registering trademarks for its many
            goods and services, including the DN name and the associated
            graphics, logos and service marks and may not be used without prior
            written consent of DN. All other trademarks, product names, and
            company names and logos appearing within the Site or App are the
            property of their respective owners.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Our Content</span>
          <p>
            Our content is protected in many ways, including copyrights,
            trademarks, service marks, and other rights and laws. You agree to
            respect all legal notices, information, and restrictions contained
            in any content accessed through the Services. You also agree not to
            change, translate, or otherwise create derivative works based off
            our content.
          </p>
          <p>
            You have a limited, revocable, non-exclusive, non-transferrable
            license to use the Services and our content solely for legally
            permitted activities related to our Services as outlined in these
            Terms.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>User Content</span>
          <p>
            We, including third party partners and our affiliates, may ask you
            for Feedback (as further defined below) on your experience with the
            Services. DN shall become the owner of any reviews, comments,
            suggestions or other feedback regarding the Services posted to the
            Services or on DN’s social media pages (collectively, “Feedback”)
            and it may share with any of its affiliates. DN will not be required
            to treat any Feedback as confidential and will not be liable for any
            Feedback posted on the Services or elsewhere. Without limitation, DN
            will have exclusive ownership of all present and future existing
            rights to the Feedback of every kind and nature everywhere and will
            be entitled to use the Feedback for any commercial or other purpose
            whatsoever, including to advertise and promote DN, without
            compensation to you or any other person sending the Feedback. You
            specifically waive any “moral rights” in and to the User Content.
          </p>
          <p>
            To the extent that you decide to post any content (“User Content”),
            including your profile information, photos, or Feedback, on the
            Services or on DN’s social media pages, you agree that your User
            Content will not contain third-party copyrighted material, or
            material that is subject to other third-party proprietary rights,
            unless you have permission from the rightful owner of the material
            or you are otherwise legally entitled to post the material and to
            grant DN all of the license rights granted herein. You acknowledge
            that you are responsible for whatever material you submit, and you,
            not DN, have full responsibility for the Feedback, including its
            legality, reliability, appropriateness, originality, and copyright.
            DN may refuse to accept or transmit User Content. Additionally, DN
            shall have the right to delete, edit, modify, reformat, excerpt, or
            translate any of your User Content.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>15. Copyright and Trademark Notices</h3>
        </div>
        <div className="serviceTermsWrap">
          <span>Copyright Policy</span>
          <p>
            DN complies with the Digital Millennium Copyright Act (“DMCA”). DN
            will remove infringing materials in accordance with the DMCA if
            properly notified that Content infringes copyright. If you believe
            that your work has been copied in a way that constitutes copyright
            infringement, please notify DN’s Copyright Agent by email at
            support@thedailynumber.com or by mail to the address in the Contact
            section. Please do not send notices or inquiries about anything
            other than alleged copyright infringement or other intellectual
            property claims to our Agent for Notice. Your email must contain the
            following information (please confirm these requirements with your
            legal counsel, or see the U.S. Copyright Act, 17 U.S.C. Â§512(c)(3),
            for more information):
          </p>
          <ul>
            <li>
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright interest;
            </li>
            <li>
              information reasonably sufficient to permit us to contact you,
              such as an address, telephone number, and, if available, an e-mail
              address;
            </li>
            <li>
              a description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              a description of where the material that you claim is infringing
              is located on the Services, sufficient for DN to locate the
              material; your address, telephone number, and email address;
            </li>
            <li>
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law; and
            </li>
            <li>
              a statement by you that the information in your notice is accurate
              and, under penalty of perjury, that you are the copyright owner or
              authorized to act on the copyright owner’s behalf.
            </li>
          </ul>
          <p />
          <p>
            If you fail to comply with these notice requirements, your
            notification may not be valid. Under the Copyright Act, any person
            who knowingly materially misrepresents that material is infringing
            or was removed or disabled by mistake or misidentification may be
            subject to liability.
          </p>
          <p>
            In accordance with the Digital Millennium Copyright Act, DN has
            adopted a policy of, in appropriate circumstances, terminating User
            accounts that are repeat infringers of the intellectual property
            rights of others. DN also may terminate User accounts even based on
            a single infringement.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Counter-Notice</span>
          <p>
            If you believe that your Content that was removed (or to which
            access was disabled) is not infringing, or that you have the
            authorization from the copyright owner, the copyright owner’s agent,
            or pursuant to the law, to post and use the material in your
            Content, you may send a written counter-notice containing the
            following information to the Copyright Agent: (1) Your physical or
            electronic signature; (2) Identification of the Content that has
            been removed or to which access has been disabled and the location
            at which the Content appeared before it was removed or disabled; (3)
            A statement that you have a good faith belief that the Content was
            removed or disabled as a result of mistake or a misidentification of
            the Content; and (4) Your name, address, telephone number, and email
            address, a statement that you consent to the jurisdiction of the
            federal court in Texas, and a statement that you will accept service
            of process from the person who provided notification of the alleged
            infringement. If a counter-notice is received by the Copyright
            Agent, DN may send a copy of the counter-notice to the original
            complaining party informing that person that it may replace the
            removed Content or cease disabling it in 10 business days. Unless
            the copyright owner files an action seeking a court order against
            the Content provider, member or user, the removed Content may be
            replaced, or access to it restored, in 10 to 14 business days or
            more after receipt of the counter-notice, at DN’s sole discretion.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Trademark Policy</span>
          <p>
            The trademarks, service marks, and logos (collectively,
            “Trademarks”) used and displayed on the Services are registered and
            unregistered marks of DN and others. Nothing on the Services should
            be construed as granting, by implication, estoppel, or otherwise,
            any license or right to use any Trademark. DN aggressively enforces
            its intellectual property rights to the fullest extent of the law.
            DN’s Trademarks may not be used in any way, including in advertising
            or publicity pertaining to distribution of materials on this Site or
            App, without our prior, written permission. To request permission to
            use the Trademarks or Content on the Services, or any other DN
            material, please contact us.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>16. Warranty Disclaimer</h3>
          <p>
            DN DOES NOT WARRANT THAT YOUR ACTIVITIES OR USE OF THE SITE OR APP
            IS LAWFUL IN ANY PARTICULAR JURISDICTION AND, IN ANY EVENT, DN
            SPECIFICALLY DISCLAIMS SUCH WARRANTIES. YOU UNDERSTAND THAT BY USING
            ANY OF THE FEATURES OF THE SITE OR APP, YOU ACT AT YOUR OWN RISK,
            AND YOU REPRESENT AND WARRANT THAT YOUR ACTIVITIES ARE LAWFUL IN
            EVERY JURISDICTION WHERE YOU ACCESS OR USE THE SITE OR APP OR THE
            CONTENT. FURTHER, DN AND ITS PARENTS, SUBSIDIARIES AND AFFILIATES
            DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES INCLUDING, WITHOUT
            LIMITATION, NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A
            PARTICULAR PURPOSE, AND TITLE.
          </p>
          <p>
            YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES AND THE
            MATERIALS CONTAINED HEREIN ARE PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THESE
            TERMS, DAILY NUMBER, INC. AND ITS AFFILIATED COMPANIES AND THEIR
            RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND OTHER REPRESENTATIVES
            (COLLECTIVELY, “DN”) EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND
            PERTAINING TO THE SERVICES AND THE MATERIALS HEREIN, WHETHER EXPRESS
            OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            SATISFACTORY QUALITY, SECURITY, ACCURACY, AVAILABILITY, USE
            REASONABLE CARE AND SKILL, AND NON-INFRINGEMENT, AS WELL AS
            WARRANTIES ARISING BY USAGE OF TRADE, COURSE OF DEALING, AND COURSE
            OF PERFORMANCE. DN MAKES NO WARRANTY THAT (I) THE SERVICES WILL MEET
            YOUR REQUIREMENTS, (II) THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE OR FREE OF VIRUSES OR BUGS, (III) THE RESULTS
            THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES WILL BE ACCURATE
            OR RELIABLE, AND (IV) ANY ERRORS IN OR ON THE SERVICES WILL BE
            CORRECTED. ANY MATERIAL, CONTENT, OR INFORMATION DOWNLOADED OR
            OTHERWISE OBTAINED AND/OR USED THROUGH THE SERVICES IS DONE AT YOUR
            OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
            DOWNLOAD OF ANY SUCH MATERIAL, CONTENT OR INFORMATION. NO ADVICE OR
            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM DN ON OR
            THROUGH THE SERVICES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED
            IN THESE TERMS AND CONDITIONS.
          </p>
          <p>
            Electronic Communications Privacy Act Notice (18 USC Â§2701-2711):
            DN MAKES NO GUARANTY OF CONFIDENTIALITY OR PRIVACY OF ANY
            COMMUNICATION OR INFORMATION TRANSMITTED ON THE SERVICES OR ANY
            WEBSITE LINKED TO THE SERVICES. DN will not be liable for the
            privacy of email addresses, registration and identification
            information, disk space, communications, confidential or
            trade-secret information, or any other Content stored on the DN
            equipment, transmitted over networks accessed by the Services, or
            otherwise connected with your use of the Services.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>17. Limitation of Liability</h3>
          <span>Limitation of Liability</span>
          <p>
            EXCEPT AS OTHERWISE SPECIFICALLY PROVIDED IN NO EVENT SHALL DN, NOR
            ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS, OR CONTENT
            PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY,
            NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO
            THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT
            OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL,
            PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER,
            SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS,
            VIRUSES, TROJAN HORSES, OR THE LIKE (REGARDLESS OF THE SOURCE OF
            ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE
            AGGREGATE) ONE HUNDRED U.S. DOLLARS ($100.00). SOME STATES OR
            COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY
            NOT APPLY TO YOU. IN THESE JURISDICTIONS, DN’S LIABILITY WILL BE
            LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>18. Dispute Resolution, Arbitration, and Class Action Waiver</h3>
          <p>
            PLEASE READ THIS SECTION CAREFULLY – IT MAY SIGNIFICANTLY AFFECT
            YOUR LEGAL RIGHTS, INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Initial Dispute Resolution</span>
          <p>
            It is DN’s goal that the Services meet your expectations and live up
            to our promises to you. However, there may be instances when you
            feel that DN has not fulfilled its obligations or you may have a
            different type of problem or dispute that needs special attention.
            In those instances, DN is committed to working with you to reach a
            reasonable resolution that satisfies you; however, we can only do
            this if we know about and understand your issue. Therefore, for any
            problem or dispute that you may have with DN, you acknowledge and
            agree that you will first give DN an opportunity to resolve your
            problem or dispute. In order to initiate this dispute resolution
            process, you must first send us a written description of your
            problem or dispute within thirty (30) days of the Services being
            performed by sending an email to{' '}
            <a className="anchorColor" onClick={() => contactSupport('mailSupport')}>support@dailynumber.app</a>{' '}
            or mail to Daily Number, Inc., 421 W 3rd Street, Unit 513, Austin,
            Texas 78701.
          </p>
          <p>
            You then agree to negotiate with DN in good faith about your problem
            or dispute for at least sixty (60) days after DN’s receipt of your
            written description of it.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Binding Arbitration</span>
          <p>
            If the parties do not reach an agreed upon solution within a period
            of 30 days from the time informal dispute resolution under the
            Initial Dispute Resolution provision, then either party may initiate
            binding arbitration as the sole means to resolve claims, subject to
            the terms set forth below. Specifically, all claims arising out of
            or relating to these Terms (including their formation, performance
            and breach), the parties’ relationship with each other and/or your
            use of the Service shall be finally settled by binding arbitration
            administered by the American Arbitration Association in accordance
            with the provisions of its Commercial Arbitration Rules and the
            supplementary procedures for consumer related disputes of the
            American Arbitration Association (the “AAA”), excluding any rules or
            procedures governing or permitting class actions.
          </p>
          <p>
            The arbitrator, and not any federal, state or local court or agency,
            shall have exclusive authority to resolve all disputes arising out
            of or relating to the interpretation, applicability, enforceability
            or formation of these Terms, including, but not limited to any claim
            that all or any part of these Terms are void or voidable, or whether
            a claim is subject to arbitration. The arbitrator shall be empowered
            to grant whatever relief would be available in a court under law or
            in equity. The arbitrator’s award shall be written, and binding on
            the parties and may be entered as a judgment in any court of
            competent jurisdiction.
          </p>
          <p>
            The Commercial Arbitration Rules governing the arbitration may be
            accessed at www.adr.org or by calling the AAA at +1.800.778.7879. To
            the extent the filing fee for the arbitration exceeds the cost of
            filing a lawsuit, DN will pay the additional cost. If the arbitrator
            finds the arbitration to be non-frivolous, DN will pay all of the
            actual filing and arbitrator fees for the arbitration, provided your
            claim does not exceed $75,000. The arbitration rules also permit you
            to recover attorney’s fees in certain cases. The parties understand
            that, absent this mandatory provision, they would have the right to
            sue in court and have a jury trial. They further understand that, in
            some instances, the costs of arbitration could exceed the costs of
            litigation and the right to discovery may be more limited in
            arbitration than in court.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Location</span>
          <p>
            If you are a resident of the United States, arbitration will take
            place at any reasonable location within the United States convenient
            for you.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Class Action Waiver</span>
          <p>
            The parties further agree that any arbitration shall be conducted in
            their individual capacities only and not as a class action or other
            representative action, and the parties expressly waive their right
            to file a class action or seek relief on a class basis. YOU AND DN
            AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR
            ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
            ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or
            arbitrator determines that the class action waiver set forth in this
            paragraph is void or unenforceable for any reason or that an
            arbitration can proceed on a class basis, then the arbitration
            provision set forth above shall be deemed null and void in its
            entirety and the parties shall be deemed to have not agreed to
            arbitrate disputes.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>
            Exception – Litigation of Intellectual Property and Small Claims
            Court Claims
          </span>
          <p>
            Notwithstanding the parties’ decision to resolve all disputes
            through arbitration, either party may bring an action in state or
            federal court to protect its intellectual property rights
            (“intellectual property rights” means patents, copyrights, moral
            rights, trademarks, and trade secrets, but not privacy or publicity
            rights). Either party may also seek relief in a small claims court
            for disputes or claims within the scope of that court’s
            jurisdiction.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>30-Day Right to Opt Out</span>
          <p>
            You have the right to opt-out and not be bound by the arbitration
            and class action waiver provisions set forth above by sending
            written notice of your decision to opt-out to the following address:
            Daily Number, Inc., 421 W 3rd Street, Unit 513, Austin, Texas 78701.
            The notice must be sent within 30 days of July 23, 2018 or your
            first use of the Service, whichever is later, otherwise you shall be
            bound to arbitrate disputes in accordance with the terms of those
            paragraphs. If you opt-out of these arbitration provisions, DN also
            will not be bound by them.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <span>Changes to this Section</span>
          <p>
            DN will provide 60-days’ notice of any changes to this section.
            Changes will become effective on the 60th day, and will apply
            prospectively only to any claims arising after the 60th day.
          </p>
          <p>
            For any dispute not subject to arbitration you and DN agree to
            submit to the personal and exclusive jurisdiction of and venue in
            the federal and state courts located in Texas. You further agree to
            accept service of process by mail, and hereby waive any and all
            jurisdictional and venue defenses otherwise available.
          </p>
          <p>
            The Terms and the relationship between you and DN shall be governed
            by the laws of the State of Texas without regard to conflict of law
            provisions.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>19. Statute of Limitations</h3>
          <p>
            You agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to the use of the
            Service or the Terms must be filed within one (1) year after such
            claim or cause of action arose or be forever barred.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>20. Assignment</h3>
          <p>
            The Terms are personal to you, and are not assignable, transferable,
            or sublicensable by you except with DN’s prior written consent. Any
            assignment in violation of this section shall be null and void. DN
            may assign, transfer, or delegate any of its rights and obligations
            hereunder without consent.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>21. No Third-Party Beneficiaries</h3>
          <p>
            You agree that, except as otherwise expressly provided in these
            Terms, there shall be no third-party beneficiaries to the Terms.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>22. Notice Policy and Your Consent</h3>
          <p>
            Under these Terms you are contracting with Daily Number, Inc., a
            Delaware corporation. All notices should be addressed to Daily
            Number, Inc. at the address in the Contact section below.
          </p>
          <p>
            For the avoidance of doubt, we are entering into this Agreement as
            principal and not as agent for any other DN company. Subject to any
            permitted assignment, the obligations owed by us under this
            Agreement shall be owed to you solely by us and the obligations owed
            by you under this Agreement shall be owed solely to us.
          </p>
          <p>
            We may give you notice by means of a general notice on the Services,
            electronic mail to your email address in your account, text message,
            or by written communication sent by first class mail or pre-paid
            post to your address in your account. Such notice shall be deemed to
            have been given upon the expiration of 48 hours after mailing or
            posting (if sent by first class mail or pre-paid post) or 12 hours
            after sending (if sent by email or text). You may give notice to us,
            with such notice deemed given when received by us, at any time by
            first class mail or pre-paid post to the address set forth above or
            at such other address as we may advise from time to time, pursuant
            to this provision.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>23. Geographic Limits of Service</h3>
          <p>
            DN make no representation that materials contained on the Services
            or products described or offered are appropriate or available for
            use in jurisdictions outside the United States, or that these Terms
            comply with the laws of any other country. Accessing the Service is
            prohibited from territories where the Content is illegal. If you
            access the Service from other locations, you do so at your own
            initiative and are responsible for compliance with local laws. You
            agree that you will not access the Services from any territory where
            the contents are illegal, and that you, and not DN, are responsible
            for compliance with applicable local laws.
          </p>
          <p>
            DN reserves the right, at any time in our sole discretion, to limit
            the availability and accessibility of the Services to any person,
            geographic area, or jurisdiction we so desire.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>24. Governing Law</h3>
          <p>
            These Terms (and any further rules, policies, or guidelines
            incorporated by reference) shall be governed by and construed in
            accordance with the laws of the State of Texas and the United
            States, without giving effect to any principles of conflicts of law,
            and without application of the Uniform Computer Information
            Transaction Act or the United Nations Convention of Controls for
            International Sale of Goods.
          </p>
          <p>
            You agree that DN and its Services are deemed passive and that do
            not give rise to personal jurisdiction over DN or its parents,
            subsidiaries, affiliates, successors, assigns, employees, agents,
            directors, officers or shareholders, either specific or general, in
            any jurisdiction other than the State of Texas. You agree that any
            action at law or in equity arising out of or relating to these
            Terms, or your use or non-use of the Services, shall be filed only
            in the state or federal courts located in Travis County in the State
            of Texas and you hereby consent and submit to the personal
            jurisdiction of such courts for the purposes of litigating any such
            action. You irrevocably waive any right you may have to trial by
            jury in any dispute, action, or proceeding.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>25. Integration and Severability</h3>
          <p>
            These Terms and other referenced material constitutes the entire
            agreement between you and DN with respect to the Services, and
            supersede all prior or contemporaneous agreements, representations,
            warranties, and understandings (whether oral, written or electronic)
            between you and DN with respect to the Services and govern the
            future relationship. If a court in any final, unappealable
            proceeding holds any provision of these Terms or its application to
            any person or circumstance invalid, illegal or unenforceable, the
            remainder of these Terms, shall not be affected, and shall be valid,
            legal and enforceable to the fullest extent permitted by law.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>26. No Waiver</h3>
          <p>
            The failure of either party to exercise in any respect any right
            provided for herein shall not be deemed a waiver of any further
            rights hereunder.
          </p>
        </div>
        <div className="serviceTermsWrap">
          <h3>27. Contact</h3>
          <p>
            If you have any questions regarding these Terms, please contact us
            at{' '}
            <a className="anchorColor" onClick={() => contactSupport('mailSupport')}>support@dailynumber.app</a>{' '}
            or by mail at the appropriate address below:
          </p>
          <p>
            <span>Customer Support Requests:</span>
          </p>
          <div className="contactDetails">
            <p>Daily Number, Inc.</p>
            <p>Attention: Daily Number Customer Service</p>
            <p>421 W 3rd Street, Unit 513</p>
            <p>Austin, Texas 78701</p>
          </div>
          <p />
        </div>
        <div className="serviceTermsWrap">
          <p>
            <span>Copyright Notices, other Legal Notices:</span>
          </p>
          <div className="contactDetails">
            <p>Daily Number, Inc.</p>
            <p>Attention: Legal</p>
            <p>421 W 3rd Street, Unit 513</p>
            <p>Austin, Texas 78701</p>
          </div>
          <p />
          <p>
            I HEREBY ACKNOWLEDGE THAT I HAVE READ AND UNDERSTAND THESE TERMS OF
            SERVICE AND PRIVACY POLICY, AND AGREE THAT MY USE OF THE SERVICES IS
            AN ACKNOWLEDGMENT OF MY AGREEMENT TO BE BOUND BY ALL OF THE TERMS
            AND CONDITIONS OF THIS AGREEMENT END.
          </p>
          <p>July 25, 2018</p>
        </div>
      </div>
    </div>
  );
};
