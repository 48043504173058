import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import 'Components/ModalBox/ModalBox.css';

export const LineupDraftModal = ({
  close,
  cancelWithoutSaving,
  saveAsDraft,
}) => {
  return (
    <div className="backdrop">
      {/*  lable to show type of modal box as warning or confirmation
    and an cancel icon  */}
      <div id="popModal" className="card-body modalPopupWrap">
        <Button className="topClose btnDraftCancel" onClick={close} />
        <div className="title draftLineupBtn">
          {MESSAGE_CONSTANTS.LINEUP.DRAFT_HEADING}
        </div>
        <div className="popupMessageWrap">
          <div className="popupMessage">
            {MESSAGE_CONSTANTS.LINEUP.DRAFT_MESSAGE}
          </div>
        </div>
        <>
          <div className="cancelBtnWrap cancelBtnBorder">
            <Button
              className="btn-outline-primary txtCancel"
              label={`${BUTTON_LABELS.LINE_UP.DELETE_LINEUP}`}
              onClick={cancelWithoutSaving}
            />
          </div>
          <div className="cancelBtnWrap">
            <Button
              className="btn-link d-block"
              label={`${BUTTON_LABELS.LINE_UP.SAVE_AS_DRAFT}`}
              onClick={() => saveAsDraft()}
            />
          </div>
        </>
      </div>
    </div>
  );
};

LineupDraftModal.propTypes = {
  close: PropTypes.func.isRequired,
  cancelWithoutSaving: PropTypes.func.isRequired,
  saveAsDraft: PropTypes.func.isRequired,
};
