/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { CustomContentProgressbar } from 'Components/ProgressCircleBar';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { Image } from 'Components/Form-Elements/Image';
import {
  getCookie,
  setInSessionStorage,
  removeFromSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import {
  reduceData,
  getPlayerStatus,
  COLORS,
  WEATHER_SEAGMENTS,
  injuryTypes,
} from 'Components/LineUpScreens/SportData/sportsObject';
import { getMyContests, getContestCount } from 'Actions/contest-action';
import { StarRating } from 'Components/StarRating';
import {
  ContextProvider,
  LineUpContext,
} from 'Components/LineUpScreens/Context';
import { PlayerDetails } from 'Components/LineUpScreens/PlayerDetails';
import { ContestDetailView } from 'Components/Profile/ProfileScreen/ContestDetailView';
import {
  getContest,
  getContestById,
  getContestByIds,
} from 'Actions/sport-action';
import { formatDateHHmmDay, getTime } from 'Utils/date-utils';
import {
  ordinalSuffixOf,
  getLastName,
  getPositionShortCode,
} from 'Utils/data-utils';
import { NewGame } from 'Components/NewGame';
import { Button } from 'Components/Form-Elements/Button';
import { detectDevice } from 'Utils/device-utils';
import { LineUpModal } from 'Components/LineUpModalFlow';
import { liveMLBGame } from 'Components/mocklivedata';
import PropTypes from 'prop-types';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { ACTION_TYPE } from 'Constants/action-type';
import icon from '../../../assets/icons/icons.svg';
import { draftUserLineups } from '../../../actions/draft-lineup-action';
import {
  getMinutesRemaining,
  getContestStart,
  getGameTimeObj,
  getProgress,
  getGameIssuesCountInTheRoster,
} from './lineup-helper';

import defaultImg from '../../../assets/images/defaultImg.png';
import nba from '../../../assets/images/sportBasketball.png';
import nfl from '../../../assets/images/sportFootballCopy.png';
import mlb from '../../../assets/images/sportBaseballCopy.svg';

const nhl = icon;
function ProfileSection({
  userData: { imageUrl, username, firstName, lastName },
  isPositionTop,
}) {
  return (
    <>
      <div className="headSection isProfilePage">
        <div className="headSection__contain">
          <h2 className={`headSection__title ${isPositionTop ? '' : 'isHide'}`}>
            {username}
          </h2>
          <div className="headSection__rightAct">
            {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
              <Link
                to="edit-profile"
                className="button"
                onClick={() => {
                  mixpanelTrackPageView(
                    MIXPANEL_EVENTS_CONSTANTS.TAP_EDIT_PROFILE_ALL_INTERACTIONS,
                    {
                      action: 'Edit Profile',
                    },
                  );
                }}>
                Edit Profile
              </Link>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="profileSection">
        <div className="profileSection__contain">
          <div className="profile">
            <Image
              className="profile__avatar"
              src={imageUrl}
              alt="avatar"
              defaultImage={defaultImg}
            />
            <div className="profile__content">
              <h2 className="profile__username">{username}</h2>
              <h3 className="profile__name">
                {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true'
                  ? `${firstName} ${lastName}`
                  : ''}
              </h3>
            </div>
            <div className="profile__rightAct">
              {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
                <Link to="edit-profile" className="button">
                  Edit Profile
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function TabSection({ activeTab, onTabClick }) {
  const { LINEUPS, ACTIVITY } = APP_CONSTANTS.USER_PROFILE_TABS;

  return (
    <div className="navSection">
      <ul className="tab">
        <li className={`tab__item ${activeTab === LINEUPS ? ' active' : ''}`}>
          <Button
            className="tab__btn button"
            onClick={evt => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVE_GAMES_ALL_EVENTS,
                {
                  action: 'Games',
                },
              );
              evt.preventDefault();
              onTabClick(LINEUPS);
            }}
            label="Games"
          />
        </li>
        <li className={`tab__item ${activeTab === ACTIVITY ? ' active' : ''}`}>
          <Button
            className="tab__btn button"
            onClick={evt => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVITY_ALL_EVENTS,
                {
                  action: 'Activity',
                },
              );
              evt.preventDefault();
              onTabClick(ACTIVITY);
            }}
            label="Activity"
          />
        </li>
      </ul>
    </div>
  );
}

export const DraftContest = ({
  draftContestData,
  contestUp,
  history,
  playerDraftDetails,
  setShowDraftLineupModal,
  setLineupModalLocation,
  setLineupModalHistory,
  setLineupModalSetter,
  setShowContestDetails,
}) => {
  const { SPORT_SLUG } = APP_CONSTANTS;
  const [draftLineup, setDraftLineup] = useState('');
  const [showLineupModal, setShowLineupModal] = useState(false);
  let sportIcon = '';
  const isMobile = detectDevice();
  draftContestData.sort(
    (a, b) => new Date(b.updateDate) - new Date(a.updateDate),
  );
  function openLineUpModal(getPlayerDetails, draftLineupIOd, amount, lineupId) {
    setInSessionStorage(APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST, {
      createdLineup: { id: lineupId },
    });
    setShowLineupModal(true);
    setDraftLineup({
      state: {
        getPlayerDraftDets: getPlayerDetails,
        draftLineupId: draftLineupIOd,
        selectedEntryfees: amount,
        status: 'drafted',
        createdLineup: { id: lineupId },
      },
    });
  }

  function toggleDraftFlow(
    getPlayerDetails,
    draftLineupIOd,
    sportSlug,
    amount,
    lineupId,
  ) {
    setInSessionStorage(APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST, {
      createdLineup: { id: lineupId },
    });

    if (contestUp.length) {
      const filteredContests = contestUp.filter(
        contestData => contestData.sportSlug === sportSlug,
      );
      setInSessionStorage(
        APP_CONSTANTS.SPORTS_KEY.CONTEST,
        filteredContests[0],
      );
    }

    return isMobile
      ? history.push({
          pathname: '/line-up',
          state: {
            getPlayerDraftDets: getPlayerDetails,
            draftLineupId: draftLineupIOd,
            selectedEntryfees: amount,
            status: 'drafted',
          },
        })
      : openLineUpModal(getPlayerDetails, draftLineupIOd, amount, lineupId);
  }

  function draftPlayers(data) {
    if (contestUp && contestUp.length) {
      const filteredContests = contestUp.filter(
        contestData => contestData.sportSlug === data.sportSlug,
      );

      const draftLineupIOd = data._id;

      const getPlayerDetails =
        data.roster &&
        data.roster.map((playerDetails, playerIndex) => {
          const tempPlayerDetails = playerDetails;
          return {
            ...tempPlayerDetails,
            id: filteredContests[0],
            index: playerIndex,
            draftDelLineup: draftLineupIOd,
            status: 'drafted',
          };
        });

      return (
        <Button
          className="lineupCard__edit button"
          onClick={() => {
            mixPanelTrackEvent(
              MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVE_GAMES_ALL_EVENTS,
              {
                action: 'Finish Draft',
              },
            );
            toggleDraftFlow(
              getPlayerDetails,
              draftLineupIOd,
              data.sportSlug,
              data.amount,
              data._id,
            );
          }}
          label={APP_CONSTANTS.FINISH_DRAFT}
        />
      );
    }

    return null;
  }

  if (showLineupModal && draftLineup) {
    setShowDraftLineupModal(showLineupModal);
    setLineupModalLocation(draftLineup);
    setLineupModalHistory(history);
    setLineupModalSetter(setShowLineupModal);
  }

  return (
    <>
      {draftContestData.map(data => {
        if (data.sportSlug === SPORT_SLUG.NBA) {
          sportIcon = nba;
        } else if (data.sportSlug === SPORT_SLUG.NFL) {
          sportIcon = nfl;
        } else if (data.sportSlug === SPORT_SLUG.NHL) {
          sportIcon = icon;
        } else {
          sportIcon = mlb;
        }
        const isMLB = data.sportSlug === SPORT_SLUG.MLB;
        const isNFL = data.sportSlug === SPORT_SLUG.NFL;
        const isNBA = data.sportSlug === SPORT_SLUG.NBA;
        const isNHL = data.sportSlug === SPORT_SLUG.NHL;
        return (
          <div className="gridSection__item" key={data._id}>
            <div className="lineupCard" key={data._id}>
              <div className="lineupCard__header">
                <span className="lineupCard__thumb">
                  {isNFL ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px">
                      <use xlinkHref={`${icon}#i-nfl`} />
                    </svg>
                  ) : isNBA ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px">
                      <use xlinkHref={`${icon}#i-nba`} />
                    </svg>
                  ) : isMLB ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px">
                      <use xlinkHref={`${icon}#i-mlb`} />
                    </svg>
                  ) : isNHL ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px">
                      <use xlinkHref={`${icon}#i-nhl`} />
                    </svg>
                  ) : (
                    <img className="gameImage" alt="" src={sportIcon} />
                  )}
                </span>
                <h1 className="lineupCard__title">
                  <strong>{data.sportSlug.toUpperCase()} • </strong>
                  {data.title.substr(4)}
                </h1>
              </div>
              <div className="lineupCard__body">
                <div className="lineupCard__playerListScroll">
                  <ul className="lineupCard__playerList">
                    {data.roster &&
                      data.roster.map((rosterData, draftKey) => {
                        const roster = {
                          ...rosterData,
                          index: draftKey,
                        };
                        const playerLastName =
                          roster && roster.player
                            ? getLastName(roster.player.name)
                            : '';
                        const injuryStatus =
                          (roster.player &&
                            roster.player.injury &&
                            roster.player.injury.status &&
                            roster.player.injury.status.charAt(0)) ||
                          (roster.player.statusInShort &&
                            (injuryTypes.mlb.includes(
                              roster.player.statusInShort,
                            ) ||
                              injuryTypes.nfl.includes(
                                roster.player.statusInShort,
                              )) &&
                            roster.player.statusInShort) ||
                          '';
                        const { weather } =
                          roster.player && roster.player.playerGame;
                        const forecast = weather && weather.forecast;
                        const current = weather && weather.current;
                        const condition =
                          (current && current.condition.toLowerCase()) ||
                          (forecast && forecast.condition.toLowerCase());
                        const weatherIconClass = WEATHER_SEAGMENTS[condition];
                        return (
                          <li
                            className={`lineupCard__playerListItem ${
                              !roster.player ? 'isEmpty' : ''
                            }`}
                            onClick={() => {
                              mixPanelTrackEvent(
                                MIXPANEL_EVENTS_CONSTANTS.TAP_PLAYER_HEADSHOT,
                                {
                                  action: 'Player Details Headshot Clicked',
                                  playerName: roster.player.name,
                                  rosterDetails: roster,
                                },
                              );
                              if (typeof roster.player === 'object') {
                                playerDraftDetails(roster);
                              }
                            }}
                            key={roster.position}>
                            <h3 className="position">
                              {getPositionShortCode(roster.position)}
                            </h3>
                            <div className="playerAvatar">
                              <span
                                className="playerAvatar__image"
                                data-label={getPositionShortCode(
                                  roster.position,
                                )}
                                style={{
                                  backgroundImage: `url(${roster.player &&
                                    roster.player.picture})`,
                                }}
                              />
                              <span
                                className={`playerAvatar__weather ${weatherIconClass}`}
                              />
                              <span className="playerAvatar__statusTopRight isHide">
                                2
                              </span>
                              {injuryStatus && (
                                <span className="playerAvatar__statusBottomLeft">
                                  {injuryStatus[0]}
                                </span>
                              )}
                            </div>
                            <h2 className="name">test{playerLastName}</h2>
                            <time>
                              {roster.player &&
                                getTime(roster.player.playerGame.gameStartDate)}
                            </time>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
              <div className="lineupCard__footer">
                <p className="lineupCard__notice">
                  <time>
                    Finish By {formatDateHHmmDay(data.contestDate.start)}
                  </time>
                </p>
              </div>
              {draftPlayers(data)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export const Contest = ({
  history,
  data,
  getStatDisplay,
  isActive,
  playerGames,
  status,
  games,
  showStats,
  activeTab,
  contestUp,
  activeContests,
  playerDetails,
  setShowEditLineupModal,
  setLineupModalLocation,
  setLineupModalHistory,
  setLineupModalSetter,
  setShowContestDetails,
}) => {
  const { UPCOMING, ACTIVE } = APP_CONSTANTS.LINEUPS_TABS;
  const { SPORT_SLUG } = APP_CONSTANTS;
  const [expanded, setExpanded] = useState(true);
  const isFree = data.amount === 0;
  const sign = isFree ? '' : '$';
  const exp = isFree ? 'XP' : '';
  const amount = isFree ? exp : `$${data.amount}`;
  const [lateScratch, setLateScratch] = useState(true);
  const { state, contextDispatch } = useContext(LineUpContext);

  const saveData = (type, payload) => {
    contextDispatch({ type, payload });
  };

  let statusString = '';
  if (data.sportSlug !== SPORT_SLUG.MLB) {
    const minutesRemaining = getMinutesRemaining(data, playerGames, games);
    statusString = isActive ? minutesRemaining : status;
  }
  if (data.sportSlug === SPORT_SLUG.MLB && status === 'FINAL') {
    statusString = status;
  }
  const [editLineUpData, setEditLineUpData] = useState({});
  const [showModalEditLineup, setShowModalEditLineup] = useState(false);
  const isMobile = detectDevice();
  const isUpcoming = UPCOMING === activeTab;
  const DataPlayerGames = [];

  data.roster.forEach(playerDetails => {
    playerGames.forEach(playerData => {
      if (playerData._id === playerDetails.playerGameId._str) {
        DataPlayerGames.push(playerData);
      }
    });
  });
  let sportIcon;

  function showPrizeDesign(score) {
    let showPrize = true;
    if (
      (activeTab === APP_CONSTANTS.LINEUPS_TABS.ACTIVE ||
        activeTab === APP_CONSTANTS.LINEUPS_TABS.COMPLETED) &&
      score < data.targetNumber
    ) {
      showPrize = false;
    } else if (activeTab === APP_CONSTANTS.LINEUPS_TABS.UPCOMING) {
      showPrize = false;
    }
    return showPrize;
  }

  const contestProgress = () => {
    const currentScore =
      (DataPlayerGames &&
        (
          Math.round(
            DataPlayerGames.reduce((sum, pg) => sum + (pg.totalFP || 0), 0) *
              100,
          ) / 100
        ).toString()) ||
      0;
    let progress = (currentScore / data.targetNumber || 0) * 100;
    progress = progress > 100 ? 100 : progress;
    const progressString = `${currentScore || 0}/${data.targetNumber}`;
    return (
      <div
        className={`lineupCard__progressBar progressBar ${
          isActive ? '' : 'isBlue'
        }`}>
        <h6 className="progressBar__label">{progressString}</h6>
        <span
          className="progressBar__progress"
          style={{ width: `${progress}%` }}
        />
        <span
          className={`progressBar__coin ${!showPrizeDesign(currentScore) &&
            'isHide'}`}>
          <span className="progressBar__coinInner">
            {isFree ? (
              <h6 className="progressBar__coinValue right">
                {`${data.prizeAmount}`}
                <small>{`${exp}`}</small>
              </h6>
            ) : (
              <h6 className="progressBar__coinValue left">
                {`${data.prizeAmount}`}
                <small>{`${sign}`}</small>
              </h6>
            )}
          </span>
        </span>
      </div>
    );
  };

  function openLineUpModal(playerData) {
    setShowModalEditLineup(true);
    setEditLineUpData({ state: playerData });
  }

  function toggleDesign(getPlayerDetails) {
    if (contestUp && contestUp.length) {
      const filteredContests = contestUp.filter(
        contestData =>
          getPlayerDetails[0].playerGames.sportSlug === contestData.sportSlug,
      );
      setInSessionStorage(
        APP_CONSTANTS.SPORTS_KEY.CONTEST,
        filteredContests[0],
      );
    }
    return isMobile
      ? history.push({ pathname: '/line-up', state: getPlayerDetails })
      : openLineUpModal(getPlayerDetails);
  }

  function toggleDesignActiveLineupEdit(getPlayerDetails) {
    if (activeContests && activeContests.length) {
      const filteredContests = activeContests.filter(
        contestData =>
          getPlayerDetails[0].id.contestId._str === contestData._id,
      );
      setInSessionStorage(
        APP_CONSTANTS.SPORTS_KEY.CONTEST,
        filteredContests[0],
      );
    }
    return isMobile
      ? history.push({ pathname: '/line-up', state: getPlayerDetails })
      : openLineUpModal(getPlayerDetails);
  }

  function onTapPlayer(playerIndex) {
    const details = {
      playerDetails: data.roster[playerIndex],
      strength: data.lineupStrength ? data.lineupStrength : '',
      live: isActive,
      progress: getProgress(
        data.roster[playerIndex].playerGameId,
        reduceData(playerGames),
        reduceData(games),
      ),
    };
    return details;
  }

  function editPlayers() {
    if (contestUp && contestUp.length) {
      const filteredContests = contestUp.filter(
        contestData => data.sportSlug === contestData.sportSlug,
      );
      /* setInSessionStorage(
        APP_CONSTANTS.SPORTS_KEY.CONTEST,
        filteredContests[0],
      ); */
      const getPlayerDetails =
        data.roster &&
        data.roster.map(playerDetails => {
          let tempPlayerDetails = playerDetails;
          playerGames.map(playerData => {
            if (playerData._id === playerDetails.playerGameId._str) {
              tempPlayerDetails = {
                ...tempPlayerDetails,
                status: 'edited',
                playerGames: playerData,
              };
            }
            return tempPlayerDetails;
          });
          return {
            ...tempPlayerDetails,
            id: data,
          };
        });

      return activeTab === UPCOMING ? (
        <>
          <Button
            className="lineupCard__edit button"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.PROFILE_PAGE, {
                action: 'Edit Lineup',
              });
              toggleDesign(getPlayerDetails);
            }}
            // onClick={() => toggleDesign(getPlayerDetails)}

            label={APP_CONSTANTS.EDIT_LINEUP}
          />
        </>
      ) : null;
    }
    return null;
  }

  // late scratch modification
  function editActivePlayers() {
    if (lateScratch) {
      const getPlayerDetails =
        data.roster &&
        data.roster.map(playerDetails => {
          let tempPlayerDetails = playerDetails;
          playerGames.map(playerData => {
            // eslint-disable-next-line no-param-reassign
            if (playerData._id === playerDetails.playerGameId._str) {
              tempPlayerDetails = {
                ...tempPlayerDetails,
                status: 'edited',
                lateScratch: true,
                playerGames: playerData,
              };
            }
            return tempPlayerDetails;
          });
          return {
            ...tempPlayerDetails,
            id: data,
          };
        });

      let editLinkActive = false;
      getPlayerDetails.forEach(element => {
        if (element.playerGames) {
          if (element.playerGames.lateScratchEnabled === true) {
            editLinkActive = true;
          }
        }
      });

      return activeTab === ACTIVE && editLinkActive ? (
        <>
          <Button
            className="lineupCard__edit button"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.PROFILE_PAGE, {
                action: 'Edit Lineup',
              });
              toggleDesignActiveLineupEdit(getPlayerDetails);
            }}
            label={APP_CONSTANTS.EDIT_LINEUP}
          />
        </>
      ) : null;
    }
    return null;
  }
  if (data.sportSlug === SPORT_SLUG.NBA) {
    sportIcon = nba;
  } else if (data.sportSlug === SPORT_SLUG.NFL) {
    sportIcon = nfl;
  } else if (data.sportSlug === SPORT_SLUG.NHL) {
    sportIcon = nhl;
  } else {
    sportIcon = mlb;
  }
  let gameCountWithIssues = {};
  if (data.sportSlug === SPORT_SLUG.MLB && playerGames.length > 0) {
    gameCountWithIssues = getGameIssuesCountInTheRoster(
      playerGames,
      data.roster,
    );
  }
  let inningsRemaining = 0;
  let weatherIconClass = '';
  let currentInning = '';
  let isPlayerActive = false;
  let gameTime = {};

  if (showModalEditLineup && editLineUpData) {
    setShowEditLineupModal(showModalEditLineup);
    setLineupModalLocation(editLineUpData);
    setLineupModalHistory(history);
    setLineupModalSetter(setShowModalEditLineup);
  }
  const isMLB = data.sportSlug === SPORT_SLUG.MLB;
  const isNFL = data.sportSlug === SPORT_SLUG.NFL;
  const isNBA = data.sportSlug === SPORT_SLUG.NBA;
  const isNHL = data.sportSlug === SPORT_SLUG.NHL;

  return (
    <>
      <div className="gridSection__item">
        <div className="lineupCard">
          <div className="lineupCard__header">
            <span className="lineupCard__thumb">
              {isNFL ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px">
                  <use xlinkHref={`${icon}#i-nfl`} />
                </svg>
              ) : isNBA ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px">
                  <use xlinkHref={`${icon}#i-nba`} />
                </svg>
              ) : isMLB ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px">
                  <use xlinkHref={`${icon}#i-mlb`} />
                </svg>
              ) : isNHL ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px">
                  <use xlinkHref={`${icon}#i-nhl`} />
                </svg>
              ) : (
                <img className="gameImage" alt="" src={sportIcon} />
              )}
            </span>
            <h1 className="lineupCard__title">
              <strong>{data.sportSlug.toUpperCase()} • </strong>
              {data.title.substr(4)}
            </h1>
            <button
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVITY_ALL_EVENTS,
                  {
                    action: 'Next Arrow Button',
                  },
                );
                if (isMobile) {
                  setShowContestDetails(data);
                } else {
                  // setShowContestDetails(!expanded);
                  setShowContestDetails(data);
                }
                // isMobile ? setShowContestDetails(data) : setExpanded(!expanded);
              }}
              // onClick={() =>
              //   isMobile ? setShowContestDetails(data) : setExpanded(!expanded)
              // }
              type="button"
              className={`lineupCard__collapse button ${
                expanded ? '' : ' expand'
              }`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <use xlinkHref={`${icon}#i-arrow`} />
              </svg>
            </button>
          </div>
          {expanded && (
            <div className="lineupCard__body">
              <div className="lineupCard__playerListScroll">
                <ul className="lineupCard__playerList">
                  {data.roster &&
                    data.roster.map((roster, dataIndex) => {
                      const { playerGameId, player, position, index } = roster;
                      const playerLastName = player
                        ? getLastName(player.name)
                        : '';
                      gameTime = {};
                      gameTime = getGameTimeObj(
                        playerGameId,
                        playerGames,
                        games,
                        activeTab,
                      );
                      const playerGame = playerGames.find(
                        pg => pg._id === playerGameId._str,
                      );
                      const injuryStatus =
                        (playerGame && playerGame.injuryStatus) ||
                        (player && player.injury && player.injury.status) ||
                        '';
                      const status = getPlayerStatus(gameTime);
                      currentInning = '';
                      const gameDetails = games.find(
                        g => playerGame && g._id === playerGame.gameId._str,
                      );
                      if (
                        data.sportSlug === SPORT_SLUG.MLB &&
                        gameDetails &&
                        [
                          'InProgress',
                          'Wdelay',
                          'Fdelay',
                          'Odelay',
                          'Suspended',
                          'Delayed',
                          'Completed',
                          'Complete',
                          'Closed',
                        ].includes(gameDetails.status) === false
                      ) {
                        inningsRemaining += gameDetails.doubleHeader ? 7 : 9;
                      }
                      if (
                        status.live &&
                        gameTime &&
                        gameTime.status === 'live'
                      ) {
                        const progressData = getProgress(
                          playerGameId,
                          reduceData(playerGames),
                          reduceData(games),
                        );
                        status.color =
                          (playerGame && playerGame.onCourt && COLORS.green) ||
                          COLORS.appBlue;
                        status.progress =
                          (progressData && progressData.progress) || 0;
                        if (data.sportSlug === SPORT_SLUG.MLB) {
                          const liveGameDetails = gameDetails;
                          if (
                            liveGameDetails &&
                            [
                              'InProgress',
                              'Wdelay',
                              'Fdelay',
                              'Odelay',
                              'Suspended',
                              'Delayed',
                            ].includes(liveGameDetails.status)
                          ) {
                            const {
                              weather,
                              currentOutcome,
                              doubleHeader,
                            } = liveGameDetails;
                            const forecast = weather && weather.forecast;
                            const current = weather && weather.current;
                            const regularTotalInnings =
                              currentOutcome.inning > 9
                                ? currentOutcome.inning
                                : 9;
                            const doubleHeaderInnings =
                              doubleHeader &&
                              (currentOutcome.inning > 7
                                ? currentOutcome.inning
                                : 7);
                            const totalInnings = doubleHeader
                              ? doubleHeaderInnings
                              : regularTotalInnings;
                            const remainingInnings =
                              currentOutcome &&
                              totalInnings - currentOutcome.inning;
                            if (remainingInnings > 0) {
                              inningsRemaining += remainingInnings;
                            }
                            // weather/injury in against each player
                            // filter inclined weathers only
                            weatherIconClass = '';
                            if (['SUSP', 'DLYD'].includes(gameTime.time)) {
                              const condition =
                                current.condition.toLowerCase() ||
                                forecast.condition.toLowerCase();
                              weatherIconClass = WEATHER_SEAGMENTS[condition];
                            }
                            // players innings with top/bottom
                            if (currentOutcome) {
                              isPlayerActive = false;
                              if (currentOutcome.halfInning === 'T') {
                                currentInning = `TOP ${ordinalSuffixOf(
                                  liveGameDetails.inning,
                                )}`;
                              }
                              if (currentOutcome.halfInning === 'B') {
                                currentInning = `BOT ${ordinalSuffixOf(
                                  liveGameDetails.inning,
                                )}`;
                              }
                              isPlayerActive =
                                currentOutcome.hitter.apiId === player.apiId ||
                                currentOutcome.pitcher.apiId === player.apiId ||
                                (currentOutcome.runners &&
                                  currentOutcome.runners.some(
                                    r => r.runnerApiId === player.apiId,
                                  ));
                            }
                            // TODO: needs to cross check
                            status.color =
                              (isPlayerActive && COLORS.green) ||
                              COLORS.appBlue;
                          }
                        }
                        if (
                          data.sportSlug === SPORT_SLUG.NFL ||
                          data.sportSlug === SPORT_SLUG.NHL
                        ) {
                          status.color = COLORS.green;
                        }
                      }
                      const greyText = status.inactiveText && 'inProgressText';
                      // console.log('MZA: status', isNHL ? status : null);
                      return (
                        // index is customized unique field
                        <>
                          <li
                            className="lineupCard__playerListItem"
                            onClick={() => {
                              mixPanelTrackEvent(
                                MIXPANEL_EVENTS_CONSTANTS.TAP_PLAYER_HEADSHOT,
                                {
                                  action: 'player headshot',
                                  formdata: {
                                    details: roster,
                                    playerDetails: player,
                                  },
                                },
                              );
                              playerDetails(roster, onTapPlayer(dataIndex));
                            }}
                            key={index}>
                            <h3 className="position">
                              {getPositionShortCode(position)}
                            </h3>
                            <div
                              className={`playerAvatar ${greyText || ''} ${
                                COLORS.green === status.color ? 'green' : ''
                              } ${
                                ['SUSP', 'DLYD', 'PPD'].includes(gameTime.time)
                                  ? 'isSuspDely'
                                  : ''
                              }`}>
                              <span
                                className="playerAvatar__image"
                                style={{
                                  backgroundImage: `url(${player.picture})`,
                                }}
                              />
                              {gameDetails &&
                              [
                                'InProgress',
                                'Wdelay',
                                'Fdelay',
                                'Odelay',
                                'Suspended',
                                'Delayed',
                                'Completed',
                                'Complete',
                                'Closed',
                                'Halftime',
                              ].includes(gameDetails.status) !== false ? (
                                <span className="playerAvatar__circleProgressWrapper">
                                  <svg
                                    className="svgCircleProgress"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 33.48 33.48">
                                    <circle
                                      className="svgCircleProgress__line"
                                      cx="16.74"
                                      cy="16.74"
                                      r="15.93"
                                    />
                                    {status.progress ? (
                                      <circle
                                        className="svgCircleProgress__bar"
                                        cx="16.74"
                                        cy="16.74"
                                        r="15.93"
                                        strokeDasharray={`${status.progress *
                                          100} 100`}
                                        strokeDashoffset={status.progress * 100}
                                      />
                                    ) : (
                                      ''
                                    )}
                                  </svg>
                                </span>
                              ) : (
                                ''
                              )}
                              <span
                                className={`playerAvatar__weather ${weatherIconClass}`}
                              />
                              <span className="playerAvatar__statusTopRight isHide">
                                2
                              </span>
                              {injuryStatus && (
                                <span className="playerAvatar__statusBottomLeft">
                                  {injuryStatus[0]}
                                </span>
                              )}
                            </div>
                            <h2 className="name">{playerLastName}</h2>
                            {showStats ? (
                              <p className="points">
                                {getStatDisplay(playerGameId)}
                              </p>
                            ) : (
                              ''
                            )}
                            {currentInning ? (
                              <p className="points">{currentInning}</p>
                            ) : (
                              ''
                            )}
                            {gameTime.time ? <time>{gameTime.time}</time> : ''}
                          </li>
                        </>
                      );
                    })}
                </ul>
              </div>
            </div>
          )}
          <div className="lineupCard__footer">
            {contestProgress()}
            <p className="lineupCard__notice">
              <time>
                {statusString}
                {inningsRemaining > 0
                  ? `${inningsRemaining} innings remaining`
                  : ''}
              </time>
              <span>
                {gameCountWithIssues && gameCountWithIssues.Postponed > 0
                  ? `${gameCountWithIssues.Postponed} postponed`
                  : ''}
                {gameCountWithIssues && gameCountWithIssues.Delayed > 0
                  ? `${gameCountWithIssues.Delayed} delayed`
                  : ''}
                {gameCountWithIssues && gameCountWithIssues.Suspended > 0
                  ? `${gameCountWithIssues.Suspended} suspended`
                  : ''}
              </span>
            </p>
            <div className="lineupCard__rating">
              <h5 className="label">
                {`${amount} • ${sign}${data.prizeAmount}`}
                {exp}
              </h5>
              <StarRating
                rating={data.lineupStrength}
                starRatedColor="rgb(148, 148, 148)"
                starEmptyColor="rgba(0, 0, 0, 0)"
                starDimension="11px"
                starSpacing="0.5px"
              />
            </div>
            {/* <button type="button" className="lineupCard__share button">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <use xlinkHref={`${icon}#i-upload`} />
              </svg>
            </button> */}
          </div>
          {(activeTab === UPCOMING && editPlayers()) ||
            (activeTab === ACTIVE && editActivePlayers())}
        </div>
      </div>
    </>
  );
};

const SubTabSection = ({
  activeTab,
  draftCount,
  onTabClick,
  contestCount,
  toggleTitle,
}) => {
  const { ACTIVE, DRAFT, UPCOMING, COMPLETED } = APP_CONSTANTS.LINEUPS_TABS;
  const tabElement = useRef(null);
  const [isPositionTopForNavBar, setIsPositionTopForNavBar] = useState(false);
  useEffect(() => {
    const handleNavSubSectionScroll = e => {
      const navSection = document.querySelector('.navSection');
      const boundingNavbar = navSection.getBoundingClientRect();

      const navSubSectionWrapper = document.querySelector(
        '.navSubSectionWrapper',
      );
      const boundingNavSubSectionWrapper = navSubSectionWrapper.getBoundingClientRect();
      if (
        boundingNavbar.top + 16 >= 0 &&
        boundingNavbar.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        setIsPositionTopForNavBar(false);
        navSubSectionWrapper.style.height = null;
      } else {
        setIsPositionTopForNavBar(true);
        navSubSectionWrapper.style.height = `${boundingNavSubSectionWrapper.height}px`;
      }
    };
    window.addEventListener('scroll', handleNavSubSectionScroll);
    return () => {
      window.removeEventListener('scroll', handleNavSubSectionScroll);
    };
  }, []);
  return (
    <div className="navSubSectionWrapper">
      <div
        className={`navSubSection ${
          isPositionTopForNavBar ? 'isPositionTop' : ''
        }`}>
        <ul className="tab" ref={tabElement}>
          {contestCount.live !== 0 && (
            <li className={`tab__item ${activeTab === ACTIVE && 'active'}`}>
              <button
                type="button"
                className="tab__btn button"
                onClick={evt => {
                  evt.preventDefault();
                  onTabClick(ACTIVE);
                }}>
                Active
              </button>
            </li>
          )}
          {contestCount.draft !== 0 && (
            <li className={`tab__item ${activeTab === DRAFT && 'active'}`}>
              <button
                type="button"
                className="tab__btn button"
                onClick={evt => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVE_GAMES_ALL_EVENTS,
                    {
                      action: 'Draft',
                    },
                  );
                  evt.preventDefault();
                  onTabClick(DRAFT);
                }}>
                Draft <span className="count">{draftCount}</span>
              </button>
            </li>
          )}
          {contestCount.upcoming !== 0 && (
            <li className={`tab__item ${activeTab === UPCOMING && 'active'}`}>
              <button
                type="button"
                className="tab__btn button"
                onClick={evt => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVE_GAMES_ALL_EVENTS,
                    {
                      action: 'Upcoming',
                    },
                  );
                  evt.preventDefault();
                  onTabClick(UPCOMING);
                }}>
                Upcoming
              </button>
            </li>
          )}
          {contestCount.completed !== 0 && (
            <li className={`tab__item ${activeTab === COMPLETED && 'active'}`}>
              <button
                type="button"
                className="tab__btn button"
                onClick={evt => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.PROFILE_ACTIVE_GAMES_ALL_EVENTS,
                    {
                      action: 'Completed',
                    },
                  );
                  evt.preventDefault();
                  onTabClick(COMPLETED);
                }}>
                Completed
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export const ProfileScreen = ({ location, history }) => {
  const tabs = ['live', 'draft', 'upcoming', 'completed'];
  const { LINEUPS, ACTIVITY } = APP_CONSTANTS.USER_PROFILE_TABS;
  const POLL_INTERVAL = 15000;
  const { ACTIVE, DRAFT, UPCOMING, COMPLETED } = APP_CONSTANTS.LINEUPS_TABS;

  const [activeTab, setActiveTab] = useState(LINEUPS);
  const [activeSubTab, setActiveSubTab] = useState('');
  const [contests, setContast] = useState([]);
  const [playerGames, setPlayerGames] = useState([]);
  const [games, setGames] = useState([]);
  const [teams, setTeams] = useState([]);
  const [showPlayerDetails, setshowPlayerDetails] = useState();
  const [showContestDetails, setShowContestDetails] = useState();
  const [contestUp, setUpContests] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [draftContest, setDraftContest] = useState([]);
  const [draftCount, setDraftCount] = useState(0);
  const [showTitle, toggleTitle] = useState(false);
  const [toggleDesign, setToggleDesign] = useState(false);
  const [contestCount, setContestCount] = useState({
    live: 0,
    upcoming: 0,
    draft: 0,
    completed: 0,
  });
  const [showLineupModal, setShowLineupModal] = useState(false);
  const [showEditLineupModal, setShowEditLineupModal] = useState(false);
  const [showDraftLineupModal, setShowDraftLineupModal] = useState(false);
  const [lineupModalLocation, setLineupModalLocation] = useState({});
  const [lineupModalHistory, setLineupModalHistory] = useState({});
  const [lineupModalSetter, setLineupModalSetter] = useState(() => {});
  const [enableScroll, setEnableScroll] = useState(true);
  const [isPositionTop, setIsPositionTop] = useState(false);
  const [activeContests, setActiveContast] = useState([]);
  const isMobile = detectDevice();
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const lineupModal = query.get('lineup');
  useEffect(() => {
    setToggleDesign(lineupModal);
  }, [lineupModal]);
  useEffect(() => {
    mixpanelTrackPageView('Profile Screen');
  }, []);

  useEffect(() => {
    if (showPlayerDetails) {
      document.body.classList.add('playerDetailsView');
    } else {
      document.body.classList.remove('playerDetailsView');
    }
  }, [showPlayerDetails]);

  useEffect(() => {
    if (showContestDetails) {
      document.body.classList.add('contestDetailView');
    } else {
      document.body.classList.remove('contestDetailView');
    }
  }, [showContestDetails]);

  useEffect(() => {
    if (enableScroll) {
      document.documentElement.classList.remove('u-overflowHidden');
    } else {
      document.documentElement.classList.add('u-overflowHidden');
    }
  }, [enableScroll]);

  useEffect(() => {
    setShowMessage(false);
    getContestCount()
      .then(resp => {
        const countData = resp.data;
        if (countData.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          setContestCount(countData.data);
          setDraftCount(countData.data.draft);
          if (!Object.values(countData.data).some(value => value)) {
            setShowMessage(true);
          }
        }
      })
      .catch(() => {
        // API error handling
      });
    getContest()
      .then(({ data }) => {
        setUpContests(data.contests || []);
      })
      .catch(() => {
        // API error handling
      });
  }, [history.location]);

  useEffect(() => {
    const property = Object.keys(APP_CONSTANTS.MAP_TABS).filter(
      key => key === activeSubTab,
    );
    if (!activeSubTab || (property.length && contestCount[property[0]] === 0)) {
      for (let i = 0; i < tabs.length; i += 1) {
        if (contestCount[tabs[i]]) {
          setActiveSubTab(APP_CONSTANTS.MAP_TABS[tabs[i]]);
          break;
        }
      }
    }
  }, [contestCount]);

  useEffect(() => {
    if (getFromSessionStorage('lineupTab')) {
      setActiveSubTab(getFromSessionStorage('lineupTab'));
      removeFromSessionStorage('lineupTab');
    } else {
      setActiveSubTab(activeSubTab);
    }
  }, [activeTab, history.location]);

  const getDraftLineups = () => {
    draftUserLineups()
      .then(({ data: { entrants } }) => {
        setContast([]);
        setPlayerGames([]);
        setGames([]);
        setTeams([]);
        setDraftContest(entrants);
        setDraftCount(entrants.length);
      })
      .catch(e => {
        setContast([]);
        setPlayerGames([]);
        setGames([]);
        setTeams([]);
        setDraftContest([]);
      });
  };
  const getContestLineups = lineupTab => {
    if (ACTIVE !== lineupTab) {
      setContast([]);
    }

    getMyContests(lineupTab)
      .then(({ data: { entrants, playerGames, games, teams } }) => {
        setContast(entrants || []);
        setPlayerGames(playerGames || []);
        setGames(games || []);
        setTeams(teams || []);
        if (entrants.length) {
          const contestIDs = [];
          entrants.forEach(element => {
            contestIDs.push(element.contestId._str);
          });
          getContestByIds(contestIDs).then(({ data }) => {
            setActiveContast(data || []);
          });
        }
      })
      .catch(e => {
        setContast([]);
        setPlayerGames([]);
        setGames([]);
        setTeams([]);
      });
  };

  useEffect(() => {
    if (activeTab === LINEUPS && activeSubTab) {
      if (getFromSessionStorage('lineupTab')) {
        if (getFromSessionStorage('lineupTab') === DRAFT) {
          getDraftLineups();
        } else {
          getContestLineups(getFromSessionStorage('lineupTab'));
        }
      } else if (activeSubTab === DRAFT) {
        getDraftLineups();
      } else {
        getContestLineups(activeSubTab);
      }
    }
    removeFromSessionStorage('lineupTab');
  }, [activeSubTab, history.location]);

  function getStatDisplay(playerGameId) {
    const pg = playerGames.find(pg => pg._id === playerGameId._str);
    return pg && pg.totalFP;
  }

  const userData = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const isActive = activeSubTab === ACTIVE;
  const isUpcoming = activeSubTab === UPCOMING;
  const status = {
    [ACTIVE]: () => 'XXX MIN REMAINING',
    [DRAFT]: () => 'DRAFT',
    [UPCOMING]: getContestStart,
    [COMPLETED]: () => 'FINAL',
  };

  useEffect(() => {
    let live;
    if (isActive) {
      live = setInterval(() => {
        getContestLineups(ACTIVE);
      }, POLL_INTERVAL);
    }

    return () => {
      clearInterval(live);
    };
  }, [activeSubTab]);

  useEffect(() => {
    const handleProfileScroll = e => {
      const profile = document.querySelector('.profileSection');
      if (typeof profile !== 'undefined' && profile != null) {
        const boundingProfile = profile.getBoundingClientRect();
        const header = document.querySelector('.headSection');
        const boundingHeader = header.getBoundingClientRect();
        // console.log(boundingProfile.height);
        if (
          boundingProfile.top +
            boundingProfile.height -
            boundingHeader.height -
            16 >=
            0 &&
          boundingProfile.bottom <=
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
          setIsPositionTop(false);
          // console.log(
          //   `to: ${boundingProfile.top}${boundingHeader.height}, bottom: ${boundingProfile.bottom} - In the viewport!`,
          // );
        } else {
          setIsPositionTop(true);
          // console.log(
          //   `to: ${boundingProfile.top}${boundingHeader.height}, bottom: ${boundingProfile.bottom} - Not in the viewport... whomp whomp!`,
          // );
        }
      }
    };
    window.addEventListener('scroll', handleProfileScroll);
    return () => {
      window.removeEventListener('scroll', handleProfileScroll);
    };
  }, []);

  if (showPlayerDetails) {
    return (
      <PlayerDetails
        player={showPlayerDetails}
        getToList={setshowPlayerDetails}
        className="profileFullPage"
      />
    );
  }

  if (isMobile && showContestDetails) {
    return (
      <ContestDetailView
        history={history}
        key={showContestDetails._id}
        status={status[activeSubTab](showContestDetails)}
        data={showContestDetails}
        playerGames={playerGames}
        games={games}
        teams={teams}
        getStatDisplay={getStatDisplay}
        isActive={isActive}
        showStats={!isUpcoming}
        activeTab={activeSubTab}
        contestUp={contestUp}
        playerDetails={({ playerGameId, player, position }, details) => {
          setshowPlayerDetails({
            playerGame: {
              _id: playerGameId._str,
              name: player.name,
              position,
            },
            name: player.name,
            strength: showContestDetails.lineupStrength,
            picture: player.picture,
            liveGameDetails: details,
          });
        }}
        setShowEditLineupModal={setShowEditLineupModal}
        setLineupModalLocation={setLineupModalLocation}
        setLineupModalHistory={setLineupModalHistory}
        setLineupModalSetter={setLineupModalSetter}
        setShowContestDetails={setShowContestDetails}
      />
    );
  }

  const toggleRoutes = () => {
    setEnableScroll(toggleDesign);
    return isMobile ? setToggleDesign(!toggleDesign) : setShowLineupModal(true);
  };

  return (
    <>
      <main className="App__main">
        <ProfileSection userData={userData} isPositionTop={isPositionTop} />
        <TabSection activeTab={activeTab} onTabClick={setActiveTab} />

        {activeTab === LINEUPS && (
          <>
            <SubTabSection
              activeTab={activeSubTab}
              draftCount={draftCount}
              onTabClick={setActiveSubTab}
              toggleTitle={toggleTitle}
              contestCount={contestCount}
            />
            <div className="gridSection">
              {(activeSubTab !== DRAFT &&
                contests.length &&
                contests.map(contest => (
                  <Contest
                    history={history}
                    key={contest._id}
                    status={status[activeSubTab](contest)}
                    data={contest}
                    playerGames={playerGames}
                    games={games}
                    getStatDisplay={getStatDisplay}
                    isActive={isActive}
                    showStats={!isUpcoming}
                    activeTab={activeSubTab}
                    contestUp={contestUp}
                    activeContests={activeContests}
                    playerDetails={(
                      { playerGameId, player, position },
                      details,
                    ) => {
                      setshowPlayerDetails({
                        playerGame: {
                          _id: playerGameId._str,
                          name: player.name,
                          position,
                        },
                        name: player.name,
                        strength: contest.lineupStrength,
                        picture: player.picture,
                        liveGameDetails: details,
                      });
                    }}
                    setShowEditLineupModal={setShowEditLineupModal}
                    setLineupModalLocation={setLineupModalLocation}
                    setLineupModalHistory={setLineupModalHistory}
                    setLineupModalSetter={setLineupModalSetter}
                    setShowContestDetails={setShowContestDetails}
                  />
                ))) ||
                (showMessage && (
                  <div className="gridSection__fullWidth">
                    <div className="emptyGame">
                      <div className="emptyGame__content">
                        <p>You have no active games</p>
                        <button className="button" type="button">
                          Create a new lineup
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              {activeSubTab === DRAFT && (
                <DraftContest
                  history={history}
                  draftContestData={draftContest}
                  contestUp={contestUp}
                  playerDraftDetails={({ playerGameId, player, position }) => {
                    setshowPlayerDetails({
                      playerGame: {
                        _id: playerGameId._str ? playerGameId._str : '',
                        name: player && player.name ? player.name : '',
                        position,
                      },
                      name: player && player.name ? player.name : '',
                      strength: player && player.strength ? player.strength : 0,
                      picture: player && player.picture ? player.picture : '',
                    });
                  }}
                  setShowDraftLineupModal={setShowDraftLineupModal}
                  setLineupModalLocation={setLineupModalLocation}
                  setLineupModalHistory={setLineupModalHistory}
                  setLineupModalSetter={setLineupModalSetter}
                  setShowContestDetails={setShowContestDetails}
                />
              )}
            </div>
          </>
        )}
        {activeTab === ACTIVITY && (
          <div className="gridSection">
            <div className="gridSection__fullWidth">
              <div className="emptyState">
                <div className="emptyState__content">
                  <p>No Activity</p>
                  <p>Uhhh...What are you waiting for?</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <button
          type="button"
          className="button button-addNewGame"
          onClick={() => toggleRoutes()}
          data-modal="lineup_draft_selection">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <use xlinkHref={`${icon}#i-plus`} />
          </svg>
        </button>
        {toggleDesign && (
          <div className="selectGuard selectGame">
            <div className="drawerClose">
              <span />
            </div>
            <div className="listOuter ">
              <div className="guardTitle">
                <Button
                  type="button"
                  className="closeGuard"
                  onClick={() => {
                    setEnableScroll(true);
                    setToggleDesign(false);
                  }}
                />
                New DN Lineup
              </div>
              <NewGame />
            </div>
          </div>
        )}
      </main>
      {showLineupModal && (
        <LineUpModal
          showLineupModal={showLineupModal}
          setShowLineupModal={setShowLineupModal}
          history={history}
        />
      )}
      {showEditLineupModal && lineupModalLocation && (
        <LineUpModal
          showLineupModal={showEditLineupModal}
          setShowLineupModal={setShowEditLineupModal}
          history={lineupModalHistory}
          location={lineupModalLocation}
          status="EDIT_LINEUP"
        />
      )}
      {showDraftLineupModal && lineupModalLocation && (
        <LineUpModal
          showLineupModal={showDraftLineupModal}
          setShowLineupModal={setShowDraftLineupModal}
          history={lineupModalHistory}
          location={lineupModalLocation}
          status="EDIT_LINEUP"
        />
      )}
      {!isMobile && showContestDetails && (
        <ContestDetailView
          history={history}
          key={showContestDetails._id}
          status={status[activeSubTab](showContestDetails)}
          data={showContestDetails}
          playerGames={playerGames}
          games={games}
          teams={teams}
          getStatDisplay={getStatDisplay}
          isActive={isActive}
          showStats={!isUpcoming}
          activeTab={activeSubTab}
          contestUp={contestUp}
          playerDetails={({ playerGameId, player, position }, details) => {
            setshowPlayerDetails({
              playerGame: {
                _id: playerGameId._str,
                name: player.name,
                position,
              },
              name: player.name,
              strength: showContestDetails.lineupStrength,
              picture: player.picture,
              liveGameDetails: details,
            });
          }}
          setShowEditLineupModal={setShowEditLineupModal}
          setLineupModalLocation={setLineupModalLocation}
          setLineupModalHistory={setLineupModalHistory}
          setLineupModalSetter={setLineupModalSetter}
          setShowContestDetails={setShowContestDetails}
        />
      )}
    </>
  );
};
