import React from 'react';
import { Link } from 'react-router-dom';

export const Settings = () => {
  return (
    <main className="App__main">
      <div className="headSection">
        <div className="headSection__contain">
          <h2 className="headSection__title">Settings</h2>
        </div>
      </div>
      <div className="settingWrapper settingsWrapper customSettingsWrapper">
        <div className="settingsInner">
          <div className="settingListWrapper">
            <Link to="/settings/account">
              <div className="settingList d-flex">
                <div className="settingTitle flex-grow-1">Account</div>
                <span className="disclosureArrow" />
              </div>
            </Link>
            <Link to="/responsible-gaming">
              <div className="settingList d-flex">
                <div className="settingTitle flex-grow-1">
                  Responsible Gaming
                </div>
                <span className="disclosureArrow" />
              </div>
            </Link>
          </div>
          <div className="additionalSettings">
            <Link to="/game-rules">
              <div className="settingList d-flex">
                <div className="settingTitle flex-grow-1">Rules</div>
                <span className="disclosureArrow" />
              </div>
            </Link>
          </div>
          <div className="additionalSettings videosection">
            <Link to="/tutorial-video">
              <div className="settingList d-flex">
                <div className="settingTitle flex-grow-1">Tutorials</div>
                <span className="disclosureArrow" />
              </div>
            </Link>
          </div>
          <div className="otherSettings">
            <a
              href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_MAILID}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex-grow-1">
              <div className="settingList d-flex">Contact Support</div>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
