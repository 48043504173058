import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { ProgressBar } from 'Components/ProgressBar';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import { Button } from 'Components/Form-Elements/Button';
import {
  setInSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { getUserState } from 'Actions/location-action';
import { ModalBox } from 'Components/ModalBox';
import Branch from 'branch-sdk';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const Location = ({
  history,
  isModal,
  fetchAndSetModalPage,
  closeModalBox,
}) => {
  const [locationStatus, setLocationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [locationObject, setLocationObject] = useState({});
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const signupType = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
  );

  useEffect(() => {
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  const onAllowLocation = position => {
    setIsLoading(true);
    getUserState(position.coords.latitude, position.coords.longitude)
      .then(({ data }) => {
        setIsLoading(false);
        mixPanelTrackEvent(
          MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
          {
            action: 'Allow Location',
            state: {
              name: data.state.name,
              stusps: data.state.stusps,
            },
            country: data.country,
            lat: position.coords.latitude,
            long: position.coords.longitude,
          },
        );
        setLocationObject({
          state: {
            name: data.state.name,
            stusps: data.state.stusps,
          },
          country: data.country,
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
        setLocationStatus(true);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        setLocationObject({
          lat: position.coords.latitude,
          long: position.coords.longitude,
        });
        mixPanelTrackEvent(
          MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
          {
            action: 'Allow Location',
            lat: position.coords.latitude,
            long: position.coords.longitude,
          },
        );
        if (
          response.data.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          response.data.code ===
            APP_CONSTANTS.ERROR_CODE.CODE_LOCATION_NOT_FOUND
        ) {
          setLocationStatus(true);
        }
      });
  };

  const onDenyLocation = error => {
    mixPanelTrackEvent(
      MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
      {
        action: 'Deny Location',
      },
    );
    setToggleModalBox(true);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setModalBoxName(APP_CONSTANTS.MODALBOX.PERMISSION_DENIED);
        break;
      case error.POSITION_UNAVAILABLE:
        setModalBoxName(APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE);
        break;
      case error.TIMEOUT:
        setModalBoxName(APP_CONSTANTS.MODALBOX.TIMEOUT);
        break;
      default:
        setModalBoxName(APP_CONSTANTS.MODALBOX.UNKNOWN_ERR);
        break;
    }
  };

  const getLocation = () => {
    mixPanelTrackEvent(
      MIXPANEL_EVENTS_CONSTANTS.GEO_LOCATION_ENABLED_DISABLED,
      {
        action: 'Get Location',
      },
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onAllowLocation, onDenyLocation);
    }
  };

  const storeData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.LOCATION,
      locationObject,
    );
    if (signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY) {
      if (isModal) {
        fetchAndSetModalPage('/verify-phone-number');
        return;
      }
      history.push('/verify-phone-number');
      return;
    }
    if (isModal) {
      fetchAndSetModalPage('/birth-date');
      return;
    }
    history.push('/birth-date');
  };

  const renderProgressDialog = () => {
    return isLoading && <Spinner />;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.PERMISSION_DENIED) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.PERMISSION_DENIED;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.TIMEOUT) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.TIMEOUT;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.UNKNOWN_ERR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.UNKNOWN_ERR;
    }
    return modalbox;
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        {toggleModalBox && (
          <ModalBox
            modalBoxDetails={modalBoxDetail()}
            okButtonHandler={processHandler}
            okButtonText="Ok"
            showBothButtons={false}
          />
        )}
        <div className="d-flex header">
          <ProgressBar percentage="20" />
          <div className="d-flex topBar locationtopBar">
            {!isModal && (
              <Link
                to="/"
                className="topClose"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION,
                    {
                      action: 'close window',
                      location: navigator.geolocation.getCurrentPosition(
                        onAllowLocation,
                        onDenyLocation,
                      ),
                    },
                  );
                }}
              />
            )}
            {isModal && (
              <Button
                className="topClose linkBtn"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION,
                    {
                      action: 'close window',
                      location: navigator.geolocation.getCurrentPosition(
                        onAllowLocation,
                        onDenyLocation,
                      ),
                    },
                  );
                  closeModalBox();
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
            {!locationStatus &&
              (!isModal ? (
                <Link
                  to={
                    signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY
                      ? '/verify-phone-number'
                      : '/birth-date'
                  }
                  className="bottomFixed btnTransparent"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION,
                      {
                        action: 'Skip',
                        location: navigator.geolocation.getCurrentPosition(
                          onAllowLocation,
                          onDenyLocation,
                        ),
                      },
                    );
                  }}>
                  {BUTTON_LABELS.COMMON.SKIP}
                </Link>
              ) : (
                <Button
                  className="bottomFixed btnTransparent"
                  label={BUTTON_LABELS.COMMON.SKIP}
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION,
                      {
                        action: 'Skip',
                        location: navigator.geolocation.getCurrentPosition(
                          onAllowLocation,
                          onDenyLocation,
                        ),
                      },
                    );
                    fetchAndSetModalPage(
                      signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY
                        ? '/verify-phone-number'
                        : '/birth-date',
                    );
                  }}
                />
              ))}
          </div>
        </div>
        <div className="wrapper">
          <div className="info locationInfo">
            <div className="iconBig locationIcon" />
            <div className="title">
              What is your <strong>location?</strong>
            </div>
            <div className="description">
              {MESSAGE_CONSTANTS.LOCATION.DESCRIPTION}
            </div>
          </div>
          {!locationStatus ? (
            <>
              <Button
                className="btn btn-primary"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION,
                    {
                      action: 'Enable Location Services',
                      location: navigator.geolocation.getCurrentPosition(
                        onAllowLocation,
                        onDenyLocation,
                      ),
                    },
                  );
                  getLocation();
                }}
                disabled={isLoading}
                label={
                  isLoading
                    ? renderProgressDialog()
                    : BUTTON_LABELS.LOCATION.ENABLE_LOCATION
                }
              />
            </>
          ) : (
            <>
              <div
                // eslint-disable-next-line max-len
                className={`${
                  locationObject.state ? 'form-group' : 'form-group hide'
                }`}>
                <Input
                  inputType="text"
                  className="form-control"
                  id="location"
                  value={`${locationObject.state && locationObject.state.name}`}
                  disabled
                  textLabel="Location"
                />
              </div>
              <Button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_LOCATION,
                    {
                      action: 'Next',
                      location: navigator.geolocation.getCurrentPosition(
                        onAllowLocation,
                        onDenyLocation,
                      ),
                    },
                  );
                  storeData();
                }}
                label={BUTTON_LABELS.COMMON.NEXT}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
