import axios from 'axios';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { setCookie } from 'Utils/storage-utilities';

axios.interceptors.response.use(
  response => response,
  error => {
    if (
      error.response.status === APP_CONSTANTS.HTTP_STATUS_CODES.UNAUTHORIZED
    ) {
      alert(MESSAGE_CONSTANTS.SESSION_EXPIRED);
      setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, '');
      setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, '');
      window.location.pathname = '/';
    }
    return Promise.reject(error);
  },
);

export class NetworkManager {
  static requestGET(url, headers = APP_CONSTANTS.HEADERS) {
    return axios({
      method: 'get',
      url,
      headers,
    });
  }

  static requestPOST(url, data, headers = APP_CONSTANTS.HEADERS) {
    return axios({
      method: 'post',
      url,
      headers,
      data,
    });
  }

  static requestPUT(url, data, headers = APP_CONSTANTS.HEADERS) {
    return axios({
      method: 'put',
      url,
      headers,
      data,
    });
  }

  static reqGET(url) {
    return axios({
      method: 'get',
      url,
    });
  }

  static requestAuthGET(url, headers = APP_CONSTANTS.AUTH_HEADER_TOKEN()) {
    return axios({
      method: 'get',
      url,
      headers,
    });
  }

  static requestAuthPOST(
    url,
    data,
    headers = APP_CONSTANTS.AUTH_HEADER_TOKEN(),
  ) {
    return axios({
      method: 'post',
      url,
      headers,
      data,
    });
  }

  static requestAuthPUT(
    url,
    data,
    headers = APP_CONSTANTS.AUTH_HEADER_TOKEN(),
  ) {
    return axios({
      method: 'put',
      url,
      headers,
      data,
    });
  }

  static requestAuthDELETE(
    url,
    data,
    headers = APP_CONSTANTS.AUTH_HEADER_TOKEN(),
  ) {
    return axios({
      method: 'delete',
      url,
      headers,
      data,
    });
  }
}
