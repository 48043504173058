/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getCookie, setInSessionStorage } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { getMyContests } from 'Actions/contest-action';
import { getUserBalance } from 'Actions/user-balance-action';
import { Image } from 'Components/Form-Elements/Image';
import { getNotifications } from 'Actions/user-notification-action';
import { SignUpModal } from 'Components/ModalSignUpFlow';
import { ProfileContext } from 'Components/Profile/Context';
import { ContextProvider } from 'Components/LineUpScreens/Context';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { LineUpModal } from 'Components/LineUpModalFlow';
import { detectDevice } from 'Utils/device-utils';
import { Location } from 'Components/Location';
import { Button } from 'Components/Form-Elements/Button';
import img from '../../assets/images/defaultImg.png';
import plusIcon from '../../assets/images/plusWhite@2x.png';
import navigation from '../../assets/images/bg-navigation.jpg';
// import logo from '../../assets/images/logo.svg';
import logo from '../../assets/images/logo_new.svg';
import home from '../../assets/images/dn-home.svg';
import user from '../../assets/icons/dn-user.svg';
import dollar from '../../assets/images/dn-dollar.svg';
import settings from '../../assets/images/dn-settings.svg';
import icon from '../../assets/icons/icons.svg';
import { MESSAGE_CONSTANTS } from '../../constants/message-constants';
import 'StyleSheets/components/navigation.css';

export const NavBar = ({ isDrawerOpen, close, locationPath }) => {
  const history = useHistory();
  const [noOfLineups, updateActiveLineups] = useState(0);
  const [totalBalance, updateTotalBalance] = useState(0);
  const { profileState } = useContext(ProfileContext);
  const { isUserLoggedIn } = profileState;
  const [showSignUpFlow, setShowSignUpFlow] = useState(false);
  const [unReadNotifsCount, setUnreadNotifsCount] = useState(0);
  const [dropLocation, setDropLocation] = useState('');
  const [showLineupModal, setShowLineupModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const isMobile = detectDevice();
  const toggleRoutes = () => {
    return isMobile
      ? history.push('/profile?lineup=true')
      : setShowLineupModal(true);
  };
  useEffect(() => {
    if (isUserLoggedIn) {
      getMyContests(APP_CONSTANTS.CONTEST_FILER.LIVE)
        .then(({ data: { entrants } }) => {
          const noOfLineups = (entrants && entrants.length) || 0;
          updateActiveLineups(noOfLineups);
        })
        .catch(e => {
          updateActiveLineups(0);
        });

      getUserBalance(['USD']).then(({ data }) => {
        if (data && data.availableBalance && data.availableBalance.amount) {
          updateTotalBalance(data.availableBalance.amount.toFixed(2));
        } else updateTotalBalance((0).toFixed(2));
      });
    }
  }, [isDrawerOpen, isUserLoggedIn]);

  useEffect(() => {
    if (isUserLoggedIn) {
      getNotifications(APP_CONSTANTS.GET_NOTIFICATION_COUNT)
        .then(({ data }) => {
          setUnreadNotifsCount(data.unreadCount);
        })
        .catch(err => {});
    }
  }, [isUserLoggedIn]);

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  const goToSignUp = gotoLocation => {
    setShowSignUpFlow(true);
    setDropLocation(gotoLocation);
  };

  const afterSignUp = (toggleSignupModal, isSuccess = false) => {
    setShowSignUpFlow(toggleSignupModal);
    if (isSuccess && !dropLocation) {
      setInSessionStorage('show-add-fund', true);
      history.push('/wallet');
    } else if (isSuccess && dropLocation) {
      history.push(dropLocation);
    }

    setDropLocation('');
  };

  const toggleDrawer = () => {
    const element = document.getElementById('root');
    element.classList.toggle('isNavOpen');
  };

  if (!isUserLoggedIn) {
    return (
      <div
        className="navigation"
        style={{
          backgroundImage: `url(${navigation})`,
        }}>
        {showSignUpFlow && (
          <SignUpModal
            showSignUpFlow={showSignUpFlow}
            setShowSignUpFlow={afterSignUp}
          />
        )}
        <div className="navigation__header">
          <div className="navigation__logoWrapper">
            <div
              className="navigation__logo"
              style={{
                backgroundImage: `url(${logo})`,
              }}
            />
          </div>
        </div>
        <div className="navigation__body">
          <Link
            className={`navigation__link ${
              locationPath === '/foryou-guest' ? 'active' : ''
            }`}
            to="/foryou-guest"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                action: 'Active Lineups',
              });
              toggleDrawer();
            }}>
            <div
              className={`navigation__link ${
                locationPath === '/foryou-guest' ? 'active' : ''
              } d-flex`}>
              <div className="menuDetails flex-grow-1 d-flex">
                <div className="userIcon" />
                <div className="menuTitle">
                  {process.env.REACT_APP_INTERNATIONAL_ENABLED === 'true'
                    ? MESSAGE_CONSTANTS.FOR_YOU.HEADING_INTL
                    : MESSAGE_CONSTANTS.FOR_YOU.HEADING}
                </div>
              </div>
            </div>
          </Link>
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <span onClick={() => setShowSignUpFlow(true)}>
              <div
                className={`navigation__link ${
                  locationPath === '/wallet' ? 'active' : ''
                } d-flex`}>
                <div className="menuDetails flex-grow-1 d-flex">
                  <div className="deviceIcon" />
                  <div className="menuTitle">Your Wallet</div>
                </div>
              </div>
            </span>
          ) : (
            ''
          )}
          <span onClick={() => goToSignUp('/for-you')}>
            <div className="drawerMenu d-flex">
              <div className="menuDetails flex-grow-1 d-flex">
                <Image className="userImage" alt="avatar" defaultImage={img} />
                <div>
                  <div className="menuTitle">Complete Profile</div>
                </div>
              </div>
            </div>
          </span>
        </div>
        {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
          <span
            className="d-md-block referFriendBtn"
            onClick={() => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.TAP_REFER_A_FRIEND_MAIN_NAV,
                {
                  action: 'Tap Refer-a-Friend (main nav)',
                },
              );
              goToSignUp('/refer-friend');
            }}>
            REFER A FRIEND AND RECEIVE $10
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <>
      <div className="App__leftDrawer">
        <button
          type="button"
          className="hamburger button"
          onClick={() => {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.TOGGLE_DRAWER, {
              action: 'Toggle Drawer',
            });
            toggleDrawer();
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
            <use xlinkHref={`${icon}#i-hamburger`} />
          </svg>
        </button>
        <div
          className="navigation"
          style={{
            backgroundImage: `url(${navigation})`,
          }}>
          <div className="navigation__header">
            <div className="navigation__logoWrapper">
              <div
                className="navigation__logo"
                style={{
                  backgroundImage: `url(${logo})`,
                }}
              />
            </div>
          </div>
          <div className="navigation__body">
            {process.env.REACT_APP_INTERNATIONAL_ENABLED === 'true' ? (
              isMobile ? (
                <Link
                  className="navigation__link"
                  to="/profile?lineup=true"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.SUBMIT_LINEUP,
                      {
                        action: 'Submit Lineup',
                      },
                    );
                    toggleDrawer();
                  }}>
                  <span
                    className="navigation__linkIcon"
                    style={{
                      backgroundImage: `url(${plusIcon})`,
                    }}
                  />
                  <span className="navigation__linkText">CREATE LINEUP</span>
                </Link>
              ) : (
                <div
                  className="navigation__link"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.SUBMIT_LINEUP,
                      {
                        action: 'Submit Lineup',
                      },
                    );
                    toggleRoutes();
                  }}>
                  <span
                    className="navigation__linkIcon"
                    style={{
                      backgroundImage: `url(${plusIcon})`,
                    }}
                  />
                  <span className="navigation__linkText">CREATE LINEUP</span>
                </div>
              )
            ) : (
              ''
            )}
            <Link
              className={`navigation__link ${
                locationPath === '/for-you' ? 'active' : ''
              } ${noOfLineups > 0 ? 'dot' : ''}`}
              to="/for-you"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                  action: 'Active Lineups',
                });
                toggleDrawer();
              }}>
              <span
                className="navigation__linkIcon"
                style={{
                  backgroundImage: `url(${isMobile ? user : home})`,
                }}
              />
              <span className="navigation__linkText">
                {process.env.REACT_APP_INTERNATIONAL_ENABLED === 'true'
                  ? MESSAGE_CONSTANTS.FOR_YOU.HEADING_INTL
                  : MESSAGE_CONSTANTS.FOR_YOU.HEADING}
              </span>
              {/* {detectDevice() &&
              process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
                <span className="notificationIcon">
                  {unReadNotifsCount > 0 && (
                    <span className="notificationIcon__count">
                      {unReadNotifsCount}
                    </span>
                  )}
                </span>
              ) : null} */}
            </Link>
            {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
              <Link
                className={`navigation__link ${
                  locationPath === '/wallet' ? 'active' : ''
                }`}
                to="/wallet"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.TAP_WALLET_MAIN_NAV,
                    {
                      action: 'Your Wallet',
                    },
                  );
                  toggleDrawer();
                }}>
                <span
                  className="navigation__linkIcon"
                  style={{
                    backgroundImage: `url(${dollar})`,
                  }}
                />
                <span className="navigation__linkText">Your Wallet</span>
                {!!totalBalance && (
                  <span className="walletAmount">${totalBalance}</span>
                )}
              </Link>
            ) : (
              ''
            )}
            <Link
              className={`navigation__link extraPadding ${
                locationPath === '/profile' ? 'active' : ''
              } ${noOfLineups > 0 ? 'dot' : ''}`}
              to="/profile"
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.TAP_PROFILE_MAIN_NAV,
                  {
                    action: 'profile',
                  },
                );
                toggleDrawer();
              }}>
              <span
                className="navigation__linkIcon avatar"
                style={{
                  backgroundImage: `url(${profileState.profileImage})`,
                }}
              />
              <span className="navigation__linkText activeLineups">
                {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true'
                  ? userdata.username
                  : MESSAGE_CONSTANTS.GAME_HISTORY}
                {noOfLineups > 0 ? (
                  <span className="activeLineups__text">
                    {noOfLineups} Active Games
                  </span>
                ) : (
                  ''
                )}
              </span>
            </Link>
            {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
              <Link
                className={`navigation__link ${
                  locationPath === '/settings' ||
                  locationPath === '/settings-desk'
                    ? 'active'
                    : ''
                } settings`}
                to={() => (detectDevice() ? '/settings' : '/settings-desk')}
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SETTINGS_ALL_EVENTS,
                    {
                      action: 'Settings',
                    },
                  );
                  toggleDrawer();
                }}>
                <span
                  className="navigation__linkIcon"
                  style={{
                    backgroundImage: `url(${settings})`,
                  }}
                />
                <span className="navigation__linkText">Settings</span>
              </Link>
            ) : (
              ''
            )}
          </div>
          <div className="navigation__footer">
            {/* <Link
          to="/refer-friend"
          className="navigation__refer"
          onClick={() => toggleDrawer()}>
          REFER A FRIEND AND RECEIVE $10
        </Link> */}
            <Link
              to="/how-to-play"
              className="navigation__refer"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.HOW_TO_PLAY, {
                  action: 'How to Play',
                });
                toggleDrawer();
              }}>
              How to Play
            </Link>
          </div>
        </div>
      </div>
      {showLineupModal && (
        <ContextProvider>
          <LineUpModal
            showLineupModal={showLineupModal}
            setShowLineupModal={setShowLineupModal}
            history={history}
          />
        </ContextProvider>
      )}
    </>
  );
};
LineUpModal.defaultProps = {
  location: { state: undefined },
};
