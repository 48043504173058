import React from 'react';
import PropTypes from 'prop-types';
import { formatDateMonthDate } from 'Utils/date-utils';

export const NotificationList = ({ value, readNotifs }) => {
  return (
    <div className="notificationsWrapper">
      <div
        className="transactionContents"
        onClick={() => {
          if (!value.read) {
            readNotifs(value.id, value.read);
          }
        }}>
        <div
          className={`${!value.read ? 'unreadNotifs ' : ''} notificationtitle`}>
          {value.title}
        </div>
        <div className="notificationDate">
          {formatDateMonthDate(value.createdDate)}
        </div>
      </div>
    </div>
  );
};

NotificationList.propTypes = {
  value: PropTypes.objectOf(PropTypes.any).isRequired,
  readNotifs: PropTypes.func.isRequired,
};
