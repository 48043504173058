/* eslint-disable max-len */
import { APP_CONSTANTS } from 'Constants/app-constants';

export const MIXPANEL_EVENTS_CONSTANTS = {
  SIGNUP_LOCATION: 'Sign Up - Location > Enable > Open Settings',
  LOGGED_OUT_SPLASH_SCREEN: 'Logged Out Splash Screen',
  SIGNUP_ENTER_DOB: 'Sign Up - Enter DOB > VerifyDob',
  SIGNUP_ENTER_EMAIL: 'Sign Up - Enter Email > Open Mail > Email Sent',
  SIGNUP_SET_USERNAME: 'Sign Up - Set Username',
  SIGNUP_CREATE_PASSWORD: 'Sign Up - Create Password',
  SIGNUP_NAME: 'Sign Up - Name',
  SIGNUP_PHONE_NUMBER: 'Sign Up - Phone Number',
  SIGNUP_VERIFY_PHONE_NUMBER: 'Sign Up - Verify Phone Number',
  USER_PREFERENCES_FAVORITE_SPORT: 'User Preferences - Favorite Sport',
  SELECT_LINEUP: 'Select Lineup',
  PAGE_VIEWED: 'Page Viewed',
  DEPOSIT: 'Deposit',
  WITHDRAW: 'Withdraw',
  SUBMIT_LINEUP: 'Submit Lineup',
  SUBMIT_PREFERENCES: 'Submit Preferences',
  TAP_PLAYER_HEADSHOT: 'Tap player headshot',
  TAP_LIVE_PLAYER_CARD_GRAPH_FILTERS: 'Tap Live player card graph filters',
  TAP_MAIN_MENU_ICON: 'Tap Main Menu icon',
  TAP_NOTIFICATION_ICON: 'Tap Notification Icon',
  CLOSE_ANY_MODAL: 'Close ANY modal',
  TAP_SKIP_FOR_INITIAL_SPLASH_SCREENS: 'Tap SKIP for initial splash screens',
  LOGIN_VIA_GOOGLE_SSO: 'Login via Google SSO',
  LOGIN_VIA_FB_SSO: 'Login via FB SSO',
  TAP_WALLET_MAIN_NAV: 'Tap Wallet - Main nav',
  TAP_FOR_YOU_MAIN_NAV: 'Tap For You - Main nav',
  TAP_FOR_YOU__ALL_EVENTS: 'Tap For You > All Events',
  TAP_PROFILE_MAIN_NAV: 'Tap Profile - Main nav',
  TAP_REFER_A_FRIEND_MAIN_NAV: 'Tap Refer-a-Friend (main nav)',
  GEO_LOCATION_ENABLED_DISABLED: 'Geo-location enabled/disabled',
  KYC_TRIGGERED: 'KYC triggered',
  TAP_IMPORT_CONTACTS_BUTTON_ALL_INTERACTIONS:
    'Tap Import Contacts button > all interactions',
  TAP_EDIT_PROFILE_ALL_INTERACTIONS: 'Tap Edit Profile > all interactions',
  PROFILE_ACTIVE_GAMES_ALL_EVENTS: 'Profile > Active Games > All events ...',
  PROFILE_ACTIVITY_ALL_EVENTS: 'Profile > Activity > All events ...',
  PROFILE_PAGE: 'Profile Page',
  SETTINGS_ALL_EVENTS: 'Settings > All Events ...',
  LOGIN_SCREEN: 'Login Screen',
  FORGET_PASSWORD: 'Forget Password',
  HOW_TO_PLAY: 'How To Play',
  ADD_NEW_GAME: 'Add New Game',
  RESPONSIBLE_GAMING: 'Responsible Gaming',
  SELF_EXCLUSION: 'Self Exclusion',
  GAME_RULE: 'Game Rule',
  TUTORIALS: 'Tutorials',
  TOGGLE_DRAWER: 'Toggle Drawer',
  LANDING_PAGE: 'Let’s Do It',
  MOBILE_LOGIN: 'Log in SMS',
  ARROW_BACK: 'arrow back',
  EMAIL_ENTER: 'Continue > open mail app > resend mail',
  OTP: 'Send OTP > Resend OTP',
};
