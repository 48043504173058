import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const getPaymentMethods = async data => {
  const url = API.GET_DEPOSIT_METHODS;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const createDeposit = async data => {
  const url = API.CREATE_DEPOSIT;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const startCreateDeposit = async (token, data = '') => {
  const url = API.START_CREATE_DEPOSIT;
  const Headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const response = await NetworkManager.requestAuthPOST(url, data, Headers);
  return response;
};

export const createDepositMethod = async data => {
  const url = API.CREATE_DEPOSIT_METHOD;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};
