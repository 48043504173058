/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { mixpanelTrackPageView } from 'Utils/mix-panel';
import { Link } from 'react-router-dom';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Button } from 'Components/Form-Elements/Button';
import { detectDevice } from 'Utils/device-utils';

export const KycErrorScreen = ({ closeModalBox, isModal }) => {
  useEffect(() => {
    mixpanelTrackPageView('Kyc Error Screen');
  }, []);

  const isMobile = detectDevice();
  return (
    <div
      className="d-flex align-items-center 
                justify-content-center kycErrorWrapper">
      <div className="d-flex topBar">
        {!isMobile ? (
          isModal ? (
            <Button
              className="linkBtn topClose btnBackArrow"
              onClick={() => closeModalBox()}
            />
          ) : (
            <Link to="/wallet" className="topClose btnBackArrow" />
          )
        ) : (
          <Link to="/wallet" className="topClose btnBackArrow" />
        )}

        <div className="flex-grow-1" />
      </div>
      <div className="wrapper">
        <div className="info">
          <div className="iconBig cautionIcon" />
          <div className="headingLine">
            <strong>{MESSAGE_CONSTANTS.MODALBOX.KYC_ERROR.title}</strong>
          </div>
          <div className="description">
            {MESSAGE_CONSTANTS.MODALBOX.KYC_ERROR.message}
          </div>
        </div>
        <div className="centerText">
          <a
            href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_MAILID}`}
            target="_blank"
            rel="noopener noreferrer"
            className="fontBold18">
            Contact Customer Support
          </a>
        </div>
      </div>
    </div>
  );
};

KycErrorScreen.prototype = {
  closeModalBox: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
};
