import React, { useState, useEffect, useRef } from 'react';
import 'StyleSheets/css/favorite-team.css';
import { searchPlayers } from 'Actions/survey-search-action';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { savePreference } from 'Actions/save-user-preference';
import { ListView } from 'Components/ListView';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';

export const SelectPlayers = ({ history }) => {
  const [searchText, setSearchText] = useState('');
  const [playersList, setPlayerList] = useState([]);
  const [selectedPlayerList, setSelectedPlayerList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const pageno = useRef(1);
  const searchtext = useRef('');
  const playerList = useRef([]);

  const formatPlayerData = list => {
    return list.map(listItem => {
      /* eslint no-underscore-dangle: 0 */
      return {
        id: listItem._id,
        title: listItem.name,
        subTitle: `${listItem.position} - ${listItem.teamInfo.fullName}`,
        isSelected: selectedPlayerList.some(item => item.id === listItem._id),
      };
    });
  };

  const playerSearch = (count = pageno.current) => {
    const text = searchtext.current;
    searchPlayers(searchtext.current, count)
      .then(({ data }) => {
        if (
          data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          count === 1 &&
          text === searchtext.current
        ) {
          setPlayerList(formatPlayerData(data.data));
          setPageNo(count + 1);
        } else if (
          data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          count > 1 &&
          text === searchtext.current
        ) {
          setPlayerList([
            ...playerList.current,
            ...formatPlayerData(data.data),
          ]);
          setPageNo(count + 1);
        }
      })
      .catch(({ response }) => {
        if (
          response.status === APP_CONSTANTS.HTTP_STATUS_CODES.NOT_FOUND &&
          count === 1
        ) {
          setPlayerList([]);
          playerList.current = [];
        }
      });
  };

  const loadMorePlayers = () => {
    playerSearch();
  };

  useEffect(() => {
    if (pageno.current !== pageNo) {
      pageno.current = pageNo;
    }
    if (searchtext.current !== searchText) {
      searchtext.current = searchText;
      if (searchText.length > 2) {
        playerSearch(1);
      } else {
        setPlayerList([]);
        playerList.current = [];
      }
    }
    if (playerList.current !== playersList) {
      playerList.current = playersList;
    }
  }, [searchText, pageNo, playersList]);

  const togglePlayers = item => {
    const temp = playersList;
    temp.forEach(listItem => {
      if (listItem.id === item.id) {
        // eslint-disable-next-line no-param-reassign
        listItem.isSelected = !listItem.isSelected;
      }
    });
    setPlayerList([...temp]);

    let temps = selectedPlayerList;
    if (item.isSelected) {
      temps.push(item);
    } else {
      temps = temps.filter(listItem => listItem.id !== item.id);
    }
    setSelectedPlayerList([...temps]);
  };

  const getPlayerData = () => {
    const data = [];
    selectedPlayerList.forEach(player => {
      if (player.isSelected) {
        data.push(player.id);
      }
    });
    return {
      favouritePlayers: data,
    };
  };

  const handleSubmit = () => {
    savePreference(getPlayerData())
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          history.push('/select-get-paid-type');
        }
      })
      .catch(resp => {
        // handle error here.
      });
  };

  const handleTextChange = event => {
    const text = event.target.value;
    setSearchText(text);
  };

  const handlePlayerDelete = deletePlayer => {
    setSelectedPlayerList(
      selectedPlayerList.filter(team => team.id !== deletePlayer.id),
    );
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="60" />
          <div className="d-flex topBar">
            <Link to="/for-you" className="arrowBack" />
            <h2 className="heading">Sign Up</h2>
            <Link
              to="/select-get-paid-type"
              // eslint-disable-next-line max-len
              className={`topSkip bottomFixed btnTransparent ${searchText.length &&
                'd-none'}`}>
              {BUTTON_LABELS.COMMON.SKIP}
            </Link>
          </div>
        </div>
        <div
          className={`wrapper ${searchText.length && 'favoriteTeamsWrapper'}`}>
          <div className="question info">
            <div className="title">
              {MESSAGE_CONSTANTS.SURVEY.FAVORITE_PLAYERS}
            </div>
          </div>
          <div className="searchWrapper">
            <input
              type="text"
              name="search"
              value={searchText}
              className="inputSearchBox"
              placeholder={MESSAGE_CONSTANTS.SURVEY.FIND_PLAYER}
              onChange={handleTextChange}
              autoComplete="off"
            />
            <div className="searchIcon" />
            <Button className="btnClose" onClick={() => setSearchText('')} />
          </div>
        </div>
        {!searchText.length && selectedPlayerList.length ? (
          <div className="yourFavoriteTeams">
            <div className="standings">
              {MESSAGE_CONSTANTS.SURVEY.YOUR_FAVORITES}
            </div>
            <ListView
              arrList={selectedPlayerList}
              designType={APP_CONSTANTS.LIST_VIEW_DESIGN.ONE_ICON}
              handleClick={handlePlayerDelete}
            />
          </div>
        ) : (
          <div className="teamListingWrapper">
            <ListView
              id="playerList"
              arrList={playersList}
              designType={APP_CONSTANTS.LIST_VIEW_DESIGN.TWO_ICON}
              handleClick={togglePlayers}
              loadMore={loadMorePlayers}
            />
          </div>
        )}
        <div className="btnTeamWrapper">
          <Button
            className="btn btn-primary"
            type="submit"
            onClick={handleSubmit}
            disabled={!selectedPlayerList.some(({ isSelected }) => isSelected)}
            label={BUTTON_LABELS.COMMON.NEXT}
          />
        </div>
      </div>
    </>
  );
};
