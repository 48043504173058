import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';
import { getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const getUserDetail = async (
  data = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA),
) => {
  const { _id } = data;
  const url = `${API.USER_DETAIL}?id=${_id}`;
  const response = await NetworkManager.requestAuthGET(url);
  return response;
};

export const updateUserProfileZipcode = async zipCode => {
  const url = API.USER_DETAIL;
  const response = await NetworkManager.requestAuthPUT(url, { zipCode });
  return response;
};

export const updateUserProfileKYC = async (
  firstName,
  lastName,
  email,
  birthDate,
  zipCode,
) => {
  const data = {
    firstName,
    lastName,
    email,
    birthDate,
    zipCode,
  };
  const url = API.USER_DETAIL;
  const response = await NetworkManager.requestAuthPUT(url, data);
  return response;
};

export const getAccountVerificationDetails = async (
  data = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA),
) => {
  const { _id } = data;
  const url = API.GET_ACCOUNT_VERIFICATION_DETAILS;
  const response = await NetworkManager.requestAuthPOST(url, { _id });
  return response;
};

export const updateAccountVerificationDetails = async (
  firstName,
  lastName,
  email,
  birthdate,
  phoneNumber,
  addressLine1,
  addressLine2,
  city,
  state,
  postalCode,
  countryCode,
) => {
  const data = {
    firstName,
    lastName,
    email,
    birthdate,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    state,
    postalCode,
    countryCode,
  };
  const url = API.UPDATE_ACCOUNT_VERIFICATION_DETAILS;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const updateUserProfile = async userName => {
  const url = API.USER_DETAIL;
  const response = await NetworkManager.requestAuthPUT(url, {
    username: userName,
  });
  return response;
};

export const getSignedUrl = async (imageName, userPublicId) => {
  const uploadPathToAws = `users/${userPublicId}/icon/${imageName}`;
  // eslint-disable-next-line max-len
  const url = `${process.env.REACT_APP_UPLOAD_IMAGE_URL}?name=${uploadPathToAws}`;
  const response = await NetworkManager.reqGET(url);
  return response;
};

export const uploadImageToAws = async (signedURL, file) => {
  const Header = {
    'Content-Type': `${file.type}`,
  };
  const response = await NetworkManager.requestPUT(signedURL, file, Header);
  return response;
};

export const updateUserProfileImage = async target => {
  const url = API.USER_PROFILE_IMAGE;
  const response = await NetworkManager.requestAuthPUT(url, {
    imageUrl: target,
  });
  return response;
};
