/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint no-else-return: "error" */

import React from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { overviewStatMapping } from 'Components/LineUpScreens/SportData/sportsObject';

const renderOverViewStats = (
  games,
  stats,
  isPreGame,
  isLiveOrPostGame,
  isLiveGame,
  position,
  recentStats,
  rankByPosition,
) => {
  if (games.sportSlug === APP_CONSTANTS.SPORT_SLUG.MLB) {
    const isPitcher = position && position === 'P';
    const player = isPitcher ? 'pitcher' : 'hitter';
    const gameStatStatus = isPreGame ? 'preGame' : 'liveOrPostGame';
    const mapping =
      APP_CONSTANTS.MLB_OVERVIEW_STATS_MAPPING[player][gameStatStatus];

    return mapping.map(stat => (
      <div className="seasonAvg text-center" key={stat.title}>
        <div className="aboutPlayer">{stat.title}</div>
        <div className="recordDetails">
          {stat.title === 'Result'
            ? isLiveGame
              ? '-'
              : isLiveOrPostGame && stats
              ? stats[stat.valueField[0]] && stats[stat.valueField[0]] === 1
                ? 'Win'
                : 'Loss'
              : 'NA'
            : Array.isArray(stat.valueField) && stats
            ? `${stats[stat.valueField[0]] || 0}-${stats[stat.valueField[1]] ||
                0}`
            : (stats && stats[stat.valueField]) || 0}
        </div>
      </div>
    ));
  } else if (games.sportSlug === APP_CONSTANTS.SPORT_SLUG.NFL) {
    const mapping = overviewStatMapping(
      games.sportSlug,
      position,
      isPreGame,
      stats,
      recentStats,
      rankByPosition,
    );

    return mapping.map(stat => (
      <div className="seasonAvg text-center" key={stat.title}>
        <div className="aboutPlayer">{stat.title}</div>
        <div className="recordDetails">{stat.value || 0}</div>
      </div>
    ));
  } else if (games.sportSlug === APP_CONSTANTS.SPORT_SLUG.NBA) {
    const mapping = overviewStatMapping(
      games.sportSlug,
      position,
      isPreGame,
      stats,
      recentStats,
      rankByPosition,
    );

    return mapping.map(stat => (
      <div className="seasonAvg text-center" key={stat.title}>
        <div className="aboutPlayer">{stat.title}</div>
        <div className="recordDetails">{stat.value || 0}</div>
      </div>
    ));
  } else if (games.sportSlug === APP_CONSTANTS.SPORT_SLUG.NHL) {
    const mapping = overviewStatMapping(
      games.sportSlug,
      position,
      isPreGame,
      stats,
      recentStats,
      rankByPosition,
    );

    return mapping.map(stat => (
      <div className="seasonAvg text-center" key={stat.title}>
        <div className="aboutPlayer">{stat.title}</div>
        <div className="recordDetails">{stat.value || 0}</div>
      </div>
    ));
  }
  return '';
};

export const PlayerOverviewStats = ({
  player,
  liveStats,
  seasonStats,
  players,
  recentStats,
  games,
}) => {
  const last = games.status === 'Complete';
  const live = games.status === 'InProgress' && !games.finished;
  const next = games.status === 'Scheduled';
  const postponed = games.status === 'Postponed';
  const canceled = games.status === 'Canceled';
  const suspended = games.status === 'Suspended';
  const wdelay = games.status === 'Wdelay';
  const fdelay = games.status === 'Fdelay';
  const odelay = games.status === 'Odelay';
  const closed = games.status === 'Closed';
  const position = players && players.position;
  const rankByPosition = players && players.rankByPos;

  return (
    <>
      {next || postponed || canceled ? (
        <>
          <div className="playerRecords d-flex">
            {renderOverViewStats(
              games,
              seasonStats,
              true,
              false,
              false,
              position,
              recentStats,
              rankByPosition,
            )}
          </div>
        </>
      ) : (
        ''
      )}
      {live || wdelay || fdelay || odelay || last || suspended || closed ? (
        <>
          <div className="playerRecords d-flex">
            {renderOverViewStats(
              games,
              liveStats,
              false,
              true,
              live || wdelay || fdelay || odelay,
              position,
              recentStats,
              rankByPosition,
            )}
          </div>
        </>
      ) : (
        ''
      )}
    </>
  );
};
