/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import { KycErrorScreen } from 'Components/Kyc/KycErrorScreen';

export const CustomerSupportModal = ({
  showCustomerSupport,
  setShowCustomerSupport,
}) => {
  const fetchAndSetModalPage = pageLocation => {
    SetInitialPage(routesHash[pageLocation]);
  };

  const closeModalBox = () => setShowCustomerSupport(false);

  const [initialPage, SetInitialPage] = useState(
    <KycErrorScreen
      isModal
      fetchAndSetModalPage={fetchAndSetModalPage}
      closeModalBox={closeModalBox}
    />,
  );

  const commonProps = {
    fetchAndSetModalPage,
    isModal: true,
    closeModalBox,
  };

  const routesHash = {
    '/customer-support': <KycErrorScreen {...commonProps} />,
  };

  return (
    <div>
      {showCustomerSupport && (
        <Modal isOpen centered>
          <ModalBody>{initialPage}</ModalBody>
        </Modal>
      )}
    </div>
  );
};

CustomerSupportModal.propTypes = {
  showCustomerSupport: PropTypes.bool.isRequired,
  setShowCustomerSupport: PropTypes.func.isRequired,
};
