import React, { useState } from 'react';
import 'StyleSheets/css/favorite-sport.css';
import { ARRAY_CONST } from 'Constants/array-constants';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { CheckBox } from 'Components/Form-Elements/Checkbox';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { savePreference } from 'Actions/save-user-preference';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const GetPaid = ({ history }) => {
  const [payTypes, setPayType] = useState(ARRAY_CONST.GET_PAID);

  const handleCheckboxChange = name => {
    setPayType(
      payTypes.map(payType => {
        if (payType.name === name) {
          // eslint-disable-next-line no-param-reassign
          payType.isSelected = !payType.isSelected;
        }
        return payType;
      }),
    );
  };

  const getPlayData = () => {
    const data = [];
    payTypes.forEach(payType => {
      if (payType.isSelected) {
        data.push(payType.name);
      }
    });
    return {
      paidStrategies: data,
    };
  };

  const handleSubmit = () => {
    savePreference(getPlayData())
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          history.push('/select-preference');
        }
      })
      .catch(resp => {
        // handle error here.
      });
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="80" />
          <div className="d-flex topBar">
            <Link to="/for-you" className="arrowBack" />
            <h2 className="heading">Sign Up</h2>
            <Link
              to="/select-preference"
              className="bottomFixed btnTransparent">
              {BUTTON_LABELS.COMMON.SKIP}
            </Link>
          </div>
        </div>

        <div className="wrapper">
          <div className="question info">
            <div className="title">{MESSAGE_CONSTANTS.SURVEY.GET_PAID}</div>
          </div>
          <form>
            <div className="sportListingWrapper">
              {payTypes.map(({ name, isSelected }) => {
                return (
                  <CheckBox
                    label={name}
                    isSelected={isSelected}
                    onCheckboxChange={handleCheckboxChange}
                    key={name}
                  />
                );
              })}
            </div>
            <Button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubmit}
              disabled={!payTypes.some(({ isSelected }) => isSelected)}
              label={BUTTON_LABELS.COMMON.NEXT}
            />
          </form>
        </div>
      </div>
    </>
  );
};
