/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import {
  getFromSessionStorage,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { Button } from 'Components/Form-Elements/Button';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { detectDevice } from 'Utils/device-utils';
import { maskCardNumber } from 'Utils/data-utils';

export const PaymentMethodList = ({
  history,
  fetchAndSetModalPage,
  isModal,
}) => {
  const [card, setCard] = useState(getFromSessionStorage('active-card'));

  const isCashoutScreen = getFromSessionStorage('is-cashout');
  const savedCards = getFromSessionStorage('saved-cards');
  const isMobile = detectDevice();

  const moveToPayment = () => {
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/payment')
        : history.push('/payment')
      : fetchAndSetModalPage('/payment');
  };

  const renderDepositMethod = item => {
    return (
      <li
        className={`d-flex ${
          card.payment_method_identifier === item.payment_method_identifier
            ? 'selectedCard'
            : ''
        }`}
        key={item.publicId}>
        <button
          type="button"
          tag="button"
          className="d-flex align-items-center"
          onClick={() => setCard(item)}>
          <div className="listIcon cardIcon" />
          <div className="infoWrap flex-grow-1">
            <div className="title">
              {item.card_brand ? item.card_brand : item.description}
            </div>
            <div className="info font-sm">
              <span>{item.account_type}</span>
              <span>{maskCardNumber(item.number)}</span>
              <span>
                {item.expiration_date ? `Expires ${item.expiration_date}` : ''}
              </span>
            </div>
          </div>
        </button>
      </li>
    );
  };

  const saveAndMoveToAddFunds = () => {
    setInSessionStorage('active-card', card);
    const path = isCashoutScreen ? '/cash-out' : '/add-funds';
    // eslint-disable-next-line no-unused-expressions
    isMobile
      ? isModal
        ? fetchAndSetModalPage(path)
        : history.push(path)
      : fetchAndSetModalPage(path);
  };

  return (
    <div>
      <div className="d-flex popUpHeader">
        <div className="leftSide">
          <Button
            className="link linkBtn arrowBack"
            onClick={() => saveAndMoveToAddFunds()}
          />
        </div>
        <h2>Payment Methods</h2>
        <div className="walletAmountBtn rightSide" />
      </div>
      <div className="wrapper">
        <div className="listWithIcons">
          <div className="listTitle">Connected Accounts</div>
          <ul>
            {card &&
              card.payment_method_identifier &&
              savedCards &&
              savedCards.map(item => renderDepositMethod(item))}
            <li className="d-flex" key="add-card">
              <button
                className="d-flex align-items-center"
                type="button"
                onClick={moveToPayment}>
                <div className="listIcon addCardIcon" />
                <div className="link">{BUTTON_LABELS.WALLET.ADD_CARD}</div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
