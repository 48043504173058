/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { videoTutorialArray } from './TutorialData';

export const VideoTutorial = ({ showBack = true }) => {
  const [isactive, setactive] = useState(0);
  const renderImage = () => {
    return videoTutorialArray.map(item => (
      <React.Fragment key={item.id}>
        <iframe
          title={item.text}
          allowFullScreen
          name={item.id}
          frameBorder="0"
          className={isactive !== item.id && 'hide'}
        />
        <a
          target={item.id}
          onClick={() => setactive(item.id)}
          href={item.videoId}
          className={isactive === item.id && 'hide'}>
          <img src={item.thumbnail} alt={item.text} />
        </a>

        {item.text}
      </React.Fragment>
    ));
  };

  if (videoTutorialArray) {
    return (
      <div className="gameRulesWrapper">
        <div className="pageHeaderWrap">
          <div className="d-flex pageHeader flexStartWrap fixedHeader">
            {showBack && (
              <div className="leftSide">
                <Link to="/settings" className="arrowBack" />
                onClick=
                {() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.TUTORIALS, {
                    action: 'Back Arrow',
                  });
                }}
              </div>
            )}
            <h2>Tutorials</h2>
            <div className="rightSide" />
          </div>
        </div>
        <div className="tutorialWrap">{renderImage()}</div>
      </div>
    );
  }
  return null;
};

VideoTutorial.propTypes = {
  showBack: PropTypes.bool.isRequired,
};
