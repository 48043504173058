import React, { useState } from 'react';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { maskCardNumber, getPaymentMethodIconClass } from 'Utils/data-utils';
import {
  getFromSessionStorage,
  getCookie,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { createDeposit } from 'Actions/wirecard-action';
import 'Components/Loader/Loader.css';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ConfirmPayment = ({ close, amount, bal, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const item = getFromSessionStorage('active-card');
  const userCoords = getFromSessionStorage('userCoords');
  const dollar = getFromSessionStorage('selected-amount');
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const {
    ADD_FUNDS_VALIDATION,
    WIRECARD_RESP,
    CARD_TYPE: { CHECK, DEBIT, CREDIT },
  } = MESSAGE_CONSTANTS;

  const addFunds = () => {
    setIsLoading(true);
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
      action: 'Confirm Transfer',
      formData: {
        currentSelectedCard: item,
        addBalanceAmount: dollar,
        userData: userdata,
      },
    });
    const locationObj = {
      latitude: userCoords.lat,
      longitude: userCoords.long,
      horizontalAccuracy: userCoords.accuracy,
      timestamp: new Date().getTime(),
    };
    const createDepositObj = [
      item.payment_method_identifier,
      dollar,
      MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD,
      locationObj,
      userdata.appInstallId,
    ];

    return createDeposit(createDepositObj)
      .then(({ data }) => {
        setIsLoading(false);
        if (data.error) {
          setInSessionStorage('message', data.reason);
          setInSessionStorage('screen', WIRECARD_RESP.ERROR);
          close();
          history.push('/message');
        }
        if (data && !data.error) {
          setInSessionStorage('message', ADD_FUNDS_VALIDATION.FUNDS_ADDED);
          close();
          history.push('/message');
        }
      })
      .catch(error => {
        const msg = encodeURIComponent('Payment Failed!');
        setInSessionStorage('message', msg);
        setInSessionStorage('screen', WIRECARD_RESP.ERROR);
        setIsLoading(false);
        history.push('/message');
      });
  };

  const renderActiveCard = () => {
    return (
      <>
        <div className="dropDownWrap">
          <div className="listWithIcons">
            <ul className="border">
              <li className="d-flex" key={item.payment_method_identifier}>
                <button
                  type="button"
                  tag="button"
                  className="d-flex align-items-center addFundsBtn">
                  <div
                    className={`listIcon ${getPaymentMethodIconClass(item)}`}
                  />
                  <div className="infoWrap flex-grow-1">
                    <div className="title">
                      {item.card_brand ? item.card_brand : item.description}
                    </div>
                    <div className="info font-sm">
                      <span>{item.account_type}</span>
                      <span>{maskCardNumber(item.number)}</span>
                      <span>
                        {item.expiration_date
                          ? `Expires ${item.expiration_date}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="backfade">
        <div id="popModal" className="card-body addFundsModalWrap">
          <Button className="arrowBack btnCancel" onClick={close} />
          <div className="d-flex  mobilePopupHeader">
            <div className="flex-grow-1 title text-center">
              {MESSAGE_CONSTANTS.WALLET.ADD_FUNDS.HEADING}
            </div>
          </div>
          <div className="modalBody">
            <div className="info pHeader">
              <div className="title centerText">
                <strong>Add ${amount} to your wallet?</strong>
                <div className="availableBalance">
                  <span>{`$${bal} available in Wallet`}</span>
                  <div className="questionMarkInfo">
                    <span className="questionIcon" />
                    <div className="aboutQuestionInfo">
                      <p>
                        {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                        <a href="/terms-of-service"> terms and conditions</a>.
                        *Some exclusions apply.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="formToWrap">
              <div className="textFromTo">FROM</div>
              {renderActiveCard()}
              <div className="divider" />
              <div className="listWithIcons">
                <div className="textFromTo">TO</div>
                <ul className="border">
                  <li className="d-flex" key="add-card">
                    <button className="d-flex align-items-center" type="button">
                      <div className="iconDollor">$</div>
                      <div className="title"> Your Daily Number Wallet</div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <Button
              className="btn btn-primary fullWidht scrollBtn"
              type="button"
              onClick={() => {
                addFunds();
              }}
              disabled={isLoading}
              label="Confirm Transfer"
            />
          </div>
        </div>
      </div>
    </>
  );
};
