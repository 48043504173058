import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'rc-drawer/assets/index.css';
import 'react-rangeslider/lib/index.css';
import 'StyleSheets/css/main.css';

// new
// base
import 'StyleSheets/base/fonts.css';
import 'StyleSheets/base/minireset.css';
import 'StyleSheets/base/generic.css';

// old
import 'StyleSheets/css/lineup.css';
import 'StyleSheets/css/lineup-web.css';
import 'StyleSheets/css/active-lineup-profile.css';
import 'StyleSheets/css/player-detail.css';
import 'StyleSheets/css/game-rules.css';
import 'StyleSheets/css/game-scoring-rules.css';

import 'StyleSheets/css/left-drawer.css';

import 'StyleSheets/css/settings.css';
import 'StyleSheets/css/account-settings.css';
import 'StyleSheets/css/privacy-policy.css';
import 'StyleSheets/css/terms-of-service.css';

import 'StyleSheets/css/responsible-gaming.css';
import 'StyleSheets/css/transaction-details.css';
import 'StyleSheets/css/cashout.css';
import 'StyleSheets/css/responsive.css';

import 'StyleSheets/css/kyc.css';
import 'StyleSheets/css/new-game.css';
import 'StyleSheets/css/refer-friend.css';
import 'StyleSheets/css/notifications.css';

// new
// elements
import 'StyleSheets/elements/button.css';
import 'StyleSheets/elements/field.css';
import 'StyleSheets/elements/modal.css';

// new
// layout
import 'StyleSheets/layouts/app.css';

// new
// components
import 'StyleSheets/components/login.css';
import 'StyleSheets/components/bottomnav.css';

import 'StyleSheets/components/grid-view.css';
import 'StyleSheets/components/grid-view-new.css';
import 'StyleSheets/components/navigation.css';
import 'StyleSheets/components/game-card.css';
import 'StyleSheets/components/clist.css';
import 'StyleSheets/components/player-avatar.css';

import 'StyleSheets/components/lineup-card.css';
import 'StyleSheets/components/lineup-details.css';
import 'StyleSheets/components/splash.css';

import 'StyleSheets/components/alerts.css';
import 'StyleSheets/components/notifications.css';
import 'StyleSheets/components/refer-a-friend.css';

import 'StyleSheets/components/ticker.css';

// new
// components:: modals
import 'StyleSheets/components/lineup-draft-selection.css';
import 'StyleSheets/components/bottomsheet.css';

// page
import 'StyleSheets/components/wallet.css';

// new
// helpers
import 'StyleSheets/helpers/visibility.css';
import 'StyleSheets/helpers/utility.css';

import { Root } from './routes';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
