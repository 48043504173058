import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Input } from 'Components/Form-Elements/Input';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { getPasswordStrength } from 'Utils/validations';
import { Button } from 'Components/Form-Elements/Button';
import { setInSessionStorage } from 'Utils/storage-utilities';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const SetPassword = ({ history, isModal, fetchAndSetModalPage }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [passwordStrength, setStrength] = useState({});
  const [passwordMatch, setMatch] = useState(false);

  useEffect(() => {
    if (
      password.length &&
      passwordStrength.strength !== MESSAGE_CONSTANTS.SET_PASSWORD.WEAK
    ) {
      setMatch(confirmPassword === password);
      setDisableButton(!(confirmPassword === password));
    }
  }, [confirmPassword, password]);

  const handlePasswordChange = value => {
    setPassword(value);
    const strength = getPasswordStrength(value);
    setStrength({
      strength,
      colorCode: APP_CONSTANTS.PASSWORD_STRENGTH[strength.toUpperCase()],
    });
  };

  const storeData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PASSWORD,
      password,
    );
    if (isModal) {
      fetchAndSetModalPage('/enter-details');
      return;
    }
    history.push('/enter-details');
  };

  const getColorCode = () => {
    return passwordMatch
      ? APP_CONSTANTS.PASSWORD_MATCH.MATCH
      : APP_CONSTANTS.PASSWORD_MATCH.NOT_MATCH;
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="75" />
          <div className="d-flex topBar">
            {!isModal ? (
              <Link to="/set-username" className="arrowBack" />
            ) : (
              <Button
                className="linkBtn arrowBack"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_CREATE_PASSWORD,
                    {
                      action: 'Back',
                    },
                  );
                  fetchAndSetModalPage('/set-username');
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>
        <div className="wrapper">
          <div className="info">
            <div className="iconBig lockIcon" />
            <div className="title">Create a password</div>
          </div>
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.PASSWORD}
              className="form-control"
              id="set-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.PASSWORD}
              value={password}
              handleChange={handlePasswordChange}
              onBlur={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.SIGNUP_CREATE_PASSWORD,
                  {
                    action: 'Password',
                  },
                );
              }}
            />
            <span className={passwordStrength.colorCode}>
              {passwordStrength.strength}
            </span>
          </div>
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.CONFIRM_PASSWORD}
              className="form-control"
              id="confirm-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.CONFIRM_PASSWORD}
              value={confirmPassword}
              handleChange={setConfirmPassword}
              onBlur={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.SIGNUP_CREATE_PASSWORD,
                  {
                    action: 'Confirm Password',
                  },
                );
              }}
            />
            <span
              className={`${
                password.length && confirmPassword.length
                  ? getColorCode()
                  : 'hide'
              }`}>
              {passwordMatch
                ? MESSAGE_CONSTANTS.SET_PASSWORD.MATCH
                : MESSAGE_CONSTANTS.SET_PASSWORD.DOESNT_MATCH}
            </span>
          </div>
          <div className="leftText">
            {MESSAGE_CONSTANTS.SET_PASSWORD.DESCRIPTION}
          </div>
          <Button
            className="btn btn-primary"
            disabled={disableButton}
            label={BUTTON_LABELS.COMMON.NEXT}
            onClick={() => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.SIGNUP_CREATE_PASSWORD,
                {
                  action: 'Next',
                },
              );
              storeData();
            }}
          />
        </div>
      </div>
    </>
  );
};
