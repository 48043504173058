import { getCookie } from 'Utils/storage-utilities';

export const APP_CONSTANTS = {
  HEADERS: {
    accept: 'application/json',
  },
  AUTH_HEADER_TOKEN: () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getCookie('JWT')}`,
  }),
  STORAGE_KEY_REGISTRATION: {
    LOCATION: 'user-location',
    BIRTH_DATE: 'user-birthdate',
    USER_EMAIL: 'user-email',
    USERNAME: 'user-username',
    PASSWORD: 'user-password',
    NAME: 'user-name',
    SIRNAME: 'user-sirname',
    PHONE_NUMBER: 'user-number',
    SIGNUP_STRATEGY: 'sign-up-strategy',
    SOCIAL_DATA: 'social-signup-data',
    TYPE_USERNAME: 'username',
    DROP_AFTER_SIGNUP: 'drop-after-signup',
  },
  USER_DATA_KEY: {
    USER_DATA: 'user-deatils',
    JWT: 'JWT',
    LOCATION: 'location',
  },
  SPORTS_KEY: {
    CONTEST: 'selected-contest',
    DRAFT_CONTEST: 'draft-contest',
    EDIT_LINEUP: 'active_edit',
  },
  LINEUP_KEY: {
    NAVIGATE_TO_KEY: 'navigate-to',
  },
  VALIDATION: {
    MIN_AGE: 18,
    VALID_EMAIL_REGEX: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z ]{2,4}$/i,
    VALID_USERNAME_REGEX: /^[a-zA-Z0-9_]{4,}$/i,
    // eslint-disable-next-line max-len
    STRONG_PASSWORD: /^((?=.*[a-z])|(?=.*[A-Z]))((?=.*[0-9])(?=.*[!@#$%^&*]))(?=.{8,})/i,
    // eslint-disable-next-line max-len
    AVERAGE_PASSWORD: /^((?=.*[a-z])|(?=.*[A-Z]))((?=.*[0-9])|(?=.*[!@#$%^&*]))(?=.{8,})/i,
    VALID_NAME: /^[a-zA-Z ’‘’'-]{2,}$/i,
    VALID_ADDRESS: /^[a-zA-Z0-9 ’‘’'-]{2,}$/i,
    // eslint-disable-next-line max-len
    VALID_DOB_REGEX: /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/,
    // eslint-disable-next-line max-len
    DEVICE_CHECK: /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i,
  },
  HTTP_STATUS_CODES: {
    OK: 200,
    BAD_REQUEST: 400,
    ALREADY_EXISTS: 409,
    NO_CONTENT: 204,
    CREATED: 201,
    NEW_CREATED: 201,
    NOT_FOUND: 404,
    PRECONDITION_FAILED: 412,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    PERMANENTLY_REMOVED: 410,
    TOO_MANY_REQUEST: 429,
    FOUND: 302,
  },
  API_STATUS_CODE: {
    PHONE_NOT_VERIFIED: 'user.phone.is_not_verified',
    DRAFT_USER_ALREADY_EXISTS: 'draft.user.exists',
  },
  RESPONSE_STATUS: {
    UNKNOWN: 'unknown',
  },
  PASSWORD_STRENGTH: {
    WEAK: 'inputOrangeRed',
    AVERAGE: 'inputAverageYellow',
    STRONG: 'inputGreen',
  },
  PASSWORD_MATCH: {
    MATCH: 'inputGreen',
    NOT_MATCH: 'inputOrangeRed',
  },
  TEXT_LENGTH: {
    SIX: 6,
    FOURTEEN: 14,
  },
  SOCIAL_SIGNUP_KEY: {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    USERNAME: 'username',
    EMAIL: 'email',
    GMAIL_ID: 'gmail-id',
    FACEBOOK_TOKEN: 'facebook-token',
    SIGNUP_TYPE: 'signup-type',
    GMAIL: 'gmail',
    FACEBOOK: 'facebook',
  },
  LIST_VIEW_DESIGN: {
    ONE_ICON: 'oneIcon',
    TWO_ICON: 'twoIcon',
  },
  WALLET: {
    CURRENCY_USD: 'USD',
    CURRENCY_XXX: 'XXX',
    CARD_NUMBER_MASK_CHARACTERS: '●●●●',
  },
  MODALBOX: {
    NOT_PAID_CONTEST: 'not-paid-contest',
    NO_PAID_CONTEST_ALLOWED: 'no-paid-contest-allowed',
    WALLET_BALANCE_ERROR: 'low-balance',
    SOMETHING_WRONG: 'something-wrong',
    AGE_ERROR: 'under-age-warning',
    LOCATION_ERROR: 'location-error',
    PERMISSION_ALLOW: 'Permission-allow',
    PERMISSION_DENIED: 'Permission-denied',
    LOCATION_UNAVAILABLE: 'location-unavailable',
    LOCATION_NOT_ALLOWED: 'location-not-allowed',
    TIMEOUT: 'timeout',
    UNKNOWN_ERR: 'Unknown-err',
    NOT_ALLOWED: 'not-allowed',
    KYC_ERROR: 'kyc-error',
    OLD_PASSWORD_ERR: 'Wrong-old-password',
    BAD_REQUEST: 'Bad-request',
    CANNOT_UPDATE_SAME_VAL: 'cannot-update-same-value',
    CANNOT_UPDATE_SAME_VAL_TWICE: 'cannot-update-same-value-twice',
    RESPONSIBLE_PLAY_LIMIT_ERR: 'responsible-play-err',
    TIME_UP_CONTEST: 'time-up-to-submit',
    CONFIRM_SUBMIT: 'confirm-submit',
    SAME_AS_OLD_PASSWORD: 'new password is same as old password',
    CHECK_LOGIN_TYPE: 'check-login-type',
    COMING_SOON: 'Coming Soon',
    TRANSACTION_WITHDRAWAL: 'Transaction Withdrawal',
    FILE_TOO_LARGE: 'file-too-large',
    FILE_TYPE_ERROR: 'file-type-error',
    LINK_EXPIRED: 'link-expired',
    INVALID_TOKEN: 'invalid-token',
    CROP_IMAGE: 'crop-image',
    RESET_LINEUP: 'reset-lineup',
    DELETE_LINEUP: 'delete-lineup',
    WALLET_LOCATION_ERROR: 'wallet-location-error',
    ENTRIES_CLOSED: 'entries-closed',
    CONTEST_NOT_FOUND: 'contest-not-found',
    REMOVE_PLAYER: 'remove-player',
    EDIT_LINEUP_COMFIRMATION: 'edit-lineup-confirmation',
    REFRESH_LINEUP: 'refresh-lineup',
    UPDATE_LINEUP: 'update-lineup',
  },
  MAX_USERNAME_LENGTH: 20,
  MIN_USERNAME_LENGTH: 4,
  USER_EXISTS: {
    CODE: 'user.invalid.email',
    STATUS: 400,
  },
  ERROR_CODE: {
    CODE_LOCATION_NOT_FOUND: 'user.not_found.location',
    EMAIL_NOT_VERIFIED: 'user.email.is_not_verified',
  },
  SPORT_SLUG: {
    NBA: 'nba',
    MLB: 'mlb',
    NFL: 'nfl',
    NHL: 'nhl',
  },
  MLB_OVERVIEW_STATS_MAPPING: {
    hitter: {
      preGame: [
        {
          title: 'FFPG',
          valueField: 'averageFP',
          value: '',
        },
        {
          title: 'AVG',
          valueField: 'battingAvg',
          value: '',
        },
        {
          title: 'HR',
          valueField: 'hr',
          value: '',
        },
        {
          title: 'RBI',
          valueField: 'rbi',
          value: '',
        },
        {
          title: 'OPS',
          valueField: 'ops',
          value: '',
        },
      ],
      liveOrPostGame: [
        {
          title: 'FP',
          valueField: 'totalFP',
          value: '',
        },
        {
          title: '(H-AB)',
          valueField: ['h', 'ab'],
          value: '',
        },
        {
          title: 'OPS',
          valueField: 'ops',
          value: '',
        },
      ],
    },
    pitcher: {
      preGame: [
        {
          title: 'FFPG',
          valueField: 'averageFP',
          value: '',
        },
        {
          title: 'W-L',
          valueField: ['win', 'loss'],
          value: '',
        },
        {
          title: 'ERA',
          valueField: 'era',
          value: '',
        },
        {
          title: 'K',
          valueField: 'so',
          value: '',
        },
        {
          title: 'WHIP',
          valueField: 'whip',
          value: '',
        },
      ],
      liveOrPostGame: [
        {
          title: 'FP',
          valueField: 'totalFP',
          value: '',
        },
        {
          title: 'IP',
          valueField: 'ip',
          value: '',
        },
        {
          title: 'ER',
          valueField: 'er',
          value: '',
        },
        {
          title: 'Result',
          valueField: ['win', 'loss'],
          value: '-',
        },
      ],
    },
  },
  MLB_SEASON_STATS_MAPPING: {
    hitter: [
      {
        title: 'Games',
        valueField: 'numGames',
        value: '',
      },
      {
        title: 'H',
        valueField: 'h',
        value: '',
      },
      {
        title: 'AB',
        valueField: 'ab',
        value: '',
      },
      {
        title: 'HR',
        valueField: 'hr',
        value: '',
      },
      {
        title: 'RBI',
        valueField: 'rbi',
        value: '',
      },
      {
        title: 'R',
        valueField: 'runs',
        value: '',
      },
      {
        title: 'SB',
        valueField: 'stolen',
        value: '',
      },
      {
        title: '2B',
        valueField: 'd',
        value: '',
      },
      {
        title: '3B',
        valueField: 't',
        value: '',
      },
      {
        title: 'BB',
        valueField: 'bb',
        value: '',
      },
    ],
    pitcher: [
      {
        title: 'Starts',
        valueField: 'gameStarted',
        value: '',
      },
      {
        title: 'W',
        valueField: 'win',
        value: '',
      },
      {
        title: 'L',
        valueField: 'loss',
        value: '',
      },
      {
        title: 'IP',
        valueField: 'ip',
        value: '',
      },
      {
        title: 'ER',
        valueField: 'er',
        value: '',
      },
      {
        title: 'K',
        valueField: 'so',
        value: '',
      },
      {
        title: 'H',
        valueField: 'ha',
        value: '',
      },
      {
        title: 'W',
        valueField: 'wa',
        value: '',
      },
    ],
  },
  NBA_STATS_MAPPING: [
    {
      title: 'PTS/G',
      valueField: 'points',
      rank: '',
      seasonStateField: 'avgPoints',
      rankField: 'rankByPoints',
    },
    {
      title: 'MIN/G',
      valueField: 'minutes',
      rank: '',
      seasonStateField: 'avgMinutes',
      rankField: 'rankByMinutes',
    },
    {
      title: 'REBS/G',
      valueField: 'rebounds',
      rank: '',
      seasonStateField: 'avgRebounds',
      rankField: 'rankByRebounds',
    },
    {
      title: 'BLK/G',
      valueField: 'blocks',
      rank: '',
      seasonStateField: 'avgBlocks',
      rankField: 'rankByBlocks',
    },
    {
      title: 'STL/G',
      valueField: 'steals',
      rank: '',
      seasonStateField: 'avgSteals',
      rankField: 'rankBySteals',
    },
    {
      title: 'AST/G',
      valueField: 'assists',
      rank: '',
      seasonStateField: 'avgAssists',
      rankField: 'rankByAssists',
    },
  ],
  GAME_RULES: [
    {
      title: 'NFL SCORING',
      link: '/game-rules/scoring/nfl',
      slug: 'nfl',
    },
    {
      title: 'NBA SCORING',
      link: '/game-rules/scoring/nba',
      slug: 'nba',
    },
    {
      title: 'MLB SCORING',
      link: '/game-rules/scoring/mlb',
      slug: 'mlb',
    },
    {
      title: 'NHL SCORING',
      link: '/game-rules/scoring/nhl',
      slug: 'nhl',
    },
  ],
  PLAYER_DETAIL_TABS: [
    {
      tab: 'THIS GAME',
      showTab: false,
    },
    {
      tab: 'THIS SEASON',
      showTab: false,
    },
  ],

  TABS: {
    CURRENT_GAME: 'THIS GAME',
    THIS_SEASON: 'THIS SEASON',
    PREV_SEASON: '2020 SEASON',
  },
  SCORING_GAME: {
    NFL: 'nfl',
    NBA: 'nba',
    MLB: 'mlb',
    NHL: 'nhl',
  },
  PARAGRAPH_CONSTANTS: {
    SELF_EXCLUSION:
      // eslint-disable-next-line max-len
      'If you wish to disable your account for any reason, you may apply a self-exclusion to your account. Tell us how much time you need, and we will make sure your account no longer allows you to log in to the Daily Number or join any contest during that period',
  },
  SCORING_GAME_HEADER_TITLE: {
    NFL_SCORING: 'NFL SCORING',
    NBA_SCORING: 'NBA SCORING',
    MLB_SCORING: 'MLB SCORING',
    NHL_SCORING: 'NHL SCORING',
  },
  GAME_STATUS: {
    IN_PROGRESS: 'InProgress',
    HALF_NBA: 'HALF',
    HALF_NFL: 'HalfTime',
    HALF_DISPLAY: 'HALF',
  },
  MINIMUM_CASHOUT_BALANCE: 10,
  MINIMUM_XP_BALANCE: 0,
  NO_OF_ARRIVAL_DAYS: 4,
  TRANSACTION_TITLE_MAPPINGS: {
    Deposit: 'Add Funds',
    'Prize Awarded': 'You beat the Daily Number!',
    'Entry Fee': 'Contest Entry Fee',
    'Withdrawal by Refund': 'Cash Out',
  },
  PRIZE_AWARDED: 'Prize Awarded',
  BIRTHDATE_FORMAT: 'yyyy-mm-dd',
  CONTEST_FILER: {
    LIVE: 'live',
    UPCOMING: 'upcoming',
    RECENT: 'recent',
  },
  IMAGE_MAX_SIZE: '3145728',
  USER_PROFILE_TABS: {
    LINEUPS: 'LINEUPS',
    ACTIVITY: 'ACTIVITY',
  },
  LINEUPS_TABS: {
    ACTIVE: 'live',
    DRAFT: 'draft',
    UPCOMING: 'upcoming',
    COMPLETED: 'recent',
  },
  MAP_TABS: {
    live: 'live',
    draft: 'draft',
    upcoming: 'upcoming',
    completed: 'recent',
  },
  TRANSACTION_TYPE: {
    'Cash Out': 'Withdrawal by Refund',
    'You beat the Daily Number!': 'Prize Awarded',
    'Contest Entry Fee': 'Entry Fee',
    'Add Funds': 'Deposit',
    'Refund Entry Fee': 'Refund Entry Fee',
  },
  GET_NOTIFICATION_COUNT: 1,
  EDIT_LINEUP: 'Edit Lineup',
  FINISH_DRAFT: 'Finish Draft',
  CASH_OUT: 'Cash Out',
  PASSWORD_RESET_SUCCESS:
    // eslint-disable-next-line max-len
    'Your password was reset successfully, please sign in with your new credentials.',
};
