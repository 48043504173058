import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const changeEmailAction = async emailAddress => {
  const url = API.USER_DETAIL;
  const data = {
    emailAddress,
  };
  const response = await NetworkManager.requestAuthPUT(url, data);
  return response;
};
