export const videoTutorialArray = [
  {
    id: 35,
    videoId: 'https://www.youtube.com/embed/eCXWWQbg-ck?autoplay=1',
    thumbnail: 'https://i.ytimg.com/vi/YxIAZ26-QXA/hqdefault.jpg',
    text: 'Daily Number tutorial ft. Kay Jay',
  },

  {
    id: 42,
    videoId: 'https://www.youtube.com/embed/YxIAZ26-QXA?autoplay=1',
    thumbnail: 'https://i.ytimg.com/vi/YxIAZ26-QXA/hqdefault.jpg',
    text: 'Daily Number tutorial ft. Kay Jay',
  },

  {
    id: 71,
    videoId: 'https://www.youtube.com/embed/OAPk2ihrQT4?autoplay=1',
    thumbnail: 'https://i.ytimg.com/vi/OAPk2ihrQT4/hqdefault.jpg',
    text: 'Daily Number tutorial ft. Kay Jay',
  },
];
