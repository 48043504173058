/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import {
  getPlayerDetails,
  getPlayerSeasonStats,
} from 'Actions/player-details-action';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Button } from 'Components/Form-Elements/Button';
import { CustomContentProgressbar } from 'Components/ProgressCircleBar';
import { StarRating } from 'Components/StarRating';
import {
  playersmith,
  playermarteLive,
  playermarteCompleted,
} from 'Components/mocklivedata';
import {
  getSportProgress,
  getEventDetails,
} from 'Components/Profile/ProfileScreen/lineup-helper';
import { COLORS } from 'Components/LineUpScreens/SportData/sportsObject';
import {
  formatDateMMDD,
  formatDateHHmm,
  scheduledDate,
  scheduledTime,
} from 'Utils/date-utils';
import { getPositionShortCode } from 'Utils/data-utils';
import { HighChart } from 'Components/HighChart';
import { TimerComponent } from 'Components/LineUpScreens/Lineup/TimerComponent';
import { detectDevice } from 'Utils/device-utils';
import { DesktopDesignPlayerDetails } from './newDesignPlayerDetails';
import { GameCardDetails } from './GameCard';
import { PlayerOverviewStats } from './PlayerOverviewStats';
import {
  renderNews,
  stateDataLive,
  renderCurrentGamePlayerEvents,
  renderStatDataForTabs,
  renderThisGameStatsData,
  renderSeasonStateData,
  seasonStatPerGame,
} from './helper-player-details';
import { getLineChartDetails } from './getChartObject';

const VALID_EVENTS = [
  'threepointmade',
  'twopointmade',
  'freethrowmade',
  'turnover',
  'rebound',
  'assit',
  'steal',
  'block',
  'dd',
  'td',
];

const EVENT_DESC = {
  dd: { attr: 'dd', desc: 'Double-Double Bonus', points: '+1.50' },
  td: { attr: 'td', desc: 'Triple-Double Bonus', points: '+1.50' },
  'passing.tyg': {
    attr: 'passing.tyg',
    desc: '300+ YD Passing Bonus',
    points: '+3.00',
  },
  'rushing.oyg': {
    attr: 'rushing.oyg',
    desc: '100+ YD Rushing Bonus',
    points: '+3.00',
  },
  'receiving.oyg': {
    attr: 'receiving.oyg',
    desc: '100+ YD Receiving Bonus',
    points: '+3.00',
  },
  fivePlusShot: {
    attr: 'fivePlusShot',
    desc: '5+ shots on net bonus',
    points: '+3.00',
  },
  hatTrick: {
    attr: 'hatTrick',
    desc: 'Hat trick bonus',
    points: '+3.00',
  },
  threePlusPts: {
    attr: 'threePlusPts',
    desc: '3+ points bonus',
    points: '+3.00',
  },
  threePlusShot: {
    attr: 'threePlusShot',
    desc: '3+ blocked shots bonus',
    points: '+3.00',
  },
  thirtyFiveSaves: {
    attr: 'thirtyFiveSaves',
    desc: '35+ saves bonus',
    points: '+3.00',
  },
};

const POLL_INTERVAL = 15000;
let myVar;
export const PlayerDetails = ({
  player,
  getToList,
  addPlayer,
  alreadySelectedPlayer,
  alreadySelectedPlayerGameData,
  removePlayer,
  showAlertPlayer,
  className,
  newDesign,
  removeSelectGaurdClass,
}) => {
  const [playerDetails, setPlayerDetails] = useState({});
  const [playerNews, setPlayerNews] = useState([]);
  const [graphPlotData, setGraphData] = useState('');
  const [seasonData, setSeasonData] = useState({});
  const [seasonStatValue, setSeasonStatValue] = useState(null);
  const [gameStatsValue, setGameStatsValue] = useState(null);
  const showTabs = useRef(APP_CONSTANTS.PLAYER_DETAIL_TABS);
  const [showTabsOnUI, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(APP_CONSTANTS.TABS.THIS_SEASON);
  const onlyOnce = useRef(false);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [startPolling, setStartPolling] = useState(false);
  const [events, setEvents] = useState([]);

  const toggleTabsStatus = tabName => {
    showTabs.current = showTabs.current.map(tabItem => {
      if (tabName === tabItem.tab) {
        return { ...tabItem, showTab: true };
      }
      return tabItem;
    });
    setTabs(showTabs.current);
  };

  const toggleStatForSingleSeasonGame = ({ stats }) => {
    setSeasonStatValue(seasonStatPerGame(stats));
  };

  const getSeasonStatData = (playerId, isPregame) => {
    getPlayerSeasonStats(playerId).then(({ data }) => {
      setSeasonData(data);
      setSeasonStatValue(renderSeasonStateData(data));
      if (data && data.playerGames) {
        const playerTeam =
          (data.teams && data.teams[0] && data.teams[0].shortName) || '';
        const graphData = data.playerGames.reverse().map(pg => ({
          y: pg.stats.totalFP,
          firstText: `${scheduledDate(pg.gameStartDate)} ${
            playerTeam
              ? playerTeam === pg.awayTeamShortName
                ? `@ ${pg.homeTeamShortName}`
                : `vs ${pg.awayTeamShortName}`
              : ' '
          }`,
          secondText: `FPs: ${pg.stats.totalFP}`,
          stats: pg.stats,
        }));
        setGraphData(
          getLineChartDetails(
            graphData,
            (data.players &&
              data.players[0] &&
              data.players[0].allPlayersAvgsByPos &&
              (data.players[0].allPlayersAvgsByPos.avgPoints ||
                data.players[0].allPlayersAvgsByPos.avgAllFP)) ||
              0,
            (data.players &&
              data.players[0] &&
              data.players[0].dailyNumberAvg) ||
              0,
            toggleStatForSingleSeasonGame,
          ),
        );
      }
      toggleTabsStatus(APP_CONSTANTS.TABS.THIS_SEASON);
      const THIS_GAME =
        !isPregame && ['nba', 'nfl', 'nhl'].includes(data.players[0].sportSlug);
      toggleTabsStatus(
        THIS_GAME
          ? APP_CONSTANTS.TABS.CURRENT_GAME
          : APP_CONSTANTS.TABS.THIS_SEASON,
      );
    });
  };

  const fetchPlayerDetails = () => {
    getPlayerDetails(player.playerGame._id).then(({ data }) => {
      if (data.players && data.players.length) {
        setPlayerDetails(data);
        setPlayerNews(data.news || []);
        const gameStatus = data.games[0].status;
        // live Tab Data
        if (
          data.playerGames &&
          data.playerGames.length &&
          data.games &&
          data.games.length &&
          data.playerGames.some(item => item.gameId._str === data.games[0]._id)
        ) {
          const liveGame = data.playerGames.filter(
            playGame => playGame.gameId._str === data.games[0]._id,
          );
          if (
            liveGame.length &&
            data.games[0].status === 'InProgress' &&
            !data.games[0].finished
          ) {
            if (!startPolling) {
              setStartPolling(true);
            }
          } else if (data.games[0].status === 'Scheduled') {
            const curDate = new Date();
            const gameDate = new Date(data.games[0].scheduledAt);
            const differenceInTime = gameDate.getTime() - curDate.getTime();
            const timeBefore = 5000; // mil secs
            const timerStartTime =
              differenceInTime <= timeBefore
                ? 0
                : differenceInTime - timeBefore;
            if (differenceInTime <= timeBefore || timerStartTime > 0) {
              myVar = setTimeout(() => {
                if (!startPolling) {
                  setStartPolling(true);
                }
              }, timerStartTime);
            }
          }
          if (
            data.games[0].status === 'Closed' ||
            data.games[0].status === 'Finished'
          ) {
            if (startPolling) {
              setStartPolling(false);
            }
          }
          if (liveGame.length && liveGame[0].events) {
            if (data.games[0].sportSlug !== 'mlb') {
              toggleTabsStatus(APP_CONSTANTS.TABS.CURRENT_GAME);
              setActiveTab(APP_CONSTANTS.TABS.CURRENT_GAME);
            }
            if (data.games[0].sportSlug === 'nba') {
              setEvents(
                liveGame[0].events
                  .filter(event => VALID_EVENTS.indexOf(event.statType) !== -1)
                  .reverse(),
              );
            } else if (data.games[0].sportSlug === 'nfl') {
              setEvents(liveGame[0].events.reverse());
            } else if (data.games[0].sportSlug === 'nhl') {
              setEvents(liveGame[0].events.reverse());
            }
          }
        }

        // seasonStatData
        if (!onlyOnce.current) {
          getSeasonStatData(
            data.players[0].apiId,
            ['Scheduled', 'Created'].includes(gameStatus),
          );
          onlyOnce.current = true;
        }
      } else {
        alert('User details not found');
      }
    });
  };

  useEffect(() => {
    let timer;
    if (startPolling) {
      timer = setInterval(() => {
        fetchPlayerDetails();
      }, POLL_INTERVAL);
    }

    return () => {
      clearInterval(timer);
      clearInterval(myVar);
    };
  }, [startPolling]);

  useEffect(() => {
    fetchPlayerDetails();
  }, []);

  let { games, players, playerGames } = playerDetails;
  const { teams } = playerDetails;
  players = (players && players[0]) || {};
  const playerTeam = (teams && teams[0]) || {};
  games = (games && games[0]) || {};
  let { seasonStats } = players;
  const { recentStats } = players;
  seasonStats = seasonStats || {};
  playerGames = playerGames || [];
  let latestPlayerGame = {};
  if (playerGames.length) {
    const playerGamesFiltered = playerGames.filter(
      playGame => playGame.gameId._str === games._id,
    );
    if (!playerGamesFiltered.length) {
      playerGames = playerGames.sort((game1, game2) => {
        return new Date(game2.date) - new Date(game1.date);
      });
      latestPlayerGame = playerGames[0];
    } else {
      latestPlayerGame = playerGamesFiltered[0];
    }
  }
  const liveStats = (latestPlayerGame && latestPlayerGame.stats) || '';
  const homeTeam =
    (latestPlayerGame &&
      teams &&
      teams.find(team => {
        return team.shortName === games.homeTeamShortName;
      })) ||
    {};

  const timeObj = (games && getSportProgress(games, games.sportSlug)) || '';
  const progress = timeObj ? timeObj.progress : 0;
  const last = games.status === 'Complete' || games.status === 'Closed';
  const live = games.status === 'InProgress' && !games.finished;
  const next = games.status === 'Scheduled';
  const halftime = games.status === 'Halftime';

  const awayTeam =
    (latestPlayerGame &&
      teams &&
      teams.find(team => {
        return team.shortName === games.awayTeamShortName;
      })) ||
    {};

  const statsData = stateDataLive(players, liveStats);

  const getColor = () => {
    if (last) {
      return COLORS.brownGrey;
    }
    if (live) {
      const currentOutcome = games && games.currentOutcome;
      let isPlayerActive = false;
      if (games.sportSlug === 'nfl' || games.sportSlug === 'nhl') {
        return COLORS.green;
      }

      if (currentOutcome) {
        isPlayerActive =
          (currentOutcome.hitter &&
            currentOutcome.hitter.apiId === players.apiId) ||
          (currentOutcome.pitcher &&
            currentOutcome.pitcher.apiId === players.apiId) ||
          (currentOutcome.runners &&
            currentOutcome.runners.some(r => r.runnerApiId === players.apiId));
      }
      if (latestPlayerGame && (latestPlayerGame.onCourt || isPlayerActive)) {
        return COLORS.green;
      }

      return COLORS.appBlue;
    }
    return '';
  };

  const renderTabs = () => {
    return showTabsOnUI
      .filter(({ showTab }) => showTab)
      .map(tabs => {
        return (
          <li key={tabs.tab}>
            <span
              className={`nextGameTitle ${tabs.tab === activeTab && 'active'}`}
              onClick={() => {
                setSeasonStatValue(
                  renderSeasonStateData(
                    seasonData,
                    activeTab === APP_CONSTANTS.TABS.THIS_SEASON &&
                      tabs.tab !== APP_CONSTANTS.TABS.THIS_SEASON,
                  ),
                );
                setActiveTab(tabs.tab);
              }}
              href="#">
              {tabs.tab}
            </span>
          </li>
        );
      });
  };

  const renderTabContents = liveStats => {
    if (APP_CONSTANTS.TABS.THIS_SEASON === activeTab) {
      return (
        (graphPlotData && graphPlotData.graphObj && (
          <>
            <HighChart chartDetails={graphPlotData.graphObj} />
            <div className="d-flex justify-content-around graphText">
              <div className="leftText">
                {`League Avg: ${graphPlotData.avgPts.toFixed(2)} PTS`}
              </div>
              <div className="rightText">
                {`Avg Daily Number: ${graphPlotData.avgDailyNumber.toFixed(2)}`}
              </div>
            </div>
          </>
        )) ||
        null
      );
    }
    if (APP_CONSTANTS.TABS.CURRENT_GAME === activeTab) {
      // console.log(2);
      return renderCurrentGamePlayerEvents(
        events,
        showAllEvents,
        EVENT_DESC,
        getEventDetails,
        setShowAllEvents,
        liveStats,
      );
    }
    console.log(3);
    return null;
  };

  if (newDesign) {
    return (
      <DesktopDesignPlayerDetails
        player={player}
        seasonStats={seasonStats}
        playerTeam={playerTeam}
        players={players}
        recentStats={recentStats}
        awayTeam={awayTeam}
        games={games}
        addPlayer={addPlayer}
        homeTeam={homeTeam}
        removePlayer={removePlayer}
        statsData={statsData}
        playerNews={playerNews}
        alreadySelectedPlayer={alreadySelectedPlayer}
        alreadySelectedPlayerGameData={alreadySelectedPlayerGameData}
        showAlertPlayer={showAlertPlayer}
        renderTabs={renderTabs}
        activeTab={activeTab}
        seasonStatValue={seasonStatValue}
        renderTabContents={renderTabContents}
      />
    );
  }

  const [showBorderBottom, setShowBorderBottom] = useState(false);
  const [showProfileNameTop, setShowProfileNameTop] = useState(false);
  const handleScroll = e => {
    const element = e.target;
    // console.log(element);
    if (element.scrollTop > 10) {
      setShowBorderBottom(true);
    } else {
      setShowBorderBottom(false);
    }

    const profileName = document.querySelector('.playerName');
    const boundingProfileName = profileName.getBoundingClientRect();
    // console.log(boundingProfileName);
    if (
      boundingProfileName.top + boundingProfileName.height - 44 >= 0 &&
      boundingProfileName.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    ) {
      setShowProfileNameTop(false);
      // console.log(`top: ${boundingProfileName.top} - In the viewport!`);
    } else {
      setShowProfileNameTop(true);
      // console.log(
      //   `top: ${boundingProfileName.top} - Not in the viewport... whomp whomp!`,
      // );
    }
  };

  return (
    <>
      {/* <div
        className={`${
          removeSelectGaurdClass ? '' : 'selectGuard'
        } ${className}`}> */}
      <main className="App__main">
        {!className && (
          <div className="drawerClose">
            <span />
          </div>
        )}
        <div className="selectGuard profileFullPage">
          <div className="playerDetailWrapper">
            <div
              className={`guardTitle ${
                showBorderBottom ? 'u-borderBottom' : ''
              }`}>
              <Button className="backGuard" onClick={() => getToList()} />
              {removeSelectGaurdClass && (
                <>
                  <h2
                    className={`${
                      showProfileNameTop ? '' : 'u-visibilityHidden'
                    }`}>
                    {player.name}
                  </h2>
                  <TimerComponent className="rightSide" />
                </>
              )}
            </div>

            <div className="playerDetailsInner" onScroll={handleScroll}>
              <div className="playerDetails">
                {live || last || halftime ? (
                  <>
                    <div className="playerAvatarWrapper">
                      <div className={`playerAvatar ${getColor()}`}>
                        <span
                          className="playerAvatar__image"
                          style={{
                            backgroundImage: `url(${player.picture})`,
                          }}
                        />

                        <span className="playerAvatar__circleProgressWrapper">
                          <svg
                            className="svgCircleProgress"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 33.48 33.48">
                            <circle
                              className="svgCircleProgress__line"
                              cx="16.74"
                              cy="16.74"
                              r="15.93"
                            />
                            {progress ? (
                              <circle
                                className="svgCircleProgress__bar"
                                cx="16.74"
                                cy="16.74"
                                r="15.93"
                                strokeDasharray={`${progress * 100} 100`}
                                strokeDashoffset={progress * 100}
                              />
                            ) : (
                              ''
                            )}
                          </svg>
                        </span>
                        {/* <span
                        className={`playerAvatar__weather ${weatherIconClass}`}
                      />
                      <span className="playerAvatar__statusTopRight isHide">
                        2
                      </span>
                      {injuryStatus && (
                        <span className="playerAvatar__statusBottomLeft">
                          {injuryStatus[0]}
                        </span>
                      )} */}
                      </div>
                    </div>
                  </>
                ) : (
                  // <div className="playerProfileimage NoProgressBar">
                  //   <img
                  //     src={player.picture}
                  //     className="img-responsive"
                  //     alt=""
                  //   />
                  // </div>
                  <div className="playerAvatarWrapper">
                    <div className="playerAvatar">
                      <span
                        className="playerAvatar__image"
                        style={{
                          backgroundImage: `url(${player.picture})`,
                        }}
                      />
                      {/* <span className="playerAvatar__circleProgressWrapper">
                        <svg
                          className="svgCircleProgress"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 33.48 33.48">
                          <circle
                            className="svgCircleProgress__line"
                            cx="16.74"
                            cy="16.74"
                            r="15.93"
                          />
                        </svg>
                      </span> */}
                    </div>
                  </div>
                )}
                <div className="playerName text-center">{player.name}</div>
                <div className="aboutPlayer text-center">
                  {getPositionShortCode(player.playerGame.position)} -{' '}
                  {playerTeam.fullName}
                </div>
                <div className="ratingContain">
                  <StarRating
                    rating={player.strength}
                    starRatedColor="rgb(251, 214, 3)"
                    starEmptyColor="rgba(0, 0, 0, 0)"
                    starDimension="14px"
                    starSpacing="0.5px"
                  />
                </div>
                <PlayerOverviewStats
                  player={player}
                  liveStats={liveStats}
                  seasonStats={seasonStats}
                  players={players}
                  recentStats={recentStats}
                  games={games}
                />
                <GameCardDetails
                  player={player}
                  awayTeam={awayTeam}
                  games={games}
                  homeTeam={homeTeam}
                />
              </div>
              <div className="playerRecords playerRecordsTabs">
                <ul className=" d-flex justify-content-start">
                  {renderTabs()}
                </ul>
                <ul className="d-flex mt-1 playerStats">
                  {renderStatDataForTabs(
                    activeTab,
                    statsData,
                    seasonStatValue,
                    games.sportSlug,
                  )}
                </ul>
              </div>
              <div className="playerGraph text-center">
                {renderTabContents(liveStats)}
              </div>
              <div className="playerVideoHighlights hide">
                <div className="container">
                  <div className="playerVideoTitle">Video Highlights</div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="col-6 text-center">
                      <span href="#">
                        <i className="playIcon" />
                        <img className="img-responsive" alt="" />
                      </span>
                    </div>
                    <div className="col-6 text-center">
                      <span href="#">
                        <i className="playIcon" />
                        <img className="img-responsive" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {games.sportSlug !== 'nfl' ? (
                <div className="playerVideoHighlights">
                  <div className="container">
                    <div className="playerVideoTitle">NEWS & ANALYSIS</div>
                  </div>
                  <div className="container">
                    {playerNews.length ? (
                      renderNews(playerNews)
                    ) : (
                      <div className="emptyState">
                        <div className="emptyState__content">
                          <p>No news</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <div
              className={`guardTitle guardBottomFixed ${
                alreadySelectedPlayerGameData &&
                !alreadySelectedPlayerGameData.lateScratchEnabled &&
                alreadySelectedPlayerGameData.activeTab
                  ? 'u-notClickable'
                  : ''
              } ${className && 'profileFullPageBtn'}`}>
              {alreadySelectedPlayer === player.apiId && removePlayer && (
                <button
                  className="btnBlue btnRemovePlayer"
                  type="button"
                  onClick={() => {
                    showAlertPlayer(player, 'remove');
                    getToList();
                  }}>
                  <span className="plusIconWhite" />
                  Remove Player
                </button>
              )}
              {alreadySelectedPlayer === '' && addPlayer && (
                <button
                  className="btnBlue"
                  type="button"
                  onClick={() => {
                    addPlayer(player);
                    getToList();
                  }}>
                  <span className="plusIconWhite" />
                  Add Player
                </button>
              )}
              {alreadySelectedPlayer &&
                alreadySelectedPlayer !== player.apiId &&
                addPlayer && (
                  <button
                    className="btnBlue btn-success"
                    type="button"
                    onClick={() => {
                      addPlayer(player);
                      getToList();
                    }}>
                    <span className="swapIconWhite" />
                    Swap Player
                  </button>
                )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
