/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
} from 'Actions/new-auth-action';
import { Alert } from '../Alert';
import icon from '../../assets/icons/icons.svg';

export const EmailSignupSent = ({ history }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [responseError, setResponseError] = useState('');
  const [alertTemplate, setAlertTemplate] = useState('red');

  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;
  const isMobile = detectDevice();
  const { email } = history.location.state ? history.location.state : '';

  const back = () => {
    const path = `/m-signup-email`;
    history.push(path, { email });
  };

  const openEmailApp = () => {
    const path = `/m-signup-phone`;
    history.push(path);
  };

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const customerId = query.get('customerid');
  const externalToken = query.get('token');

  const requestEmail = response => {
    setForbiddenErr('');
    setError('');
    requestEmailVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
      })
      .catch(error => {
        const { status, message, code } = error.response.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const handleSubmit = () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        email,
      },
    });
    // evt.preventDefault();
    if (!email) {
      return;
    }
    const data = {
      email,
    };
    requestEmail(data);
  };
  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button
                  className="button button-back"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
            <use xlinkHref={`${icon}#i-email`} />
          </svg>
          <h1 className="title">Check your email</h1>
          <p className="copy">
            We sent an email to <strong>{email}</strong> with a link you can use
            to continue creating your account.
          </p>
          {/* <button
            className="button button-full button-blue"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL, {
                action: 'Open Mail',
              });
              openEmailApp();
            }}
            disabled>
            Open mail app
          </button> */}
        </div>
        <div className="ffScreen__footer">
          <button
            className="button button-full"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.EMAIL_ENTER, {
                action: 'resend mail',
              });
              handleSubmit();
            }}>
            Resend email
          </button>
        </div>
      </div>
    </>
  );
};
