import React from 'react';

export const FileInput = ({ id, accept, handleChange, label, labelClass }) => {
  const handleFileChange = event => {
    handleChange(event);
  };
  return (
    <div>
      <label htmlFor={id} className={labelClass}>
        {label}
        <input
          type="file"
          accept={accept}
          id={id}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
};
FileInput.defaultProps = {
  handleChange: () => {},
  id: '',
  accept: 'audio/*,video/*,image/*',
  label: 'Select File',
  labelClass: '',
};
