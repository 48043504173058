import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Button } from 'Components/Form-Elements/Button';
import { Input } from 'Components/Form-Elements/Input';
import { Image } from 'Components/Form-Elements/Image';
import 'react-image-crop/dist/ReactCrop.css';
import { getUserDetail, updateUserProfile } from 'Actions/user-profile-action';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { getCookie, setCookie } from 'Utils/storage-utilities';
import { chekUserExists } from 'Actions/user-exists-action';
import { Loader } from 'Components/Loader';
import { OptionModal } from 'Components/Profile/OptionModal';
import { ModalBox } from 'Components/ModalBox';
import { ProfileContext } from 'Components/Profile/Context';
import img from '../../../assets/images/defaultImg.png';

export const EditProfileScreen = ({ history }) => {
  const [userName, setUserName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [webcamAvailable, setwebcamAvailable] = useState(false);
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const [asyncValidation, setValidity] = useState('');
  const [optionModal, showOptionModal] = useState(false);
  const [valid, setIsValid] = useState(false);

  const { profileState, profileContextDispatch } = useContext(ProfileContext);

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  useEffect(() => {
    setUserName(userdata.username);
    mixpanelTrackPageView('Edit profile Screen');
  }, []);

  // eslint-disable-next-line consistent-return
  const detectWebcam = callback => {
    const md = navigator.mediaDevices;
    if (!md || !md.enumerateDevices) {
      return callback(false);
    }
    md.enumerateDevices().then(devices => {
      callback(devices.some(devices => devices.kind === 'videoinput'));
    });
  };

  useEffect(() => {
    detectWebcam(function(hasWebcam) {
      if (hasWebcam) {
        setwebcamAvailable(true);
      }
    });
  }, []);

  const updateUserProfileData = () => {
    setIsLoading(true);
    updateUserProfile(userName)
      .then(({ data }) => {
        getUserDetail()
          .then(({ data }) => {
            setIsLoading(false);
            const userProfileData = data.data;
            delete userProfileData.services;
            setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, userProfileData);
            history.push({ pathname: '/profile' });
          })
          .catch(({ response }) => {
            setIsLoading(false);
            setModalBoxName(APP_CONSTANTS.MODALBOX.SOMETHING_WRONG);
            setToggleModalBox(true);
          });
      })
      .catch(({ response }) => {
        setIsLoading(false);
        setModalBoxName(APP_CONSTANTS.MODALBOX.SOMETHING_WRONG);
        setToggleModalBox(true);
      });
  };

  const isUsernameUnique = () => {
    setIsLoading(true);
    chekUserExists(`username=${userName}`)
      .then(response => {
        setIsLoading(false);
        if (response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          setValidity('');
          updateUserProfileData();
        }
      })
      .catch(({ response }) => {
        setIsLoading(false);
        if (
          response.data.status ===
          APP_CONSTANTS.HTTP_STATUS_CODES.ALREADY_EXISTS
        ) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.USERNAME_EXISTS);
        } else if (
          response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
        ) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_REQUEST);
        }
      });
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.SOMETHING_WRONG) {
      modalbox = MESSAGE_CONSTANTS.ERROR_MSG.SOMETHING_WRONG;
    }
    return modalbox;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const renderForm = () => {
    return (
      <div className="wrapper">
        <div className="form-group">
          <Input
            inputType="text"
            placeholder={MESSAGE_CONSTANTS.VERIFY_USERNAME.USERNAME}
            className="form-control"
            id="username"
            value={userName}
            textLabel="Username"
            handleChange={setUserName}
            validationRules={['validateUsername', 'maxUserNameLength']}
            externalError={asyncValidation}
            getValidity={isValid => setIsValid(isValid)}
          />
          <Button
            type="button"
            onClick={() => setUserName('')}
            className="inputClear"
          />
        </div>
        <div className="form-group">
          <Input
            inputType="text"
            className="form-control"
            id="firstName"
            value={userdata.firstName}
            textLabel="First Name"
            readOnly
          />
        </div>
        <div className="form-group">
          <Input
            inputType="text"
            className="form-control"
            id="lastName"
            value={userdata.lastName}
            textLabel="Last Name"
            readOnly
          />
        </div>
        <div className="form-group">
          <Input
            inputType="date"
            maxLength="10"
            className="form-control"
            id="dob"
            value={dateFormat(
              userdata.birthdate.substring(0, 10),
              APP_CONSTANTS.BIRTHDATE_FORMAT,
              true,
            )}
            textLabel="Date of Birth"
            readOnly
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="Ok"
          showBothButtons={false}
        />
      )}
      {isLoading && <Loader />}
      {optionModal && (
        <OptionModal
          close={() => showOptionModal(false)}
          setIsLoading={setIsLoading}
          webcamAvailable={webcamAvailable}
          profileContextDispatch={profileContextDispatch}
        />
      )}
      <div className="App__main">
        <div className="d-flex topBar resetTopBar topHeaderBar">
          <Link
            to="/profile"
            className="link"
            onClick={() => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.TAP_EDIT_PROFILE_ALL_INTERACTIONS,
                {
                  action: 'Cancel',
                },
              );
            }}>
            Cancel
          </Link>
          <div className="title">Edit Profile</div>
          <Button
            className="link linkBtn"
            label="Save"
            onClick={isUsernameUnique}
            disabled={!valid || userdata.username === userName.toLowerCase()}
          />
        </div>
        <div className="profileWrapper editProfileWrapper">
          <div className="d-flex userInfo editProfile">
            <Image
              className="profileImage"
              src={profileState.profileImage}
              alt="avatar"
              defaultImage={img}
            />
            <Button
              label="Edit Photo"
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.TAP_EDIT_PROFILE_ALL_INTERACTIONS,
                  {
                    action: 'Edit Photo',
                  },
                );
                showOptionModal(true);
              }}
              className="link linkBtn"
            />
          </div>
        </div>
        {renderForm()}
      </div>
    </>
  );
};
