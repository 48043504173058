/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/button-has-type */
import React, {
  useState,
  useEffect,
  useContext,
  setState,
  useReducer,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import NumberFormat from 'react-number-format';
import { sendOTP, getOTPVerified, verifyPhone } from 'Actions/otp-actions';
import { normalize } from 'react-phone-input-auto-format';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
  getDraftUser,
  authenticatePhoneSignupVerification,
} from 'Actions/new-auth-action';
import { signUpManual } from 'Actions/sign-up-action';
import { Alert } from '../Alert';
import { initialState, reducer } from './reducer';
import icon from '../../assets/icons/icons.svg';

export const SignupPhoneVerification = ({ history }) => {
  const isMobile = detectDevice();
  const [otp, setOtp] = useState('');
  const [method, setMethod] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [errorDisabled, setErrorDisabled] = useState(true);
  const [successDisabled, setSuccessDisabled] = useState(true);
  const [verificationCode, setVerificationCode] = useState('');
  const [number, setNumber] = useState('');
  const [responseError, setResponseError] = useState('');
  const [dobError, setDobError] = useState(false);
  const [dobErrorMsg, setDobErrorMsg] = useState('');
  const [userObj, setUserObj] = useState({});
  const [userDob, setUserDob] = useState('');
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [alertTemplate, setAlertTemplate] = useState('red');

  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;
  const [reducerState, dispatch] = useReducer(reducer, initialState());
  const {
    err,
    fnErr,
    lnErr,
    usrErr,
    emailAddress,
    firstName,
    lastName,
    disableNext,
    loginStrategy,
  } = reducerState;

  const {
    SOCIAL_SIGNUP_KEY: {
      EMAIL,
      FIRST_NAME,
      LAST_NAME,
      SIGNUP_TYPE,
      USERNAME,
      GMAIL_ID,
      FACEBOOK_TOKEN,
      GMAIL,
      FACEBOOK,
    },
    HTTP_STATUS_CODES: {
      OK,
      BAD_REQUEST,
      ALREADY_EXISTS,
      NO_CONTENT,
      CREATED,
      TOO_MANY_REQUEST,
    },
  } = APP_CONSTANTS;
  const { phoneNo, email, dob } = history.location.state
    ? history.location.state
    : '';

  function formateDob(dob) {
    const tempDob = dob.split('/');
    const dobMonth = tempDob[0];
    const dobDay = tempDob[1];
    const dobYear = tempDob[2];
    // const bdate = new Date(dobYear, dobMonth - 1, dobDay);
    const bdate = `${dobYear}-${dobMonth}-${dobDay}`;
    return bdate;
  }

  function getAge(dateString) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      // eslint-disable-next-line no-plusplus
      age--;
    }
    return age;
  }

  useEffect(() => {
    if (dob) {
      const formatedDob = formateDob(dob);
      const age = getAge(formatedDob);
      if (age >= 18) {
        setDobError(false);
        setUserDob(formatedDob);
      } else {
        setDobError(true);
        setUserDob(formatedDob);
        setDobErrorMsg('Not old enough, Age Must be 18 or over!');
      }
    }
  }, []);

  const getDraftUserData = response => {
    getDraftUser(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK && data) {
          setUserObj(data);
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
      });
  };

  useEffect(() => {
    const data = {
      email,
    };
    getDraftUserData(data);
  }, []);

  useEffect(() => {
    // setTimeout(setBtnDisabled(true), 5000);
    // setBtnDisabled(false);
    setTimeout(() => {
      setBtnDisabled(false);
    }, 5000);
  }, []);

  const back = () => {
    const path = `/m-signup-phone`;
    history.push(path, { phoneNo, email });
  };

  const requestPhone = response => {
    setForbiddenErr('');
    setError('');
    requestPhoneVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        /* console.log('code', code);
        console.log('message', message);
        console.log('data', data);
        console.log('status', status); */
      })
      .catch(error => {
        const { status, message, code } = error.response.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const handleSubmit = async () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        dob,
        email,
      },
    });
    // evt.preventDefault();
    if (!userObj) {
      return;
    }
    const data = {
      email: userObj.emailAddress,
      username: userObj.emailAddress,
      emailVerified: userObj.emailVerified,
      phoneNo,
      phoneVerified: userObj.phoneVerified,
      birthdate: userDob,
      location: null,
    };
    // updateDateOfBirth(data);
    signUpManual(data)
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.CREATED) {
          const path = `/m-signup-locAllow`;
          history.push(path, { email, login: resp.data.loginSession });
        }
      })
      .catch(({ response }) => {
        /* if (isModal) {
          setInSessionStorage('screen', 'error');
          setInSessionStorage('message', response.data.message);
          fetchAndSetModalPage('/message');
          return;
        } */
        if (response) {
          // alert(response.data.message);
          setResponseError(response.data.message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }

        // history.push('/login');
      });
  };

  const authenticatePhone = response => {
    setForbiddenErr('');
    setError('');
    authenticatePhoneVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          if (dob) {
            if (dobError) {
              const path = `/m-signup-dob`;
              history.push(path, { phoneNo, email, userDob, dobErrorMsg });
            } else {
              handleSubmit();
            }
          } else {
            const path = `/m-signup-dob`;
            history.push(path, {
              phoneNo,
              email,
              successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.DOB_AFTER_PHONE,
            });
          }
        }
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setTimeout(function() {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
              action: 'Username Login',
              formData: {
                user: data.userName,
              },
            });
          }, 2000);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
      })
      .catch(error => {
        const { status, code } = error.response;
        const { message } = error;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NOT_FOUND) {
          setResponseError(MESSAGE_CONSTANTS.AUTHENTICATION.OTP_NOT_FOUND);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.TOO_MANY_REQUEST) {
          setResponseError(MESSAGE_CONSTANTS.AUTHENTICATION.TOO_MANY_REQUEST);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const verifyOTP = vCode => {
    const data = {
      phoneNo,
      OTP: vCode,
      method: 'signup',
      email,
    };
    if (emailAddress) {
      data.email = emailAddress;
    }
    authenticatePhone(data);
    /*  const path = `/m-signup-dob`;
    history.push(path); */
  };

  const verifyPhone = val => {
    if (val.length === 6) {
      verifyOTP(val);
      setErrorDisabled(false);
      return;
    }
    setErrorDisabled(true);
  };

  const handleResendSubmit = () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        phoneNo,
      },
    });
    // evt.preventDefault();
    if (!phoneNo) {
      return;
    }
    const data = {
      phoneNo,
      method: 'signup',
    };
    requestPhone(data);
  };

  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button
                  className="button button-back"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body justify-start">
          <h1 className="title">Enter the verification code we sent</h1>
          <p className="copy">We sent you a text message with a 6-digit code</p>
          <div className="inputAreaWrapper">
            <div className="verifyInputArea">
              {/* <input
                className="input"
                type="number"
                placeholder="••••••"
                defaultValue=""
              /> */}
              <NumberFormat
                id="otpInput"
                className="input"
                format="######"
                mask=""
                name="phone"
                placeholder="••••••"
                autoComplete="off"
                autoFocus
                onValueChange={values => {
                  if (values.value.length === 6) {
                    verifyPhone(values.value);
                  } else {
                    setErrorDisabled(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="ffScreen__footer">
          <button
            className="button button-full button-sm"
            disabled={btnDisabled}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.OTP, {
                action: 'Resend OTP',
              });
              handleResendSubmit();
            }}>
            Resend code
          </button>
        </div>
      </div>
    </>
  );
};
