import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Input } from 'Components/Form-Elements/Input';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Button } from 'Components/Form-Elements/Button';
import {
  setInSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const UserDetails = ({ history, isModal, fetchAndSetModalPage }) => {
  const firstName = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.NAME,
  );
  const lastName = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIRNAME,
  );
  const [name, setName] = useState(firstName || '');
  const [sirName, setSirName] = useState(lastName || '');
  const [nameValid, setNameValidity] = useState(false);
  const [sirNameValid, setSirNameValidity] = useState(false);

  const storeData = () => {
    setInSessionStorage(APP_CONSTANTS.STORAGE_KEY_REGISTRATION.NAME, name);
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIRNAME,
      sirName,
    );
    if (isModal) {
      fetchAndSetModalPage('/verify-phone-number');
      return;
    }
    history.push('/verify-phone-number');
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="90" />
          <div className="d-flex topBar">
            {!isModal ? (
              <Link to="/set-password" className="arrowBack" />
            ) : (
              <Button
                className="linkBtn arrowBack"
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_NAME, {
                    action: 'Back',
                  });
                  fetchAndSetModalPage('/set-password');
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>
        <div className="wrapper">
          <div className="info">
            <div className="iconBig userIcon" />
            <div className="title">
              Tell us your <strong>name</strong>
            </div>
          </div>
          <div className="form-group">
            <Input
              inputType="text"
              textLabel={MESSAGE_CONSTANTS.USER_DETAILS.FIRST_NAME}
              className="form-control"
              id="set-name"
              value={name}
              validationRules={['validateName']}
              handleChange={setName}
              getValidity={setNameValidity}
              onBlur={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_NAME, {
                  action: 'First Name',
                  formdata: { name },
                });
              }}
            />
          </div>
          <div className="form-group">
            <Input
              inputType="text"
              textLabel={MESSAGE_CONSTANTS.USER_DETAILS.LAST_NAME}
              className="form-control"
              id="set-sirname"
              value={sirName}
              validationRules={['validateName']}
              handleChange={setSirName}
              getValidity={setSirNameValidity}
              onBlur={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_NAME, {
                  action: 'Last Name',
                  formdata: { sirName },
                });
              }}
            />
          </div>
          <Button
            className="btn btn-primary"
            label={BUTTON_LABELS.COMMON.NEXT}
            disabled={!nameValid || !sirNameValid}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_NAME, {
                action: 'Next',
                formdata: { name, sirName },
              });
              storeData();
            }}
          />
        </div>
      </div>
    </>
  );
};

UserDetails.propTypes = {
  history: PropTypes.objectOf({
    push: PropTypes.func,
  }).isRequired,
  isModal: PropTypes.bool.isRequired,
  fetchAndSetModalPage: PropTypes.func.isRequired,
};
