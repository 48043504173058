import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { StarRating } from 'Components/StarRating';
import { injuryTypes } from 'Components/LineUpScreens/SportData/sportsObject';
import icon from '../../../assets/icons/icons.svg';

export const LineupPositionDetails = ({
  lineUp,
  handleActivePosition,
  getSelectedPlayers,
  lineUpStrength,
  lineUpFinalWin,
  calculateAvg,
  contestData,
  getTotalFP,
}) => {
  const getTotalFPForLineUp = () => {
    let totalFP = 0;
    lineUp.forEach(position => {
      if (position.player && position.player.seasonStats) {
        totalFP += position.player.seasonStats.averageFP;
      }
    });
    getTotalFP(totalFP.toFixed(2));
    return totalFP.toFixed(2);
  };

  const getAVGForlineUp = () => {
    const count = getSelectedPlayers();
    if (count.length) {
      const totalFp = getTotalFPForLineUp();
      return (totalFp / count.length).toFixed(2);
    }
    return 0;
  };

  useEffect(() => {
    const element = document.getElementsByClassName('playerAvatar active');
    if (element.length) {
      element[0].scrollIntoView();
    }
  }, [lineUp]);

  const renderLineUp = () => {
    if (lineUp.length) {
      return lineUp.map(position => {
        const { player, playerGame } = position;
        const injuryStatus =
          (player &&
            player.injury &&
            player.injury.status &&
            player.injury.status.charAt(0)) ||
          (player &&
            player.statusInShort &&
            injuryTypes.mlb.includes(player.statusInShort) &&
            player.statusInShort);
        const displayName =
          player && player.name && player.name.split(' ').pop();
        const ffpg =
          (player && player.seasonStats && player.seasonStats.averageFP) || '';

        return (
          <li
            className={`lineupCard__playerListItem ${!player ? 'isEmpty' : ''}`}
            key={position.position}
            onClick={() => handleActivePosition(position, true)}>
            <h3 className="position">
              {MESSAGE_CONSTANTS.NEW_GAME.POSITION_MAPPING[position.position]}
            </h3>
            <div
              className={`playerAvatar ${position.isActive ? 'selected' : ''} ${
                playerGame &&
                !playerGame.lateScratchEnabled &&
                playerGame.activeTab
                  ? 'lock'
                  : ''
              }`}>
              <span
                className="playerAvatar__image"
                data-label={
                  MESSAGE_CONSTANTS.NEW_GAME.POSITION_MAPPING[position.position]
                }
                style={
                  player.picture
                    ? { backgroundImage: `url(${player.picture})` }
                    : {}
                }
              />
              {playerGame &&
                !playerGame.lateScratchEnabled &&
                playerGame.activeTab && (
                  <span className="lock__screenIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16">
                      <use xlinkHref={`${icon}#i-lock`} />
                    </svg>
                  </span>
                )}
              {injuryStatus && (
                <span className="playerAvatar__statusBottomLeft">
                  {injuryStatus}
                </span>
              )}
            </div>
            <h2 className="name">{displayName}</h2>
            <p className="points">{ffpg}</p>
            <time />
          </li>
        );
      });
    }
    return null;
  };
  return (
    <>
      <ul className="lineupCard__playerList">{renderLineUp()}</ul>
      <div className="lineUpNumbers d-flex justify-content-between">
        <div className="yourLineup">
          <span className="d-block">
            {MESSAGE_CONSTANTS.LINEUP.YOUR_LINEUP}
          </span>
          <p>
            <strong>{getTotalFPForLineUp()}</strong>
            {getAVGForlineUp()}
            {MESSAGE_CONSTANTS.LINEUP.AVG}
          </p>
        </div>
        <div className="yourLineupRating text-center he">
          <StarRating
            rating={lineUpStrength.lineUpstrength || 0}
            starRatedColor="rgb(251, 214, 3)"
            starEmptyColor="rgba(0, 0, 0, 0)"
            starDimension="14px"
            starSpacing="2px"
          />
          <strong>vs</strong>
        </div>
        <div className="yourLineup text-right">
          <span className="d-block">
            {MESSAGE_CONSTANTS.LINEUP.DAILY_NUMBER}
          </span>
          <p>
            <strong>
              {(lineUpFinalWin && lineUpFinalWin.targetNumber) ||
                (contestData && contestData.targetNumber) ||
                '0.0'}
            </strong>
            {calculateAvg(lineUpFinalWin.targetNumber) || '0'}
            {MESSAGE_CONSTANTS.LINEUP.AVG}
          </p>
        </div>
      </div>
    </>
  );
};

LineupPositionDetails.defaultProps = {
  handleActivePosition: () => {},
  calculateAvg: () => {},
  getSelectedPlayers: 0,
  lineUp: [],
  lineUpStrength: {},
  lineUpFinalWin: {},
  contestData: {},
  getTotalFP: () => {},
};

LineupPositionDetails.propTypes = {
  handleActivePosition: PropTypes.func,
  calculateAvg: PropTypes.func,
  getSelectedPlayers: PropTypes.number,
  lineUp: PropTypes.arrayOf(PropTypes.any),
  lineUpStrength: PropTypes.objectOf({ lineUpstrength: PropTypes.number }),
  lineUpFinalWin: PropTypes.objectOf({ targetNumber: PropTypes.number }),
  contestData: PropTypes.objectOf({ targetNumber: PropTypes.number }),
  getTotalFP: PropTypes.func,
};
