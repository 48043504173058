/* eslint-disable max-len */
import { APP_CONSTANTS } from 'Constants/app-constants';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const MESSAGE_CONSTANTS = {
  LOCATION: {
    DESCRIPTION:
      'Some Daily Number features are only available in certain states.' +
      ' Enable location services to unlock these restricted games.',
  },
  BIRTH_DATE: {
    DESCRIPTION: `You must be ${APP_CONSTANTS.VALIDATION.MIN_AGE} years or older to play.`,
  },
  VERIFY_EMAIL: {
    ENTER_EMAIL: 'Enter email',
    OR: 'or',
    ALREADY_HAVE_ACCOUNT: 'Already have an account?',
    LOGIN: 'Log In',
  },
  VERIFY_USERNAME: {
    DESCRIPTION:
      'Your username is how we, or others will talk smack to you.' +
      '  It can be edited in your profile settings.',
    USERNAME: 'Username',
  },
  SET_PASSWORD: {
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm password',
    STRONG: 'Strong',
    WEAK: 'Weak',
    AVERAGE: 'Average',
    DOESNT_MATCH: "Doesn't match",
    MATCH: 'Matches',
    DESCRIPTION:
      'Your password must be at least eight characters long and' +
      ' include a number or a symbol.',
    OLD_PASSWORD: 'Old Password',
    NEW_PASSWORD: 'New password',
    REPEAT_NEW_PASSWORD: 'Repeat new Password',
  },
  USER_DETAILS: {
    ASK_NAME: 'Tell us your name',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
  },
  VERIFY_PHONE_NUMBER: {
    DESCRIPTION:
      'Please check your text messages and ' +
      ' enter the verification code that was sent',
  },
  ERROR_MSG: {
    INVALID_EMAIL: 'Invalid email address',
    EMAIL_EXISTS: 'Email already exists',
    INVALID_USERNAME:
      'Usernames must be at least 4 characters and' +
      ' may only contain letters, numbers and _',
    USERNAME_EXISTS: 'Username is taken',
    INVALID_REQUEST: 'Invalid username',
    EMAIL_PASSWORD_MATCH: 'Email/Username and Password do not match',
    NAME_FORMATE_ERROR:
      'Names may only contain letters, apostrophes, hyphens, and spaces',
    ADDRESS_FORMATE_ERROR:
      'Addresses may only contain letters, numbers, apostrophes, hyphens, and spaces',
    EMPTY_ERROR: 'Please fill out this field',
    DOB_ELIGIBILTY: 'You must be 18 years or older to play',
    DOB_VALID_FORMAT: 'Enter date in MM/dd/yyyy format ONLY.',
    OTP_ERROR: 'OTP entered is incorrect',
    PHONE_NUMBER_ERROR: 'Unable to send otp or invalid number',
    BAD_REQUEST: 'Bad Request. Invalid User Details.. please try again',
    USER_EXISTS: 'User Already exists.',
    SOMETHING_WRONG: 'Something is not right.. please try after some time',
    SELF_EXCLUSION:
      // eslint-disable-next-line max-len
      'Your account is temporarily blocked, please ',
    // eslint-disable-next-line max-len
    MAX_USERNAME_LENGTH: `username should not be more than ${APP_CONSTANTS.MAX_USERNAME_LENGTH} characters`,
    PHONE_EXISTS: 'An account already exists with this phone number',
    TOO_MANY_REQUEST: 'Unable to send OTP, please retry after some time.',
    TIMEOUT: 'Slow Network Detected. Could not load page',
    INVALID_DATE: 'Invalid date',
    MIN_TWO_GAMES: 'You must select players from a minimum of 2 games.',
  },
  ERROR_CODE: {
    INVALID_EMAIL: 'user.invalid.email',
    USER_INSERT_FAILED: 'user.invalid.username_or_password',
    SELF_EXCLUDED: 'user.disabled.self_excluded',
    USER_NOT_EXISTS: 'user.does_not.exists',
    INVALID_CUSTOMERID_OR_TOKEN: 'user.invalid.customerId_or_token',
    FB_UNKNOWN: 'unknown',
    PHONE_EXISTS: 'user.exists.phone_number',
    USER_EXISTS: 'user.exists.email',
  },
  LOG_IN: 'Log in',
  CONSENT: 'Does this look right?',
  CONSENT_MSG_1: "Here's what ",
  CONSENT_MSG_2:
    // eslint-disable-next-line max-len
    ' told us about you. Make any neccessary changes, then tap next to finish setting up your account.',
  NO_ACCOUNT: "Don't have an account yet? ",
  FB_ME: '/me',
  FB_CONNECTED: 'connected',
  STRING: {
    PHONE: {
      INVALID: 'Invalid phone number',
    },
    ZIP: 'Invalid zip code',
    SSN: 'Invalid SSN Number, please enter last 4 digits of SSN.',
  },
  GMAIL: 'gmail',
  GOOGLE: 'Google',
  FACEBOOK: 'Facebook',
  SIGNUP_STRATEGY: 'social',
  SURVEY: {
    FAVORITE_SPORT: 'What are your favorite sports?',
    GET_PAID: 'How do you like to get paid?',
    PLAY_PREFERENCE: 'How do you like to play?',
    FAVORITE_TEAM: 'What are your favorite teams?',
    FAVORITE_PLAYERS: 'Who are your favorite players?',
    YOUR_FAVORITES: 'Your favorites',
    FIND_TEAM: 'Find your favorite teams',
    FIND_PLAYER: 'Find your favorite players',
  },
  WIRECARD_RESP: {
    VALID_LOCATION: 'granted',
    ERROR: 'error',
    TXN_SUCCESS: 'paidSuccessfully',
    SUCCESS: 'success',
  },
  ADD_FUNDS_VALIDATION: {
    SOMETHING_WENT_WRONG: 'Something went wrong.',
    FUNDS_ADDED: 'Funds added!',
    TXN_DECLINED: 'Transaction declined due to insufficient funds',
  },
  CURRENCY_ISO_CODE: {
    USD: 'USD',
    XP_POINTS: 'XXX',
  },
  WALLET: {
    ADD_FUNDS: {
      HEADING: 'Add Funds',
    },
    ADD_METHOD: {
      HEADING: 'Add Method',
    },
    ADD_BANK: {
      HEADING: 'Add Bank Details',
      HEADING1: 'Add a Bank',
      DETAIL: 'Add your bank to your Wallet',
    },
    ADD_CARD: {
      HEADING: 'Add Card Details',
      HEADING1: 'Add a Credit or Debit Card',
      DETAIL: 'Add your credit or debit card to your Wallet',
    },
    CASHOUT: {
      HEADING: 'Cash Out',
    },
    HEADING: 'Your Wallet',
    TOOL_TIP_MSG:
      // eslint-disable-next-line max-len
      'Available balance may vary depending on amount of pending game entries and the current status. Wallet funds are subject to restrictions. ',
  },
  FOR_YOU: {
    HEADING: 'For You',
    HEADING_INTL: 'Active Games',
  },
  GAME_HISTORY: 'Game History',
  CANCEL: 'Cancel',
  SUBMITTED: 'Submitted',
  TRANSACTION_MSG: 'Your withdrawal is being processed',
  TRANSFER_INITIATED: 'Transfer initiated!',
  CASH_ME_OUT: 'Cash me out!',
  CASH_OUT_GENERIC:
    'There seemed to be an issue when cashing out funds into your account. Please try again.',
  NEW_GAME: {
    POSITION_MAPPING: {
      G: 'G',
      G1: 'G',
      G2: 'G',
      F1: 'F',
      F2: 'F',
      C: 'C',
      UTIL: 'U',
      QB: 'QB',
      RB1: 'RB',
      RB2: 'RB',
      WR1: 'WR',
      WR2: 'WR',
      TE: 'TE',
      'RB/WR/TE': 'FL',
      P1: 'P',
      P2: 'P',
      H1: 'H',
      H2: 'H',
      H3: 'H',
      H4: 'H',
      W: 'W',
      D: 'D',
      U: 'U',
    },
  },
  DEPOSIT_METHOD_ERR_MSGS: {
    UNABLE_TO_FETCH_DEPOSIT_LIMITS: 'Unable to fetch deposit limits.',
    UNABLE_TO_FETCH_DEPOSIT_METHODS: 'Unable to fetch  deposit methods.',
  },
  CARD_TYPE: {
    CREDIT: 'Credit',
    DEBIT: 'Debit',
    CHECK: 'CreditCard',
  },
  MODALBOX: {
    NOT_PAID_CONTEST: {
      title: 'Message',
      message:
        // eslint-disable-next-line max-len
        'You are not allowed to make a payment from the location you are in.',
    },
    NO_PAID_CONTEST_ALLOWED: {
      title: 'Message',
      message:
        // eslint-disable-next-line max-len
        'Sorry, Daily Number does not allow paid play from your current location',
    },

    EDIT_LINEUP_COMFIRMATION: {
      title: 'Edit Lineup?',
      message:
        // eslint-disable-next-line max-len
        'Editing your lineup will reset the target score.',
    },

    REMOVE_PLAYER: {
      title: 'Remove Player?',
      message:
        // eslint-disable-next-line max-len
        "Removing this player will reset the lineup strength, and you'll need to re-do any lineup multipliers you added.",
    },

    TIME_UP_CONTEST: {
      title: 'Time Up to submit contest',
      message:
        // eslint-disable-next-line max-len
        'contest has been started.Please try up-comming contest',
    },
    CONFIRM_SUBMIT: {
      title: 'Confirmation',
      message:
        // eslint-disable-next-line max-len
        'Enter this contest?',
    },
    WALLET_BALANCE_ERROR: {
      title: "You're Low On Funds!",
      message:
        "To submit this lineup, you'll need to add some cash to your wallet.",
    },
    SOMETHING_WRONG: {
      title: 'Something went wrong',
      message: 'Try after some time.',
    },
    AGE_ERROR: {
      title: 'Warning Age Limit',
      message:
        'You are not allowd to play cash contest, try free games instead.',
    },
    LOCATION_ERROR: {
      title: 'Location Not Allowed',
      message:
        // eslint-disable-next-line max-len
        'Some Daily Number features are available only in certain states, please grant permission to access your location to continue',
    },
    PERMISSION_DENIED: {
      title: 'Error',
      message:
        // eslint-disable-next-line max-len
        'User denied the Permission to Access Location. Please enable the location to add the funds',
    },
    PERMISSION_ALLOW: {
      title: 'Allow Location Sharing',
      message:
        // eslint-disable-next-line max-len
        'Daily Number requires your location for accessing certain features, please enable the location sharing from your browser settings.',
    },
    LOCATION_UNAVAILABLE: {
      title: 'Position is currently unavailable.',
      message: 'Position is currently unavailable for your location.',
    },
    LOCATION_NOT_ALLOWED: {
      title: 'Location not allowed',
      message:
        'You are not allowed to make a payment from the location you are in.',
    },
    WALLET_LOCATION_ERROR: {
      title: 'Location not allowed',
      message:
        'Some Daily Number features are only available in certain states.',
    },
    TIMEOUT: {
      title: 'Timed out',
      message: 'User took to long to grant/deny permission.',
    },
    UNKNOWN_ERR: {
      title: 'Unknown Error',
      message: 'An unknown error occoured',
    },
    NOT_ALLOWED: {
      title: 'Message',
      message: 'You are not allowed to play, try our free games instead.',
    },
    KYC_ERROR: {
      title: 'Unfortunately, we can’t verify your identity right now.',
      message:
        // eslint-disable-next-line max-len
        'Something doesn’t seem right. To activate this account, you’ll need to to talk with customer support to verify your identity.',
    },
    CANNOT_UPDATE_SAME_VAL: {
      title: 'Error',
      message: 'This limit cannot be increased within 90 days.',
    },
    CANNOT_UPDATE_SAME_VAL_TWICE: {
      title: 'Error',
      message: 'Cannot update the same value.',
    },
    RESPONSIBLE_PLAY_LIMIT_ERR: {
      title: 'Error',
      message:
        // eslint-disable-next-line max-len
        'There was an error completing your request. Please contact support if this continues',
    },
    SAME_AS_OLD_PASSWORD: {
      title: 'Error',
      message: 'New password cannot be same as old password',
    },
    CHECK_LOGIN_TYPE: {
      title: 'Error',
      message:
        // eslint-disable-next-line max-len
        'You cannot change the password since have used social login to sign in',
    },
    COMING_SOON: {
      title: '',
      message:
        // eslint-disable-next-line max-len
        "Stay tuned! We're working on some great items to redeem XP points",
    },
    TRANSACTION_WITHDRAWAL: {
      title: '',
      message:
        // eslint-disable-next-line max-len
        'Your transaction amount is withdrawn ',
    },
    FILE_TOO_LARGE: {
      title: 'Error',
      message: 'Please upload a picture with size less than 3MB',
    },
    FILE_TYPE_ERROR: {
      title: 'Error',
      message: 'Selected file is not an image',
    },
    LINK_EXPIRED: {
      title: 'Error',
      message: 'Password reset link is invalid or has expired',
    },
    INVALID_TOKEN: {
      title: 'Error',
      message: 'Invalid Token',
    },
    CROP_IMAGE: {
      title: 'Crop Image',
      message: '',
    },
    DELETE_LINEUP: {
      title: 'Delete Lineup',
      message: 'Are you sure want to delete the Line up?',
    },
    RESET_LINEUP: {
      title: 'Reset Lineup',
      message: 'Are you sure you would like to reset your Line up?',
    },
    ENTRIES_CLOSED: {
      title: 'Entries Closed',
      message: 'Entries are closed for this contest',
    },
    CONTEST_NOT_FOUND: {
      title: 'Contest ?',
      message: 'Contest not found',
    },
    REFRESH_LINEUP: {
      title: 'The Daily Number and player ratings have updated',
      message: 'Review the changes before you submit the edited lineup',
    },
    UPDATE_LINEUP: {
      title: 'Hold on! You Must Update Your Lineup',
      message:
        'All Daily Number lineups must include players from at least two different games.',
    },
  },
  KYC: {
    HEADING: 'We need some more info to verify your identity',
    SSN_LABEL:
      'Please provide the last 4 digits of your Social Security Number',
    FORM_LABEL:
      // eslint-disable-next-line max-len
      'Please check that the following previously entered information is correct',
    VERIFIED_LABEL:
      // eslint-disable-next-line max-len
      'The following has been verified',
    BAD_REQUEST: {
      title: 'Error',
      message: 'Bad Request',
    },
    OLD_PASSWORD_ERR: {
      title: 'Error',
      message: 'You seem to have entered the wrong old password',
    },
  },
  RESET_PASSWORD: {
    PAGE: 'Forgot Password',
    HEADING: 'Enter your email',
    DESCRIPTION:
      // eslint-disable-next-line max-len
      'Enter your email address and we will send you a link to get back into your account.',
    ERR_MESSAGE: 'Looks like you have entered an incorrect email address',
  },
  RESET_CONFIRMATION: {
    HEADING: 'We emailed you a password reset link',
    DESCRIPTION:
      // eslint-disable-next-line max-len
      'Can’t find it? Check your junk folder or view our troubleshooting tips.',
  },
  PLAYER_SELECTION: {
    SELECT_A: 'Select a ',
    REPLACE: 'Replace ',
    ANY_RATING: 'Any Rating',
  },
  RESPONSIBLE_GAMING: {
    DESC:
      // eslint-disable-next-line max-len
      'At Daily Number, we are committed to protecting our players. We recognize that sometimes people may not have all the resources necessary to manage their play in a productive responsible manner. Therefore, we offer proactive tools and support meant to encourage healthy player behavior and deliver positive player experiences.',
    MAX_MONTHLY_DEPOSIT: {
      CONTENT: 'Maximum Monthly Deposit',
      DESC:
        'Limit the amount you may add to your Daily Number wallet each month',
    },
    MAX_WEEKLY_CONTEST: {
      CONTENT: 'Maximum Weekly Contests',
      DESC: 'Limit how many Daily Number contests you may enter each week',
    },
    WEEKLY_CONTEST_ALERT: {
      CONTENT: 'Weekly Contests Alert',
      DESC:
        // eslint-disable-next-line max-len
        'Recieve an alert when you have entered more than a specified number of Daily Number contests for the week',
    },
    MAX_CONTEST_ENTRY_FEE: {
      CONTENT: 'Maximum Contest Entry Fee',
      DESC: 'Limit the entry fee for individual contests',
    },
    SELF_EXCLUSION_REQUEST: {
      CONTENT: 'Self Exclusion Request',
      DESC: 'Take a break from play or permanently close your account',
    },
    NEED_ADDITIONAL_HELP: {
      CONTENT: 'Need Additional Help?',
      DESC: 'Access additional resources to help with problem play',
    },
  },
  SESSION_EXPIRED: 'Session expired, please login again',
  ACCOUNT_UNVERFIED: 'Your account must be verified to enter this contest',
  ACCOUNT_UNDER_REVIEW:
    'Your account is currently under review. Please contact support@dailynumber.app.',
  SOCIAL_LOGGED_IN: 'Social Logged In ?',
  EMAIL_UPDATE_SUCCESS: 'Email Address has been updated successfully',
  LINEUP: {
    BALANCE_AVAILABLE: 'available in Wallet',
    CUSTOM_PLAY_STYLE: 'Customize Play Style',
    FREE: 'Free',
    ENTRY_FEE: 'Entry fee',
    YOUR_LINEUP: 'YOUR LINEUP',
    DAILY_NUMBER: 'DAILY NUMBER',
    AVG: ' AVG',
    DRAFT_HEADING: 'Not Finished ?',
    // eslint-disable-next-line prettier/prettier
    DRAFT_MESSAGE:
      'You have options! Either keep editing, delete your lineup or save and finish later.',
  },
  NOTIFICATIONS: { HEADING: 'Notifications' },
  EMAIL_BODY:
    'You should play a game on Daily Number. It’s a really simple sports gaming app that offers money and cool prizes.',
  AUTHENTICATION: {
    PHONE_NOT_VERIFIED:
      'Email Verified Successfully. Please Verify Phone Number to Login.',
    EMAIL_NOT_VERIFIED:
      'Phone Verified Successfully. Please Verify Email to Login.',
    EMAIL_VERIFIED_SIGNUP:
      'Email Verified Successfully. Please Verify Phone Number to Continue Signup.',
    PHONE_VERIFIED_SIGNUP:
      'Phone Verified Successfully. Please Verify Email to Continue Signup.',
    DOB_AFTER_PHONE:
      'Phone Verified Successfully. Please Enter your Date of Birth to Continue Signup.',
    DOB_AFTER_EMAIL:
      'Email Verified Successfully. Please Enter your Date of Birth to Continue Signup.',
    TOO_MANY_REQUEST: 'Too Many Requests. Try again after 10 Minutes',
    OTP_NOT_FOUND: 'OTP not found. Please Try Again',
  },
};
