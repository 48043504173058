import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';

export const maskCardNumber = number => {
  return APP_CONSTANTS.WALLET.CARD_NUMBER_MASK_CHARACTERS + number;
};

export const getPaymentMethodIconClass = item => {
  const cardTypes = ['Credit', 'Debit'];
  const cardBrands = ['MasterCard', 'Visa'];
  const bankTypes = [
    'Personal Checking',
    'Personal Savings',
    'Business Checking',
    'Business Savings',
  ];

  if (cardTypes.includes(item.account_type)) {
    if (cardBrands.includes(item.card_brand)) {
      return `${item.card_brand.toLowerCase()}Icon`;
    }
    return 'cardIcon';
  }
  if (bankTypes.includes(item.account_type)) {
    return 'bankIcon';
  }
  return 'cardIcon';
};

export const ordinalSuffixOf = i => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return `${i}ST`;
  }
  if (j === 2 && k !== 12) {
    return `${i}ND`;
  }
  if (j === 3 && k !== 13) {
    return `${i}RD`;
  }
  return `${i}TH`;
};

export const getLastName = value => {
  if (!value || typeof value !== 'string') {
    return value;
  }
  const arr = value.split(' ');
  if (arr.length === 3 && ['sr.', 'jr.', 'ii'].includes(arr[2].toLowerCase())) {
    return arr.slice(-2).join(' ');
  }
  return arr.slice(-1).join(' ');
};
export const getPositionShortCode = position => {
  return MESSAGE_CONSTANTS.NEW_GAME.POSITION_MAPPING[position];
};
