import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';
import { mixPanelTrackRegistration, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { TimerComponent } from './TimerComponent';

export const LineUpHeader = ({ isModal, closeModalBox, draftModalPopUp }) => {
  return (
    <header className="nodal__header">
      <h1 className="nodal__title">Your Lineup</h1>
      <div className="nodal__leftAct">
        <Button
          className="button"
          data-dismiss
          onClick={() => {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
              action: 'Close',
            });
            return isModal ? closeModalBox() : draftModalPopUp();
          }}
          label="Close"
        />
      </div>
      <TimerComponent className="nodal__rightAct" />
    </header>
  );
};

LineUpHeader.defaultProps = {
  draftModalPopUp: () => {},
  closeModalBox: () => {},
  isModal: false,
};

LineUpHeader.propTypes = {
  draftModalPopUp: PropTypes.func,
  closeModalBox: PropTypes.func,
  isModal: PropTypes.bool,
};
