/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { verifyUserLocation } from 'Actions/location-action';
import { Button } from 'Components/Form-Elements/Button';
import {
  getTrasaction,
  getUserBalance,
  cancelWithdrawal,
} from 'Actions/user-balance-action';
import { getUserDetail } from 'Actions/user-profile-action';
import { TransactionHistory } from 'Components/Wallet/TransactionHistory';
import { Button as ButtonReact } from 'reactstrap';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { getAge, getUserBirthDate } from 'Utils/user-details-utils';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { ModalBox } from 'Components/ModalBox';
import {
  setCookie,
  getFromSessionStorage,
  setInSessionStorage,
  removeFromSessionStorage,
} from 'Utils/storage-utilities';
import { Loader } from 'Components/Loader';
import { CancelWithDrawalModal } from 'Components/Wallet/CancelWithDrawalModal';
import { detectDevice } from 'Utils/device-utils';
import { AddFundsModal } from 'Components/Wallet/AddFundsModal';
import { CashoutModal } from 'Components/Wallet/CashoutModal';
import { CustomerSupportModal } from 'Components/Wallet/CustomerSupportModal';
import { getPaymentMethods } from 'Actions/wirecard-action';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const Wallet = ({ history }) => {
  const [balance, setBalance] = useState('');
  const [availableBalance, setAvailableBalance] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [toggleBalance, setToggleBalance] = useState(false);
  const [xpPoints, setXpPoints] = useState('');
  const [xpCurrency, setXpCurrency] = useState(true);
  const [isData, setIsData] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const [pendingWithdrawal, setPendingWithdrawal] = useState(null);
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const [showCashOutModal, setShowCashOutModal] = useState(false);
  const [showCustomerSupport, setShowCustomerSupport] = useState(false);
  const [showCancelWithDrawModal, setShowCancelWithDrawModal] = useState(false);
  const [getWithDrawalId, setWithDrawalId] = useState(null);

  const redirectScreen = getFromSessionStorage(
    APP_CONSTANTS.LINEUP_KEY.NAVIGATE_TO_KEY,
  );

  const isMobile = detectDevice();

  const callCashOutFundAndUserBalanceAPI = () => {
    setIsLoading(true);
    getUserBalance(['USD'])
      .then(({ data }) => {
        if (data && data.availableBalance) {
          setPendingWithdrawal(data.pendingWithdrawal);
          setAvailableBalance(
            parseFloat(data.availableBalance.amount.toFixed(2)),
          );
          setBalance(data.balance.amount);
          setIsData(true);
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });

    getPaymentMethods()
      .then(({ data }) => {
        const { summary, results } = data;
        if (summary && summary.totalCount > 0) {
          console.log(results[0]);
          setInSessionStorage('active-card', results[0]);
          setInSessionStorage('saved-cards', results);
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });

    getUserBalance(['XXX'])
      .then(({ data }) => {
        setXpPoints(data.balance.amount);
      })
      .catch(err => {
        setIsLoading(false);
        console.log(err);
      });

    getTrasaction()
      .then(({ data }) => {
        setIsLoading(false);
        if (data && data.results) {
          setTransactions(data.results);
        }
      })
      .catch(err => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const cancelWithdrawalAction = withdrawalID => {
    setWithDrawalId(withdrawalID);
    setShowCancelWithDrawModal(true);
  };

  const confirmWithDrawalAction = () => {
    const cancelObj = [getWithDrawalId];
    cancelWithdrawal(cancelObj).then(data => {
      callCashOutFundAndUserBalanceAPI();
      setShowCancelWithDrawModal(false);
    });
  };

  const checkUserDetail = position => {
    getUserDetail()
      .then(({ data }) => {
        if (data.data.needsManualKyc) {
          setIsLoading(false);
          isMobile
            ? history.push('/customer-support')
            : setShowCustomerSupport(true);
        } else {
          setIsLoading(false);
          const userCoords = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            horizontalAccuracy: position.coords.accuracy,
          };
          setInSessionStorage('userCoords', userCoords);
          setInSessionStorage('WalletBal', availableBalance);
          isMobile ? history.push('/add-funds') : setShowAddFundsModal(true);
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    if (modalBoxName.length) setToggleModalBox(true);
  }, [modalBoxName]);

  useEffect(() => {
    const data = ['USD'];
    getUserBalance(data)
      .then(({ data }) => {
        setPendingWithdrawal(data.pendingWithdrawal);
        setAvailableBalance(
          parseFloat(data.availableBalance.amount.toFixed(2)),
        );
        setBalance(data.balance.amount);
        setIsData(true);
      })
      .catch(err => {
        console.log(err);
      });
    mixpanelTrackPageView('Wallet Screen');
  }, []);

  useEffect(() => {
    const data = ['XXX'];
    getUserBalance(data)
      .then(({ data }) => {
        setXpPoints(data.balance.amount);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getTrasaction()
      .then(({ data }) => {
        setIsLoading(false);
        if (data && data.results) {
          setTransactions(data.results);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getPaymentMethods()
      .then(({ data }) => {
        const { summary, results } = data;
        if (summary && summary.totalCount > 0) {
          setInSessionStorage('active-card', results[0]);
          setInSessionStorage('saved-cards', results);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const toggleXp = () => {
    setXpCurrency(!xpCurrency);
    if (toggleBalance) {
      setToggleBalance(false);
    } else {
      setToggleBalance(true);
    }
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.NOT_PAID_CONTEST) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.NOT_PAID_CONTEST;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.SOMETHING_WRONG) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.SOMETHING_WRONG;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.AGE_ERROR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.AGE_ERROR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.PERMISSION_DENIED) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.PERMISSION_DENIED;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.TIMEOUT) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.TIMEOUT;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.WALLET_LOCATION_ERROR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.WALLET_LOCATION_ERROR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.UNKNOWN_ERR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.UNKNOWN_ERR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.KYC_ERROR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.KYC_ERROR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.LOCATION_NOT_ALLOWED) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.LOCATION_NOT_ALLOWED;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.COMING_SOON) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.COMING_SOON;
    }
    return modalbox;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const verifyLocation = position => {
    const {
      coords: { latitude, longitude, accuracy },
    } = position;
    verifyUserLocation(latitude, longitude, accuracy)
      .then(({ data }) => {
        if (data.allowPlay) {
          if (data.allowPaidPlay) {
            const age = getAge(getUserBirthDate());
            if (age >= data.minimumAge) {
              checkUserDetail(position);
            } else {
              setModalBoxName(APP_CONSTANTS.MODALBOX.AGE_ERROR);
              setIsLoading(false);
            }
          } else {
            setModalBoxName(APP_CONSTANTS.MODALBOX.NOT_PAID_CONTEST);
            setIsLoading(false);
          }
        } else {
          setModalBoxName(APP_CONSTANTS.MODALBOX.WALLET_LOCATION_ERROR);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setModalBoxName(APP_CONSTANTS.MODALBOX.SOMETHING_WRONG);
        setIsLoading(false);
      });
  };

  const showLocationErr = error => {
    setIsLoading(false);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setModalBoxName(APP_CONSTANTS.MODALBOX.PERMISSION_DENIED);
        break;
      case error.POSITION_UNAVAILABLE:
        setModalBoxName(APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE);
        break;
      case error.TIMEOUT:
        setModalBoxName(APP_CONSTANTS.MODALBOX.TIMEOUT);
        break;
      default:
        setModalBoxName(APP_CONSTANTS.MODALBOX.UNKNOWN_ERR);
        break;
    }
  };

  const requestLocationPermission = (cashOutScreen = false) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const coordinates = {
          lat: position.coords.latitude,
          long: position.coords.longitude,
          accuracy: position.coords.accuracy,
        };
        setCookie(APP_CONSTANTS.USER_DATA_KEY.LOCATION, coordinates);
        if (cashOutScreen) {
          // setIsLoading(true);
          setInSessionStorage('userCoords', coordinates);
          isMobile ? history.push('/cash-out') : setShowCashOutModal(true);
        } else {
          verifyLocation(position);
        }
      }, showLocationErr);
    }
  };

  const clearDataCard = () => {
    removeFromSessionStorage('is-cashout');
    removeFromSessionStorage('active-card');
    removeFromSessionStorage('do-cashin-flow');
    removeFromSessionStorage('do-cashout-flow');
  };

  const verifyLocnAndMoveToAddFund = () => {
    clearDataCard();
    setIsLoading(true);
    requestLocationPermission();
  };

  useEffect(() => {
    if (isData && getFromSessionStorage('show-add-fund')) {
      removeFromSessionStorage('show-add-fund');
      verifyLocnAndMoveToAddFund();
    }
  }, [isData]);

  const verifyLocnAndMoveToCashout = () => {
    clearDataCard();
    setInSessionStorage('is-cashout', true);
    setIsLoading(false);
    requestLocationPermission(true);
  };

  const xpPopup = () => {
    setModalBoxName(APP_CONSTANTS.MODALBOX.COMING_SOON);
    setIsLoading(false);
  };

  const walletCashOutDisabled =
    pendingWithdrawal || !isData || availableBalance <= 0;

  const [isBorderBottom, setIsBorderBottom] = useState(false);
  const handleScroll = e => {
    const element = e.target;
    if (element.scrollTop > 100) {
      setIsBorderBottom(true);
    } else {
      setIsBorderBottom(false);
    }
  };
  return (
    <>
      {showAddFundsModal && (
        <AddFundsModal
          showAddFundsModal={showAddFundsModal}
          setShowAddFundsModal={setShowAddFundsModal}
          updateUserBal={callCashOutFundAndUserBalanceAPI}
        />
      )}
      {showCashOutModal && (
        <CashoutModal
          showCashOutModal={showCashOutModal}
          setShowCashOutModal={setShowCashOutModal}
          updateUserBal={callCashOutFundAndUserBalanceAPI}
        />
      )}
      {showCustomerSupport && (
        <CustomerSupportModal
          showCustomerSupport={showCustomerSupport}
          setShowCustomerSupport={setShowCustomerSupport}
        />
      )}
      {showCancelWithDrawModal && (
        <CancelWithDrawalModal
          close={() => setShowCancelWithDrawModal(false)}
          confirm={() => confirmWithDrawalAction()}
        />
      )}
      {isLoading && <Loader />}
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="Ok"
          showBothButtons={false}
        />
      )}
      <main className="App__main" onScroll={handleScroll}>
        <div
          className={`headSection isWalletPage ${
            isBorderBottom ? 'isBorderBottom' : ''
          }`}>
          <div className="headSection__contain">
            <h2 className="headSection__title">
              <span>Your </span>Wallet
            </h2>
            {/* <h2 className="headSection__title">
              {MESSAGE_CONSTANTS.WALLET.HEADING}
            </h2> */}
            <div className="headSection__rightAct">
              <button
                className="button button-blue"
                type="button"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.TAP_WALLET_MAIN_NAV,
                    {
                      action: 'toggleXp',
                      currency: xpCurrency,
                      xp_Points: xpPoints,
                      xp_balance: balance,
                    },
                  );
                  toggleXp();
                }}>
                {xpCurrency ? `${xpPoints}XP` : `$${balance}`}
              </button>
            </div>
          </div>
        </div>
        {/* <div className="pageHeaderWrap">
          <div className="d-flex topBar resetTopBar">
            <div className="d-flex pageHeader">
              <div className="leftSide" />
              {redirectScreen && (
                <div className="float-left">
                  <Link to="/line-up">{BUTTON_LABELS.COMMON.CANCEL}</Link>
                  <div className="flex-grow-1" />
                </div>
              )}
              <h2>{MESSAGE_CONSTANTS.WALLET.HEADING}</h2>
              <div className="walletAmountBtn rightSide">
                <ButtonReact color="primary" onClick={toggleXp} size="sm">
                  {xpCurrency ? `${xpPoints}XP` : `$${balance}`}
                </ButtonReact>
              </div>
            </div>
          </div>
        </div> */}
        <div className="wrapper walletPage">
          <div className="clearfix walletBalanceWrap">
            <div className="info">
              {isData && xpCurrency ? (
                <div className="title centerText">
                  <strong>
                    <sup className="dollorSign">$</sup>
                    {balance}
                  </strong>
                </div>
              ) : (
                isData && (
                  <div className="title centerText">
                    <strong>{xpPoints}</strong>
                    <small className="xpSign">xp</small>
                  </div>
                )
              )}
              <div className="centerText">
                <div className="availableBalance">
                  <span>
                    {!toggleBalance && `$${availableBalance} Available`}
                  </span>
                  <span>
                    {!toggleBalance && (
                      <div className="questionMarkInfo">
                        <span className="questionIcon" />
                        <div className="aboutQuestionInfo">
                          <p>
                            {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                            <a href="/terms-of-service">
                              {' '}
                              terms and conditions
                            </a>
                            . *Some exclusions apply.
                          </p>
                        </div>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              {!toggleBalance && !isLoading && (
                <>
                  <Button
                    className="btn walletButtons fundButtons"
                    type="button"
                    onClick={() => {
                      mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                        action: 'Action Type',
                        formData: {
                          button: BUTTON_LABELS.WALLET.ADD_FUNDS,
                        },
                      });
                      verifyLocnAndMoveToAddFund();
                    }}
                    disabled={!isData}
                    label={BUTTON_LABELS.WALLET.ADD_FUNDS}
                  />
                  <Button
                    className={`btn walletButtons fundButtons ${
                      walletCashOutDisabled ? 'disableBtn' : ''
                    }`}
                    type="button"
                    onClick={() => {
                      mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.WITHDRAW, {
                        action: 'Action Type',
                        formData: {
                          button: BUTTON_LABELS.WALLET.CASHOUT,
                        },
                      });
                      verifyLocnAndMoveToCashout();
                    }}
                    disabled={walletCashOutDisabled}
                    label={BUTTON_LABELS.WALLET.CASHOUT}
                  />
                </>
              )}
              {toggleBalance && !isLoading && (
                <>
                  <Button
                    className="btn walletButtons btnRedeem"
                    type="button"
                    disabled={xpPoints <= APP_CONSTANTS.MINIMUM_XP_BALANCE}
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.TAP_WALLET_MAIN_NAV,
                        {
                          action: 'Reedem',
                          xp: xpPoints,
                        },
                      );
                      xpPopup();
                    }}
                    xpPoints={xpPoints}
                    label={BUTTON_LABELS.XP.REDEEM}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {!isLoading && (
          <TransactionHistory
            pendingWithdrawal={pendingWithdrawal}
            transactions={transactions}
            cancelWithdrawalAction={cancelWithdrawalAction}
          />
        )}
      </main>
    </>
  );
};
