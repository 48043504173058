import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const receiveDraftPlayers = json => {
  const playerGames = json.playerGames.reduce((obj, pg) => {
    /* eslint-disable no-param-reassign */
    /* eslint no-underscore-dangle: 0 */
    obj[pg.playerId._str] = pg;
    return obj;
  }, {});

  const players = json.players.map(pl => {
    /* eslint-disable no-param-reassign */
    /* eslint no-underscore-dangle: 0 */
    pl.playerGame = playerGames[pl._id];
    return pl;
  });
  return players;
};

export const draftPlayers = async contest => {
  const { sportSlug, season, timePeriod, slate } = contest;
  const queryURL = `/${sportSlug}/${season}/${timePeriod}/${slate}`;
  const url = `${API.DRAFT_PLAYER}${queryURL}`;
  const data = await NetworkManager.requestGET(url);
  return data;
};

export const getLineupStrengthWithPlayers = async (
  contest,
  positionDetails,
) => {
  const requestBody = [
    {
      $type: 'oid',
      /* eslint no-underscore-dangle: 0 */
      $value: contest._id,
    },
    positionDetails,
  ];
  const url = API.LINE_UP_STRENGTH;
  const response = await NetworkManager.requestPOST(url, requestBody);
  return response;
};

export const receiveLineupStrength = (json, players = []) => {
  json.players = json.players.reduce((obj, player) => {
    obj[player.pid] = player.new_lineup_strength;
    return obj;
  }, {});

  const playersWithStrength = players.map(player => {
    player.strength = json.players[player.apiId];
    return player;
  });
  return playersWithStrength;
};

export const cancelLineup = async id => {
  const url = `${API.CANCEL_LINEUP}`;
  const requestBody = [id, null, true];
  const response = await NetworkManager.requestAuthPOST(url, requestBody);
  return response;
};
