/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Login } from 'Components/Login';
import { Location } from 'Components/Location';
import { PhoneNumberVerification } from 'Components/PhoneNumberVerification';
import { BirthDate } from 'Components/BirthDate';
import { EmailVerification } from 'Components/EmailVerification';
import { UserNameVerification } from 'Components/UserNameVerification';
import { SetPassword } from 'Components/SetPassword';
import { UserDetails } from 'Components/UserDetails';
import { ConsentScreen } from 'Components/ConsentScreen';
import { MessageScreen } from 'Components/MessageScreen';

export const SignUpModal = ({
  showSignUpFlow,
  setShowSignUpFlow,
  signUpDraftLineup,
}) => {
  const fetchAndSetModalPage = (pageLocation, data) => {
    SetInitialPage(routesHash[pageLocation]);
  };

  const closeModalBox = (isSuccess = false) =>
    setShowSignUpFlow(false, isSuccess, signUpDraftLineup);

  const [initialPage, SetInitialPage] = useState(
    <Location
      isModal
      fetchAndSetModalPage={fetchAndSetModalPage}
      closeModalBox={closeModalBox}
    />,
  );

  const commonProps = {
    fetchAndSetModalPage,
    isModal: true,
    closeModalBox,
  };

  const routesHash = {
    '/location': <Location {...commonProps} />,
    '/verify-phone-number': <PhoneNumberVerification {...commonProps} />,
    '/birth-date': <BirthDate {...commonProps} />,
    '/verify-email': <EmailVerification {...commonProps} />,
    '/set-username': <UserNameVerification {...commonProps} />,
    '/set-password': <SetPassword {...commonProps} />,
    '/enter-details': <UserDetails {...commonProps} />,
    '/consent': <ConsentScreen {...commonProps} />,
    '/message': <MessageScreen {...commonProps} />,
  };

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const externalCloseBtn = (
    <button className="close popupClose" type="button" onClick={toggle}>
      &times;
    </button>
  );

  return (
    <div>
      {showSignUpFlow && (
        <Modal isOpen centered className="guestSignup signUpProcess">
          <ModalBody>{initialPage}</ModalBody>
        </Modal>
      )}
    </div>
  );
};
