import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const getPlayerDetails = async playerId => {
  // const url = `${API.GET_PLAYER_DETAILS}${playerId}`;
  const url = `${API.GET_PLAYER_DETAILS_NEW}`;
  const body = [playerId];
  // const response = await NetworkManager.requestGET(url);
  const response = await NetworkManager.requestAuthPOST(url, body);
  return response;
};

export const getMultiPlayerDetails = async playerIds => {
  const url = `${API.GET_MULTIPLAYER_DETAILS}`;
  const body = [playerIds];
  const response = await NetworkManager.requestPOST(url, body);
  return response;
};

export const getPlayerSeasonStats = async playerApiId => {
  const url = `${API.GET_PLAYER_SEASON_STAT}/${playerApiId}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};
