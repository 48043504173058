/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Route, Redirect } from 'react-router';

export const MultiSignin = () => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const curUrl = url.searchParams.get('cur');

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const jwtToken = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
  const userId = userdata._id;

  // console.log('cookie', userdata);

  if (userdata) {
    window.onload = function() {
      window.setTimeout(function() {
        if (curUrl !== undefined && curUrl !== null && curUrl !== '') {
          window.location.href = `${curUrl}?n=${userdata.fullName}&i=${userdata.imageUrl}&id=${userId}&t=${jwtToken}`;
        } else {
          window.location.href = `${process.env.REACT_APP_MARKETING_SITE_URL}?n=${userdata.fullName}&i=${userdata.imageUrl}&id=${userId}&t=${jwtToken}`;
        }
        // window.location.href = `https://dnstg.wpengine.com?myParam=${userdata.fullName}&imageUrl=${userdata.imageUrl}`;
        // window.location.href = `${process.env.REACT_APP_MARKETING_SITE_URL}?myParam=${userdata.fullName}&imageUrl=${userdata.imageUrl}`;
      }, 0);
    };
  } else {
    window.onload = function() {
      window.setTimeout(function() {
        // window.location.href = `https://dnstg.wpengine.com?myParam=null&imageUrl=null`;
        if (curUrl !== undefined && curUrl !== null && curUrl !== '') {
          window.location.href = `${curUrl}?n=null&i=null&id=null&t=null`;
        } else {
          window.location.href = `${process.env.REACT_APP_MARKETING_SITE_URL}?n=null&i=null&id=null&t=null`;
        }
      }, 0);
    };
  }
  return (
    <>
      <div />
    </>
  );
};
