import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { LineUpContext } from 'Components/LineUpScreens/Context';
import { timeUntilInHMS, now, compareTime } from 'Utils/date-utils';
import icon from '../../../assets/icons/icons.svg';

export const TimerComponent = ({ className }) => {
  const [timeCounter, setTimeCounter] = useState('');
  const { state } = useContext(LineUpContext);
  const { contestData, editLineUpRawData } = state;

  useEffect(() => {
    let timer;
    if (editLineUpRawData[0]) {
      const timeNow = now();
      let nextGame;
      editLineUpRawData.forEach(element => {
        const gameTime = element.playerGames.gameStartDate;
        const compareToNow = compareTime(gameTime, timeNow);
        if (!nextGame && !compareToNow) {
          nextGame = gameTime;
        } else {
          const compareToPrevGame = compareTime(gameTime, nextGame);
          if (compareToPrevGame && !compareToNow) {
            nextGame = gameTime;
          }
        }
      });
      timer = setInterval(() => {
        setTimeCounter(timeUntilInHMS(nextGame));
      }, 1000);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (
        contestData &&
        contestData.contestDate &&
        contestData.contestDate.start
      ) {
        timer = setInterval(() => {
          setTimeCounter(timeUntilInHMS(contestData.contestDate.start));
        }, 1000);
      }
    }

    return () => {
      clearInterval(timer);
    };
  }, [contestData]);

  return (
    <div className={className}>
      <span className={`font13 ${editLineUpRawData[0] ? 'timeUnlock' : ''}`}>
        {editLineUpRawData[0] && (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <use xlinkHref={`${icon}#i-unlock`} />
          </svg>
        )}
        {timeCounter}
      </span>
    </div>
  );
};

TimerComponent.propTypes = {
  className: PropTypes.string.isRequired,
};
