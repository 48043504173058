import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const sendOTP = async data => {
  const url = API.GET_OPT;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};

export const getOTPVerified = async data => {
  const url = API.VERIFY_OTP;
  const response = await NetworkManager.requestPUT(url, data);
  return response;
};

export const verifyPhone = async data => {
  const url = API.VERIFY_PHONE;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};
