import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { getFromSessionStorage } from 'Utils/storage-utilities';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ConfirmCashOutModal = ({
  cashOutBalance,
  withdrawalAmt,
  close,
}) => {
  const walletBal = getFromSessionStorage('WalletBal');
  const item = getFromSessionStorage('active-card');

  const renderActiveCard = () => {
    return (
      <>
        <div className="dropDownWrap">
          <div className="listWithIcons">
            <ul className="border">
              <li className="d-flex" key={item.payment_method_identifier}>
                <button
                  type="button"
                  tag="button"
                  className="d-flex align-items-center addFundsBtn">
                  <div className="listIcon cardIcon" />
                  <div className="infoWrap flex-grow-1">
                    <div className="title">{item.account_type}</div>
                    <div className="info font-sm">
                      <span> {item.description}</span>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="backfade">
      <div id="popModal" className="card-body cashoutWrapper">
        <div className="d-flex">
          <Button className="arrowBack btnCancel" onClick={close} />
          <div className="d-flex topBar mobilePopupHeader">
            <div className="flex-grow-1 title text-center ">
              {MESSAGE_CONSTANTS.WALLET.CASHOUT.HEADING}
            </div>
          </div>
        </div>
        <div className="modal-body">
          <div className="info pHeader">
            <div className="centerText title mr-0">
              <strong>Cash Out ${withdrawalAmt}?</strong>
            </div>
            <div className="availableBalance centerText">
              <span>{`$${walletBal} available in Wallet`}</span>
              <div className="questionMarkInfo">
                <span className="questionIcon" />
                <div className="aboutQuestionInfo">
                  <p>
                    {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                    <a href="/terms-of-service"> terms and conditions</a>. *Some
                    exclusions apply.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="formToWrap">
            <div className="listWithIcons">
              <div className="textFromTo">FROM</div>
              <ul className="border">
                <li className="d-flex" key="add-card">
                  <button className="d-flex align-items-center" type="button">
                    <div className="iconDollor">$</div>
                    <div className="textWallet">Your Daily Number Wallet</div>
                  </button>
                </li>
              </ul>
            </div>
            <div className="divider" />
            <div className="listWithIcons">
              <div className="textFromTo">TO</div>
              {renderActiveCard()}
            </div>
          </div>
          <div className="info">
            <Button
              className="btn btn-primary fullWidht"
              type="button"
              label={`${BUTTON_LABELS.COMMON.CONFIRM}`}
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.WITHDRAW, {
                  action: BUTTON_LABELS.COMMON.CONFIRM,
                  formData: {
                    allCard: item,
                    WithDrawAmount: withdrawalAmt,
                  },
                });
                cashOutBalance();
              }}
            />
            <div className="yourFundText">
              Your funds will be available in 3-6 days
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ConfirmCashOutModal.propTypes = {
  cashOutBalance: PropTypes.func.isRequired,
  withdrawalAmt: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,
};
