/* eslint-disable import/no-duplicates */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import baseballImg from '../../../assets/images/sportBaseballCopy.png';
import bsktballImg from '../../../assets/images/sportBasketball.png';
import footballImg from '../../../assets/images/sportFootballCopy.png';
import nhlImg from '../../../assets/icons/icons.svg';
import svgImg from '../../../assets/icons/icons.svg';

export const sports = [
  {
    name: 'Basketball',
    image: bsktballImg,
    league: 'NBA',
    height: 100,
    width: 100,
    sportSlug: 'nba',
    requiredPositions: ['G1', 'G2', 'F1', 'F2', 'UTIL'],
    positionTypes: [
      {
        name: 'Guard',
        lineupPositions: ['G1', 'G2'],
        key: 'G',
        values: ['SG', 'PG', 'G'],
      },
      {
        name: 'Forward',
        lineupPositions: ['F1', 'F2'],
        key: 'F',
        values: ['SF', 'PF', 'F'],
      },
      {
        name: 'Utility Player',
        lineupPositions: ['UTIL'],
        key: 'U',
        values: ['PG', 'SG', 'G', 'SF', 'PF', 'F', 'C'],
        filters: ['G', 'F', 'C'],
      },
    ],
  },
  {
    name: 'Football',
    image: footballImg,
    league: 'NFL',
    height: 100,
    width: 114,
    sportSlug: 'nfl',
    requiredPositions: ['QB', 'RB1', 'WR1', 'WR2', 'RB/WR/TE'],
    positionTypes: [
      {
        name: 'Quarterback',
        lineupPositions: ['QB'],
        key: 'QB',
        values: ['QB'],
      },
      {
        name: 'Running Back',
        lineupPositions: ['RB1', 'RB2'],
        key: 'RB',
        values: ['RB'],
      },
      {
        name: 'Wide Receiver',
        lineupPositions: ['WR1', 'WR2'],
        key: 'WR',
        values: ['WR'],
      },
      {
        name: 'Tight End',
        lineupPositions: ['TE'],
        key: 'TE',
        values: ['TE'],
      },
      {
        name: 'Flex Player',
        lineupPositions: ['RB/WR/TE'],
        key: 'RB/WR/TE',
        values: ['RB', 'WR', 'TE'],
        filters: ['RB', 'WR', 'TE'],
      },
    ],
  },
  {
    name: 'Baseball',
    image: baseballImg,
    league: 'MLB',
    height: 100,
    width: 100,
    sportSlug: 'mlb',
    requiredPositions: ['P1', 'H1', 'H2', 'H3', 'H4'],
    positionTypes: [
      {
        name: 'Pitcher',
        lineupPositions: ['P1'],
        key: 'P',
        values: ['SP', 'RP', 'P'],
      },
      {
        name: 'Hitter',
        lineupPositions: ['H1', 'H2', 'H3', 'H4'],
        key: 'H',
        values: ['1B', '2B', '3B', 'SS', 'OF', 'C', 'DH'],
      },
    ],
  },
  {
    name: 'Hockey',
    image: nhlImg,
    league: 'NHL',
    height: 100,
    width: 100,
    sportSlug: 'nhl',
    requiredPositions: ['G', 'C', 'W', 'D', 'UTIL'],
    positionTypes: [
      {
        name: 'Goalie',
        lineupPositions: ['G'],
        key: 'G',
        values: ['G'],
      },
      {
        name: 'Center',
        lineupPositions: ['C'],
        key: 'C',
        values: ['C'],
      },
      {
        name: 'Wing',
        lineupPositions: ['W'],
        key: 'W',
        values: ['LW', 'RW'],
        filters: ['LW', 'RW'],
      },
      {
        name: 'Defenseman',
        lineupPositions: ['D'],
        key: 'D',
        values: ['D'],
      },
      {
        name: 'Utility Player',
        lineupPositions: ['UTIL'],
        key: 'U',
        values: ['C', 'LW', 'RW', 'D'],
        filters: ['C', 'LW', 'RW', 'D'],
      },
    ],
  },
];

const compositePositions = {
  U: ['G', 'F', 'C', 'D', 'W', 'U'],
  FLEX: ['RB', 'WR', 'TE'],
};

export const getCompositePositions = position => {
  return compositePositions[position];
};

export const contestImages = {
  nba: bsktballImg,
  nfl: footballImg,
  mlb: baseballImg,
  nhl: nhlImg,
  svg: svgImg,
};

export const gameName = {
  nba: 'Basketball',
  nfl: 'Football',
  mlb: 'Baseball',
  nhl: 'Hockey',
};

export const overviewStatMapping = (
  sportSlug,
  position,
  preGame,
  liveStats,
  recentStats,
  rankByPosition,
) => {
  const stats = liveStats;
  const statArr = [];
  switch (sportSlug) {
    case 'nfl': {
      switch (position) {
        case 'QB':
          if (preGame) {
            statArr.push({ title: 'FPPG', value: stats.averageFP });
            statArr.push({
              title: 'PASS TD',
              value: stats.passing && stats.passing.td,
            });
            statArr.push({
              title: 'YDS/G',
              value:
                stats.passing &&
                stats.passing.yds &&
                stats.played &&
                stats.passing.yds / stats.played,
            });
            statArr.push({
              title: 'RATING',
              value: stats.passing && stats.passing.rating,
            });
          } else {
            statArr.push({ title: 'FP', value: stats.totalFP });
            statArr.push({
              title: 'CMP-ATT',
              value: `${stats.passing && stats.passing.c}-${stats.passing &&
                stats.passing.a}`,
            });
            statArr.push({
              title: 'PASS YDS',
              value: stats.passing && stats.passing.yds,
            });
            statArr.push({
              title: 'PASS TDS',
              value: stats.passing && stats.passing.td,
            });
            statArr.push({
              title: 'INT',
              value: stats.passing && stats.passing.i,
            });
          }
          break;
        case 'RB':
          if (preGame) {
            statArr.push({ title: 'FPPG', value: stats.averageFP });
            statArr.push({
              title: 'RUSH TDS',
              value: stats.rushing && stats.rushing.td,
            });
            statArr.push({
              title: 'YDS/G',
              value:
                (stats.rushing &&
                  stats.rushing.yds &&
                  stats.played &&
                  stats.rushing.yds / stats.played) ||
                0,
            });
            statArr.push({
              title: 'ATT/G',
              value:
                (stats.rushing &&
                  stats.rushing.rush &&
                  stats.played &&
                  stats.rushing.rush / stats.played) ||
                0,
            });
          } else {
            statArr.push({ title: 'FP', value: stats.totalFP });
            statArr.push({
              title: 'ATT',
              value: stats.rushing && stats.rushing.rush,
            });
            statArr.push({
              title: 'RUSH YDS',
              value: stats.rushing && stats.rushing.yds,
            });
            statArr.push({
              title: 'AVG',
              value:
                (stats.rushing &&
                  stats.rushing.yds &&
                  stats.rushing.rush &&
                  stats.rushing.yds / stats.rushing.rush) ||
                0,
            });
            statArr.push({
              title: 'RUSH TDS',
              value: stats.rushing && stats.rushing.td,
            });
          }
          break;
        case 'WR':
        case 'TE':
        case 'F':
          if (preGame) {
            statArr.push({ title: 'FPPG', value: stats.averageFP });
            statArr.push({
              title: 'REC TDS',
              value: stats.receiving && stats.receiving.td,
            });
            statArr.push({
              title: 'YDS/G',
              value:
                (stats.receiving &&
                  stats.receiving.yds &&
                  stats.played &&
                  stats.receiving.yds / stats.played) ||
                0,
            });
            statArr.push({
              title: 'REC',
              value: stats.receiving && stats.receiving.rec,
            });
          } else {
            statArr.push({ title: 'FP', value: stats.totalFP });
            statArr.push({
              title: 'REC',
              value: stats.receiving && stats.receiving.rec,
            });
            statArr.push({
              title: 'REC YDS',
              value: stats.receiving && stats.receiving.yds,
            });
            statArr.push({
              title: 'AVG',
              value:
                (stats.receiving &&
                  stats.receiving.yds &&
                  stats.receiving.rec &&
                  stats.receiving.yds / stats.receiving.rec) ||
                0,
            });
            statArr.push({
              title: 'REC TDS',
              value: stats.receiving && stats.receiving.td,
            });
          }
          break;
        default:
          break;
      }
      break;
    }
    case 'nba': {
      if (preGame) {
        statArr.push({ title: 'FPPG', value: stats.averageFP });
      } else {
        statArr.push({ title: 'FP', value: stats.totalFP });
      }
      statArr.push({
        title: 'PTS',
        value: stats.points || 0,
      });
      statArr.push({
        title: 'REB',
        value: stats.rebounds || 0,
      });
      statArr.push({
        title: 'AST',
        value: stats.assists || 0,
      });
      break;
    }
    case 'nhl': {
      switch (position) {
        case 'G':
          if (preGame) {
            statArr.push({ title: 'FPPG', value: stats.totalFP });
            statArr.push({
              title: 'W-L-OTL',
              value: `${stats.wins}-${stats.losses}-${stats.overtimeLoss}`,
            });
            statArr.push({
              title: 'GA/G',
              value: `${stats.goalsAgainst}/${stats.numGames}`,
            });
            statArr.push({
              title: 'SV%',
              value: stats.svPct,
            });
            statArr.push({
              title: 'SO',
              value: stats.shutout,
            });
          } else {
            statArr.push({ title: 'FP', value: stats.totalFP });
            statArr.push({
              title: 'SAVES',
              value: stats.saves,
            });
            statArr.push({
              title: 'GA',
              value: stats.goalsAgainst,
            });
            statArr.push({
              title: 'SV%',
              value: stats.svPct,
            });
            /* TODO */
            statArr.push({
              title: 'RESULT',
              value: '',
            });
          }
          break;
        /* case 'C':
        case 'W':
        case 'D':
        case 'U': */
        default:
          if (preGame) {
            statArr.push({ title: 'FPPG', value: stats.totalFP });
            statArr.push({
              title: 'GOALS',
              value: stats.goals,
            });
            statArr.push({
              title: 'AST',
              value: stats.assists,
            });
            statArr.push({
              title: 'PTS',
              value: stats.points,
            });
            statArr.push({
              title: '+/-',
              value: '',
            });
          } else {
            statArr.push({ title: 'FP', value: stats.totalFP });
            statArr.push({
              title: 'GOALS',
              value: stats.goals,
            });
            statArr.push({
              title: 'AST',
              value: stats.assists,
            });
            statArr.push({
              title: 'PTS',
              value: stats.points,
            });
            statArr.push({
              title: '+/-',
              value: '',
            });
          }
          break;
      }
      break;
    }
    default:
      return [];
  }

  return statArr.map(stat => {
    const value = stat.value ? stat.value : 0;
    return {
      ...stat,
      value:
        Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
    };
  });
};

export const sesonalStatMapping = (sportSlug, position, seasonStats) => {
  const stats = seasonStats;
  const statArr = [];
  switch (sportSlug) {
    case 'nfl': {
      switch (position) {
        case 'QB':
          statArr.push({ title: 'GP', value: stats.played });
          statArr.push({
            title: 'CMP',
            value: stats.passing && stats.passing.c,
          });
          statArr.push({
            title: 'ATT',
            value: stats.passing && stats.passing.a,
          });
          statArr.push({
            title: 'CMP%',
            value: stats.passing && stats.passing.pct,
          });
          statArr.push({
            title: 'YDS',
            value: stats.passing && stats.passing.yds,
          });
          statArr.push({
            title: 'YDS/G',
            value:
              (stats.passing &&
                stats.passing.yds &&
                stats.played &&
                stats.passing.yds / stats.played) ||
              0,
          });
          statArr.push({
            title: 'TDS',
            value: stats.passing && stats.passing.td,
          });
          statArr.push({
            title: 'INT',
            value: stats.passing && stats.passing.i,
          });
          statArr.push({
            title: 'RuYDS',
            value: stats.rushing && stats.rushing.yds,
          });
          statArr.push({
            title: 'YDS/G',
            value:
              (stats.rushing &&
                stats.rushing.yds &&
                stats.played &&
                stats.rushing.yds / stats.played) ||
              0,
          });
          statArr.push({
            title: 'RuTD',
            value: stats.rushing && stats.rushing.td,
          });
          statArr.push({ title: 'FUM', value: stats.fumbles.f });
          break;
        case 'RB':
          statArr.push({ title: 'GP', value: stats.played });
          statArr.push({
            title: 'ATT',
            value: stats.rushing && stats.rushing.rush,
          });
          statArr.push({
            title: 'YDS',
            value: stats.rushing && stats.rushing.yds,
          });
          statArr.push({
            title: 'AVG',
            value:
              (stats.rushing &&
                stats.rushing.yds &&
                stats.rushing.rush &&
                stats.rushing.yds / stats.rushing.rush) ||
              0,
          });
          statArr.push({
            title: 'LONG',
            value: stats.rushing && stats.rushing.lngst,
          });
          statArr.push({
            title: 'YDS/G',
            value:
              (stats.rushing &&
                stats.rushing.yds &&
                stats.played &&
                stats.rushing.yds / stats.played) ||
              0,
          });
          statArr.push({
            title: 'TDS',
            value: stats.rushing && stats.rushing.td,
          });
          statArr.push({ title: 'FUM', value: stats.fumbles.f });
          statArr.push({ title: 'REC', value: stats.receiving.rec });
          statArr.push({ title: 'RecYDS', value: stats.receiving.yds });
          statArr.push({
            title: 'YDS/G',
            value:
              (stats.receiving &&
                stats.receiving.yds &&
                stats.played &&
                stats.receiving.yds / stats.played) ||
              0,
          });
          statArr.push({ title: 'RecTD', value: stats.receiving.td });
          break;
        case 'WR':
        case 'TE':
        case 'F':
          statArr.push({ title: 'GP', value: stats.played });
          statArr.push({ title: 'REC', value: stats.receiving.rec });
          statArr.push({ title: 'TGTS', value: stats.receiving.tar });
          statArr.push({ title: 'YDS', value: stats.receiving.yds });
          statArr.push({
            title: 'AVG',
            value:
              (stats.receiving &&
                stats.receiving.yds &&
                stats.receiving.rec &&
                stats.receiving.yds / stats.receiving.rec) ||
              0,
          });
          statArr.push({ title: 'TD', value: stats.receiving.td });
          statArr.push({ title: 'LONG', value: stats.receiving.lngst });
          statArr.push({
            title: 'YDS/G',
            value:
              (stats.receiving &&
                stats.receiving.yds &&
                stats.played &&
                stats.receiving.yds / stats.played) ||
              0,
          });
          statArr.push({ title: 'FUM', value: stats.fumbles.f });
          break;
        default:
          break;
      }
      break;
    }
    case 'nba': {
      const { numGames } = stats;
      statArr.push({ title: 'MIN', value: stats.minutes / numGames || 0 });
      statArr.push({ title: 'PTS', value: stats.points / numGames || 0 });
      statArr.push({
        title: 'FGM/FGA',
        value: `${stats.fgm || 0}/${stats.fga || 0}`,
      });
      statArr.push({ title: 'FG%', value: stats.fgp * 100 || 0 });
      statArr.push({
        title: '3PM/3PA',
        value: `${stats.threeMade || 0}/${stats.threeAtt || 0}`,
      });
      statArr.push({ title: '3P%', value: stats.threePct * 100 || 0 });
      statArr.push({
        title: 'FTM/FTA',
        value: `${stats.ftm || 0}/${stats.fta || 0}`,
      });
      statArr.push({ title: 'FT%', value: stats.ftpct * 100 || 0 });
      statArr.push({ title: 'REB', value: stats.rebounds / numGames || 0 });
      statArr.push({
        title: 'AST',
        value: stats.assists / numGames || 0,
      });
      statArr.push({ title: 'BLK', value: stats.blocks / numGames || 0 });
      statArr.push({ title: 'STL', value: stats.steals / numGames || 0 });
      break;
    }
    case 'nhl': {
      switch (position) {
        case 'G':
          statArr.push({ title: 'GP', value: stats.played });
          statArr.push({
            title: 'WIN',
            value: stats.wins,
          });
          statArr.push({
            title: 'LOSS',
            value: stats.losses,
          });
          statArr.push({
            title: 'OT LOSS',
            value: stats.overtimeLoss,
          });
          statArr.push({
            title: 'SV/G',
            value: stats.saves,
          });
          statArr.push({
            title: 'SV%',
            value: stats.svPct,
          });
          statArr.push({
            title: 'GA/G',
            value: stats.goalsAgainst,
          });
          statArr.push({
            title: 'SO',
            value: stats.shutout,
          });
          break;
        /* case 'C':
        case 'W':
        case 'D':
        case 'U': */
        default:
          statArr.push({ title: 'GP', value: stats.played });
          statArr.push({
            title: 'TOI/G',
            value: stats.totalTimeOnIce,
          });
          statArr.push({
            title: 'GOALS',
            value: stats.goals,
          });
          statArr.push({
            title: 'AST',
            value: stats.assists,
          });
          statArr.push({
            title: 'PTS',
            value: stats.points,
          });
          statArr.push({
            title: '+/-',
            value: '',
          });
          statArr.push({
            title: 'SHOTS',
            value: stats.shotsOnGoal,
          });
          statArr.push({
            title: 'ON NET',
            value: '',
          });
          statArr.push({
            title: 'SH GOAL',
            value: stats.shortHandedGoals,
          });
          statArr.push({
            title: 'SH ASSIST',
            value: stats.shortHandedAssists,
          });
          statArr.push({
            title: 'BLK SHOT',
            value: stats.blockedShots,
          });
          statArr.push({
            title: 'SO GOAL',
            value: stats.shootOutGoals,
          });
          break;
      }
      break;
    }
    default:
      return [];
  }

  return statArr.map(stat => {
    const value = stat.value ? stat.value : 0;
    return {
      ...stat,
      value:
        Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
    };
  });
};

export const currentGameStatMapping = (sportSlug, position, liveStats) => {
  const stats = liveStats;
  const statArr = [];
  switch (sportSlug) {
    case 'nfl': {
      switch (position) {
        case 'QB':
          statArr.push({
            title: 'CMP',
            value: stats.passing && stats.passing.c,
          });
          statArr.push({
            title: 'ATT',
            value: stats.passing && stats.passing.a,
          });
          statArr.push({ title: 'CMP%', value: stats.passing.pct });
          statArr.push({
            title: 'PASS YDS',
            value: stats.passing && stats.passing.yds,
          });
          statArr.push({
            title: 'PASS TDS',
            value: stats.passing && stats.passing.td,
          });
          statArr.push({ title: 'INT', value: stats.passing.i });
          statArr.push({
            title: 'RUSH YDS',
            value: stats.rushing && stats.rushing.yds,
          });
          statArr.push({
            title: 'RUSH TDS',
            value: stats.rushing && stats.rushing.td,
          });
          statArr.push({
            title: 'FUM',
            value: stats.fumbles && stats.fumbles.f,
          });
          statArr.push({
            title: '2PT CONV',
            value: (stats.passing && stats.passing.tpc) || 0,
          });
          break;
        case 'RB':
          statArr.push({
            title: 'ATT',
            value: stats.rushing && stats.rushing.rush,
          });
          statArr.push({
            title: 'YDS',
            value: stats.rushing && stats.rushing.yds,
          });
          statArr.push({
            title: 'AVG',
            value:
              (stats.rushing &&
                stats.rushing.yds &&
                stats.rushing.rush &&
                stats.rushing.yds / stats.rushing.rush) ||
              0,
          });
          statArr.push({
            title: 'LONG',
            value: stats.rushing && stats.rushing.lngst,
          });
          statArr.push({
            title: 'RUSH TDS',
            value: stats.rushing && stats.rushing.td,
          });
          statArr.push({ title: 'FUM', value: stats.fumbles.f });
          statArr.push({ title: 'REC', value: stats.receiving.rec });
          statArr.push({ title: 'REC YDS', value: stats.receiving.yds });
          statArr.push({ title: 'REC TDS', value: stats.receiving.td });
          statArr.push({
            title: '2PT CONV',
            value: (stats.rushing && stats.rushing.tpc) || 0,
          });
          break;
        case 'WR':
        case 'TE':
          statArr.push({ title: 'REC', value: stats.receiving.rec });
          statArr.push({ title: 'TGTS', value: stats.receiving.tar });
          statArr.push({ title: 'REC YDS', value: stats.receiving.yds });
          statArr.push({
            title: 'AVG',
            value:
              (stats.receiving &&
                stats.receiving.yds &&
                stats.receiving.rec &&
                stats.receiving.yds / stats.receiving.rec) ||
              0,
          });
          statArr.push({ title: 'TD', value: stats.receiving.td });
          statArr.push({ title: 'LONG', value: stats.receiving.lngst });
          statArr.push({ title: 'FUM', value: stats.fumbles.f });
          statArr.push({
            title: '2PT CONV',
            value: (stats.receiving && stats.receiving.tpc) || 0,
          });
          break;
        default:
          break;
      }
      break;
    }
    case 'nba': {
      statArr.push({ title: 'MIN', value: stats.minutes || 0 });
      statArr.push({ title: 'PTS', value: stats.points || 0 });
      statArr.push({
        title: 'FGM/FGA',
        value: `${stats.fgm || 0}/${stats.fga || 0}`,
      });
      statArr.push({ title: 'FG%', value: stats.fgp || 0 });
      statArr.push({
        title: '3PM/3PA',
        value: `${stats.threeMade || 0}/${stats.threeAtt || 0}`,
      });
      statArr.push({ title: '3P%', value: stats.threePct || 0 });
      statArr.push({
        title: 'FTM/FTA',
        value: `${stats.ftm || 0}/${stats.fta || 0}`,
      });
      statArr.push({ title: 'FT%', value: stats.ftpct || 0 });
      statArr.push({ title: 'REB', value: stats.rebounds || 0 });
      statArr.push({
        title: 'AST',
        value: stats.assists || 0,
      });
      statArr.push({ title: 'BLK', value: stats.blocks || 0 });
      statArr.push({ title: 'STL', value: stats.steals || 0 });
      break;
    }
    case 'nhl': {
      switch (position) {
        case 'G':
          statArr.push({ title: 'SHOTS', value: stats.shotsAgainst });
          statArr.push({
            title: 'SAVES',
            value: stats.saves,
          });
          statArr.push({
            title: 'GA',
            value: stats.goalAgainst,
          });
          statArr.push({
            title: 'SV%',
            value: stats.svPct,
          });
          /* TODO : UI needs to deal with it using following fields: game-> status, playerGames -> stats.played, stats.wins, stats.losses, stats.overtimeLoss */
          statArr.push({
            title: 'RESULT',
            value: '',
          });
          break;
        /* case 'C':
        case 'W':
        case 'D':
        case 'U': */
        default:
          statArr.push({ title: 'TOI', value: stats.totalTimeOnIce });
          statArr.push({
            title: 'GOALS',
            value: stats.goals,
          });
          statArr.push({
            title: 'AST',
            value: stats.assists,
          });
          statArr.push({
            title: 'PTS',
            value: stats.points,
          });
          /* TODO */
          statArr.push({
            title: '+/-',
            value: '',
          });
          statArr.push({
            title: 'SHOTS',
            value: stats.shotsOnGoal,
          });
          statArr.push({
            title: 'SH GOAL',
            value: stats.shortHandedGoals,
          });
          statArr.push({
            title: 'SH ASSIST',
            value: stats.shortHandedAssists,
          });
          statArr.push({
            title: 'BLK SHOT',
            value: stats.blockedShots,
          });
          statArr.push({
            title: 'SO GOAL',
            value: stats.shootOutGoals,
          });
          break;
      }
      break;
    }
    default:
      break;
  }
  return statArr.map(stat => {
    const value = stat.value ? stat.value : 0;
    return {
      ...stat,
      value:
        Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
    };
  });
};

export const reduceData = data => {
  if (!data) {
    return;
  }

  return data.reduce((obj, d) => {
    obj[d._id] = d;
    return obj;
  }, {});
};

export const COLORS = {
  marineBlue: '#012864',
  lightGray: 'rgb(220,218,218)',
  // green: 'rgba(48,174,65,1)',
  green: 'green',
  // appBlue: '#0064fb',
  appBlue: '', // default css-class
  brownGrey: '#949494',
  yellow: 'yellow',
};

export const WEATHER_SEAGMENTS = {
  overcast: 'cloudy',
  cloudy: 'cloudy',
  'partly cloudy': 'partly-cloudy',
  sunny: 'sunny',
  clear: 'sunny',
  rain: 'rainy',
  'patchy rain nearby': 'rainy',
  'patchy light drizzle': 'rainy',
  'light drizzle': 'rainy',
  'patchy light rain': 'rainy',
  'light rain shower': 'rainy',
  'light rain': 'rainy',
  'moderate rain at times': 'rainy',
  'moderate rain': 'rainy',
  'moderate or heavy rain shower': 'rainy',
  'heavy rain at times': 'rainy',
  'heavy rain': 'rainy',
  'torrential rain shower': 'rainy',
  'freezing drizzle': 'freezing-rain',
  'heavy freezing drizzle': 'freezing-rain',
  'patchy freezing drizzle nearby': 'freezing-rain',
  'light freezing rain': 'freezing-rain',
  'moderate or heavy freezing rain': 'freezing-rain',
  'patchy sleet nearby': 'freezing-rain',
  'light sleet showers': 'freezing-rain',
  'light sleet': 'freezing-rain',
  'moderate or heavy sleet showers': 'freezing-rain',
  'moderate or heavy sleet': 'freezing-rain',
  'Ice pellets': 'freezing-rain',
  'light showers of ice pellets': 'freezing-rain',
  'moderate or heavy showers of ice pellets': 'freezing-rain',
  'patchy light snow': 'snow',
  'light snow showers': 'snow',
  'light snow': 'snow',
  'moderate snow': 'snow',
  'patchy moderate snow': 'snow',
  'patchy snow nearby': 'snow',
  'patchy heavy snow': 'snow',
  'moderate or heavy snow showers': 'snow',
  'heavy snow': 'snow',
  blizzard: 'snow',
  'blowing snow': 'snow',
  'thundery outbreaks nearby': 'lightning',
  'patchy light rain in area with thunder': 'lightning',
  'moderate or heavy snow in area with thunder': 'lightning',
  'patchy light snow in area with thunder': 'lightning',
  'freezing fog': 'fog',
  fog: 'fog',
  mist: 'fog',
};

export const injuryTypes = {
  mlb: ['D7', 'D10', 'D15', 'D60', 'DTD'],
  nfl: ['O', 'D', 'Q', 'P'],
};

export const TEAM_COLORS = {
  nfl: {
    ARI: {
      state: 'Arizona',
      team: 'Cardinals',
      hex: '#97233F',
      rgba: 'rgba(151, 35, 63, 1)',
      rgba10: 'rgba(192, 45, 80, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    ATL: {
      state: 'Atlanta',
      team: 'Falcons',
      hex: '#A71930',
      rgba: 'rgba(167, 25, 48, 1)',
      rgba10: 'rgba(211, 32, 61, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    BAL: {
      state: 'Baltimore',
      team: 'Ravens',
      hex: '#241773',
      rgba: 'rgba(26, 25, 95, 1)',
      rgba10: 'rgba(37, 36, 135, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    BUF: {
      state: 'Buffalo',
      team: 'Bills',
      hex: '#00338D',
      rgba: 'rgba(0, 51, 141, 1)',
      rgba10: 'rgba(0, 69, 192, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CAR: {
      state: 'Carolina',
      team: 'Panthers',
      hex: '#0085CA',
      rgba: 'rgba(0, 133, 202, 1)',
      rgba10: 'rgba(0, 167, 253, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CHI: {
      state: 'Chicago',
      team: 'Bears',
      hex: '#0B162A',
      rgba: 'rgba(11, 22, 42, 1)',
      rgba10: 'rgba(22, 43, 82, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CIN: {
      state: 'Cincinatti',
      team: 'Bengals',
      hex: '#FB4F14',
      rgba: 'rgba(251, 79, 20, 1)',
      rgba10: 'rgba(252, 117, 70, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CLE: {
      state: 'Cleveland',
      team: 'Browns',
      hex: '#311D00',
      rgba: 'rgba(49, 29, 0, 1)',
      rgba10: 'rgba(100, 59, 0, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    DAL: {
      state: 'Dallas',
      team: 'Cowboys',
      hex: '#003594',
      rgba: 'rgba(0, 53, 148, 1)',
      rgba10: 'rgba(0, 71, 199, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    DEN: {
      state: 'Denver',
      team: 'Broncos',
      hex: '#FB4F14',
      rgba: 'rgba(251, 79, 20, 1)',
      rgba10: 'rgba(252, 117, 70, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    DET: {
      state: 'Detroit',
      team: 'Lions',
      hex: '#0076B6',
      rgba: 'rgba(0, 118, 182, 1)',
      rgba10: 'rgba(0, 151, 233, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    GB: {
      state: 'Green Bay',
      team: 'Packers',
      hex: '#203731',
      rgba: 'rgba(24, 48, 40, 1)',
      rgba10: 'rgba(41, 82, 68, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    HOU: {
      state: 'Houston',
      team: 'Texans',
      hex: '#03202F',
      rgba: 'rgba(3, 32, 47, 1)',
      rgba10: 'rgba(6, 65, 95, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    IND: {
      state: 'Indianapolis',
      team: 'Colts',
      hex: '#002C5F',
      rgba: 'rgba(0, 44, 95, 1)',
      rgba10: 'rgba(0, 68, 146, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    JAC: {
      state: 'Jacksonville',
      team: 'Jaguars',
      hex: '#00677F',
      rgba: 'rgba(0, 103, 127, 1)',
      rgba10: 'rgba(0, 144, 178, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    JAX: {
      state: 'Jacksonville',
      team: 'Jaguars',
      hex: '#00677F',
      rgba: 'rgba(0, 103, 127, 1)',
      rgba10: 'rgba(0, 144, 178, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    KC: {
      state: 'Kansas City',
      team: 'Chiefs',
      hex: '#E31837',
      rgba: 'rgba(227, 24, 55, 1)',
      rgba10: 'rgba(235, 67, 93, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    LV: {
      state: 'Las Vegas',
      team: 'Raiders',
      hex: '#000000',
      rgba: 'rgba(0, 0, 0, 1)',
      rgba10: 'rgba(25, 25, 25, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    LAC: {
      state: 'Los Angeles',
      team: 'Chargers',
      hex: '#0080C6',
      rgba: 'rgba(0, 128, 198, 1)',
      rgba10: 'rgba(0, 161, 249, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    LA: {
      state: 'Los Angeles',
      team: 'Rams',
      hex: '#003594',
      rgba: 'rgba(0, 53, 148, 1)',
      rgba10: 'rgba(0, 71, 199, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    LAR: {
      state: 'Los Angeles',
      team: 'Rams',
      hex: '#003594',
      rgba: 'rgba(0, 53, 148, 1)',
      rgba10: 'rgba(0, 71, 199, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    MIA: {
      state: 'Miami',
      team: 'Dolphins',
      hex: '#008E97',
      rgba: 'rgba(0, 142, 151, 1)',
      rgba10: 'rgba(0, 190, 202, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    MIN: {
      state: 'Minnesota',
      team: 'Vikings',
      hex: '#4F2683',
      rgba: 'rgba(79, 38, 131, 1)',
      rgba10: 'rgba(103, 49, 171, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    NE: {
      state: 'New England',
      team: 'Patriots',
      hex: '#002244',
      rgba: 'rgba(0, 34, 68, 1)',
      rgba10: 'rgba(0, 60, 119, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    NO: {
      state: 'New Orleans',
      team: 'Saints',
      hex: '#D3BC8D',
      rgba: 'rgba(211, 188, 141, 1)',
      rgba10: 'rgba(225, 210, 178, 1)',
      text: 'rgba(0, 0, 0, 1)',
    },
    NYG: {
      state: 'New York',
      team: 'Giants',
      hex: '#0B2265',
      rgba: 'rgba(1, 35, 82, 1)',
      rgba10: 'rgba(2, 57, 132, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    NYJ: {
      state: 'New York',
      team: 'Jets',
      hex: '#125740',
      rgba: 'rgba(18, 87, 64, 1)',
      rgba10: 'rgba(27, 129, 95, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    PHI: {
      state: 'Philadelphia',
      team: 'Eagles',
      hex: '#004C54',
      rgba: 'rgba(0, 76, 84, 1)',
      rgba10: 'rgba(0, 122, 13, 15)',
      text: 'rgba(255, 255, 255, 1)',
    },
    PIT: {
      state: 'Pittsburgh',
      team: 'Steelers',
      hex: '#FFB612',
      rgba: 'rgba(255, 182, 18, 1)',
      rgba10: 'rgba(255, 198, 69, 1)',
      text: 'rgba(0, 0, 0, 1)',
    },
    SF: {
      state: 'San Francisco',
      team: '49ers',
      hex: '#AA0000',
      rgba: 'rgba(170, 0, 0, 1)',
      rgba10: 'rgba(221, 0, 0, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    SEA: {
      state: 'Seattle',
      team: 'Seahawks',
      hex: '#002244',
      rgba: 'rgba(0, 34, 68, 1)',
      rgba10: 'rgba(0, 60, 119, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    TB: {
      state: 'Tampa Bay',
      team: 'Buccaneers',
      hex: '#D50A0A',
      rgba: 'rgba(213, 10, 10, 1)',
      rgba10: 'rgba(244, 30, 30, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    TEN: {
      state: 'Tennessee',
      team: 'Titans',
      hex: '#0C2340',
      rgba: 'rgba(12, 35, 64, 1)',
      rgba10: 'rgba(20, 58, 107, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    WAS: {
      state: 'Washington',
      team: 'Football Team',
      hex: '#773141',
      rgba: 'rgba(63, 16, 16, 1)',
      rgba10: 'rgba(104, 26, 26, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
  },
  mlb: {
    ARI: {
      state: 'Arizona',
      team: 'Diamondbacks',
      hex: '#A71930',
      rgba: 'rgba(167, 25, 48, 1)',
      rgba10: 'rgba(211, 32, 61, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    ATL: {
      state: 'Atlanta',
      team: 'Braves',
      hex: '#CE1141',
      rgba: 'rgba(206, 17, 65, 1)',
      rgba10: 'rgba(237, 37, 88, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    BAL: {
      state: 'Baltimore',
      team: 'Orioles',
      hex: '#DF4601',
      rgba: 'rgba(223, 70, 1, 1)',
      rgba10: 'rgba(254, 93, 21, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    BOS: {
      state: 'Boston',
      team: 'Red Sox',
      hex: '#BD3039',
      rgba: 'rgba(189, 48, 57, 1)',
      rgba10: 'rgba(210, 78, 86, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CHC: {
      state: 'Chicago',
      team: 'Cubs',
      hex: '#0E3386',
      rgba: 'rgba(14, 51, 134, 1)',
      rgba10: 'rgba(19, 69, 180, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CHW: {
      state: 'Chicago',
      team: 'White Sox',
      hex: '#27251F',
      rgba: 'rgba(39, 37, 31, 1)',
      rgba10: 'rgba(67, 64, 54, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CWS: {
      state: 'Chicago',
      team: 'White Sox',
      hex: '#27251F',
      rgba: 'rgba(39, 37, 31, 1)',
      rgba10: 'rgba(67, 64, 54, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CIN: {
      state: 'Cincinatti',
      team: 'Reds',
      hex: '#C6011F',
      rgba: 'rgba(198, 1, 31, 1)',
      rgba10: 'rgba(249, 1, 39, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    CLE: {
      state: 'Cleveland',
      team: 'Indians',
      hex: '#0C2340',
      rgba: 'rgba(12, 35, 64, 1)',
      rgba10: 'rgba(20, 58, 107, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    COL: {
      state: 'Colorado',
      team: 'Rockies',
      hex: '#33006F',
      rgba: 'rgba(51, 0, 111, 1)',
      rgba10: 'rgba(74, 0, 162, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    DET: {
      state: 'Detroit',
      team: 'Tigers',
      hex: '#0C2340',
      rgba: 'rgba(12, 35, 64, 1)',
      rgba10: 'rgba(20, 58, 107, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    HOU: {
      state: 'Houston',
      team: 'Astros',
      hex: '#002D62',
      rgba: 'rgba(0, 45, 98, 1)',
      rgba10: 'rgba(0, 68, 149, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    KC: {
      state: 'Kansas City',
      team: 'Royals',
      hex: '#004687',
      rgba: 'rgba(0, 70, 135, 1)',
      rgba10: 'rgba(0, 96, 186, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    LAA: {
      state: 'Los Angeles',
      team: 'Angels',
      hex: '#003263',
      rgba: 'rgba(0, 50, 99, 1)',
      rgba10: 'rgba(0, 76, 150, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    LAD: {
      state: 'Los Angeles',
      team: 'Dodgers',
      hex: '#005A9C',
      rgba: 'rgba(0, 90, 156, 1)',
      rgba10: 'rgba(0, 119, 207, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    MIA: {
      state: 'Miami',
      team: 'Marlins',
      hex: '#00A3E0',
      rgba: 'rgba(0, 163, 224, 1)',
      rgba10: 'rgba(20, 191, 255, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    MIL: {
      state: 'Milwaukee',
      team: 'Brewers',
      hex: '#FFC52F',
      rgba: 'rgba(255, 197, 47, 1)',
      rgba10: 'rgba(255, 211, 98, 1)',
      text: 'rgba(0, 0, 0, 1)',
    },
    MIN: {
      state: 'Minnesota',
      team: 'Twins',
      hex: '#002B5C',
      rgba: 'rgba(0, 43, 92, 1)',
      rgba10: 'rgba(0, 67, 143, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    NYM: {
      state: 'New York',
      team: 'Mets',
      hex: '#002D72',
      rgba: 'rgba(0, 45, 114, 1)',
      rgba10: 'rgba(0, 65, 165, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    NYY: {
      state: 'New York',
      team: 'Yankees',
      hex: '#003087',
      rgba: 'rgba(0, 48, 135, 1)',
      rgba10: 'rgba(0, 66, 186, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    OAK: {
      state: 'Oakland',
      team: 'Athletics',
      hex: '#003831',
      rgba: 'rgba(0, 56, 49, 1)',
      rgba10: 'rgba(0, 107, 94, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    PHI: {
      state: 'Philadelphia',
      team: 'Phillies',
      hex: '#E81828',
      rgba: 'rgba(232, 24, 40, 1)',
      rgba10: 'rgba(237, 70, 83, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    PIT: {
      state: 'Pittsburgh',
      team: 'Pirates',
      hex: '#27251F',
      rgba: 'rgba(39, 37, 31, 1)',
      rgba10: 'rgba(67, 64, 54, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    STL: {
      state: 'St. Louis',
      team: 'Cardinals',
      hex: '#C41E3A',
      rgba: 'rgba(196, 30, 58, 1)',
      rgba10: 'rgba(224, 53, 82, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    SD: {
      state: 'San Diego',
      team: 'Padres',
      hex: '#2F241D',
      rgba: 'rgba(47, 36, 29, 1)',
      rgba10: 'rgba(79, 60, 48, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    SF: {
      state: 'San Francisco',
      team: 'Giants',
      hex: '#FD5A1E',
      rgba: 'rgba(253, 90, 30, 1)',
      rgba10: 'rgba(253, 127, 81, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    SEA: {
      state: 'Seattle',
      team: 'Mariners',
      hex: '#0C2C56',
      rgba: 'rgba(12, 44, 86, 1)',
      rgba10: 'rgba(18, 67, 131, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    TB: {
      state: 'Tampa Bay',
      team: 'Rays',
      hex: '#092C5C',
      rgba: 'rgba(9, 44, 92, 1)',
      rgba10: 'rgba(14, 66, 138, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    TEX: {
      state: 'Texas',
      team: 'Rangers',
      hex: '#003278',
      rgba: 'rgba(0, 50, 120, 1)',
      rgba10: 'rgba(0, 71, 171, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    TOR: {
      state: 'Toronto',
      team: 'Blue Jays',
      hex: '#134A8E',
      rgba: 'rgba(19, 74, 142, 1)',
      rgba10: 'rgba(25, 97, 187, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    WAS: {
      state: 'Washington',
      team: 'Nationals',
      hex: '#AB0003',
      rgba: 'rgba(171, 0, 3, 1)',
      rgba10: 'rgba(222, 0, 4, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
    WSH: {
      state: 'Washington',
      team: 'Nationals',
      hex: '#AB0003',
      rgba: 'rgba(171, 0, 3, 1)',
      rgba10: 'rgba(222, 0, 4, 1)',
      text: 'rgba(255, 255, 255, 1)',
    },
  },
  nba: {
    ATL: {
      name: 'Atlanta Hawks',
      hex: '#E03A3E',
      rgba: 'rgba(225, 68, 52, 1)',
      rgba10: 'rgba(232, 109, 96, 1)',
    },
    BOS: {
      name: 'Boston Celtics',
      hex: '#007A33',
      rgba: 'rgba(0, 122, 51)',
      rgba10: 'rgba(0, 173, 72, 1)',
    },
    BKN: {
      name: 'Brooklyn Nets',
      hex: '#000000',
      rgba: 'rgba(0, 0, 0)',
      rgba10: 'rgba(26, 26, 26, 1)',
    },
    CHA: {
      name: 'Charlotte Hornets',
      hex: '#1D1160',
      rgba: 'rgba(29, 17, 96)',
      rgba10: 'rgba(42, 25, 139, 1)',
    },
    CHI: {
      name: 'Chicago Bulls',
      hex: '#CE1141',
      rgba: 'rgba(206, 17, 65)',
      rgba10: 'rgba(237, 37, 88, 1)',
    },
    CLE: {
      name: 'Cleveland Cavaliers',
      hex: '#860038',
      rgba: 'rgba(134, 0, 56)',
      rgba10: 'rgba(185, 0, 77, 1)',
    },
    DAL: {
      name: 'Dallas Mavericks',
      hex: '#00538C',
      rgba: 'rgba(0, 83, 188)',
      rgba10: 'rgba(0, 106, 239, 1)',
    },
    DEN: {
      name: 'Denver Nuggets',
      hex: '#0E2240',
      rgba: 'rgba(13, 34, 64)',
      rgba10: 'rgba(22, 57, 106, 1)',
    },
    DET: {
      name: 'Detroit Pistons',
      hex: '#C8102E',
      rgba: 'rgba(200,16,46)',
      rgba10: 'rgba(237, 30, 64, 1)',
    },
    GSW: {
      name: 'Golden State Warriors',
      hex: '#1D428A',
      rgba: 'rgba(29, 66, 138)',
      rgba10: 'rgba(38, 86, 180, 1)',
    },
    HOU: {
      name: 'Houston Rockets',
      hex: '#CE1141',
      rgba: 'rgba(206,17,65)',
      rgba10: 'rgba(237, 37, 88, 1)',
    },
    IND: {
      name: 'Indiana Pacers',
      hex: '#002D62',
      rgba: 'rgba(0, 45, 98)',
      rgba10: 'rgba(0, 68, 149, 1)',
    },
    LAC: {
      name: 'Los Angeles Clippers',
      hex: '#C8102E',
      rgba: 'rgba(200,16,46)',
      rgba10: 'rgba(237, 30, 64, 1)',
    },
    LAL: {
      name: 'Los Angeles Lakers',
      hex: '#552583',
      rgba: 'rgba(85, 37, 130)',
      rgba10: 'rgba(111, 48, 170, 1)',
    },
    MEM: {
      name: 'Memphis Grizzlies',
      hex: '#5D76A9',
      rgba: 'rgba(93, 118, 169)',
      rgba10: 'rgba(126, 146, 187, 1)',
    },
    MIA: {
      name: 'Miami Heat',
      hex: '#98002E',
      rgba: 'rgba(152, 0, 46)',
      rgba10: 'rgba(203, 0, 61, 1)',
    },
    MIL: {
      name: 'Milwaukee Bucks',
      hex: '#00471B',
      rgba: 'rgba(0, 71, 27)',
      rgba10: 'rgba(0, 122, 46, 1)',
    },
    MIN: {
      name: 'Minnesota Timberwolves',
      hex: '#0C2340',
      rgba: 'rgba(12, 35, 64)',
      rgba10: 'rgba(20, 58, 107, 1)',
    },
    NOP: {
      name: 'New Orleans Pelicans',
      hex: '#0C2340',
      rgba: 'rgba(12, 35, 64)',
      rgba10: 'rgba(20, 58, 107, 1)',
    },
    NYK: {
      name: 'New York Knicks',
      hex: '#006BB6',
      rgba: 'rgba(0, 107, 182)',
      rgba10: 'rgba(0, 137, 233, 1)',
    },
    OKC: {
      name: 'Oklahoma City Thunder',
      hex: '#007AC1',
      rgba: 'rgba(0, 125, 195)',
      rgba10: 'rgba(0, 158, 246, 1)',
    },
    ORL: {
      name: 'Orlando Magic',
      hex: '#0077C0',
      rgba: 'rgba(0, 125, 197)',
      rgba10: 'rgba(0, 157, 248, 1)',
    },
    PHI: {
      name: 'Philadelphia 76ers',
      hex: '#006BB6',
      rgba: 'rgba(0, 107, 182)',
      rgba10: 'rgba(0, 137, 233, 1)',
    },
    PHX: {
      name: 'Phoenix Suns',
      hex: '#1D1160',
      rgba: 'rgba(29, 17, 96)',
      rgba10: 'rgba(42, 25, 139, 1)',
    },
    POR: {
      name: 'Portland Trail Blazers',
      hex: '#E03A3E',
      rgba: 'rgba(224, 58, 62)',
      rgba10: 'rgba(231, 102, 105, 1)',
    },
    SAC: {
      name: 'Sacramento Kings',
      hex: '#5A2D81',
      rgba: 'rgba(91,43,130)',
      rgba10: 'rgba(118, 56, 168, 1)',
    },
    SAS: {
      name: 'San Antonio Spurs',
      hex: '#000000',
      rgba: 'rgba(6, 25, 34)',
      rgba10: 'rgba(26, 26, 26, 1)',
    },
    TOR: {
      name: 'Toronto Raptors',
      hex: '#CE1141',
      rgba: 'rgba(206,17,65)',
      rgba10: 'rgba(237, 37, 88, 1)',
    },
    UTA: {
      name: 'Utah Jazz',
      hex: '#002B5C',
      rgba: 'rgba(0, 43, 92)',
      rgba10: 'rgba(0, 67, 143, 1)',
    },
    WAS: {
      name: 'Washington Wizards',
      hex: '#002B5C',
      rgba: 'rgba(0, 43, 92)',
      rgba10: 'rgba(0, 67, 143, 1)',
    },
  },
  nhl: {
    SEA: {
      fullName: 'Seattle Kraken',
      name: 'Kraken',
      hex: '#001628',
      rgba: 'rgba(0, 22, 40, 1)',
      rgba10: 'rgba(0, 47, 87, 1)',
    },
    VGK: {
      fullName: 'Vegas Golden Knights',
      name: 'Golden Knights',
      hex: '#B4975A',
      rgba: 'rgba(180, 151, 90, 1)',
      rgba10: 'rgba(196, 173, 125, 1)',
    },
    LA: {
      fullName: 'Los Angeles Kings',
      name: 'Kings',
      hex: '#111111',
      rgba: 'rgba(17, 17, 17, 1)',
      rgba10: 'rgba(42, 42, 42, 1)',
    },
    ARI: {
      fullName: 'Arizona Coyotes',
      name: 'Coyotes',
      hex: '#8C2633',
      rgba: 'rgba(140, 38, 51, 1)',
      rgba10: 'rgba(180, 49, 66, 1)',
    },
    SJ: {
      fullName: 'San Jose Sharks',
      name: 'Sharks',
      hex: '#006D75',
      rgba: 'rgba(0, 109, 117, 1)',
      rgba10: 'rgba(0, 157, 168, 1)',
    },
    DAL: {
      fullName: 'Dallas Stars',
      name: 'Stars',
      hex: '#006847',
      rgba: 'rgba(0, 104, 71, 1)',
      rgba10: 'rgba(0, 155, 106, 1)',
    },
    CGY: {
      fullName: 'Calgary Flames',
      name: 'Flames',
      hex: '#C8102E',
      rgba: 'rgba(200, 16, 46, 1)',
      rgba10: 'rgba(237, 30, 64, 1)',
    },
    VAN: {
      fullName: 'Vancouver Canucks',
      name: 'Canucks',
      hex: ' #00205B',
      rgba: 'rgba(0, 32, 91, 1)',
      rgba10: 'rgba(0, 50, 142, 1)',
    },
    COL: {
      fullName: 'Colorado Avalanche',
      name: 'Avalanche',
      hex: '#6F263D',
      rgba: 'rgba(111, 38, 61, 1)',
      rgba10: 'rgba(149, 51, 82, 1)',
    },
    EDM: {
      fullName: 'Edmonton Oilers',
      name: 'Oilers',
      hex: '#041E42',
      rgba: 'rgba(4, 30, 66, 1)',
      rgba10: 'rgba(7, 52, 114, 1)',
    },
    MIN: {
      fullName: 'Minnesota Wild',
      name: 'Wild',
      hex: '#A6192E',
      rgba: 'rgba(166, 25, 46, 1)',
      rgba10: 'rgba(210, 32, 58, 1)',
    },
    CHI: {
      fullName: 'Chicago Blackhawks',
      name: 'Blackhawks',
      hex: '#CF0A2C',
      rgba: 'rgba(207, 10, 44, 1)',
      rgba10: 'rgba(244, 24, 62, 1)',
    },
    NSH: {
      fullName: 'Nashville Predators',
      name: 'Predators',
      hex: '#FFB81C',
      rgba: 'rgba(255, 184, 28, 1)',
      rgba10: 'rgba(0, 137, 233, 1)',
    },
    STL: {
      fullName: 'St. Louis Blues',
      name: 'Blues',
      hex: '#002F87',
      rgba: 'rgba(0, 47, 135, 1)',
      rgba10: 'rgba(0, 65, 186, 1)',
    },
    CBJ: {
      fullName: 'Columbus Blue Jackets',
      name: 'Blue Jackets',
      hex: '#002654',
      rgba: 'rgba(0, 38, 84, 1)',
      rgba10: 'rgba(0, 61, 135, 1)',
    },
    DET: {
      fullName: 'Detroit Red Wings',
      name: 'Red Wings',
      hex: '#CE1126',
      rgba: 'rgba(206, 17, 38, 1)',
      rgba10: 'rgba(237, 37, 59, 1)',
    },
    BOS: {
      fullName: 'Nashville Predators',
      name: 'Bruins',
      hex: ' #FFB81C',
      rgba: 'rgba(255, 184, 28, 1)',
      rgba10: 'rgba(255, 200, 79, 1)',
    },
    BUF: {
      fullName: 'Buffalo Sabres',
      name: 'Sabres',
      hex: '#002654',
      rgba: 'rgba(0, 38, 84, 1)',
      rgba10: 'rgba(0, 61, 135, 1)',
    },
    OTT: {
      fullName: 'Ottawa Senators',
      name: 'Senators',
      hex: '#C52032',
      rgba: 'rgba(197, 32, 50, 1)',
      rgba10: 'rgba(223, 57, 75, 1)',
    },

    MTL: {
      fullName: 'Montreal Canadiens',
      name: 'Canadiens',
      hex: '#AF1E2D',
      rgba: 'rgba(175, 30, 45, 1)',
      rgba10: 'rgba(218, 38, 57, 1)',
    },
    TOR: {
      fullName: 'Toronto Maple Leafs',
      name: 'Maple Leafs',
      hex: '#00205B',
      rgba: 'rgba(0, 32, 91, 1)',
      rgba10: 'rgba(0, 50, 142, 1)',
    },
    NJ: {
      fullName: 'New Jersey Devils',
      name: 'Devils',
      hex: '#CE1126',
      rgba: 'rgba(206, 17, 38, 1)',
      rgba10: 'rgba(237, 37, 59, 1)',
    },
    NYI: {
      fullName: 'New York Islanders',
      name: 'Islanders',
      hex: '#00539B',
      rgba: 'rgba(0, 83, 155, 1)',
      rgba10: 'rgba(0, 110, 206, 1)',
    },
    NYR: {
      fullName: 'New York Rangers',
      name: 'Rangers',
      hex: '#0038A8',
      rgba: 'rgba(0, 56, 168, 1)',
      rgba10: 'rgba(0, 73, 219, 1)',
    },
    PHI: {
      fullName: 'Philadelphia Flyers',
      name: 'Flyers',
      hex: '#F74902',
      rgba: 'rgba(247, 73, 2, 1)',
      rgba10: 'rgba(253, 107, 47, 1)',
    },
    PIT: {
      fullName: 'Pittsburgh Penguins',
      name: 'Penguins',
      hex: '#000000',
      rgba: 'rgba(0, 0, 0, 1)',
      rgba10: 'rgba(26, 26, 26, 1)',
    },
    TB: {
      fullName: 'Tampa Bay Lightning',
      name: 'Lightning',
      hex: '#002868',
      rgba: 'rgba(0, 40, 104, 1)',
      rgba10: 'rgba(0, 60, 155, 1)',
    },
    WSH: {
      fullName: 'Washington Capitals',
      name: 'Capitals',
      hex: '#041E42',
      rgba: 'rgba(4, 30, 66, 1)',
      rgba10: 'rgba(7, 52, 114, 1)',
    },
    WPG: {
      fullName: 'Winnipeg Jets',
      name: 'Jets',
      hex: '#041E42',
      rgba: 'rgba(4, 30, 66, 1)',
      rgba10: 'rgba(7, 52, 114, 1)',
    },
    CAR: {
      fullName: 'Carolina Hurricanes',
      name: 'Hurricanes',
      hex: '#CC0000',
      rgba: 'rgba(204, 0, 0, 1)',
      rgba10: 'rgba(255, 0, 0, 1)',
    },
    FLA: {
      fullName: 'Florida Panthers',
      name: 'Panthers',
      hex: '#041E42',
      rgba: 'rgba(4, 30, 66, 1)',
      rgba10: 'rgba(7, 52, 114, 1)',
    },
    ANA: {
      fullName: 'Anaheim Ducks',
      name: 'Ducks',
      hex: '#F47A38',
      rgba: 'rgba(244, 122, 56, 1)',
      rgba10: 'rgba(247, 154, 104, 1)',
    },
  },
};
const playerProgressStatus = {
  selected: {
    color: COLORS.marineBlue,
    progress: 1,
  },
  pending: {
    color: COLORS.marineBlue,
    borderColor: COLORS.lightGray,
    progress: 0,
    inactiveText: true,
  },
  live: {
    color: COLORS.green,
    progress: 0,
    live: true,
    active: true,
    background: COLORS.green,
  },
  half: {
    color: COLORS.appBlue,
    live: true,
    active: false,
    progress: 0.5,
  },
  finished: {
    color: COLORS.brownGrey,
    progress: 1,
    inactiveText: true,
  },
  default: {
    borderColor: 'rgba(0,0,0,0)',
    progress: 0,
    live: false,
  },
};

export const getPlayerStatus = gameTime => {
  if (!gameTime || !gameTime.status) {
    return playerProgressStatus.default;
  }

  return playerProgressStatus[gameTime.status];
};
