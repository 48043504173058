import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const signUpManual = async data => {
  let OsType = '';
  if (navigator.appVersion.indexOf('Win') !== -1) OsType = 'Windows OS';
  if (navigator.appVersion.indexOf('Mac') !== -1) OsType = 'MacOS';
  if (navigator.appVersion.indexOf('X11') !== -1) OsType = 'UNIX OS';
  if (navigator.appVersion.indexOf('Linux') !== -1) OsType = 'Linux OS';

  const header = {
    ...APP_CONSTANTS.HEADERS,
    DeviceId: Date.now(),
    OsType,
    IsVirtual: false,
    OsVersion: 'browser',
    Resolution: 'browser',
    AppVersion: 'browser',
    DeviceMake: 'browser',
    DeviceModel: 'browser',
  };
  const response = await NetworkManager.requestPOST(API.REGISTER, data, header);
  return response;
};
