import React, { useState } from 'react';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { GameRules } from '../GameRules';
import { GameScoringRules } from '../GameRules/GameScoringRules';

const SETTING_OPTION = {
  ACCOUNT: 'ACCOUNT',
  ACCOUNT_CHANGE_EMAIL: 'ACCOUNT_CHANGE_EMAIL',
  ACCOUNT_CHANGE_PASS: 'ACCOUNT_CHANGE_PASS',
  RESPONSIBLE_GAMING: 'RESPONSIBLE_GAMING',
  RESPONSIBLE_GAMING_SELF_EXCLUSION: 'RESPONSIBLE_GAMING_SELF_EXCLUSION',
  RESPONSIBLE_GAMING_ADD_INFO: 'RESPONSIBLE_GAMING_ADD_INFO',
  RULES: 'RULES',
  RULES_SCORING: 'RULES_SCORING',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TUTORIAL: 'TUTORIAL',
};
export const HowToPlay = ({ history }) => {
  const [selectedSetting, setSelectedSetting] = useState(SETTING_OPTION.RULES);
  const [gameScoreRule, setGameScoreRule] = useState('');

  return (
    <main className="App__main">
      <div className="headSection">
        <div className="headSection__contain">
          <h2 className="headSection__title">How to Play</h2>
        </div>
      </div>
      <div className="gridSection">
        <div className="gridSection__fullWidth">
          <div className="settingsInner">
            <div className="cols2">
              {selectedSetting === SETTING_OPTION.RULES && (
                <GameRules
                  history={history}
                  showBack={false}
                  onGameScoreRule={settingOption => {
                    setSelectedSetting(SETTING_OPTION.RULES_SCORING);
                    setGameScoreRule(settingOption);
                  }}
                />
              )}
              {selectedSetting === SETTING_OPTION.RULES_SCORING && (
                <GameScoringRules
                  history={history}
                  match={{ params: { scoringGame: gameScoreRule } }}
                  onBack={() => setSelectedSetting(SETTING_OPTION.RULES)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
