import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const requestEmailVerification = async data => {
  const url = API.REQUEST_EMAIL_VERIFICATION;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
/* export const authenticateEmailVerification = async data => {
  const url = API.AUTHENTICATE_EMAIL_VERIFICATION;
  let finalUrl = `${url}/${data.method}/${data.token}`;
  if (data.phoneNo) {
    finalUrl = `${url}/${data.method}/${data.token}/${data.phoneNo}`;
  }
  const response = await NetworkManager.requestGET(finalUrl);

  return response;
}; */
export const authenticateEmailVerification = async data => {
  const url = API.AUTHENTICATE_EMAIL_VERIFICATION;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const requestPhoneVerification = async data => {
  const url = API.REQUEST_PHONE_VERIFICATION;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const authenticatePhoneVerification = async data => {
  const url = API.AUTHENTICATE_PHONE_VERIFICATION;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const updateDob = async data => {
  const url = API.UPDATE_DOB;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const getDraftUser = async data => {
  const url = API.GET_DRAFT_USER;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const updateLocation = async data => {
  const url = API.UPDATE_LOCATION;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const getExternalUser = async data => {
  const url = API.GET_EXTERNAL_USER;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const checkExternalUserWithSystem = async data => {
  const url = API.CHECK_EXTERNAL_USER_WITH_SYSTEM;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
export const authenticatePhoneSignupVerification = async data => {
  const url = API.AUTHENTICATE_PHONE_SIGNUP_VERIFICATION;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
