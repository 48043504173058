import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const draftLineupAction = async lineupData => {
  const url = API.USER_LINEUP;
  const response = await NetworkManager.requestAuthPOST(url, lineupData);
  return response;
};

export const draftLineupUpdate = async (draftLineupId, lineupData) => {
  const url = `${API.USER_LINEUP}/${draftLineupId}`;
  const response = await NetworkManager.requestAuthPUT(url, lineupData);
  return response;
};

export const draftUserLineups = async () => {
  const url = `${API.USER_LINEUP}/`;
  const response = await NetworkManager.requestAuthGET(url);
  return response;
};

export const draftLineupDelete = async draftLineupId => {
  const url = `${API.USER_LINEUP}/${draftLineupId}`;
  const response = await NetworkManager.requestAuthDELETE(url);
  return response;
};
