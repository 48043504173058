/* eslint-disable max-len */
import { getContest } from 'Actions/sport-action';
import React, { useEffect, useState, useContext } from 'react';
import { LineUpContext } from 'Components/LineUpScreens/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import { setInSessionStorage, getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import {
  contestImages,
  gameName,
} from 'Components/LineUpScreens/SportData/sportsObject';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { Button, Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { draftLineupAction } from 'Actions/draft-lineup-action';
import { Loader } from 'Components/Loader';
// import loader from 'sass-loader';

export const NewGame = () => {
  const history = useHistory();
  const { contextDispatch } = useContext(LineUpContext);
  const [contests, setContests] = useState([]);
  const [selectedGameIndex, setSelectedGameIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(true);
  const [content, setContent] = useState('');
  const [enableScroll, setEnableScroll] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setContent('');
  };
  const [showText, setShowText] = useState(false);

  const startLoader = () => {
    setToggleLoader(true);
  };

  const stopLoader = () => {
    setToggleLoader(false);
  };
  useEffect(() => {
    startLoader();
    getContest()
      .then(({ data }) => {
        setContests(data.contests || []);
        if (!data.contests) {
          setShowText(true);
        }
        contextDispatch({
          type: ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE,
          payload: null,
        });
        stopLoader();
      })
      .catch(() => {
        // API error handling
        stopLoader();
      });
  }, []);

  useEffect(() => {
    if (enableScroll) {
      document.documentElement.classList.remove('u-overflowHidden');
    } else {
      document.documentElement.classList.add('u-overflowHidden');
    }
  }, [enableScroll]);
  const handleClick = () => {
    setEnableScroll(true);
    const draftLineupData = {
      entryFee: 0,
      currencyIsoCode: 'USD',
      // eslint-disable-next-line no-underscore-dangle
      contestId: contests[selectedGameIndex]._id,
      selectedNumber: 0,
      isDrafted: true,
    };
    const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
    if (token !== '') {
      mixPanelTrackEvent('contest selected', contests[selectedGameIndex]);
      draftLineupAction(draftLineupData)
        .then(({ data }) => {
          mixpanelTrackPageView('Contest Selection');
          setInSessionStorage(
            APP_CONSTANTS.SPORTS_KEY.CONTEST,
            contests[selectedGameIndex],
          );
          setInSessionStorage(APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST, data);
          history.push({ pathname: '/line-up' });
        })
        .catch(err => {
          if (
            err.response.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.PERMANENTLY_REMOVED
          ) {
            setModal(!modal);
            setContent('PERMANENTLY_REMOVED');
          } else if (
            err.response.status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN
          ) {
            setModal(!modal);
            setContent('FORBIDDEN');
          }
        });
    } else {
      mixpanelTrackPageView('Contest Selection');
      setInSessionStorage(
        APP_CONSTANTS.SPORTS_KEY.CONTEST,
        contests[selectedGameIndex],
      );
      history.push({ pathname: '/line-up' });
    }
  };

  return (
    <div className="newGame">
      <Modal isOpen={modal} toggle={modal}>
        <ModalHeader toggle={modal}>Draft Lineup Error</ModalHeader>
        <ModalBody>{content}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div className="sliderTitle">Sport</div>
      <div className="sliderWrap text-center">
        {contests.length !== 0 && (
          <Slider
            afterChange={setSelectedGameIndex}
            className={`
          slider selectSport ${contests.length === 1 && 'singleItem'}
        `}
            dots={false}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: false,
                  dots: false,
                },
              },
            ]}
            centerMode
            variableWidth
            infinite={false}
            focusOnSelect
            arrows={false}
            slidesToShow={1}
            slidesToScroll={1}
            centerPadding="23px">
            {contests.length &&
              contests.map(sport => {
                /* eslint no-underscore-dangle: 0 */
                /* if (
                  sport.sportSlug &&
                  sport.sportSlug.toUpperCase() === 'NHL'
                ) {
                  return (
                    <div key={sport._id}>
                      <div className="imgWrap imgWrap3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="auto"
                          width="101"
                          style={
                            ({ maxWidth: '101px' }, { marginTop: '-12px' })
                          }>
                          <use
                            xlinkHref={`${
                              contestImages[sport.sportSlug]
                            }#i-nhl`}
                          />
                        </svg>
                      </div>
                      <div className="smallHeading">
                        {' '}
                        {(sport.sportSlug && sport.sportSlug.toUpperCase()) ||
                          ''}
                      </div>
                      <div className="largeHeading">
                        {gameName[sport.sportSlug] || ''}
                      </div>
                      <input
                        type="radio"
                        className="hiddenRadio"
                        name="exampleRadios"
                        id="exampleRadios1"
                        defaultValue="option1"
                        defaultChecked
                      />
                    </div>
                  );
                } */

                return (
                  <div key={sport._id}>
                    <div className="imgWrap imgWrap3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="auto"
                        width="101"
                        style={({ maxWidth: '101px' }, { marginTop: '0px' })}>
                        <use
                          xlinkHref={`${contestImages.svg}#i-${sport.sportSlug}`}
                        />
                      </svg>
                    </div>
                    <div className="smallHeading">
                      {' '}
                      {(sport.sportSlug && sport.sportSlug.toUpperCase()) || ''}
                    </div>
                    <div className="largeHeading">
                      {gameName[sport.sportSlug] || ''}
                    </div>
                    <input
                      type="radio"
                      className="hiddenRadio"
                      name="exampleRadios"
                      id="exampleRadios1"
                      defaultValue="option1"
                      defaultChecked
                    />
                  </div>
                );
              })}
          </Slider>
        )}
        {showText &&
          'There are no contests currently available. Check back soon.'}
      </div>
      {toggleLoader && <Loader />}
      {/* <div className="sliderTitle">Filter by matchup</div>
      <div className="filterLargeHeading">
        All
        <br />
        matchups
      </div> */}

      <button
        type="button"
        className="btn btn-primary notFixed"
        onClick={handleClick}
        disabled={!contests.length}>
        Select Lineup
      </button>
    </div>
  );
};
