/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Slider from 'react-slick';

import { clearSessionStorage } from 'Utils/storage-utilities';
import { Button } from 'Components/Form-Elements/Button';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { detectDevice } from 'Utils/device-utils';
import icon from '../../assets/icons/icons.svg';

export const LandingPageNew = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const isMobile = detectDevice();

  const history = useHistory();

  const routeChange = () => {
    const path = `/signup`;
    history.push(path);
  };
  const anotherRoute = () => {
    const path = `/login`;
    history.push(path);
  };
  const emailSignup = () => {
    const path = `/m-signup-email`;
    history.push(path);
  };

  useEffect(() => {
    clearSessionStorage();
  }, []);

  // if (isMobile) {
  return (
    <>
      <div className="ffScreen isLanding hasSlickCarousel">
        <div className="ffScreen__body justify-start">
          <div className="logoNew">
            <img src="/favicon/DN_web-icon_57.png" alt="Upside" />
            <h2>Upside</h2>
          </div>
          <div className="slickCarouselFFScreeen">
            <Slider {...settings}>
              <div>
                <div className="fpoGraphics">
                  <div className="fpoGraphics__content">
                    <h1 className="fpoGraphics__title">FPO</h1>
                    <h2 className="fpoGraphics__copy">Graphic</h2>
                  </div>
                </div>
                <h1 className="title">Enter your email</h1>
                <p className="copy">
                  It’s all about our star ratings. No salary caps. We pay more
                  for a lineup of sleepers over a squad of all-stars.
                </p>
              </div>
              <div>
                <div className="fpoGraphics">
                  <div className="fpoGraphics__content">
                    <h1 className="fpoGraphics__title">FPO</h1>
                    <h2 className="fpoGraphics__copy">Graphic</h2>
                  </div>
                </div>
                <h1 className="title">Beat the Daily Number</h1>
                <p className="copy">
                  Beat the Daily Number! That’s the name of the game. Now make
                  your way to the winner’s circle.
                </p>
              </div>
              <div>
                <div className="fpoGraphics">
                  <div className="fpoGraphics__content">
                    <h1 className="fpoGraphics__title">FPO</h1>
                    <h2 className="fpoGraphics__copy">Graphic</h2>
                  </div>
                </div>
                <h1 className="title">Win!</h1>
                <p className="copy">
                  Congratulations your excellency-it feels good to be king (or
                  queen).
                </p>
              </div>
            </Slider>
          </div>
        </div>
        <div className="ffScreen__footer">
          <button
            className="button button-full button-white"
            type="button"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LANDING_PAGE, {
                action: 'Let’s do this',
              });
              emailSignup(`/login`);
            }}>
            Let’s do this
          </button>
          <div className="haveAccount">
            <p className="haveAccount__copy">Already have an account?</p>
            <button
              className="haveAccount__button button"
              type="button"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LANDING_PAGE, {
                  action: 'Log in',
                });
                anotherRoute();
              }}>
              Log in
            </button>
          </div>
        </div>
      </div>
    </>
  );
  // }

  /* return (
    <>
      <div className="logo" />
      <div className="landingWrapper">
        <h1>Set Your Lineup, and Game On!</h1>
        <span className="subLine">A Daily Fantasy Sports game changer.</span>
        <Button
          className="btn btn-transparent"
          onClick={() => {
            mixPanelTrackEvent(
              MIXPANEL_EVENTS_CONSTANTS.LOGGED_OUT_SPLASH_SCREEN,
              { pageViewed: 'More About Daily Number' },
            );
            window.open('https://dailynumber.app/', '_blank');
          }}
          label="More about Daily Number"
        />
        <Link
          to="/location"
          className="btn btn-white"
          onClick={() => {
            mixPanelTrackEvent(
              MIXPANEL_EVENTS_CONSTANTS.LOGGED_OUT_SPLASH_SCREEN,
              { pageViewed: 'Sign Up' },
            );
          }}>
          Sign Up
        </Link>
        <div className="d-flex justify-content-center forgotLink">
          <span className="signupLink">
            Already have an account?
            <Link
              to="/login"
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.LOGGED_OUT_SPLASH_SCREEN,
                  { pageViewed: 'Login' },
                );
              }}>
              {' '}
              Log In
            </Link>
          </span>
        </div>
      </div>
    </>
  ); */
};
