import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  getResposiblePlayLimits,
  submitResposiblePlayLimits,
} from 'Actions/responsible-gaming-action';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { ARRAY_CONST } from 'Constants/array-constants';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { ModalBox } from 'Components/ModalBox';
import { Button } from 'Components/Form-Elements/Button';
import { Loader } from 'Components/Loader';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ResponsibleGaming = ({
  history,
  showBack = true,
  onSelfExclusion,
  onAdditionalInfo,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [responsiblePlayLimits, setResponsiblePlayLimits] = useState({});
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);

  useEffect(() => {
    if (modalBoxName.length) setToggleModalBox(true);
  }, [modalBoxName]);

  const {
    RESPONSIBLE_GAMING: {
      MAX_MONTHLY_DEPOSIT,
      MAX_WEEKLY_CONTEST,
      WEEKLY_CONTEST_ALERT,
      MAX_CONTEST_ENTRY_FEE,
      SELF_EXCLUSION_REQUEST,
      NEED_ADDITIONAL_HELP,
    },
  } = MESSAGE_CONSTANTS;

  const setResposiblePlayLimits = () => {
    getResposiblePlayLimits()
      .then(({ data }) => {
        setIsLoading(false);
        if (data) {
          setResponsiblePlayLimits(data);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setModalBoxName(APP_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR);
      });
  };

  useEffect(() => {
    setResposiblePlayLimits();
    mixpanelTrackPageView('Responsible Gaming Screen');
  }, []);

  const submitPlayLimits = (data = {}) => {
    submitResposiblePlayLimits(data)
      .then(({ data }) => {
        setIsLoading(false);
        if (data === null) {
          setResposiblePlayLimits();
        } else {
          setModalBoxName(APP_CONSTANTS.MODALBOX.CANNOT_UPDATE_SAME_VAL);
        }
      })
      .catch(err => {
        setIsLoading(false);
        setModalBoxName(APP_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR);
      });
  };

  const onValChange = (currVal, prevVal, objectKey) => {
    setIsLoading(true);
    if (currVal !== prevVal) {
      submitPlayLimits([{ [objectKey]: Number(currVal) }]);
    } else {
      setIsLoading(false);
      setModalBoxName(APP_CONSTANTS.MODALBOX.CANNOT_UPDATE_SAME_VAL_TWICE);
    }
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.RESPONSIBLE_PLAY_LIMIT_ERR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.CANNOT_UPDATE_SAME_VAL) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.CANNOT_UPDATE_SAME_VAL;
    } else if (
      modalBoxName === APP_CONSTANTS.MODALBOX.CANNOT_UPDATE_SAME_VAL_TWICE
    ) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.CANNOT_UPDATE_SAME_VAL_TWICE;
    }
    return modalbox;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const renderDrowndown = (content, limit, key, desc, arr) => {
    return (
      <div className="responsibleGamingInfo">
        <div className="aboutResponsibleGaming d-flex">
          <div className="maximumLimits colLeft">{content}</div>
          <div className="colRight">
            <UncontrolledDropdown className="selectedAmount">
              <DropdownToggle caret>{limit || arr[0]}</DropdownToggle>
              <DropdownMenu className="amountDropdown">
                {arr.map(item => (
                  <DropdownItem
                    key={`${item}-${key}`}
                    value={item}
                    onClick={e =>
                      onValChange(
                        e.target.value,
                        responsiblePlayLimits.depositLimit,
                        key,
                      )
                    }>
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div className="gamingShortInfo">{desc}</div>
      </div>
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="responsibleGamingWrapper">
        {toggleModalBox && (
          <ModalBox
            modalBoxDetails={modalBoxDetail()}
            okButtonHandler={processHandler}
            okButtonText="Ok"
            showBothButtons={false}
          />
        )}
        <div className="pageHeaderWrap">
          <div className="d-flex pageHeader">
            {showBack && (
              <div className="leftSide">
                <Link
                  to="#"
                  className="arrowBack"
                  onClick={() => history.push('/settings')}
                />
              </div>
            )}
            <h2>Responsible Gaming</h2>
            <div className="rightSide" />
          </div>
        </div>
        <div className="responsibleGamingContent">
          <p>{MESSAGE_CONSTANTS.RESPONSIBLE_GAMING.DESC}</p>
        </div>
        {/* {renderDrowndown(
          MAX_MONTHLY_DEPOSIT.CONTENT,
          responsiblePlayLimits.depositLimit,
          'depositLimit',
          MAX_MONTHLY_DEPOSIT.DESC,
          ARRAY_CONST.DEPOSIT_LIMIT,
        )}
        {renderDrowndown(
          MAX_WEEKLY_CONTEST.CONTENT,
          responsiblePlayLimits.entryLimit,
          'entryLimit',
          MAX_WEEKLY_CONTEST.DESC,
          ARRAY_CONST.ENTRY_LIMIT,
        )}
        {renderDrowndown(
          WEEKLY_CONTEST_ALERT.CONTENT,
          responsiblePlayLimits.entryAlert,
          'entryLimit',
          WEEKLY_CONTEST_ALERT.DESC,
          ARRAY_CONST.ENTRY_ALERT,
        )}
        {renderDrowndown(
          MAX_CONTEST_ENTRY_FEE.CONTENT,
          responsiblePlayLimits.entryFeeLimit,
          'entryFeeLimit',
          MAX_CONTEST_ENTRY_FEE.DESC,
          ARRAY_CONST.ENTRY_FEE_LIMIT,
        )} */}
        <div className="gamingAccountInfo">
          <ul>
            <li className="aboutResponsibleGaming d-flex">
              <div className="infoWrap flex-grow-1">
                <div className="maximumLimits flex-grow-1">
                  {SELF_EXCLUSION_REQUEST.CONTENT}
                </div>
                <div className="accountAdditionalInfo">
                  <span>{SELF_EXCLUSION_REQUEST.DESC}</span>
                </div>
              </div>
              <Button
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING,
                    {
                      action: 'Next Arrow Button',
                    },
                  );
                  if (typeof onSelfExclusion === 'function') {
                    onSelfExclusion();
                  } else {
                    history.push({ pathname: '/self-exclusion' });
                  }
                }}
                type="button"
                className="arrowLeftIcon">
                arrrowleftIcon
              </Button>
            </li>
            <li className="aboutResponsibleGaming d-flex">
              <div>
                <div className="maximumLimits flex-grow-1">
                  {NEED_ADDITIONAL_HELP.CONTENT}
                </div>
                <div className="accountAdditionalInfo">
                  {NEED_ADDITIONAL_HELP.DESC}
                </div>
              </div>
              <Button
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING,
                    {
                      action: 'Next Arrow Button',
                    },
                  );
                  if (typeof onAdditionalInfo === 'function') {
                    onAdditionalInfo();
                  } else {
                    history.push({ pathname: '/additional-info' });
                  }
                }}
                type="button"
                className="arrowLeftIcon">
                arrrowleftIcon
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
