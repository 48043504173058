import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { clearSessionStorage } from 'Utils/storage-utilities';
import { Button } from 'Components/Form-Elements/Button';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const LandingPage = () => {
  useEffect(() => {
    clearSessionStorage();
  }, []);

  return (
    <>
      <div className="logo" />
      <div className="landingWrapper">
        <h1>Set Your Lineup, and Game On!</h1>
        <span className="subLine">A Daily Fantasy Sports game changer.</span>
        <Button
          className="btn btn-transparent"
          onClick={() => {
            mixPanelTrackEvent(
              MIXPANEL_EVENTS_CONSTANTS.LOGGED_OUT_SPLASH_SCREEN,
              { pageViewed: 'More About Daily Number' },
            );
            window.open('https://dailynumber.app/', '_blank');
          }}
          label="More about Daily Number"
        />
        <Link
          to="/location"
          className="btn btn-white"
          onClick={() => {
            mixPanelTrackEvent(
              MIXPANEL_EVENTS_CONSTANTS.LOGGED_OUT_SPLASH_SCREEN,
              { pageViewed: 'Sign Up' },
            );
          }}>
          Sign Up
        </Link>
        <div className="d-flex justify-content-center forgotLink">
          <span className="signupLink">
            Already have an account?
            <Link
              to="/login"
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.LOGGED_OUT_SPLASH_SCREEN,
                  { pageViewed: 'Login' },
                );
              }}>
              {' '}
              Log In
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};
