/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import NumberFormat from 'react-number-format';
import { requestPhoneVerification } from 'Actions/new-auth-action';
import { normalize } from 'react-phone-input-auto-format';
import { Alert } from '../Alert';
import icon from '../../assets/icons/icons.svg';

export const phoneLogin = ({ history }) => {
  const [phoneNo, setPhone] = useState('');
  const [method, setMethod] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [responseError, setResponseError] = useState('');
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [alertTemplate, setAlertTemplate] = useState('red');
  const { phone, email, successMsg } = history.location.state
    ? history.location.state
    : '';

  useEffect(() => {
    if (phone) {
      setPhone(phone);
    }
    if (successMsg) {
      setResponseError(successMsg);
      setAlertTemplate('green');
      setToggleAlertBox(true);
    }
  }, [phone]);

  const verifyPhone = () => {
    const path = `/m-login-phone-verify`;
    history.push(path, { phoneNo: `+1${normalize(phoneNo)}`, email });
  };

  const back = () => {
    const path = `/login`;
    history.push(path);
  };

  const requestPhone = response => {
    requestPhoneVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          verifyPhone();
          // setToggleToNext(true);]
          /* if (buttonNext.current) {
            buttonNext.current.click();
          } */
        }
      })
      .catch(error => {
        const { status, message, code } = error.response.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const handleSubmit = () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        phoneNo: `+1${normalize(phoneNo)}`,
        method,
      },
    });
    // evt.preventDefault();
    if (!phoneNo && !method) {
      return;
    }
    const data = {
      phoneNo: `+1${normalize(phoneNo)}`,
      method,
    };
    requestPhone(data);
  };

  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button className="button button-back" onClick={back}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body justify-start">
          <h1 className="title">Enter your phone number</h1>
          <p className="copy">We’ll send you a code to log in</p>
          <div className="inputAreaWrapper">
            <div className="phoneInputArea">
              {/* <span className="countryCode">🇺🇸 +001</span> */}
              {/* <input
                className="input"
                name="phone"
                type="number"
                placeholder="(•••) ••• ••••"
                defaultValue=""
                onChange={handleChange}
              /> */}
              <NumberFormat
                id="phoneInput"
                className="input"
                format="(###) ### ####"
                mask=""
                name="phone"
                placeholder="(•••) ••• ••••"
                autoComplete="off"
                type="tel"
                value={phoneNo}
                autoFocus
                onValueChange={values => {
                  if (values.value.length === 10) {
                    setBtnDisabled(false);
                    setPhone(values.value);
                    setMethod('login');
                  } else {
                    setPhone(values.value);
                    setMethod('login');
                    setBtnDisabled(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="ffScreen__footer">
          <button
            id="submitButton"
            className="button button-full button-blue"
            disabled={btnDisabled}
            onClick={() => {
              handleSubmit();
              /* const path = `/m-login-phone-verify`;
              history.push(path, {
                phoneNo: `+880${normalize(phoneNo)}`,
                email,
              }); */
            }}>
            Continue
          </button>
          {/* <button
            id="nextPage"
            className="button button-full button-blue"
            ref={buttonNext}
            onClick={() => {
              const path = `/m-login-phone-verify`;
              history.push(path, {
                phoneNo: `+880${normalize(phoneNo)}`,
                email,
              });
            }}>
            next
          </button> */}
        </div>
      </div>
    </>
  );
};
