import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Input } from 'Components/Form-Elements/Input';
import { Button } from 'Components/Form-Elements/Button';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { chekUserExists } from 'Actions/user-exists-action';
import {
  setInSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

const API_TIMEOUT = 500;

export const UserNameVerification = ({
  history,
  isModal,
  fetchAndSetModalPage,
}) => {
  const userNameselected = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USERNAME,
  );
  const [userName, setUserName] = useState(userNameselected || '');
  const [asyncValidation, setValidity] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [enableButton, setEnableButton] = useState(false);

  function isUsernameUnique(value) {
    if (
      value.length >= APP_CONSTANTS.MIN_USERNAME_LENGTH &&
      value.length <= APP_CONSTANTS.MAX_USERNAME_LENGTH
    ) {
      chekUserExists(`username=${value}`)
        .then(response => {
          if (response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
            if (value === userName) {
              setEnableButton(true);
              setValidity('');
            }
          }
        })
        .catch(({ response }) => {
          setEnableButton(false);
          if (
            response.data.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.ALREADY_EXISTS
          ) {
            setValidity(MESSAGE_CONSTANTS.ERROR_MSG.USERNAME_EXISTS);
          } else if (
            response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
          ) {
            setValidity(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_REQUEST);
          }
        });
    }
  }

  useEffect(() => {
    setValidity('');
    setEnableButton(false);
    const timeout = setTimeout(() => {
      if (userName && isValid) {
        isUsernameUnique(userName);
      }
    }, API_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [userName]);

  const storeData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USERNAME,
      userName,
    );
    if (isModal) {
      fetchAndSetModalPage('/set-password');
      return;
    }
    history.push('/set-password');
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="65" />
          <div className="d-flex topBar">
            {!isModal ? (
              <Link to="/verify-email" className="arrowBack" />
            ) : (
              <Button
                className="linkBtn arrowBack"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_SET_USERNAME,
                    {
                      action: 'Username',
                    },
                  );
                  fetchAndSetModalPage('/verify-email');
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>
        <div className="wrapper">
          <div className="info">
            <div className="iconBig userIcon" />
            <div className="title">
              Create a <strong>username</strong>
            </div>
            <div className="description">
              {MESSAGE_CONSTANTS.VERIFY_USERNAME.DESCRIPTION}
            </div>
          </div>
          <div className="form-group">
            <Input
              inputType="text"
              textLabel="Username"
              className="form-control"
              id="verfiy-username"
              placeholder={MESSAGE_CONSTANTS.VERIFY_USERNAME.USERNAME}
              value={userName}
              handleChange={userName => {
                setUserName(userName.trim());
              }}
              validationRules={['validateUsername', 'maxUserNameLength']}
              externalError={asyncValidation}
              getValidity={setIsValid}
              onBlur={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.SIGNUP_SET_USERNAME,
                  {
                    action: 'Username',
                    formdata: { userName },
                  },
                );
              }}
            />
            <button
              type="button"
              className={`${isValid ? 'inputSuccess' : 'hide'}`}>
              cancel
            </button>
          </div>
          <Button
            className="btn btn-primary"
            disabled={!enableButton}
            label={BUTTON_LABELS.COMMON.NEXT}
            onClick={() => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.SIGNUP_SET_USERNAME,
                {
                  action: 'Next',
                  formdata: { userName },
                },
              );
              storeData();
            }}
          />
        </div>
      </div>
    </>
  );
};
