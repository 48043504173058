/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/button-has-type */
import React, {
  useState,
  useEffect,
  useContext,
  setState,
  useReducer,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import NumberFormat from 'react-number-format';
import { sendOTP, getOTPVerified, verifyPhone } from 'Actions/otp-actions';
import { normalize } from 'react-phone-input-auto-format';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
  checkExternalUserWithSystem,
} from 'Actions/new-auth-action';
import { Alert } from '../Alert';
import { initialState, reducer } from './reducer';
import icon from '../../assets/icons/icons.svg';

export const SignupPhone = ({ history }) => {
  const isMobile = detectDevice();
  const [phoneNo, setPhone] = useState('');
  const [method, setMethod] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [number, setNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [responseError, setResponseError] = useState('');
  const [errorDisabled, setErrorDisabled] = useState(true);
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [alertTemplate, setAlertTemplate] = useState('red');
  const [localEmail, setLocalEmail] = useState('');

  const [reducerState, dispatch] = useReducer(reducer, initialState());
  const {
    err,
    fnErr,
    lnErr,
    usrErr,
    dobError,
    emailAddress,
    firstName,
    lastName,
    disableNext,
    loginStrategy,
  } = reducerState;

  const {
    SOCIAL_SIGNUP_KEY: {
      EMAIL,
      FIRST_NAME,
      LAST_NAME,
      SIGNUP_TYPE,
      USERNAME,
      GMAIL_ID,
      FACEBOOK_TOKEN,
      GMAIL,
      FACEBOOK,
    },
    HTTP_STATUS_CODES: {
      OK,
      BAD_REQUEST,
      ALREADY_EXISTS,
      NO_CONTENT,
      CREATED,
      TOO_MANY_REQUEST,
    },
  } = APP_CONSTANTS;

  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;
  const { email, phoneNumber, dob, successMsg } = history.location.state
    ? history.location.state
    : '';

  useEffect(() => {
    if (successMsg) {
      setResponseError(successMsg);
      setAlertTemplate('green');
      setToggleAlertBox(true);
    }
    if (phoneNumber) {
      setPhone(phoneNumber);
    }
    if (email) {
      setLocalEmail(email);
    } else if (emailAddress) {
      setLocalEmail(emailAddress);
      checkExternalUserWithSystem({
        email: emailAddress,
      })
        .then(res => {
          const { code, message, data, status } = res.data;
          if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
            /* const path = `/m-login-email-sent`;
            history.push(path, { email: emailAddress }); */
          } else if (status === APP_CONSTANTS.HTTP_STATUS_CODES.CREATED) {
            /* const path = `/m-signup-phone`;
            history.push(path, {
              email: externalUser.email,
              phoneNumber: externalUser.phoneNumber,
              dob: externalUser.birthdate,
            }); */
          }
        })
        .catch(error => {
          const { status, code } = error.response.data;
        });
    }
  }, []);

  const verifyPhone = () => {
    const path = `/m-signup-phone-verify`;
    history.push(path, {
      phoneNo: `+1${normalize(phoneNo)}`,
      email: localEmail,
      dob,
    });
  };

  const back = () => {
    const path = `/m-signup-email-sent`;
    history.push(path, { email: localEmail });
  };

  useEffect(() => {
    // document.getElementById('phoneInput').focus();
  }, []);

  // const concernedElement = document.querySelector('.input');

  /* document.addEventListener('mousedown', event => {
    if (concernedElement) {
      if (concernedElement.contains(event.target)) {
        event.preventDefault();
      } else {
        event.preventDefault();
      }
    }
  }); */

  /*   const input = document.getElementById('phoneInput');
  if (input) {
    // eslint-disable-next-line func-names
    // eslint-disable-next-line consistent-return
    input.addEventListener('keydown', function(event) {
      if (event.keyCode === 13) {
        if (!document.getElementById('submitButton').disabled) {
          document.getElementById('submitButton').click();
        }
        event.preventDefault();
        return false;
      }
    });
  } */

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const customerId = query.get('customerid');
  const externalToken = query.get('token');

  const requestPhone = response => {
    setForbiddenErr('');
    setError('');
    requestPhoneVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          verifyPhone();
        }
      })
      .catch(error => {
        const { status, message, code } = error.response.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const handleSubmit = () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        phoneNo: `+1${normalize(phoneNo)}`,
        method,
      },
    });
    // evt.preventDefault();
    if (!phoneNo && !method) {
      return;
    }
    const data = {
      phoneNo: `+1${normalize(phoneNo)}`,
      method,
    };
    requestPhone(data);
  };
  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button
                  className="button button-back"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body justify-start">
          <h1 className="title">Enter your phone number</h1>
          <p className="copy">We’ll send you a code to log in</p>
          <div className="inputAreaWrapper">
            <div className="phoneInputArea">
              {/* <span className="countryCode">🇺🇸 +001</span> */}
              {/* <input
                className="input"
                name="phone"
                type="number"
                placeholder="(•••) ••• ••••"
                defaultValue=""
                onChange={handleChange}
              /> */}
              <NumberFormat
                id="phoneInput"
                className="input"
                format="(###) ### ####"
                mask=""
                name="phone"
                placeholder="(•••) ••• ••••"
                autoComplete="off"
                value={phoneNo}
                autoFocus
                onValueChange={values => {
                  if (values.value.length === 10) {
                    setBtnDisabled(false);
                    setPhone(values.value);
                    setMethod('signup');
                  } else {
                    setPhone(values.value);
                    setMethod('signup');
                    setBtnDisabled(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="ffScreen__footer">
          <button
            id="submitButton"
            onClick={() => {
              mixPanelTrackEvent(
                MIXPANEL_EVENTS_CONSTANTS.SIGNUP_PHONE_NUMBER,
                {
                  action: 'Sign Up - Phone Number',
                },
              );
              handleSubmit();
            }}
            className="button button-full button-blue"
            disabled={btnDisabled}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};
