import React, { useState, useEffect, useContext } from 'react';
import { ProgressBar } from 'Components/ProgressBar';
import { Link } from 'react-router-dom';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Phone } from 'Components/Form-Elements/Phone';
import { Button } from 'Components/Form-Elements/Button';
import { Input } from 'Components/Form-Elements/Input';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { sendOTP, getOTPVerified, verifyPhone } from 'Actions/otp-actions';
import { signUpManual } from 'Actions/sign-up-action';
import { normalize } from 'react-phone-input-auto-format';
import { signup } from 'Actions/signup-action';
import { APP_CONSTANTS } from 'Constants/app-constants';
import {
  setInSessionStorage,
  getFromSessionStorage,
  setCookie,
  removeFromSessionStorage,
} from 'Utils/storage-utilities';
import { checkIfUserExists } from 'Actions/login-action';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import branch from 'branch-sdk';
import { mixPanelTrackRegistration, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const PhoneNumberVerification = ({
  history,
  isModal,
  fetchAndSetModalPage,
  closeModalBox,
}) => {
  const { profileContextDispatch } = useContext(ProfileContext);
  const [number, setNumber] = useState('');
  const [toggleDesign, setToggleDesign] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [responseError, setResponseError] = useState('');
  const [disableOtpButton, setOtpDisable] = useState(true);
  const signupType = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
  );

  const {
    SOCIAL_SIGNUP_KEY: {
      EMAIL,
      FIRST_NAME,
      LAST_NAME,
      SIGNUP_TYPE,
      USERNAME,
      GMAIL_ID,
      FACEBOOK_TOKEN,
      GMAIL,
      FACEBOOK,
    },
    HTTP_STATUS_CODES: {
      OK,
      BAD_REQUEST,
      ALREADY_EXISTS,
      NO_CONTENT,
      CREATED,
      TOO_MANY_REQUEST,
    },
  } = APP_CONSTANTS;

  useEffect(() => {
    setResponseError('');
    setOtpDisable(!(number.length === APP_CONSTANTS.TEXT_LENGTH.FOURTEEN));
  }, [number]);

  useEffect(() => {
    setOtpDisable(!(verificationCode.length === APP_CONSTANTS.TEXT_LENGTH.SIX));
  }, [verificationCode]);

  const verifyNumber = phoneNo => {
    setVerificationCode('');
    setResponseError('');
    const data = {
      phoneNo: `+1${normalize(number) || phoneNo}`,
    };

    verifyPhone(data)
      .then(() => {
        sendOTP(data)
          .then(resp => {
            if (resp.data.status === OK) {
              setInSessionStorage(
                APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PHONE_NUMBER,
                data.phoneNo,
              );
              setOtpDisable(true);
              setToggleDesign(true);
            }
          })
          .catch(({ response }) => {
            if (response.data.status === BAD_REQUEST) {
              setResponseError(MESSAGE_CONSTANTS.ERROR_MSG.PHONE_NUMBER_ERROR);
            } else if (response.data.status === TOO_MANY_REQUEST) {
              setResponseError(MESSAGE_CONSTANTS.ERROR_MSG.TOO_MANY_REQUEST);
            } else {
              setResponseError(MESSAGE_CONSTANTS.ERROR_MSG.SOMETHING_WRONG);
            }
          });
      })
      .catch(({ response }) => {
        const { status, code } = response;
        if (
          status === ALREADY_EXISTS &&
          code === APP_CONSTANTS.ERROR_CODE.PHONE_EXISTS
        ) {
          setResponseError(MESSAGE_CONSTANTS.ERROR_MSG.PHONE_EXISTS);
        }
      });
  };

  const getUserData = () => {
    const data = {
      username: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USERNAME,
      ),
      password: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PASSWORD,
      ),
      email: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USER_EMAIL,
      ),
      firstName: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.NAME,
      ),
      lastName: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIRNAME,
      ),
      phoneNo: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PHONE_NUMBER,
      ),
      birthDate: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.BIRTH_DATE,
      ),
      signupStrategy: 'username',
      location: getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.LOCATION,
      ),
    };

    Object.keys(data).forEach(property => {
      if (!data[property]) delete data[property];
    });

    return data;
  };

  const login = () => {
    const signupType = getFromSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
    );
    const loginType = getFromSessionStorage(SIGNUP_TYPE);

    let data;
    if (signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY) {
      data = {
        username: getFromSessionStorage(EMAIL),
        gmailId: getFromSessionStorage(GMAIL_ID),
        facebookToken: getFromSessionStorage(FACEBOOK_TOKEN),
        loginstrategy: loginType === GMAIL ? GMAIL : FACEBOOK,
      };
    } else {
      data = {
        username: getFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USERNAME,
        ),
        password: getFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PASSWORD,
        ),
        loginstrategy: 'username',
      };
    }
    checkIfUserExists(data)
      .then(response => {
        const resp = response;
        if (resp.data.status === NO_CONTENT) {
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, resp.data.data);
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, resp.data.data.token);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          const payload = {
            userId: resp.data.data.publicId,
            eventName: 'registration',
            email: resp.data.data.emailAddress,
            name: resp.data.data.fullName,
            distinct_id: resp.data.data.publicId,
          };
          mixPanelTrackRegistration(payload);
          branch.setIdentity(resp.data.data.publicId);
          branch.logEvent('CompleteRegistration');
          if (isModal) {
            closeModalBox(true);
          } else {
            const navigateTo = getFromSessionStorage(
              APP_CONSTANTS.STORAGE_KEY_REGISTRATION.DROP_AFTER_SIGNUP,
            );
            history.push(navigateTo || '/favorite-sport');
            removeFromSessionStorage(
              APP_CONSTANTS.STORAGE_KEY_REGISTRATION.DROP_AFTER_SIGNUP,
            );
          }
        }
      })
      .catch(({ response }) => {
        setInSessionStorage('screen', 'error');
        setInSessionStorage('message', response.data.message);
        if (isModal) {
          fetchAndSetModalPage('/message');
        } else {
          alert(response.data.message);
          history.push('/login');
        }
      })
      .finally(() => {
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USERNAME,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PASSWORD,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.LOCATION,
        );
        removeFromSessionStorage(APP_CONSTANTS.STORAGE_KEY_REGISTRATION.NAME);
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIRNAME,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PHONE_NUMBER,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.BIRTH_DATE,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USER_EMAIL,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
        );
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_TYPE,
        );
        removeFromSessionStorage(EMAIL);
        removeFromSessionStorage(GMAIL_ID);
        removeFromSessionStorage(FACEBOOK_TOKEN);
        removeFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SOCIAL_DATA,
        );
        removeFromSessionStorage(FIRST_NAME);
        removeFromSessionStorage(LAST_NAME);
        removeFromSessionStorage(USERNAME);
      });
  };

  const socialSignup = data => {
    signup(data)
      .then(res => {
        const { code, message, status } = res.data;
        if (status === CREATED) {
          login();
        } else if (status === BAD_REQUEST) {
          if (isModal) {
            setInSessionStorage('screen', 'error');
            setInSessionStorage(
              'message',
              MESSAGE_CONSTANTS.ERROR_MSG.BAD_REQUEST,
            );
            fetchAndSetModalPage('/message');
            return;
          }
          alert(MESSAGE_CONSTANTS.ERROR_MSG.BAD_REQUEST);
          history.push('/login');
        } else if (status === ALREADY_EXISTS) {
          if (isModal) {
            setInSessionStorage('screen', 'error');
            setInSessionStorage(
              'message',
              MESSAGE_CONSTANTS.ERROR_MSG.USER_EXISTS,
            );
            fetchAndSetModalPage('/message');
            return;
          }
          alert(MESSAGE_CONSTANTS.ERROR_MSG.USER_EXISTS);
          history.push('/login');
        } else {
          if (isModal) {
            setInSessionStorage('screen', 'error');
            setInSessionStorage(
              'message',
              MESSAGE_CONSTANTS.ERROR_MSG.SOMETHING_WRONG,
            );
            fetchAndSetModalPage('/message');
            return;
          }
          alert(MESSAGE_CONSTANTS.ERROR_MSG.SOMETHING_WRONG);
          history.push('/login');
        }
      })
      .catch(err => {
        // handle error
      });
  };

  const signUp = () => {
    const signupType = getFromSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
    );
    if (signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY) {
      const userData = getFromSessionStorage(
        APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SOCIAL_DATA,
      );
      const signupData = {
        ...userData,
        location: getFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.LOCATION,
        ),
        phoneNo: getFromSessionStorage(
          APP_CONSTANTS.STORAGE_KEY_REGISTRATION.PHONE_NUMBER,
        ),
      };
      socialSignup(signupData);
      return;
    }
    signUpManual(getUserData())
      .then(resp => {
        if (resp.data.status === CREATED) {
          login();
        }
      })
      .catch(({ response }) => {
        if (isModal) {
          setInSessionStorage('screen', 'error');
          setInSessionStorage('message', response.data.message);
          fetchAndSetModalPage('/message');
          return;
        }
        alert(response.data.message);
        history.push('/login');
      });
  };

  const verifyOTP = () => {
    const data = {
      phoneNo: `+1${normalize(number)}`,
      otp: verificationCode,
    };
    getOTPVerified(data)
      .then(resp => {
        if (resp.data.status === OK) {
          signUp();
        }
      })
      .catch(resp => {
        setResponseError(MESSAGE_CONSTANTS.ERROR_MSG.OTP_ERROR);
      });
  };

  const handleCodeChange = value => {
    if (value.length <= APP_CONSTANTS.TEXT_LENGTH.SIX) {
      setVerificationCode(value);
    }
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="95" />
          <div className="d-flex topBar">
            {!isModal ? (
              <Link
                to={
                  signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY
                    ? '/location'
                    : '/enter-details'
                }
                className="arrowBack"
              />
            ) : (
              <Button
                className="linkBtn arrowBack"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_PHONE_NUMBER,
                    {
                      action: 'Back',
                    },
                  );
                  fetchAndSetModalPage(
                    signupType === MESSAGE_CONSTANTS.SIGNUP_STRATEGY
                      ? '/location'
                      : '/enter-details',
                  );
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>
        <div>
          {!toggleDesign ? (
            <>
              <div className="wrapper">
                <div className="info">
                  <div className="iconBig mobileIcon" />
                  <div className="title">
                    Verify <strong>phone number</strong>
                  </div>
                </div>
                <Phone
                  onChange={setNumber}
                  value={number}
                  externalError={responseError}
                />
                <Button
                  label={BUTTON_LABELS.COMMON.NEXT}
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.SIGNUP_PHONE_NUMBER,
                      {
                        action: 'Next',
                        formData: { number },
                      },
                    );
                    verifyNumber();
                  }}
                  disabled={disableOtpButton}
                  className="btn btn-primary"
                />
              </div>
            </>
          ) : (
            <>
              <div className="wrapper">
                <div className="info">
                  <div className="iconBig mobileIcon" />
                  <div className="title">
                    <strong>We sent you a text</strong>
                  </div>
                  <div className="description">
                    {MESSAGE_CONSTANTS.VERIFY_PHONE_NUMBER.DESCRIPTION}
                  </div>
                </div>
                <div className="form-group verficationCodeWrap">
                  <Input
                    inputType="number"
                    id="verify-code"
                    value={verificationCode}
                    handleChange={handleCodeChange}
                    textLabel="Verification Code"
                    placeholder="Verification Code"
                    externalError={responseError}
                    onBlur={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.SIGNUP_VERIFY_PHONE_NUMBER,
                        {
                          action: 'Verification Code',
                          formdata: { verificationCode },
                        },
                      );
                    }}
                  />
                  <Button
                    type="button"
                    className="inputClear"
                    onClick={() => {
                      setVerificationCode('');
                    }}
                  />
                </div>
                <div className="or">&nbsp;</div>
                <div className="centerText">
                  Didn’t receive a verification code?
                  <br />
                  <Button
                    className="fontBold18 btn btn-link"
                    label="Try Sending Again"
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.SIGNUP_VERIFY_PHONE_NUMBER,
                        {
                          action: 'Send Again',
                        },
                      );
                      verifyNumber();
                    }}
                  />
                </div>
                <Button
                  label={BUTTON_LABELS.COMMON.NEXT}
                  disabled={disableOtpButton}
                  className="btn btn-primary"
                  onClick={() => {
                    mixPanelTrackEvent(
                      MIXPANEL_EVENTS_CONSTANTS.SIGNUP_VERIFY_PHONE_NUMBER,
                      {
                        action: 'Next',
                        formData: { verificationCode },
                      },
                    );
                    verifyOTP();
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
