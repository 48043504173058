import React from 'react';
import { Link } from 'react-router-dom';
import { APP_CONSTANTS } from '../../../constants/app-constants';

export const ResetPasswordConfirmation = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroidd = ua.indexOf('android') > -1 && ua.indexOf('mobile');
  const isIOS =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  const renderLinkToLogin = () => {
    if (isIOS) {
      window.location.href = 'dailynumber://app';
    }
    if (isAndroidd) {
      return (
        <div className="link">
          <a
            id="showAndroidLink"
            // eslint-disable-next-line max-len
            href="intent:#Intent;scheme=dailynumber://app/map;package=com.webonise.dailyNumbers;end">
            Open DailyNumber App
          </a>
        </div>
      );
    }
    return <Link to="/">Click here to Login</Link>;
  };
  return (
    <div className="signup">
      <div className="d-flex align-items-center resetPasswordConfirmWrapper ">
        <div className="msgWrap">
          <div className="checked" />
          <div className="title">{APP_CONSTANTS.PASSWORD_RESET_SUCCESS}</div>
          {renderLinkToLogin()}
        </div>
      </div>
    </div>
  );
};
