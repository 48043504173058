/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Branch from 'branch-sdk';
import dateFormat from 'dateformat';
import { Button } from 'Components/Form-Elements/Button';
import { Input } from 'Components/Form-Elements/Input';
import { TRACKING_CONSTANTS } from 'Constants/branch-tracking';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import {
  getUserDetail,
  updateUserProfileKYC,
  updateAccountVerificationDetails,
  getAccountVerificationDetails,
} from 'Actions/user-profile-action';
import { createDeposit } from 'Actions/wirecard-action';
import { verifyUserIdentity } from 'Actions/user-balance-action';
import { ModalBox } from 'Components/ModalBox';
import { mixPanelTrackEvent, mixpanelTrackPageView } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

import {
  getCookie,
  getFromSessionStorage,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { detectDevice } from 'Utils/device-utils';
import { initialState, reducer } from './reducer';

export const Kyc = ({
  history,
  fetchAndSetModalPage,
  isModal,
  closeModalBox,
}) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState);
  const {
    firstName,
    lastName,
    email,
    emails,
    dob,
    zip,
    phone,
    address,
    address1,
    city,
    state,
    disableBtn,
    fnErr,
    lnErr,
    emailErr,
    dobErr,
    zipErr,
    phoneErr,
    addressErr,
    address1Err,
    cityErr,
    stateErr,
    isLoading,
    modalBoxName,
    toggleModalBox,
  } = reducerState;

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const { ADD_FUNDS_VALIDATION, WIRECARD_RESP } = MESSAGE_CONSTANTS;
  const isMobile = detectDevice();

  useEffect(() => {
    if (modalBoxName.length) {
      dispatch({ type: 'setToggleModalBox', payload: true });
    }
  }, [modalBoxName]);

  const showError = error => {
    console.log(error);
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.KYC_TRIGGERED, {
      action: 'Error Kyc',
      response: error,
    });
    setInSessionStorage('message', error);
    setInSessionStorage('screen', 'error');
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/message')
        : history.push('/message')
      : fetchAndSetModalPage('/message');
  };

  const showSuccess = msg => {
    setInSessionStorage('message', msg);
    setInSessionStorage('screen', 'success');
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.KYC_TRIGGERED, {
      action: 'Success Kyc',
      response: msg,
    });
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/message')
        : history.push('/message')
      : fetchAndSetModalPage('/message');
  };

  const checkUserDetail = () => {
    getUserDetail()
      .then(({ data }) => {
        const {
          firstName,
          lastName,
          emailAddress,
          birthdate,
          zipCode,
          phoneNo,
          emails,
        } = data.data;
        getAccountVerificationDetails()
          .then(({ data }) => {
            dispatch({ type: 'setIsLoading', payload: false });
            const {
              addressLine1,
              addressLine2,
              city,
              state,
              postalCode,
              countryCode,
            } = data;
            const address = addressLine1;
            const address1 = addressLine2;
            dispatch({ type: 'setFirstName', payload: firstName });
            dispatch({ type: 'setLastName', payload: lastName });
            dispatch({ type: 'setEmail', payload: emailAddress });
            dispatch({ type: 'setEmails', payload: emails });
            dispatch({
              type: 'setDob',
              payload: dateFormat(
                birthdate.substring(0, 10),
                'yyyy-mm-dd',
                true,
              ),
            });
            dispatch({ type: 'setZip', payload: zipCode });
            dispatch({ type: 'setPhone', payload: phoneNo });
            dispatch({ type: 'setAddress', payload: address });
            dispatch({ type: 'setAddress1', payload: address1 });
            dispatch({ type: 'setCity', payload: city });
            dispatch({ type: 'setState', payload: state });
            if (firstName) {
              dispatch({ type: 'setFnErr', payload: true });
            }
            if (lastName) {
              dispatch({ type: 'setLnErr', payload: true });
            }
            if (emailAddress) {
              dispatch({ type: 'setEmailErr', payload: true });
            }
            if (birthdate) {
              dispatch({ type: 'setDobErr', payload: true });
            }
            if (zipCode) {
              dispatch({ type: 'setZipErr', payload: true });
            }
            if (phoneNo) {
              dispatch({ type: 'setPhoneErr', payload: true });
            }
            if (address) {
              dispatch({ type: 'setAddressErr', payload: true });
            }
            dispatch({ type: 'setAddress1Err', payload: true });
            if (city) {
              dispatch({ type: 'setCityErr', payload: true });
            }
            if (state) {
              dispatch({ type: 'setStateErr', payload: true });
            }
          })
          .catch(err => showError(err));
      })
      .catch(err => showError(err));
  };

  const disableButton = () => {
    return ![
      fnErr,
      lnErr,
      emailErr,
      dobErr,
      zipErr,
      phoneErr,
      addressErr,
      address1Err,
      cityErr,
      stateErr,
    ].every(Boolean);
  };

  useEffect(() => {
    mixpanelTrackPageView('Kyc Input Screen');
    checkUserDetail();
  }, []);

  useEffect(() => {
    dispatch({ type: 'setDisableBtn', payload: disableButton() });
  }, [
    fnErr,
    lnErr,
    emailErr,
    dobErr,
    zipErr,
    phoneErr,
    addressErr,
    address1Err,
    cityErr,
    stateErr,
  ]);

  const renderProgressDialog = () => {
    return isLoading && <Spinner />;
  };

  const showLocationErr = error => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        dispatch({
          type: 'setModalBoxName',
          payload: APP_CONSTANTS.MODALBOX.PERMISSION_DENIED,
        });
        break;
      case error.POSITION_UNAVAILABLE:
        dispatch({
          type: 'setModalBoxName',
          payload: APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE,
        });
        break;
      case error.TIMEOUT:
        dispatch({
          type: 'setModalBoxName',
          payload: APP_CONSTANTS.MODALBOX.TIMEOUT,
        });
        break;
      default:
        dispatch({
          type: 'setModalBoxName',
          payload: APP_CONSTANTS.MODALBOX.UNKNOWN_ERR,
        });
        break;
    }
  };

  const handleSubmit = () => {
    updateUserProfileKYC(firstName, lastName, email, dob, zip)
      .then(({ data }) => {
        updateAccountVerificationDetails(
          firstName,
          lastName,
          email,
          dob,
          phone,
          address,
          address1,
          city,
          state,
          zip,
          'US',
        )
          .then(({ data }) => {
            console.log('KYC Updated');
            if (navigator.geolocation) {
              navigator.geolocation.getCurrentPosition(position => {
                const userLocation = {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                  horizontalAccuracy: position.coords.accuracy,
                  timestamp: Date.now(),
                };
                verifyUserIdentity([userLocation])
                  .then(({ data }) => {
                    if (data && data.verified) {
                      const createObj = getFromSessionStorage('pnm-response');
                      const amount = getFromSessionStorage('selected-amount');
                      const eventName = TRACKING_CONSTANTS.DEPOSIT;
                      let depositId;
                      let depositMethodId;
                      createDeposit(createObj)
                        .then(({ data }) => {
                          console.log('create deposit started');
                          // setIsLoading(false);
                          if (data.error) {
                            showError(data.reason);
                          }
                          if (data && !data.error) {
                            depositId = data;
                            const payload = {
                              amount,
                              deposit_id: depositId,
                              deposit_method_id: depositMethodId,
                            };
                            mixPanelTrackEvent('deposit', payload);
                            try {
                              const metaData = {
                                amount,
                                deposit_id: depositId,
                                deposit_method_id: depositMethodId,
                              };
                              Branch.logEvent(eventName, metaData);
                            } catch (error) {
                              console.log(error);
                            }
                            showSuccess(ADD_FUNDS_VALIDATION.FUNDS_ADDED);
                          }
                        })
                        .catch(() => {
                          showError(ADD_FUNDS_VALIDATION.TXN_DECLINED);
                          // setIsLoading(false);
                        });
                    } else {
                      isMobile
                        ? isModal
                          ? fetchAndSetModalPage('/customer-support')
                          : history.push({ pathname: '/customer-support' })
                        : fetchAndSetModalPage('/customer-support');
                    }
                  })
                  .catch(({ response }) => showError(response.data.message));
              }, showLocationErr);
            }
          })
          .catch(({ response }) => showError(response.data.message));
      })
      .catch(({ response }) => showError(response.data.message));
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.PERMISSION_DENIED) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.PERMISSION_DENIED;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.TIMEOUT) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.TIMEOUT;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.UNKNOWN_ERR) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.UNKNOWN_ERR;
    }
    return modalbox;
  };

  const processHandler = () => {
    dispatch({ type: 'setToggleModalBox', payload: false });
    dispatch({ type: 'setModalBoxName', payload: '' });
  };

  return (
    <main className="App__main noHamburger">
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="OK"
          showBothButtons={false}
        />
      )}
      <div className="kycPage wrapper signup">
        <div className="d-flex header">
          <div className="d-flex topBar">
            {isMobile ? (
              <Link to="/wallet" className="topClose btnBackArrow" />
            ) : (
              <Button
                onClick={() => {
                  closeModalBox();
                }}
                className="topClose  linkBtn btnBackArrow"
              />
            )}
            {!isMobile && <h2 className="heading">Add Funds</h2>}
          </div>
        </div>
        <div className="info">
          <div className="kycIconWrapper">
            <div className="iconBig questionIcon" />
          </div>
          <div className="title">
            <strong>{MESSAGE_CONSTANTS.KYC.HEADING}</strong>
          </div>
          <div className="description black">
            {MESSAGE_CONSTANTS.KYC.FORM_LABEL}
          </div>
        </div>
        <div className="centerText">{renderProgressDialog()}</div>
        {!isLoading && (
          <form className="kycInfoForm">
            <div className="form-group">
              <Input
                inputType="text"
                className="form-control"
                id="firstName"
                value={firstName}
                handleChange={firstName => {
                  dispatch({ type: 'setFirstName', payload: firstName });
                }}
                textLabel="First Name"
                validationRules={['validateMandatory', 'validateName']}
                getValidity={err => {
                  dispatch({ type: 'setFnErr', payload: err });
                }}
              />
            </div>
            <div className="form-group">
              <Input
                inputType="text"
                className="form-control"
                id="lastName"
                value={lastName}
                handleChange={lastName => {
                  dispatch({ type: 'setLastName', payload: lastName });
                }}
                textLabel="Last Name"
                validationRules={['validateMandatory', 'validateName']}
                getValidity={err => {
                  dispatch({ type: 'setLnErr', payload: err });
                }}
              />
            </div>
            <div className="form-group">
              <Input
                inputType="text"
                className="form-control"
                id="email"
                value={email}
                handleChange={email => {
                  dispatch({ type: 'setEmail', payload: email });
                }}
                textLabel="Email"
                validationRules={['validateMandatory', 'validateEmail']}
                getValidity={err => {
                  dispatch({ type: 'setEmailErr', payload: err });
                }}
              />
            </div>
            <div className="form-group">
              <Input
                inputType="date"
                maxLength="10"
                className="form-control"
                id="dob"
                value={dob}
                handleChange={dob => {
                  dispatch({ type: 'setDob', payload: dob });
                }}
                textLabel="Date of Birth"
                validationRules={['validateMandatory', 'validateDOB']}
                getValidity={err => {
                  dispatch({ type: 'setDobErr', payload: err });
                }}
              />
            </div>
            <div className="form-group">
              <Input
                inputType="text"
                maxLength="150"
                className="form-control"
                id="address"
                value={address}
                handleChange={address => {
                  dispatch({ type: 'setAddress', payload: address });
                }}
                textLabel="Address"
                validationRules={['validateMandatory', 'validateAddress']}
                getValidity={err => {
                  dispatch({ type: 'setAddressErr', payload: err });
                }}
              />
            </div>
            <div className="form-group">
              <Input
                inputType="text"
                maxLength="150"
                className="form-control"
                id="address1"
                value={address1}
                handleChange={address1 => {
                  dispatch({ type: 'setAddress1', payload: address1 });
                }}
                validationRules={['validateOptionalAddress']}
                getValidity={err => {
                  dispatch({ type: 'setAddress1Err', payload: err });
                }}
              />
            </div>
            <div className="form-group">
              <Input
                inputType="text"
                maxLength="50"
                size="4"
                className="form-control"
                id="city"
                value={city}
                handleChange={city => {
                  dispatch({ type: 'setCity', payload: city });
                }}
                textLabel="City"
                validationRules={['validateMandatory', 'validateAddress']}
                getValidity={err => {
                  dispatch({ type: 'setCityErr', payload: err });
                }}
              />
            </div>
            <div className="form-group cols2">
              <Input
                inputType="text"
                maxLength="2"
                size="2"
                className="form-control state"
                id="state"
                value={state}
                handleChange={state => {
                  dispatch({ type: 'setState', payload: state });
                }}
                textLabel="State"
                validationRules={['validateMandatory', 'validateName']}
                getValidity={err => {
                  dispatch({ type: 'setStateErr', payload: err });
                }}
              />
            </div>
            <div className="form-group cols2">
              <Input
                inputType="text"
                maxLength="5"
                size="5"
                className="form-control"
                id="zip"
                value={zip}
                handleChange={zip => {
                  dispatch({ type: 'setZip', payload: zip });
                }}
                textLabel="ZIP"
                validationRules={['validateMandatory', 'validateZip']}
                getValidity={err => {
                  dispatch({ type: 'setZipErr', payload: err });
                }}
              />
            </div>
            <div className="verifiedInfoWrap">
              <div className="title" />
              <div className="description">
                {MESSAGE_CONSTANTS.KYC.VERIFIED_LABEL}
              </div>
              {emails &&
                emails.map(emailAddress =>
                  emailAddress.verified === true ? (
                    <div className="verifiedInfo" key={emailAddress.address}>
                      <div className="verifiedIcon" />
                      <div className="form-control">Email</div>
                      <div className="form-control">{emailAddress.address}</div>
                    </div>
                  ) : (
                    ''
                  ),
                )}
              <div className="verifiedInfo">
                <div className="verifiedIcon" />
                <div className="form-control">Phone Number</div>
                <div className="form-control">{phone}</div>
              </div>
            </div>
            <Button
              className="btn btn-primary scrollBtn"
              type="submit"
              onClick={handleSubmit}
              disabled={disableBtn}
              label={BUTTON_LABELS.COMMON.SUBMIT}
            />
          </form>
        )}
      </div>
    </main>
  );
};
