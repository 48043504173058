import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';

export const CheckUserLogin = ({ close }) => {
  return (
    <div className="backfade">
      <div id="popModal" className="card-body modalPopupWrap">
        <div className="title">{MESSAGE_CONSTANTS.SOCIAL_LOGGED_IN}</div>
        <div className="popupMessageWrap">
          <div className="popupMessage">
            Email / Password cannot be updated since you have logged in using
            Facebook / Gmail account.
          </div>
        </div>
        <div>
          <div className="cancelBtnWrap cancelBtnBorder">
            <Button
              className="btn-outline-primary txtCancel"
              type="button"
              label={`${BUTTON_LABELS.COMMON.OKAY}`}
              onClick={close}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CheckUserLogin.propTypes = {
  close: PropTypes.func.isRequired,
};
