/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Cashout } from 'Components/Wallet/Cashout';
import { Checkout } from 'Components/Wallet/Checkout';
import { MessageScreen } from 'Components/MessageScreen';
import { PaymentMethodList } from 'Components/Wallet/PaymentMethodList';
import { ConfirmCashoutScreen } from 'Components/Wallet/ConfirmCashoutScreen';

export const CashoutModal = ({
  showCashOutModal,
  setShowCashOutModal,
  updateUserBal,
}) => {
  const fetchAndSetModalPage = (pageLocation, data) => {
    setPage(routesHash[pageLocation]);
  };

  const closeModalBox = () => setShowCashOutModal(false);

  const [page, setPage] = useState(
    <Cashout
      isModal
      fetchAndSetModalPage={fetchAndSetModalPage}
      closeModalBox={closeModalBox}
    />,
  );

  const commonProps = {
    fetchAndSetModalPage,
    isModal: true,
    closeModalBox,
    updateUserBal,
  };

  const routesHash = {
    '/cash-out': <Cashout {...commonProps} />,
    '/payment': <Checkout {...commonProps} key="payment" />,
    '/payment-methods': <PaymentMethodList {...commonProps} />,
    '/message': <MessageScreen {...commonProps} />,
    '/confirm-cashout': <ConfirmCashoutScreen {...commonProps} />,
  };

  if (page && page.key && showCashOutModal) {
    return page;
  }

  return (
    <div>
      {showCashOutModal && (
        <Modal isOpen centered>
          <ModalBody>{page}</ModalBody>
        </Modal>
      )}
    </div>
  );
};
