import React, { useState } from 'react';
import { Button } from 'Components/Form-Elements/Button';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { maskCardNumber, getPaymentMethodIconClass } from 'Utils/data-utils';
import { BUTTON_LABELS } from 'Constants/button-labels';
import {
  getFromSessionStorage,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { createWithdrawal } from 'Actions/user-balance-action';
import 'Components/Loader/Loader.css';

export const ConfirmCashoutScreen = ({ fetchAndSetModalPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const availableBalance = getFromSessionStorage('WalletBal');
  const withdrawalAmt = getFromSessionStorage('cash-out-amount');
  const userLocation = getFromSessionStorage('userCoords');
  const item = getFromSessionStorage('active-card');
  const publicOrderId = getFromSessionStorage('publicOrderId');

  const renderActiveCard = () => {
    return (
      <>
        <div className="dropDownWrap">
          <div className="listWithIcons">
            <ul className="border">
              <li className="d-flex" key={item.payment_method_identifier}>
                <button
                  type="button"
                  tag="button"
                  className="d-flex align-items-center addFundsBtn">
                  <div
                    className={`listIcon ${getPaymentMethodIconClass(item)}`}
                  />
                  <div className="infoWrap flex-grow-1">
                    <div className="title">
                      {item.card_brand ? item.card_brand : item.description}
                    </div>
                    <div className="info font-sm">
                      <span>{item.account_type}</span>
                      <span>{maskCardNumber(item.number)}</span>
                      <span>
                        {item.expiration_date
                          ? `Expires ${item.expiration_date}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  };

  const cashoutBalance = () => {
    const createObj = [
      publicOrderId,
      item.payment_method_identifier,
      withdrawalAmt,
      MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD,
      'mail_a_check@thedailynumber.com',
      userLocation,
    ];
    setIsLoading(true);
    createWithdrawal(createObj)
      .then(({ data }) => {
        setIsLoading(false);
        if (data && data.error) {
          setInSessionStorage('screen', 'error');
          setInSessionStorage('message', MESSAGE_CONSTANTS.CASH_OUT_GENERIC);
        }
        if (data && !data.error && data.id) {
          setInSessionStorage('screen', 'success');
          setInSessionStorage('description', MESSAGE_CONSTANTS.CASH_ME_OUT);
          setInSessionStorage('message', MESSAGE_CONSTANTS.TRANSFER_INITIATED);
        }
        fetchAndSetModalPage('/message');
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="d-flex popUpHeader">
        <div className="leftSide">
          {!isLoading && (
            <Button
              className="arrowBack linkBtn"
              onClick={() => fetchAndSetModalPage('/cash-out')}
            />
          )}
        </div>
        <h2>{MESSAGE_CONSTANTS.WALLET.CASHOUT.HEADING}</h2>
        <div className="walletAmountBtn rightSide" />
      </div>
      {!isLoading ? (
        <>
          <div className="modalBody wrapper cashoutWrapper">
            <div className="walletHeadingWrap">
              <div className="centerText">
                <strong>Cash Out ${withdrawalAmt}?</strong>
              </div>
              <div className="availableBalance centerText">
                <span>{`$${availableBalance} available in Wallet`}</span>
                <div className="questionMarkInfo">
                  <span className="questionIcon" />
                  <div className="aboutQuestionInfo">
                    <p>
                      {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                      <a href="/terms-of-service"> terms and conditions</a>.
                      *Some exclusions apply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="formToWrap">
              <div className="listWithIcons">
                <div className="textFromTo">FROM</div>
                <ul className="border">
                  <li className="d-flex" key="add-card">
                    <button className="d-flex align-items-center" type="button">
                      <div className="iconDollor">$</div>
                      <div className="textWallet">Your Daily Number Wallet</div>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="listWithIcons">
                <div className="textFromTo">TO</div>
                {renderActiveCard()}
              </div>
            </div>
            <div className="info">
              <Button
                className="btn btnGrren fullWidht"
                type="button"
                label={`${BUTTON_LABELS.COMMON.CONFIRM}`}
                onClick={cashoutBalance}
              />
              <div className="availableBalance centerText d-block">
                Your funds will be available in 3-6 days
              </div>
            </div>
          </div>
        </>
      ) : (
        <div id="cover-spin" />
      )}
    </>
  );
};
