import React, { useEffect } from 'react';
import { Button } from 'Components/Form-Elements/Button';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const ListView = ({
  id,
  arrList,
  designType,
  handleClick,
  loadMore,
}) => {
  const handleAPICAll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      if (id === 'teamList' || id === 'playerList') {
        loadMore();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleAPICAll);
    return () => {
      window.removeEventListener('scroll', handleAPICAll);
    };
  }, []);

  const renderDesign = () => {
    if (designType === APP_CONSTANTS.LIST_VIEW_DESIGN.TWO_ICON) {
      return arrList.map(item => {
        return (
          <div className="teamListing d-flex" key={item.id}>
            <div className="teamInfo">
              <div className="teamName">{item.title}</div>
              <div className="standings">{item.subTitle}</div>
            </div>
            <Button
              className={`btnAddTeam ${item.isSelected && 'btnSelected'}`}
              onClick={() => {
                handleClick(item);
              }}
            />
          </div>
        );
      });
    }
    if (designType === APP_CONSTANTS.LIST_VIEW_DESIGN.ONE_ICON) {
      return arrList.map(item => {
        return (
          <div className="teamListing d-flex" key={item.id}>
            <div className="teamInfo">
              <div className="teamName">{item.title}</div>
            </div>
            <Button
              className="btnAddTeam btnDeselected"
              onClick={() => {
                handleClick(item);
              }}
            />
          </div>
        );
      });
    }
    return null;
  };

  return renderDesign();
};
