/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/button-has-type */
import React, {
  useState,
  useEffect,
  useContext,
  setState,
  useCallback,
  useRef,
  useFocus,
} from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { setCookie } from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import NumberFormat from 'react-number-format';
import {
  requestPhoneVerification,
  authenticatePhoneVerification,
} from 'Actions/new-auth-action';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Alert } from '../Alert';
import icon from '../../assets/icons/icons.svg';

export const phoneVerification = ({ history }) => {
  const isMobile = detectDevice();
  const [otp, setOtp] = useState('');
  const [method, setMethod] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [errorDisabled, setErrorDisabled] = useState(true);
  const [successDisabled, setSuccessDisabled] = useState(true);
  const [responseError, setResponseError] = useState('');
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [otpDefault, setOtpDefault] = useState('');
  const [alertTemplate, setAlertTemplate] = useState('red');

  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;
  const { phoneNo, email, successMsg } = history.location.state
    ? history.location.state
    : '';
  const inputRef = useRef(null);

  const back = () => {
    const path = `/m-login-phone`;
    history.push(path, { phoneNo, email });
  };

  useEffect(() => {
    setTimeout(() => {
      setBtnDisabled(false);
    }, 5000);
  }, []);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.click();
      // setTimeout(() => inputRef.current.click(), 300);
    }
  }, [inputRef.current]);

  const requestPhone = response => {
    requestPhoneVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
      })
      .catch(error => {
        const { status, message, code } = error.response.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const authenticatePhone = response => {
    authenticatePhoneVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setTimeout(function() {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
              action: 'Username Login',
              formData: {
                user: data.userName,
              },
            });
          }, 2000);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          code === APP_CONSTANTS.ERROR_CODE.EMAIL_NOT_VERIFIED
        ) {
          // const phoneWithoutCode = phoneNo.slice(-10);
          const path = `/m-login-email`;
          if (data) {
            history.push(path, {
              phone: phoneNo,
              unVerifiedEmail: data.address,
              successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.EMAIL_NOT_VERIFIED,
            });
          } else {
            history.push(path, {
              phone: phoneNo,
              unVerifiedEmail: '',
              successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.EMAIL_NOT_VERIFIED,
            });
          }
        }
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.CREATED) {
          if (data.emailAddress && data.emailVerified === true) {
            const path = `/m-signup-dob`;
            history.push(path, {
              phoneNo,
              email: data.emailAddress,
              successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.DOB_AFTER_PHONE,
            });
          } else {
            const path = `/m-signup-email`;
            history.push(path, {
              phoneNo,
              successMsg:
                MESSAGE_CONSTANTS.AUTHENTICATION.PHONE_VERIFIED_SIGNUP,
            });
          }
        }
      })
      .catch(error => {
        const { status, code } = error.response;
        const { message } = error;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NOT_FOUND) {
          setResponseError(MESSAGE_CONSTANTS.AUTHENTICATION.OTP_NOT_FOUND);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.TOO_MANY_REQUEST) {
          setResponseError(MESSAGE_CONSTANTS.AUTHENTICATION.TOO_MANY_REQUEST);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  const verifyOTP = vCode => {
    const data = {
      phoneNo,
      OTP: vCode,
      method: 'login',
      email,
    };
    authenticatePhone(data);
  };

  const verifyPhone = val => {
    if (val.length === 6) {
      verifyOTP(val);
      setErrorDisabled(false);
      return;
    }
    setErrorDisabled(true);
  };

  const handleResendSubmit = () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        phoneNo,
      },
    });
    if (!phoneNo) {
      return;
    }
    const data = {
      phoneNo,
      method: 'login',
    };
    requestPhone(data);
  };

  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button className="button button-back" onClick={back}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body justify-start">
          <h1 className="title">Enter the verification code we sent</h1>
          <p className="copy">We sent you a text message with a 6-digit code</p>
          <div className="inputAreaWrapper">
            <div className="verifyInputArea">
              {/* <input
                className="input"
                type="tel"
                // eslint-disable-next-line jsx-a11y/no-autofocus
                ref={callbackRef}
                placeholder="••••••"
              /> */}
              <NumberFormat
                id="otpInput"
                className="input"
                format="######"
                mask=""
                name="otp"
                placeholder="••••••"
                getInputRef={inputRef}
                autoComplete="off"
                autoFocus
                onValueChange={values => {
                  if (values.value.length === 6) {
                    setOtp(values.value);
                    verifyPhone(values.value);
                  } else {
                    setOtp(values.value);
                    setErrorDisabled(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="ffScreen__footer">
          <button
            className="button button-full button-sm"
            disabled={btnDisabled}
            onClick={handleResendSubmit}>
            Resend code
          </button>
        </div>
      </div>
    </>
  );
};
