/* eslint-disable react/no-danger */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { mixPanelTrackRegistration, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { EntryFee } from './EntryFee';

export const LineupEntryFee = ({
  sliderEntryFee,
  handleEntryFeeChange,
  entryFee,
  lineUpFinalWin,
  winAmountString,
  isModal,
  selectedEntryFee,
}) => {
  const renderFeeOption = () => {
    return entryFee.map((fee, index) => {
      if (selectedEntryFee.amount === fee.amount) {
        return (
          <option value={index} key={fee.amount} selected>
            {fee.amount ? fee.amount : MESSAGE_CONSTANTS.LINEUP.FREE}
          </option>
        );
      }
      return (
        <option value={index} key={fee.amount}>
          {fee.amount ? fee.amount : MESSAGE_CONSTANTS.LINEUP.FREE}
        </option>
      );
    });
  };

  const getStringToShow = str => {
    if (str.indexOf('$') !== -1) {
      const string = str.split('-').map(amount => {
        const str1 = amount.replace(/\$/g, '<sup>$</sup>');
        return `<div class="winWrap">${str1}</div>`;
      });
      return string.join('-');
    }
    return str;
  };

  if (isModal) {
    return (
      <div className="d-flex justify-content-between priceBlock">
        <div className="cols ">
          <span className="winTitle">{MESSAGE_CONSTANTS.LINEUP.ENTRY_FEE}</span>
          <div className="feeSelect">
            {selectedEntryFee.amount !== 0 && <sup>$</sup>}
            <select
              className={`form-control ${
                selectedEntryFee.amount === 0 ? 'freeWrap' : ''
              }`}
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                  action: 'Entry Fee Dropdown',
                });
              }}
              onChange={event => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                  action: 'Entry Fee Dropdown Changed',
                  formData: { 'Entry Fee Amount': selectedEntryFee.amount },
                });
                handleEntryFeeChange(event);
              }}>
              {renderFeeOption()}
            </select>
          </div>
        </div>
        <div className="cols">
          <span className="winTitle">To Win</span>
          <h4
            dangerouslySetInnerHTML={{
              __html: getStringToShow(
                (lineUpFinalWin && lineUpFinalWin.winAmount) || winAmountString,
              ),
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="sliderTitle">{MESSAGE_CONSTANTS.LINEUP.ENTRY_FEE}</div>
      <Slider
        ref={sliderEntryFee}
        afterChange={handleEntryFeeChange}
        className="slider entryFee"
        dots={false}
        infinite={false}
        centerMode
        slidesToShow={1}
        focusOnSelect
        slidesToScroll={1}
        variableWidth
        arrows={false}
        centerPadding="23px">
        {entryFee.map(item => (
          <EntryFee key={item.amount} data={item} />
        ))}
      </Slider>
      <div className="winPrize">
        {(lineUpFinalWin && lineUpFinalWin.winAmount) || winAmountString}
      </div>
    </>
  );
};

LineupEntryFee.defaultProps = {
  handleEntryFeeChange: () => {},
  sliderEntryFee: '',
  entryFee: [],
  lineUpFinalWin: '',
  winAmountString: '',
  isModal: false,
};

LineupEntryFee.propTypes = {
  sliderEntryFee: PropTypes.string,
  handleEntryFeeChange: PropTypes.func,
  entryFee: PropTypes.arrayOf({
    amount: PropTypes.number,
  }),
  lineUpFinalWin: PropTypes.string,
  winAmountString: PropTypes.string,
  isModal: PropTypes.bool,
  selectedEntryFee: PropTypes.objectOf({
    amount: PropTypes.number,
  }).isRequired,
};
