/* eslint-disable */
import React from 'react';

export const PrivacyStatementMobile = () => {
  const contactSupport = param => {
    typeof window.postMessage === 'function' && window.postMessage(param);
  };
  return (
    <div className="privacyPolicyWrapper mainWrapper">
      <div className="privacyPolicyWrap contentWrapper">
        <div className="lastUpdated">Last updated: March 23, 2018</div>
        <div className="privacyPolicyWrap">
          <h3>Our Policy:</h3>
          <p>
            Welcome to Daily Number (“DN,” “we,” “us” and/or “our”), operates
            the website located at www.dailynumber.app and related mobile
            applications and services (collectively, the “Service”). The Service
            is designed to provide information about DN and to provide end users
            (“you,” “your”) with the ability to participate in one or more
            skill- and/or prediction-based contests and/or other social games.
            Many of our contests and other social games are hosted in connection
            with a third party (our “Partners”). This Privacy Policy describes
            how DN collects, uses and discloses information, including
            personally identifiable data (“Personal Data”) and other
            information, that is collected from visitors to the Service.
          </p>
          <p>
            Please be sure to read this entire Privacy Policy before using or
            submitting information through the Service. By using or submitting
            information through the service, you are indicating that you agree
            to be bound by the terms of this Privacy Policy. This Privacy Policy
            is incorporated into the DN{' '}
            <a
              className="anchorColor"
              onClick={() => contactSupport('openTermsOfServices')}>
              Terms of Service
            </a>
            .
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Information We Collect:</h3>
          <p>
            When you interact with us through the Service, we may collect
            Personal Data and other information from you, as further described
            below:
          </p>
          <div className="policyContent">
            <p>
              <span>Personal Data That You Provide Through the Service: </span>
              We collect Personal Data from you when you voluntarily provide
              such information to us, such as when you contact us with
              inquiries; respond to one of our surveys; register for an account
              with or access to the Service; participate in promotions,
              sweepstakes or contests; or use certain features of the Service in
              connection with gameplay. For instance, when a user registers for
              an account with the Service, the user is asked to provide their
              full name, email address, and date of birth. Additional
              information may be provided to DN when users voluntarily connect
              to the Service through a social media account. Wherever DN
              collects Personal Data, we make an effort to provide a link to
              this Privacy Policy.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>User Content: </span>
              We may collect and store information that you and other users
              upload, post, publish or display through the Service, including
              without limitation user profile pictures and any comments or
              messages you post to or send through the Service (“User Content”).
              You acknowledge and agree that, to the extent that you include
              Personal Data in User Content, such Personal Data will be public,
              and we are not responsible for how others may use it. Please use
              caution and common sense when sharing or posting User Content.
            </p>
            <p>
              You may request that we remove User Content you have previously
              posted from publicly viewable areas of the Service by contacting
              us at support@dailynumber.app. You understand that such content
              may remain on publicly viewable areas of the Service if other
              users have posted or reposted it, and that your User Content may
              remain on our servers or in our records after it is no longer
              viewable by other users of the Service.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>Data You Provide about Others: </span>
              We may also collect and store information about others that you
              provide to us through your use of the Service, such as when you
              share User Content with others through email or social media
              websites and services. For more information on the data we share
              with and receive from social media websites and services, see the
              section titled “Social Networking Services” below.
            </p>
            <p>
              By voluntarily providing us with Personal Data, you are consenting
              to our use of it in accordance with this Privacy Policy. You
              represent that the Personal Data you provide is true, accurate,
              current and complete, and that you have the authorization to
              provide it to us. If you provide Personal Data to the Service, you
              acknowledge and agree that such Personal Data may be transferred
              from your current location to the offices and servers of DN and
              the authorized third parties referred to herein located in the
              United States.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>IP Addresses; Device ID Information: </span>
              The requests you make to DN may contain your IP address (the
              Internet address of your computer). We may use visitors’ IP
              addresses for various purposes, including to display customized
              content and analyze and report upon the usage of the Service and
              content, features and services made available through the Service;
              to diagnose and prevent service or technology problems affecting
              the Service; and to monitor and prevent fraud and abuse. If you
              access the Service on a mobile device, we may also collect your
              device identification number and request access to settings and
              location information for similar purposes and to personalize your
              experience with the Service.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>Location Information: </span>
              The Service may collect and use your location information (for
              example, by using the GPS on your mobile device or through
              connected Social Networking Services) to provide certain
              functionality. We may also use your location information in an
              aggregate way, as described below in the “Aggregated Personal
              Data” section. The collection and tracking of your location
              information may occur even when the Service, including any mobile
              application, is not actively open and running.
            </p>
            <p>
              You may change the privacy settings of your mobile device to turn
              off the functionality to share location information with the
              Service. Please note that turning off location sharing may affect
              certain features of the Service. If you have any questions about
              the privacy settings of your mobile device, we recommend that you
              contact the manufacturer or your mobile service provider for
              assistance.
            </p>
          </div>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Other Information:</h3>
          <div className="policyContent">
            <p>
              <span>Non-Identifiable Data: </span>
              When you interact with DN through the Service, we and third
              parties that may provide certain functionality on the Service,
              such as advertising networks, use various technologies to
              passively collect information that cannot by itself be used to
              specifically identify you (“non-identifiable data”). DN may store
              such information itself or such information may be included in
              databases owned and maintained by DN affiliates, agents or service
              providers. We may use such information and pool it with other
              information to track, for example, the total number of visitors to
              the Service, the number of visitors to each page of the Service,
              the number of visitors and in-game activity per level of gameplay
              for our games, the domain names of our visitors’ Internet service
              providers, and the devices, operating systems and browsers used by
              our visitors. It is important to note that no Personal Data is
              available or used in this process.
            </p>
            <p>
              In operating and providing the Service, we use technologies called
              “cookies” and “deep links.” A cookie is a piece of information
              that the computer that hosts the Service gives to your browser
              when you access the Service. Cookies and deep links help us
              provide additional functionality to the Service, personalize your
              experience with the Service and analyze Service usage more
              accurately. For instance, we may set a cookie on your browser that
              allows you to access the Service without needing to remember and
              then enter a password more than once during a visit to the
              Service. In all cases in which we use cookies, we will not collect
              Personal Data except with your permission. On most web browsers,
              you will find a “help” section on the toolbar. Please refer to
              this section for information on how to receive notification when
              you are receiving a new cookie and how to turn cookies off. We
              recommend that you leave cookies turned on because they allow you
              to take advantage of some of the Service’s features.
            </p>
            <p>
              We also may use Google Analytics and similar tools to help analyze
              how users use the Service. Google Analytics uses cookies to
              collect standard Internet log-in information and visitor behavior
              information. The information generated by the cookie about your
              use of the Service (including IP address) is transmitted to and
              stored by Google. This information is then used to evaluate
              visitors’ uses of the Service, compile statistical reports on
              activity on the Service and enhance your experience when you use
              the Service. Google will not associate your IP address with any
              other data held by Google. For more information on how Google uses
              this data, go to{' '}
              <a href="https://policies.google.com/technologies/partner-sites">
                www.google.com/policies/privacy/partners/.
              </a>
            </p>
          </div>
          <div className="policyContents">
            <p>
              <span>Response to “Do Not Track” Signals: </span>
              At present, we do not respond to “do not track” signals sent from
              web browsers. If we do so in the future, we will provide all
              relevant information in this policy.
            </p>
            <p>
              This Privacy Policy covers the use of cookies, deep links and
              similar technologies by DN only. Uses of these or similar
              technologies by any third-party advertiser or web service linked
              from the Service (including services of DN’s Partners and other
              business partners) are governed by each such advertiser or web
              service’s privacy policy.
            </p>
            <p>
              When DN offers the Service in connection with Partners, such
              Partners may use other technologies to collect information about
              you and your use of the Service. The manner in which Partners use,
              store and disclose your information is governed by the policies of
              the applicable Partner, and, as a result, DN shall not have any
              liability or responsibility for the privacy practices or other
              actions of a Partner that may be enabled within and/or otherwise
              accessible through the Service. If you use the Service as offered
              in connection with a Partner, please review such Partner’s privacy
              policy for a description of how the Partner collects and uses your
              information.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>Aggregated Personal Data: s</span>
              In an ongoing effort to better understand and serve the users of
              the Service, DN often conducts research on its customer
              demographics, interests and behavior based on the Personal Data
              and other information provided to us. This research may be
              compiled and analyzed on an aggregate basis, and DN may share this
              aggregate data with its affiliates, agents and business partners
              or otherwise use it for any lawful purpose. This aggregate
              information does not identify you personally. DN may also disclose
              aggregated user statistics in order to describe the Service to
              current and prospective business partners, and to other third
              parties for other lawful purposes.
            </p>
          </div>
          <div className="policyContent">
            <span>Our Use of Your Personal Data and Other Information: </span>
            <p>
              DN uses the Personal Data you provide in a manner that is
              consistent with this Privacy Policy. If you provide Personal Data
              for a certain reason, we may use the Personal Data in connection
              with the reason for which it was provided. For instance, when we
              offer a game or other Service feature, such as a contest, with a
              Partner and you register for the game or feature, your Personal
              Data may be shared with such Partner to facilitate your
              participation. If you contact us by email, we will use the
              Personal Data you provide to answer your question or resolve your
              problem. Also, if you provide Personal Data in order to obtain
              access to the Service, we will use your Personal Data to provide
              you with access to the Service, to provide you with game
              notifications and to monitor your use of the Service. DN and its
              subsidiaries and affiliates (the “DN Related Companies”) may also
              use your Personal Data and other personally non-identifiable
              information collected through the Service to help us improve the
              content and functionality of the Service, to better understand our
              users and to improve the Service.
            </p>
            <p>
              DN and its affiliates and Partners may use your Personal Data and
              other information to communicate with you about content, features,
              promotions and services we believe will be of interest to you,
              such as upcoming DN or Partner games. We will send such messages
              only with your consent, which we will ask you for when you
              register for your account with the Service. If we do so, each
              communication we send you will contain instructions permitting you
              to “opt-out” of receiving future communications. In addition, if
              at any time you wish not to receive any future communications or
              you wish to have your name deleted from our mailing lists, please
              contact us as indicated below.
            </p>
            <p>
              If DN intends on using any Personal Data in any manner that is not
              consistent with this Privacy Policy, you will be informed of such
              anticipated use prior to or at the time at which the Personal Data
              is collected.
            </p>
          </div>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Our Disclosure of Your Personal Data and Other Information:</h3>
          <p>
            DN considers your information to be a vital part of our relationship
            with you. There are, however, certain circumstances in which we may
            share your Personal Data with certain third parties without further
            notice to you, as set forth below (and as previously set forth
            above):
          </p>
          <div className="policyContent">
            <p>
              <span>Partners: </span>
              We often work with Partners to make available social games,
              provide content or services, sponsor contests or sweepstakes and
              display advertisements. If you link to the Service through a
              Partner’s website or service, we may provide such Partner with
              information about you and your use of the Service, including your
              Personal Data, the games you play through the Service and your
              activities during gameplay. We may also share information about
              your use of the Service with our other Partners on an aggregate
              basis
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>Business Transfers: </span>
              As we develop our business, we might sell or buy businesses or
              assets. In the event of a corporate sale, merger, reorganization,
              dissolution or similar event, Personal Data may be part of the
              transferred assets. We cannot control how an acquiring or
              surviving entity may use or disclose such information.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>Related Companies: </span>
              We may also share your Personal Data with our Related Companies
              for purposes consistent with this Privacy Policy.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>Agents, Consultants, Vendors and Service Providers: </span>
              DN, like many businesses, sometimes hires other companies to
              perform certain business-related functions. Examples of such
              functions include mailing information, maintaining databases and
              performing customer service. When we employ another company to
              perform a function of this nature, we only provide such company
              with the information needed to perform its specific function. DN
              will take measures to ensure that such companies do not use your
              information for any other purposes.
            </p>
          </div>
          <div className="policyContent">
            <p>
              <span>As Required By Law and Similar Disclosures: </span>
              DN may disclose your Personal Data if required to do so by law or
              in the good faith belief that such action is necessary to (i)
              comply with a legal obligation, (ii) protect and defend the rights
              or property of DN, (iii) act in urgent circumstances to protect
              the personal safety of users of the Service or the public, or (iv)
              protect against legal liability.
            </p>
          </div>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Your Choices: </h3>
          <p>
            You can use certain features of the Service without providing any
            Personal Data. If you choose not to provide any Personal Data, you
            may not be able to use all features of the Service.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Exclusions: </h3>
          <h3>
            <p>
              This Privacy Policy does not apply to any Personal Data collected
              by DN other than Personal Data collected through the Service. This
              Privacy Policy shall not apply to any unsolicited information you
              provide to DN through the Service or through any other means. This
              includes, but is not limited to, information posted to any public
              areas of the Service, such as comment sections or bulletin boards
              (collectively, “Public Areas”), any ideas for new products or
              services or modifications to existing products or services, and
              other unsolicited submissions (collectively, “Unsolicited
              Information”). All Unsolicited Information shall be deemed to be
              non-confidential and DN shall be free to reproduce, use, disclose,
              and distribute such Unsolicited Information to others without
              limitation or attribution.
            </p>
          </h3>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Children: </h3>
          <p>
            DN does not knowingly collect Personal Data from children under the
            age of 13. If you are under the age of 13, please do not submit any
            Personal Data through the Service. We encourage parents and legal
            guardians to monitor their children’s Internet usage and to help
            enforce our Privacy Policy by instructing their children never to
            provide Personal Data on the Service without their permission. If
            you have reason to believe that a child under the age of 13 has
            provided Personal Data to DN through the Service, please contact us,
            and we will endeavor to delete that information from our databases.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Links to Other Websites: </h3>
          <p>
            This Privacy Policy applies only to the Service. The Service may
            contain links to other websites not operated or controlled by DN
            (the “Third-Party Sites”). The policies and procedures we described
            here do not apply to the Third-Party Sites. The links from the
            Service do not imply that DN endorses or has reviewed the
            Third-Party Sites. We suggest contacting those sites directly for
            information on their privacy policies.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Social Networking Services: </h3>
          <p>
            Through the Service, we may offer you the opportunity to access
            certain social media and social networking websites and services
            that are owned and/or controlled by third parties (including,
            without limitation, Facebook and Twitter) (such websites and
            services, collectively, the “Social Networking Services”). When you
            elect to access and use the Social Networking Services, you will be
            sharing your information (which will include Personal Data if you
            elect to share such information) with those Social Networking
            Services. As with other Third-Party Sites, the information that you
            share with the Social Networking Services will be governed by the
            privacy policies and terms of service of the providers of such
            Social Networking Services and not by the policies and procedures we
            describe here. You may also be able to modify your privacy settings
            with these Social Networking Services to, for example, control what
            information the Social Networking Services disclose to other
            entities, including DN. When you log into the Service using a Social
            Networking account or otherwise link your DN account with a Social
            Networking Service account, we will collect relevant information
            necessary to enable the Service to access that Social Networking
            Service; however, you will provide any login information, like your
            password, directly to such Social Networking Service (and not to
            DN). As part of such integration, the Social Networking Service will
            provide DN with access to certain information that you have provided
            to such Social Networking Service and information about your use of
            such Social Networking Service, and we will use, store and disclose
            such information in accordance with this Privacy Policy and, if and
            to the extent applicable, the policies of such Social Networking
            Services. However, please remember that the manner in which Social
            Networking Services use, store and disclose your information is
            governed by the policies of the applicable Social Networking
            Services provider, and, as a result, DN shall not have any liability
            or responsibility for the privacy practices or other actions of
            Social Networking Services that may be enabled within and/or
            otherwise accessible through the Service.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Security: </h3>
          <p>
            DN takes reasonable steps to protect the Personal Data provided via
            the Service from loss, misuse, and unauthorized access, disclosure,
            alteration, or destruction. However, no Internet or email
            transmission is ever fully secure or error-free. In particular,
            email sent to or from the Service may not be secure. Therefore, you
            should take special care in deciding what information you send to us
            via email. Please keep this in mind when disclosing any Personal
            Data to DN via the Internet.
          </p>
          <p>
            By using the Service or providing Personal Data to us, you agree
            that we may communicate with you electronically regarding security,
            privacy and administrative issues relating to your use of the
            Service. If we learn of a security system’s breach, we may attempt
            to notify you electronically by posting a notice on the Service or
            by sending an email to you. You may have a legal right to receive
            this notice in writing. To receive free written notice of a security
            breach (or to withdraw your consent from receiving electronic
            notice), please notify us at{' '}
            <a
              className="anchorColor"
              onClick={() => contactSupport('mailSupport')}>
              support@dailynumber.app.
            </a>
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>International Users: </h3>
          <p>
            All Personal Data is stored on servers in the United States and is
            subject to United States law. By choosing to use the Service or
            otherwise provide information to us, you agree that any dispute over
            privacy or the terms contained in this Privacy Policy will be
            governed by the law of the Commonwealth of Massachusetts and the
            adjudication of any disputes arising in connection with DN or the
            Service be in accordance with the Terms of Service.
          </p>
          <p>
            If you are visiting from the European Union or other regions with
            laws governing data collection and use, please note that you are
            agreeing to the transfer of your information to the United States
            and the global processing of such information. By providing your
            information you consent to any transfer and processing in accordance
            with this Privacy Policy.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Other Terms and Conditions: </h3>
          <p>
            Your access to and use of the Service is subject to the Terms of
            Service.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Changes to DN’s Privacy Policy: </h3>
          <p>
            The Service and our business may change from time to time. As a
            result, at times it may be necessary for DN to make changes to this
            Privacy Policy. DN reserves the right to update or modify this
            Privacy Policy at any time and from time to time without prior
            notice. Please review this policy periodically, and especially
            before you provide any Personal Data. This Privacy Policy was last
            updated on the date indicated above. Your continued use of the
            Service after any changes or revisions to this Privacy Policy shall
            indicate your agreement with the terms of such revised Privacy
            Policy.
          </p>
          <p>
            <span>New Uses of Personal Data: </span>
            From time to time, we may desire to use Personal Data for uses not
            previously disclosed in our Privacy Policy. If our practices change
            regarding previously collected Personal Data in a way that would be
            materially less restrictive than stated in the version of this
            Privacy Policy in effect at the time we collected the information,
            we will make reasonable efforts to provide notice and obtain consent
            to any such uses as may be required by law.
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>Access to Information; Contacting DN: </h3>
          <p>
            To keep your Personal Data accurate, current, and complete, you may
            log in to your account and review and update your information or
            contact us as specified below. We will take reasonable steps to
            update or correct Personal Data in our possession that you have
            previously submitted via the Service.
          </p>
          <p>
            Please also feel free to contact us if you have any questions about
            DN’s Privacy Policy or the information practices of the Service.
          </p>
          <p>
            You may contact us as follows:{' '}
            <a
              className="anchorColor"
              onClick={() => contactSupport('mailSupport')}>
              support@dailynumber.app
            </a>
            .
          </p>
        </div>
        <div className="privacyPolicyWrap">
          <h3>** Your California Privacy Rights: </h3>
          <p>
            Applicable California privacy legislation permits visitors who are
            California residents to request certain information regarding our
            disclosure of personal information to third parties for their direct
            marketing purposes. To make such a request to access such
            information, please contact us at{' '}
            <a
              className="anchorColor"
              onClick={() => contactSupport('mailSupport')}>
              support@dailynumber.app
            </a>
            . Within thirty (30) days of receiving such a request, we will
            provide a list of the categories of personal information disclosed
            to third parties for third-party direct marketing purposes during
            the immediately preceding calendar year, along with the names and
            addresses of these third parties. This request may be made no more
            than once per calendar year. We reserve our right not to respond to
            requests submitted other than to the address specified in this
            paragraph.
          </p>
        </div>
      </div>
    </div>
  );
};
