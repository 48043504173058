/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
  checkIfUserExistsSocial,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
} from 'Actions/new-auth-action';
import { Alert } from '../Alert';

import icon from '../../assets/icons/icons.svg';

export const EmailLoginPage = ({ history }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [responseError, setResponseError] = useState('');
  const [errorDisabled, setErrorDisabled] = useState(true);
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [alertTemplate, setAlertTemplate] = useState('red');

  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;
  const { phone, unVerifiedEmail, successMsg } = history.location.state
    ? history.location.state
    : '';
  const inputElement = useRef(null);

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
    if (unVerifiedEmail) {
      setEmail(unVerifiedEmail);
      setBtnDisabled(false);
    }
    if (successMsg) {
      setResponseError(successMsg);
      setAlertTemplate('green');
      setToggleAlertBox(true);
    }
  }, []);

  useEffect(() => {
    const input = document.getElementById('emailTextArea');
    if (input) {
      // eslint-disable-next-line func-names
      // eslint-disable-next-line consistent-return
      input.addEventListener('keydown', function(event) {
        if (event.keyCode === 13) {
          if (!document.getElementById('submitButton').disabled) {
            document.getElementById('submitButton').click();
          }
          event.preventDefault();
          return false;
        }
      });
    }
  }, []);

  const back = () => {
    const path = `/login`;
    history.push(path);
  };

  const loginEmailSent = () => {
    const path = `/m-login-email-sent`;
    history.push(path, { email });
  };

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    // setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  const isUserExists = response => {
    setForbiddenErr('');
    setError('');
    checkIfUserExistsSocial(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setTimeout(function() {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
              action: 'Email Login',
              formData: {
                user: email,
              },
            });
          }, 2000);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          code === APP_CONSTANTS.API_STATUS_CODE.PHONE_NOT_VERIFIED
        ) {
          const phoneWithoutCode = data.phoneNo.slice(-10);
          const path = `/m-login-phone`;
          history.push(path, {
            phone: +phoneWithoutCode,
            email: data.email,
            successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.PHONE_NOT_VERIFIED,
          });
        }
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.OK &&
          code === APP_CONSTANTS.API_STATUS_CODE.DRAFT_USER_ALREADY_EXISTS
        ) {
          const path = `/m-signup-dob`;
          history.push(path, {
            email: data.email,
            successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.DOB_AFTER_EMAIL,
          });
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.SELF_EXCLUDED
        ) {
          setForbiddenErr(MESSAGE_CONSTANTS.ERROR_MSG.SELF_EXCLUSION);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_INSERT_FAILED
        ) {
          setError(MESSAGE_CONSTANTS.ERROR_MSG.EMAIL_PASSWORD_MATCH);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.INVALID_EMAIL
        ) {
          setError(MESSAGE_CONSTANTS.ERROR_CODE.INVALID_EMAIL);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_NOT_EXISTS
        ) {
          history.push('/m-signup-phone', {
            successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.EMAIL_VERIFIED_SIGNUP,
          });
        }
      });
  };

  const responseFacebook = response => {
    setInSessionStorage(SOCIAL_SIGNUP_KEY.EMAIL, response.email);
    setInSessionStorage(
      SOCIAL_SIGNUP_KEY.FIRST_NAME,
      response.name && firstName(response.name),
    );
    setInSessionStorage(
      SOCIAL_SIGNUP_KEY.LAST_NAME,
      response.name && lastName(response.name),
    );
    setInSessionStorage(SOCIAL_SIGNUP_KEY.SIGNUP_TYPE, 'facebook');
    setInSessionStorage(SOCIAL_SIGNUP_KEY.USERNAME, response.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.FACEBOOK_TOKEN, response.accessToken);
    const data = {
      firstName: response.name && firstName(response.name),
      lastName: response.name && lastName(response.name),
      email: response.email,
      facebookToken: response.accessToken,
      username: response.email,
      loginstrategy: 'facebook',
    };
    if (response && response.status !== APP_CONSTANTS.RESPONSE_STATUS.UNKNOWN) {
      isUserExists(data);
    }
  };

  const responseGoogle = response => {
    const { profileObj } = response;
    setInSessionStorage(SOCIAL_SIGNUP_KEY.EMAIL, profileObj.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.FIRST_NAME, profileObj.givenName);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.LAST_NAME, profileObj.familyName);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.SIGNUP_TYPE, 'gmail');
    setInSessionStorage(SOCIAL_SIGNUP_KEY.USERNAME, profileObj.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.GMAIL_ID, profileObj.googleId);
    const data = {
      firstName: profileObj.givenName,
      lastName: profileObj.familyName,
      email: profileObj.email,
      username: profileObj.email,
      loginstrategy: 'gmail',
      gmailId: profileObj.googleId,
    };
    isUserExists(data);
  };

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const customerId = query.get('customerid');
  const externalToken = query.get('token');

  const errGoogle = response => {
    // Handle Error
  };

  const requestEmail = response => {
    setForbiddenErr('');
    setError('');
    requestEmailVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          loginEmailSent();
        }
      })
      .catch(error => {
        const { status, message, code } = error.response.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST) {
          setResponseError(message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }
      });
  };

  function handleChange(event) {
    // eslint-disable-next-line max-len
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(event.target.value)) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.
      setEmail(event.target.value);
      setBtnDisabled(false);
    } else {
      // invalid email, maybe show an error to the user.
      setEmail();
      setBtnDisabled(true);
    }
  }

  const handleSubmit = () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        email,
      },
    });
    // evt.preventDefault();
    if (!email) {
      return;
    }
    const data = {
      email,
    };
    if (phone) {
      data.phoneNo = phone;
    }
    requestEmail(data);
  };
  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}
                  className="button button-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={responseError}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body justify-start">
          <div className="title inputAreaWrapper">
            <input
              type="text"
              className="input"
              placeholder="Enter your Email"
              autoCapitalize="none"
              autoComplete="off"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              value={email}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
          <p className="copy">We’ll send you a link you can use to log in</p>

          <div className="loginOptions ">
            <p className="copy or">Or, continue with</p>
            <div className="buttonGroup">
              <FacebookLogin
                appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                fields="name, email, picture"
                /* callback={responseFacebook} */
                callback={res => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Facebook Login Response',
                    facebookResponse: responseFacebook,
                  });
                  /* return responseFacebook; */
                  responseFacebook(res);
                }}
                cssClass="loginOptions__item button button-white"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-facebook`} />
                  </svg>
                }
                textButton=""
                scope="email,public_profile"
                disableMobileRedirect
                isMobile={detectDevice()}
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Facebook Login',
                  });
                }}
              />
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                render={renderProps => (
                  <button
                    className="loginOptions__item button button-white"
                    onClick={() => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN,
                        {
                          action: 'Google Login',
                        },
                      );
                      renderProps.onClick();
                    }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24">
                      <use xlinkHref={`${icon}#i-google`} />
                    </svg>
                  </button>
                )}
                onSuccess={res => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Google Login Success',
                    successResponse: responseGoogle,
                  });
                  responseGoogle(res);
                }}
                onFailure={err => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
                    action: 'Google Login Failure',
                    failureResponse: errGoogle,
                  });
                  errGoogle(err);
                }}
                /* onSuccess={responseGoogle}
                onFailure={errGoogle} */
              />
            </div>
            <p className="copy terms">
              By accessing Daily Number you are agreeing to the&nbsp;
              <a href="/terms-of-service">Terms of Service</a>&nbsp;&&nbsp;
              <a href="/privacy-statement">Privacy Policy.</a>
            </p>
          </div>
        </div>
        <div className="ffScreen__footer">
          <button
            id="submitButton"
            className="button button-full button-blue"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                action: 'arrow back',
              });

              handleSubmit();
            }}
            disabled={btnDisabled}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};
