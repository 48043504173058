/* eslint-disable max-len */
import React from 'react';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { StarRating } from 'Components/StarRating';
import { CustomRangeSelector } from 'Components/LineUpScreens/Lineup/CustomRangeSelector';
import { LineupEntryFee } from 'Components/LineUpScreens/Lineup/LineupEntryFee';
import { Button } from 'Components/Form-Elements/Button';
import { injuryTypes } from 'Components/LineUpScreens/SportData/sportsObject';
import { DesktopDesignPlayerListing } from 'Components/LineUpScreens/PlayerListing/newDesignPlayerListing';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { getLastName } from 'Utils/string-utils';
import icon from '../../../assets/icons/icons.svg';

export const LineUpPositionDesign = ({
  lineUp,
  editLineUpRawData,
  handleActivePosition,
  getSelectedPlayers,
  lineUpStrength,
  lineUpFinalWin,
  calculateAvg,
  contestData,
  isActivePosition,
  enablewinScreen,
  rangeData,
  rangeSlideValue,
  changeRangeValue,
  handleEntryFeeChange,
  entryFee,
  winAmountString,
  selectedEntryFee,
  handleShowPlayer,
  submitLineup,
  buttonEnable,
  walletBalance,
  editLineupId,
  deleteLineup,
  resetPlayersFromLineup,
  isSimpleLineup,
  getPlayer,
  getPlayerGame,
  getActivePosition,
  deselectAllposition,
  addPlayerToPosition,
  removePlayerFromLineup,
  dataPlayers,
}) => {
  const getTotalFPForLineUp = () => {
    let totalFP = 0;
    lineUp.forEach(position => {
      if (position.player && position.player.seasonStats) {
        totalFP += position.player.seasonStats.averageFP;
      }
    });
    return totalFP.toFixed(2);
  };

  const getAVGForlineUp = () => {
    const count = getSelectedPlayers();
    if (count.length) {
      const totalFp = getTotalFPForLineUp();
      return (totalFp / count.length).toFixed(2);
    }
    return 0;
  };

  const renderTargetNumberScore = () => {
    return (
      <div className="blackBox lineUpNumbers d-flex justify-content-between align-items-center">
        <div className="yourLineup">
          <span className="d-block">
            {MESSAGE_CONSTANTS.LINEUP.YOUR_LINEUP}
          </span>
          <p>
            <strong>{getTotalFPForLineUp()}</strong>
            {getAVGForlineUp()}
            {MESSAGE_CONSTANTS.LINEUP.AVG}
          </p>
        </div>
        <div className="rating text-center">
          <strong>vs</strong>
          <div className="stars">
            <div className="rating text-center">
              <div className="starWrapper d-flex">
                <StarRating
                  rating={lineUpStrength.lineUpstrength || 0}
                  starRatedColor="rgb(251, 214, 3)"
                  starEmptyColor="rgb(148, 148, 148)"
                  starDimension="11px"
                  starSpacing="0.5px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="yourLineup text-right">
          <span className="d-block">
            {MESSAGE_CONSTANTS.LINEUP.DAILY_NUMBER}
          </span>
          <p>
            <strong>
              {(lineUpFinalWin && lineUpFinalWin.targetNumber) ||
                (contestData && contestData.targetNumber) ||
                '0.0'}
            </strong>
            {calculateAvg(lineUpFinalWin.targetNumber) || '0'}
            {MESSAGE_CONSTANTS.LINEUP.AVG}
          </p>
        </div>
      </div>
    );
  };

  const renderCustomPlay = () => {
    return (
      <div className="greyBox d-flex justify-content-between align-items-start">
        <div
          className={`topBlock ${
            editLineUpRawData.length && editLineUpRawData[0].lateScratch
              ? 'u-notClickable'
              : ''
          }`}>
          {enablewinScreen && rangeData.length > 1 && (
            <CustomRangeSelector
              rangeSlideValue={rangeSlideValue}
              rangeData={rangeData}
              changeRangeValue={changeRangeValue}
              isModal
            />
          )}
          <LineupEntryFee
            isModal
            handleEntryFeeChange={handleEntryFeeChange}
            selectedEntryFee={selectedEntryFee}
            entryFee={entryFee}
            lineUpFinalWin={lineUpFinalWin}
            winAmountString={winAmountString}
          />
        </div>
        <div className="btnBlock">
          {!enablewinScreen && (
            <Button
              className="btn btn-primary btn-block"
              label="Choose Next Player"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                  action: 'Choose Next Player',
                  formData: {
                    selectedEntryFee: selectedEntryFee.amount,
                    players: getSelectedPlayers(),
                    remainingWalletBalance: walletBalance.amount,
                    starRating: lineUpStrength.lineUpstrength,
                    targetNumber: contestData.targetNumber,
                  },
                });
                handleShowPlayer();
              }}
              disabled={!selectedEntryFee || !lineUp.length || !buttonEnable}
            />
          )}

          <Button
            className={`btn dd btn-default ${
              editLineUpRawData.length && editLineUpRawData[0].lateScratch
                ? 'u-notClickable'
                : ''
            }`}
            label="Reset"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                action: 'Reset',
                formData: {
                  selectedEntryFee: selectedEntryFee.amount,
                  players: getSelectedPlayers(),
                  lineupFp: getTotalFPForLineUp(),
                  remainingWalletBalance: walletBalance.amount,
                  starRating: lineUpStrength.lineUpstrength,
                  targetNumber: contestData.targetNumber,
                },
              });
              resetPlayersFromLineup();
            }}
            disabled={!getSelectedPlayers().length}
          />
          <Button
            className={`btn btn-default deleteBtn ${
              editLineUpRawData.length && editLineUpRawData[0].lateScratch
                ? 'u-notClickable'
                : ''
            }`}
            label="Delete"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                action: 'Delete',
                formData: {
                  selectedEntryFee: selectedEntryFee.amount,
                  players: getSelectedPlayers(),
                  lineupFp: getTotalFPForLineUp(),
                  remainingWalletBalance: walletBalance.amount,
                },
              });
              deleteLineup();
            }}
          />

          {enablewinScreen && (
            <Button
              className="btn btn-success btn-block"
              label="Submit Lineup"
              onClick={submitLineup}
            />
          )}

          <span
            className={`bottomLine ${walletBalance.isLowBalance &&
              'lowFundRedText'}`}>
            {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true'
              ? `$${walletBalance.amount}
          ${MESSAGE_CONSTANTS.LINEUP.BALANCE_AVAILABLE}`
              : ''}
          </span>
        </div>
      </div>
    );
  };

  const renderLineUpDesign1 = (design2 = false) => {
    if (lineUp.length) {
      return lineUp.map(position => {
        const { player, playerGame } = position;
        const injuryStatus =
          (player &&
            player.injury &&
            player.injury.status &&
            player.injury.status.charAt(0)) ||
          (player.statusInShort &&
            (injuryTypes.mlb.includes(player.statusInShort) ||
              injuryTypes.nfl.includes(player.statusInShort)) &&
            player.statusInShort);
        const displayName = player ? getLastName(player.name) : '';
        const ffpg =
          (player && player.seasonStats && player.seasonStats.averageFP) || '';

        return (
          <li
            className={`lineupCard__playerListItem ${!player ? 'isEmpty' : ''}`}
            key={position.position}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SELECT_LINEUP, {
                action: 'Select Position',
                formData: { position },
                lineupFp: getTotalFPForLineUp(),
                remainingWalletBalance: walletBalance.amount,
                starRating: lineUpStrength.lineUpstrength,
                targetNumber: contestData.targetNumber,
              });
              handleActivePosition(position, true);
            }}>
            {!design2 && (
              <h3 className="position">
                {MESSAGE_CONSTANTS.NEW_GAME.POSITION_MAPPING[position.position]}
              </h3>
            )}
            <div
              className={`playerAvatar ${position.isActive ? 'selected' : ''} ${
                playerGame &&
                !playerGame.lateScratchEnabled &&
                playerGame.activeTab
                  ? 'lock'
                  : ''
              }`}>
              <span
                className="playerAvatar__image"
                data-label={
                  MESSAGE_CONSTANTS.NEW_GAME.POSITION_MAPPING[position.position]
                }
                style={{
                  backgroundImage: `url(${player.picture})`,
                }}
              />
              {playerGame &&
                !playerGame.lateScratchEnabled &&
                playerGame.activeTab && (
                  <span className="lock__screenIcon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16">
                      <use xlinkHref={`${icon}#i-lock`} />
                    </svg>
                  </span>
                )}
              {injuryStatus && (
                <span className="playerAvatar__statusBottomLeft">
                  {injuryStatus}
                </span>
              )}
            </div>
            <h2 className="name">{displayName}</h2>
            <p className="points">{ffpg}</p>
            <time />
          </li>
        );
      });
    }
    return null;
  };

  return (
    <main className="nodal__body">
      <div className={`mPlayers ${isActivePosition() ? 'isActive' : ''}`}>
        <div className="lineupCard">
          <div className="lineupCard__body">
            <div className="lineupCard__playerListScroll">
              <ul className="lineupCard__playerList">
                {renderLineUpDesign1()}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mStat">{renderTargetNumberScore()}</div>
      {!isActivePosition() && <div className="mMod">{renderCustomPlay()}</div>}
      <div className={`mDropdown ${isActivePosition() ? 'isOpen' : ''}`}>
        {isActivePosition() && (
          <DesktopDesignPlayerListing
            alreadySelectedPlayer={getPlayer()}
            alreadySelectedPlayerGameData={getPlayerGame()}
            selectedEntryFee={selectedEntryFee}
            positionData={getActivePosition()}
            hidePlayersDesign={deselectAllposition}
            addPlayer={addPlayerToPosition}
            selectedPlayers={getSelectedPlayers()}
            removePlayer={removePlayerFromLineup}
            dataPlayers={dataPlayers}
            contestData={contestData}
          />
        )}
      </div>
    </main>
  );
};

LineUpPositionDesign.defaultProps = {
  handleActivePosition: () => {},
  isSimpleLineup: false,
  calculateAvg: () => {},
  isActivePosition: () => false,
  getSelectedPlayers: 0,
  lineUp: [],
  lineUpStrength: {},
  lineUpFinalWin: {},
  contestData: {},
};
