import React, { useState, useEffect } from 'react';
import { Input } from 'Components/Form-Elements/Input';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { isDate, isValidDate } from 'Utils/date-utils';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

const YEAR_STR_LEN = 4;

function validateNumers(evt) {
  const excludeKeys = [8, 9, 13, 46, 110, 190];
  const charCode = evt.which ? evt.which : evt.keyCode;
  if (
    !(
      excludeKeys.includes(charCode) ||
      (charCode >= 35 && charCode <= 40) ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 96 && charCode <= 105)
    )
  ) {
    evt.preventDefault();
  }
}

export const DatePicker = ({ handleDateChange, setdisableNext = () => {} }) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    if (day && month && year && year.length === YEAR_STR_LEN && isDate(date)) {
      if (isValidDate(year, month - 1, day)) {
        // eslint-disable-next-line
        handleDateChange(date);
        setDateError('');
      } else {
        setdisableNext(true);
        handleDateChange('');
        setDateError(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_DATE);
      }
    } else {
      setdisableNext(true);
      handleDateChange('');
    }
  }, [day, month, year]);

  return (
    <>
      <div className="birthdyInputs d-flex">
        <div className="form-group">
          <Input
            id="month"
            inputType="number"
            className="form-control"
            textLabel="Month"
            placeholder="Month"
            value={month}
            handleChange={value => {
              if (value.length <= 2) {
                setMonth(value);
              }
            }}
            onKeyDown={validateNumers}
            onKeyUp={me => {
              const number = Number(me.currentTarget.value);
              if (number > 12) {
                setMonth(12);
              } else if (
                me.currentTarget.value &&
                me.currentTarget.value === '00'
              ) {
                setMonth(12);
              }
            }}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_DOB, {
                action: 'Month',
              });
            }}
          />
        </div>
        <div className="form-group">
          <Input
            id="day"
            inputType="number"
            className="form-control"
            textLabel="Day"
            placeholder="Day"
            value={day}
            handleChange={value => {
              if (value.length <= 2) {
                setDay(value);
              }
            }}
            onKeyDown={validateNumers}
            onKeyUp={me => {
              const number = Number(me.currentTarget.value);
              if (number > 31) {
                setDay(31);
              } else if (
                me.currentTarget.value &&
                me.currentTarget.value === '00'
              ) {
                setDay(31);
              }
            }}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_DOB, {
                action: 'Day',
              });
            }}
          />
        </div>
        <div className="form-group">
          <Input
            id="year"
            inputType="number"
            className="form-control"
            textLabel="Year"
            placeholder="Year"
            value={year}
            handleChange={setYear}
            onKeyDown={validateNumers}
            onKeyUp={me => {
              const number = Number(me.currentTarget.value);
              if (number > new Date().getFullYear()) {
                setYear(new Date().getFullYear());
              } else if (me.currentTarget.value && number === 0) {
                setYear(new Date().getFullYear());
              }
            }}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_DOB, {
                action: 'Year',
              });
            }}
          />
        </div>
      </div>
      {dateError && (
        <div className="invalid-feedback birthDateInvalid">{dateError}</div>
      )}
    </>
  );
};
