/* eslint-disable react/button-has-type */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import {
  checkIfUserExists,
  checkIfInternationalUserExists,
} from 'Actions/login-action';
import { firstName, lastName } from 'Utils/string-utils';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { detectDevice } from 'Utils/device-utils';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import icon from '../../assets/icons/icons.svg';

export const NotificationAllow = ({ history }) => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const isMobile = detectDevice();
  const { profileContextDispatch } = useContext(ProfileContext);
  const { SOCIAL_SIGNUP_KEY } = APP_CONSTANTS;
  const { email, login } = history.location.state ? history.location.state : '';

  const back = () => {
    const path = `/m-signup-locAllow`;
    history.push(path, { email, login });
  };
  const skip = () => {
    const path = `/m-signup-add-payment`;
    history.push(path, { email, login });
  };

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  // A custom hook that builds on useLocation to parse
  // the query string for you.
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const customerId = query.get('customerid');
  const externalToken = query.get('token');

  const isInternationalUserExists = response => {
    checkIfInternationalUserExists(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
      })
      .catch(error => {
        const { status, code, message } = error.response.data;
        console.log('error');
        console.log(error);
      });
  };

  const isUserExists = response => {
    setForbiddenErr('');
    setError('');
    checkIfUserExists(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setTimeout(function() {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
              action: 'Username Login',
              formData: {
                user: userName,
              },
            });
          }, 2000);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.SELF_EXCLUDED
        ) {
          setForbiddenErr(MESSAGE_CONSTANTS.ERROR_MSG.SELF_EXCLUSION);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_INSERT_FAILED
        ) {
          setError(MESSAGE_CONSTANTS.ERROR_MSG.EMAIL_PASSWORD_MATCH);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.INVALID_EMAIL
        ) {
          setError(MESSAGE_CONSTANTS.ERROR_CODE.INVALID_EMAIL);
        } else if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_NOT_EXISTS
        ) {
          history.push('/consent');
        }
      });
  };

  const responseFacebook = response => {
    setInSessionStorage(SOCIAL_SIGNUP_KEY.EMAIL, response.email);
    setInSessionStorage(
      SOCIAL_SIGNUP_KEY.FIRST_NAME,
      response.name && firstName(response.name),
    );
    setInSessionStorage(
      SOCIAL_SIGNUP_KEY.LAST_NAME,
      response.name && lastName(response.name),
    );
    setInSessionStorage(SOCIAL_SIGNUP_KEY.SIGNUP_TYPE, 'facebook');
    setInSessionStorage(SOCIAL_SIGNUP_KEY.USERNAME, response.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.FACEBOOK_TOKEN, response.accessToken);
    const data = {
      firstName: response.name && firstName(response.name),
      lastName: response.name && lastName(response.name),
      email: response.email,
      facebookToken: response.accessToken,
      username: response.email,
      loginstrategy: 'facebook',
    };
    if (response && response.status !== APP_CONSTANTS.RESPONSE_STATUS.UNKNOWN) {
      isUserExists(data);
    }
  };

  const responseGoogle = response => {
    const { profileObj } = response;
    setInSessionStorage(SOCIAL_SIGNUP_KEY.EMAIL, profileObj.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.FIRST_NAME, profileObj.givenName);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.LAST_NAME, profileObj.familyName);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.SIGNUP_TYPE, 'gmail');
    setInSessionStorage(SOCIAL_SIGNUP_KEY.USERNAME, profileObj.email);
    setInSessionStorage(SOCIAL_SIGNUP_KEY.GMAIL_ID, profileObj.googleId);
    const data = {
      firstName: profileObj.givenName,
      lastName: profileObj.familyName,
      email: profileObj.email,
      username: profileObj.email,
      loginstrategy: 'gmail',
      gmailId: profileObj.googleId,
    };
    isUserExists(data);
  };

  const errGoogle = response => {
    // Handle Error
  };

  const handleSubmit = evt => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Submit Login Data',
      formData: {
        user_Name: userName,
      },
    });
    evt.preventDefault();
    if (!userName || !password) {
      return;
    }
    const data = {
      username: userName,
      password,
      loginstrategy: 'username',
    };
    isUserExists(data);
  };

  if (customerId && externalToken) {
    isInternationalUserExists({
      customerId,
      externalToken,
      loginstrategy: 'international',
    });
    return (
      <>
        <div className="logo" />
        <div className="landingWrapper">
          <h1>Set Your Lineup, and Game On!</h1>
          <span className="subLine">A Daily Fantasy Sports game changer.</span>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="ffScreen Signup Login">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              {/* <div className="headSection-new__leftAct">
                <button className="button button-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="ffScreen__body">
          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
            <use xlinkHref={`${icon}#i-notification`} />
          </svg>
          <h1 className="title">Keep tabs on your games</h1>
          <p className="copy">
            We’ll let you know about status changes that may affect your
            strategy
          </p>
        </div>
        <div className="ffScreen__footer">
          <button className="button button-full button-blue" disabled>
            Allow Notifications
          </button>
          <button className="button button-full" onClick={skip}>
            Skip
          </button>
        </div>
      </div>
    </>
  );
};
