/* eslint-disable no-param-reassign */
import { getCookie, getFromSessionStorage } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';

const userData = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
const guestEmail = getFromSessionStorage('guest-user-email');
export function mixPanelTrackRegistration(payload) {
  try {
    window.mixpanel.identify(payload.distinct_id);
    window.mixpanel.people.set(payload);
  } catch (error) {
    // error handle
  }
}

export function mixPanelTrackEvent(eventName, payload) {
  try {
    if (typeof eventName !== 'string' || !eventName.trim()) {
      console.warn('unSupported event:', eventName);
      return;
    }

    if (userData) {
      payload = {
        ...payload,
        distinct_id: userData.publicId,
        userId: userData.publicId,
        source: 'web',
      };
    }
    window.mixpanel.track(eventName, payload);
  } catch (error) {
    // error handle
  }
}

export function mixpanelTrackPageView(pageName, payload) {
  try {
    let payload1 = { pageViewed: pageName, source: 'web' };
    if (payload) {
      payload1 = {
        ...payload,
        ...payload1,
      };
    }
    if (userData) {
      payload1 = {
        ...payload1,
        distinct_id: userData.publicId,
        userId: userData.publicId,
      };
    } else if (guestEmail) {
      payload1 = {
        ...payload1,
        distinct_id: guestEmail,
        userId: guestEmail,
      };
    }
    window.mixpanel.track('pageViewed', payload1);
  } catch (error) {
    // error handle
  }
}
