/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint no-else-return: "error" */

import React from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { ordinalFormatNumber } from 'Utils/string-utils';
import {
  sesonalStatMapping,
  currentGameStatMapping,
} from 'Components/LineUpScreens/SportData/sportsObject';
import { formatDatellll } from 'Utils/date-utils';
import { getLastName } from 'Utils/data-utils';
import { Button } from 'Components/Form-Elements/Button';

export const renderStatsData = data => {
  return data.map(item => {
    return (
      <li className="text-center" key={`${item.title}${item.value}`}>
        <div className="teamName aboutPlayer">
          {item.title} <span>{item.value}</span>
        </div>
        {(typeof item.rank === 'number' && (
          <div className="rankPlace aboutPlayer">
            {`${item.rank}${ordinalFormatNumber(item.rank)}`}
          </div>
        )) ||
          null}
      </li>
    );
  });
};

export const renderNews = data => {
  return data.map(item => {
    const newsDate = formatDatellll(item.pubDate);
    return (
      <div className="row" key={item._id}>
        <div className="col-sm newsWrap">
          <div className="date">{newsDate}</div>
          <div className="nTitle">{item.title}</div>
          <div className="nDetail">
            {`${item.description}..`}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              href={item.link}>
              more
            </a>
          </div>
        </div>
      </div>
    );
  });
};
export const renderThisGameStatsData = data => {
  const stats = data && data.stats;
  const position = data && data.position;
  const sportSlug = data && data.sportSlug;
  if (stats && sportSlug === 'nfl') {
    const statsData = currentGameStatMapping(sportSlug, position, stats);
    const statsDataPopulated = statsData.map(stat => {
      const value = stat.value ? stat.value : 0;
      return {
        ...stat,
        value:
          Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
      };
    });
    return statsDataPopulated;
  }
  if (stats && sportSlug === 'nba') {
    const statsData = currentGameStatMapping(sportSlug, position, stats);
    const statsDataPopulated = statsData.map(stat => {
      const value = stat.value ? stat.value : 0;
      return {
        ...stat,
        value:
          Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
      };
    });
    return statsDataPopulated;
  }
  if (stats && sportSlug === 'nhl') {
    const statsData = currentGameStatMapping(sportSlug, position, stats);
    const statsDataPopulated = statsData.map(stat => {
      const value = stat.value ? stat.value : 0;
      return {
        ...stat,
        value:
          Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
      };
    });
    return statsDataPopulated;
  }
  return null;
};
export const stateDataLive = (players, liveStats) => {
  let statsData = [];
  if (liveStats) {
    const sportSlug = `${players.sportSlug}`.trim().toLowerCase();
    if (['nba', 'nfl', 'nhl'].includes(sportSlug)) {
      statsData = renderThisGameStatsData({
        position: players.position,
        sportSlug: players.sportSlug,
        stats: liveStats,
      });
    } else if (sportSlug === APP_CONSTANTS.SPORT_SLUG.MLB) {
      return liveStats;
    }
  }
  return statsData;
};

export const seasonStatPerGame = stats => {
  const statsData = APP_CONSTANTS.NBA_STATS_MAPPING.map(stat => {
    const value = stats[stat.valueField];
    return {
      ...stat,
      title: stat.title.slice(0, -2),
      value: value || 0,
    };
  });
  return statsData;
};
export const renderBonusEvents = (EVENT_DESC, liveStats) => {
  return (
    <>
      {liveStats && liveStats.dd && liveStats.dd > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.dd.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.dd.points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats && liveStats.td && liveStats.td > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.td.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.td.points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats &&
      liveStats.passing &&
      liveStats.passing.tyg &&
      liveStats.passing.tyg > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC['passing.tyg'].desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC['passing.tyg'].points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats &&
      liveStats.rushing &&
      liveStats.rushing.oyg &&
      liveStats.rushing.oyg > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC['rushing.oyg.desc']}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC['rushing.oyg'].points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats &&
      liveStats.receiving &&
      liveStats.receiving.oyg &&
      liveStats.receiving.oyg > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC['receiving.oyg'].desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC['receiving.oyg'].points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats && liveStats.fivePlusShot && liveStats.fivePlusShot > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.fivePlusShot.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.fivePlusShot.points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats && liveStats.hatTrick && liveStats.hatTrick > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.hatTrick.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.hatTrick.points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats && liveStats.threePlusPts && liveStats.threePlusPts > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.threePlusPts.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.threePlusPts.points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats && liveStats.threePlusShot && liveStats.threePlusShot > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.threePlusShot.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.threePlusShot.points}</span>
        </li>
      ) : (
        ''
      )}
      {liveStats &&
      liveStats.thirtyFiveSaves &&
      liveStats.thirtyFiveSaves > 0 ? (
        <li key={`index${Math.random()}`}>
          <div className="pointWrap">
            <strong>{EVENT_DESC.thirtyFiveSaves.desc}</strong>
            <p> </p>
          </div>
          <span className="green">{EVENT_DESC.thirtyFiveSaves.points}</span>
        </li>
      ) : (
        ''
      )}
    </>
  );
};
export const renderCurrentGamePlayerEvents = (
  events,
  showAllEvents,
  EVENT_DESC,
  getEventDetails,
  setShowAllEvents,
  liveStats,
) => {
  if (!events.length) {
    // return <div className="playerRecords playListWrap">No events</div>;
    return (
      <div className="emptyState">
        <div className="emptyState__content">
          <p>No events</p>
        </div>
      </div>
    );
  }

  const num = showAllEvents ? events.length : 4;
  return (
    <div className="playerRecords playListWrap">
      <ul className="playsList" key={Math.random()}>
        {renderBonusEvents(EVENT_DESC, liveStats)}
        {events.slice(0, num).map(event => (
          <li key={`index${Math.random()}`}>
            <div className="pointWrap">
              <strong>{EVENT_DESC[event.statType] || event.statDesc}</strong>
              <p>
                {getEventDetails(
                  event.quarter ? event.quarter : event.period,
                  event.clock,
                )}
              </p>
            </div>
            <span
              className={
                event.fpts > 0 ? 'green' : event.fpts === 0 ? 'black' : 'red'
              }>
              {event.fpts > 0
                ? `+${event.fpts.toFixed(2)}`
                : event.fpts === 0
                ? event.fpts
                : event.fpts.toFixed(2)}
            </span>
          </li>
        ))}
        {!showAllEvents && events.length > 4 ? (
          <li className="showAllPlays">
            <button
              className="link"
              type="button"
              onClick={() => setShowAllEvents(true)}>
              Show all plays
            </button>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export const renderLineupDetailsViewEvents = (
  events,
  showAllEvents,
  EVENT_DESC,
  getEventDetails,
  setShowAllEvents,
  liveStats,
  player,
  DataPlayers,
) => {
  const live =
    player.gameStatus === 'InProgress' &&
    !player.finished &&
    player.includedInLineup;
  const isMLB = player.sportSlug === 'mlb';
  const isNFL = player.sportSlug === 'nfl';
  const isNBA = player.sportSlug === 'nba';
  const isNHL = player.sportSlug === 'nhl';
  const gamePlayers = DataPlayers[player.gameId._str];
  if (!events || !events.length) {
    return (
      <ul className="gameCard__list" key={Math.random()}>
        {gamePlayers.map(player => (
          <li className="gameCard__listItem" key={`index${Math.random()}`}>
            <div
              className={`playerAvatar ${
                live ? (isNBA || isNFL ? 'green' : isNHL ? 'green' : '') : ''
              }`}>
              <span
                className="playerAvatar__image"
                style={{
                  backgroundImage: `url(${player.picture})`,
                }}
              />
            </div>
            <span className="copy">{getLastName(player.name)}</span>
          </li>
        ))}
      </ul>
    );
  }

  const num = showAllEvents ? events.length : 3;
  return (
    <>
      <ul className="gameCard__list" key={Math.random()}>
        {liveStats && liveStats.td && liveStats.td > 0 ? (
          <li className="gameCard__listItem" key={`index${Math.random()}`}>
            <div className="playerAvatar">
              <span
                className="playerAvatar__image"
                style={{
                  backgroundImage: `url(${player && player.picture})`,
                }}
              />
            </div>
            <span className="copy">{EVENT_DESC.td}</span>
            <span className="points">+1.50</span>
          </li>
        ) : (
          ''
        )}
        {events.slice(0, num).map(event => (
          <li className="gameCard__listItem" key={`index${Math.random()}`}>
            <div
              className={`playerAvatar ${
                live ? (isNBA || isNFL ? 'green' : isNHL ? 'green' : '') : ''
              }`}>
              <span
                className="playerAvatar__image"
                style={{
                  backgroundImage: `url(${event.picture})`,
                }}
              />
            </div>
            <span className="copy">
              {EVENT_DESC[event.statType] ||
                event.shortDesc.charAt(0).toUpperCase() +
                  event.shortDesc.slice(1)}
            </span>
            <span
              className={`points ${
                event.fpts > 0 ? 'green' : event.fpts === 0 ? 'black' : 'red'
              }`}>
              {event.fpts > 0
                ? `+${event.fpts.toFixed(2)}`
                : event.fpts === 0
                ? event.fpts
                : event.fpts.toFixed(2)}
            </span>
          </li>
        ))}
        {!showAllEvents && events.length > 3 ? (
          <li className="gameCard__listItem more">
            <Button
              className="button"
              label="more"
              onClick={() => setShowAllEvents(true)}
            />
          </li>
        ) : null}
      </ul>
    </>
  );
};

export const renderSeasonStateData = seasonData => {
  const stats =
    seasonData &&
    seasonData.players &&
    seasonData.players[0] &&
    seasonData.players[0].seasonRanks;
  const player = seasonData && seasonData.players && seasonData.players[0];
  if (player.seasonStats && player.sportSlug === 'mlb') {
    const isPicther = player.position && player.position === 'P';
    const statsData = isPicther
      ? APP_CONSTANTS.MLB_SEASON_STATS_MAPPING.pitcher
      : APP_CONSTANTS.MLB_SEASON_STATS_MAPPING.hitter;
    const statsDataPopulated = statsData.map(stat => {
      const value = player.seasonStats[stat.valueField];
      return {
        ...stat,
        value: value || 0,
      };
    });

    return statsDataPopulated;
  }
  if (player.seasonStats && player.sportSlug === 'nfl') {
    const { position } = player;
    const statsData = sesonalStatMapping(
      player.sportSlug,
      position,
      player.seasonStats,
    );
    const statsDataPopulated = statsData.map(stat => {
      const value = stat.value ? stat.value : 0;
      return {
        ...stat,
        value:
          Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
      };
    });
    return statsDataPopulated;
  }
  if (player.seasonStats && player.sportSlug === 'nba') {
    const { position } = player;
    const statsData = sesonalStatMapping(
      player.sportSlug,
      position,
      player.seasonStats,
    );
    const statsDataPopulated = statsData.map(stat => {
      const value = stat.value ? stat.value : 0;
      return {
        ...stat,
        value:
          Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
      };
    });
    return statsDataPopulated;
  }
  if (player.seasonStats && player.sportSlug === 'nhl') {
    const { position } = player;
    const statsData = sesonalStatMapping(
      player.sportSlug,
      position,
      player.seasonStats,
    );
    const statsDataPopulated = statsData.map(stat => {
      const value = stat.value ? stat.value : 0;
      return {
        ...stat,
        value:
          Number(value) === value && value % 1 !== 0 ? value.toFixed(2) : value,
      };
    });
    return statsDataPopulated;
  }
  return null;
};

export const renderStatDataForTabs = (
  activeTab,
  statsData,
  seasonStatData,
  sportSlug,
) => {
  if (
    APP_CONSTANTS.TABS.CURRENT_GAME === activeTab &&
    statsData &&
    statsData.length > 0
  ) {
    return renderStatsData(statsData);
  }
  if (APP_CONSTANTS.TABS.THIS_SEASON === activeTab && seasonStatData) {
    return renderStatsData(seasonStatData);
  }
  return null;
};
