/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const GameScoringRules = ({ history, match, onBack }) => {
  const { scoringGame } = match.params;

  const getGameScoringHeader = () => {
    switch (true) {
      case scoringGame === APP_CONSTANTS.SCORING_GAME.NFL:
        return APP_CONSTANTS.SCORING_GAME_HEADER_TITLE.NFL_SCORING;
      case scoringGame === APP_CONSTANTS.SCORING_GAME.NBA:
        return APP_CONSTANTS.SCORING_GAME_HEADER_TITLE.NBA_SCORING;
      case scoringGame === APP_CONSTANTS.SCORING_GAME.MLB:
        return APP_CONSTANTS.SCORING_GAME_HEADER_TITLE.MLB_SCORING;
      case scoringGame === APP_CONSTANTS.SCORING_GAME.NHL:
        return APP_CONSTANTS.SCORING_GAME_HEADER_TITLE.NHL_SCORING;
      default:
        return APP_CONSTANTS.SCORING_GAME_HEADER_TITLE.NFL_SCORING;
    }
  };

  return (
    <div className="gameScoringRulesWrapper">
      <div className="pageHeaderWrap">
        <div className="d-flex pageHeader flexStartWrap fixedHeader">
          <div className="leftSide">
            <Link
              to="#"
              className="arrowBack"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                  action: 'Back Arrow',
                });
                if (typeof onBack === 'function') {
                  onBack();
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.GAME_RULE, {
                    action: 'Back Arrow',
                  });
                } else {
                  history.push('/game-rules');
                }
              }}
            />
          </div>
          <h2>{getGameScoringHeader()}</h2>
          <div className="rightSide" />
        </div>
      </div>
      <div
        className={
          scoringGame !== APP_CONSTANTS.SCORING_GAME.NFL ? 'hidden-rules' : ''
        }>
        <div className="scoringWrapper">
          <ul className="scoringRulesWrap">
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                <strong>Play</strong>
              </div>
              <div className="scores title">
                <strong>Points</strong>
              </div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Passing Yard</div>
              <div className="scores">0.04</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Passing Touchdown
              </div>
              <div className="scores">4</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Interception Thrown
              </div>
              <div className="scores negativeScores">-1</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                2pt Passing Conversion
              </div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                300+ Yard Passing Game
              </div>
              <div className="scores">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Rushing Yard</div>
              <div className="scores">0.1</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Rushing Touchdown
              </div>
              <div className="scores">6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                2pt Rushing Conversion
              </div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                100+ Yard Rushing Game
              </div>
              <div className="scores">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Passing Reception
              </div>
              <div className="scores">1</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Receiving Yard</div>
              <div className="scores">0.1</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Receiving Touchdown
              </div>
              <div className="scores">6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                2pt Receiving Conversion
              </div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                100+ Yard Receiving Game
              </div>
              <div className="scores">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Punt Return Touchdown
              </div>
              <div className="scores">6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Kickoff Return Touchdown
              </div>
              <div className="scores">6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Fumble Lost</div>
              <div className="scores negativeScores">-1</div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          scoringGame !== APP_CONSTANTS.SCORING_GAME.NBA ? 'hidden-rules' : ''
        }>
        <div className="scoringWrapper">
          <ul className="scoringRulesWrap">
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                <strong>Play</strong>
              </div>
              <div className="scores title">
                <strong>Points</strong>
              </div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Point</div>
              <div className="scores">1</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">3 Pointer Made</div>
              <div className="scores">0.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Rebound</div>
              <div className="scores">1.25</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Assist</div>
              <div className="scores">1.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Steal</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Block</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Turnover</div>
              <div className="scores negativeScores">-0.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Double-Double Bonus
              </div>
              <div className="scores">1.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Triple-Double Bonus
              </div>
              <div className="scores">1.5</div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          scoringGame !== APP_CONSTANTS.SCORING_GAME.MLB ? 'hidden-rules' : ''
        }>
        <div className="scoringWrapper">
          <ul className="scoringRulesWrap">
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                <strong>Play (Pitchers)</strong>
              </div>
              <div className="scores title">
                <strong>Points</strong>
              </div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Innings Pitched
              </div>
              <div className="scores">1</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Strikeout</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Win</div>
              <div className="scores">4</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Complete Game</div>
              <div className="scores">2.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Shut Out</div>
              <div className="scores">2.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">No Hitter</div>
              <div className="scores">5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Earned Run</div>
              <div className="scores negativeScores">-2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Hits Against</div>
              <div className="scores negativeScores">-0.6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Walks Against</div>
              <div className="scores negativeScores">-0.6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Hit Batter</div>
              <div className="scores negativeScores">-0.6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                <strong>Play (Hitters)</strong>
              </div>
              <div className="scores title">
                <strong>Points</strong>
              </div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Single</div>
              <div className="scores">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Double</div>
              <div className="scores">5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Triple</div>
              <div className="scores">8</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Home Run</div>
              <div className="scores">10</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">RBI</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Run</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Base on balls</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Hit by pitch</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Stolen Base</div>
              <div className="scores">5</div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={
          scoringGame !== APP_CONSTANTS.SCORING_GAME.NHL ? 'hidden-rules' : ''
        }>
        <div className="scoringWrapper">
          <ul className="scoringRulesWrap">
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                <strong>Players (non-Goalie)</strong>
              </div>
              <div className="scores title">
                <strong>Points</strong>
              </div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Goal</div>
              <div className="scores">8.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Assist</div>
              <div className="scores">5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Shot on Goal</div>
              <div className="scores">1.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Blocked Shot</div>
              <div className="scores">1.3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Short Handed Point Bonus (Goal/Assist)
              </div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Shootout Goal</div>
              <div className="scores">1.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                Hat Trick Bonus
              </div>
              <div className="scores ">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                5+ Shots on Goal Bonus
              </div>
              <div className="scores ">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                3+ Blocked Shots Bonus
              </div>
              <div className="scores ">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                3+ Points Bonus
              </div>
              <div className="scores">3</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                <strong>Goalies</strong>
              </div>
              <div className="scores title">
                <strong>Points</strong>
              </div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Win</div>
              <div className="scores">6</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Save</div>
              <div className="scores">0.7</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Goal Against</div>
              <div className="scores negativeScores">-3.5</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Shutout Bonus</div>
              <div className="scores">4</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">Overtime Loss</div>
              <div className="scores">2</div>
            </li>
            <li className="d-flex">
              <div className="scoringListTitle flex-grow-1">
                35+ Saves Bonus
              </div>
              <div className="scores">3</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

GameScoringRules.propTypes = {
  history: PropTypes.objectOf({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.objectOf({
    params: PropTypes.objectOf({
      scoringGame: PropTypes.string,
    }),
  }).isRequired,
  onBack: PropTypes.func.isRequired,
};
