import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const getNotifications = async pageNo => {
  const url = `${API.USER_NOTIFICATIONS}?pageNo=${pageNo}`;
  const response = await NetworkManager.requestAuthGET(url);
  return response;
};

export const updateNotifications = async (id, isRead) => {
  const url = `${API.USER_NOTIFICATIONS}`;
  const notifsData = { read: isRead, ids: [id] };
  const response = await NetworkManager.requestAuthPUT(url, notifsData);
  return response;
};
