import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const getContestFeeDetails = async contestSlug => {
  const url = API.DRAFT_CONTEST;
  const body = [contestSlug, true];
  const response = await NetworkManager.requestPOST(url, body);
  return response;
};

export const getMyContests = async filter => {
  const url = `${API.MY_CONTEST}/${filter}`;
  // const url = `${API.MY_CONTEST_NEW}`;
  const body = [filter];
  const response = await NetworkManager.requestAuthGET(url);
  // const response = await NetworkManager.requestAuthPOST(url, body);
  return response;
};

export const getContestCount = async () => {
  const response = await NetworkManager.requestAuthGET(API.LINEUP_COUNT);
  return response;
};
