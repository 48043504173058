/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'Components/Form-Elements/Button';
import Rangeslider from 'react-rangeslider';
import {
  getUserBalance,
  createWithdrawal,
  createStartWithdrawal,
} from 'Actions/user-balance-action';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import {
  getCookie,
  getFromSessionStorage,
  setInSessionStorage,
  removeFromSessionStorage,
} from 'Utils/storage-utilities';
import { ConfirmCashOutModal } from 'Components/Wallet/ConfirmCashOutModal';
import { Image } from 'Components/Form-Elements/Image';
import { detectDevice } from 'Utils/device-utils';
import { maskCardNumber, getPaymentMethodIconClass } from 'Utils/data-utils';
import profileImg from '../../assets/images/defaultImg.png';
import 'Components/Loader/Loader.css';
// eslint-disable-next-line import/order
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const Cashout = ({
  history,
  fetchAndSetModalPage,
  closeModalBox,
  isModal,
}) => {
  const [withdrawalAmt, setWithdrawalAmt] = useState(0);
  const [withdrawal, setWithdrawal] = useState(0);
  const [isLoading, setLoaderVisibility] = useState(true);
  const [maxWithdrawLimit, setmaxWithdrawLimit] = useState(0);
  const [maxSlider, setMaxSlider] = useState(0);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState();
  const [cardsSaved, setSavedCards] = useState([]);
  const [activecard, setActiveCards] = useState(null);

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const userLocation = getFromSessionStorage('userCoords');
  const { emailAddress, imageUrl } = userdata;
  const isMobile = detectDevice();

  const callUserBalanceAPI = () => {
    setLoaderVisibility(true);
    getUserBalance(['USD'])
      .then(({ data }) => {
        setLoaderVisibility(false);
        if (data && data.availableBalance) {
          setmaxWithdrawLimit(data.availableBalance.amount);
          const addAmount = 10 - (Math.ceil(data.availableBalance.amount) % 10);
          let roundedAmount =
            Math.ceil(data.availableBalance.amount) + addAmount;
          if (Math.ceil(data.availableBalance.amount) % 10 === 0) {
            roundedAmount = data.availableBalance.amount;
          }
          setMaxSlider(roundedAmount);

          if (
            data &&
            data.availableBalance.amount < APP_CONSTANTS.MINIMUM_CASHOUT_BALANCE
          ) {
            setWithdrawal(data.availableBalance.amount);
            setWithdrawalAmt(data.availableBalance.amount);
          } else {
            setWithdrawal(APP_CONSTANTS.MINIMUM_CASHOUT_BALANCE);
            setWithdrawalAmt(APP_CONSTANTS.MINIMUM_CASHOUT_BALANCE);
          }
        }
      })
      .catch(err => {
        setLoaderVisibility(false);
        console.log(err);
      });
  };

  useEffect(() => {
    callUserBalanceAPI();
    mixpanelTrackPageView('Cash out Screen');
  }, []);

  const successScreen = () => {
    callUserBalanceAPI();
    setShowCashModal(false);
    history.push('/message');
  };

  const addCashOutMethod = () => {
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/payment')
        : history.push('/payment')
      : fetchAndSetModalPage('/payment');
  };

  const handlePayment = (activecard1, withdrawal1) => {
    setInSessionStorage('active-card', activecard1);
    setInSessionStorage('cash-out-amount', withdrawal1);
    return isMobile
      ? isModal
        ? fetchAndSetModalPage('/confirm-cashout')
        : setShowCashModal(true)
      : fetchAndSetModalPage('/confirm-cashout');
  };

  const cashOutPopUpBalance = () => {
    setInSessionStorage('cash-out-amount', withdrawal);
    if (activecard) {
      return handlePayment(activecard, withdrawal);
    }
    setInSessionStorage('do-cashout-flow', true);
    return addCashOutMethod();
  };

  const cashOutBalance = () => {
    const publicOrderId = getFromSessionStorage('publicOrderId');
    const createObj = [
      publicOrderId,
      activecard.payment_method_identifier,
      withdrawal,
      MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD,
      'mail_a_check@thedailynumber.com',
      userLocation,
    ];
    setLoaderVisibility(true);
    createWithdrawal(createObj)
      .then(({ data }) => {
        if (data && data.error) {
          setInSessionStorage('screen', 'error');
          setInSessionStorage('message', MESSAGE_CONSTANTS.CASH_OUT_GENERIC);
        }
        if (data && !data.error && data.id) {
          setInSessionStorage('screen', 'success');
          setInSessionStorage('description', MESSAGE_CONSTANTS.CASH_ME_OUT);
          setInSessionStorage('message', MESSAGE_CONSTANTS.TRANSFER_INITIATED);
          successScreen();
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const setValueWithSlider = changedValue => {
    const amount =
      changedValue >= maxWithdrawLimit ? maxWithdrawLimit : changedValue;
    setInSessionStorage('cash-out-amount', amount);
    setWithdrawal(amount);
    setWithdrawalAmt(changedValue);
  };

  const setActiveCard = item => {
    // setInSessionStorage('active-card', item);
    setActiveCards(item);
    setShowDropDown(!showDropDown);
  };

  const paymentMethodList = () => {
    setInSessionStorage('active-card', activecard);
    setInSessionStorage('saved-cards', cardsSaved);
    isModal ? setShowDropDown(!showDropDown) : history.push('/payment-methods');
  };

  useEffect(() => {
    const body = {
      cashOut: true,
      amount: {
        amount: 10,
        currencyIsoCode: 'USD',
      },
    };
    createStartWithdrawal(body).then(({ data }) => {
      setInSessionStorage('publicOrderId', data.publicId);
      if (data.paymentMethods && data.paymentMethods.length) {
        let data1 = [];
        data.paymentMethods.forEach(({ accounts }) => {
          data1 = [...data1, ...accounts];
        });
        if (data1.length) {
          setActiveCards(data1[0]);
          setSavedCards(data1);
          const doCashOut = getFromSessionStorage('do-cashout-flow');
          const cashoutFund = getFromSessionStorage('cash-out-amount');
          if (doCashOut) {
            removeFromSessionStorage('do-cashout-flow');
          }
          if (doCashOut && data1.length && data1.length === 1 && cashoutFund) {
            setWithdrawal(cashoutFund);
            handlePayment(data1[0], cashoutFund);
          }
        }
      }
    });
  }, []);

  const paymentList = item => {
    return (
      <li
        className={`d-flex ${
          activecard &&
          activecard.payment_method_identifier ===
            item.payment_method_identifier
            ? 'selectedCard'
            : ''
        }`}
        key={item.payment_method_identifier}>
        <button
          type="button"
          className="d-flex align-items-center"
          onClick={() => setActiveCard(item)}>
          <div className={`listIcon ${getPaymentMethodIconClass(item)}`} />
          <div className="infoWrap flex-grow-1">
            <div className="title">
              {item.card_brand ? item.card_brand : item.description}
            </div>
            <div className="info font-sm">
              <span>{item.account_type}</span>
              <span>{maskCardNumber(item.number)}</span>
              <span>
                {item.expiration_date ? `Expires ${item.expiration_date}` : ''}
              </span>
            </div>
          </div>
        </button>
      </li>
    );
  };

  const renderDepositMethod = item => {
    return (
      <>
        <div className="dropDownWrap">
          <div className="listWithIcons">
            <div className="sourceTitle">Select Method</div>
            <ul className="border">
              <li className="d-flex" key={item.publicId}>
                <button
                  type="button"
                  tag="button"
                  className="d-flex align-items-center addFundsBtn"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.WITHDRAW, {
                      action: 'Select Method',
                      formData: {
                        currentSelectedCard: activecard,
                        allCard: cardsSaved,
                        maxWithdrawLimitBalance: maxWithdrawLimit,
                        WithDrawAmount: withdrawal,
                      },
                    });
                    isMobile
                      ? paymentMethodList()
                      : setShowDropDown(!showDropDown);
                  }}>
                  <div
                    className={`listIcon ${getPaymentMethodIconClass(item)}`}
                  />
                  <div className="infoWrap flex-grow-1">
                    <div className="title">
                      {item.card_brand ? item.card_brand : item.description}
                    </div>
                    <div className="info font-sm">
                      <span>{item.account_type}</span>
                      <span>{maskCardNumber(item.number)}</span>
                      <span>
                        {item.expiration_date
                          ? `Expires ${item.expiration_date}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
          {showDropDown && (
            <div className="paymentDropdown">
              <div className="listWithIcons">
                <ul>
                  {cardsSaved && cardsSaved.map(card => paymentList(card))}
                  <li className="d-flex" key="add-card">
                    <button
                      className="d-flex align-items-center"
                      type="button"
                      onClick={addCashOutMethod}>
                      <div className="listIcon addCardIcon" />
                      <div className="link">
                        {BUTTON_LABELS.WALLET.ADD_CARD}
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const isCardExists = () => {
    return activecard ? (
      renderDepositMethod(activecard)
    ) : (
      <div className="listWithIcons">
        <div className="sourceTitle">Select Method</div>
        <ul className="border">
          <li className="d-flex" key="add-card">
            <button
              className="d-flex align-items-center"
              type="button"
              onClick={addCashOutMethod}>
              <div className="listIcon addCardIcon" />
              <div className="link">{BUTTON_LABELS.WALLET.ADD_CARD}</div>
            </button>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      {showCashModal && (
        <ConfirmCashOutModal
          close={() => setShowCashModal(false)}
          userLocation={userLocation}
          cashOutBalance={cashOutBalance}
          withdrawalAmt={withdrawal}
        />
      )}
      <div className="d-flex popUpHeader">
        <div className="leftSide">
          {isMobile ? (
            <Link to="/wallet">{BUTTON_LABELS.COMMON.CANCEL}</Link>
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link onClick={() => closeModalBox()} className="btnCancel">
              {BUTTON_LABELS.COMMON.CANCEL}
            </Link>
          )}
        </div>
        <h2>{MESSAGE_CONSTANTS.WALLET.CASHOUT.HEADING}</h2>
        <div className="walletAmountBtn rightSide" />
      </div>
      {!isLoading ? (
        <div className="wrapper cashoutWrapper modalContainer">
          <div>
            <div className="popUpMiddleWrapp info">
              <div className="title centerText">
                <strong>
                  <sup className="dollorSign">$</sup>
                  {withdrawal}
                </strong>
                <div className="availableBalance centerText">
                  <span>{`$${maxWithdrawLimit} available in Wallet`}</span>
                  <div className="questionMarkInfo">
                    <span className="questionIcon" />
                    <div className="aboutQuestionInfo">
                      <p>
                        {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                        <a href="/terms-of-service"> terms and conditions</a>.
                        *Some exclusions apply.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {maxWithdrawLimit >= APP_CONSTANTS.MINIMUM_CASHOUT_BALANCE && (
                <div className="w-100 rangeSliderWrap">
                  <Rangeslider
                    value={withdrawalAmt}
                    min={10}
                    max={maxSlider}
                    step={10}
                    onChange={val => setValueWithSlider(val)}
                    format={() => withdrawal}
                  />
                </div>
              )}
              {maxWithdrawLimit >= APP_CONSTANTS.MINIMUM_CASHOUT_BALANCE && (
                <div className="availableBalance centerText w-100 minAmount">
                  <span>{withdrawal === 10 && 'Minimum Transfer Amount'}</span>
                  <span>
                    {maxWithdrawLimit === withdrawal &&
                      'Maximum Transfer Amount'}
                  </span>
                </div>
              )}
            </div>
            {isCardExists()}
            {/* eslint-disable-next-line max-len */}
            <div className="availableBalance centerText w-100 minAmount showMobile">
              <span>Estimated to arrive within 3-5 business days.</span>
            </div>
            <Button
              className="btn btn-primary fullWidht"
              type="button"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.WITHDRAW, {
                  action: BUTTON_LABELS.COMMON.CASHOUT,
                  formData: {
                    currentSelectedCard: activecard,
                    allCard: cardsSaved,
                    maxWithdrawLimitBalance: maxWithdrawLimit,
                    WithDrawAmount: withdrawal,
                  },
                });
                cashOutPopUpBalance();
              }}
              label={`${BUTTON_LABELS.COMMON.CASHOUT} $${withdrawal}`}
              disable={withdrawal <= 0}
            />
          </div>
          {/* eslint-disable-next-line max-len */}
          <div className="availableBalance centerText w-100 minAmount showDesktop">
            <span>Estimated to arrive within 3-5 business days.</span>
          </div>
        </div>
      ) : (
        <div id="cover-spin" />
      )}
    </>
  );
};
