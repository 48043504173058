import React, { useState, useEffect } from 'react';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Input } from 'Components/Form-Elements/Input';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { getPasswordStrength } from 'Utils/validations';
import { Button } from 'Components/Form-Elements/Button';
import {
  resetPassword,
  validateResetLink,
} from 'Actions/reset-password-action';
import { ModalBox } from 'Components/ModalBox';
import { Loader } from 'Components/Loader';

export const ResetPasswordScreen = ({ history, location }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [passwordStrength, setStrength] = useState({});
  const [passwordMatch, setMatch] = useState(false);
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [queryParams, setQueryParams] = useState({});

  const queryString = require('query-string');

  useEffect(() => {
    setQueryParams(queryString.parse(location.search));
  }, []);

  useEffect(() => {
    const body = {
      email: queryParams.email,
      token: queryParams.token,
    };
    if (Object.keys(queryParams).length !== 0) {
      validateResetLink(body)
        .then(({ data }) => {
          setIsLoading(false);
        })
        .catch(({ response }) => {
          const { status } = response.data;
          setIsLoading(false);
          setToggleModalBox(true);
          if (status === APP_CONSTANTS.HTTP_STATUS_CODES.UNAUTHORIZED) {
            setModalBoxName(APP_CONSTANTS.MODALBOX.LINK_EXPIRED);
          } else {
            setModalBoxName(APP_CONSTANTS.MODALBOX.BAD_REQUEST);
          }
        });
    }
  }, [queryParams]);

  useEffect(() => {
    if (
      password.length &&
      passwordStrength.strength !== MESSAGE_CONSTANTS.SET_PASSWORD.WEAK
    ) {
      setMatch(confirmPassword === password);
      setDisableButton(!(confirmPassword === password));
    }
  }, [confirmPassword, password]);

  const handlePasswordChange = value => {
    setPassword(value);
    const strength = getPasswordStrength(value);
    setStrength({
      strength,
      colorCode: APP_CONSTANTS.PASSWORD_STRENGTH[strength.toUpperCase()],
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    resetPassword(queryParams.email, queryParams.token, confirmPassword)
      .then(({ data }) => {
        setIsLoading(false);
        history.push({ pathname: '/forgot-password-success' });
      })
      .catch(({ response }) => {
        const { status } = response.data;
        setIsLoading(false);
        setToggleModalBox(true);
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NOT_FOUND) {
          setModalBoxName(APP_CONSTANTS.MODALBOX.INVALID_TOKEN);
        } else {
          setModalBoxName(APP_CONSTANTS.MODALBOX.BAD_REQUEST);
        }
      });
  };

  const getColorCode = () => {
    return passwordMatch
      ? APP_CONSTANTS.PASSWORD_MATCH.MATCH
      : APP_CONSTANTS.PASSWORD_MATCH.NOT_MATCH;
  };

  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
    history.push('/login');
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.BAD_REQUEST) {
      modalbox = MESSAGE_CONSTANTS.KYC.BAD_REQUEST;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.LINK_EXPIRED) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.LINK_EXPIRED;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.INVALID_TOKEN) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.INVALID_TOKEN;
    }
    return modalbox;
  };

  return (
    <>
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="OK"
          showBothButtons={false}
        />
      )}
      {isLoading && <Loader />}
      <div className="signup">
        <div className="wrapper">
          <div className="info">
            <div className="iconBig lockIcon" />
            <div className="title">
              Reset <strong>password</strong>
            </div>
          </div>
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.PASSWORD}
              className="form-control"
              id="set-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.PASSWORD}
              value={password}
              handleChange={handlePasswordChange}
            />
            <span className={passwordStrength.colorCode}>
              {passwordStrength.strength}
            </span>
          </div>
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.CONFIRM_PASSWORD}
              className="form-control"
              id="confirm-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.CONFIRM_PASSWORD}
              value={confirmPassword}
              handleChange={setConfirmPassword}
            />
            <span
              className={`${
                password.length && confirmPassword.length
                  ? getColorCode()
                  : 'hide'
              }`}>
              {passwordMatch
                ? MESSAGE_CONSTANTS.SET_PASSWORD.MATCH
                : MESSAGE_CONSTANTS.SET_PASSWORD.DOESNT_MATCH}
            </span>
          </div>
          <div className="leftText">
            {MESSAGE_CONSTANTS.SET_PASSWORD.DESCRIPTION}
          </div>
          <Button
            className="btn btn-primary bottomFixed"
            disabled={disableButton}
            label={BUTTON_LABELS.COMMON.NEXT}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};
