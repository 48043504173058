import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  getNotifications,
  updateNotifications,
} from 'Actions/user-notification-action';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { mixpanelTrackPageView } from 'Utils/mix-panel';
import InfiniteScroll from 'react-infinite-scroller';
import { NotificationList } from './NotificationList';

export const Notifications = () => {
  const [notificationList, setNotificationList] = useState([]);
  const [hasMoreItems, setMoreItems] = useState(true);
  const loader = <div className="loader">Loading ...</div>;

  useEffect(() => {
    mixpanelTrackPageView('Notification Screen');
  }, []);

  const readNotifs = (id, isRead) => {
    if (!isRead) {
      updateNotifications(id, true).then(({ data }) => {
        if (data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          setNotificationList(
            notificationList.map(value => ({
              ...value,
              read: value.id === id ? true : value.read,
            })),
          );
        }
      });
    }
  };

  const checkRecordExists = item => {
    return notificationList.some(record => record.id === item.id);
  };

  const loadItems = pageNo => {
    getNotifications(pageNo).then(({ data }) => {
      if (data.results.length) {
        if (!checkRecordExists(data.results[0])) {
          const paginatedResults = [...notificationList, ...data.results];
          setMoreItems(true);
          setNotificationList(paginatedResults);
        } else {
          setMoreItems(false);
        }
      }
    });
  };

  return (
    <div className="wrapper notificationPage">
      <div className="pageHeaderWrap">
        <div className="d-flex topBar resetTopBar">
          <div className="d-flex pageHeader">
            <div className="leftSide">
              <Link to="/for-you" className="arrowBack" />
            </div>
            <h2 className="headerTitle">
              {MESSAGE_CONSTANTS.NOTIFICATIONS.HEADING}
            </h2>
            <div className="rightSide" />
          </div>
        </div>
      </div>

      <InfiniteScroll
        pageStart={0}
        loadMore={loadItems}
        hasMore={hasMoreItems}
        loader={loader}>
        <div className="info notificationsListing">
          {notificationList.map(value => {
            return (
              <NotificationList
                key={value.id}
                value={value}
                readNotifs={readNotifs}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};
