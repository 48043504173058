/* eslint-disable react/jsx-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
import React from 'react';
import StarRatings from 'react-star-ratings';
import icon from '../../assets/icons/icons.svg';

export const StarRating = ({
  rating,
  numberOfStars,
  name,
  starRatedColor,
  starEmptyColor,
  starDimension,
  starSpacing,
}) => {
  return (
    <div className="rating">
      {/* {// rating &&
      [...Array(5)].map((x, i) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11">
          <use xlinkHref={`${icon}#i-star`} />
        </svg>
      ))} */}
      <StarRatings
        rating={rating}
        numberOfStars={numberOfStars}
        name={name}
        starRatedColor={starRatedColor}
        starEmptyColor={starEmptyColor}
        starDimension={starDimension}
        starSpacing={starSpacing}
        svgIconPath="M15,5.3L9.6,4.9L7.5,0L5.4,4.9L0,5.3l4.1,3.5L2.9,14l4.6-2.8l4.6,2.8l-1.3-5.2L15,5.3z" // need to be handle conditional
        svgIconViewBox="0 0 15 14" // need to be handle conditional
      />
    </div>
  );
};

StarRating.defaultProps = {
  rating: 0,
  numberOfStars: 5,
  name: 'rating',
  starRatedColor: 'rgb(251,214,2)',
  starEmptyColor: 'transparent', // need to be handle conditional
  starDimension: '15px',
  starSpacing: '2px',
};
