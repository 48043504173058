/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react';
import Branch, { BranchEvent } from 'branch-sdk';
import { LineUpContext } from 'Components/LineUpScreens/Context';
import { ProfileContext } from 'Components/Profile/Context';
import { verifyUserLocation } from 'Actions/location-action';
import {
  refreshLineup,
  updateLineUpStrength,
  getPositionDetails,
  modalBoxDetail,
  onDenyLocation,
  getInitialAmount,
} from 'Components/LineUpScreens/lineup-functions';
import { LineUpPositionDesign } from 'Components/LineUpScreens/Lineup/playerPositionNewDesign';
import { getAge, getUserBirthDate } from 'Utils/user-details-utils';
import { getUserBalance } from 'Actions/user-balance-action';
import { SignUpModal } from 'Components/ModalSignUpFlow';
import { getContestFeeDetails } from 'Actions/contest-action';
// eslint-disable-next-line max-len
import { LineupDraftModal } from 'Components/LineUpScreens/Lineup/LineupDraftModal';
import {
  draftPlayers,
  cancelLineup,
  receiveDraftPlayers,
  getLineupStrengthWithPlayers,
  receiveLineupStrength,
} from 'Actions/player-selection-action';
import { ModalBox } from 'Components/ModalBox';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import {
  setInSessionStorage,
  getFromSessionStorage,
  removeFromSessionStorage,
  getCookie,
} from 'Utils/storage-utilities';
import { sports } from 'Components/LineUpScreens/SportData/sportsObject';
import { ACTION_TYPE } from 'Constants/action-type';
import { getTimeDiff } from 'Utils/date-utils';
import { getPaymentMethods } from 'Actions/wirecard-action';
import {
  getSelectedPlayersForSubmission,
  submitLineupconfirm,
  swapPlayerForActiveLineUp,
  getSelectedPlayersForSubmissionForActiveLineUp,
} from 'Actions/submit-lineup-action';
import { Loader } from 'Components/Loader';
import { TRACKING_CONSTANTS } from 'Constants/branch-tracking';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import { LineUpHeader } from 'Components/LineUpScreens/Lineup/LineupHeader';
import { AddFundsModal } from 'Components/Wallet/AddFundsModal';
import {
  draftLineupAction,
  draftLineupUpdate,
  draftLineupDelete,
} from 'Actions/draft-lineup-action';
import { getContestById } from 'Actions/sport-action';

export const LineUpInModal = ({
  onSuccess,
  location,
  closeModalBox,
  isUserLoggedIn,
}) => {
  const DEFAULT_ENTRY_FEE = 1;
  const [showSignUpFlow, setShowSignUpFlow] = useState(false);
  const { profileState } = useContext(ProfileContext);
  const [apiErrorMessage, setApiErrorMessage] = useState({});
  const [showAddFundsModal, setShowAddFundsModal] = useState(false);
  const [signUpDraftLineup, setSignupDraftLineup] = useState(null);
  // console.log(LineUpContext);
  const { state, contextDispatch } = useContext(LineUpContext);
  const {
    allowPaid,
    modalBoxName,
    toggleModalBox,
    selectedEntryFee,
    entryFee,
    ageError,
    winAmountString,
    contestData,
    sportData,
    lineUp,
    editLineUpRawData,
    toggleLoader,
    lineUpStrength,
    buttonEnable,
    rangeSlideValue,
    enablewinScreen,
    activePosition,
    rangeData,
    lineUpFinalWin,
    isLocationVerified,
    walletBalance,
    editLineupId,
    editLineupEntryData,
    dataPlayers,
    playerRawData,
    isIntialDataSet,
    isRangeSliderUsed,
  } = state;
  // console.log('state');
  // console.log(state);
  const eventName = TRACKING_CONSTANTS.LINEUP;
  let metadata;

  const saveData = (type, payload) => {
    contextDispatch({ type, payload });
  };

  const [showDraftModal, setLineupDraftModal] = useState(false);
  const [totalFP, setTotalFP] = useState(0);

  const saveAsDraft = (tempLineup = null, redirect = true) => {
    const contestSessionData = getFromSessionStorage(
      APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST,
    );

    let draftLineupId = '';
    if (contestSessionData) {
      const { createdLineup } = contestSessionData;
      draftLineupId = createdLineup.id;
    }

    let saveLineUp = [];
    if (tempLineup === null) {
      saveLineUp = [...lineUp];
    } else {
      saveLineUp = tempLineup;
    }

    const lineupDataUpdate = {
      entryFee: selectedEntryFee.amount,
      currencyIsoCode: selectedEntryFee.currencyIsoCode,
      selectedNumber: totalFP,
      // contestId: contests[selectedGameIndex]._id,
      roster: saveLineUp,
      isDrafted: true,
    };
    setSignupDraftLineup({ ...lineupDataUpdate, contestId: contestData._id });
    const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
    if (token) {
      draftLineupUpdate(draftLineupId, lineupDataUpdate)
        .then(data => {
          if (redirect) {
            closeModalBox();
            onSuccess(APP_CONSTANTS.LINEUPS_TABS.DRAFT);
          }
        })
        .catch(err => {
          if (
            err.response.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.PERMANENTLY_REMOVED
          ) {
            // Need to have an error popup, design issue
            closeModalBox();
          } else if (
            err.response.status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN
          ) {
            // Need to have an error popup, design issue
            closeModalBox();
          }
        });
    }
  };

  const getPaymentMethoddata = isUserLogged => {
    if (isUserLogged) {
      getPaymentMethods()
        .then(({ data }) => {
          const { summary, results } = data;
          if (summary && summary.totalCount > 0) {
            setInSessionStorage('active-card', results[0]);
            setInSessionStorage('saved-cards', results);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getPaymentMethoddata(profileState.isUserLoggedIn);
    return () => {
      saveData(ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE);
    };
  }, []);

  const startLoader = () => {
    saveData(ACTION_TYPE.LINEUP.TOGGLE_LOADER, true);
  };

  const stopLoader = () => {
    saveData(ACTION_TYPE.LINEUP.TOGGLE_LOADER, false);
  };

  const refreshContestData = error => {
    refreshLineup(
      contestData.sportSlug,
      lineUp,
      saveData,
      startLoader,
      stopLoader,
      error,
    );
  };

  const getSelectedPlayers = () => {
    const selectedPlayer = [];
    lineUp.forEach(position => {
      if (position.player) {
        selectedPlayer.push(position.player.apiId);
      }
    });
    return selectedPlayer;
  };

  const isDraftedLineup = (players, tempPosition, selectedContest) => {
    const data = location.state;
    const dataPosition = [];
    const data1 = data.getPlayerDraftDets.map(position => {
      if (position.player) {
        dataPosition.push({
          position: position.position,
          playerId: position.player.playerGame.playerId._str,
        });
        return position.player.playerGame.playerId._str;
      }
      dataPosition.push({
        position: position.position,
        playerId: '',
      });
      return '';
    });
    const newplayerdata = players.filter(player =>
      data1.includes(player.playerGame.playerId._str),
    );
    const playerdata = newplayerdata.reduce((obj1, obj2) => {
      obj1[obj2.playerGame.playerId._str] = obj2;
      return obj1;
    }, {});
    const finalData = [];
    tempPosition.forEach(position => {
      dataPosition.forEach(playerPosition => {
        if (position.position === playerPosition.position) {
          finalData.push({
            position: position.position,
            isActive: false,
            player: playerdata[playerPosition.playerId] || '',
          });
        }
      });
    });

    if (data.getPlayerDraftDets.length > 0) {
      saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, finalData);
      updateLineUpStrength(
        selectedContest,
        finalData,
        startLoader,
        stopLoader,
        saveData,
      );
    }
  };

  const isEditUpdateLineUp = (players, tempPosition, selectedContest) => {
    startLoader();
    const data = location.state;
    const getPlayerPostions = [];
    const getplayers = players.filter(player => {
      return data.some(positionplayer => {
        if (editLineUpRawData.length) {
          positionplayer.playerGames.activeTab = true;
        }
        if (
          positionplayer.playerGames._id ===
          (player.playerGame && player.playerGame._id)
        ) {
          getPlayerPostions.push({
            position: positionplayer.position,
            player,
            playerGame: positionplayer.playerGames,
            isActive: false,
          });
        }
        return (
          positionplayer.playerGames._id ===
          (player.playerGame && player.playerGame._id)
        );
      });
    });
    let flag;
    const editLineupData = [];
    tempPosition.forEach(position => {
      flag = false;
      getPlayerPostions.forEach(player => {
        if (player.position === position.position) {
          flag = true;
          editLineupData.push(player);
        }
      });
      if (!flag) {
        editLineupData.push(position);
      }
    });
    stopLoader();

    saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, editLineupData);
    updateLineUpStrength(
      selectedContest,
      editLineupData,
      startLoader,
      stopLoader,
      saveData,
    );
  };

  const isAllPlayerSelected = () => {
    const selectedPlayer = getSelectedPlayers();
    const flag = lineUp.length && lineUp.length === selectedPlayer.length;
    saveData(ACTION_TYPE.LINEUP.ENABLE_WIN_SCREEN, flag);
  };
  useEffect(() => {
    isAllPlayerSelected();
    for (let i = 0; i < lineUp.length; i += 1) {
      if (lineUp[i].isActive) {
        const { position } = lineUp[i];
        if (position !== activePosition)
          saveData(ACTION_TYPE.LINEUP.SET_ACTIVE_POSITION, position);
      }
    }
  }, [lineUp]);

  useEffect(() => {
    if (lineUp && lineUp.length) {
      let positionDetails = getPositionDetails(lineUp);
      positionDetails = positionDetails.map(position => {
        if (position.position === activePosition) {
          return {
            position: position.position,
          };
        }
        return position;
      });
      startLoader();
      getLineupStrengthWithPlayers(contestData, positionDetails)
        .then(({ data }) => {
          const allPlayers = receiveLineupStrength(data, playerRawData);
          saveData(ACTION_TYPE.LINEUP.SET_TO_DATA_PLAYERS, allPlayers);
        })
        .catch(resp => {
          refreshContestData('');
        })
        .finally(() => {
          stopLoader();
        });
    }
  }, [activePosition]);

  useEffect(() => {
    if (modalBoxName.length || Object.keys(apiErrorMessage).length)
      saveData(ACTION_TYPE.LINEUP.SET_TOGGLE_MODAL_BOX, true);
  }, [modalBoxName, apiErrorMessage]);

  useEffect(() => {
    if (contestData && Object.keys(contestData).length) {
      const temp = contestData.entryFees.sort((a, b) => a.amount - b.amount);
      saveData(ACTION_TYPE.LINEUP.SET_ENTRY_FEE, temp);
    }
  }, [contestData]);

  useEffect(() => {
    if (!isIntialDataSet && entryFee && entryFee.length) {
      if (!location.state) {
        saveData(ACTION_TYPE.LINEUP.SET_SELECTED_ENTRY_FEE, entryFee[1]);
      } else if (
        location.state &&
        location.state.status &&
        location.state.status === 'drafted'
      ) {
        const selectedAmount = location.state.selectedEntryfees;
        const selectedFee = entryFee.filter(
          fee => fee.amount === selectedAmount,
        );
        saveData(ACTION_TYPE.LINEUP.SET_SELECTED_ENTRY_FEE, ...selectedFee);
      } else if (location.state && location.state.length) {
        const selectedAmount = location.state[0].id.amount;
        const selectedFee = entryFee.filter(
          fee => fee.amount === selectedAmount,
        );
        saveData(ACTION_TYPE.LINEUP.SET_SELECTED_ENTRY_FEE, ...selectedFee);
      }
      saveData(ACTION_TYPE.LINEUP.TOGGLE_INITAL_DATA);
    }
  }, [entryFee]);

  useEffect(() => {
    if (
      typeof location.state !== 'undefined' &&
      location.state &&
      location.state.length
    ) {
      const locationData = location.state;
      saveData(
        ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ID,
        locationData[0].id._id,
      );
      saveData(
        ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ENTRY_DATA,
        locationData[0].id,
      );
      if (locationData[0].lateScratch === true) {
        saveData(ACTION_TYPE.LINEUP.SET_EDIT_LINE_UP_RAW_DATA, locationData);
        /* const param = {
          id: locationData[0].id.contestId._str,
        };
        getContestById(param).then(({ data }) => {
          setInSessionStorage(
            APP_CONSTANTS.SPORTS_KEY.CONTEST,
            data.contests[0],
          );
        }); */
      } else {
        saveData(ACTION_TYPE.LINEUP.SET_EDIT_LINE_UP_RAW_DATA, []);
      }
    } else {
      saveData(ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ID, '');
      saveData(ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ROSTER, null);
    }
    const selectedContest = getFromSessionStorage(
      APP_CONSTANTS.SPORTS_KEY.CONTEST,
    );
    if (Object.keys(sportData).length) {
      startLoader();
      const tempPosition = [];
      const tempLineup = sportData.requiredPositions.map(position => {
        tempPosition.push({ position });
        return {
          position,
          isActive: false,
          player: '',
          playerGame: '',
        };
      });

      saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, tempLineup);
      draftPlayers(selectedContest, tempPosition)
        .then(({ data }) => {
          const player = receiveDraftPlayers(data);
          saveData(ACTION_TYPE.LINEUP.SET_RAW_PLAYERS, player);

          getLineupStrengthWithPlayers(
            selectedContest,
            getPositionDetails(tempPosition),
          ).then(({ data }) => {
            const allPlayers = receiveLineupStrength(data, player);
            saveData(ACTION_TYPE.LINEUP.SET_TO_DATA_PLAYERS, allPlayers);
            if (
              allPlayers.length &&
              location.state &&
              location.state.length &&
              location.state[0].status === 'edited'
            ) {
              isEditUpdateLineUp(allPlayers, tempLineup, selectedContest);
            }
            if (
              allPlayers.length &&
              location.state &&
              location.state.status === 'drafted' &&
              location.state.getPlayerDraftDets &&
              location.state.getPlayerDraftDets.length
            ) {
              isDraftedLineup(allPlayers, tempLineup, selectedContest);
            }
            if (allPlayers.length) {
              saveData(ACTION_TYPE.LINEUP.SET_BUTTON_ENABLE, true);
            }
          });
        })
        .catch(resp => {
          saveData(
            ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
            APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
          );
        })
        .finally(() => {
          stopLoader();
        });
    }
  }, [sportData]);

  const confirmLineUpSubmit = () => {
    if (!allowPaid && selectedEntryFee.amount !== 0) {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.NO_PAID_CONTEST_ALLOWED,
      );
    } else if (ageError && selectedEntryFee.amount !== 0) {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.AGE_ERROR,
      );
    } else if (walletBalance.isLowBalance) {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.WALLET_BALANCE_ERROR,
      );
    } else if (editLineUpRawData.length) {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT,
      );
    } else {
      const time = getTimeDiff(contestData.contestDate.start);
      // if (time.days > 0 || time.hours > 0 || time.minutes > 0) {
      if (
        (!editLineupEntryData &&
          (time.days > 0 || time.hours > 0 || time.minutes > 0)) ||
        (editLineupEntryData &&
          !editLineupEntryData.started &&
          !editLineupEntryData.finished)
      ) {
        saveData(
          ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
          APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT,
        );
      } else {
        saveData(
          ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
          APP_CONSTANTS.MODALBOX.TIME_UP_CONTEST,
        );
      }
    }
    mixpanelTrackPageView('lineup');
  };

  const fetchUserBalance = (submitAgain = false) => {
    if (profileState.isUserLoggedIn) {
      getUserBalance([APP_CONSTANTS.WALLET.CURRENCY_USD])
        .then(({ data }) => {
          setInSessionStorage('WalletBal', data.availableBalance.amount);
          if (
            data &&
            data.availableBalance &&
            (submitAgain || (selectedEntryFee && selectedEntryFee.amount))
          ) {
            saveData(ACTION_TYPE.LINEUP.SET_BALANCE, {
              amount: data.availableBalance.amount,
              isLowBalance: !(
                selectedEntryFee.amount <= data.availableBalance.amount
              ),
            });
          } else {
            const amount = getInitialAmount(DEFAULT_ENTRY_FEE, location);
            saveData(ACTION_TYPE.LINEUP.SET_BALANCE, {
              amount: data.availableBalance.amount,
              isLowBalance: !(amount <= data.availableBalance.amount),
            });
          }
          if (
            submitAgain &&
            data.availableBalance.amount >= selectedEntryFee.amount
          ) {
            const time = getTimeDiff(contestData.contestDate.start);
            if (time.days > 0 || time.hours > 0 || time.minutes > 0) {
              saveData(
                ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
                APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT,
              );
            } else {
              saveData(
                ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
                APP_CONSTANTS.MODALBOX.TIME_UP_CONTEST,
              );
            }
          } else if (submitAgain) {
            removeFromSessionStorage(APP_CONSTANTS.LINEUP_KEY.NAVIGATE_TO_KEY);
          }
        })
        .catch(err => {
          saveData(
            ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
            APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
          );
        });
    }
  };

  useEffect(() => {
    saveData(ACTION_TYPE.LINEUP.SET_LINEUP_STRENGTH, {
      lineUpstrength: 0,
    });
    const selectedContest = getFromSessionStorage(
      APP_CONSTANTS.SPORTS_KEY.CONTEST,
    );
    const sportD = sports.filter(
      sportType => sportType.sportSlug === selectedContest.sportSlug,
    );
    if (sportD.length) saveData(ACTION_TYPE.LINEUP.SET_SPORT_DATA, sportD[0]);

    getContestById({ id: selectedContest._id })
      .then(({ data }) => {
        saveData(ACTION_TYPE.LINEUP.SET_CONTEST_DATA, data.contests[0]);
      })
      .catch(resp => {
        saveData(
          ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
          APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
        );
      });

    /* getContestFeeDetails(selectedContest.slug)
        .then(resp => {
          if (resp) {
            saveData(ACTION_TYPE.LINEUP.SET_CONTEST_DATA, resp.data);
          }
        })
        .catch(resp => {
          saveData(
            ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
            APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
          );
        }); */

    fetchUserBalance();
  }, []);

  const verifyLocationAndAge = () => {
    if (process.env.REACT_APP_INTERNATIONAL_ENABLED === 'true') {
      saveData(ACTION_TYPE.LINEUP.SET_ALLOW_PAID, true);
      saveData(ACTION_TYPE.LINEUP.SET_AGE_ERROR, false);
      saveData(ACTION_TYPE.LINEUP.SET_LOCATION_VERIFIED, true);
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const coordinates = {
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accuracy: position.coords.accuracy,
          };
          setInSessionStorage('userCoords', coordinates);
          verifyUserLocation(
            position.coords.latitude,
            position.coords.longitude,
          )
            .then(({ data }) => {
              const age = getAge(getUserBirthDate());
              saveData(ACTION_TYPE.LINEUP.SET_ALLOW_PAID, data.allowPaidPlay);
              saveData(
                ACTION_TYPE.LINEUP.SET_AGE_ERROR,
                !(age >= data.minimumAge),
              );
              saveData(ACTION_TYPE.LINEUP.SET_LOCATION_VERIFIED, true);
            })
            .catch(resp => {
              saveData(
                ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
                APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
              );
            });
        },
        error => onDenyLocation(error, saveData),
      );
    } else {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.PERMISSION_ALLOW,
      );
    }
  };

  const getMinMaxAmount = amounts => {
    const min = Math.min(...amounts.map(({ amount }) => amount));
    const max = Math.max(...amounts.map(({ amount }) => amount));
    return { min, max };
  };

  const calculatePrize = moneyline => {
    let convertedEntryFee = selectedEntryFee.amount;
    if (convertedEntryFee === 0) {
      convertedEntryFee = 100;
    }

    let prize;
    if (moneyline > 0) {
      prize = convertedEntryFee + convertedEntryFee * (moneyline * 0.01);
    } else {
      prize = convertedEntryFee + convertedEntryFee / (moneyline * -0.01);
    }

    let rounding;
    if (prize < 10) {
      rounding = 100;
    } else if (prize < 30) {
      rounding = 10;
    } else {
      rounding = 1;
    }

    const rounded = Math.floor(prize * rounding) / rounding;

    return rounded;
  };
  useEffect(() => {
    let winAmount;
    if (selectedEntryFee && selectedEntryFee.winAmounts) {
      const isLowBalance = !(selectedEntryFee.amount <= walletBalance.amount);
      saveData(ACTION_TYPE.LINEUP.SET_BALANCE, {
        ...walletBalance,
        isLowBalance,
      });
      const minMax = getMinMaxAmount(selectedEntryFee.winAmounts);

      const selectedRange = rangeData[rangeSlideValue];
      const strAmount = selectedEntryFee.winAmounts.find(amount => {
        return amount.lineupStrength === lineUpStrength.lineUpstrength;
      });
      const prize =
        (selectedRange &&
          selectedRange.moneyline &&
          calculatePrize(selectedRange.moneyline)) ||
        (strAmount ? strAmount.amount : 0);
      if (selectedEntryFee.amount === 0) {
        winAmount = prize ? `${prize} EXP` : `${minMax.min}xp-${minMax.max}xp`;
      } else {
        winAmount = prize
          ? `$${prize.toFixed(2)}`
          : `$${minMax.min}-$${minMax.max}`;
      }
    }
    if (winAmount) {
      saveData(ACTION_TYPE.LINEUP.SET_WIN_AMOUNT_STR, winAmount);
    }
  }, [selectedEntryFee, lineUp]);

  useEffect(() => {
    if (
      rangeSlideValue >= 0 &&
      enablewinScreen &&
      rangeData[rangeSlideValue] &&
      selectedEntryFee.winAmounts
    ) {
      const selectedRange = rangeData[rangeSlideValue];
      const isFree = selectedEntryFee.amount === 0;

      const strAmount = selectedEntryFee.winAmounts.find(amount => {
        return amount.lineupStrength === lineUpStrength.lineUpstrength;
      });
      let prize =
        (selectedRange &&
          selectedRange.moneyline &&
          calculatePrize(selectedRange.moneyline)) ||
        (strAmount ? strAmount.amount : 0);
      if (isFree) {
        prize = `${prize} EXP`;
      } else {
        prize = `$${prize.toFixed(2)}`;
      }
      if (strAmount) {
        saveData(ACTION_TYPE.LINEUP.SET_FINAL_WIN, {
          targetNumber: selectedRange.number,
          winAmount: prize,
        });
      }
    } else if (
      enablewinScreen &&
      lineUpStrength.lineUpstrength &&
      selectedEntryFee.winAmounts
    ) {
      const isFree = selectedEntryFee.amount === 0;
      const strAmount = selectedEntryFee.winAmounts.find(amount => {
        return amount.lineupStrength === lineUpStrength.lineUpstrength;
      });
      let prize = strAmount.amount;

      if (isFree) {
        prize = `${prize} EXP`;
      } else {
        prize = `$${prize.toFixed(2)}`;
      }

      saveData(ACTION_TYPE.LINEUP.SET_FINAL_WIN, {
        targetNumber: contestData.targetNumber,
        winAmount: prize,
      });
    } else if (!enablewinScreen) {
      saveData(ACTION_TYPE.LINEUP.SET_FINAL_WIN, '');
    }
  }, [rangeSlideValue, selectedEntryFee, enablewinScreen]);

  const handleEntryFeeChange = event => {
    const index = event.target.value;
    if (entryFee[index]) {
      const selectedFee = entryFee[index];
      saveData(ACTION_TYPE.LINEUP.SET_SELECTED_ENTRY_FEE, selectedFee);
    }
  };

  const handleActivePosition = (currentPosition, checkIsRangeSliderUsed) => {
    const tempLineup = lineUp.map(position => {
      return {
        ...position,
        isActive: position.position === currentPosition.position,
      };
    });
    saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, tempLineup);
    if (checkIsRangeSliderUsed && isRangeSliderUsed) {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.EDIT_LINEUP_COMFIRMATION,
      );
    }
  };

  const deselectAllposition = () => {
    const tempLineup = lineUp.map(position => {
      return {
        ...position,
        isActive: false,
      };
    });
    saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, tempLineup);
    if (!editLineupId) {
      saveAsDraft(tempLineup, false);
    }
  };

  const isActivePosition = () => {
    return lineUp.some(({ isActive }) => isActive);
  };

  const handleShowPlayer = () => {
    const nextEmptyPosition = lineUp.findIndex(
      position => position.player === '',
    );
    handleActivePosition(lineUp[nextEmptyPosition], false);
  };

  const cancelWithoutSaving = (onCancel = true) => {
    const contestData = getFromSessionStorage(
      APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST,
    );
    let draftLineupId = '';
    if (contestData) {
      const { createdLineup } = contestData;
      draftLineupId = createdLineup.id;
    } else if (location.state) {
      draftLineupId = location.state.draftLineupId;
    }

    if (location.state && location.state.status === 'drafted') {
      draftLineupId = location.state.draftLineupId;
    }
    const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
    if (token) {
      startLoader();
      draftLineupDelete(draftLineupId)
        .then(({ data }) => {
          if (onCancel) {
            closeModalBox();
            if (location.state && location.state.status === 'drafted') {
              onSuccess(APP_CONSTANTS.LINEUPS_TABS.DRAFT);
            }
          }
        })
        .catch(err => {
          console.log(err);
          if (
            err.response.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.PERMANENTLY_REMOVED
          ) {
            // Need to have an error popup, design issue
            closeModalBox();
          } else if (
            err.response.status === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN
          ) {
            // Need to have an error popup, design issue
            closeModalBox();
          }
        })
        .finally(() => {
          stopLoader();
        });
    }
  };

  const checkEditAndSubmit = lineupData => {
    submitLineupconfirm(lineupData)
      .then(({ data }) => {
        if (!data) {
          if (selectedEntryFee.amount) {
            try {
              metadata = {
                sport: contestData.sportSlug,
                timePeriod: contestData.timePeriod,
                entryFee: selectedEntryFee.amount,
                currencyIsoCode: APP_CONSTANTS.WALLET.CURRENCY_USD,
              };
              Branch.logEvent(eventName, metadata);
            } catch (errorBranch) {
              console.log('error', errorBranch);
            }
          }
          mixPanelTrackEvent('line up', lineupData);
          saveData(ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE);
          onSuccess(APP_CONSTANTS.LINEUPS_TABS.UPCOMING);
        } else {
          const { details, reason, error } = data;
          if (
            reason === MESSAGE_CONSTANTS.ACCOUNT_UNVERFIED ||
            reason === MESSAGE_CONSTANTS.ACCOUNT_UNDER_REVIEW
          ) {
            saveData(
              ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
              APP_CONSTANTS.MODALBOX.WALLET_BALANCE_ERROR,
            );
          } else if (error === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN) {
            refreshContestData(reason);
          } else {
            setApiErrorMessage({
              title: details,
              message: reason,
            });
          }
        }
      })
      .catch(err => {
        saveData(
          ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
          APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
        );
      });
  };

  const checkEditAndSubmitForActiveLineUp = lineupData => {
    swapPlayerForActiveLineUp(lineupData)
      .then(({ data }) => {
        if (data) {
          if (selectedEntryFee.amount) {
            try {
              metadata = {
                sport: contestData.sportSlug,
                timePeriod: contestData.timePeriod,
                entryFee: selectedEntryFee.amount,
                currencyIsoCode: APP_CONSTANTS.WALLET.CURRENCY_USD,
              };
              Branch.logEvent(eventName, metadata);
            } catch (errorBranch) {
              console.log('error', errorBranch);
            }
          }
          mixPanelTrackEvent('line up', lineupData);
          saveData(ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE);
          onSuccess(APP_CONSTANTS.LINEUPS_TABS.ACTIVE);
        } else {
          const { details, reason, error } = data;
          if (
            reason === MESSAGE_CONSTANTS.ACCOUNT_UNVERFIED ||
            reason === MESSAGE_CONSTANTS.ACCOUNT_UNDER_REVIEW
          ) {
            saveData(
              ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
              APP_CONSTANTS.MODALBOX.WALLET_BALANCE_ERROR,
            );
          } else if (error === APP_CONSTANTS.HTTP_STATUS_CODES.FORBIDDEN) {
            refreshContestData(reason);
          } else {
            setApiErrorMessage({
              title: details,
              message: reason,
            });
          }
        }
      })
      .catch(err => {
        saveData(
          ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
          APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
        );
      });
  };

  const resetPlayers = () => {
    const temp = lineUp.map(position => {
      return {
        ...position,
        player: '',
      };
    });

    saveData(ACTION_TYPE.LINEUP.SET_LINEUP_STRENGTH, {
      ...lineUpStrength,
      lineUpstrength: 0,
    });
    saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, temp);
  };

  const processHandler = () => {
    saveData(ACTION_TYPE.LINEUP.SET_TOGGLE_MODAL_BOX, false);
    saveData(ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME, '');
    if (modalBoxName === APP_CONSTANTS.MODALBOX.EDIT_LINEUP_COMFIRMATION) {
      saveData(ACTION_TYPE.LINEUP.RESET_RANGE_SLIDER_USED_CHECK);
    }
    if (modalBoxName === APP_CONSTANTS.MODALBOX.SOMETHING_WRONG) {
      saveData(ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ID, '');
      closeModalBox();
    }
    if (modalBoxName === APP_CONSTANTS.MODALBOX.WALLET_BALANCE_ERROR) {
      setShowAddFundsModal(true);
    }
    if (editLineUpRawData.length && editLineUpRawData[0].lateScratch === true) {
      if (modalBoxName === APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT) {
        removeFromSessionStorage(APP_CONSTANTS.LINEUP_KEY.NAVIGATE_TO_KEY);
        const data = getSelectedPlayersForSubmissionForActiveLineUp(
          lineUp,
          editLineupId,
          editLineUpRawData,
        );
        if (!data[1].length) {
          saveData(ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE);
          setInSessionStorage('lineupTab', APP_CONSTANTS.LINEUPS_TABS.ACTIVE);
          onSuccess(APP_CONSTANTS.LINEUPS_TABS.ACTIVE);
          return;
        }
        if (
          editLineupId &&
          location.state &&
          typeof (location.state.status === 'undefined')
        ) {
          checkEditAndSubmitForActiveLineUp(data);
        } else {
          cancelWithoutSaving(false);
          checkEditAndSubmitForActiveLineUp(data);
        }
      }
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT) {
      removeFromSessionStorage(APP_CONSTANTS.LINEUP_KEY.NAVIGATE_TO_KEY);
      const data = getSelectedPlayersForSubmission(
        selectedEntryFee,
        lineUpFinalWin.targetNumber,
        contestData,
        lineUp,
      );
      if (
        editLineupId &&
        location.state &&
        typeof (location.state.status === 'undefined')
      ) {
        cancelLineup(editLineupId)
          .then(() => {
            saveData(ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ID, '');
            // we adding the last field, isResubmit=true
            data.push(true);
            checkEditAndSubmit(data);
          })
          .catch(err => {
            saveData(
              ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
              APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
            );
          });
      } else {
        cancelWithoutSaving(false);
        checkEditAndSubmit(data);
      }
    }

    if (modalBoxName === APP_CONSTANTS.MODALBOX.DELETE_LINEUP) {
      if (
        !editLineupId &&
        location.state &&
        location.state.status === 'drafted'
      ) {
        cancelWithoutSaving(true);
        closeModalBox();
        return;
      }
      if (!editLineupId) {
        cancelWithoutSaving(false);
        closeModalBox();
        return;
      }

      cancelLineup(editLineupId)
        .then(() => {
          saveData(ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ID, '');
          onSuccess(APP_CONSTANTS.LINEUPS_TABS.UPCOMING);
        })
        .catch(error => {
          saveData(
            ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
            APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
          );
        });
    }
    if (modalBoxName === APP_CONSTANTS.MODALBOX.RESET_LINEUP) {
      resetPlayers();
    }
    if (Object.keys(apiErrorMessage).length) {
      setApiErrorMessage({});
      closeModalBox();
    }
  };

  const cancelHandler = () => {
    if (modalBoxName === APP_CONSTANTS.MODALBOX.EDIT_LINEUP_COMFIRMATION) {
      deselectAllposition();
    }
    saveData(ACTION_TYPE.LINEUP.SET_TOGGLE_MODAL_BOX, false);
    saveData(ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME, '');
  };
  const calculateAvg = targetNum => {
    if (!targetNum) {
      /* eslint-disable no-param-reassign */
      targetNum = (contestData && contestData.targetNumber) || 0;
    }
    return (targetNum / lineUp.length).toFixed(2);
  };

  const getActivePosition = () => {
    for (let i = 0; i < lineUp.length; i += 1) {
      if (lineUp[i].isActive) {
        const temp = sportData.positionTypes.filter(({ lineupPositions }) => {
          return lineupPositions.includes(lineUp[i].position);
        });
        return temp[0];
      }
    }
    return {};
  };

  const removePlayerFromLineup = player => {
    const temp = lineUp.map(position => {
      let tempObj = position;
      if (position.isActive) {
        tempObj = {
          ...position,
          player: '',
        };
      }
      return tempObj;
    });
    startLoader();
    getLineupStrengthWithPlayers(contestData, getPositionDetails(temp))
      .then(({ data }) => {
        saveData(ACTION_TYPE.LINEUP.SET_LINEUP_STRENGTH, {
          lineUpstrength: data.lineupStrength,
          lineUpRange: data.lineupRange,
        });
        saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, temp);
        if (!editLineupId) {
          saveAsDraft(temp, false);
        }
      })
      .catch(() => {
        refreshContestData('');
      })
      .finally(() => {
        stopLoader();
      });
  };

  const addPlayerToPosition = player => {
    const activeIndex = lineUp.findIndex(spot => spot.isActive);

    const firstEmptyPosition = lineUp.findIndex(
      position => !position.isActive && position.player === '',
    );

    const nextEmptyPosition = lineUp.findIndex(
      (spot, index) => spot.player === '' && index > activeIndex,
    );

    const nextPosition =
      nextEmptyPosition >= 0 ? nextEmptyPosition : firstEmptyPosition;

    const temp = lineUp.map((position, index) => {
      let tempObj = position;
      if (position.isActive) {
        tempObj = {
          ...position,
          player,
          isActive: false,
        };
      } else if (nextPosition === index) {
        tempObj = {
          ...position,
          isActive: true,
        };
      }
      return tempObj;
    });
    startLoader();
    getLineupStrengthWithPlayers(contestData, getPositionDetails(temp))
      .then(({ data }) => {
        saveData(ACTION_TYPE.LINEUP.SET_LINEUP_STRENGTH, {
          lineUpstrength: data.lineupStrength,
          lineUpRange: data.lineupRange,
        });
        saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, temp);
        if (!editLineupId) {
          saveAsDraft(temp, false);
        }
      })
      .catch(resp => {
        refreshContestData('');
      })
      .finally(() => {
        stopLoader();
      });
  };

  const getPlayer = () => {
    const activePlayer = lineUp.filter(({ isActive }) => isActive);
    return (activePlayer.length && activePlayer[0].player.apiId) || '';
  };

  const getPlayerGame = () => {
    const activePlayerGame = lineUp.filter(({ isActive }) => isActive);
    if (editLineUpRawData.length) {
      activePlayerGame[0].playerGame.activeTab = true;
    }
    return (activePlayerGame.length && activePlayerGame[0].playerGame) || '';
  };

  const changeRangeValue = value => {
    saveData(ACTION_TYPE.LINEUP.SET_RANGE_VALUE, value);
  };
  const getRangeData = () => {
    const rangeData = contestData.rangeData.find(
      data => data.range === lineUpStrength.lineUpRange,
    );
    const setRange = rangeData.numbers.filter(number => {
      return Math.abs(number.number - contestData.targetNumber) >= 10;
    });
    setRange.push({ number: contestData.targetNumber });

    const sortData = setRange.sort((a, b) => a.number - b.number);
    saveData(ACTION_TYPE.LINEUP.SET_RANGE_DATA, sortData);
  };

  useEffect(() => {
    if (rangeData.length) {
      const rangevalue = rangeData.findIndex(
        range => range.number === contestData.targetNumber,
      );
      saveData(ACTION_TYPE.LINEUP.SET_RANGE_VALUE, rangevalue);
    }
  }, [rangeData]);

  useEffect(() => {
    if (enablewinScreen && lineUpStrength.lineUpRange) {
      getRangeData();
    }
  }, [lineUpStrength, enablewinScreen]);

  useEffect(() => {
    if (isLocationVerified) {
      confirmLineUpSubmit();
    }
  }, [isLocationVerified]);

  const submitLineup = (isSuccess = false) => {
    if (!profileState.isUserLoggedIn && !isSuccess) {
      setShowSignUpFlow(true);
    } else if (selectedEntryFee.amount && !isLocationVerified) {
      verifyLocationAndAge();
    } else if (!selectedEntryFee.amount || isLocationVerified) {
      confirmLineUpSubmit();
    }
  };

  const afterSignUp = async (
    toggleSignupModal,
    isSuccess = false,
    signUpDraftLineup = null,
  ) => {
    setShowSignUpFlow(toggleSignupModal);
    if (isSuccess && signUpDraftLineup) {
      draftLineupAction(signUpDraftLineup)
        .then(({ data }) => {
          setInSessionStorage(APP_CONSTANTS.SPORTS_KEY.DRAFT_CONTEST, data);
        })
        .catch(err => {
          // err
        });
    }
    if (isSuccess && selectedEntryFee.amount === 0) {
      confirmLineUpSubmit();
    } else if (isSuccess) {
      await getPaymentMethoddata(isSuccess);
      fetchUserBalance();
      submitLineup(isSuccess);
    }
  };

  const resetPlayersFromLineup = () => {
    saveData(
      ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
      APP_CONSTANTS.MODALBOX.RESET_LINEUP,
    );
  };

  const deleteLineup = () => {
    saveData(
      ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
      APP_CONSTANTS.MODALBOX.DELETE_LINEUP,
    );
  };

  const closeAddFundModal = flag => {
    setShowAddFundsModal(flag);
    fetchUserBalance(true);
  };

  const closeOperation = () => {
    const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
    if (!editLineupId && token) {
      const selectedPlayers = getSelectedPlayers();
      if (selectedPlayers.length === 0) {
        cancelWithoutSaving(true);
      } else {
        setLineupDraftModal(true);
      }
    } else {
      closeModalBox();
    }
  };

  const getButtonText = type => {
    let text = BUTTON_LABELS.COMMON.OK;
    if (type === APP_CONSTANTS.MODALBOX.WALLET_BALANCE_ERROR) {
      text = BUTTON_LABELS.LINE_UP.ADD_FUND;
    } else if (type === APP_CONSTANTS.MODALBOX.DELETE_LINEUP) {
      text = BUTTON_LABELS.LINE_UP.DELETE_LINEUP;
    } else if (type === APP_CONSTANTS.MODALBOX.RESET_LINEUP) {
      text = BUTTON_LABELS.LINE_UP.RESET_LINEUP;
    }
    return text;
  };

  return (
    <>
      {!editLineupId && showDraftModal && (
        <LineupDraftModal
          close={() => setLineupDraftModal(false)}
          cancelWithoutSaving={cancelWithoutSaving}
          saveAsDraft={() => saveAsDraft()}
        />
      )}
      {showAddFundsModal && (
        <AddFundsModal
          showAddFundsModal={showAddFundsModal}
          setShowAddFundsModal={closeAddFundModal}
        />
      )}
      {showSignUpFlow && (
        <SignUpModal
          showSignUpFlow={showSignUpFlow}
          setShowSignUpFlow={afterSignUp}
          signUpDraftLineup={signUpDraftLineup}
        />
      )}
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail(
            modalBoxName,
            apiErrorMessage,
            selectedEntryFee,
          )}
          cancelButtonHandler={cancelHandler}
          cancelButtonText={BUTTON_LABELS.COMMON.CANCEL}
          okButtonHandler={processHandler}
          okButtonText={getButtonText(modalBoxName)}
          showBothButtons={
            modalBoxName === APP_CONSTANTS.MODALBOX.WALLET_BALANCE_ERROR ||
            modalBoxName === APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT ||
            modalBoxName === APP_CONSTANTS.MODALBOX.DELETE_LINEUP ||
            modalBoxName === APP_CONSTANTS.MODALBOX.RESET_LINEUP ||
            modalBoxName === APP_CONSTANTS.MODALBOX.EDIT_LINEUP_COMFIRMATION
          }
        />
      )}
      <LineUpHeader isModal closeModalBox={closeOperation} />
      <LineUpPositionDesign
        lineUp={lineUp}
        editLineUpRawData={editLineUpRawData}
        handleActivePosition={handleActivePosition}
        getSelectedPlayers={getSelectedPlayers}
        lineUpStrength={lineUpStrength}
        lineUpFinalWin={lineUpFinalWin}
        calculateAvg={calculateAvg}
        contestData={contestData}
        isActivePosition={isActivePosition}
        enablewinScreen={enablewinScreen}
        rangeData={rangeData}
        rangeSlideValue={rangeSlideValue}
        changeRangeValue={changeRangeValue}
        handleEntryFeeChange={handleEntryFeeChange}
        entryFee={entryFee}
        winAmountString={winAmountString}
        selectedEntryFee={selectedEntryFee}
        handleShowPlayer={handleShowPlayer}
        buttonEnable={buttonEnable}
        walletBalance={walletBalance}
        editLineupId={editLineupId}
        resetPlayersFromLineup={resetPlayersFromLineup}
        deleteLineup={deleteLineup}
        submitLineup={() => submitLineup()}
        isSimpleLineup={!location.state}
        getPlayer={getPlayer}
        getPlayerGame={getPlayerGame}
        getActivePosition={getActivePosition}
        deselectAllposition={deselectAllposition}
        addPlayerToPosition={addPlayerToPosition}
        removePlayerFromLineup={removePlayerFromLineup}
        dataPlayers={dataPlayers}
      />
      {toggleLoader && <Loader />}
    </>
  );
};
