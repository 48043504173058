/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { Route, Switch, withRouter, BrowserRouter } from 'react-router-dom';
import { ContextProvider } from 'Components/LineUpScreens/Context';
import { ProfileContextProvider } from 'Components/Profile/Context';
import Drawer from 'rc-drawer';
import { NavBar } from 'Components/NavBar';
import { LandingPage, LandingPageNew } from 'Components/LandingPage';
import {
  Login,
  NewLogin,
  phoneLogin,
  phoneVerification,
  EmailLoginPage,
  EmailLoginSent,
  VerifyEmail,
  VerifyEmailFD,
} from 'Components/Login';
import { MultiSignin } from 'Components/Login/MultiSignin';
import { Location } from 'Components/Location';
import { BirthDate } from 'Components/BirthDate';
import { NewGame } from 'Components/NewGame';
import { Lineup } from 'Components/LineUpScreens/Lineup';
import { EmailVerification } from 'Components/EmailVerification';
import { UserNameVerification } from 'Components/UserNameVerification';
import { ConsentScreen } from 'Components/ConsentScreen';
import { SetPassword } from 'Components/SetPassword';
import { UserDetails } from 'Components/UserDetails';
import { PhoneNumberVerification } from 'Components/PhoneNumberVerification';
import { FavoriteSport } from 'Components/Survey/FavoriteSport';
import { Wallet } from 'Components/Wallet';
import { AddFunds } from 'Components/Wallet/AddFundsScreen';
import { Cashout } from 'Components/Wallet/Cashout';
import { Checkout } from 'Components/Wallet/Checkout';
import { GetPaid } from 'Components/Survey/GetPaid';
import { PlayPreference } from 'Components/Survey/PlayPreference';
import { SelectTeam } from 'Components/Survey/SelectTeam';
import { SelectPlayers } from 'Components/Survey/SelectPlayers';
import { MessageScreen } from 'Components/MessageScreen';
import { getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import PropTypes from 'prop-types';
import { PaymentMethodList } from 'Components/Wallet/PaymentMethodList';
import { Kyc } from 'Components/Kyc';
import { ResetPassword } from 'Components/ResetPassword';
import { ResetConfirmation } from 'Components/ResetPassword/ResetConfirmation';
import { ChangePassword } from 'Components/ChangePassword';
import { ChangeEmail } from 'Components/ChangeEmail';
import { KycErrorScreen } from 'Components/Kyc/KycErrorScreen';
import { TermsOfService } from 'Components/TermsOfService';
import { PrivacyStatement } from 'Components/PrivacyStatement';
import { Settings, SettingsDesktop } from 'Components/Settings';
import { HowToPlay } from 'Components/Settings/HowToPlay';
import { Account } from 'Components/Settings/Account';
import { GameRules } from 'Components/GameRules';
import { GameScoringRules } from 'Components/GameRules/GameScoringRules';
import { ResponsibleGaming } from 'Components/ResponsibleGaming/Gaming';
import { SelfExclusion } from 'Components/ResponsibleGaming/SelfExclusion';
import { VideoTutorial } from 'Components/VideoTutorial';
// eslint-disable-next-line max-len
import { ResetPasswordScreen } from 'Components/ResetPassword/ResetPasswordScreen';
// eslint-disable-next-line max-len
import { ResetPasswordConfirmation } from 'Components/ResetPassword/ResetPasswordConfirmation';
import { ProfileScreen } from 'Components/Profile/ProfileScreen';
import { EditProfileScreen } from 'Components/Profile/EditProfileScreen';
import { ForYou } from 'Components/ForYou';
import { ForYouGuest } from 'Components/ForYouGuest';
import { ReferFriend } from 'Components/ReferFriend';
import { Notifications } from 'Components/Notifications';
import { TermsOfServiceMobile } from 'Components/TermsOfServiceMobile';
import { PrivacyStatementMobile } from 'Components/PrivacyStatementMobile';
import { AdditionalInfo } from 'Components/ResponsibleGaming/AdditionalInfo';
import { detectDevice } from 'Utils/device-utils';
import {
  EmailSignupPage,
  BirthDateEntered,
  EmailSignupSent,
  LocationAllowMust,
  LocationAllow,
  SignupComplete,
  SignupPhone,
  SignupPhoneVerification,
  NotificationAllow,
  NotificationPayment,
} from '../components/Signup';
// keep adding non secure route path here.
const NON_SECURE_ROUTES = [
  '/',
  '/login',
  '/verify-email',
  '/location',
  '/signup',
  '/birth-date',
  '/set-username',
  '/set-password',
  '/enter-details',
  '/verify-phone-number',
  '/consent',
  '/reset-password',
  '/reset-confirmation',
  '/forgot-password',
  '/forgot-password-success',
  '/m-login-phone',
  '/m-login-email',
  '/m-signup-email',
  '/m-login-phone-verify',
  '/m-signup-email-sent',
  '/m-login-email-sent',
  '/m-signup-phone',
  '/m-signup-phone-verify',
  '/m-signup-dob',
  '/m-signup-locAllow',
  '/m-signup-locAllowMust',
  '/m-signup-complete',
  '/m-signup-allow-notification',
  '/m-signup-add-payment',
];

// Routes accessible to logged in as well as non logged in user
const PUBLIC_URL = [
  '/new-game',
  '/line-up',
  '/foryou-guest',
  '/terms-of-service',
  '/privacy-statement',
  '/game-rules',
  '/game-rules/scoring/nfl',
  '/game-rules/scoring/nba',
  '/game-rules/scoring/mlb',
  '/terms-of-service-mobile',
  '/privacy-statement-mobile',
  '/msi',
  '/m-verify-email',
  '/authenticate',
];

function useDeepCompareMemoize(value) {
  const ref = useRef();
  if (value.pathname === ref.current) {
    ref.current = value.pathname;
  }
  return ref.current;
}

const RouterComponent = ({ history, location, children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [locationPath, setLocationPath] = useState('');

  useEffect(() => {
    const isMobile = detectDevice();
    const path = location.pathname;
    setLocationPath(path);
    const pathArr = path.split('/');
    const documentClassList = document.body.classList;
    let pageClassName = 'loginWrapper';

    if (pathArr[1]) {
      const subPageClassName = pathArr[2] ? pathArr[2].replace('-', '') : '';
      pageClassName = `${pathArr[1].replace(
        '-',
        '',
      )}${subPageClassName}Wrapper`;
    }

    const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);
    documentClassList.remove('mobileViewContainer');
    documentClassList.remove('webViewContainer');
    if (isMobile) {
      documentClassList.add('mobileViewContainer');
    } else {
      documentClassList.add('webViewContainer');
    }

    if (token) {
      if (NON_SECURE_ROUTES.includes(path) && !PUBLIC_URL.includes(path)) {
        history.push('/for-you');
      }
      if (!NON_SECURE_ROUTES.includes(path) || PUBLIC_URL.includes(path)) {
        documentClassList.add(pageClassName);
      }
    } else {
      if (!NON_SECURE_ROUTES.includes(path) && !PUBLIC_URL.includes(path)) {
        history.push('/');
      }
      if (
        NON_SECURE_ROUTES.includes(path) ||
        (PUBLIC_URL.includes(path) &&
          path !== '/foryou-guest' &&
          path !== '/line-up')
      ) {
        documentClassList.add('noSideBarWrap');
      }
      if (NON_SECURE_ROUTES.includes(path)) {
        if (!isMobile) {
          // documentClassList.add('signUpProcess');
          documentClassList.add('hasNewBackground');
          documentClassList.remove('webViewContainer');
          documentClassList.remove('signUpProcess');
        } else if (isMobile) {
          documentClassList.add('hasNewBackground');
        }
      }
      if (
        PUBLIC_URL.includes(path) &&
        path !== '/foryou-guest' &&
        path !== '/line-up'
      ) {
        documentClassList.add('publicPageWrap');
      }
      // if (path === '/') {
      //   documentClassList.add('mobileBlueWrapper');
      // }
      if (path === '/foryou-guest' || path === '/line-up') {
        documentClassList.add(pageClassName);
      }
      if (path === '/for-you' || path === '/line-up' || path === '/consent') {
        documentClassList.remove('hasNewBackground');
      }
      if (path === '/consent') {
        documentClassList.remove('hasNewBackground');
        documentClassList.add('signUpProcess');
      }
      if (
        path === '/terms-of-service-mobile' ||
        path === '/privacy-statement-mobile'
      ) {
        documentClassList.add('mobileStaticPages');
      }
    }

    return () => {
      documentClassList.remove('noSideBarWrap');
      documentClassList.remove('publicPageWrap');
      documentClassList.remove('signUpProcess');
      documentClassList.remove('mobileBlueWrapper');
      documentClassList.remove('mobileStaticPages');
      documentClassList.remove('hasNewBackground');
      documentClassList.remove(pageClassName);
    };
  }, useDeepCompareMemoize([location]));

  const token = getCookie(APP_CONSTANTS.USER_DATA_KEY.JWT);

  return (
    <>
      <ProfileContextProvider>
        {((locationPath &&
          token &&
          !NON_SECURE_ROUTES.includes(locationPath)) ||
          (!token && locationPath === '/foryou-guest')) && (
          <NavBar
            isDrawerOpen={isDrawerOpen}
            close={() => setIsDrawerOpen(false)}
            locationPath={locationPath}
            defaultOpen={isDrawerOpen}
            open={isDrawerOpen}
            onHandleClick={() => setIsDrawerOpen(!isDrawerOpen)}
          />
        )}
        {children}
      </ProfileContextProvider>
    </>
  );
};

RouterComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const WithRouterComponent = withRouter(RouterComponent);

export const Root = () => {
  return (
    <BrowserRouter>
      <Switch>
        <WithRouterComponent>
          <Route name="Login" exact path="/" component={LandingPageNew} />
          <Route name="Login" exact path="/login" component={NewLogin} />
          <Route name="MSI" exact path="/msi" component={MultiSignin} />
          <Route
            name="VerifyEmail"
            exact
            path="/m-verify-email"
            component={VerifyEmail}
          />
          <Route
            name="VerifyEmailFD"
            exact
            path="/authenticate"
            component={VerifyEmailFD}
          />
          <Route
            name="mPhoneLogin"
            exact
            path="/m-login-phone"
            component={phoneLogin}
          />
          <Route
            name="mPSignupPhone"
            exact
            path="/m-signup-phone"
            component={SignupPhone}
          />
          <Route
            name="mEmailLogin"
            exact
            path="/m-login-email"
            component={EmailLoginPage}
          />
          <Route
            name="mEmailSignup"
            exact
            path="/m-signup-email"
            component={EmailSignupPage}
          />
          <Route
            name="mEmailSignupSent"
            exact
            path="/m-signup-email-sent"
            component={EmailSignupSent}
          />
          <Route
            name="mEmailLoginSent"
            exact
            path="/m-login-email-sent"
            component={EmailLoginSent}
          />
          <Route
            name="Email Verification"
            path="/verify-email"
            component={EmailVerification}
            exact
          />
          <Route
            name="Phone Verification"
            path="/m-login-phone-verify"
            component={phoneVerification}
            exact
          />
          <Route
            name="Signup Phone Verification"
            path="/m-signup-phone-verify"
            component={SignupPhoneVerification}
            exact
          />
          <Route
            name="Username Verification"
            path="/set-username"
            component={UserNameVerification}
            exact
          />
          <Route
            name="Set Password"
            path="/set-password"
            component={SetPassword}
            exact
          />
          <Route
            name="User Details"
            path="/enter-details"
            component={UserDetails}
            exact
          />
          <Route
            name="Phone Number Verification"
            path="/verify-phone-number"
            component={PhoneNumberVerification}
            exact
          />
          <Route name="Location" path="/location" component={Location} exact />
          <Route
            name="BirthDate"
            path="/birth-date"
            component={BirthDate}
            exact
          />
          <Route
            name="SignupBirthDate"
            path="/m-signup-dob"
            component={BirthDateEntered}
            exact
          />
          <Route
            name="SignupLocationAllow"
            path="/m-signup-locAllow"
            component={LocationAllow}
            exact
          />
          <Route
            name="SignupLocationAllowMust"
            path="/m-signup-locAllowMust"
            component={LocationAllowMust}
            exact
          />
          <Route
            name="SignupComplete"
            path="/m-signup-complete"
            component={SignupComplete}
            exact
          />
          <Route
            name="Allow Notification"
            path="/m-signup-allow-notification"
            component={NotificationAllow}
            exact
          />
          <Route
            name="Add Payment"
            path="/m-signup-add-payment"
            component={NotificationPayment}
            exact
          />
          <Route
            name="Consent Screen"
            path="/consent"
            component={ConsentScreen}
            exact
          />
          <Route
            name="Reset Password"
            path="/reset-password"
            component={ResetPassword}
            exact
          />
          <Route
            name="Reset Confirmation"
            path="/reset-confirmation"
            component={ResetConfirmation}
            exact
          />
          <Route
            name="Forgot Password"
            path="/forgot-password"
            component={ResetPasswordScreen}
            exact
          />
          <Route
            name="Reset Password Success"
            path="/forgot-password-success"
            component={ResetPasswordConfirmation}
            exact
          />
          <Route
            name="Terms Of Service Mobile"
            path="/terms-of-service-mobile"
            component={TermsOfServiceMobile}
          />
          <Route
            name="Privacy Statement Mobile"
            path="/privacy-statement-mobile"
            component={PrivacyStatementMobile}
          />
          <ContextProvider>
            <Route name="NewGame" path="/new-game" component={NewGame} />
            <Route name="Lineup" path="/line-up" component={Lineup} />
            <Route name="Lineup" path="/for-you" component={ForYou} />
            <Route name="Lineup" path="/foryou-guest" component={ForYouGuest} />
            <Route
              name="Profile Screen"
              path="/profile"
              component={ProfileScreen}
            />
          </ContextProvider>
          <Route
            name="Favorite Sport"
            path="/favorite-sport"
            component={FavoriteSport}
          />
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route name="Wallet" path="/wallet" component={Wallet} />
          ) : (
            ''
          )}
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route name="AddFunds" path="/add-funds" component={AddFunds} />
          ) : (
            ''
          )}
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route name="CashOut" path="/cash-out" component={Cashout} />
          ) : (
            ''
          )}
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route name="Wirecard" path="/payment" component={Checkout} />
          ) : (
            ''
          )}
          <Route
            name="Get Paid"
            path="/select-get-paid-type"
            component={GetPaid}
          />
          <Route
            name="Play Preference"
            path="/select-preference"
            component={PlayPreference}
          />
          <Route
            name="Select Team"
            path="/select-team"
            component={SelectTeam}
          />
          <Route
            name="Select Player"
            path="/select-players"
            component={SelectPlayers}
          />
          <Route
            name="Message Screen"
            path="/message"
            component={MessageScreen}
          />
          <Route
            name="Payment Method List"
            path="/payment-methods"
            component={PaymentMethodList}
          />
          <Route name="KYC" path="/kyc" component={Kyc} />
          <Route
            name="KYC Error"
            path="/customer-support"
            component={KycErrorScreen}
          />
          <Route
            name="Change Password"
            path="/change-password"
            component={ChangePassword}
          />
          <Route
            name="Change Email"
            path="/change-email"
            component={ChangeEmail}
          />
          <Route
            name="Notifications"
            path="/notifications"
            component={Notifications}
          />
          <Route
            name="Terms Of Service"
            path="/terms-of-service"
            component={TermsOfService}
          />
          <Route
            name="Privacy Statement"
            path="/privacy-statement"
            component={PrivacyStatement}
          />
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route
              exact
              name="Settings"
              path="/settings"
              component={Settings}
            />
          ) : (
            ''
          )}
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route
              exact
              name="SettingsDesktop"
              path="/settings-desk"
              component={SettingsDesktop}
            />
          ) : (
            ''
          )}
          <Route
            exact
            name="HowToPlay"
            path="/how-to-play"
            component={HowToPlay}
          />
          {process.env.REACT_APP_INTERNATIONAL_ENABLED !== 'true' ? (
            <Route
              name="Account Settings"
              path="/settings/account"
              component={Account}
            />
          ) : (
            ''
          )}
          <Route
            name="Game Scoring Rules"
            path="/game-rules/scoring/:scoringGame"
            component={GameScoringRules}
          />
          <Route
            exact
            name="Game Rules"
            path="/game-rules"
            component={GameRules}
          />
          <Route
            exact
            name="Video Tutorial"
            path="/tutorial-video"
            component={VideoTutorial}
          />
          <Route
            name="Responsible Gaming"
            path="/responsible-gaming"
            component={ResponsibleGaming}
          />
          <Route
            name="Self Exclusion"
            path="/self-exclusion"
            component={SelfExclusion}
          />
          <Route
            name="Additional Info"
            path="/additional-info"
            component={AdditionalInfo}
          />
          <Route
            name="Edit Profile Screen"
            path="/edit-profile"
            component={EditProfileScreen}
          />
          <Route
            name="Refer Friend"
            path="/refer-friend"
            component={ReferFriend}
          />
          <Route name="Sign-up" path="/signup" component={Location} />
        </WithRouterComponent>
      </Switch>
    </BrowserRouter>
  );
};
