import React from 'react';

export const Button = ({ className, onClick, disabled, label }) => {
  return (
    <button
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}>
      {label}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => {},
  className: 'btn btn-primary bottomFixed',
  disabled: false,
  label: '',
};
