import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { ProgressBar } from 'Components/ProgressBar';
import { checkIfUserExists } from 'Actions/login-action';
import { firstName, lastName, toObject } from 'Utils/string-utils';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Input } from 'Components/Form-Elements/Input';
import { Button } from 'Components/Form-Elements/Button';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { chekUserExists } from 'Actions/user-exists-action';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import {
  setInSessionStorage,
  setCookie,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import Branch from 'branch-sdk';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

const API_TIMEOUT = 500;

export const EmailVerification = ({
  history,
  isModal,
  fetchAndSetModalPage,
  closeModalBox,
}) => {
  const email = getFromSessionStorage(
    APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USER_EMAIL,
  );
  const { profileState, profileContextDispatch } = useContext(ProfileContext);
  const { guestUserMail } = profileState;
  const [emailAddress, setEmail] = useState(email || guestUserMail || '');
  const [disableButton, setDisableButton] = useState(true);
  const [asyncValidation, setValidity] = useState('');
  const [valid, setValid] = useState(true);

  const {
    SOCIAL_SIGNUP_KEY: {
      EMAIL,
      FIRST_NAME,
      LAST_NAME,
      SIGNUP_TYPE,
      USERNAME,
      GMAIL_ID,
      FACEBOOK_TOKEN,
    },
  } = APP_CONSTANTS;

  const isEmailUnique = value => {
    if (!(typeof value !== 'string' || value.trim())) {
      return;
    }
    chekUserExists(`email=${value}`)
      .then(response => {
        if (response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          setValidity('');
          setDisableButton(false);
        }
      })
      .catch(({ response }) => {
        if (response === undefined) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_EMAIL);
        } else if (
          response.data.status ===
          APP_CONSTANTS.HTTP_STATUS_CODES.ALREADY_EXISTS
        ) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.EMAIL_EXISTS);
        } else if (
          response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
        ) {
          setValidity(MESSAGE_CONSTANTS.ERROR_MSG.INVALID_EMAIL);
        }
      });
  };

  useEffect(() => {
    setValidity('');
    setDisableButton(true);
    const timeout = setTimeout(() => {
      if (emailAddress && valid) {
        isEmailUnique(emailAddress);
      }
    }, API_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [emailAddress]);

  const storeData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.USER_EMAIL,
      emailAddress.trim(),
    );
    if (isModal) {
      fetchAndSetModalPage('/set-username');
      return;
    }
    history.push('/set-username');
  };

  const isUserExists = response => {
    checkIfUserExists(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          if (isModal) {
            closeModalBox(true);
          } else {
            history.push('/for-you');
          }
        }
      })
      .catch(error => {
        const consentData = toObject(error.config.data);
        const { status, code } = error.response.data;
        if (
          status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST &&
          code === MESSAGE_CONSTANTS.ERROR_CODE.USER_NOT_EXISTS
        ) {
          if (isModal) {
            fetchAndSetModalPage('/consent');
            return;
          }
          history.push({ pathname: '/consent', state: consentData });
        }
      });
  };

  const responseGoogle = response => {
    const { profileObj } = response;
    setInSessionStorage(EMAIL, profileObj.email);
    setInSessionStorage(FIRST_NAME, profileObj.givenName);
    setInSessionStorage(LAST_NAME, profileObj.familyName);
    setInSessionStorage(SIGNUP_TYPE, 'gmail');
    setInSessionStorage(USERNAME, profileObj.email);
    setInSessionStorage(GMAIL_ID, profileObj.googleId);
    const data = {
      firstName: profileObj.givenName,
      lastName: profileObj.familyName,
      email: profileObj.email,
      username: profileObj.email,
      loginstrategy: 'gmail',
      gmailId: profileObj.googleId,
    };
    isUserExists(data);
  };

  const errGoogle = response => {
    // Handle Error
  };

  const responseFacebook = response => {
    setInSessionStorage(EMAIL, response.email);
    setInSessionStorage(FIRST_NAME, response.name && firstName(response.name));
    setInSessionStorage(LAST_NAME, response.name && lastName(response.name));
    setInSessionStorage(SIGNUP_TYPE, 'facebook');
    setInSessionStorage(USERNAME, response.email);
    setInSessionStorage(FACEBOOK_TOKEN, response.accessToken);
    const data = {
      firstName: response.name && firstName(response.name),
      lastName: response.name && lastName(response.name),
      email: response.email,
      facebookToken: response.accessToken,
      username: response.email,
      loginstrategy: 'facebook',
    };
    if (response && response.status !== APP_CONSTANTS.RESPONSE_STATUS.UNKNOWN) {
      isUserExists(data);
    }
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="50" />
          <div className="d-flex topBar">
            {!isModal ? (
              <Link to="/birth-date" className="arrowBack" />
            ) : (
              <Button
                className="linkBtn arrowBack"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL,
                    {
                      action: 'Next',
                    },
                  );
                  fetchAndSetModalPage('/birth-date');
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>
        <div className="wrapper emailverificationWrapper">
          <div className="info">
            <div className="iconBig emailIcon" />
            <div className="title">
              Enter your <strong>email</strong>
            </div>
          </div>
          <div className="form-group">
            <Input
              inputType="text"
              textLabel="Enter email"
              className="form-control"
              id="verify-email-id"
              placeholder={MESSAGE_CONSTANTS.VERIFY_EMAIL.ENTER_EMAIL}
              value={emailAddress}
              handleChange={setEmail}
              getValidity={setValid}
              validationRules={['validateEmail']}
              externalError={asyncValidation}
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL,
                  {
                    action: 'Enter Email',
                  },
                );
              }}
            />
            <button
              type="button"
              onClick={() => {
                setEmail('');
              }}
              className="inputClear">
              cnacel
            </button>
          </div>
          <div className="or orEmail">{MESSAGE_CONSTANTS.VERIFY_EMAIL.OR}</div>
          <div className="socialList d-flex justify-content-center">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fields="name, email, picture"
              callback={responseFacebook}
              cssClass="fb"
              textButton=""
              scope="email,public_profile,user_birthday"
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL,
                  {
                    action: 'Facebook Login',
                  },
                );
              }}
            />
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
              render={renderProps => (
                <button
                  type="button"
                  className="g"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}>
                  {''}
                </button>
              )}
              onSuccess={responseGoogle}
              onFailure={errGoogle}
              onClick={() => {
                mixPanelTrackEvent(
                  MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL,
                  {
                    action: 'Google Login',
                  },
                );
              }}
            />
          </div>
          <div className="d-flex justify-content-center d-block d-sm-none">
            <span className="signupLink">
              Already have an account?
              <Link
                to="/login"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL,
                    {
                      action: 'Account Login',
                    },
                  );
                }}>
                {' '}
                Log in
              </Link>
            </span>
          </div>
          <Button
            className="btn btn-primary"
            disabled={disableButton}
            label={BUTTON_LABELS.COMMON.NEXT}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_EMAIL, {
                action: 'Next',
                formData: { emailAddress },
              });
              storeData();
            }}
          />
        </div>
      </div>
    </>
  );
};
