/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import {
  WEATHER_SEAGMENTS,
  TEAM_COLORS,
} from 'Components/LineUpScreens/SportData/sportsObject';
import 'StyleSheets/components/game-card.css';

import {
  formatDateMMDD,
  formatDateHHmm,
  scheduledDate,
  scheduledTime,
  formatDateTime,
} from 'Utils/date-utils';
import { ordinalSuffixOf, getLastName } from 'Utils/data-utils';
import {
  getSportProgress,
  getEventDetails,
} from 'Components/Profile/ProfileScreen/lineup-helper';
import { renderLineupDetailsViewEvents } from 'Components/LineUpScreens/PlayerDetails/helper-player-details';

export const GameCardDetails = ({
  player,
  awayTeam,
  games,
  homeTeam,
  DataPlayers,
  detailView,
}) => {
  const GAME_LABELS = {
    LIVE: 'LIVE GAME',
    NEXT: 'NEXT GAME',
    LAST: 'LAST GAME',
  };
  const timeObj = (games && getSportProgress(games, games.sportSlug)) || '';
  const last = games.status === 'Complete';
  const live = games.status === 'InProgress' && !games.finished;
  const next = games.status === 'Scheduled' || games.status === 'Created';
  const postponed = games.status === 'Postponed';
  const canceled = games.status === 'Canceled';
  const suspended = games.status === 'Suspended';
  const wdelay = games.status === 'Wdelay';
  const fdelay = games.status === 'Fdelay';
  const odelay = games.status === 'Odelay';
  const delay = games.status === 'Delayed';
  const closed = games.status === 'Closed';
  const halftime = games.status === 'Halftime';
  const events = games.events || player.events;
  const VALID_EVENTS = [
    'threepointmade',
    'twopointmade',
    'freethrowmade',
    'turnover',
    'rebound',
    'assit',
    'steal',
    'block',
    'dd',
    'td',
  ];
  const EVENT_DESC = {
    dd: { attr: 'dd', desc: 'Double-Double Bonus', points: '+1.50' },
    td: { attr: 'td', desc: 'Triple-Double Bonus', points: '+1.50' },
    'passing.tyg': {
      attr: 'passing.tyg',
      desc: '300+ YD Passing Bonus',
      points: '+3.00',
    },
    'rushing.oyg': {
      attr: 'rushing.oyg',
      desc: '100+ YD Rushing Bonus',
      points: '+3.00',
    },
    'receiving.oyg': {
      attr: 'receiving.oyg',
      desc: '100+ YD Receiving Bonus',
      points: '+3.00',
    },
    fivePlusShot: {
      attr: 'fivePlusShot',
      desc: 'Earned a 5+ shot on net bonus',
      points: '+3.00',
    },
    hatTrick: {
      attr: 'hatTrick',
      desc: 'Earned a hat trick',
      points: '+3.00',
    },
    threePlusPts: {
      attr: 'threePlusPts',
      desc: 'Earned a 3+ points bonus',
      points: '+3.00',
    },
    threePlusShot: {
      attr: 'threePlusShot',
      desc: 'Earned a 3+ blocked shots bonus',
      points: '+3.00',
    },
    thirtyFiveSaves: {
      attr: 'thirtyFiveSaves',
      desc: 'Earned a 35+ saves bonus',
      points: '+3.00',
    },
  };
  const [showAllEvents, setShowAllEvents] = useState(false);
  const gameStatusTexts = {
    InProgress: 'Live',
    Complete: 'Final',
    Scheduled: 'Scheduled',
    Created: 'Created',
    Postponed: 'Postponed',
    Canceled: 'Canceled',
    Suspended: 'Suspended',
    Rescheduled: 'Rescheduled',
    FlexSchedule: 'Flex-Schedule',
    TimeTbd: 'Time-Tbd',
    Halftime: 'Half',
    Wdelay: 'Delayed',
    Fdelay: 'Delayed',
    Odelay: 'Delayed',
    Delayed: 'Delayed',
    Closed: 'Closed',
    Maintenance: 'Maintenance',
  };

  const gameLabel =
    (live && GAME_LABELS.LIVE) ||
    (last && GAME_LABELS.LAST) ||
    (next && GAME_LABELS.NEXT);
  const topTimeLabel =
    live && timeObj.clock ? timeObj.clock : scheduledDate(games.scheduledAt);
  const bottomTimeLabel =
    live && timeObj.getQuarter
      ? `${timeObj.getQuarter()}`
      : scheduledTime(games.scheduledAt);
  const isHalf =
    live &&
    timeObj.getQuarter &&
    timeObj.getQuarter() === APP_CONSTANTS.GAME_STATUS.HALF_DISPLAY;
  const scheduleTimeLabel = formatDateTime(games.scheduledAt);
  const weatherForecastTempF = games.weather
    ? live && games.weather.current
      ? `${games.weather.current.tempF}°F`
      : games.weather.forecast
      ? `${games.weather.forecast.tempF}°F`
      : ''
    : '';
  const weatherIconClass = games.weather
    ? live && games.weather.current
      ? WEATHER_SEAGMENTS[games.weather.current.condition.toLowerCase()]
      : games.weather.forecast
      ? WEATHER_SEAGMENTS[games.weather.forecast.condition.toLowerCase()]
      : ''
    : '';
  const awayTeamDetails = games.awayTeamDetails;
  const homeTeamDetails = games.homeTeamDetails;
  const isMLB = games.sportSlug === 'mlb';
  const isNFL = games.sportSlug === 'nfl';
  const isNBA = games.sportSlug === 'nba';
  const isNHL = games.sportSlug === 'nhl';
  if (
    games.sportSlug === 'mlb' ||
    games.sportSlug === 'nfl' ||
    games.sportSlug === 'nba' ||
    games.sportSlug === 'nhl'
  ) {
    const awayTeamBgColor = TEAM_COLORS[games.sportSlug][awayTeam.shortName]
      ? `linear-gradient(90deg, ${TEAM_COLORS[games.sportSlug][awayTeam.shortName].rgba} 0, ${TEAM_COLORS[games.sportSlug][awayTeam.shortName].rgba10} 100%)`
      : '';
    const awayTeamTextColor = TEAM_COLORS[games.sportSlug][awayTeam.shortName]
      ? TEAM_COLORS[games.sportSlug][awayTeam.shortName].text
      : '#ffffff';
    const homeTeamBgColor = TEAM_COLORS[games.sportSlug][homeTeam.shortName]
      ? `linear-gradient(210deg, ${TEAM_COLORS[games.sportSlug][homeTeam.shortName].rgba} 0, ${TEAM_COLORS[games.sportSlug][homeTeam.shortName].rgba10} 100%)`
      : '';
    const homeTeamTextColor = TEAM_COLORS[games.sportSlug][homeTeam.shortName]
      ? TEAM_COLORS[games.sportSlug][homeTeam.shortName].text
      : '#ffffff';
    const innings = games.inning;
    const halfInning = games.halfInning;
    const doubleHeader = games.doubleHeader;
    const gameNumber = games.gameNumber;
    const doubleHeaderGame1 = doubleHeader && gameNumber && gameNumber === 1;
    const doubleHeaderGame2 = doubleHeader && gameNumber && gameNumber === 2;
    const currentOutcome = games.currentOutcome;
    const currentInning =
      games.currentOutcome && games.currentOutcome.inning
        ? games.currentOutcome.inning
        : 0;
    const currentHitterName =
      currentOutcome && currentOutcome.hitter && currentOutcome.hitter.name
        ? getLastName(currentOutcome.hitter.name)
        : '';
    const currentHitter =
      currentOutcome && currentOutcome.hitter && currentOutcome.hitter.apiId
        ? halfInning === 'T'
          ? games.awayLineups &&
            games.awayLineups.find(player => {
              return currentOutcome.hitter.apiId === player.playerApiId;
            })
          : games.homeLineups &&
            games.homeLineups.find(player => {
              return currentOutcome.hitter.apiId === player.playerApiId;
            })
        : '';
    const currentHitterOrder = (currentHitter && currentHitter.order) || 0;
    const currentPitcherName =
      currentOutcome && currentOutcome.pitcher && currentOutcome.pitcher.name
        ? getLastName(currentOutcome.pitcher.name)
        : '';
    const currentRunners =
      currentOutcome && currentOutcome.runners ? currentOutcome.runners : [];
    const { base1, base2, base3 } = currentRunners
      ? currentRunners.reduce(
          (runnersAtBase, runner) => {
            return {
              base1: runnersAtBase.base1 || runner.endingBase === 1,
              base2: runnersAtBase.base2 || runner.endingBase === 2,
              base3: runnersAtBase.base3 || runner.endingBase === 3,
            };
          },
          {
            base1: false,
            base2: false,
            base3: false,
          },
        )
      : { base1: false, base2: false, base3: false };
    const currentOuts =
      currentOutcome && currentOutcome.count ? currentOutcome.count.outs : 0;
    const currentBalls =
      currentOutcome && currentOutcome.count ? currentOutcome.count.balls : 0;
    const currentStrikes =
      currentOutcome && currentOutcome.count ? currentOutcome.count.strikes : 0;
    const inningScores = games.inningScores ? games.inningScores : [];
    const {
      awayTeamTotalRuns,
      awayTeamTotalHits,
      awayTeamTotalErrors,
    } = inningScores
      ? inningScores.reduce(
          (awayTeamTotal, score) => {
            console.log(score);
            return {
              awayTeamTotalRuns:
                awayTeamTotal.awayTeamTotalRuns +
                (!score.awayScore || isNaN(score.awayScore.runs)
                  ? 0
                  : score.awayScore.runs),
              awayTeamTotalHits:
                awayTeamTotal.awayTeamTotalHits +
                (!score.awayScore || isNaN(score.awayScore.hits)
                  ? 0
                  : score.awayScore.hits),
              awayTeamTotalErrors:
                awayTeamTotal.awayTeamTotalErrors +
                (!score.awayScore || isNaN(score.awayScore.errors)
                  ? 0
                  : score.awayScore.errors),
            };
          },
          {
            awayTeamTotalRuns: 0,
            awayTeamTotalHits: 0,
            awayTeamTotalErrors: 0,
          },
        )
      : { awayTeamTotalRuns: 0, awayTeamTotalHits: 0, awayTeamTotalErrors: 0 };
    const {
      homeTeamTotalRuns,
      homeTeamTotalHits,
      homeTeamTotalErrors,
    } = inningScores
      ? inningScores.reduce(
          (homeTeamTotal, score) => {
            return {
              homeTeamTotalRuns:
                homeTeamTotal.homeTeamTotalRuns +
                (!score.homeScore || isNaN(score.homeScore.runs)
                  ? 0
                  : score.homeScore.runs),
              homeTeamTotalHits:
                homeTeamTotal.homeTeamTotalHits +
                (!score.homeScore || isNaN(score.homeScore.hits)
                  ? 0
                  : score.homeScore.hits),
              homeTeamTotalErrors:
                homeTeamTotal.homeTeamTotalErrors +
                (!score.homeScore || isNaN(score.homeScore.errors)
                  ? 0
                  : score.homeScore.errors),
            };
          },
          {
            homeTeamTotalRuns: 0,
            homeTeamTotalHits: 0,
            homeTeamTotalErrors: 0,
          },
        )
      : { homeTeamTotalRuns: 0, homeTeamTotalHits: 0, homeTeamTotalErrors: 0 };
    // fill up inningScores array for displaying innings number in card
    const currentInnings =
      games.currentOutcome && games.currentOutcome.inning
        ? games.currentOutcome.inning
        : -1;
    if (currentInnings < innings) {
      for (let i = currentInnings + 1; i <= innings; i += 1) {
        inningScores[i] = !inningScores[i] && {
          homeScore: {
            inning: i,
            runs: '',
            hits: '',
            errors: '',
          },
          awayScore: {
            inning: i,
            runs: '',
            hits: '',
            errors: '',
          },
        };
      }
    }
    const awayTeamSeasonalRecords = awayTeam && awayTeam.seasonalRecords;
    const homeTeamSeasonalRecords = homeTeam && homeTeam.seasonalRecords;
    const awayTeamDivision = awayTeam && awayTeam.division;
    const homeTeamDivision = homeTeam && homeTeam.division;
    const awayTeamSeasonalRanks =
      awayTeamSeasonalRecords && awayTeamSeasonalRecords.rank;
    const homeTeamSeasonalRanks =
      homeTeamSeasonalRecords && homeTeamSeasonalRecords.rank;
    /* const awayWinLoss = isNFL
      ? awayTeam
        ? `#${
            awayTeamSeasonalRanks.division
          } ${awayTeamDivision} (${(awayTeamSeasonalRecords &&
            awayTeamSeasonalRecords.wins) ||
            0}-${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.losses) ||
            0}-${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.ties) ||
            0})`
        : ''
      : isNBA
      ? awayTeam
        ? ''
        : ''
      : awayTeamDetails
      ? `(${awayTeamDetails.win || 0}-${awayTeamDetails.loss || 0})`
      : '';
    const homeWinLoss = isNFL
      ? homeTeam
        ? `#${
            homeTeamSeasonalRanks.division
          } ${homeTeamDivision} (${(homeTeamSeasonalRecords &&
            homeTeamSeasonalRecords.wins) ||
            0}-${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.losses) ||
            0}-${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.ties) ||
            0})`
        : ''
      : isNBA
      ? homeTeam
        ? ''
        : ''
      : homeTeamDetails
      ? `(${homeTeamDetails.win || 0}-${homeTeamDetails.loss || 0})`
      : ''; */

    /*
    TODO
    Have to take the win loss for NBA, currently NBA win loss is missing in API
    */
    const awayWinLoss = isNFL
      ? awayTeam
        ? `${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.wins) ||
            0}-${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.losses) ||
            0}`
        : ''
      : isNBA
      ? awayTeam
        ? `${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.wins) ||
            0}-${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.losses) ||
            0}`
        : ''
      : isNHL
      ? awayTeam
        ? `${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.wins) ||
            0}-${(awayTeamSeasonalRecords && awayTeamSeasonalRecords.losses) ||
            0}`
        : ''
      : awayTeamDetails
      ? `${awayTeamDetails.win || 0}-${awayTeamDetails.loss || 0}`
      : '';
    /*
    TODO
    Have to take the win loss for NBA, currently NBA win loss is missing in API
    */
    const homeWinLoss = isNFL
      ? homeTeam
        ? `${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.wins) ||
            0}-${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.losses) ||
            0}`
        : ''
      : isNBA
      ? homeTeam
        ? `${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.wins) ||
            0}-${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.losses) ||
            0}`
        : ''
      : isNHL
      ? homeTeam
        ? `${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.wins) ||
            0}-${(homeTeamSeasonalRecords && homeTeamSeasonalRecords.losses) ||
            0}`
        : ''
      : homeTeamDetails
      ? `${homeTeamDetails.win || 0}-${homeTeamDetails.loss || 0}`
      : '';
    const awayProbablePitcher =
      awayTeamDetails && awayTeamDetails.probablePitcher
        ? getLastName(awayTeamDetails.probablePitcher.name)
        : '';
    const homeProbablePitcher =
      homeTeamDetails && homeTeamDetails.probablePitcher
        ? getLastName(homeTeamDetails.probablePitcher.name)
        : '';
    const awayProbablePitcherScore =
      awayTeamDetails && awayTeamDetails.probablePitcher
        ? `${
            awayTeamDetails.probablePitcher.throwingHand
              ? `${awayTeamDetails.probablePitcher.throwingHand}HP`
              : ''
          } (${
            awayTeamDetails.probablePitcher.win
              ? awayTeamDetails.probablePitcher.win
              : 0
          }-${
            awayTeamDetails.probablePitcher.loss
              ? awayTeamDetails.probablePitcher.loss
              : 0
          }) ${
            awayTeamDetails.probablePitcher.era
              ? awayTeamDetails.probablePitcher.era
              : 0
          } ERA`
        : '';
    const homeProbablePitcherScore =
      homeTeamDetails && homeTeamDetails.probablePitcher
        ? `${
            homeTeamDetails.probablePitcher.throwingHand
              ? `${homeTeamDetails.probablePitcher.throwingHand}HP`
              : ''
          } (${
            homeTeamDetails.probablePitcher.win
              ? homeTeamDetails.probablePitcher.win
              : 0
          }-${
            homeTeamDetails.probablePitcher.loss
              ? homeTeamDetails.probablePitcher.loss
              : 0
          }) ${
            homeTeamDetails.probablePitcher.era
              ? homeTeamDetails.probablePitcher.era
              : 0
          } ERA`
        : '';
    const finalAwayWinningPlayer =
      games.awayLineups &&
      games.finalPitching &&
      games.awayLineups.find(player => {
        return games.finalPitching.win.apiId === player.playerApiId;
      });
    const finalHomeWinningPlayer =
      games.homeLineups &&
      games.finalPitching &&
      games.homeLineups.find(player => {
        return games.finalPitching.win.apiId === player.playerApiId;
      });
    const finalAwayLosingPlayer =
      games.awayLineups &&
      games.finalPitching &&
      games.awayLineups.find(player => {
        return games.finalPitching.loss.apiId === player.playerApiId;
      });
    const finalHomeLosingPlayer =
      games.homeLineups &&
      games.finalPitching &&
      games.homeLineups.find(player => {
        return games.finalPitching.loss.apiId === player.playerApiId;
      });
    const awayFinalPitcher = finalAwayWinningPlayer
      ? `W:${getLastName(games.finalPitching.win.name)}`
      : finalAwayLosingPlayer
      ? `L:${getLastName(games.finalPitching.loss.name)}`
      : '';
    const homeFinalPitcher = finalHomeWinningPlayer
      ? `W:${getLastName(games.finalPitching.win.name)}`
      : finalHomeLosingPlayer
      ? `L:${getLastName(games.finalPitching.loss.name)}`
      : '';
    const awayFinalPitcherScore = finalAwayWinningPlayer
      ? games.finalPitching.win
        ? `(${games.finalPitching.win.win ? games.finalPitching.win.win : 0}-${
            games.finalPitching.win.loss ? games.finalPitching.win.loss : 0
          })`
        : ''
      : finalAwayLosingPlayer
      ? games.finalPitching.loss
        ? `(${
            games.finalPitching.loss.win ? games.finalPitching.loss.win : 0
          }-${
            games.finalPitching.loss.loss ? games.finalPitching.loss.loss : 0
          })`
        : ''
      : '';
    const homeFinalPitcherScore = finalHomeWinningPlayer
      ? games.finalPitching.win
        ? `(${games.finalPitching.win.win ? games.finalPitching.win.win : 0}-${
            games.finalPitching.win.loss ? games.finalPitching.win.loss : 0
          })`
        : ''
      : finalHomeLosingPlayer
      ? games.finalPitching.loss
        ? `(${
            games.finalPitching.loss.win ? games.finalPitching.loss.win : 0
          }-${
            games.finalPitching.loss.loss ? games.finalPitching.loss.loss : 0
          })`
        : ''
      : '';
    const awayTeamNameinScore = isNFL
      ? awayTeam.fullName
      : isNBA
      ? awayTeam.fullName
      : isNHL
      ? awayTeam.fullName
      : awayTeam.shortName;
    const homeTeamNameinScore = isNFL
      ? homeTeam.fullName
      : isNBA
      ? homeTeam.fullName
      : isNHL
      ? homeTeam.fullName
      : homeTeam.shortName;
    const currentDown =
      (currentOutcome && ordinalSuffixOf(currentOutcome.down)) || '';
    const currentDistance = (currentOutcome && currentOutcome.distance) || '';
    const currentBallLocation =
      (currentOutcome &&
        currentOutcome.ballPossession &&
        currentOutcome.ballPossession.locationInYardLine) ||
      '';
    const currentBallPossessionAway =
      currentOutcome &&
      currentOutcome.ballPossession &&
      currentOutcome.ballPossession.teamApiId === awayTeam.apiId;
    const currentBallPossessionTeam = currentBallPossessionAway
      ? awayTeam.shortName
      : homeTeam.shortName;
    const currentBallPossessionHome =
      currentOutcome &&
      currentOutcome.ballPossession &&
      currentOutcome.ballPossession.teamApiId === homeTeam.apiId;
    const currentDownAndDistance =
      currentDown && currentDistance && `${currentDown} & ${currentDistance}`;
    const currentBallPossession =
      currentBallPossessionTeam &&
      currentBallLocation &&
      ` on ${currentBallPossessionTeam} ${currentBallLocation}`;
    const awayTeamUsedTimeouts = games.awayTeamUsedTimeouts;
    const homeTeamUsedTimeouts = games.homeTeamUsedTimeouts;
    const timeoutsClasses = [
      'isActBottomLine1',
      'isActBottomLine2',
      'isActBottomLine3',
    ];
    const timeoutClassStringAway =
      awayTeamUsedTimeouts &&
      `hasBottomLines ${timeoutsClasses[awayTeamUsedTimeouts]}`;
    const timeoutClassStringHome =
      homeTeamUsedTimeouts &&
      `hasBottomLines ${timeoutsClasses[homeTeamUsedTimeouts]}`;
    const clock = games.clock;
    const period = games.period;
    const quarter = period && ordinalSuffixOf(period);
    const awayStartingQBObj =
      awayTeam &&
      awayTeam.quarterBack &&
      awayTeam.quarterBack.find(qb => {
        return qb.depth === 1;
      });
    const awayStartingQB =
      awayStartingQBObj && getLastName(awayStartingQBObj.name);
    const awayStartingQBRating =
      awayStartingQBObj &&
      awayStartingQBObj.rating &&
      `QBR ${awayStartingQBObj.rating}`;
    const homeStartingQBObj =
      homeTeam &&
      homeTeam.quarterBack &&
      homeTeam.quarterBack.find(qb => {
        return qb.depth === 1;
      });
    const homeStartingQB =
      homeStartingQBObj && getLastName(homeStartingQBObj.name);
    const homeStartingQBRating =
      homeStartingQBObj &&
      homeStartingQBObj.rating &&
      `QBR ${homeStartingQBObj.rating}`;
    const quarterScores = games.quarterScores ? games.quarterScores : [];
    const awayTeamScores = games.awayTeamScores ? games.awayTeamScores : [];
    const homeTeamScores = games.homeTeamScores ? games.homeTeamScores : [];
    // const quarterScores = [
    //   {
    //     homeScore: {
    //       quarter: 1,
    //       score: 3,
    //     },
    //     awayScore: {
    //       quarter: 1,
    //       score: 0,
    //     },
    //   },
    //   {
    //     homeScore: {
    //       quarter: 2,
    //       score: 14,
    //     },
    //     awayScore: {
    //       quarter: 2,
    //       score: 0,
    //     },
    //   },
    //   {
    //     homeScore: {
    //       quarter: 3,
    //       score: 7,
    //     },
    //     awayScore: {
    //       quarter: 3,
    //       score: 3,
    //     },
    //   },
    //   {
    //     homeScore: {
    //       quarter: 4,
    //       score: 10,
    //     },
    //     awayScore: {
    //       quarter: 4,
    //       score: 6,
    //     },
    //   },
    // ];
    // fill up inningScores array for displaying innings number in card
    if (period < 4) {
      for (let i = period; i <= 3; i += 1) {
        if (!quarterScores[i] || quarterScores[i] === false) {
          quarterScores[i] = {
            homeScore: {
              quarter: i,
              score: '-',
            },
            awayScore: {
              quarter: i,
              score: '-',
            },
          };
        }
      }
    }
    // console.log(quarterScores);
    return (
      <>
        {next || postponed || canceled ? (
          <div
            className={`gameCard ${
              isNFL ? 'nfl' : isNBA ? 'nba' : isMLB ? 'mlb' : isNHL ? 'nhl' : ''
            } isPreGame`}>
            {doubleHeaderGame1 ? (
              <div className="gameCard__dheader isGame1">
                Double Header - Game 1
              </div>
            ) : (
              ''
            )}
            {doubleHeaderGame2 ? (
              <div className="gameCard__dheader isGame2">
                Double Header - Game 2
              </div>
            ) : (
              ''
            )}
            <div className="gameCard__status">
              <span className="gameCard__statusLabel">{scheduleTimeLabel}</span>
              <span className={`gameCard__statusWeather ${weatherIconClass}`}>
                {weatherForecastTempF}
              </span>
            </div>
            {canceled || postponed ? (
              <div className="gameCard__notice">
                {gameStatusTexts[games.status]}
              </div>
            ) : (
              ''
            )}
            <div className="gameCard__header">
              <div
                className="gameCard__team awayTeam"
                style={{
                  backgroundImage: awayTeamBgColor,
                }}>
                <span className="location">
                  {awayTeam.market && awayTeam.market.toUpperCase()}
                </span>
                <span className="team">{awayTeam.name}</span>
                <span className="winloss">{awayWinLoss}</span>
              </div>
              <div
                className="gameCard__team homeTeam"
                style={{
                  backgroundImage: homeTeamBgColor,
                }}>
                <span className="location">
                  {homeTeam.market && homeTeam.market.toUpperCase()}
                </span>
                <span className="team">{homeTeam.name}</span>
                <span className="winloss">{homeWinLoss}</span>
              </div>
            </div>
            {!canceled && !postponed ? (
              isNFL ? (
                <div className="gameCard__lw">
                  {awayTeam && awayStartingQB ? (
                    <div className="gameCard__lwPlayer awayPlayer">
                      <span className="player">{awayStartingQB}</span>
                      <span className="score">{awayStartingQBRating}</span>
                    </div>
                  ) : (
                    <div className="gameCard__lwPlayer awayPlayer" />
                  )}
                  {homeTeam && homeStartingQB ? (
                    <div className="gameCard__lwPlayer homePlayer">
                      <span className="player">{homeStartingQB}</span>
                      <span className="score">{homeStartingQBRating}</span>
                    </div>
                  ) : (
                    <div className="gameCard__lwPlayer homePlayer" />
                  )}
                </div>
              ) : (
                <div className="gameCard__lw">
                  {awayTeamDetails && awayTeamDetails.probablePitcher ? (
                    <div className="gameCard__lwPlayer awayPlayer">
                      <span className="player">{awayProbablePitcher}</span>
                      <span className="score">{awayProbablePitcherScore}</span>
                    </div>
                  ) : (
                    <div className="gameCard__lwPlayer awayPlayer" />
                  )}
                  {homeTeamDetails && homeTeamDetails.probablePitcher ? (
                    <div className="gameCard__lwPlayer homePlayer">
                      <span className="player">{homeProbablePitcher}</span>
                      <span className="score">{homeProbablePitcherScore}</span>
                    </div>
                  ) : (
                    <div className="gameCard__lwPlayer homePlayer" />
                  )}
                </div>
              )
            ) : (
              ''
            )}
            {detailView ? (
              <>
                {renderLineupDetailsViewEvents(
                  events,
                  showAllEvents,
                  EVENT_DESC,
                  getEventDetails,
                  setShowAllEvents,
                  games.stats,
                  player,
                  DataPlayers,
                )}
              </>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
        {live || halftime || wdelay || fdelay || odelay || delay ? (
          <div
            className={`gameCard ${
              isNFL ? 'nfl' : isNBA ? 'nba' : isNHL ? 'nhl' : ''
            } isLiveGame`}>
            {doubleHeaderGame1 ? (
              <div className="gameCard__dheader isGame1">
                Double Header - Game 1
              </div>
            ) : (
              ''
            )}
            {doubleHeaderGame2 ? (
              <div className="gameCard__dheader isGame2">
                Double Header - Game 2
              </div>
            ) : (
              ''
            )}
            <div className="gameCard__status">
              <span className="gameCard__statusLabel">
                {halftime || (clock === '0:00' && quarter === '2ND') ? (
                  <>
                    <span>HALFTIME</span>
                    <time />
                    <time />
                  </>
                ) : clock === '0:00' && !['1ST', '3RD'].includes(quarter) ? (
                  <>
                    <span>{`END ${
                      quarter === '5TH' || quarter === '6TH' ? 'T' : quarter
                    }`}</span>
                    <time>{clock || ''}</time>
                    <time />
                  </>
                ) : (
                  <>
                    <span>{quarter || ''}</span>
                    <time>{clock || ''}</time>
                    {/* <time className={`secondHand ${isNBA ? 'orange' : ''}`} /> */}
                  </>
                )}
              </span>
              <span className={`gameCard__statusWeather ${weatherIconClass}`}>
                {weatherForecastTempF}
              </span>
            </div>
            {wdelay || fdelay || odelay || delay ? (
              <div className="gameCard__notice">
                {gameStatusTexts[games.status]}
              </div>
            ) : (
              ''
            )}
            {isNFL ? (
              <div className="gameCard__header">
                <div
                  className={`gameCard__team awayTeam ${
                    !halftime && currentBallPossessionAway ? 'hasBall' : ''
                  } ${!halftime && timeoutClassStringAway}`}
                  style={{
                    backgroundImage: awayTeamBgColor,
                  }}>
                  <span className="location">{awayTeam.shortName}</span>
                  <span className="run">{games.awayTeamScore}</span>
                  <div className="slot">
                    <span
                      className={`slot__item ${
                        awayTeamUsedTimeouts && awayTeamUsedTimeouts > 0
                          ? ''
                          : 'active'
                      }`}
                    />
                    <span
                      className={`slot__item ${
                        awayTeamUsedTimeouts && awayTeamUsedTimeouts > 1
                          ? ''
                          : 'active'
                      }`}
                    />
                    <span
                      className={`slot__item ${
                        awayTeamUsedTimeouts && awayTeamUsedTimeouts > 2
                          ? ''
                          : 'active'
                      }`}
                    />
                  </div>
                </div>
                <div
                  className={`gameCard__team homeTeam ${
                    !halftime && currentBallPossessionHome ? 'hasBall' : ''
                  } ${!halftime && timeoutClassStringHome}`}
                  style={{
                    backgroundImage: homeTeamBgColor,
                  }}>
                  <span className="location">{homeTeam.shortName}</span>
                  <span className="run">{games.homeTeamScore}</span>
                  <div className="slot">
                    <span
                      className={`slot__item ${
                        homeTeamUsedTimeouts && homeTeamUsedTimeouts > 0
                          ? ''
                          : 'active'
                      }`}
                    />
                    <span
                      className={`slot__item ${
                        homeTeamUsedTimeouts && homeTeamUsedTimeouts > 1
                          ? ''
                          : 'active'
                      }`}
                    />
                    <span
                      className={`slot__item ${
                        homeTeamUsedTimeouts && homeTeamUsedTimeouts > 2
                          ? ''
                          : 'active'
                      }`}
                    />
                  </div>
                </div>
                <div className="gameCard__field">
                  {currentBallPossessionAway ? (
                    <>
                      <span className="gameCard__fieldLines">
                        <span className="arrow left" />
                      </span>
                      <span className="gameCard__fieldCopy">
                        {currentDownAndDistance}
                      </span>
                      <span className="gameCard__fieldCopy">
                        {currentBallPossession}
                      </span>
                    </>
                  ) : currentBallPossessionHome ? (
                    <>
                      <span className="gameCard__fieldLines">
                        <span className="arrow right" />
                        <span
                          className="line right white"
                          style={{
                            right: '13%',
                          }}
                        />
                        <span
                          className="line right yellow"
                          style={{
                            right: '22%',
                          }}
                        />
                      </span>
                      <span className="gameCard__fieldCopy">
                        {currentDownAndDistance}
                      </span>
                    </>
                  ) : (
                    <span className="gameCard__fieldLines">
                      <span className="arrow right" />
                    </span>
                  )}
                </div>
              </div>
            ) : isNBA ? (
              <div className="gameCard__header">
                <div
                  className="gameCard__team awayTeam"
                  style={{
                    backgroundImage: awayTeamBgColor,
                  }}>
                  <span className="location">{awayTeam.name}</span>
                  <span className="run">{games.awayTeamScore}</span>
                  {/*
                    TODO 
                    Timeout for Away team is missing in api. Need to implement it
                    */}
                  <div className="slot">
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                  </div>
                </div>
                <div
                  className="gameCard__team homeTeam"
                  style={{
                    backgroundImage: homeTeamBgColor,
                  }}>
                  <span className="location">{homeTeam.name}</span>
                  <span className="run">{games.homeTeamScore}</span>
                  {/*
                    TODO 
                    Timeout for Away team is missing in api. Need to implement it
                    */}
                  <div className="slot">
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                  </div>
                </div>
              </div>
            ) : isNHL ? (
              <div className="gameCard__header">
                <div
                  className="gameCard__team awayTeam"
                  style={{
                    backgroundImage: awayTeamBgColor,
                  }}>
                  <span className="location">{awayTeam.name}</span>
                  <span className="run">{games.awayTeamScore}</span>
                  {/*
                    TODO 
                    Timeout for Away team is missing in api. Need to implement it
                    */}
                  {/* <div className="slot">
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                  </div> */}
                </div>
                <div
                  className="gameCard__team homeTeam"
                  style={{
                    backgroundImage: homeTeamBgColor,
                  }}>
                  <span className="location">{homeTeam.name}</span>
                  <span className="run">{games.homeTeamScore}</span>
                  {/*
                    TODO 
                    Timeout for Away team is missing in api. Need to implement it
                    */}
                  {/*  <div className="slot">
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item active" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                    <span className="slot__item" />
                  </div> */}
                </div>
              </div>
            ) : (
              <div className="gameCard__header">
                <div
                  className="gameCard__team awayTeam"
                  style={{
                    backgroundColor: awayTeamBgColor,
                    color: awayTeamTextColor,
                  }}>
                  <span className="location">{awayTeam.shortName}</span>
                  <span className="run">{games.awayTeamScore}</span>
                </div>
                <div
                  className="gameCard__player awayPlayer"
                  style={{
                    backgroundColor: awayTeamBgColor.replace(', 1)', ', 0.7)'),
                    color: awayTeamTextColor,
                  }}>
                  <span className="player">
                    {halfInning === 'T'
                      ? `${currentHitterOrder}.${currentHitterName}`
                      : ''}
                    {halfInning === 'B' ? currentPitcherName : ''}
                  </span>
                  <span className="score">
                    {halfInning === 'T'
                      ? `${
                          currentOutcome && currentOutcome.hitter.stats
                            ? currentOutcome.hitter.stats.h
                            : '0'
                        }-${
                          currentOutcome && currentOutcome.hitter.stats
                            ? currentOutcome.hitter.stats.ab
                            : '0'
                        }`
                      : ''}
                    {halfInning === 'B'
                      ? `P:${
                          currentOutcome && currentOutcome.pitcher.stats
                            ? currentOutcome.pitcher.stats.pc
                            : '0'
                        }`
                      : ''}
                  </span>
                </div>
                <div
                  className="gameCard__team homeTeam"
                  style={{
                    backgroundColor: homeTeamBgColor,
                    color: homeTeamTextColor,
                  }}>
                  <span className="location">{homeTeam.shortName}</span>
                  <span className="run">{games.homeTeamScore}</span>
                </div>
                <div
                  className="gameCard__player homePlayer"
                  style={{
                    backgroundColor: homeTeamBgColor.replace(', 1)', ', 0.7)'),
                    color: homeTeamTextColor,
                  }}>
                  <span className="player">
                    {halfInning === 'T' ? currentPitcherName : ''}
                    {halfInning === 'B'
                      ? `${currentHitterOrder}.${currentHitterName}`
                      : ''}
                  </span>
                  <span className="score">
                    {halfInning === 'B'
                      ? `${
                          currentOutcome && currentOutcome.hitter.stats
                            ? currentOutcome.hitter.stats.h
                            : '0'
                        }-${
                          currentOutcome && currentOutcome.hitter.stats
                            ? currentOutcome.hitter.stats.ab
                            : '0'
                        }`
                      : ''}
                    {halfInning === 'T'
                      ? `P:${
                          currentOutcome && currentOutcome.pitcher.stats
                            ? currentOutcome.pitcher.stats.pc
                            : '0'
                        }`
                      : ''}
                  </span>
                </div>
                <div className="gameCard__summary">
                  <span
                    className={`gameCard__summaryCount ${
                      halfInning === 'T' ? 'isActTop' : ''
                    } ${halfInning === 'B' ? 'isActBottom' : ''}`}>
                    {currentInning}
                  </span>
                  <span
                    className={`gameCard__summaryOut ${base1 ? 'isAct3' : ''} ${
                      base2 ? 'isAct2' : ''
                    } ${base3 ? 'isAct1' : ''}`}>
                    <span className="out">{currentOuts} Outs</span>
                    <span className="score">
                      {currentBalls}-{currentStrikes}
                    </span>
                  </span>
                </div>
              </div>
            )}
            {detailView ? (
              <>
                {renderLineupDetailsViewEvents(
                  events,
                  showAllEvents,
                  EVENT_DESC,
                  getEventDetails,
                  setShowAllEvents,
                  games.stats,
                  player,
                  DataPlayers,
                )}
              </>
            ) : (
              <div className="gameCard__innings">
                <div className="gameCard__inningsScroll">
                  <table className="gameCard__inningsTable">
                    <thead>
                      {isNFL ? (
                        <tr>
                          <th>Team</th>
                          {quarterScores.map((score, quarter) =>
                            quarter !== '' ? (
                              <th key={`quarter${quarter + 1}`}>
                                {quarter === 4 ? 'OT' : quarter + 1}
                              </th>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                            </>
                          ) : period === 1 ? (
                            <>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                            </>
                          ) : period === 2 ? (
                            <>
                              <th>3</th>
                              <th>4</th>
                            </>
                          ) : period === 3 ? (
                            <>
                              <th>4</th>
                            </>
                          ) : (
                            ''
                          )}
                          <th>T</th>
                        </tr>
                      ) : isNBA ? (
                        <tr>
                          <th>Team</th>
                          {awayTeamScores.map((score, quarter) =>
                            quarter !== '' ? (
                              <th key={`quarter${quarter + 1}`}>
                                {quarter === 4 ? 'OT' : quarter + 1}
                              </th>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                            </>
                          ) : period === 1 ? (
                            <>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                            </>
                          ) : period === 2 ? (
                            <>
                              <th>3</th>
                              <th>4</th>
                            </>
                          ) : period === 3 ? (
                            <>
                              <th>4</th>
                            </>
                          ) : (
                            ''
                          )}
                          <th>T</th>
                        </tr>
                      ) : isNHL ? (
                        <tr>
                          <th>Team</th>
                          {awayTeamScores.map((score, quarter) =>
                            quarter !== '' ? (
                              <th key={`quarter${quarter + 1}`}>
                                {quarter === 3 ? 'S' : quarter + 1}
                              </th>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                            </>
                          ) : period === 1 ? (
                            <>
                              <th>2</th>
                              <th>3</th>
                            </>
                          ) : period === 2 ? (
                            <>
                              <th>3</th>
                            </>
                          ) : (
                            ''
                          )}
                          <th>T</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Team</th>
                          {inningScores.map((score, inning) =>
                            inning !== 0 ? (
                              <th key={`inning${inning + 1}`}>{inning}</th>
                            ) : (
                              ''
                            ),
                          )}
                          <th>R</th>
                          <th>H</th>
                          <th>E</th>
                        </tr>
                      )}
                    </thead>
                    {isNFL ? (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {quarterScores.map((score, quarter) =>
                            score.awayScore ? (
                              <td key={`awayQuarter${quarter + 1}`}>
                                {score.awayScore.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <td />
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 1 ? (
                            <>
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 2 ? (
                            <>
                              <td />
                              <td />
                            </>
                          ) : period === 3 ? (
                            <>
                              <td />
                            </>
                          ) : (
                            ''
                          )}
                          <td>{games.awayTeamScore}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {quarterScores.map((score, quarter) =>
                            score.homeScore ? (
                              <td key={`homeQuarter${quarter + 1}`}>
                                {score.homeScore.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <td />
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 1 ? (
                            <>
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 2 ? (
                            <>
                              <td />
                              <td />
                            </>
                          ) : period === 3 ? (
                            <>
                              <td />
                            </>
                          ) : (
                            ''
                          )}
                          <td>{games.homeTeamScore}</td>
                        </tr>
                      </tbody>
                    ) : isNBA ? (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {awayTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`awayQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <td />
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 1 ? (
                            <>
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 2 ? (
                            <>
                              <td />
                              <td />
                            </>
                          ) : period === 3 ? (
                            <>
                              <td />
                            </>
                          ) : (
                            ''
                          )}
                          <td>{games.awayTeamScore}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {homeTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`homeQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <td />
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 1 ? (
                            <>
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 2 ? (
                            <>
                              <td />
                              <td />
                            </>
                          ) : period === 3 ? (
                            <>
                              <td />
                            </>
                          ) : (
                            ''
                          )}
                          <td>{games.homeTeamScore}</td>
                        </tr>
                      </tbody>
                    ) : isNHL ? (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {awayTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`awayQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 1 ? (
                            <>
                              <td />
                              <td />
                            </>
                          ) : period === 2 ? (
                            <>
                              <td />
                            </>
                          ) : (
                            ''
                          )}
                          <td>{games.awayTeamScore}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {homeTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`homeQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          {period === 0 ? (
                            <>
                              <td />
                              <td />
                              <td />
                            </>
                          ) : period === 1 ? (
                            <>
                              <td />
                              <td />
                            </>
                          ) : period === 2 ? (
                            <>
                              <td />
                            </>
                          ) : (
                            ''
                          )}
                          <td>{games.homeTeamScore}</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {inningScores.map((score, inning) =>
                            inning !== 0 ? (
                              <td key={`awayInning${inning + 1}`}>
                                {score ? score.awayScore.runs : ''}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{awayTeamTotalRuns}</td>
                          <td>{awayTeamTotalHits}</td>
                          <td>{awayTeamTotalErrors}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {inningScores.map((score, inning) =>
                            inning !== 0 ? (
                              <td key={`homeInning${inning + 1}`}>
                                {score ? score.homeScore.runs : ''}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{homeTeamTotalRuns}</td>
                          <td>{homeTeamTotalHits}</td>
                          <td>{homeTeamTotalErrors}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
        {last || suspended || closed ? (
          <div
            className={`gameCard ${
              isNFL ? 'nfl' : isNBA ? 'nba' : isMLB ? 'mlb' : isNHL ? 'nhl' : ''
            } isPostGame`}>
            <div className="gameCard__status">
              <span className="gameCard__statusLabel">Final</span>
              {suspended ? (
                <span className={`gameCard__statusWeather ${weatherIconClass}`}>
                  {weatherForecastTempF}
                </span>
              ) : (
                ''
              )}
            </div>
            {suspended ? (
              <div className="gameCard__notice">
                {gameStatusTexts[games.status]}
              </div>
            ) : (
              ''
            )}
            <div className="gameCard__header">
              <div
                className="gameCard__team awayTeam"
                style={{
                  backgroundImage: awayTeamBgColor,
                }}>
                <span className="location">
                  {awayTeam.market && awayTeam.market.toUpperCase()}
                </span>
                <span className="team">{awayTeam.name}</span>
                <span className="run">{games.awayTeamScore}</span>
                <span className="winloss">{awayWinLoss}</span>
              </div>
              <div
                className="gameCard__team homeTeam"
                style={{
                  backgroundImage: homeTeamBgColor,
                }}>
                <span className="location">
                  {homeTeam.market && homeTeam.market.toUpperCase()}
                </span>
                <span className="team">{homeTeam.name}</span>
                <span className="run">{games.homeTeamScore}</span>
                <span className="winloss">{homeWinLoss}</span>
              </div>
            </div>
            {!suspended && (awayFinalPitcher || homeFinalPitcher) ? (
              <div className="gameCard__lw">
                {awayFinalPitcher ? (
                  <div className="gameCard__lwPlayer awayPlayer">
                    <span className="player">{awayFinalPitcher}</span>
                    <span className="score">{awayFinalPitcherScore}</span>
                  </div>
                ) : (
                  <div className="gameCard__lwPlayer awayPlayer" />
                )}
                {homeFinalPitcher ? (
                  <div className="gameCard__lwPlayer homePlayer">
                    <span className="player">{homeFinalPitcher}</span>
                    <span className="score">{homeFinalPitcherScore}</span>
                  </div>
                ) : (
                  <div className="gameCard__lwPlayer homePlayer" />
                )}
              </div>
            ) : (
              ''
            )}
            {detailView ? (
              <>
                {renderLineupDetailsViewEvents(
                  events,
                  showAllEvents,
                  EVENT_DESC,
                  getEventDetails,
                  setShowAllEvents,
                  games.stats,
                  player,
                  DataPlayers,
                )}
              </>
            ) : (
              <div className={`gameCard__innings ${isNFL ? 'isNFL' : ''}`}>
                <div className="gameCard__inningsScroll">
                  <table className="gameCard__inningsTable">
                    <thead>
                      {isNFL ? (
                        <tr>
                          <th>Team</th>
                          {quarterScores.map((score, quarter) =>
                            quarter !== '' ? (
                              <th key={`quarter${quarter + 1}`}>
                                {quarter === 4 ? 'OT' : quarter + 1}
                              </th>
                            ) : (
                              ''
                            ),
                          )}
                          <th>T</th>
                        </tr>
                      ) : isNBA ? (
                        <tr>
                          <th>Team</th>
                          {awayTeamScores.map((score, quarter) =>
                            quarter !== '' ? (
                              <th key={`quarter${quarter + 1}`}>
                                {quarter === 4 ? 'OT' : quarter + 1}
                              </th>
                            ) : (
                              ''
                            ),
                          )}
                          <th>T</th>
                        </tr>
                      ) : isNHL ? (
                        <tr>
                          <th>Team</th>
                          {awayTeamScores.map((score, quarter) =>
                            quarter !== '' ? (
                              <th key={`quarter${quarter + 1}`}>
                                {quarter === 3 ? 'S' : quarter + 1}
                              </th>
                            ) : (
                              ''
                            ),
                          )}
                          <th>T</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>Team</th>
                          {inningScores.map((score, inning) =>
                            inning !== 0 ? (
                              <th key={`inning${inning + 1}`}>{inning}</th>
                            ) : (
                              ''
                            ),
                          )}
                          <th>R</th>
                          <th>H</th>
                          <th>E</th>
                        </tr>
                      )}
                    </thead>
                    {isNFL ? (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {quarterScores.map((score, quarter) =>
                            score.awayScore ? (
                              <td key={`awayQuarter${quarter + 1}`}>
                                {score.awayScore.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{games.awayTeamScore}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {quarterScores.map((score, quarter) =>
                            score.homeScore ? (
                              <td key={`homeQuarter${quarter + 1}`}>
                                {score.homeScore.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{games.homeTeamScore}</td>
                        </tr>
                      </tbody>
                    ) : isNBA ? (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {awayTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`awayQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{games.awayTeamScore}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {homeTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`homeQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{games.homeTeamScore}</td>
                        </tr>
                      </tbody>
                    ) : isNHL ? (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {awayTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`awayQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{games.awayTeamScore}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {homeTeamScores.map((score, quarter) =>
                            score ? (
                              <td key={`homeQuarter${quarter + 1}`}>
                                {score.score || 0}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{games.homeTeamScore}</td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td>{awayTeamNameinScore}</td>
                          {inningScores.map((score, inning) =>
                            inning !== 0 ? (
                              <td key={`awayInning${inning + 1}`}>
                                {score ? score.awayScore.runs : ''}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{awayTeamTotalRuns}</td>
                          <td>{awayTeamTotalHits}</td>
                          <td>{awayTeamTotalErrors}</td>
                        </tr>
                        <tr>
                          <td>{homeTeamNameinScore}</td>
                          {inningScores.map((score, inning) =>
                            inning !== 0 ? (
                              <td key={`homeInning${inning + 1}`}>
                                {score ? score.homeScore.runs : ''}
                              </td>
                            ) : (
                              ''
                            ),
                          )}
                          <td>{homeTeamTotalRuns}</td>
                          <td>{homeTeamTotalHits}</td>
                          <td>{homeTeamTotalErrors}</td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }

  return (
    <div className="nextGameWrapper">
      <div className="nextGameTitle text-center">{gameLabel}</div>
      {player.injury && (
        <button className="btnOrange" type="button">
          {player.injury.status}
        </button>
      )}
      <div className="nextGameDetails d-flex">
        <div className="teamInfo text-center">
          <div className="teamName aboutPlayer">
            {awayTeam.market && awayTeam.market.toUpperCase()}
            <span>{awayTeam.name}</span>
          </div>
          <div
            className={`aboutPlayer ${gameLabel === GAME_LABELS.NEXT &&
              'hide'}`}>
            {games.awayTeamScore}
          </div>
        </div>
        <div className="gameInfo text-center">
          <div className="versus"> </div>
          <div className="gameDatetime">
            <div className="date">
              {topTimeLabel || formatDateMMDD(games.scheduledAt)}
            </div>
            <div className="time">
              {isHalf || bottomTimeLabel || formatDateHHmm(games.scheduledAt)}
            </div>
          </div>
        </div>
        <div className="teamInfo text-center">
          <div className="teamName aboutPlayer">
            {homeTeam.market && homeTeam.market.toUpperCase()}
            <span>{homeTeam.name}</span>
          </div>
          <div
            className={`aboutPlayer ${gameLabel === GAME_LABELS.NEXT &&
              'hide'}`}>
            {games.homeTeamScore}
          </div>
        </div>
      </div>
    </div>
  );
};
