import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { normalize } from 'react-phone-input-auto-format';
import dateFormat from 'dateformat';
import { dob } from 'Utils/date-utils';

export const minimumAge = age => {
  return age >= APP_CONSTANTS.VALIDATION.MIN_AGE;
};

export const validateEmail = value => {
  return !APP_CONSTANTS.VALIDATION.VALID_EMAIL_REGEX.test(value)
    ? MESSAGE_CONSTANTS.ERROR_MSG.INVALID_EMAIL
    : '';
};

export const validateUsername = value => {
  return !APP_CONSTANTS.VALIDATION.VALID_USERNAME_REGEX.test(value)
    ? MESSAGE_CONSTANTS.ERROR_MSG.INVALID_USERNAME
    : '';
};

export const maxUserNameLength = username => {
  if (username.length > APP_CONSTANTS.MAX_USERNAME_LENGTH) {
    return MESSAGE_CONSTANTS.ERROR_MSG.MAX_USERNAME_LENGTH;
  }
  return '';
};

export const getPasswordStrength = value => {
  let strength = MESSAGE_CONSTANTS.SET_PASSWORD.WEAK;
  if (APP_CONSTANTS.VALIDATION.STRONG_PASSWORD.test(value)) {
    strength = MESSAGE_CONSTANTS.SET_PASSWORD.STRONG;
  } else if (APP_CONSTANTS.VALIDATION.AVERAGE_PASSWORD.test(value)) {
    strength = MESSAGE_CONSTANTS.SET_PASSWORD.AVERAGE;
  }
  return strength;
};

export const validateName = value => {
  return !APP_CONSTANTS.VALIDATION.VALID_NAME.test(value)
    ? MESSAGE_CONSTANTS.ERROR_MSG.NAME_FORMATE_ERROR
    : '';
};

export const validateAddress = value => {
  return !APP_CONSTANTS.VALIDATION.VALID_ADDRESS.test(value)
    ? MESSAGE_CONSTANTS.ERROR_MSG.ADDRESS_FORMATE_ERROR
    : '';
};

export const validateOptionalAddress = value => {
  return value.length > 0 && !APP_CONSTANTS.VALIDATION.VALID_ADDRESS.test(value)
    ? MESSAGE_CONSTANTS.ERROR_MSG.ADDRESS_FORMATE_ERROR
    : '';
};

export const validateMandatory = value => {
  return value ? '' : MESSAGE_CONSTANTS.ERROR_MSG.EMPTY_ERROR;
};

export const validatePhoneLength = value => {
  return normalize(value).length === 10
    ? ''
    : MESSAGE_CONSTANTS.STRING.PHONE.INVALID;
};

export const validateZip = value => {
  return normalize(value).length === 5 ? '' : MESSAGE_CONSTANTS.STRING.ZIP;
};

export const validateSSN = value => {
  return normalize(value).length === 4 ? '' : MESSAGE_CONSTANTS.STRING.SSN;
};

export const validateDOB = value => {
  const formattedValue = dateFormat(value, 'dd/mm/yyyy');
  if (APP_CONSTANTS.VALIDATION.VALID_DOB_REGEX.test(formattedValue)) {
    const dtDOB = new Date(dob(formattedValue));
    const dtCurrent = new Date();
    if (
      dtCurrent.getFullYear() - dtDOB.getFullYear() <
      APP_CONSTANTS.VALIDATION.MIN_AGE
    ) {
      return MESSAGE_CONSTANTS.ERROR_MSG.DOB_ELIGIBILTY;
    }
    if (
      dtCurrent.getFullYear() - dtDOB.getFullYear() ===
      APP_CONSTANTS.VALIDATION.MIN_AGE
    ) {
      if (dtCurrent.getMonth() < dtDOB.getMonth()) {
        return MESSAGE_CONSTANTS.ERROR_MSG.DOB_ELIGIBILTY;
      }
      if (dtCurrent.getMonth() === dtDOB.getMonth()) {
        if (dtCurrent.getDate() < dtDOB.getDate()) {
          return MESSAGE_CONSTANTS.ERROR_MSG.DOB_ELIGIBILTY;
        }
      }
    }
    return '';
  }
  return MESSAGE_CONSTANTS.ERROR_MSG.DOB_VALID_FORMAT;
};
