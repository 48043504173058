import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export const HighChart = ({ chartDetails }) => {
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartDetails} />
    </div>
  );
};

HighChart.propTypes = {
  chartDetails: PropTypes.objectOf(PropTypes.any).isRequired,
};
