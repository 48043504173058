import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { Button } from 'Components/Form-Elements/Button';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const WebcamCapture = ({
  close,
  setTempImage,
  toggleModalBox,
  setModalBoxName,
}) => {
  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 250,
    height: 250,
    facingMode: 'user',
  };

  const closeAndStopCam = () => {
    close();
    window.localStream.getTracks().forEach(track => {
      track.stop();
    });
  };

  const capture = useCallback(() => {
    // getScreenshot returns a base64 string
    const imageSrc = webcamRef.current.getScreenshot();
    setTempImage(imageSrc);
    setModalBoxName(APP_CONSTANTS.MODALBOX.CROP_IMAGE);
    toggleModalBox();
    closeAndStopCam();
  }, [webcamRef]);

  return (
    <>
      <Webcam
        audio={false}
        height={250}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={250}
        videoConstraints={videoConstraints}
      />
      <Button
        className="btn btn-link d-block"
        onClick={() => {
          mixPanelTrackEvent(
            MIXPANEL_EVENTS_CONSTANTS.TAP_EDIT_PROFILE_ALL_INTERACTIONS,
            {
              action: 'Capture Photo',
            },
          );
          capture();
        }}
        label="Capture Photo"
      />
      <Button
        className="btn btn-link d-block txtCancel"
        onClick={() => {
          mixPanelTrackEvent(
            MIXPANEL_EVENTS_CONSTANTS.TAP_EDIT_PROFILE_ALL_INTERACTIONS,
            {
              action: 'Cancel',
            },
          );
          closeAndStopCam();
        }}
        label="cancel"
      />
    </>
  );
};

WebcamCapture.propTypes = {
  close: PropTypes.func.isRequired,
  setTempImage: PropTypes.func.isRequired,
  toggleModalBox: PropTypes.func.isRequired,
  setModalBoxName: PropTypes.func.isRequired,
};
