/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Route, Redirect } from 'react-router';
import {
  requestEmailVerification,
  authenticateEmailVerification,
  requestPhoneVerification,
  authenticatePhoneVerification,
  updateDob,
  getDraftUser,
  updateLocation,
} from 'Actions/new-auth-action';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import {
  setCookie,
  setInSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from 'Utils/storage-utilities';
import { normalize } from 'react-phone-input-auto-format';
import Branch from 'branch-sdk';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';

export const VerifyEmail = ({ history }) => {
  const [error, setError] = useState('');
  const [forbiddenErr, setForbiddenErr] = useState('');
  const { profileContextDispatch } = useContext(ProfileContext);
  const [userName, setUserName] = useState('');

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const method = url.searchParams.get('method');
  const token = url.searchParams.get('token');
  const email = url.searchParams.get('email');
  const phone = url.searchParams.get('phone');
  const phoneVerified = url.searchParams.get('phoneVerified');
  const accountMerge = url.searchParams.get('accountMerge');

  const openSignupPhone = successMsg => {
    const path = `/m-signup-phone`;
    history.push(path, { email, successMsg });
  };
  const openDobPage = successMsg => {
    const path = `/m-signup-dob`;
    history.push(path, { email, successMsg });
  };

  useEffect(() => {
    const userExists = getFromSessionStorage('user-already-exists');
    setUserName(getFromSessionStorage('existing-email'));
    if (userExists) {
      setError('You are an already existing user, please login to continue.');
    }
  }, []);

  useEffect(() => {
    clearSessionStorage();
    Branch.init(process.env.REACT_APP_BRANCH_KEY, console.error);
  }, []);

  const authenticateEmail = (response, step, successMsg) => {
    setForbiddenErr('');
    setError('');
    authenticateEmailVerification(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
          setTimeout(function() {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
              action: 'Username Login',
              formData: {
                user: data.userName,
              },
            });
          }, 2000);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN,
            payload: true,
          });
          setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
          if (data.groups) {
            delete data.groups;
          }
          setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
          profileContextDispatch({
            type: ACTION_TYPE.PROFILE.USER_IMAGE,
            payload: data.imageUrl,
          });
          Branch.setIdentity(data.publicId);
          Branch.data();
          history.push('/for-you');
        } else if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          if (step === 'phone') {
            openSignupPhone(successMsg);
          }
          if (step === 'dob') {
            openDobPage(successMsg);
          }
        }
      })
      .catch(error => {
        const { status, code, message } = error.response.data;
        const path = `/m-signup-email`;
        history.push(path, { apiError: message });
      });
  };

  useEffect(() => {
    if (phoneVerified === 'true' && method === 'login') {
      const data = {
        token,
        method,
      };
      const userExists = authenticateEmail(data, 'phone');
    } else if (phoneVerified === 'true' && method === 'signup') {
      const data = {
        token,
        method,
      };
      const userExists = authenticateEmail(
        data,
        'dob',
        MESSAGE_CONSTANTS.AUTHENTICATION.DOB_AFTER_EMAIL,
      );
    } else if (accountMerge === 'true' && method === 'login') {
      const phoneWithoutCode = phone.slice(-10);
      const data = {
        token,
        method,
        phoneNo: `+1${normalize(phoneWithoutCode)}`,
      };
      const userExists = authenticateEmail(data, '');
    } else if (phoneVerified === 'false' && method === 'login') {
      let phoneWithoutCode;
      if (phone) {
        phoneWithoutCode = phone.slice(-10);
      }
      const path = `/m-login-phone`;
      history.push(path, {
        phone: phoneWithoutCode ? +phoneWithoutCode : null,
        email,
        successMsg: MESSAGE_CONSTANTS.AUTHENTICATION.PHONE_NOT_VERIFIED,
      });
    } else {
      const data = {
        token,
        method,
        email,
      };
      const userExists = authenticateEmail(
        data,
        'phone',
        MESSAGE_CONSTANTS.AUTHENTICATION.EMAIL_VERIFIED_SIGNUP,
      );
    }
  }, []);

  return (
    <>
      <div />
    </>
  );
};
