/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import Branch from 'branch-sdk';
import { createDeposit } from 'Actions/wirecard-action';

import {
  getFromSessionStorage,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { TRACKING_CONSTANTS } from 'Constants/branch-tracking';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { detectDevice } from 'Utils/device-utils';
import { getUserDetail } from 'Actions/user-profile-action';

export const processPayment = (commonProps, userLocation, createObj) => {
  const amount = getFromSessionStorage('selected-amount');
  const isMobile = detectDevice();
  const {
    ADD_FUNDS_VALIDATION,
    WIRECARD_RESP,
    ERROR_MSG: { TIMEOUT },
  } = MESSAGE_CONSTANTS;
  const eventName = TRACKING_CONSTANTS.DEPOSIT;

  const { fetchAndSetModalPage, isModal, history } = commonProps;
  let depositId;
  let depositMethodId;
  const showError = error => {
    setInSessionStorage('message', error);
    setInSessionStorage('screen', WIRECARD_RESP.ERROR);
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/message')
        : history.push('/message')
      : fetchAndSetModalPage('/message');
  };

  const showSuccess = msg => {
    setInSessionStorage('message', msg);
    setInSessionStorage('screen', 'success');
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/message')
        : history.push('/message')
      : fetchAndSetModalPage('/message');
  };

  getUserDetail()
    .then(({ data }) => {
      const { accountVerified } = data.data;
      if (accountVerified) {
        if (createObj) {
          return createDeposit(createObj)
            .then(({ data }) => {
              console.log('create deposit started');
              // setIsLoading(false);
              if (data.error) {
                showError(data.reason);
              }
              if (data && !data.error) {
                depositId = data;
                const payload = {
                  amount,
                  deposit_id: depositId,
                  deposit_method_id: depositMethodId,
                };
                mixPanelTrackEvent('deposit', payload);
                try {
                  const metaData = {
                    amount,
                    deposit_id: depositId,
                    deposit_method_id: depositMethodId,
                  };
                  Branch.logEvent(eventName, metaData);
                } catch (error) {
                  console.log(error);
                }
                showSuccess(ADD_FUNDS_VALIDATION.FUNDS_ADDED);
              }
            })
            .catch(() => {
              showError(encodeURIComponent('Payment Failed!'));
              // setIsLoading(false);
            });
        }
      } else if (!accountVerified) {
        setInSessionStorage('pnm-response', createObj);
        isMobile
          ? isModal
            ? fetchAndSetModalPage('/kyc')
            : history.push('/kyc')
          : fetchAndSetModalPage('/kyc');
      } else {
        isMobile
          ? isModal
            ? fetchAndSetModalPage('/customer-support')
            : history.push('/customer-support')
          : fetchAndSetModalPage('/customer-support');
      }
      return false;
    })
    .catch(err => {
      isMobile
        ? isModal
          ? fetchAndSetModalPage('/customer-support')
          : history.push('/customer-support')
        : fetchAndSetModalPage('/customer-support');
    });
};
