import React, { useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { Input } from 'Components/Form-Elements/Input';
import { requestResetPassword } from 'Actions/reset-password-action';
import { Link } from 'react-router-dom';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { setInSessionStorage } from 'Utils/storage-utilities';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ResetPassword = ({ history }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitForReset = () => {
    setIsLoading(true);
    requestResetPassword({ email })
      .then(({ data }) => {
        setIsLoading(false);
        if (
          data &&
          data.status === APP_CONSTANTS.USER_EXISTS.STATUS &&
          data.code === APP_CONSTANTS.USER_EXISTS.CODE
        ) {
          setMessage(MESSAGE_CONSTANTS.RESET_PASSWORD.ERR_MESSAGE);
          return;
        }
        history.push({ pathname: '/reset-confirmation' });
      })
      .catch(err => {
        setInSessionStorage('screen', 'error');
        setInSessionStorage('message', err);
        history.push('/message');
      });
  };

  const renderProgressDialog = () => {
    return isLoading && <Spinner />;
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="forgotPasswordPage">
          <div className="d-flex topBar resetTopBar">
            <Link
              to="/"
              className="link bottomFixed"
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.FORGET_PASSWORD, {
                  action: 'Cancel Forget Password',
                });
              }}>
              Cancel
            </Link>
            <h2 className="heading">{MESSAGE_CONSTANTS.RESET_PASSWORD.PAGE}</h2>
          </div>
          <div className="wrapper">
            <div className="info">
              <div className="forgotPasswordHeading">
                <strong>{MESSAGE_CONSTANTS.RESET_PASSWORD.HEADING}</strong>
              </div>
              <div className="description">
                {MESSAGE_CONSTANTS.RESET_PASSWORD.DESCRIPTION}
              </div>
            </div>
            <form onSubmit={e => e.preventDefault()}>
              <div className="form-group">
                <Input
                  inputType="text"
                  className="form-control"
                  id="email"
                  handleChange={setEmail}
                  value={email}
                  textLabel="Email address"
                  validationRules={['validateEmail']}
                  getValidity={err => setError(err)}
                />
              </div>
              {message && (
                <span className="invalid-feedback showMsg">{message}</span>
              )}
              <div className="centerText">{renderProgressDialog()}</div>
              <Button
                className="btn btn-primary"
                disabled={!error}
                label={BUTTON_LABELS.COMMON.NEXT}
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.FORGET_PASSWORD,
                    {
                      action: 'Submit',
                      formData: {
                        userEmail: email,
                      },
                    },
                  );
                  submitForReset();
                }}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
