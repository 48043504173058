import React from 'react';
import PropTypes from 'prop-types';

export const ProgressBar = ({ percentage }) => (
  <div className="progress" style={{ height: '4px' }}>
    <div
      className="progress-bar"
      style={{ width: `${percentage}%` }}
      aria-valuenow={percentage}
      aria-valuemin="0"
      aria-valuemax="100"
    />
  </div>
);

ProgressBar.propTypes = {
  percentage: PropTypes.string.isRequired,
};
