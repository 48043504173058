/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Branch from 'branch-sdk';
import {
  startCreateDeposit,
  createDeposit,
  createDepositMethod,
} from 'Actions/wirecard-action';
import {
  verifyUserIdentity,
  createStartWithdrawal,
} from 'Actions/user-balance-action';
import { updateUserProfileZipcode } from 'Actions/user-profile-action';
import { Button } from 'Components/Form-Elements/Button';
import {
  getCookie,
  getFromSessionStorage,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Loader } from 'Components/Loader';
import { TRACKING_CONSTANTS } from 'Constants/branch-tracking';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { mixPanelTrackEvent, mixpanelTrackPageView } from 'Utils/mix-panel';
import { detectDevice } from 'Utils/device-utils';
import { BUTTON_LABELS } from 'Constants/button-labels';
import bank from '../../assets/icons/dn-bank.svg';
import card from '../../assets/icons/dn-card.svg';

export const Checkout = ({
  history,
  fetchAndSetModalPage,
  closeModalBox,
  isModal,
}) => {
  const amount = getFromSessionStorage('selected-amount');
  const isMobile = detectDevice();
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAddMethod, setIsShowAddMethod] = useState(true);
  const [isShowPNM, setIsShowPNM] = useState(false);
  const [paymentType, setPaymentType] = useState('ach');
  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
  const {
    ADD_FUNDS_VALIDATION,
    WIRECARD_RESP,
    ERROR_MSG: { TIMEOUT },
  } = MESSAGE_CONSTANTS;
  const eventName = TRACKING_CONSTANTS.DEPOSIT;

  let depositId;
  let depositMethodId;

  const showError = error => {
    setInSessionStorage('message', error);
    setInSessionStorage('screen', WIRECARD_RESP.ERROR);
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/message')
        : history.push('/message')
      : fetchAndSetModalPage('/message');
  };

  const showSuccess = msg => {
    setInSessionStorage('message', msg);
    setInSessionStorage('screen', 'success');
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/message')
        : history.push('/message')
      : fetchAndSetModalPage('/message');
  };

  const callBackAddFund = () => {
    console.log('payment method added');
    isMobile ? history.push('/add-funds') : fetchAndSetModalPage('/add-funds');
  };

  const callBackChashOut = () => {
    console.log('payment method added');
    isMobile ? history.push('/cash-out') : fetchAndSetModalPage('/cash-out');
  };

  const createSetIframe = (embeddedJsUrl, element, callBackFunc, type) => {
    window.callbackpayment = callBackFunc;
    element.src = `${embeddedJsUrl}?go=1&container=pnm_div_go`;
    element.setAttribute('data-callback', 'callbackpayment');
    element.setAttribute('data-only', type === 'ach' ? 'ach' : 'debit,credit');
    document.getElementsByTagName('head')[0].appendChild(element);
    setIsShowAddMethod(false);
    setIsShowPNM(true);
    setIsLoading(false);
  };
  const loadPNMForm = type => {
    setPaymentType(type);
    setIsLoading(true);
    const element = document.createElement('script');
    const isCashOutProcess = getFromSessionStorage('is-cashout');
    if (!isCashOutProcess) {
      startCreateDeposit(userdata.token)
        .then(({ data }) => {
          console.log('Start create deposit started');
          createSetIframe(data.embeddedJsUrl, element, callBackAddFund, type);
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      const cashOutAmount = getFromSessionStorage('cash-out-amount');
      const body = {
        cashOut: true,
        amount: {
          amount: cashOutAmount,
          currencyIsoCode: 'USD',
        },
      };
      createStartWithdrawal(body)
        .then(({ data }) => {
          console.log('Create withdrawal started');
          createSetIframe(data.embeddedJsUrl, element, callBackChashOut, type);
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err);
        });
    }
    mixpanelTrackPageView('Payment Screen');

    return () => {
      document.head.removeChild(element);
      setIsLoading(false);
    };
  };

  return (
    <>
      {isShowAddMethod ? (
        isMobile ? (
          <div className="paymentMethodWrapper">
            <div className="clist">
              <a className="clist__item" onClick={() => loadPNMForm('ach')}>
                <span
                  className="clist__icon"
                  style={{ backgroundImage: `url(${bank})` }}
                />
                <div className="clist__content">
                  <h4 className="clist__title">
                    {MESSAGE_CONSTANTS.WALLET.ADD_BANK.HEADING1}
                  </h4>
                  <p className="clist__subtitle">
                    {MESSAGE_CONSTANTS.WALLET.ADD_BANK.DETAIL}
                  </p>
                </div>
              </a>
              <a className="clist__item" onClick={() => loadPNMForm('card')}>
                <span
                  className="clist__icon"
                  style={{ backgroundImage: `url(${card})` }}
                />
                <div className="clist__content">
                  <h4 className="clist__title">
                    {MESSAGE_CONSTANTS.WALLET.ADD_CARD.HEADING1}
                  </h4>
                  <p className="clist__subtitle">
                    {MESSAGE_CONSTANTS.WALLET.ADD_CARD.DETAIL}
                  </p>
                </div>
              </a>
            </div>
            {isLoading && <Loader />}
          </div>
        ) : (
          <div className="nodal open" id="choose_bank_or_card">
            <div className="nodal__dialog">
              <div className="nodal__overlay" data-dismiss />
              <div className="nodal__content">
                <header className="nodal__header">
                  <h1 className="nodal__title">
                    {MESSAGE_CONSTANTS.WALLET.ADD_METHOD.HEADING}
                  </h1>
                  <div className="nodal__leftAct">
                    <Button
                      className="button"
                      data-dismiss
                      label={BUTTON_LABELS.COMMON.CANCEL}
                      onClick={() => closeModalBox()}
                    />
                  </div>
                </header>
                <main className="nodal__body">
                  <div className="clist">
                    <a
                      className="clist__item"
                      onClick={() => loadPNMForm('ach')}>
                      <span
                        className="clist__icon"
                        style={{ backgroundImage: `url(${bank})` }}
                      />
                      <div className="clist__content">
                        <h4 className="clist__title">
                          {MESSAGE_CONSTANTS.WALLET.ADD_BANK.HEADING1}
                        </h4>
                        <p className="clist__subtitle">
                          {MESSAGE_CONSTANTS.WALLET.ADD_BANK.DETAIL}
                        </p>
                      </div>
                    </a>
                    <a
                      className="clist__item"
                      onClick={() => loadPNMForm('card')}>
                      <span
                        className="clist__icon"
                        style={{ backgroundImage: `url(${card})` }}
                      />
                      <div className="clist__content">
                        <h4 className="clist__title">
                          {MESSAGE_CONSTANTS.WALLET.ADD_CARD.HEADING1}
                        </h4>
                        <p className="clist__subtitle">
                          {MESSAGE_CONSTANTS.WALLET.ADD_CARD.DETAIL}
                        </p>
                      </div>
                    </a>
                  </div>
                  {isLoading && <Loader />}
                </main>
              </div>
            </div>
          </div>
        )
      ) : (
        ''
      )}
      {isShowPNM ? (
        isMobile ? (
          <div className="pnm">
            <div className="d-flex popUpHeader">
              <div className="leftSide">
                <Link to="/wallet">{BUTTON_LABELS.COMMON.CANCEL}</Link>
              </div>
              <h2>{MESSAGE_CONSTANTS.WALLET.ADD_METHOD.HEADING}</h2>
              <div className="walletAmountBtn rightSide" />
            </div>
            <div className="pnmWrapper">
              <div className="info">
                <div className="pnmContainer" id="pnm_div_go">
                  {isLoading && <Loader />}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="nodal open" id="pnm">
            <div className="nodal__dialog">
              <div className="nodal__overlay" data-dismiss />
              <div className="nodal__content">
                <header className="nodal__header">
                  <h1 className="nodal__title">
                    {paymentType === 'ach'
                      ? MESSAGE_CONSTANTS.WALLET.ADD_BANK.HEADING
                      : MESSAGE_CONSTANTS.WALLET.ADD_CARD.HEADING}
                  </h1>
                  <div className="nodal__leftAct">
                    <Button
                      className="button"
                      data-dismiss
                      label={BUTTON_LABELS.COMMON.CANCEL}
                      onClick={() => closeModalBox()}
                    />
                  </div>
                </header>
                <main className="nodal__body">
                  <div className="pnmContainer" id="pnm_div_go">
                    {isLoading && <Loader />}
                  </div>
                </main>
              </div>
            </div>
          </div>
        )
      ) : (
        ''
      )}
    </>
  );
};
