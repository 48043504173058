/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import { getContestFeeDetails } from 'Actions/contest-action';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { getContest } from 'Actions/sport-action';
import {
  draftPlayers,
  receiveDraftPlayers,
  getLineupStrengthWithPlayers,
} from 'Actions/player-selection-action';
import { ACTION_TYPE } from 'Constants/action-type';

export const onDenyLocation = (error, saveData) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.PERMISSION_ALLOW,
      );
      break;
    case error.POSITION_UNAVAILABLE:
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.LOCATION_UNAVAILABLE,
      );
      break;
    case error.TIMEOUT:
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.TIMEOUT,
      );
      break;
    default:
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.UNKNOWN_ERR,
      );
      break;
  }
};

export const getInitialAmount = (DEFAULT_ENTRY_FEE, location) => {
  let intialAmount = DEFAULT_ENTRY_FEE;
  if (
    location.state &&
    location.state.status &&
    location.state.status === 'drafted'
  ) {
    intialAmount = location.state.selectedEntryfees;
  } else if (location.state && location.state.length) {
    intialAmount = location.state[0].id.amount;
  }
  return intialAmount;
};

export const reduceData = data => {
  if (!data) {
    return;
  }

  return data.reduce((obj1, obj2) => {
    if (obj2.playerGame.playerId._str) {
      obj1[obj2.playerGame.playerId._str] = obj2;
    }
    return obj1;
  }, {});
};

export const modalBoxDetail = (
  modalBoxName,
  apiErrorMessage,
  selectedEntryFee,
) => {
  let modalbox = {};
  const key = Object.keys(APP_CONSTANTS.MODALBOX).find(
    key => APP_CONSTANTS.MODALBOX[key] === modalBoxName,
  );
  if (key) {
    modalbox = MESSAGE_CONSTANTS.MODALBOX[key];
    if (
      modalBoxName === APP_CONSTANTS.MODALBOX.CONFIRM_SUBMIT &&
      selectedEntryFee.amount !== 0
    ) {
      modalbox = {
        ...modalbox,
        message: `You are submitting $${selectedEntryFee.amount} lineup.`,
      };
    }
  } else if (Object.keys(apiErrorMessage).length) {
    modalbox = apiErrorMessage;
  }
  return modalbox;
};

export const getDraftContest = (sportSlug, saveData) => {
  getContestFeeDetails(sportSlug)
    .then(resp => {
      if (resp) {
        saveData(ACTION_TYPE.LINEUP.SET_CONTEST_DATA, resp.data);
      }
    })
    .catch(() => {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
      );
    });
};

export const getPositionDetails = lineUpData => {
  const tempPosition = lineUpData.map(position => {
    if (position.player)
      return {
        position: position.position,
        playerId: position.player.apiId,
      };
    return {
      position: position.position,
    };
  });
  return tempPosition;
};

export const UpdatePlayerData = (players, lineup) => {
  const playerData = reduceData(players);
  const lineupData = lineup.map(position => {
    if (
      position.player &&
      playerData[position.player.playerGame.playerId._str]
    ) {
      return {
        ...position,
        player: playerData[position.player.playerGame.playerId._str],
      };
    }
    return {
      ...position,
      player: '',
    };
  });
  return lineupData;
};

export const updateLineUpStrength = (
  selectedContest,
  tempLineUp,
  startLoader,
  stopLoader,
  saveData,
) => {
  startLoader();
  getLineupStrengthWithPlayers(selectedContest, getPositionDetails(tempLineUp))
    .then(({ data }) => {
      saveData(ACTION_TYPE.LINEUP.SET_LINEUP_STRENGTH, {
        lineUpstrength: data.lineupStrength,
        lineUpRange: data.lineupRange,
      });
      stopLoader();
    })
    .catch(() => {
      saveData(
        ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
        APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
      );
      stopLoader();
    });
};

const reduceContestData = contests => {
  if (!contests) {
    return;
  }

  return contests.reduce((obj1, obj2) => {
    obj1[obj2.sportSlug] = obj2;
    return obj1;
  }, {});
};

export const refreshLineup = async (
  slug,
  lineUp,
  saveData,
  startLoader,
  stopLoader,
  error,
) => {
  startLoader();
  saveData(
    ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
    error === MESSAGE_CONSTANTS.ERROR_MSG.MIN_TWO_GAMES
      ? APP_CONSTANTS.MODALBOX.UPDATE_LINEUP
      : APP_CONSTANTS.MODALBOX.REFRESH_LINEUP,
  );
  let selectedContest = {};
  await getContest().then(({ data }) => {
    const contestData = reduceContestData(data.contests);
    selectedContest = contestData[slug];
    if (Object.keys(selectedContest))
      getDraftContest(selectedContest.slug, saveData);
    draftPlayers(selectedContest)
      .then(({ data }) => {
        const player = receiveDraftPlayers(data);
        saveData(ACTION_TYPE.LINEUP.SET_RAW_PLAYERS, player);
        const lineupdata = UpdatePlayerData(player, lineUp);
        saveData(ACTION_TYPE.LINEUP.SET_LINE_UP, lineupdata);
        updateLineUpStrength(
          selectedContest,
          lineupdata,
          startLoader,
          stopLoader,
          saveData,
        );
      })
      .catch(() => {
        saveData(
          ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME,
          APP_CONSTANTS.MODALBOX.SOMETHING_WRONG,
        );
      })
      .finally(() => {
        stopLoader();
      });
  });
};
