import React from 'react';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';

import { Button } from 'reactstrap';
import {
  formatDateHHmm,
  formatDatell,
  formatAndDateAdd,
} from '../../utils/date-utils';

export const TransactionDetails = ({
  amount,
  createDate,
  withdrawalId = null,
  type,
  cancelWithdrawalAction,
  currencyIsoCode,
}) => {
  return (
    <div className="transactionHistoryWrapper">
      {withdrawalId && (
        <>
          <div className="transactionDetailWrap">
            <Button
              color="link"
              style={{ lineHeight: 'normal', height: 'auto' }}
              size="sm"
              onClick={() =>
                cancelWithdrawalAction(withdrawalId)
              }>{`${MESSAGE_CONSTANTS.CANCEL} Transaction`}</Button>
          </div>
        </>
      )}

      <div className="transactionDetailWrap">
        <div className="typeofTransaction">Type</div>
        <div className="transactionContents">
          {
            APP_CONSTANTS.TRANSACTION_TYPE[
              withdrawalId ? APP_CONSTANTS.CASH_OUT : type
            ]
          }
        </div>
      </div>
      <div className="transactionDetailWrap">
        <div className="typeofTransaction">Amount</div>
        <div className="transactionContent">
          {currencyIsoCode === MESSAGE_CONSTANTS.CURRENCY_ISO_CODE.USD
            ? `$${amount}`
            : `${amount}xp`}
        </div>
      </div>
      <div className="transactionDetailWrap">
        <div className="typeofTransaction">Source</div>
        <div className="transactionContent">DN Wallet</div>
      </div>
      <div className="transactionDetailWrap">
        <div className="typeofTransaction">Date initialized</div>
        <div className="transactionContent">{formatDatell(createDate)}</div>
      </div>

      <div className="transactionDetailWrap">
        <div className="typeofTransaction">Time initialized</div>
        <div className="transactionContent">{formatDateHHmm(createDate)}</div>
      </div>
      {type !== 'Add Funds' && (
        <div className="transactionDetailWrap">
          <div className="typeofTransaction">Scheduled to Arrive</div>
          <div className="transactionContent">
            {formatAndDateAdd(createDate, APP_CONSTANTS.NO_OF_ARRIVAL_DAYS)}
          </div>
        </div>
      )}
      {withdrawalId && (
        <div className="transactionDetailWrap">
          <div className="typeofTransaction">Transaction ID</div>
          <div className="transactionContent">{withdrawalId}</div>
        </div>
      )}
    </div>
  );
};
