import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const searchTeams = async (data, pageNo = 1, limit = 8) => {
  const url = `${API.GET_TEAMS}?name=${data}&pageNo=${pageNo}&limit=${limit}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};

export const searchPlayers = async (data, pageNo = 1, limit = 8) => {
  const url = `${API.GET_PLAYERS}?name=${data}&pageNo=${pageNo}&limit=${limit}`;
  const response = await NetworkManager.requestGET(url);
  return response;
};
