import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

/* eslint-disable */
export const AdditionalInfo = ({ history, onBack }) => {
  return (
    <>
      <div className="addtionalWrapper">
        <div className="pageHeaderWrap">
        <div className="d-flex pageHeader resetTopBar  flexStartWrap">
        <div className="leftSide">
          <Link
            to="#"
            className="arrowBack"
            onClick={() => {
              if (typeof onBack === 'function') {
                onBack();
              } else {
                history.push('/responsible-gaming');
              }
            }}
          />
          </div>
          <h2>Additional Info</h2>
          <div className="rightSide" />
        </div>
        </div>
        <div className="wrapper selfMargin">
          <h3>Need More Help With Problem Play?</h3>
          <p>We consult with various organizations - such as the National Council for Problem Gambling(NCPG) and the National Center for Responsible Gaming - that cover a wide array of consumer protection issues,including problem play, to discuss our policies and way in which they can remain aligned with the ever evolving needs of our consumer.</p>
          <p>If you have additional concerns about managing your play on Daily Number or gaming anywhere else - or if you are concerned about a friend or family member - NCPG offers support and counseling referrals that may help you through personal, gaming-related issues.</p>
          <p>The NCPG operates a 24-hour confidential toll-free helpline that you can reach by phone or text at:
            {' '}<a href={`tel:${process.env.REACT_APP_ADDITIONAL_INFO_TEL}`}>{process.env.REACT_APP_ADDITIONAL_INFO_TEL}</a>
            {' '}or chat at:{' '}
            <a href={`${process.env.REACT_APP_ADDITIONAL_INFO_CHAT}`} 
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.RESPONSIBLE_GAMING, {
                action: 'Click Here',
              });
            }}
            target="_blank">Click Here</a>
          </p>
        </div>
      </div>
    </>
  );
};
