import React, { useEffect, useReducer } from 'react';
import { ProgressBar } from 'Components/ProgressBar';
import { Link } from 'react-router-dom';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Input } from 'Components/Form-Elements/Input';
import { chekUserExists } from 'Actions/user-exists-action';
import { validateDOB } from 'Utils/validations';
import { Button } from 'Components/Form-Elements/Button';
import { DatePicker } from 'Components/DatePicker';
import { setInSessionStorage } from 'Utils/storage-utilities';
import { initialState, reducer } from './reducer';

export const ConsentScreen = ({ history }) => {
  const [reducerState, dispatch] = useReducer(reducer, initialState());
  const {
    err,
    fnErr,
    lnErr,
    usrErr,
    error,
    dobError,
    email,
    firstName,
    lastName,
    userName,
    dob,
    disableNext,
    loginStrategy,
  } = reducerState;

  const disableButton = () => {
    return ![fnErr, lnErr, usrErr, error].every(Boolean);
  };

  useEffect(() => {
    dispatch({ type: 'setdisableNext', payload: disableButton() });
  }, [fnErr, lnErr, usrErr, error]);

  const handleDateChange = dob => {
    dispatch({ type: 'setDob', payload: dob });
    dispatch({ type: 'setdobError', payload: validateDOB(dob) });
    dispatch({ type: 'setError', payload: !validateDOB(dob) });
  };

  const storeUserData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
      MESSAGE_CONSTANTS.SIGNUP_STRATEGY,
    );
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SIGNUP_STRATEGY,
      MESSAGE_CONSTANTS.SIGNUP_STRATEGY,
    );
    const data = {
      email,
      firstName,
      lastName,
      username: userName,
      birthDate: dob,
      signupStrategy: MESSAGE_CONSTANTS.SIGNUP_STRATEGY,
    };
    storeUserData();
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.SOCIAL_DATA,
      data,
    );
    history.push('/location');
  };

  const isUsernameUnique = value => {
    chekUserExists(`username=${value}`)
      .then(response => {
        if (response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          dispatch({ type: 'setErr', payload: '' });
          dispatch({ type: 'setusrErr', payload: true });
        }
      })
      .catch(({ response }) => {
        if (
          response.data.status ===
          APP_CONSTANTS.HTTP_STATUS_CODES.ALREADY_EXISTS
        ) {
          dispatch({
            type: 'setErr',
            payload: MESSAGE_CONSTANTS.ERROR_MSG.USERNAME_EXISTS,
          });
          dispatch({ type: 'setusrErr', payload: false });
        } else if (
          response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
        ) {
          dispatch({
            type: 'setErr',
            payload: MESSAGE_CONSTANTS.ERROR_MSG.USERNAME_EXISTS,
          });
          dispatch({ type: 'setusrErr', payload: false });
        }
      });
  };

  const validateUserExists = username => {
    isUsernameUnique(username);
  };

  return (
    <>
      <div className="logo" />
      <div className="signup heightWrapper">
        <div className="d-flex header">
          <ProgressBar percentage="20" />
          <div className="d-flex topBar">
            <Link to="/" className="arrowBack" />
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>

        <div className="wrapper">
          <div className="info">
            <div className="iconBig lockIcon" />
            <div className="title">
              <strong>{MESSAGE_CONSTANTS.CONSENT}</strong>
            </div>
            <div className="description">
              {MESSAGE_CONSTANTS.CONSENT_MSG_1}
              {loginStrategy === MESSAGE_CONSTANTS.GMAIL
                ? MESSAGE_CONSTANTS.GOOGLE
                : MESSAGE_CONSTANTS.FACEBOOK}
              {MESSAGE_CONSTANTS.CONSENT_MSG_2}
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <Input
                  inputType="text"
                  className="form-control"
                  id="email"
                  value={email}
                  disabled
                  textLabel="Email"
                />
              </div>
              <div className="form-group">
                <Input
                  inputType="text"
                  className="form-control"
                  id="firstName"
                  value={firstName}
                  handleChange={firstName => {
                    dispatch({
                      type: 'setFirstName',
                      payload: firstName,
                    });
                  }}
                  textLabel="First name"
                  validationRules={['validateName']}
                  getValidity={fnErr => {
                    dispatch({
                      type: 'setfnErr',
                      payload: fnErr,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <Input
                  inputType="text"
                  className="form-control"
                  id="lastName"
                  value={lastName}
                  handleChange={lastName => {
                    dispatch({
                      type: 'setLastName',
                      payload: lastName,
                    });
                  }}
                  textLabel="Last name"
                  validationRules={['validateName']}
                  getValidity={lnErr => {
                    dispatch({
                      type: 'setlnErr',
                      payload: lnErr,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <Input
                  inputType="text"
                  textLabel="Username"
                  className="form-control"
                  id="verfiy-username"
                  value={userName}
                  externalError={err}
                  handleChange={userName => {
                    dispatch({
                      type: 'setUserName',
                      payload: userName,
                    });
                    dispatch({
                      type: 'setErr',
                      payload: '',
                    });
                  }}
                  onBlur={validateUserExists}
                  validationRules={['validateUsername']}
                  getValidity={userErr => {
                    dispatch({
                      type: 'setusrErr',
                      payload: userErr,
                    });
                  }}
                />
              </div>
              <div className="form-group consentDOB birthdatePage">
                <span>Date of Birth</span>
                <DatePicker handleDateChange={handleDateChange} />
              </div>
              <Button
                className="btn btn-primary"
                type="submit"
                onClick={handleSubmit}
                disabled={disableNext}
                label={BUTTON_LABELS.COMMON.NEXT}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
