import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const getResposiblePlayLimits = async (data = {}) => {
  const url = API.GET_RESPONSIBLE_PLAY_LIMITS;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const submitResposiblePlayLimits = async (data = {}) => {
  const url = API.SUBMIT_RESPOSIBLE_PLAY_LIMITS;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const submitSelfExclusion = async (data = {}) => {
  const url = API.SUBMIT_SELF_EXCLUSION;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};
