import React, { useState, useEffect, useRef } from 'react';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { getTime } from 'Utils/date-utils';
import { PlayerDetails } from 'Components/LineUpScreens/PlayerDetails';
import { StarRating } from 'Components/StarRating';
import { Button } from 'Components/Form-Elements/Button';
import { ModalBox } from 'Components/ModalBox';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { TimerComponent } from 'Components/LineUpScreens/Lineup/TimerComponent';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { injuryTypes } from 'Components/LineUpScreens/SportData/sportsObject';
import icon from '../../../assets/icons/icons.svg';

function StarRatingFiler({ rating, onSelect, selectedRating }) {
  return (
    <li onClick={() => onSelect(rating)}>
      <div className="rating">
        <div className="starWrapper d-flex">
          {selectedRating === rating && <span className="tick">&#10003;</span>}
          <StarRating
            rating={rating}
            starRatedColor="rgb(251, 214, 3)"
            starEmptyColor="rgba(0, 0, 0, 0)"
            starDimension="14px"
            starSpacing="2px"
          />
        </div>
      </div>
    </li>
  );
}
export const PlayerListing = ({
  removePlayer,
  alreadySelectedPlayer,
  alreadySelectedPlayerGameData,
  selectedPlayers,
  contestData,
  positionData,
  selectedEntryFee,
  hidePlayersDesign,
  addPlayer,
  dataPlayers,
  removeSelectGaurdClass,
  setSwipStatus,
}) => {
  const [modalBoxName, setModalBoxName] = useState('');
  const [allPlayers, setAllPlayers] = useState([]);
  const [aftersearch, setNewPlayers] = useState([]);
  const [toggleDesign, setToggleDesign] = useState(false);
  const [query, setQuery] = useState('');
  const [showPlayerDetails, setshowPPlayerDetails] = useState();
  const [ratingFilterOpen, setRatingFilterOpen] = useState(false);
  const [ratingFilter, setRatingFilter] = useState(0);
  const [positionFilter, setPositionFilter] = useState([]);
  const [ratings, setRating] = useState([]);
  const [playerData, setPlayerData] = useState('');

  const getAvailableRatings = players => {
    return players
      .map(player => player.strength)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => {
        return b - a;
      });
  };

  useEffect(() => {
    let temp = allPlayers;
    if (query && query.length > 0) {
      temp = allPlayers.filter(player => {
        return player && player.name.match(new RegExp(query, 'i'));
      });
    }
    setNewPlayers(temp);
  }, [query]);

  useEffect(() => {
    if (!positionData.filters || !positionData.filters.length) {
      setPositionFilter([]);
    }
  }, [positionData.filters]);

  useEffect(() => {
    let alreadySelected = {};
    const players = [...dataPlayers];

    let temp =
      players &&
      players.filter(player => {
        if (player.apiId === alreadySelectedPlayer) alreadySelected = player;
        return (
          !selectedPlayers.includes(player.apiId) &&
          player.playerGame &&
          positionData.values.includes(player.playerGame.position)
        );
      });
    temp.sort((a, b) => {
      return b.strength - a.strength;
    });
    temp = temp || [];
    if (Object.keys(alreadySelected).length) {
      temp.unshift(alreadySelected);
    }
    setAllPlayers(temp);
    setNewPlayers(temp);
    setRating(getAvailableRatings(temp));
    setRatingFilter(0);
    setRatingFilterOpen(false);
  }, [positionData, selectedPlayers]);

  const getToWinForStrength = strength => {
    if (!selectedEntryFee.winAmounts) {
      return { amount: 0, isFree: false };
    }

    const selectedFee = selectedEntryFee.winAmounts.find(fee => {
      return fee.lineupStrength === strength;
    });

    return {
      amount: selectedFee ? selectedFee.amount : 0,
      isFree: selectedEntryFee.amount === 0,
    };
  };

  const toggleHeaderDesign = () => {
    const touchDiv = document.getElementById('touchDiv');
    if (touchDiv.classList.contains('swipUp')) {
      setSwipStatus(false);
      if (toggleDesign) {
        setToggleDesign(!toggleDesign);
      }
    } else {
      setSwipStatus(true);
    }
    setQuery('');
  };

  const toggleHeaderSearch = op => {
    const touchDiv = document.getElementById('touchDiv');
    if (!touchDiv.classList.contains('swipUp') && op === 'search') {
      setSwipStatus(true);
    }
    if (touchDiv.classList.contains('swipUp') && op === 'cancel') {
      setSwipStatus(false);
    }
    setToggleDesign(!toggleDesign);
    setQuery('');
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    const key = Object.keys(APP_CONSTANTS.MODALBOX).find(
      key => APP_CONSTANTS.MODALBOX[key] === modalBoxName,
    );
    if (key) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX[key];
    }
    return modalbox;
  };

  const showAlertPlayer = (player, type) => {
    setPlayerData(player);
    if (type === 'remove') {
      setModalBoxName(APP_CONSTANTS.MODALBOX.REMOVE_PLAYER);
    }
  };

  const processHandler = () => {
    if (modalBoxName === APP_CONSTANTS.MODALBOX.REMOVE_PLAYER && playerData) {
      setModalBoxName('');
      removePlayer(playerData);
    }
    setPlayerData('');
  };

  const handleAdd = item => {
    const touchDiv = document.getElementById('touchDiv');
    if (touchDiv.classList.contains('swipUp')) {
      setTimeout(() => {
        toggleHeaderDesign();
      }, 900);
    }
    addPlayer(item);
    const element = document.getElementById('listScrollToTop');
    if (element) {
      element.scrollTo(0, 0);
    }
  };

  const renderPlayerItem = () => {
    let afterFilter = [...aftersearch];
    if (ratingFilter) {
      afterFilter = afterFilter.filter(
        ({ strength }) => strength === ratingFilter,
      );
    }
    if (positionFilter.length) {
      afterFilter = afterFilter.filter(({ playerGame: { position } }) =>
        positionFilter.includes(position),
      );
    }

    if (!afterFilter.length || !aftersearch.length) {
      return (
        <span className="noPlayerLine">{`No players match${
          ratingFilter ? ` rating "${ratingFilter}"` : ''
        }${query ? ` “${query}”` : ''}`}</span>
      );
    }
    function renderContent(item) {
      if (
        alreadySelectedPlayerGameData &&
        !alreadySelectedPlayerGameData.lateScratchEnabled &&
        alreadySelectedPlayerGameData.activeTab
      ) {
        return (
          <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
              <use xlinkHref={`${icon}#i-lock`} />
            </svg>
          </>
        );
      }

      return (
        <>
          {alreadySelectedPlayer !== item.apiId ? (
            <Button
              className={alreadySelectedPlayer ? 'swapIcon' : 'plusIcon'}
              onClick={() => handleAdd(item)}
            />
          ) : (
            <Button
              className="removeIcon"
              onClick={() => showAlertPlayer(item, 'remove')}
            />
          )}
        </>
      );
    }
    return afterFilter.map((item, index) => {
      const { amount, isFree } = getToWinForStrength(item.strength);
      const dollarSign = isFree ? '' : '$';
      const exp = isFree ? ` EXP` : '';
      const winnings = isFree ? amount : amount && amount.toFixed(2);

      const stats = item.seasonStats;
      const ffpg = (stats && stats.averageFP) || '-';
      const {
        awayTeamShortName,
        gameStartDate,
        homeTeamShortName,
        teamShortName,
        position,
      } = item.playerGame;
      const isHome = homeTeamShortName === teamShortName;
      const vs = isHome ? 'VS' : '@';
      const opp = isHome ? awayTeamShortName : homeTeamShortName;
      const time = getTime(gameStartDate);
      const injuryStatus =
        (item.injury && item.injury.status && item.injury.status.charAt(0)) ||
        (item.statusInShort &&
          (injuryTypes.mlb.includes(item.statusInShort) ||
            injuryTypes.nfl.includes(item.statusInShort)) &&
          item.statusInShort);

      return (
        <>
          {/* eslint no-underscore-dangle: 0 */}
          <div
            className={`guardListing d-flex hello1 ${
              alreadySelectedPlayerGameData &&
              !alreadySelectedPlayerGameData.lateScratchEnabled &&
              alreadySelectedPlayerGameData.activeTab
                ? 'lockMuted'
                : ''
            }`}
            key={item._id}>
            <button
              type="button"
              onClick={() => setshowPPlayerDetails(item)}
              className="flex-grow-1 d-flex listClick">
              <div className="guardAvatar">
                <div className="playerAvatar">
                  <span
                    className="playerAvatar__image"
                    style={{
                      backgroundImage: `url(${item.picture})`,
                    }}
                  />
                  {injuryStatus && (
                    <span className="playerAvatar__statusBottomLeft">
                      {injuryStatus}
                    </span>
                  )}
                </div>
              </div>
              <div className="guardProfile flex-grow-1">
                <div className="guardName">
                  {item.name}
                  <span className="spanNames">
                    {`${position}  - ${teamShortName}`}
                  </span>
                </div>
                <div className="guardInfo">
                  <ul>
                    <li>
                      <div className="spanNames">
                        <div className="dateVs">{`${vs} ${opp} - ${time}`}</div>
                        <div className="stars">
                          <StarRating
                            rating={item.strength}
                            starRatedColor="rgb(251, 214, 3)"
                            starEmptyColor="rgba(0, 0, 0, 0)"
                            starDimension="11px"
                            starSpacing="0.5px"
                          />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="spanNames">
                        FFPG
                        <div className="ffpg">{ffpg}</div>
                      </div>
                    </li>
                    <li>
                      <div className="spanNames">
                        TO WIN
                        <div className="toWin">
                          {`${dollarSign}${winnings}${exp}`}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </button>
            <div className="plus align-self-center">{renderContent(item)}</div>
          </div>
        </>
      );
    });
  };
  const ratingFilters = ratings.map(ele => {
    return (
      <StarRatingFiler
        onSelect={rating => {
          setRatingFilterOpen(false);
          setRatingFilter(rating);
        }}
        key={ele}
        rating={ele}
        selectedRating={ratingFilter}
      />
    );
  });
  ratingFilters.unshift(
    <li
      key={0}
      onClick={() => {
        setRatingFilterOpen(false);
        setRatingFilter(0);
      }}>
      <div className="rating">
        <div className="starWrapper d-flex">
          {ratingFilter === 0 && <span className="tick">&#10003;</span>}
          Any Rating
        </div>
      </div>
    </li>,
  );
  const [showBorderBottom, setShowBorderBottom] = useState(false);
  const handleScroll = e => {
    const element = e.target;
    if (element.scrollTop > 10) {
      setShowBorderBottom(true);
    } else {
      setShowBorderBottom(false);
    }
  };

  if (showPlayerDetails) {
    return (
      <>
        {modalBoxName && (
          <ModalBox
            modalBoxDetails={modalBoxDetail()}
            cancelButtonHandler={() => setModalBoxName('')}
            cancelButtonText={BUTTON_LABELS.COMMON.CANCEL}
            okButtonHandler={processHandler}
            okButtonText="Remove Player"
            showBothButtons
          />
        )}
        <PlayerDetails
          alreadySelectedPlayer={alreadySelectedPlayer}
          alreadySelectedPlayerGameData={alreadySelectedPlayerGameData}
          removePlayer={removePlayer}
          addPlayer={handleAdd}
          player={showPlayerDetails}
          sportSlug={contestData.sportSlug}
          getToList={setshowPPlayerDetails}
          showAlertPlayer={showAlertPlayer}
          removeSelectGaurdClass
        />
      </>
    );
  }

  return (
    <>
      {modalBoxName && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          cancelButtonHandler={() => setModalBoxName('')}
          cancelButtonText={BUTTON_LABELS.COMMON.CANCEL}
          okButtonHandler={processHandler}
          okButtonText="Remove Player"
          showBothButtons
        />
      )}
      <div className={removeSelectGaurdClass ? '' : 'selectGuard'}>
        <div className="drawerClose">
          <span />
        </div>
        <div className="listOuter ">
          <div
            id="guardDiv"
            className={`guardTitle ${toggleDesign ? 'isHide' : ''} ${
              showBorderBottom ? 'u-borderBottom' : ''
            }`}>
            <Button className="closeGuard" onClick={hidePlayersDesign} />
            <h2>
              {(alreadySelectedPlayer &&
                MESSAGE_CONSTANTS.PLAYER_SELECTION.REPLACE) ||
                MESSAGE_CONSTANTS.PLAYER_SELECTION.SELECT_A}
              {positionData && positionData.name}
            </h2>
            <TimerComponent className="rightSide" />
          </div>
          {!toggleDesign ? (
            <div className="searchTag">
              <div className="d-flex">
                <ul>
                  <li className="search">
                    {/* {eslint-disable-next-line max-len} */}
                    <Button
                      className="searchIcon"
                      onClick={() => {
                        toggleHeaderSearch('search');
                      }}
                    />
                  </li>
                  <li
                    onClick={() => setRatingFilterOpen(!ratingFilterOpen)}
                    className={`ratingSelection ${
                      ratingFilter ? 'active' : ''
                    } ${ratingFilterOpen ? 'filterOpen' : ''}`}>
                    {ratingFilter ? (
                      <StarRating rating={ratingFilter} />
                    ) : (
                      <span>
                        {MESSAGE_CONSTANTS.PLAYER_SELECTION.ANY_RATING}
                      </span>
                    )}
                  </li>
                  <li>
                    {/* modification needed for action */}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24">
                        <use xlinkHref={`${icon}#i-love`} />
                      </svg>
                    </span>
                  </li>
                </ul>
                {console.log(positionData)}
                {positionData.filters &&
                  positionData.filters.length &&
                  positionData.filters.map(position => {
                    return (
                      <div
                        key={position}
                        onClick={() => {
                          const index = positionFilter.indexOf(position);
                          const currentFilter = [];
                          if (index === -1) {
                            if (position === 'G') {
                              currentFilter.push('PG');
                              currentFilter.push('SG');
                              currentFilter.push('G');
                            } else if (position === 'F') {
                              currentFilter.push('SF');
                              currentFilter.push('PF');
                              currentFilter.push('F');
                            } else {
                              currentFilter.push(position);
                            }
                          } else {
                            currentFilter.splice(index, 1);
                          }
                          setPositionFilter(currentFilter);
                        }}
                        className={`playerPosition sel d-flex ${
                          positionFilter.includes(position)
                            ? ' selectedPosition'
                            : ''
                        }`}>
                        {position}
                      </div>
                    );
                  })}
              </div>
              {ratingFilterOpen && (
                <div className="ratingListWrapper">
                  <ul className="ratingList">{ratingFilters}</ul>
                </div>
              )}
            </div>
          ) : (
            <div
              className={`searchActive d-flex ${
                showBorderBottom ? 'u-borderBottom' : ''
              }`}>
              <div className="flex-grow-1">
                <div className="searchWrapper">
                  <input
                    type="text"
                    name="search"
                    className="inputSearchBox"
                    placeholder="Find your favorite player"
                    onChange={event => setQuery(event.target.value)}
                  />
                  <div className="searchIcon" />
                </div>
              </div>
              <div className="align-self-center">
                <Button
                  className="link"
                  label="Cancel"
                  onClick={() => {
                    toggleHeaderSearch('cancel');
                  }}
                />
              </div>
            </div>
          )}
          {alreadySelectedPlayerGameData &&
            !alreadySelectedPlayerGameData.lateScratchEnabled &&
            alreadySelectedPlayerGameData.activeTab && (
              <div className="alertContain">
                <div className="alert redMuted">
                  <p className="alert__copy">
                    This slot is locked from editing because the player’s game
                    has begun.
                  </p>
                </div>
              </div>
            )}
          <div
            className="gaurdListWrap"
            id="listScrollToTop"
            onScroll={handleScroll}>
            {renderPlayerItem()}
          </div>
        </div>
      </div>
    </>
  );
};
