import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { Input } from 'Components/Form-Elements/Input';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { getPasswordStrength } from 'Utils/validations';
import { Button } from 'Components/Form-Elements/Button';
import { changePassword } from 'Actions/change-password-action';
import { ModalBox } from 'Components/ModalBox';
import { mixpanelTrackPageView, mixPanelTrackEvent } from 'Utils/mix-panel';
import {
  getCookie,
  setCookie,
  setInSessionStorage,
} from 'Utils/storage-utilities';
import { checkIfUserExists } from 'Actions/login-action';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ChangePassword = ({ history, onBack }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [passwordStrength, setStrength] = useState({});
  const [passwordMatch, setMatch] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [modalBoxName, setModalBoxName] = useState('');
  const [toggleModalBox, setToggleModalBox] = useState(false);

  const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  useEffect(() => {
    mixpanelTrackPageView('Change Password Screen');
  }, []);

  useEffect(() => {
    if (
      password.length &&
      passwordStrength.strength !== MESSAGE_CONSTANTS.SET_PASSWORD.WEAK
    ) {
      setMatch(confirmPassword === password);
      setDisableButton(!(confirmPassword === password && oldPassword));
    }
  }, [confirmPassword, password, oldPassword]);

  const handlePasswordChange = value => {
    setPassword(value);
    const strength = getPasswordStrength(value);
    setStrength({
      strength,
      colorCode: APP_CONSTANTS.PASSWORD_STRENGTH[strength.toUpperCase()],
    });
  };

  const changeUserPassword = () => {
    if (oldPassword === password) {
      setToggleModalBox(true);
      setModalBoxName(APP_CONSTANTS.MODALBOX.SAME_AS_OLD_PASSWORD);
    } else if (
      userdata.type !== APP_CONSTANTS.STORAGE_KEY_REGISTRATION.TYPE_USERNAME
    ) {
      setToggleModalBox(true);
      setModalBoxName(APP_CONSTANTS.MODALBOX.CHECK_LOGIN_TYPE);
    } else {
      changePassword(oldPassword, password)
        .then(({ data }) => {
          const loginData = {
            username: userdata.emailAddress,
            password,
            loginstrategy: userdata.type,
          };
          checkIfUserExists(loginData)
            .then(res => {
              const { data, status } = res.data;
              if (status === APP_CONSTANTS.HTTP_STATUS_CODES.NO_CONTENT) {
                setCookie(APP_CONSTANTS.USER_DATA_KEY.JWT, data.token);
                setCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA, data);
              }
            })
            .catch(error => {
              const { code } = error.response.data;
              setInSessionStorage('screen', 'error');
              setInSessionStorage('message', code);
              history.push('/message');
            });
          if (typeof onBack === 'function') {
            onBack();
          } else {
            history.push('/settings/account');
          }
        })
        .catch(({ response }) => {
          if (
            response.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.BAD_REQUEST
          ) {
            setToggleModalBox(true);
            setModalBoxName(APP_CONSTANTS.MODALBOX.BAD_REQUEST);
          } else if (
            response.data.status ===
            APP_CONSTANTS.HTTP_STATUS_CODES.PRECONDITION_FAILED
          ) {
            setToggleModalBox(true);
            setModalBoxName(APP_CONSTANTS.MODALBOX.OLD_PASSWORD_ERR);
          }
        });
    }
  };

  const getColorCode = () => {
    return passwordMatch
      ? APP_CONSTANTS.PASSWORD_MATCH.MATCH
      : APP_CONSTANTS.PASSWORD_MATCH.NOT_MATCH;
  };
  const processHandler = () => {
    setToggleModalBox(false);
    setModalBoxName('');
  };

  const modalBoxDetail = () => {
    let modalbox = {};
    if (modalBoxName === APP_CONSTANTS.MODALBOX.BAD_REQUEST) {
      modalbox = MESSAGE_CONSTANTS.KYC.BAD_REQUEST;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.OLD_PASSWORD_ERR) {
      modalbox = MESSAGE_CONSTANTS.KYC.OLD_PASSWORD_ERR;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.SAME_AS_OLD_PASSWORD) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.SAME_AS_OLD_PASSWORD;
    } else if (modalBoxName === APP_CONSTANTS.MODALBOX.CHECK_LOGIN_TYPE) {
      modalbox = MESSAGE_CONSTANTS.MODALBOX.CHECK_LOGIN_TYPE;
    }
    return modalbox;
  };

  return (
    <>
      {toggleModalBox && (
        <ModalBox
          modalBoxDetails={modalBoxDetail()}
          okButtonHandler={processHandler}
          okButtonText="Ok"
          showBothButtons={false}
        />
      )}
      <div className="changePassWrapper">
        <div className="d-flex topBar topHeaderBar resetTopBar">
          <Link
            to="#"
            className="link"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES, {
                action: 'Cancel Change Password',
                formData: {
                  userMail: userdata.emailAddress,
                },
              });
              if (typeof onBack === 'function') {
                onBack();
              } else {
                history.push('/settings/account');
              }
            }}>
            Cancel
          </Link>
          <div className="title">Change Password</div>
          <Button
            className="link linkBtn"
            disabled={disableButton}
            label="Save"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES, {
                action: 'Save Change Password',
                formData: {
                  userMail: userdata.emailAddress,
                },
              });
              changeUserPassword();
            }}

            // onClick={changeUserPassword}
          />
        </div>
        <div className="wrapper">
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.OLD_PASSWORD}
              className="form-control"
              id="set-old-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.OLD_PASSWORD}
              value={oldPassword}
              handleChange={setOldPassword}
            />
          </div>
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.NEW_PASSWORD}
              className="form-control"
              id="set-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.NEW_PASSWORD}
              value={password}
              handleChange={handlePasswordChange}
            />
            <span className={passwordStrength.colorCode}>
              {passwordStrength.strength}
            </span>
          </div>
          <div className="form-group">
            <Input
              inputType="password"
              textLabel={MESSAGE_CONSTANTS.SET_PASSWORD.REPEAT_NEW_PASSWORD}
              className="form-control"
              id="confirm-password"
              placeholder={MESSAGE_CONSTANTS.SET_PASSWORD.REPEAT_NEW_PASSWORD}
              value={confirmPassword}
              handleChange={setConfirmPassword}
            />
            <span
              className={`${
                password.length && confirmPassword.length
                  ? getColorCode()
                  : 'hide'
              }`}>
              {passwordMatch
                ? MESSAGE_CONSTANTS.SET_PASSWORD.MATCH
                : MESSAGE_CONSTANTS.SET_PASSWORD.DOESNT_MATCH}
            </span>
          </div>
          <div className="leftText">
            {MESSAGE_CONSTANTS.SET_PASSWORD.DESCRIPTION}
          </div>
        </div>
      </div>
    </>
  );
};
