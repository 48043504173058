import { API } from 'Constants/api-constants';
import { NetworkManager } from 'Network/network-manager';

export const getUserBalance = async data => {
  const url = API.GET_WALLET_BALANCE;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const getTrasaction = async data => {
  const url = API.GET_WALLET_BALANCE_ITEMS;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const getResposiblePlayLimits = async (data = {}) => {
  const url = API.GET_RESPONSIBLE_PLAY_LIMITS;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const getWithdrawals = async (data = {}) => {
  const url = API.GET_WITHDRAWAL;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const createWithdrawal = async (data = []) => {
  const url = API.CREATE_WITHDRAWAL;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const cancelWithdrawal = async (data = []) => {
  const url = API.CANCEL_WITHDRAWAL;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const verifyUserIdentity = async (data = []) => {
  const url = API.VERIFY_USER_IDENTITY;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};

export const createStartWithdrawal = async (data = []) => {
  const url = API.CREATE_START_WITHDRAWAL;
  const response = await NetworkManager.requestAuthPOST(url, data);
  return response;
};
