import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';
import { BUTTON_LABELS } from 'Constants/button-labels';
import './ModalBox.css';

export const ModalBox = ({
  modalBoxDetails,
  cancelButtonHandler,
  okButtonHandler,
  cancelButtonText,
  okButtonText,
  showFooterButtons,
  showBothButtons,
}) => {
  return (
    <div className="backdrop">
      {/*  lable to show type of modal box as warning or confirmation
      and an cancel icon  */}
      <div id="popModal" className="card-body modalPopupWrap">
        <div className="title">{modalBoxDetails.title}</div>
        <div className="popupMessageWrap">
          {/* message to show */}
          {/* single button present at bottom middle
        and two buttons condition wise */}
          <div className="popupMessage">{modalBoxDetails.message}</div>
        </div>
        {showFooterButtons &&
          (showBothButtons ? (
            <>
              <div className="cancelBtnWrap cancelBtnBorder">
                <Button
                  className="btn-outline-primary txtCancel"
                  label={cancelButtonText}
                  onClick={cancelButtonHandler}
                />
              </div>
              <div className="cancelBtnWrap">
                <Button
                  className="btn-link d-block"
                  label={okButtonText}
                  onClick={okButtonHandler}
                />
              </div>
            </>
          ) : (
            <div className="cancelBtnWrap">
              <Button
                className="btn btn-link d-block"
                label={okButtonText}
                onClick={okButtonHandler}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

ModalBox.defaultProps = {
  modalBoxDetails: {},
  cancelButtonHandler: () => {},
  okButtonHandler: () => {},
  cancelButtonText: BUTTON_LABELS.COMMON.CANCEL,
  okButtonText: BUTTON_LABELS.COMMON.OK,
  showFooterButtons: true,
  showBothButtons: true,
};

ModalBox.propTypes = {
  modalBoxDetails: PropTypes.objectOf({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  cancelButtonHandler: PropTypes.func,
  okButtonHandler: PropTypes.func,
  cancelButtonText: PropTypes.string,
  okButtonText: PropTypes.string,
  showFooterButtons: PropTypes.bool,
  showBothButtons: PropTypes.bool,
};
