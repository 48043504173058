import React, { useState } from 'react';
import { VideoTutorial } from 'Components/VideoTutorial';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { Account } from './Account';
import { ChangeEmail } from '../ChangeEmail';
import { ChangePassword } from '../ChangePassword';
import { ResponsibleGaming } from '../ResponsibleGaming/Gaming';
import { SelfExclusion } from '../ResponsibleGaming/SelfExclusion';
import { GameRules } from '../GameRules';
import { GameScoringRules } from '../GameRules/GameScoringRules';
import { AdditionalInfo } from '../ResponsibleGaming/AdditionalInfo';
import { PrivacyStatement } from '../PrivacyStatement';
import { TermsOfService } from '../TermsOfService';

const SETTING_OPTION = {
  ACCOUNT: 'ACCOUNT',
  ACCOUNT_CHANGE_EMAIL: 'ACCOUNT_CHANGE_EMAIL',
  ACCOUNT_CHANGE_PASS: 'ACCOUNT_CHANGE_PASS',
  RESPONSIBLE_GAMING: 'RESPONSIBLE_GAMING',
  RESPONSIBLE_GAMING_SELF_EXCLUSION: 'RESPONSIBLE_GAMING_SELF_EXCLUSION',
  RESPONSIBLE_GAMING_ADD_INFO: 'RESPONSIBLE_GAMING_ADD_INFO',
  RULES: 'RULES',
  RULES_SCORING: 'RULES_SCORING',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TUTORIAL: 'TUTORIAL',
};
export const SettingsDesktop = ({ history }) => {
  const [selectedSetting, setSelectedSetting] = useState(
    SETTING_OPTION.ACCOUNT,
  );
  const [gameScoreRule, setGameScoreRule] = useState('');

  return (
    <main className="App__main">
      <div className="headSection isSettingPage">
        <div className="headSection__contain">
          <h2 className="headSection__title">Settings</h2>
        </div>
      </div>
      <div className="gridSection">
        <div className="gridSection__fullWidth">
          <div className="settingWrapper settingsWrapper customSettingsWrapper">
            <div className="settingsInner d-flex align-items-stretch">
              <div className="cols1">
                <div className="settingListWrapper">
                  <div className="settingList d-flex">
                    <div
                      onClick={() => {
                        mixPanelTrackEvent(
                          MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                          {
                            action: 'Account',
                          },
                        );
                        setSelectedSetting(SETTING_OPTION.ACCOUNT);
                      }}
                      className={`settingTitle flex-grow-1 ${
                        selectedSetting === SETTING_OPTION.ACCOUNT ||
                        selectedSetting ===
                          SETTING_OPTION.ACCOUNT_CHANGE_EMAIL ||
                        selectedSetting === SETTING_OPTION.ACCOUNT_CHANGE_PASS
                          ? 'active'
                          : ''
                      }`}>
                      Account
                    </div>
                  </div>
                  <div className="settingList d-flex">
                    <div
                      onClick={() => {
                        mixPanelTrackEvent(
                          MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                          {
                            action: 'Responsible Gaming',
                          },
                        );
                        setSelectedSetting(SETTING_OPTION.RESPONSIBLE_GAMING);
                      }}
                      className={`settingTitle flex-grow-1 ${
                        selectedSetting === SETTING_OPTION.RESPONSIBLE_GAMING ||
                        selectedSetting ===
                          SETTING_OPTION.RESPONSIBLE_GAMING_ADD_INFO ||
                        selectedSetting ===
                          SETTING_OPTION.RESPONSIBLE_GAMING_SELF_EXCLUSION
                          ? 'active'
                          : ''
                      }`}>
                      Responsible Gaming
                    </div>
                  </div>
                </div>
                <div className="additionalSettings">
                  <div className="settingList d-flex">
                    <div
                      onClick={() => {
                        mixPanelTrackEvent(
                          MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                          {
                            action: SETTING_OPTION.RULES,
                          },
                        );
                        setSelectedSetting(SETTING_OPTION.RULES);
                      }}
                      className={`settingTitle flex-grow-1 ${
                        selectedSetting === SETTING_OPTION.RULES ||
                        selectedSetting === SETTING_OPTION.RULES_SCORING
                          ? 'active'
                          : ''
                      }`}>
                      Rules
                    </div>
                  </div>
                </div>
                <div className="additionalSettings">
                  <div className="settingList d-flex">
                    <div
                      onClick={() => {
                        mixPanelTrackEvent(
                          MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                          {
                            action: 'Tutorial',
                          },
                        );
                        setSelectedSetting(SETTING_OPTION.TUTORIAL);
                      }}
                      className={`settingTitle flex-grow-1 ${selectedSetting ===
                        SETTING_OPTION.TUTORIAL && 'active'}`}>
                      Tutorials
                    </div>
                  </div>
                </div>
                <div className="otherSettings">
                  <a
                    // eslint-disable-next-line max-len
                    href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_MAILID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={evt => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                        {
                          action: 'Contact Support',
                        },
                      );
                      evt.preventDefault();
                      setSelectedSetting(SETTING_OPTION.TERMS_OF_SERVICE);
                    }}
                    className="flex-grow-1">
                    <div className="settingList d-flex">Contact Support</div>
                  </a>
                  {/* eslint-disable */}
                  <a
                    href="#"
                    onClick={evt => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                        {
                          action: 'Terms of service',
                        },
                      );
                      evt.preventDefault();
                      setSelectedSetting(SETTING_OPTION.TERMS_OF_SERVICE);
                    }}
                    className="flex-grow-1">
                    <div className="settingList d-flex">Terms of service</div>
                  </a>
                  <a
                    href="#"
                    onClick={evt => {
                      mixPanelTrackEvent(
                        MIXPANEL_EVENTS_CONSTANTS.SUBMIT_PREFERENCES,
                        {
                          action: 'Privacy Statement',
                        },
                      );
                      evt.preventDefault();
                      setSelectedSetting(SETTING_OPTION.PRIVACY_POLICY);
                    }}
                    className="flex-grow-1">
                    <div className="settingList d-flex">Privacy Statement</div>
                  </a>
                  {/* eslint-enable */}
                </div>
              </div>
              <div className="cols2 d-flex">
                {selectedSetting === SETTING_OPTION.ACCOUNT && (
                  <Account
                    history={history}
                    showBack={false}
                    onPasswordChange={() =>
                      setSelectedSetting(SETTING_OPTION.ACCOUNT_CHANGE_PASS)
                    }
                    onEmailChange={() =>
                      setSelectedSetting(SETTING_OPTION.ACCOUNT_CHANGE_EMAIL)
                    }
                  />
                )}
                {selectedSetting === SETTING_OPTION.ACCOUNT_CHANGE_EMAIL && (
                  <ChangeEmail
                    history={history}
                    onBack={() => setSelectedSetting(SETTING_OPTION.ACCOUNT)}
                  />
                )}
                {selectedSetting === SETTING_OPTION.ACCOUNT_CHANGE_PASS && (
                  <ChangePassword
                    history={history}
                    onBack={() => setSelectedSetting(SETTING_OPTION.ACCOUNT)}
                  />
                )}
                {selectedSetting === SETTING_OPTION.RESPONSIBLE_GAMING && (
                  <ResponsibleGaming
                    history={history}
                    showBack={false}
                    onSelfExclusion={() =>
                      setSelectedSetting(
                        SETTING_OPTION.RESPONSIBLE_GAMING_SELF_EXCLUSION,
                      )
                    }
                    onAdditionalInfo={() =>
                      setSelectedSetting(
                        SETTING_OPTION.RESPONSIBLE_GAMING_ADD_INFO,
                      )
                    }
                  />
                )}
                {selectedSetting ===
                  SETTING_OPTION.RESPONSIBLE_GAMING_SELF_EXCLUSION && (
                  <SelfExclusion
                    history={history}
                    onBack={() =>
                      setSelectedSetting(SETTING_OPTION.RESPONSIBLE_GAMING)
                    }
                  />
                )}
                {selectedSetting ===
                  SETTING_OPTION.RESPONSIBLE_GAMING_ADD_INFO && (
                  <AdditionalInfo
                    history={history}
                    onBack={() =>
                      setSelectedSetting(SETTING_OPTION.RESPONSIBLE_GAMING)
                    }
                  />
                )}
                {selectedSetting === SETTING_OPTION.RULES && (
                  <GameRules
                    history={history}
                    showBack={false}
                    onGameScoreRule={settingOption => {
                      setSelectedSetting(SETTING_OPTION.RULES_SCORING);
                      setGameScoreRule(settingOption);
                    }}
                  />
                )}
                {selectedSetting === SETTING_OPTION.RULES_SCORING && (
                  <GameScoringRules
                    history={history}
                    match={{ params: { scoringGame: gameScoreRule } }}
                    onBack={() => setSelectedSetting(SETTING_OPTION.RULES)}
                  />
                )}
                {selectedSetting === SETTING_OPTION.PRIVACY_POLICY && (
                  <PrivacyStatement
                    history={history}
                    location={{ search: { isMobile: false } }}
                    onBack={() => setSelectedSetting(SETTING_OPTION.ACCOUNT)}
                    onTermsOfService={() =>
                      setSelectedSetting(SETTING_OPTION.TERMS_OF_SERVICE)
                    }
                  />
                )}
                {selectedSetting === SETTING_OPTION.TERMS_OF_SERVICE && (
                  <TermsOfService
                    history={history}
                    location={{ search: { isMobile: false } }}
                    onBack={() => setSelectedSetting(SETTING_OPTION.ACCOUNT)}
                  />
                )}
                {selectedSetting === SETTING_OPTION.TUTORIAL && (
                  <VideoTutorial showBack={false} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
