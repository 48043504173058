import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { ACTION_TYPE } from 'Constants/action-type';
import { getCookie } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';

const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

const initialState = {
  profileImage: userdata.imageUrl,
  isUserLoggedIn: !!userdata,
  guestUserMail: '',
  activeLineupEdit: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.PROFILE.USER_IMAGE:
      return { ...state, profileImage: action.payload };
    case ACTION_TYPE.PROFILE.SET_USER_LOGGED_IN:
      return { ...state, isUserLoggedIn: action.payload };
    case ACTION_TYPE.PROFILE.SET_USER_MAIL:
      return { ...state, guestUserMail: action.payload.split('=')[1] };
    case ACTION_TYPE.PROFILE.SET_ACTIVE_LINEUP_EDIT:
      return { ...state, activeLineupEdit: action.payload };
    default:
      return state;
  }
};

const ProfileContext = React.createContext(initialState);

function ProfileContextProvider({ children }) {
  const [profileState, profileContextDispatch] = useReducer(
    reducer,
    initialState,
  );
  return (
    <ProfileContext.Provider value={{ profileState, profileContextDispatch }}>
      {children}
    </ProfileContext.Provider>
  );
}

ProfileContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { ProfileContext, ProfileContextProvider };
