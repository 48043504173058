/* eslint-disable max-len */
/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { clearSessionStorage } from 'Utils/storage-utilities';
import { Button } from 'Components/Form-Elements/Button';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';
import { detectDevice } from 'Utils/device-utils';
import { updateDob, getDraftUser } from 'Actions/new-auth-action';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { signUpManual } from 'Actions/sign-up-action';
import NumberFormat from 'react-number-format';
import { Alert } from '../Alert';
import icon from '../../assets/icons/icons.svg';

export const BirthDateEntered = ({ history }) => {
  const [disableNext, setdisableNext] = useState(true);
  const [errorDisabled, setErrorDisabled] = useState(true);
  const [birthDate, setBirthDate] = useState('');
  const [userObj, setUserObj] = useState({});
  const [toggleAlertBox, setToggleAlertBox] = useState(false);
  const [alertBoxMsg, setAlertBoxMsg] = useState('');
  const [alertTemplate, setAlertTemplate] = useState('red');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const { email, userDob, dobErrorMsg, successMsg } = history.location.state
    ? history.location.state
    : '';

  const isMobile = detectDevice();

  const back = () => {
    const path = `/m-signup-phone-verify`;
    history.push(path, { email });
  };
  const getDraftUserData = response => {
    getDraftUser(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK && data) {
          setUserObj(data);
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
      });
  };

  useEffect(() => {
    if (successMsg) {
      setAlertBoxMsg(successMsg);
      setAlertTemplate('green');
      setToggleAlertBox(true);
    }
    if (dobErrorMsg) {
      setAlertBoxMsg(dobErrorMsg);
      setAlertTemplate('red');
      setToggleAlertBox(true);
    }
    if (userDob) {
      const tempDob = userDob.split('-');
      const dobMonth = `0${tempDob[1]}`.slice(-2);
      const dobDay = `0${tempDob[2]}`.slice(-2);
      const dobYear = tempDob[0];
      setMonth(dobMonth);
      setDay(dobDay);
      setYear(dobYear);
    }
  }, []);

  useEffect(() => {
    if (email) {
      const data = {
        email,
      };
      getDraftUserData(data);
    }
  }, []);

  const updateDateOfBirth = response => {
    updateDob(response)
      .then(res => {
        const { code, message, data, status } = res.data;
        if (status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          const path = `/m-signup-locAllow`;
          history.push(path, { email });
        }
      })
      .catch(error => {
        const { status, code } = error.response.data;
      });
  };

  const handleSubmit = async () => {
    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.LOGIN_SCREEN, {
      action: 'Create User',
      formData: {
        dob: birthDate,
        email,
      },
    });
    // evt.preventDefault();
    if (!userObj) {
      return;
    }
    const data = {
      email: userObj.emailAddress,
      username: userObj.emailAddress,
      emailVerified: userObj.emailVerified,
      phoneNo: userObj.phoneNo,
      phoneVerified: userObj.phoneVerified,
      birthdate: birthDate,
      location: null,
    };
    // updateDateOfBirth(data);
    signUpManual(data)
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.CREATED) {
          const path = `/m-signup-locAllow`;
          history.push(path, { email, login: resp.data.loginSession });
        }
      })
      .catch(({ response }) => {
        /* if (isModal) {
          setInSessionStorage('screen', 'error');
          setInSessionStorage('message', response.data.message);
          fetchAndSetModalPage('/message');
          return;
        } */
        if (response) {
          // alert(response.data.message);
          setAlertBoxMsg(response.data.message);
          setAlertTemplate('red');
          setToggleAlertBox(true);
        }

        // history.push('/login');
      });
  };
  const verifyDOB = async () => {
    const dobMonth = document.getElementById('dob_month').value;
    const dobDay = document.getElementById('dob_day').value;
    const dobYear = document.getElementById('dob_year').value;
    if (dobMonth > 12 || dobMonth < 1) {
      setErrorDisabled(false);
    } else if (dobDay > 31 || dobDay < 1) {
      setErrorDisabled(false);
    } else if (dobYear.length !== 4 || dobYear > 2004) {
      setErrorDisabled(false);
    } else {
      /* const bdate = new Date(dobYear, dobMonth, dobDay);
      const isoDate = bdate.toISOString(); */
      // setBirthDate(isoDate);
      setErrorDisabled(true);
      handleSubmit();
    }
  };

  function buildDob() {
    const dobMonth = document.getElementById('dob_month').value;
    const dobDay = document.getElementById('dob_day').value;
    const dobYear = document.getElementById('dob_year').value;
    // const bdate = new Date(dobYear, dobMonth - 1, dobDay);
    const bdate = `${dobYear}-${dobMonth}-${dobDay}`;
    /* const isoDate = bdate.toISOString();
    console.log(bdate); */
    setBirthDate(bdate);
  }

  useEffect(() => {
    clearSessionStorage();
  }, []);

  function autoTab(field1, len, field2) {
    if (document.getElementById(field1).value.length === len) {
      document.getElementById(field2).focus();
    }
  }

  function reverseAutoTab(field1, len, field2) {
    if (document.getElementById(field1).value.length === len) {
      document.getElementById(field2).focus();
    }
  }
  function closeAlert() {
    setToggleAlertBox(false);
  }

  return (
    <>
      <div className="ffScreen">
        <div className="ffScreen__header">
          <div className="headSection-new">
            <div className="headSection-new__contain">
              <h2 className="headSection-new__title">
                <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24">
                  <use xlinkHref={`${icon}#i-logo`} />
                </svg>
              </h2>
              <div className="headSection-new__leftAct">
                <button
                  className="button button-back"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.ARROW_BACK, {
                      action: 'arrow back',
                    });
                    back();
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24">
                    <use xlinkHref={`${icon}#i-back`} />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="alertContain">
          {toggleAlertBox && (
            <Alert
              alertBoxDetails={alertBoxMsg}
              template={alertTemplate}
              closeButtonHandler={closeAlert}
            />
          )}
        </div>
        <div className="ffScreen__body justify-start">
          <h1 className="title">Enter your Date of Birth</h1>
          <p className="copy">
            This helps us make sure you’re eligible to play
          </p>
          <div className="inputAreaWrapper">
            <div className="dobInputArea">
              {/* <input
                className="input month"
                id="dob_month"
                type="number"
                placeholder="MM"
                min="0"
                max="12"
                onChange={event => {
                  buildDob();
                }}
                maxLength="2"
              /> */}
              <NumberFormat
                className="input month"
                id="dob_month"
                format="##"
                mask=""
                placeholder="MM"
                value={month}
                autoComplete="off"
                autoFocus
                onValueChange={values => {
                  if (values.value.length === 2) {
                    setMonth(values.value);
                    buildDob();
                    autoTab('dob_month', 2, 'dob_day');
                  } else {
                    setMonth(values.value);
                    autoTab('dob_month', 2, 'dob_day');
                  }
                }}
              />
              {/* <input
                className="input day"
                id="dob_day"
                type="number"
                placeholder="DD"
                min="0"
                max="31"
                onChange={event => {
                  buildDob();
                }}
                maxLength="2"
              /> */}
              <NumberFormat
                className="input day"
                id="dob_day"
                format="##"
                mask=""
                placeholder="DD"
                autoComplete="off"
                value={day}
                onValueChange={values => {
                  if (values.value.length === 2) {
                    setDay(values.value);
                    buildDob();
                    autoTab('dob_day', 2, 'dob_year');
                    reverseAutoTab('dob_day', 0, 'dob_month');
                  } else {
                    setDay(values.value);
                    buildDob();
                    autoTab('dob_day', 2, 'dob_year');
                    reverseAutoTab('dob_day', 0, 'dob_month');
                  }
                }}
              />
              {/* <input
                className="input year"
                type="number"
                placeholder="YYYY"
                id="dob_year"
                min="1900"
                max="2004"
                onChange={event => {
                  buildDob();
                }}
                maxLength="4"
              /> */}
              <NumberFormat
                className="input year"
                id="dob_year"
                format="####"
                mask=""
                placeholder="YYYY"
                autoComplete="off"
                value={year}
                onValueChange={values => {
                  if (values.value.length === 2) {
                    setYear(values.value);
                    buildDob();
                    reverseAutoTab('dob_year', 0, 'dob_day');
                  } else {
                    setYear(values.value);
                    buildDob();
                    reverseAutoTab('dob_year', 0, 'dob_day');
                  }
                }}
              />
            </div>
          </div>
          <p className="copy orange">
            {errorDisabled ? '' : 'Not old enough, try again!'}
          </p>
        </div>
        <div className="ffScreen__footer">
          <button
            className="button button-full button-blue"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_DOB, {
                action: 'VerifyDob',
              });
              verifyDOB();
            }}>
            Continue
          </button>
        </div>
      </div>
    </>
  );
};
