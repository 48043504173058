import React, { useState, useEffect } from 'react';
import 'StyleSheets/css/favorite-sport.css';
import { getCondensedSport } from 'Actions/sport-action';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { CheckBox } from 'Components/Form-Elements/Checkbox';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { savePreference } from 'Actions/save-user-preference';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const FavoriteSport = ({ history }) => {
  const [sports, setSports] = useState([]);
  const [disableNext, setdisableNext] = useState(true);

  useEffect(() => {
    getCondensedSport()
      .then(({ data }) => {
        const obj = data.data.map(sport => ({
          ...sport,
          isChecked: false,
        }));
        setSports(obj);
      })
      .catch(() => {
        // API error handling
      });
  }, []);

  const getSportData = () => {
    const data = [];
    sports.forEach(sport => {
      /* eslint no-underscore-dangle: 0 */
      if (sport.isChecked) {
        data.push(sport._id);
      }
    });
    return {
      favouriteSports: data,
    };
  };

  const isAllCheckFalse = () => {
    const allChecked = sports.map(sport => sport.isChecked);
    setdisableNext(!allChecked.includes(true));
  };

  useEffect(() => {
    isAllCheckFalse();
  }, [sports]);

  const handleCheckboxChange = value => {
    setSports(
      sports.map(sport => {
        if (sport.name === value) {
          // eslint-disable-next-line no-param-reassign
          sport.isChecked = !sport.isChecked;
        }
        return sport;
      }),
    );
  };

  const handleFormSubmit = () => {
    savePreference(getSportData())
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          history.push('/select-team');
        }
      })
      .catch(resp => {
        // handle error here.
      });
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="20" />
          <div className="d-flex topBar">
            <Link to="/for-you" className="arrowBack" />
            <h2 className="heading">Sign Up</h2>
            <Link to="/select-team" className="bottomFixed btnTransparent">
              {BUTTON_LABELS.COMMON.SKIP}
            </Link>
          </div>
        </div>
        <div className="wrapper">
          <div className="question info">
            <div className="title">
              {MESSAGE_CONSTANTS.SURVEY.FAVORITE_SPORT}
            </div>
          </div>
          <form>
            <div className="sportListingWrapper">
              {sports.map(({ name, isChecked }) => {
                return (
                  <CheckBox
                    label={name}
                    isSelected={isChecked}
                    onCheckboxChange={handleCheckboxChange}
                    key={name}
                  />
                );
              })}
            </div>
            <Button
              className="btn btn-primary"
              type="submit"
              onClick={handleFormSubmit}
              disabled={disableNext}
              label={BUTTON_LABELS.COMMON.NEXT}
            />
          </form>
        </div>
      </div>
    </>
  );
};
