import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'Components/Form-Elements/Button';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { getCookie } from 'Utils/storage-utilities';
import { Input } from 'Components/Form-Elements/Input';

export const LinkShareModal = ({ close }) => {
  const userData = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);

  const copyText = () => {
    const url = document.getElementById('refer-link');
    url.select();
    document.execCommand('copy');
  };

  const { EMAIL_BODY } = MESSAGE_CONSTANTS;

  return (
    <>
      <div className="backfade">
        <div id="popModal" className="card-body">
          <div className="info">
            <div className="title centerText">
              <strong>
                To Share your referral code, copy and paste the link into your
                favorite social media app
              </strong>
            </div>
          </div>
          <div className="form-group">
            <Input
              inputType="text"
              textLabel=""
              className="form-control referLink"
              id="refer-link"
              value={userData.referralLink}
              readOnly
            />
          </div>
          <div className="title cancelBtnWrap">
            <Button
              type="button"
              label="Copy link"
              className="btn btn-link d-block"
              onClick={copyText}
            />
            <a
              // eslint-disable-next-line max-len
              href={`mailto:?body=${EMAIL_BODY} ${userData.referralLink}`}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link d-block">
              Email
            </a>
            <Button
              className="btn btn-link d-block"
              label="Cancel"
              onClick={close}
            />
          </div>
        </div>
      </div>
    </>
  );
};

LinkShareModal.propTypes = {
  close: PropTypes.func.isRequired,
};
