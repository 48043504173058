export const ARRAY_CONST = {
  GET_PAID: [
    { id: 1, name: 'Longshots', isSelected: false },
    { id: 2, name: '50/50', isSelected: false },
    { id: 3, name: 'Sure things', isSelected: false },
  ],
  PLAY_STYLE: [
    { id: 1, name: 'Free / Play for free', isSelected: false },
    { id: 2, name: '$1-10', isSelected: false },
    { id: 3, name: '$10-25', isSelected: false },
    { id: 4, name: '$25-50', isSelected: false },
    { id: 5, name: '$50-100', isSelected: false },
    { id: 6, name: '$100+', isSelected: false },
  ],
  DEPOSIT_LIMIT: [500, 400, 250, 100],
  ENTRY_LIMIT: ['NO LIMIT', 100, 50, 20, 10],
  ENTRY_ALERT: ['NO ALERT', 100, 50, 20, 10],
  ENTRY_FEE_LIMIT: ['NO LIMIT', 100, 50, 20, 10],
  SELF_EXCLUSION: {
    important_points_initial: [
      'You can suspend your account for your choice of 3,6,9, or 12 months.',
      'Any upcoming entries will be cancelled and refunded.',
    ],
    important_points_default: [
      'You will not be able to log or access your Daily Number account.',
      // eslint-disable-next-line max-len
      'We will suppress the email address associated with your account from receiving additional,personally directed promotional or marketing messages.',
    ],
    term: [
      {
        key: 3,
        value: 'Three Months',
      },
      {
        key: 6,
        value: 'Six Months',
      },
      {
        key: 9,
        value: 'Nine Months',
      },
      {
        key: 12,
        value: 'Twelve Months',
      },
    ],
    errorCode: 'user.disabled.self_excluded',
    errorMsg: 'User Disabled',
    USER_DOES_NOT_EXIST: 'user.does_not.exists',
  },
};
