import React, { useState, useEffect } from 'react';
import { Button } from 'Components/Form-Elements/Button';

export const CheckBox = props => {
  const { label, isSelected, onCheckboxChange } = props;
  const [isChecked, setIsChecked] = useState(props.isChecked);
  useEffect(() => {
    if (props.isChecked !== isChecked) {
      setIsChecked(props.isChecked);
    }
  }, [props.isChecked]);

  return (
    <div className="sportListing d-flex">
      <input type="checkbox" className="sportCheckbox" />
      <Button
        className={`customeCheckbox ${isSelected ? 'checked' : ''}`}
        onClick={() => {
          onCheckboxChange(label);
          setIsChecked(!isChecked);
        }}
      />
      <div className="sportName">{label}</div>
    </div>
  );
};
