import React, { useState, useEffect } from 'react';

export const Image = ({ src, className, alt, defaultImage }) => {
  const [imgSrc, setImgSrc] = useState(src || defaultImage);
  const [errored, setError] = useState(false);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const onError = () => {
    if (!errored) {
      setImgSrc(defaultImage);
      setError(true);
    }
  };

  return (
    <span
      style={{ backgroundImage: `url(${imgSrc})` }}
      onError={onError}
      className={className}
      alt={alt}
    />
  );
};
Image.defaultProps = {
  className: 'form-control',
  src: '',
  alt: '',
  defaultImage: '',
};
