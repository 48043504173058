import React from 'react';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';

export const EntryFee = ({ data }) => {
  return (
    <div className="largeHeading">
      {data.amount === 0 ? (
        MESSAGE_CONSTANTS.LINEUP.FREE
      ) : (
        <>
          <sup>$</sup>
          {data.amount}
        </>
      )}
    </div>
  );
};

EntryFee.defaultProps = {
  data: {},
};
