import React, { useState } from 'react';
import { ProgressBar } from 'Components/ProgressBar';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Link } from 'react-router-dom';
import { minimumAge } from 'Utils/validations';
import { Button } from 'Components/Form-Elements/Button';
import { setInSessionStorage } from 'Utils/storage-utilities';
import { formatDatell, isDate } from 'Utils/date-utils';
import { DatePicker } from 'Components/DatePicker';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const BirthDate = ({ history, isModal, fetchAndSetModalPage }) => {
  const [date, setDate] = useState('');
  const [disableNext, setdisableNext] = useState(true);

  const getAge = date => {
    if (!date) {
      // use case where user removes date completely from text input
      return 0;
    }
    const today = new Date();
    let age = today.getFullYear() - date.getFullYear();
    const m = today.getMonth() - date.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
      age -= 1;
    }
    return age;
  };

  const formateDate = date => {
    if (!isDate(date)) {
      return '';
    }
    let month = date.getMonth() + 1;
    let day = date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  };

  const handleDateChange = date => {
    setDate(date);
    setdisableNext(!minimumAge(getAge(date)));
  };

  const storeData = () => {
    setInSessionStorage(
      APP_CONSTANTS.STORAGE_KEY_REGISTRATION.BIRTH_DATE,
      formateDate(date),
    );
    if (isModal) {
      fetchAndSetModalPage('/verify-email');
      return;
    }
    history.push('/verify-email');
  };

  return (
    <>
      <div className="logo" />
      <div className="signup birthdatePage">
        <div className="d-flex header">
          <ProgressBar percentage="35" />
          <div className="d-flex topBar">
            {!isModal ? (
              <Link to="/location" className="arrowBack" />
            ) : (
              <Button
                className="linkBtn arrowBack"
                onClick={() => {
                  mixPanelTrackEvent(
                    MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_DOB,
                    {
                      action: 'Back',
                    },
                  );
                  fetchAndSetModalPage('/location');
                }}
              />
            )}
            <h2 className="heading">Sign Up</h2>
          </div>
        </div>
        <div className="wrapper">
          <div className="info">
            <div className="iconBig lockIcon" />
            <div className="title">
              Enter your <strong>birthdate</strong>
            </div>
            <div className="description">
              {MESSAGE_CONSTANTS.BIRTH_DATE.DESCRIPTION}
            </div>
          </div>
          <span className="birthdayOutput d-none">{formatDatell(date)}</span>
          <DatePicker
            setdisableNext={setdisableNext}
            setDate={setDate}
            handleDateChange={handleDateChange}
          />
          <Button
            className="btn btn-primary "
            disabled={disableNext}
            label={BUTTON_LABELS.COMMON.NEXT}
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.SIGNUP_ENTER_DOB, {
                action: 'Next',
                formData: formateDate(date),
              });
              storeData();
            }}
          />
        </div>
      </div>
    </>
  );
};
