import React, { useState } from 'react';
import 'StyleSheets/css/favorite-sport.css';
import { ARRAY_CONST } from 'Constants/array-constants';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'Components/ProgressBar';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { CheckBox } from 'Components/Form-Elements/Checkbox';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { savePreference } from 'Actions/save-user-preference';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const PlayPreference = ({ history }) => {
  const [playPreference, setPlayPreference] = useState(ARRAY_CONST.PLAY_STYLE);

  const handleCheckboxChange = name => {
    setPlayPreference(
      playPreference.map(playStyle => {
        if (playStyle.name === name) {
          // eslint-disable-next-line no-param-reassign
          playStyle.isSelected = !playStyle.isSelected;
        }
        return playStyle;
      }),
    );
  };

  const getPlayData = () => {
    const data = [];
    playPreference.forEach(playStyle => {
      if (playStyle.isSelected) {
        if (playStyle.id === 1) {
          data.push('free');
        } else {
          data.push(playStyle.name);
        }
      }
    });
    return {
      playAmounts: data,
    };
  };

  const handleSubmit = () => {
    savePreference(getPlayData())
      .then(resp => {
        if (resp.data.status === APP_CONSTANTS.HTTP_STATUS_CODES.OK) {
          history.push('/for-you');
        }
      })
      .catch(resp => {
        // handle error here.
      });
  };

  return (
    <>
      <div className="logo" />
      <div className="signup">
        <div className="d-flex header">
          <ProgressBar percentage="95" />
          <div className="d-flex topBar">
            <Link to="/for-you" className="arrowBack" />
            <h2 className="heading">Sign Up</h2>
            <Link to="/for-you" className="bottomFixed btnTransparent">
              {BUTTON_LABELS.COMMON.SKIP}
            </Link>
          </div>
        </div>
        <div className="wrapper">
          <div className="question info">
            <div className="title">
              {MESSAGE_CONSTANTS.SURVEY.PLAY_PREFERENCE}{' '}
            </div>
          </div>
          <form>
            <div className="sportListingWrapper">
              {playPreference.map(({ name, isSelected }) => {
                return (
                  <CheckBox
                    label={name}
                    isSelected={isSelected}
                    onCheckboxChange={handleCheckboxChange}
                    key={name}
                  />
                );
              })}
            </div>
            <Button
              className="btn btn-primary"
              type="submit"
              onClick={handleSubmit}
              disabled={!playPreference.some(({ isSelected }) => isSelected)}
              label={BUTTON_LABELS.COMMON.NEXT}
            />
          </form>
        </div>
      </div>
    </>
  );
};
