import React from 'react';
import PropTypes from 'prop-types';
import icon from '../../assets/icons/icons.svg';

export const Alert = ({ alertBoxDetails, template, closeButtonHandler }) => {
  if (template === 'red') {
    return (
      <div className="alert red">
        <h3 className="alert__title">Server-side message</h3>
        <p className="alert__copy">{alertBoxDetails}</p>
        <button
          className="alert__btnClose"
          type="button"
          onClick={closeButtonHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <use xlinkHref={`${icon}#i-close`} />
          </svg>
        </button>
      </div>
    );
  }
  if (template === 'green') {
    return (
      <div className="alert green">
        <h3 className="alert__title">Server-side message</h3>
        <p className="alert__copy">{alertBoxDetails}</p>
        <button
          className="alert__btnClose"
          type="button"
          onClick={closeButtonHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <use xlinkHref={`${icon}#i-close`} />
          </svg>
        </button>
      </div>
    );
  }
  if (template === 'powderBlue') {
    return (
      <div className="alert powderBlue">
        <h3 className="alert__title">Server-side message</h3>
        <p className="alert__copy">{alertBoxDetails}</p>
        <button
          className="alert__btnClose"
          type="button"
          onClick={closeButtonHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <use xlinkHref={`${icon}#i-close`} />
          </svg>
        </button>
      </div>
    );
  }

  if (template === 'marineBlue') {
    return (
      <div className="alert marineBlue">
        <h3 className="alert__title">Server-side message</h3>
        <p className="alert__copy">{alertBoxDetails}</p>
        <button
          className="alert__btnClose"
          type="button"
          onClick={closeButtonHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <use xlinkHref={`${icon}#i-close`} />
          </svg>
        </button>
      </div>
    );
  }
  if (template === 'blue') {
    return (
      <div className="alert blue">
        <h3 className="alert__title">Server-side message</h3>
        <p className="alert__copy">{alertBoxDetails}</p>
        <button
          className="alert__btnClose"
          type="button"
          onClick={closeButtonHandler}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
            <use xlinkHref={`${icon}#i-close`} />
          </svg>
        </button>
      </div>
    );
  }
  return (
    <div className="alert">
      <h3 className="alert__title">Server-side message</h3>
      <p className="alert__copy">{alertBoxDetails}</p>
      <button
        className="alert__btnClose"
        type="button"
        onClick={closeButtonHandler}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <use xlinkHref={`${icon}#i-close`} />
        </svg>
      </button>
    </div>
  );
};

Alert.defaultProps = {
  alertBoxDetails: '',
  template: '',
  closeButtonHandler: () => {},
};
Alert.propTypes = {
  alertBoxDetails: PropTypes.string,
  template: PropTypes.string,
  closeButtonHandler: PropTypes.func,
};

/* ModalBox.defaultProps = {
  modalBoxDetails: {},
  cancelButtonHandler: () => {},
  okButtonHandler: () => {},
  cancelButtonText: BUTTON_LABELS.COMMON.CANCEL,
  okButtonText: BUTTON_LABELS.COMMON.OK,
  showFooterButtons: true,
  showBothButtons: true,
};

ModalBox.propTypes = {
  modalBoxDetails: PropTypes.objectOf({
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  cancelButtonHandler: PropTypes.func,
  okButtonHandler: PropTypes.func,
  cancelButtonText: PropTypes.string,
  okButtonText: PropTypes.string,
  showFooterButtons: PropTypes.bool,
  showBothButtons: PropTypes.bool,
}; */
