import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { ACTION_TYPE } from 'Constants/action-type';

const initialState = {
  allowPaid: false,
  modalBoxName: '',
  toggleModalBox: false,
  selectedEntryFee: '',
  entryFee: [],
  ageError: true,
  winAmountString: '',
  contestData: '',
  sportData: {},
  lineUp: [],
  editLineUpRawData: [],
  activeLineupEdit: false,
  contestId: '',
  activeContest: {},
  toggleLoader: false,
  lineUpStrength: 0,
  buttonEnable: false,
  rangeSlideValue: 0,
  dataPlayers: [],
  enablewinScreen: false,
  activePosition: '',
  rangeData: '',
  editLineupId: '',
  editLineupEntryData: null,
  lineUpFinalWin: '',
  isDraftCreated: false,
  isLocationVerified: false,
  playerRawData: [],
  walletBalance: {
    amount: 0,
  },
  isIntialDataSet: false,
  isRangeSliderUsed: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPE.LINEUP.SET_ALLOW_PAID:
      return { ...state, allowPaid: action.payload };
    case ACTION_TYPE.LINEUP.SET_MODAL_BOX_NAME:
      return { ...state, modalBoxName: action.payload };
    case ACTION_TYPE.LINEUP.SET_TOGGLE_MODAL_BOX:
      return { ...state, toggleModalBox: action.payload };
    case ACTION_TYPE.LINEUP.SET_SELECTED_ENTRY_FEE:
      return { ...state, selectedEntryFee: action.payload };
    case ACTION_TYPE.LINEUP.SET_ENTRY_FEE:
      return { ...state, entryFee: action.payload };
    case ACTION_TYPE.LINEUP.SET_AGE_ERROR:
      return { ...state, ageError: action.payload };
    case ACTION_TYPE.LINEUP.SET_WIN_AMOUNT_STR:
      return { ...state, winAmountString: action.payload };
    case ACTION_TYPE.LINEUP.SET_CONTEST_DATA:
      return { ...state, contestData: action.payload };
    case ACTION_TYPE.LINEUP.SET_SPORT_DATA:
      return { ...state, sportData: action.payload };
    case ACTION_TYPE.LINEUP.SET_LINE_UP:
      return { ...state, lineUp: action.payload };
    case ACTION_TYPE.LINEUP.SET_EDIT_LINE_UP_RAW_DATA:
      return { ...state, editLineUpRawData: action.payload };
    case ACTION_TYPE.LINEUP.SET_ACTIVE_LINEUP_EDIT:
      return { ...state, activeLineupEdit: action.payload };
    case ACTION_TYPE.LINEUP.SET_ACTIVE_CONTEST_ID:
      return { ...state, contestId: action.payload };
    case ACTION_TYPE.LINEUP.SET_ACTIVE_CONTEST_DATA:
      return { ...state, activeContest: action.payload };
    case ACTION_TYPE.LINEUP.TOGGLE_LOADER:
      return { ...state, toggleLoader: action.payload };
    case ACTION_TYPE.LINEUP.SET_LINEUP_STRENGTH:
      return { ...state, lineUpStrength: action.payload };
    case ACTION_TYPE.LINEUP.SET_BUTTON_ENABLE:
      return { ...state, buttonEnable: action.payload };
    case ACTION_TYPE.LINEUP.SET_RANGE_VALUE: {
      const isRangeSliderUsed =
        state.contestData &&
        state.rangeData &&
        state.rangeData[action.payload] &&
        state.contestData.targetNumber !==
          state.rangeData[action.payload].number;
      return { ...state, rangeSlideValue: action.payload, isRangeSliderUsed };
    }
    case ACTION_TYPE.LINEUP.ENABLE_WIN_SCREEN:
      return { ...state, enablewinScreen: action.payload };
    case ACTION_TYPE.LINEUP.SET_ACTIVE_POSITION:
      return { ...state, activePosition: action.payload };
    case ACTION_TYPE.LINEUP.SET_RANGE_DATA:
      return { ...state, rangeData: action.payload };
    case ACTION_TYPE.LINEUP.SET_FINAL_WIN:
      return { ...state, lineUpFinalWin: action.payload };
    case ACTION_TYPE.LINEUP.SET_LOCATION_VERIFIED:
      return { ...state, isLocationVerified: action.payload };
    case ACTION_TYPE.LINEUP.SET_BALANCE:
      return { ...state, walletBalance: action.payload };
    case ACTION_TYPE.LINEUP.SET_TO_INITIAL_STATE:
      return initialState;
    case ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ID:
      return { ...state, editLineupId: action.payload };
    case ACTION_TYPE.LINEUP.SET_TO_EDIT_LINEUP_ENTRY_DATA:
      return { ...state, editLineupEntryData: action.payload };
    case ACTION_TYPE.LINEUP.SET_TO_DATA_PLAYERS:
      return { ...state, dataPlayers: action.payload };
    case ACTION_TYPE.LINEUP.SET_RAW_PLAYERS:
      return { ...state, playerRawData: action.payload };
    case ACTION_TYPE.LINEUP.SET_DRAFT_CREATED:
      return { ...state, isDraftCreated: action.payload };
    case ACTION_TYPE.LINEUP.TOGGLE_INITAL_DATA:
      return { ...state, isIntialDataSet: true };
    case ACTION_TYPE.LINEUP.RESET_RANGE_SLIDER_USED_CHECK:
      return { ...state, isRangeSliderUsed: false };
    default:
      return state;
  }
};

const LineUpContext = React.createContext(initialState);

function ContextProvider({ children }) {
  const [state, contextDispatch] = useReducer(reducer, initialState);
  return (
    <LineUpContext.Provider value={{ state, contextDispatch }}>
      {children}
    </LineUpContext.Provider>
  );
}

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { LineUpContext, ContextProvider };
