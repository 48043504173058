import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'Components/Form-Elements/Image';
import { detectDevice } from 'Utils/device-utils';
import { ProfileContext } from 'Components/Profile/Context';
import { ACTION_TYPE } from 'Constants/action-type';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { LineUpModal } from 'Components/LineUpModalFlow';
import { SignUpModal } from 'Components/ModalSignUpFlow';
import { mixpanelTrackPageView } from 'Utils/mix-panel';
import { chekUserExists } from 'Actions/user-exists-action';
import { setInSessionStorage } from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { Loader } from 'Components/Loader';
import { NewGame } from 'Components/NewGame';
import { Button } from 'Components/Form-Elements/Button';
import plusIcon from '../../assets/images/plusWhite@2x.png';
import gameEntryImage from '../../assets/images/freeEntryImage.png';

export const ForYouGuest = ({ history, location }) => {
  const [showSignUpFlow, setShowSignUpFlow] = useState(false);
  const { profileState, profileContextDispatch } = useContext(ProfileContext);
  const [showLineupModal, setShowLineupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [toggleDesign, setToggleDesign] = useState(false);
  const isMobile = detectDevice();

  const queryString = require('query-string');
  const parsed = queryString.parse(location.search);

  const {
    HTTP_STATUS_CODES: { ALREADY_EXISTS, OK },
  } = APP_CONSTANTS;

  const {
    ERROR_CODE: { USER_EXISTS },
  } = MESSAGE_CONSTANTS;

  useEffect(() => {
    chekUserExists(`email=${parsed.email}`)
      .then(({ data }) => {
        setIsLoading(false);
        setInSessionStorage('guest-user-email', parsed.email);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        const {
          data: { status, code },
        } = response;
        if (status === ALREADY_EXISTS && code === USER_EXISTS) {
          setInSessionStorage('user-already-exists', USER_EXISTS);
          setInSessionStorage('existing-email', parsed.email);
          history.push('/login');
        }
      });
  }, []);

  useEffect(() => {
    const guestUser = location.search;
    if (guestUser) {
      profileContextDispatch({
        type: ACTION_TYPE.PROFILE.SET_USER_MAIL,
        payload: guestUser || '',
      });
      mixpanelTrackPageView('Home Screen Guest');
    }
  }, []);

  const toggleRoutes = () => {
    return isMobile ? setToggleDesign(!toggleDesign) : setShowLineupModal(true);
  };

  const afterSignUp = (toggleSignupModal, isSuccess = false) => {
    setShowSignUpFlow(toggleSignupModal);
    if (isSuccess) {
      history.push('/refer-friend');
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      {showLineupModal && (
        <LineUpModal
          showLineupModal={showLineupModal}
          setShowLineupModal={setShowLineupModal}
          history={history}
          location={location}
        />
      )}
      {
        <SignUpModal
          showSignUpFlow={showSignUpFlow}
          setShowSignUpFlow={afterSignUp}
        />
      }
      <div className="forYouPage">
        <div className="pageHeaderWrap">
          <div className="d-flex topBar resetTopBar">
            <div className="d-flex pageHeader">
              <div className="leftSide" />
              <h2 className="headerTitle d-none d-md-block">
                {MESSAGE_CONSTANTS.FOR_YOU.HEADING}
              </h2>
            </div>
          </div>
        </div>
        <h2 className="pageTitle d-md-none">
          {MESSAGE_CONSTANTS.FOR_YOU.HEADING}
        </h2>
        <div className="emptyGame">
          <div className="emptyLinupWrap">
            <span className="startNewGame" onClick={() => toggleRoutes()}>
              start a new game
            </span>
          </div>
        </div>
        <div className="btnAddWrap">
          <span className="btnaddNewGame" onClick={() => toggleRoutes()}>
            <Image className="gameImage" src={plusIcon} alt="basketball" />
          </span>
        </div>
        <div className="moreAboutGame">
          <div className="gameInfoContainer">
            <div className="gameInfoList d-flex">
              <Link onClick={() => setShowSignUpFlow(true)}>
                <div className="gameInfoWrap">
                  <Image
                    className="gameImage"
                    src={gameEntryImage}
                    alt="basketball"
                  />
                  <div className="gameEntry">game Entry</div>
                  <div className="gameEntryTitle">$10 Free Cash Game Entry</div>
                  <p>
                    Easy money for you. For every friend you invite who plays a
                    paid game, you recieve $10!
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {toggleDesign && (
          <div className="selectGuard selectGame">
            <div className="drawerClose">
              <span />
            </div>
            <div className="listOuter ">
              <div className="guardTitle">
                <Button
                  type="button"
                  className="closeGuard"
                  onClick={() => setToggleDesign(false)}
                />
                New DN Lineup
              </div>
              <NewGame />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
