/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'Components/Form-Elements/Button';
import {
  mixpanelTrackPageView,
  mixPanelTrackRegistration,
  mixPanelTrackEvent,
} from 'Utils/mix-panel';
import Rangeslider from 'react-rangeslider';
import { getResposiblePlayLimits } from 'Actions/user-balance-action';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { startCreateDeposit } from 'Actions/wirecard-action';
import {
  getCookie,
  getFromSessionStorage,
  setInSessionStorage,
  removeFromSessionStorage,
} from 'Utils/storage-utilities';
import { APP_CONSTANTS } from 'Constants/app-constants';
import { ConfirmPayment } from 'Components/Wallet/ConfirmPayment';
import { Loader } from 'Components/Loader';
import { detectDevice } from 'Utils/device-utils';
import { maskCardNumber, getPaymentMethodIconClass } from 'Utils/data-utils';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const AddFunds = ({
  history,
  fetchAndSetModalPage,
  closeModalBox,
  isModal,
}) => {
  const activeCard = getFromSessionStorage('active-card');

  const [dollar, setDollar] = useState(10);
  const [maxDepositLimit, setmaxDepositLimit] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDropDown, setShowDropDown] = useState();
  const [cardsSaved, setSavedCards] = useState([]);
  const [activecard, setActiveCards] = useState(activeCard);
  const WalletBal = getFromSessionStorage('WalletBal');
  const isMobile = detectDevice();
  const savedCards = getFromSessionStorage('saved-cards');

  const {
    CARD_TYPE: { CHECK, DEBIT, CREDIT },
  } = MESSAGE_CONSTANTS;

  useEffect(() => {
    getResposiblePlayLimits()
      .then(({ data }) => {
        setIsLoading(false);
        if (data && data.depositLimit) {
          setmaxDepositLimit(data.depositLimit);
        }
      })
      .catch(err => {
        console.log(err);
      });

    mixpanelTrackPageView('Add Funds Screen');
  }, []);

  const addFundsToWallet = () => {
    setInSessionStorage('selected-amount', dollar);
    isMobile
      ? isModal
        ? fetchAndSetModalPage('/payment')
        : history.push('/payment')
      : fetchAndSetModalPage('/payment');
  };

  const setActiveCard = item => {
    setActiveCards(item);
    setShowDropDown(!showDropDown);
  };

  const paymentMethodList = () => {
    setInSessionStorage('active-card', activecard);
    setInSessionStorage('saved-cards', cardsSaved);
    isModal ? setShowDropDown(!showDropDown) : history.push('/payment-methods');
  };

  const paymentList = item => {
    return (
      <li
        className={`d-flex ${
          activecard &&
          activecard.payment_method_identifier ===
            item.payment_method_identifier
            ? 'selectedCard'
            : ''
        }`}
        key={item.payment_method_identifier}>
        <button
          type="button"
          className="d-flex align-items-center"
          onClick={() => {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
              action: 'Select Method',
              formData: {
                newSelectedCard: item,
                walletBalance: WalletBal,
                addBalanceAmount: dollar,
              },
            });
            setActiveCard(item);
          }}>
          <div className={`listIcon ${getPaymentMethodIconClass(item)}`} />
          <div className="infoWrap flex-grow-1">
            <div className="title">
              {item.card_brand ? item.card_brand : item.description}
            </div>
            <div className="info font-sm">
              <span>{item.account_type}</span>
              <span>{maskCardNumber(item.number)}</span>
              <span>
                {item.expiration_date ? `Expires ${item.expiration_date}` : ''}
              </span>
            </div>
          </div>
        </button>
      </li>
    );
  };

  const renderDepositMethod = item => {
    return (
      <>
        <div className="dropDownWrap">
          <div className="listWithIcons">
            <div className="sourceTitle">Select Method</div>
            <ul className="border">
              <li className="d-flex" key={item.publicId}>
                <button
                  type="button"
                  tag="button"
                  className="d-flex align-items-center addFundsBtn"
                  onClick={() => {
                    mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                      action: 'Select Method',
                      formData: {
                        currentSelectedCard: item,
                        newSelectedCard: item,
                        walletBalance: WalletBal,
                        addBalanceAmount: dollar,
                      },
                    });
                    isMobile
                      ? paymentMethodList()
                      : setShowDropDown(!showDropDown);
                  }}>
                  <div
                    className={`listIcon ${getPaymentMethodIconClass(item)}`}
                  />
                  <div className="infoWrap flex-grow-1">
                    <div className="title">
                      {item.card_brand ? item.card_brand : item.description}
                    </div>
                    <div className="info font-sm">
                      <span>{item.account_type}</span>
                      <span>{maskCardNumber(item.number)}</span>
                      <span>
                        {item.expiration_date
                          ? `Expires ${item.expiration_date}`
                          : ''}
                      </span>
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </div>
          {showDropDown && (
            <div className="paymentDropdown">
              <div className="listWithIcons">
                <ul>
                  {cardsSaved && cardsSaved.map(card => paymentList(card))}
                  <li className="d-flex" key="add-card">
                    <button
                      className="d-flex align-items-center"
                      type="button"
                      onClick={addFundsToWallet}>
                      <div className="listIcon addCardIcon" />
                      <div className="link">
                        {BUTTON_LABELS.WALLET.ADD_CARD}
                      </div>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  const isCardExists = () => {
    return activecard ? (
      renderDepositMethod(activecard)
    ) : (
      <div className="listWithIcons">
        <div className="sourceTitle">Select Method</div>
        <ul className="border">
          <li className="d-flex" key="add-card">
            <button
              className="d-flex align-items-center"
              type="button"
              onClick={addFundsToWallet}>
              <div className="listIcon addCardIcon" />
              <div className="link">{BUTTON_LABELS.WALLET.ADD_CARD}</div>
            </button>
          </li>
        </ul>
      </div>
    );
  };

  const handlePayment = (activecard1, dollar1) => {
    setInSessionStorage('active-card', activecard1);
    setInSessionStorage('selected-amount', dollar1);
    setInSessionStorage(
      'card-public-id',
      activecard1.payment_method_identifier,
    );
    return isMobile
      ? isModal
        ? fetchAndSetModalPage('/confirm-payment')
        : setShowConfirmation(true)
      : fetchAndSetModalPage('/confirm-payment');
  };

  const handleAddFunds = () => {
    if (activecard) {
      return handlePayment(activecard, dollar);
    }
    setInSessionStorage('do-cashin-flow', true);
    return addFundsToWallet();
  };

  useEffect(() => {
    const userdata = getCookie(APP_CONSTANTS.USER_DATA_KEY.USER_DATA);
    startCreateDeposit(userdata.token).then(({ data }) => {
      if (data.paymentMethods && data.paymentMethods.length) {
        let data1 = [];
        data.paymentMethods.forEach(({ accounts }) => {
          data1 = [...data1, ...accounts];
        });
        if (data1.length) {
          if (!activeCard || !activeCard.payment_method_identifier) {
            const obj = data1.find(({ recently_used }) => recently_used);
            setActiveCards(obj || data1[0]);
          }
          setSavedCards(data1);
          const doCashIn = getFromSessionStorage('do-cashin-flow');
          const addFundAmount = getFromSessionStorage('selected-amount');
          if (doCashIn) {
            removeFromSessionStorage('do-cashin-flow');
          }
          if (doCashIn && data1.length && data1.length === 1 && addFundAmount) {
            setDollar(addFundAmount);
            setActiveCard(data1[0]);
            handlePayment(data1[0], addFundAmount);
          }
        }
      }
    });
  }, []);

  return (
    <>
      {isLoading && <Loader />}
      {showConfirmation && (
        <ConfirmPayment
          close={() => setShowConfirmation(false)}
          amount={dollar}
          bal={WalletBal}
          renderCard={isCardExists}
          history={history}
        />
      )}
      <div className="d-flex popUpHeader">
        <div className="leftSide">
          {isMobile ? (
            isModal ? (
              <Button
                onClick={() => {
                  mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                    action: 'Cancel',
                    formData: {
                      currentSelectedCard: activecard,
                      walletBalance: WalletBal,
                      addBalanceAmount: dollar,
                    },
                  });
                  closeModalBox();
                }}
                className="btnCancel"
                label={BUTTON_LABELS.COMMON.CANCEL}
              />
            ) : (
              <Link to="/wallet">{BUTTON_LABELS.COMMON.CANCEL}</Link>
            )
          ) : (
            <Button
              onClick={() => {
                mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                  action: 'Cancel',
                  formData: {
                    currentSelectedCard: activecard,
                    walletBalance: WalletBal,
                    addBalanceAmount: dollar,
                  },
                });
                closeModalBox();
              }}
              className="btnCancel"
              label={BUTTON_LABELS.COMMON.CANCEL}
            />
          )}
        </div>
        <h2>{MESSAGE_CONSTANTS.WALLET.ADD_FUNDS.HEADING}</h2>
        <div className="walletAmountBtn rightSide" />
      </div>
      <div className="wrapper modalContainer">
        <div>
          <div className="popUpMiddleWrapp info">
            <div className="title centerText">
              <strong>
                <sup className="dollorSign">$</sup>
                {dollar}
              </strong>
              <div className="availableBalance centerText">
                <span>{`$${WalletBal} available in Wallet`}</span>
                <div className="questionMarkInfo">
                  <span className="questionIcon" />
                  <div className="aboutQuestionInfo">
                    <p>
                      {MESSAGE_CONSTANTS.WALLET.TOOL_TIP_MSG} See
                      <a href="/terms-of-service"> terms and conditions</a>.
                      *Some exclusions apply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 rangeSliderWrap">
              <Rangeslider
                value={dollar}
                min={10}
                max={maxDepositLimit}
                step={10}
                onChange={setDollar}
              />
            </div>
            <div className="availableBalance centerText w-100 minAmount">
              <span>{dollar === 10 && 'Minimum Transfer Amount'}</span>
              <span>
                {maxDepositLimit === dollar && 'Maximum Transfer Amount'}
              </span>
            </div>
          </div>
          {isCardExists()}
          <Button
            className="btn btn-primary fullWidht addBtn"
            type="button"
            onClick={() => {
              mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.DEPOSIT, {
                action: 'Add',
                formData: {
                  currentSelectedCard: activecard,
                  walletBalance: WalletBal,
                  addBalanceAmount: dollar,
                },
              });
              handleAddFunds();
            }}
            label={`${BUTTON_LABELS.COMMON.ADD} $${dollar}`}
          />
        </div>
      </div>
    </>
  );
};
