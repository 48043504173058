/* eslint-disable no-console */
export const firstName = value => {
  if (!value || typeof value !== 'string') {
    return value;
  }

  return value
    .split(' ')
    .slice(0, 1)
    .join(' ');
};
export const lastName = value => {
  if (!value || typeof value !== 'string') {
    return value;
  }
  return value
    .split(' ')
    .slice(-1)
    .join(' ');
};
export const getLastName = value => {
  if (!value || typeof value !== 'string') {
    return value;
  }
  let lname = value
    .split(' ')
    .slice(-1)
    .join(' ');

  // special case of last name handling,
  if (lname === 'II' || lname === 'Sr.' || lname === 'Jr.') {
    lname = value
      .split(' ')
      .slice(-2)
      .join(' ');
  }
  return lname;
};
export const toObject = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch {
    console.error('Error while parsing json');
    return null;
  }
};
export const ordinalFormatNumber = number => {
  const remainder = number % 10;
  if (remainder === 1 && number !== 11) {
    return 'st';
  }
  if (remainder === 2 && number !== 12) {
    return 'nd';
  }
  if (remainder === 3 && number !== 13) {
    return 'rd';
  }
  return 'th';
};
