import { APP_CONSTANTS } from 'Constants/app-constants';

export const isAndroid = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('android') > -1 && ua.indexOf('mobile');
};

export const isIos = () => {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
};

export const detectDevice = () => {
  return APP_CONSTANTS.VALIDATION.DEVICE_CHECK.test(navigator.userAgent);
};
