import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

export const MoboTouchEffect = ({
  hidePlayersDesign,
  children,
  swipup,
  setSwipStatus,
}) => {
  const [height, setheight] = useState('');
  const [onlyOnce, setOnce] = useState(true);

  const [swipePosition, setSwipePosition] = useState(0);

  useEffect(() => {
    setSwipStatus(false);
    setSwipePosition(0);
  }, []);

  const handlers = useSwipeable({
    onSwiping: eventData => {
      setSwipePosition(eventData.deltaY);
    },
    onSwipedUp: eventData => {
      // console.log('onSwipeUp ', eventData.absY, eventData.absY > 80);
      if (eventData.absY > 80) {
        setSwipStatus(true);
      } else {
        setSwipePosition(0);
      }
    },
    onSwipedDown: eventData => {
      // console.log('onSwipedDown ', eventData.absY, eventData.absY > 80);
      // console.log('User onSwipedDown!', eventData);
      const guardDiv = document.getElementById('guardDiv');
      if (guardDiv && !guardDiv.classList.contains('u-borderBottom')) {
        setSwipStatus(false);
      }

      if (
        eventData.initial &&
        eventData.initial[1] &&
        eventData.initial[1] <= 40
      ) {
        setSwipStatus(false);
        setSwipePosition(0);
        if (eventData.deltaY >= 130) {
          setTimeout(() => {
            hidePlayersDesign();
          }, 300);
        }
      } else if (
        eventData &&
        eventData.event &&
        eventData.event.path &&
        eventData.event.path.length &&
        /*
        eventData.event.path.some(
          item =>
            item.classList &&
            (item.classList[0] === 'guardTitle' ||
              item.classList[0] === 'drawerClose'),
        ) &&
        */
        eventData.deltaY >= 80
      ) {
        hidePlayersDesign();
      } else {
        setSwipePosition(0);
      }
    },
  });

  const EmelementStale =
    (height && {
      style: {
        height: `${height - Math.round(swipePosition)}px`,
        maxHeight: '100%',
      },
    }) ||
    {};

  return (
    <div
      id="touchDiv"
      className={`selectGuard ${swipup ? 'swipUp' : ''}`}
      {...handlers}
      onLoad={() => {
        const data = document.getElementById('touchDiv');
        if (data && onlyOnce) {
          setheight(data.getBoundingClientRect().height);
          setOnce(false);
        }
      }}
      {...EmelementStale}>
      {children}
    </div>
  );
};

MoboTouchEffect.propTypes = {
  hidePlayersDesign: PropTypes.func.isRequired,
  setSwipStatus: PropTypes.func.isRequired,
  swipup: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
