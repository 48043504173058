import React from 'react';
import PropTypes from 'prop-types';
import Rangeslider from 'react-rangeslider';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { mixPanelTrackRegistration, mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const CustomRangeSelector = ({
  rangeSlideValue,
  rangeData,
  changeRangeValue,
  isModal,
}) => {
  const horizontalLabels = {
    0: 'Sure Thing',
    9: 'Longshot',
  };

  if (isModal) {
    return (
      <div className="rangeBlock">
        <span>{MESSAGE_CONSTANTS.LINEUP.CUSTOM_PLAY_STYLE}</span>
        <div className="questionMarkInfo">
          <span className="questionIcon" />
          <div className="aboutQuestionInfo">
            <p>
              You wanna push it to the limit? Or give yourself an advantage to
              beat the Daily Number? Adjust your play style to increase your
              potential winnings or lower your stress.
            </p>
          </div>
        </div>
        <div className="rangeWrap">
          <Rangeslider
            value={rangeSlideValue}
            min={0}
            max={(rangeData && rangeData.length - 1) || 0}
            labels={horizontalLabels}
            onChange={changeRangeValue}
            tooltip={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="rangeWrap">
      <div className="sliderTitle">
        <span>{MESSAGE_CONSTANTS.LINEUP.CUSTOM_PLAY_STYLE}</span>
        <div className="questionMarkInfo">
          <span className="questionIcon" />
          <div className="aboutQuestionInfo">
            <p>
              You wanna push it to the limit? Or give yourself an advantage to
              beat the Daily Number? Adjust your play style to increase your
              potential winnings or lower your stress.
            </p>
          </div>
        </div>
      </div>
      <span className="questionSign" />
      <Rangeslider
        value={rangeSlideValue}
        min={0}
        max={(rangeData && rangeData.length - 1) || 0}
        labels={horizontalLabels}
        onChange={changeRangeValue}
        tooltip={false}
      />
    </div>
  );
};

CustomRangeSelector.defaultProps = {
  changeRangeValue: () => {},
  rangeSlideValue: 0,
  rangeData: [],
  isModal: false,
};

CustomRangeSelector.propTypes = {
  rangeSlideValue: PropTypes.number,
  changeRangeValue: PropTypes.func,
  rangeData: PropTypes.arrayOf(PropTypes.any),
  isModal: PropTypes.bool,
};
