import { NetworkManager } from 'Network/network-manager';
import { API } from 'Constants/api-constants';

export const checkIfUserExists = async data => {
  const url = API.USER_LOGIN;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};

export const checkIfInternationalUserExists = async data => {
  const url = API.INTERNATIONAL_LOGIN;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};

export const checkIfUserExistsSocial = async data => {
  const url = API.USER_SOCIAL_LOGIN;
  const response = await NetworkManager.requestPOST(url, data);
  return response;
};
