import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as validate from 'Utils/validations';

const INPUT_TYPE_PASSWORD = 'password';

export const Input = ({
  id,
  textLabel,
  inputType,
  className,
  value,
  readOnly = false,
  disabled,
  placeholder,
  handleChange,
  validationRules,
  getValidity,
  onBlur,
  externalError,
  maxLength,
  min, // type = number
  max, // type = number
  onKeyUp,
  onKeyDown,
}) => {
  const [text, setText] = useState(value);
  const [hideError, setHideError] = useState(true);
  const [isValid, setValidity] = useState(false);
  const [errorText, setErrorText] = useState(undefined);

  const validator = value => {
    let inputValid = true;
    for (let i = 0; i < validationRules.length; i += 1) {
      setErrorText(validate[validationRules[i]](value));
      if (validate[validationRules[i]](value)) {
        inputValid = false;
        break;
      }
    }
    setValidity(inputValid);
    getValidity(inputValid);
    setHideError(inputValid);
  };

  const handleOnBlur = () => {
    if (isValid) onBlur(text);
  };

  useEffect(() => {
    if (value && id === 'verify-email-id' && value.length) {
      validator(value);
    }
  }, []);

  const handleInputChange = event => {
    const userInput = event.target.value;
    setText(userInput);
    if (validationRules.length) {
      validator(userInput);
    }
    handleChange(userInput);
  };
  const inputProps = {
    type: inputType,
    className: `${className}${
      !hideError || externalError ? ' is-invalid' : ''
    }`,
    id,
    disabled,
    placeholder,
    onChange: handleInputChange,
    onBlur: handleOnBlur,
    maxLength,
    readOnly,
    autoComplete: 'Off',
    min,
    max,
    onKeyUp,
    onKeyDown,
  };
  /* This check is for handling vulnerability of password being exposed
   *  in browser console.
   */
  if (inputType !== INPUT_TYPE_PASSWORD) {
    inputProps.value = value;
  }
  return (
    <>
      {/* eslint-disable-next-line */}
      <input {...inputProps} />
      <label htmlFor={id}>{textLabel}</label>
      <div className="invalid-feedback">{errorText || externalError}</div>
    </>
  );
};
Input.defaultProps = {
  getValidity: () => {},
  handleChange: () => {},
  onBlur: () => {},
  textLabel: '',
  inputType: 'text',
  className: 'form-control',
  value: '',
  id: '',
  disabled: false,
  placeholder: '',
  validationRules: [],
  externalError: undefined,
  maxLength: '',
  readOnly: false,
};

Input.propTypes = {
  getValidity: PropTypes.func,
  handleChange: PropTypes.func,
  onBlur: PropTypes.func,
  textLabel: PropTypes.string,
  inputType: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  validationRules: PropTypes.arrayOf(PropTypes.string),
  externalError: PropTypes.string,
  maxLength: PropTypes.string,
  readOnly: PropTypes.string,
};
