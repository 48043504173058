export const API = {
  GEO_LOCATION: 'https://maps.googleapis.com/maps/api/geocode/json',
  // Regitration urls
  USER_EXISTS: '/api/users/username-or-email/exists',
  USER_LOGIN: '/api/users/login',
  USER_SOCIAL_LOGIN: '/api/users/social-login',
  INTERNATIONAL_LOGIN: '/api/users/autoLogin',
  USER_LOGOUT: '/api/users/logout',
  GET_OPT: '/api/users/otp',
  VERIFY_OTP: '/api/users/verify-otp',
  REGISTER: '/api/users',
  // Registration urls end here..
  SPORT_LIST: '/api/sports',
  CONTEST_LIST: '/apimt/publications/lobbyContests',
  USER_SIGNUP: '/api/users',
  SPORT_SLUG_LIST: '/apimt/publications/sports',
  GET_WALLET_BALANCE: '/apimt/methods/getUserBalanceSummary',
  GET_ACCOUNT_VERIFICATION_DETAILS:
    '/apimt/methods/getAccountVerificationDetails',
  UPDATE_ACCOUNT_VERIFICATION_DETAILS:
    '/apimt/methods/updateAccountVerificationDetails',
  GET_WALLET_BALANCE_ITEMS: '/apimt/methods/getUserBalanceItems',
  GET_RESPONSIBLE_PLAY_LIMITS: '/apimt/methods/getResposiblePlayLimits',
  GET_WITHDRAWAL: '/apimt/methods/getWithdrawals',
  SAVE_USER_PREFERENCE: 'api/users/preferences',
  GET_TEAMS: '/api/teams',
  GET_PLAYERS: '/api/players',
  CREATE_WITHDRAWAL: '/apimt/methods/createWithdrawal',
  CREATE_START_WITHDRAWAL: '/apimt/methods/startWithdrawalRequest',
  CANCEL_WITHDRAWAL: '/apimt/methods/cancelWithdrawal',
  GET_DEPOSIT_METHODS: '/apimt/methods/getDepositMethods',
  CREATE_DEPOSIT: '/apimt/methods/createDeposit',
  VERIFY_LOCATION: '/apimt/methods/verifyLocation',
  DRAFT_CONTEST: '/apimt/methods/draftContest',
  VERIFY_USER_IDENTITY: '/apimt/methods/verifyUserIdentity',
  USER_DETAIL: '/api/users/profile',
  USER_NOTIFICATIONS: '/api/users/notifications',
  DRAFT_PLAYER: '/apimt/publications/draftPlayers',
  LINE_UP_STRENGTH: '/apimt/methods/getLineupStrengthsWithPlayers',
  GET_PLAYER_DETAILS: '/apimt/publications/playerGameDetails/',
  GET_PLAYER_DETAILS_NEW: '/apimt/methods/playerGameDetails',
  GET_MULTIPLAYER_DETAILS: '/apimt/methods/multiPlayerGameDetails',
  GET_PLAYER_SEASON_STAT: '/apimt/publications/playerSeasonalGames',
  RESET_PASSWORD_LINK: '/api/users/forgot-password',
  RESET_PASSWORD_MAIL: '/api/users/forgot-password',
  GET_LOCATION_STATE: '/api/users/reverse-geo-code',
  UPDATE_PASSWORD: '/api/users/password',
  START_CREATE_DEPOSIT: '/apimt/methods/startCreateDepositMethod',
  CREATE_DEPOSIT_METHOD: '/apimt/methods/createDepositMethod',
  SUBMIT_RESPOSIBLE_PLAY_LIMITS: '/apimt/methods/submitResposiblePlayLimits',
  SUBMIT_SELF_EXCLUSION: '/apimt/methods/submitSelfExclusion',
  LINE_UP_SUBMIT: '/apimt/methods/submitEntry',
  VALIDATE_RESET_LINK: '/api/users/validate-reset-link',
  RESET_PASSWORD: '/api/users/reset-password',
  USER_PROFILE_IMAGE: '/api/users/profile-image',
  MY_CONTEST: '/apimt/publications/myContests',
  CONTEST_BY_ID: '/apimt/publications/lobbyContests',
  CONTEST_BY_IDS: '/apimt/methods/getLobbyContestsByIds',
  MY_CONTEST_NEW: '/apimt/methods/myContests',
  CANCEL_LINEUP: '/apimt/methods/refundEntry',
  SWAP_LINEUP: '/apimt/methods/swapPlayers',
  VERIFY_PHONE: '/api/users/phone-number/exists',
  USER_LINEUP: '/api/users/lineups',
  LINEUP_COUNT: '/api/users/lineups-count',
  REQUEST_EMAIL_VERIFICATION: '/api/users/request-email-verification',
  AUTHENTICATE_EMAIL_VERIFICATION: '/api/users/verify-email',
  REQUEST_PHONE_VERIFICATION: '/api/users/request-phone-verification',
  AUTHENTICATE_PHONE_VERIFICATION: '/api/users/verify-phone',
  UPDATE_DOB: '/api/users/update-dob',
  GET_DRAFT_USER: '/api/users/get-draft-user',
  UPDATE_LOCATION: '/api/users/update-location',
  GET_EXTERNAL_USER: '/api/users/get-external-user-info',
  CHECK_EXTERNAL_USER_WITH_SYSTEM: '/api/users/create-external-draft-user',
  AUTHENTICATE_PHONE_SIGNUP_VERIFICATION: '/api/users/verify-signup-phone',
};
