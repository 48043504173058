import React from 'react';
import PropTypes from 'prop-types';
import { MESSAGE_CONSTANTS } from 'Constants/message-constants';
import { BUTTON_LABELS } from 'Constants/button-labels';
import { Button } from 'Components/Form-Elements/Button';
import { Link } from 'react-router-dom';
import { mixPanelTrackEvent } from 'Utils/mix-panel';
import { MIXPANEL_EVENTS_CONSTANTS } from 'Constants/mixpanel_events_constants';

export const ResetConfirmation = ({ history }) => {
  return (
    <div className="signup">
      <div className="wrapper">
        <div className="info noTopBar">
          <div className="iconBig emailIcon" />
          <div className="title">
            <strong>{MESSAGE_CONSTANTS.RESET_CONFIRMATION.HEADING}</strong>
          </div>
          <div className="description">
            {MESSAGE_CONSTANTS.RESET_CONFIRMATION.DESCRIPTION}
            <br />
            <br />
            Can’t acces your email?
            <Link to="/"> Help us by confirming your identity. </Link>
          </div>
        </div>
        <Button
          className="btn btn-primary "
          disabled={false}
          label={BUTTON_LABELS.COMMON.DONE}
          onClick={() => {
            mixPanelTrackEvent(MIXPANEL_EVENTS_CONSTANTS.FORGET_PASSWORD, {
              action: 'Done',
            });
            history.push({ pathname: '/login' });
          }}
        />
      </div>
    </div>
  );
};

ResetConfirmation.propTypes = {
  history: PropTypes.objectOf({
    push: PropTypes.func,
  }).isRequired,
};
