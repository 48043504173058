import { gameTime } from 'Utils/date-utils';
import { APP_CONSTANTS } from 'Constants/app-constants';

export const sportTimes = {
  nfl: { quarter: 15, game: 60 },
  nba: { quarter: 12, game: 48 },
  mlb: { quarter: 0, game: 9 },
  nhl: { quarter: 20, game: 60 },
};

export const getContestStart = contest => {
  const startTime = gameTime(
    contest && contest.contestDate && contest.contestDate.start,
  );
  return `STARTS ${startTime || 'SOON'}`;
};

export const getSportMinutesRemaining = (item, playerGames, getProgress) => {
  if (!playerGames) {
    return '';
  }

  try {
    const timing = sportTimes[item.sportSlug];
    const mR = item.roster.reduce((sum, p) => {
      const pg = playerGames[p.playerGameId._str];
      if (pg && (pg.finished || pg.gameStatus === 'Complete')) {
        return sum;
      }
      if (pg && pg.gameStatus === 'InProgress') {
        const prog = getProgress(p);
        return sum + prog.minutesRemaining;
      }
      return sum + timing.game;
    }, 0);
    return `${mR} MIN REMAINING`;
  } catch (e) {
    console.log(e);
  }
  return '';
};

export const readableQuarter = (quarter, abbrv) => {
  const suffix = abbrv ? 'QTR' : 'QUARTER';
  if (!quarter) {
    return '';
  }
  switch (quarter.toString()) {
    case '1':
      return `${quarter}ST ${suffix}`;
    case '2':
      return `${quarter}ND ${suffix}`;
    case '3':
      return `${quarter}RD ${suffix}`;
    case '4':
      return `${quarter}TH ${suffix}`;
    default:
      return '';
  }
};

export const getEventDetails = (quarter, clock) => {
  const suffix = 'Quarter';
  switch (quarter.toString()) {
    case '1':
      return `${clock} ${quarter}st ${suffix}`;
    case '2':
      return `${clock} ${quarter}nd ${suffix}`;
    case '3':
      return `${clock} ${quarter}rd ${suffix}`;
    case '4':
      return `${clock} ${quarter}th ${suffix}`;
    default:
      return '';
  }
};

export const getSportProgress = (game, sportSlug) => {
  try {
    if (sportSlug === 'mlb') {
      const totalInnings = game.doubleHeader ? 7 : 9;
      const completedInningsPercentage =
        game.currentOutcome && game.currentOutcome.inning / totalInnings;
      return {
        minutesRemaining: 0,
        getQuarter: () => '',
        clock: '',
        progress: completedInningsPercentage,
      };
    }
    const timing = sportTimes[sportSlug];
    if (game.period === 'F') {
      return {
        minutesRemaining: 0,
        getQuarter: () => 'FINAL',
        clock: game.clock,
        progress: 1,
      };
    }

    if (game.period === 'Half' || game.status === 'HalfTime') {
      return {
        minutesRemaining: Math.round(timing.game / 2),
        getQuarter: () => 'HALF',
        clock: game.clock,
        progress: Math.round(timing.game / 2) / timing.game,
      };
    }
    if (!game.period || !game.clock) {
      return {
        minutesRemaining: 0,
        getQuarter: () => '',
        clock: '',
        progress: 0,
      };
    }
    const minutesAtEndOfPeriod = game.period * timing.quarter;
    const periodRemainingTime = game.clock.split(':');
    const quarterMinutes = Number(periodRemainingTime[0]);
    const quarterSeconds = Number(periodRemainingTime[1]);
    const minutesElapsed =
      minutesAtEndOfPeriod - (quarterMinutes + quarterSeconds / 60);
    return {
      minutesRemaining: Math.round(timing.game - minutesElapsed),
      getQuarter: abbrv => readableQuarter(game.period, abbrv),
      clock: game.clock,
      progress: minutesElapsed / timing.game,
    };
  } catch (e) {
    return { progress: 0 };
  }
};

export const getProgress = (playerGameId, playerGames, games) => {
  if (!playerGameId || !playerGames || !games) {
    return 0;
  }
  const playerGame = playerGames[playerGameId._str];
  if (!playerGame) {
    return 0;
  }
  /* eslint no-underscore-dangle: 0 */
  const game = games[playerGame.gameId._str];

  return getSportProgress(game, playerGame.sportSlug);
};

function reduceData(data) {
  if (!data) {
    return data;
  }

  return data.reduce((obj, d) => {
    return {
      ...obj,
      [d._id]: d,
    };
  }, {});
}

export const getMinutesRemaining = (item, playerGames, games) => {
  const formatPlayerGames = reduceData(playerGames);
  const formatGames = reduceData(games);
  return getSportMinutesRemaining(item, formatPlayerGames, ({ playerGameId }) =>
    getProgress(playerGameId, formatPlayerGames, formatGames),
  );
};

export const getGameIssuesCountInTheRoster = (playerGames, roster) => {
  const formatPlayerGames = reduceData(playerGames);
  return playerGames && roster
    ? roster.reduce(
        (gameByStatus, player) => {
          const playerGameId = player.playerGameId._str;
          if (!formatPlayerGames[playerGameId]) {
            console.log(`${playerGameId} game id not found`);
            return gameByStatus;
          }
          return {
            Delayed:
              gameByStatus.Delayed +
              (formatPlayerGames[playerGameId].gameStatus.includes(
                'Wdelay',
                'Fdelay',
                'Odelay',
              )
                ? 1
                : 0),
            Suspended:
              gameByStatus.Suspended +
              (formatPlayerGames[playerGameId].gameStatus === 'Suspended'
                ? 1
                : 0),
            Postponed:
              gameByStatus.Postponed +
              (formatPlayerGames[playerGameId].gameStatus === 'Postponed'
                ? 1
                : 0),
          };
        },
        {
          Delayed: 0,
          Suspended: 0,
          Postponed: 0,
        },
      )
    : { Delayed: 0, Suspended: 0, Postponed: 0 };
};

export const getGameTimeObj = (
  playerGameId,
  playerGames,
  games,
  activeFilter,
) => {
  const { ACTIVE, UPCOMING, COMPLETED } = APP_CONSTANTS.LINEUPS_TABS;
  const gameTimeData = {};
  const pg =
    playerGames.length && playerGames.find(pg => pg._id === playerGameId._str);

  const futureTime = gameTime(pg && pg.gameStartDate);

  switch (activeFilter) {
    case ACTIVE: {
      if (pg && pg.gameStatus === 'Postponed') {
        gameTimeData.time = 'PPD';
        gameTimeData.status = 'live';
        break;
      }
      if (pg && pg.gameStatus === 'Delayed') {
        gameTimeData.time = 'DLYD';
        gameTimeData.status = 'live';
        break;
      }
      if (pg && pg.gameStatus === 'Suspended') {
        gameTimeData.time = 'SUSP';
        gameTimeData.status = 'live';
        break;
      }
      if (pg && pg.gameStatus === 'Scheduled') {
        gameTimeData.time = futureTime;
        gameTimeData.status = 'pending';
        break;
      }
      if (pg && pg.finished) {
        gameTimeData.time = 'FINAL';
        gameTimeData.status = 'finished';
        break;
      }
      if (pg && pg.gameStatus === 'Complete') {
        gameTimeData.time = 'FINAL';
        gameTimeData.status = 'finished';
        break;
      }
      gameTimeData.status = 'live';
      const formatPlayerGames = reduceData(playerGames);
      const formatGames = reduceData(games);
      const { getQuarter } = getProgress(
        playerGameId,
        formatPlayerGames,
        formatGames,
      );
      gameTimeData.time = getQuarter && getQuarter(true);
      if (gameTimeData.time === APP_CONSTANTS.GAME_STATUS.HALF_DISPLAY) {
        gameTimeData.status = 'half';
      } else if (gameTimeData.time === 'FINAL') {
        gameTimeData.time = 'FINAL';
        gameTimeData.status = 'finished';
      }
      break;
    }
    case UPCOMING:
      gameTimeData.time = futureTime;
      break;

    case COMPLETED:
      gameTimeData.time = 'FINAL';
      gameTimeData.status = 'finished';
      break;
    default:
      break;
  }
  return gameTimeData;
};
